//css
import "./changeOrderModal.css";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalDialogFooter } from "../../../../../../components/modalDialogFooter/modalDialogFooter";
import { createChangeOrder } from "../../../../../../api/clientAPI";
import { isError } from "../../../../../../lib/isError";

//import { ModalDialogFooter } from "../../../../../../../../components/modalDialogFooter/modalDialogFooter";

export const ChangeOrderModal = ({ closeDialog, setClientData, clientData }) => {
    //Medication data state
    const [data, setData] = useState(null);

    //Medication data state
    const [medicationId, setMedicationId] = useState(null);

    //list of current Medications
    const [listOfCurrentMedications, setListOfCurrentMedications] = useState([]);

    //saving state
    const [creating, setCreating] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {}, []);

    //=== Methods ============================================================

    //=== Handlers ===========================================================

    const handleReset = () => {
        //const newData = { id: nurseReviewData?.id, status: nurseReviewData?.status };
        //setNurseReviewData(newData);
    };

    const handleSave = async () => {
        setCreating(true);

        const requestData = { ...data, id: clientData.id };

        await createChangeOrder(requestData)
            .then((ret) => {
                const newClientData = { ...clientData, documents: [...clientData.documents, { id: ret?.id, typeDescription: ret?.typeDescription, createdDate: ret?.createdDate.split("Z")[0], createdBy: ret?.createdBy }] };
                setClientData(newClientData);
                closeDialog();
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally((_) => {
                setCreating(false);
            });
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...data, [name]: value };
        setData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...data, [name]: checked };
        setData(newData);
    };

    //= Render ===================================================================

    return (
        <Modal show={true} onHide={closeDialog} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="ps-2">Create Change Order</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    <div className="row">
                        <div className="col-6">
                            <input name="nursingTherapyRevision" id="nursingTherapyRevision" type="checkbox" className="form-check-input" checked={data?.nursingTherapyRevision} onChange={handleCheckboxChange} />
                            <label className="form-check-label small ps-2" htmlFor="nursingTherapyRevision">
                                Nursing/Therapy Revision (Physician Notified)
                            </label>
                        </div>
                        <div className="col-6">
                            <input name="physicianRevision" id="physicianRevision" type="checkbox" className="form-check-input" checked={data?.physicianRevision} onChange={handleCheckboxChange} />
                            <label className="form-check-label ps-2 small" htmlFor="physicianRevision">
                                Physician Revision (Must forward for signature)
                            </label>
                        </div>
                        <div className="col-6 mt-2">
                            <input name="verbalOrder" id="verbalOrder" type="checkbox" className="form-check-input" checked={data?.verbalOrder} onChange={handleCheckboxChange} />
                            <label className="form-check-label ps-2 small" htmlFor="verbalOrder">
                                Verbal Order (Must forward for signature)
                            </label>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-3">
                            <label className="my-1 d-block small">Patient's HI Claim No.</label>
                            <input name="patientHIClaimNumber" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Claim #" value={data?.patientHIClaimNumber ?? ""} />
                        </div>
                        <div className="col-3">
                            <label className="my-1 d-block small">Start of Care</label>
                            <input name="startOfCare" type="date" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Claim #" value={data?.startOfCare ?? ""} />
                        </div>
                        <div className="col-3">
                            <label className="my-1 d-block small">Certification Period From</label>
                            <input name="certificationPeriodFrom" type="date" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Claim #" value={data?.certificationPeriodFrom ?? ""} />
                        </div>
                        <div className="col-3">
                            <label className="my-1 d-block small">Certification Period To</label>
                            <input name="certificationPeriodTo" type="date" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Claim #" value={data?.certificationPeriodTo ?? ""} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-3">
                            <label className="my-1 d-block small">Medical Record No.</label>
                            <input name="medicalRecordNumber" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Medical Record #" value={data?.medicalRecordNumber ?? ""} />
                        </div>
                        <div className="col-3">
                            <label className="my-1 d-block small">Provider No.</label>
                            <input name="providerNumber" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Provider #" value={data?.providerNumber ?? ""} />
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                TO <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="my-1 d-block small">Name</label>
                            <input name="toName" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Name" value={data?.toName ?? ""} />
                        </div>
                        <div className="col-6">
                            <label className="my-1 d-block small">Address</label>
                            <input name="toAddress" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Address" value={data?.toAddress ?? ""} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <label className="my-1 d-block small">City</label>
                            <input name="toCity" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="City" value={data?.toCity ?? ""} />
                        </div>
                        <div className="col-4">
                            <label className="my-1 d-block small">State</label>
                            <input name="toState" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="State" value={data?.toState ?? ""} />
                        </div>
                        <div className="col-4">
                            <label className="my-1 d-block small">Zip</label>
                            <input name="toZip" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Zip" value={data?.toZip ?? ""} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <label className="my-1 d-block small">Phone</label>
                            <input name="toPhoneNumber" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Phone" value={data?.toPhoneNumber ?? ""} />
                        </div>
                        <div className="col-4">
                            <label className="my-1 d-block small">Fax</label>
                            <input name="toFaxNumber" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Fax" value={data?.toFaxNumber ?? ""} />
                        </div>
                    </div>

                    <div className="row m-0 p-0 mt-3">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                FROM <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <label className="my-1 d-block small">Name</label>
                            <input name="fromName" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Name" value={data?.fromName ?? ""} />
                        </div>
                        <div className="col-6">
                            <label className="my-1 d-block small">Address</label>
                            <input name="fromAddress" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Address" value={data?.fromAddress ?? ""} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <label className="my-1 d-block small">City</label>
                            <input name="fromCity" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="City" value={data?.fromCity ?? ""} />
                        </div>
                        <div className="col-4">
                            <label className="my-1 d-block small">State</label>
                            <input name="fromState" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="State" value={data?.fromState ?? ""} />
                        </div>
                        <div className="col-4">
                            <label className="my-1 d-block small">Zip</label>
                            <input name="fromZip" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Zip" value={data?.fromZip ?? ""} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <label className="my-1 d-block small">Phone</label>
                            <input name="fromPhoneNumber" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Phone" value={data?.fromPhoneNumber ?? ""} />
                        </div>
                        <div className="col-4">
                            <label className="my-1 d-block small">Fax</label>
                            <input name="fromFaxNumber" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Fax" value={data?.fromFaxNumber ?? ""} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label className="my-1 d-block small">Comments</label>
                            <textarea name="comments" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Comments" value={data?.comments ?? ""} />
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-success" disabled={creating} onClick={handleSave}>
                    {!creating && "Generate Order"}
                    {creating && (
                        <span className="ps-2">
                            <span className="pe-2">Generating...</span>
                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                        </span>
                    )}
                </button>

                <button type="button" className="btn btn-outline-secondary" disabled={creating} onClick={closeDialog}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};
