import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./completeAssessmentConfirmationDialog.css";

export const CompleteAssessmentConfirmationDialog = ({ data, closeDialog, handleConfirmClick }) => {
    const [formData, setFormData] = useState();

    useEffect(() => {
        const vet = [];

        //1. Medication
        const medication = {
            category: "Medication",
            value: data?.medicationsSection?.medications?.map((m) => {
                return m.name + (m.dose ? " - " + m.dose : "") + (m.frequency ? " - " + m.frequency : "") + (m.route ? " - " + m.route : "");
            }),
        };
        vet.push(medication);

        //2. Diagnosis
        const diagnosis = { category: "Diagnosis", value: [...(data?.skilledNurseVisitNoteSection?.diagnoses ?? []), ...[data?.skilledNurseVisitNoteSection?.reasonForNeedingHomecare ?? ""]]?.filter((d) => d !== "") };
        vet.push(diagnosis);

        //3. Medical Equipment
        const medicalEquipment = { category: "Medical Equipment", value: [...(data?.assistiveDevicesSection?.devices ?? []), ...[data?.assistiveDevicesSection?.deviceOtherDescription ?? ""]]?.filter((d) => d !== "Other" && d !== "") };
        vet.push(medicalEquipment);

        //4. Safety Measures //safetyOther
        const safetyMeasures = { category: "Safety Measures", value: [...(data?.interventionsInstructionsSection?.safety ?? []), ...[data?.interventionsInstructionsSection?.safetyOther ?? ""]]?.filter((d) => d !== "Other" && d !== "") };
        vet.push(safetyMeasures);

        //5. Functional Limitations
        const functionalLimitations = { category: "Functional Limitations", value: data?.nurseOrdersSection?.functionalLimitations ?? [] };
        vet.push(functionalLimitations);

        //6. Activities Permitted
        const activitiesPermitted = { category: "Activities Permitted", value: [...(data?.nurseOrdersSection?.activitiesPermitted ?? []), ...[data?.nurseOrdersSection?.activitiesPermittedOther ?? ""]]?.filter((d) => d !== "Other" && d !== "") };
        vet.push(activitiesPermitted);

        //7. Mental Status
        const mentalStatus = { category: "Mental Status", value: data?.skilledObservationSection?.mentalStatus ?? [] };
        vet.push(mentalStatus);

        //8. Treatments
        const treatments = {
            category: "Treatments",
            value: data?.nurseOrdersSection?.nurseOrders?.map((m) => {
                return m.skill + (m.daysForWeek ? " - " + m.daysForWeek + " Days/Week" : "") + (m.daysForWeek ? " - " + m.hoursForDay + " Hours/Day" : "") + (m.comments ? " - " + m.comments : "");
            }),
        };
        vet.push(treatments);

        setFormData(vet);
    }, [data]);

    const handleDialogConfirmClick = () => {
        handleConfirmClick();
        closeDialog();
    };

    return (
        <Modal dialogClassName="confirmationCompleteModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                        style={{
                            fontSize: "3.5rem",
                            color: "green",
                            paddingTop: "0.6rem",
                        }}
                    ></i>
                    <h4 className="text-success pt-2 pb-3">You have requested to "Complete" this assessment.</h4>
                    <h5 className="pb-0 mb-0">Reminder</h5>
                    <p className="pt-0 mt-0">You can no longer update this assessment after completing.</p>
                </div>

                <div className="row mx-0 px-0 mt-5 text-left">
                    <div className="col-12 mx-0 px-0">
                        <span className="text-success">
                            <b>485 Form Data</b> <hr className="my-1" />
                        </span>
                    </div>
                </div>

                <div className="p-2" style={{ backgroundColor: "rgba(88, 174, 137, 0.15)", maxHeight: "175px", overflowY: "auto", overflowX: "hidden" }}>
                    {formData &&
                        formData.map((item, index) => {
                            return (
                                <div className="row" key={index}>
                                    <div className="col-12 pt-2">
                                        <li className="text-success">{item.category}</li>
                                        <small>
                                            <ul className="formData">
                                                {item?.value &&
                                                    item?.value?.map((i, index) => {
                                                        if (i !== "") {
                                                            return (
                                                                <small key={index}>
                                                                    <li>{i}</li>
                                                                </small>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                {item?.value?.filter((i) => i !== "").length === 0 && "-"}
                                            </ul>
                                        </small>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-secondary" onClick={closeDialog}>
                    Cancel
                </button>
                <button className="btn btn-success" onClick={handleDialogConfirmClick}>
                    Confirm
                </button>
            </Modal.Footer>
        </Modal>
    );
};
