export const getMARPeriodText = (periodValue) => {
    switch (periodValue) {
        case 0:
            return "Morning";
        case 1:
            return "Afternoon";
        case 2:
            return "Evening";
        case 3:
            return "Night";
        default:
            return "Unknown";
    }
};
