import Moment from "react-moment";
//import "../../report1/report1.css";

export const ReportListTableBody = ({ items, loadingError, loading, currentPage, numberPerPage }) => {
    const begin = (Number(currentPage) - 1) * Number(numberPerPage);
    const end = begin + Number(numberPerPage);
    const data = items.slice(begin, end) ?? [];

    if (loadingError)
        return (
            <tr>
                <td colSpan={"8"} className="table-danger text-center">
                    {loadingError}
                </td>
            </tr>
        );

    return data.length === 0
        ? !loading && (
              <tr>
                  <td colSpan={"8"} className="table-danger text-center">
                      No Visits to display
                  </td>
              </tr>
          )
        : data.map(({ officeName, clientName, appointmentDate, assessmentType, workerName, visitStatus, completedDate, daysLate, id }) => {
              return (
                  <tr
                      key={id}
                      //onClick={() => handleItemClick(nurseAssessmentId, id)}
                      style={{ cursor: "pointer" }}
                  >
                      <td className="small" valign="middle">
                          {officeName}
                      </td>
                      {
                          <td className="small d-none d-md-table-cell" valign="middle">
                              {clientName}
                          </td>
                      }
                      <td className="small" valign="middle">
                          <Moment format="MM/DD/YYYY hh:mm A">{appointmentDate}</Moment>
                      </td>
                      <td className="small d-none d-md-table-cell" valign="middle">
                          {assessmentType}
                      </td>
                      <td className="small d-none d-md-table-cell" valign="middle">
                          {workerName}
                      </td>
                      <td className="small d-none d-md-table-cell" valign="middle">
                          {visitStatus}
                      </td>
                      {completedDate == null ? (
                          <td className="small text-center" valign="middle">
                              -
                          </td>
                      ) : (
                          <td className="small text-center">
                              <Moment format="MM/DD/YYYY hh:mm A">{completedDate}</Moment>
                          </td>
                      )}
                      <td className="small text-center" valign="middle">
                          {daysLate}
                      </td>
                  </tr>
              );
          });
};
