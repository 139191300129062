//css
import "./medicationsDialog.css";

//Icons
import icon from "../../../../assets/prescription-bottle-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isError } from "../../../../lib/isError";
import { updateMedicationsSection, addMedication_MedicationsSection, updateMedication_MedicationsSection, removeMedication_MedicationsSection, getMedicationByClientId, copyMedicationsFromProfileToVisit } from "../../../../api/nurseAssessment/medicationsAPI";
import { doseTypeData, routeData, medicationJson } from "../../../../lib/tempData";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { DateFormatted } from "../../../../components/dateFormatted/dateFormatted";
import { AutoComplete } from "../../../../components/autoComplete/autoComplete";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";
import { medicationDialogValidation } from "./medicationsDialogValidation";
import { getAllActivePhysicians } from "../../../../api/physicianAPI";
import { getFDAMedications, getFDAMedicationsById } from "../../../../api/nurseAssessment/medicationFDAApi";

export const MedicationsDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Medication data
    const fdaMedicationDataEmpty = {
        id: null,
        name: null,
        fdaMedicationDosageTypes: [],
    };

    //dosage type data
    const dosageTypeDataEmpty = {
        fdaMedicationId: null,
        fdaDosageTypeId: null,
        fdaDosageType: null,
        fdaMedicationDosageTypeRoutes: [],
        id: null,
    };

    //medication details data
    const medicationDetailsData = {
        id: null,
        name: "",
        physicianName: "",
        selfManaged: "",
        discontinued: "",
        dose: "",
        doseType: "",
        frequency: "",
        frequencyOtherDescription: "",
        route: "",
        class: "",
        rxNumber: "",
        rxRefillsLeft: "",
        startDate: "",
        endDate: "",
        time: "",
        hour: "",
        okToCrush: "",
        controlled: "",
        administeredThisVisit: "",
        pharmacyName: "",
        pharmacyPhone: "",
        orderDetails: "",
    };

    //Use States ===========================================================

    //Medication Section data state
    const [medicationData, setMedicationData] = useState(null);

    //Medication FDA Section data state
    const [medicationFDAData, setMedicationFDAData] = useState(null);

    //Physicians data state
    const [physiciansData, setPhysiciansData] = useState([]);

    //Form Validation Errors
    const [formValidationErrors, setFormValidationErrors] = useState(null);

    //Medication Item data state
    const [medicationItemData, setMedicationItemData] = useState(null);

    //FDAMedication data state
    const [fdaMedicationItemData, setFDAMedicationItemData] = useState(fdaMedicationDataEmpty);

    //Medication Dose Type Options
    const [doseTypeOptions, setDoseTypeOptions] = useState(doseTypeData);

    //Medication Route Options
    const [routeOptions, setRouteOptions] = useState(routeData);

    //saving state
    const [saving, setSaving] = useState(false);
    const [adding, setAdding] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //show Medication Form state
    const [showMedicationForm, setShowMedicationForm] = useState(false);

    //Form mode state (new or edit)
    const [formMode, setFormMode] = useState(null);

    //Selected Medication index
    const [selectedMedicationIndex, setSelectedMedicationIndex] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        getFDAMedications()
            .then((data) => {
                setMedicationFDAData(data);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading physician data");
                }
            });
    }, []);

    useEffect(() => {
        const sectionData = { ...data?.medicationsSection, status: data?.medicationsSection?.status ?? "Applicable" };
        setMedicationData(sectionData);
    }, [data]);

    useEffect(() => {
        const newDoseTypeOptions = fdaMedicationItemData?.fdaMedicationDosageTypes?.map((d) => d?.fdaDosageType?.name);
        setDoseTypeOptions(newDoseTypeOptions);

        //if there are just one option for dose type, selected it.
        if (newDoseTypeOptions.length === 1) {
            const newData = { ...medicationItemData, doseType: newDoseTypeOptions[0], route: "" };
            setMedicationItemData(newData);
        }
    }, [fdaMedicationItemData]);

    //=== Methods ============================================================

    const getIsValidClass = (fieldName) => ((formValidationErrors ?? []).find((err) => err.field === fieldName)?.errorMessage ? "is-invalid" : "");

    const getErrorMessage = (fieldName) => (formValidationErrors ?? []).find((err) => err.field === fieldName)?.errorMessage;

    const loadFDAMedication = (id) => {
        setLoadingError(null);
        setLoading(true);
        getFDAMedicationsById(id)
            .then((data) => {
                console.log("FDAMedicationOtemData", data);
                setFDAMedicationItemData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: medicationData?.id, status: medicationData?.status, medications: [] };
        setMedicationData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...medicationData };

        //===========================================================

        //Saving MedicationSection
        updateMedicationsSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", medicationsSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldStatusChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...medicationData, [name]: value };

        setMedicationData(newData);
    };

    const handleMedicationBlur = () => {
        const fdaMedication = medicationFDAData?.find((m) => m.name === medicationItemData?.name);

        if (fdaMedication) {
            loadFDAMedication(fdaMedication.id);
        } else {
            setFDAMedicationItemData(fdaMedicationDataEmpty);
        }
    };

    const handleDoseTypeBlur = () => {
        const newRouteOptions = fdaMedicationItemData?.fdaMedicationDosageTypes?.filter((d) => d.fdaDosageType.name === medicationItemData?.doseType)[0].fdaMedicationDosageTypeRoutes.map((r) => r?.fdaRoute?.name);

        setRouteOptions(newRouteOptions);

        //if there are just one option for dose type, selected it.
        if (newRouteOptions.length === 1) {
            const newData = { ...medicationItemData, route: newRouteOptions[0] };
            setMedicationItemData(newData);
        }
    };

    const handleNameFieldChange = (value) => {
        const newData = { ...medicationItemData, name: value };
        setMedicationItemData(newData);
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...medicationItemData, [name]: value };
        setMedicationItemData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...medicationItemData, [name]: checked };
        setMedicationItemData(newData);
    };

    const handleAddNewMedication = () => {
        const newData = medicationDetailsData;
        setMedicationItemData(newData);
        setFormMode("New");
        setShowMedicationForm(true);
    };

    const handleAddMedication = () => {
        const addObj = { ...medicationItemData, MedicationSectionId: data?.id };

        const formValidation = medicationDialogValidation(addObj, medicationFDAData);

        if (!formValidation?.isValid) {
            setFormValidationErrors(formValidation?.errors);
            setOperationError("Error: Unable to save. Please review the highlighted fields and try again.");
            //hide message after 5s
            setTimeout(() => setOperationError(null), 5000);
        } else {
            setAdding(true);

            //Saving MedicationSection
            addMedication_MedicationsSection(addObj)
                .then((ret) => {
                    let medications = medicationData?.medications ? medicationData?.medications : [];

                    const newMedicationData = { ...medicationData, medications: [...medications, { ...medicationItemData, id: ret?.id }] };
                    setMedicationData(newMedicationData);

                    const newData = { ...data, status: "In Progress", medicationsSection: { ...newMedicationData, progress: newMedicationData?.medications?.length === 0 ? "Pending" : "Completed" } };
                    setData(newData);

                    setShowMedicationForm(false);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error loading data");
                    }

                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                })
                .finally(() => setAdding(false));
        }
    };

    const handleUpdateMedication = () => {
        const updateObj = { ...medicationItemData, MedicationSectionId: data?.id };

        const formValidation = medicationDialogValidation(updateObj, medicationFDAData);

        if (!formValidation?.isValid) {
            setFormValidationErrors(formValidation?.errors);
            setOperationError("Error: Unable to save. Please review the highlighted fields and try again.");
            //hide message after 5s
            setTimeout(() => setOperationError(null), 5000);
        } else {
            setUpdating(true);

            //Saving MedicationSection
            updateMedication_MedicationsSection(updateObj)
                .then((ret) => {
                    let medications = medicationData?.medications ? medicationData?.medications : [];
                    medications[selectedMedicationIndex] = medicationItemData;
                    const newData = { ...medicationData, medications: medications };
                    setMedicationData(newData);
                    setShowMedicationForm(false);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error loading data");
                    }

                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                })
                .finally(() => setUpdating(false));
        }
    };

    const handleRemoveMedication = () => {
        setRemoving(true);
        const removeObj = medicationItemData;

        //Saving MedicationSection
        removeMedication_MedicationsSection(removeObj)
            .then((ret) => {
                let medications = [...medicationData?.medications];
                medications.splice(selectedMedicationIndex, 1);

                const newMedicationData = { ...medicationData, medications: medications };
                setMedicationData(newMedicationData);

                const newData = { ...data, status: "In Progress", medicationsSection: { ...newMedicationData, progress: (newMedicationData?.medications?.length ?? []) === 0 ? "Pending" : "Completed" } };
                setData(newData);

                setShowMedicationForm(false);
                setRemoving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setRemoving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    const editMedication = (index) => {
        const newData = medicationData?.medications[index];
        setSelectedMedicationIndex(index);
        setMedicationItemData(newData);
        setFormMode("Edit");
        setShowMedicationForm(true);
        setFormValidationErrors(null);
    };

    const handleLoadDataPreviousVisitClick = () => {
        setLoading(true);
        copyMedicationsFromProfileToVisit(data?.id)
            .then((ret) => {
                const newMedicationData = { ...medicationData, medications: ret };
                setMedicationData(newMedicationData);

                const newData = { ...data, status: "In Progress", medicationsSection: { ...newMedicationData, progress: (newMedicationData?.medications?.length ?? []) === 0 ? "Pending" : "Completed" } };
                setData(newData);

                //hide message after 1s
                setTimeout(() => setLoading(false), 1000);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data.");
                }
                setLoading(false);
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    //=== Render ===========================================================

    return (
        <Modal dialogClassName="medicationSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Medications - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-3">{medicationData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldStatusChange} value={medicationData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Reported Allergies <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">{data?.client?.allergies ?? "-"}</div>
                    </div>

                    <div className="row m-0 p-0  mt-3">
                        <div className="col-4 m-0 p-0 pt-2">
                            <span className="text-success">Current Medication Orders</span>
                        </div>
                        {!readOnly && (medicationData?.medications ?? []).length === 0 && (
                            <div className="col-8 text-end">
                                <button className="btn btn-sm btn-outline-secondary" onClick={handleLoadDataPreviousVisitClick}>
                                    {!loading && "Load Medication from Client's Profile"}
                                    {loading && (
                                        <span className="ps-2">
                                            <span className="pe-2">Loading...</span>
                                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        )}
                        <hr className="my-1" />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm medicationList mt-1">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-center">Dose</th>
                                        <th>Type</th>
                                        <th>Frequency</th>
                                        <th>Route</th>
                                        <th className="text-center">Start</th>
                                        <th className="text-center">End</th>
                                        <th className="text-center">Crush</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medicationData?.medications && medicationData?.medications.length === 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="8" align="center">
                                                    No medication order
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {medicationData?.medications &&
                                        medicationData?.medications.map((medication, index) => {
                                            return (
                                                <tr key={index} className={`${medication?.discontinued || (medication?.endDate?.length > 0 && (medication?.endDate ?? new Date().toISOString().split("T")[0]) < new Date().toISOString().split("T")[0]) ? "discontinued" : ""}`}>
                                                    <td className="small text-primary" onClick={() => editMedication(index)}>
                                                        <a href="#medicationForm">{medication?.name}</a>
                                                    </td>
                                                    <td className="small text-center">{medication?.dose ?? "-"}</td>
                                                    <td className="small">{medication?.doseType ?? "-"}</td>
                                                    <td className="small">{medication?.frequency === "Other" ? medication?.frequencyOtherDescription ?? "-" : medication?.frequency ?? "-"}</td>
                                                    <td className="small">{medication?.route ?? "-"}</td>
                                                    <td className="small text-center">
                                                        <DateFormatted date={medication?.startDate} dateFormat="MM/DD/YY" />
                                                    </td>
                                                    <td className="small text-center">
                                                        <DateFormatted date={medication?.endDate} dateFormat="MM/DD/YY" />
                                                    </td>
                                                    <td className="small text-center">{medication?.okToCrush ? "Yes" : "No"}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                            {!readOnly && (
                                <a href="#medicationForm">
                                    <button className="btn btn-sm btn-success" onClick={handleAddNewMedication} disabled={!["Applicable"].includes(medicationData?.status)}>
                                        Add New Medication
                                    </button>
                                </a>
                            )}
                        </div>
                    </div>

                    {showMedicationForm && (
                        <form>
                            <div className="row mx-0 px-0 mt-4" id="medicationForm">
                                <div className="col-12 mx-0 px-0">
                                    <span className="text-success">
                                        Medication <hr className="my-1" />
                                    </span>
                                </div>
                            </div>
                            <div className="p-2" style={{ backgroundColor: "rgba(88, 174, 137, 0.15)" }}>
                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Name</span>
                                        {/*<AutoComplete suggestions={medicationJson?.map((m) => m.Medication)} value={medicationItemData?.name} className={`form-control ${getIsValidClass("name")}`} onBlur={handleMedicationBlur} onNameChange={handleNameFieldChange} maxLength="230" disabled={!["Applicable"].includes(medicationData?.status) || readOnly} /> */}
                                        <AutoComplete suggestions={medicationFDAData?.map((m) => m.name)} value={medicationItemData?.name} className={`form-control ${getIsValidClass("name")}`} onBlur={handleMedicationBlur} onNameChange={handleNameFieldChange} maxLength="230" disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />

                                        {/*<input name="name" type="text" className="form-control" placeholder={`Name`} value={medicationItemData?.name} onChange={handleFieldChange} maxLength="230" disabled={!["Applicable"].includes(medicationData?.status)} /> */}
                                        <div className="d-block text-danger small text-end">{getErrorMessage("name")}</div>
                                    </div>

                                    <div className="col-2 pt-2">
                                        <span className="my-1 d-block">&nbsp;</span>
                                        <input name="selfManaged" id="selfManaged" type="checkbox" className="form-check-input" checked={medicationItemData?.selfManaged} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <label className="form-check-label ps-2" htmlFor="selfManaged">
                                            Self Managed
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-2">
                                        <span className="my-1 d-block">Dose</span>
                                        <input name="dose" type="text" className={`form-control ${getIsValidClass("dose")}`} placeholder={`Dose`} value={medicationItemData?.dose} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false)} />
                                        <div className="d-block text-danger small text-end">{getErrorMessage("dose")}</div>
                                    </div>

                                    <div className="col-4">
                                        <span className="my-1 d-block">Dose Type</span>
                                        <select name="doseType" className={`form-select ${getIsValidClass("doseType")}`} onChange={handleFieldChange} onBlur={handleDoseTypeBlur} value={medicationItemData?.doseType} disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false)}>
                                            <option value="">Select</option>
                                            {doseTypeOptions &&
                                                doseTypeOptions.map((item, index) => (
                                                    <option key={index} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className="d-block text-danger small text-end">{getErrorMessage("doseType")}</div>
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Frequency</span>
                                        <select name="frequency" className={`form-select ${getIsValidClass("frequency")}`} onChange={handleFieldChange} value={medicationItemData?.frequency} disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false)}>
                                            <option value="">Select</option>
                                            <option value="Continuous">Continuous</option>
                                            <option value="Once a day">Once a day</option>
                                            <option value="Twice daily">Twice daily</option>
                                            <option value="3 times a day">3 times a day</option>
                                            <option value="4 times a day">4 times a day</option>
                                            <option value="Every hour">Every hour</option>
                                            <option value="Every 4 hours">Every 4 hours</option>
                                            <option value="Every 6 hours">Every 6 hours</option>
                                            <option value="Every other day">Every other day</option>
                                            <option value="Every night">Every night</option>
                                            <option value="Every night at bedtime">Every night at bedtime</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                            <option value="As needed">As needed</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">{getErrorMessage("frequency")}</div>
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Frequency</span>
                                        <input name="frequencyOtherDescription" type="text" className="form-control" placeholder={`Describe`} value={medicationItemData?.frequencyOtherDescription} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false) || !["Other"].includes(medicationItemData?.frequency)} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <span className="my-1 d-block">Route</span>
                                        <select name="route" className={`form-select ${getIsValidClass("route")}`} onChange={handleFieldChange} value={medicationItemData?.route} disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false)}>
                                            <option value="">Select</option>
                                            {routeOptions &&
                                                routeOptions.map((item, index) => (
                                                    <option key={index} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className="d-block text-danger small text-end">{getErrorMessage("route")}</div>
                                    </div>

                                    <div className="col-6">
                                        <span className="my-1 d-block">Class</span>
                                        <select name="class" className="form-select" onChange={handleFieldChange} value={medicationItemData?.class} disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false)}>
                                            <option value="">Select</option>
                                            <option value="Antibiotic">Antibiotic</option>
                                            <option value="Anticoagulant">Anticoagulant</option>
                                            <option value="Antiplatelet">Antiplatelet</option>
                                            <option value="Antipsychotic">Antipsychotic</option>
                                            <option value="Hypoglycemic (including insulin)">Hypoglycemic (including insulin)</option>
                                            <option value="Opioid">Opioid</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <span className="my-1 d-block">Rx Number</span>
                                        <input name="rxNumber" type="text" className="form-control" placeholder={`Rx Number`} value={medicationItemData?.rxNumber} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-3">
                                        <span className="my-1 d-block">Rx Refills Left</span>
                                        <input name="rxRefillsLeft" type="number" className="form-control" placeholder={`# Refills`} value={medicationItemData?.rxRefillsLeft} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false)} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="col-2 pt-2">
                                        <span className="my-1 d-block">&nbsp;</span>
                                        <input name="discontinued" id="discontinued" type="checkbox" className="form-check-input" checked={medicationItemData?.discontinued} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <label className="form-check-label ps-2" htmlFor="discontinued">
                                            Discontinued
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-2">
                                        <span className="my-1 d-block">Start</span>
                                        <input name="startDate" type="date" className="form-control" placeholder={`Start`} value={medicationItemData?.startDate?.split("T")[0]} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false)} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-2">
                                        <span className="my-1 d-block">End</span>
                                        <input name="endDate" type="date" className={`form-control ${getIsValidClass("endDate")}`} placeholder={`End`} value={medicationItemData?.endDate?.split("T")[0]} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(medicationData?.status) || readOnly || (medicationItemData?.selfManaged ?? false)} />
                                        <div className="d-block text-danger small text-end">{getErrorMessage("endDate")}</div>
                                    </div>
                                    <div className="col-2 pt-2">
                                        <span className="my-1 d-block">&nbsp;</span>
                                        <input name="okToCrush" id="okToCrush" type="checkbox" className="form-check-input" checked={medicationItemData?.okToCrush} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <label className="form-check-label ps-2" htmlFor="okToCrush">
                                            Ok to Crush
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="col-2 pt-2">
                                        <span className="my-1 d-block">&nbsp;</span>
                                        <input name="controlled" id="controlled" type="checkbox" className="form-check-input" checked={medicationItemData?.controlled} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <label className="form-check-label ps-2" htmlFor="controlled">
                                            Controlled
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="col-3 pt-2">
                                        <span className="my-1 d-block">&nbsp;</span>
                                        <input name="administeredThisVisit" id="administeredThisVisit" type="checkbox" className="form-check-input" checked={medicationItemData?.administeredThisVisit} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <label className="form-check-label ps-2" htmlFor="administeredThisVisit">
                                            Administered this visit
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8">
                                        <span className="my-1 d-block">Pharmacy Name</span>
                                        <input name="pharmacyName" type="text" className="form-control" placeholder={`Pharmacy Name`} value={medicationItemData?.pharmacyName} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4">
                                        <span className="my-1 d-block">Pharmacy Phone</span>
                                        <input name="pharmacyPhone" type="text" className="form-control" placeholder={`Pharmacy Phone`} value={medicationItemData?.pharmacyPhone} onChange={handleFieldChange} maxLength="20" disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 d-block">Order Details</span>
                                        <input name="orderDetails" type="text" className="form-control" placeholder={`Order Details`} value={medicationItemData?.orderDetails} onChange={handleFieldChange} maxLength="250" disabled={!["Applicable"].includes(medicationData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>
                            </div>

                            {!readOnly && (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <MedicationButtons
                                                formMode={formMode}
                                                adding={adding}
                                                handleAdd={handleAddMedication}
                                                updating={updating}
                                                handleUpdate={handleUpdateMedication}
                                                removing={removing}
                                                handleRemove={handleRemoveMedication}
                                                handleCancel={() => {
                                                    setShowMedicationForm(false);
                                                }}
                                                readOnly={false}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </form>
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} disableSave={showMedicationForm} showPrint={true} readOnly={true}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, medicationsSection: data?.medicationsSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};

export const MedicationButtons = ({ formMode, adding, handleAdd, updating, handleUpdate, removing, handleRemove, handleCancel, readOnly = false }) => {
    const [showRemoveConfirmationMessage, setShowRemoveConfirmationMessage] = useState(false);

    return (
        <>
            {formMode === "New" ? (
                <button type="button" className="btn btn-sm btn-outline-success" onClick={handleAdd}>
                    {!adding && "Add"}
                    {adding && (
                        <span className="ps-2">
                            <span className="pe-2">Adding...</span>
                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                        </span>
                    )}
                </button>
            ) : (
                <>
                    <button type="button" className="btn btn-sm btn-outline-success" onClick={handleUpdate}>
                        {!updating && "Update"}
                        {updating && (
                            <span className="ps-2">
                                <span className="pe-2">Updating...</span>
                                <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                            </span>
                        )}
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-danger ms-2"
                        onClick={() => {
                            setShowRemoveConfirmationMessage(true);
                        }}
                    >
                        {!removing && "Remove"}
                        {removing && (
                            <span className="ps-2">
                                <span className="pe-2">Removing...</span>
                                <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                            </span>
                        )}
                    </button>
                </>
            )}

            <button
                type="button"
                className="btn btn-sm btn-outline-secondary ms-2"
                onClick={() => {
                    handleCancel(false);
                }}
            >
                Cancel
            </button>

            {showRemoveConfirmationMessage && (
                <span className="alert alert-danger ms-4 py-2 my-1" role="alert">
                    <small>Are you sure you want to remove this medication?</small>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary ms-2 my-0 py-0"
                        onClick={() => {
                            setShowRemoveConfirmationMessage(false);
                            handleRemove();
                        }}
                    >
                        <small>Yes</small>
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary ms-2 my-0 py-0"
                        onClick={() => {
                            setShowRemoveConfirmationMessage(false);
                        }}
                    >
                        <small>No</small>
                    </button>
                </span>
            )}
        </>
    );
};
