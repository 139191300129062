import icon from "../../../../../../../../assets/caregiver-clinical-orientation-red.svg";

import { useEffect } from "react";

export const SummaryCaregiverClinicalOrientation = ({ data }) => {
    //=== Use Effects ========================================================

    useEffect(() => {}, []);

    //=== Methods =============================================================

    //=== Render ==============================================================

    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Caregiver Clinical Orientation
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-3 fw-bold">Caregiver Name</div>
                        <div className="col-prt-3 fw-bold">Caregiver Position</div>
                        <div className="col-prt-3 fw-bold">Clinical Orientation Type</div>
                        <div className="col-prt-3 fw-bold">Location</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-3 text-primary">{data?.worker?.fullName ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.caregiverPosition ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.clinicalOrientationType ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.location ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Person being supervised</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.worker?.fullName ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Observations</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.observations ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 3,
                                }}
                            >
                                {data?.observations?.map((item, index) => {
                                    if (item !== "Other") return <li key={index}>{item}</li>;
                                })}
                                {data?.observationOther && <li>{data?.observationOther}</li>}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Instructions Related to Plan of Care</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.instructionsPlanOfCare ?? "-"}</div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
