//css
import "./homeSafetyDialog.css";

//Icons
import icon from "../../../../assets/house-circle-check-solid-green.svg";

import Select from "react-select";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateHomeSafetySection } from "../../../../api/nurseAssessment/homeSafetyAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { ToggleSwitch } from "../../../../components/toogleSwitch/toogleSwitch";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const HomeSafetyDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //HomeSafety data state
    const [homeSafetyData, setHomeSafetyData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of Reasons
    const listOfReasons = [
        { label: "Animal Waste", value: "Animal Waste" },
        { label: "Excessive dust", value: "Excessive dust" },
        { label: "Firearms are unsecured", value: "Firearms are unsecured" },
        { label: "Insect/rodent infestation", value: "Insect/rodent infestation" },
        { label: "Mold/dampness", value: "Mold/dampness" },
        { label: "Peeling paint", value: "Peeling paint" },
        { label: "Temperature extreme", value: "Temperature extreme" },
        { label: "Unsanitary/unsafe conditions", value: "Unsanitary/unsafe conditions" },
        { label: "Unsecured/Unsafe Animal", value: "Unsecured/Unsafe Animal" },
    ];

    //list of recommendations
    const listOfRecommendations = [
        { label: "Baby Monitor", value: "Baby Monitor" },
        { label: "Bath Seat/Bench", value: "Bath Seat/Bench" },
        { label: "Bed Alarms", value: "Bed Alarms" },
        { label: "Chair Alarm", value: "Chair Alarm" },
        { label: "Fire Extinguisher", value: "Fire Extinguisher" },
        { label: "Grab Bars", value: "Grab Bars" },
        { label: "Life Alert", value: "Life Alert" },
        { label: "Medic Alert", value: "Medic Alert" },
        { label: "Other", value: "Other" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.homeSafetySection, status: data?.homeSafetySection?.status ?? "Applicable" };
        setHomeSafetyData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: homeSafetyData?.id, status: homeSafetyData?.status };
        setHomeSafetyData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...homeSafetyData };

        //===========================================================

        //Saving WoundCareSection
        updateHomeSafetySection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", homeSafetySection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...homeSafetyData, [name]: value };
        setHomeSafetyData(newData);
    };

    const toggleValueChange = (e) => {
        const name = e.name;
        const value = e.value;
        const newData = { ...homeSafetyData, [name]: value };
        setHomeSafetyData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...homeSafetyData, [name]: checked };
        setHomeSafetyData(newData);
    };

    const handleRadioClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...homeSafetyData, [name]: value };
        setHomeSafetyData(newData);
    };

    const handleRecommendationsChange = (e) => {
        let recommendationsNew = e?.map((i) => i.value);
        const newData = { ...homeSafetyData, recommendations: recommendationsNew };
        setHomeSafetyData(newData);
    };

    const handleReasonsIsNotSuitableForCareChange = (e) => {
        let reasonsIsNotSuitableForCareNew = e?.map((i) => i.value);
        const newData = { ...homeSafetyData, reasonsIsNotSuitableForCare: reasonsIsNotSuitableForCareNew };
        setHomeSafetyData(newData);
    };

    return (
        <Modal dialogClassName="homeSafetySectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Home Safety - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{homeSafetyData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={homeSafetyData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {homeSafetyData && (
                        <>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <span className="text-success">
                                        Living Space <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Unsecured firearms in the home</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.unsecuredFirearms ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.unsecuredFirearms ?? "N/A"} name="unsecuredFirearms" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Steps or stairs have accompanying sturdy handrails or banisters</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.stepsOrStairsAccompanyingSturdyHandrailsOrBanisters ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.stepsOrStairsAccompanyingSturdyHandrailsOrBanisters ?? "N/A"} name="stepsOrStairsAccompanyingSturdyHandrailsOrBanisters" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Pathways are clear in heavy traffic areas</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.pathwaysClearHeavyTrafficAreas ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.pathwaysClearHeavyTrafficAreas ?? "N/A"} name="pathwaysClearHeavyTrafficAreas" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Stairways/hallways/exits are clear of clutter and loose objects</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.stairwaysHallwaysExitsClearClutterAndLooseObjects ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.stairwaysHallwaysExitsClearClutterAndLooseObjects ?? "N/A"} name="stairwaysHallwaysExitsClearClutterAndLooseObjects" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Throw rugs are eliminated or fastened down</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.throwRugsEliminatedOfFastenedDown ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.throwRugsEliminatedOfFastenedDown ?? "N/A"} name="throwRugsEliminatedOfFastenedDown" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Electrical cords are placed close to walls and out of the pathway</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.electricalCordsCloseWallsAndOutPathway ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.electricalCordsCloseWallsAndOutPathway ?? "N/A"} name="electricalCordsCloseWallsAndOutPathway" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Lighting is adequate in client care area(s)</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.lightingAdequate ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.lightingAdequate ?? "N/A"} name="lightingAdequate" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">A flashlight, light switch or lamp is located within easy reach of the client</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.lightLocatedEasyReachClient ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.lightLocatedEasyReachClient ?? "N/A"} name="lightLocatedEasyReachClient" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Furniture is arranged to allow free movement</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.furnitureArrangedFreeMovement ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.furnitureArrangedFreeMovement ?? "N/A"} name="furnitureArrangedFreeMovement" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Hand grippers are installed in the bathroom, when appropriate</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.handGrippersInstalledBathroom ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.handGrippersInstalledBathroom ?? "N/A"} name="handGrippersInstalledBathroom" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">All medications are clearly labeled</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.allMedicationsClearlyLabeled ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.allMedicationsClearlyLabeled ?? "N/A"} name="allMedicationsClearlyLabeled" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Refrigeration is available for proper storage of solutions/medications</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.refrigerationAvailableForStorageSolutionsMedications ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.refrigerationAvailableForStorageSolutionsMedications ?? "N/A"} name="refrigerationAvailableForStorageSolutionsMedications" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">There is access to a working telephone</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.accessWorkingTelephone ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.accessWorkingTelephone ?? "N/A"} name="accessWorkingTelephone" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">A list of emergency telephone numbers is present in the home</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.listEmergencyTelephoneNumbers ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.listEmergencyTelephoneNumbers ?? "N/A"} name="listEmergencyTelephoneNumbers" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Smoke alarms are present on each floor</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.smokeAlarmsPresent ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.smokeAlarmsPresent ?? "N/A"} name="smokeAlarmsPresent" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">There is a non-skid surface or mat on the bathtub or shower floor</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.nonSkidSurfaceBathtubOrShower ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.nonSkidSurfaceBathtubOrShower ?? "N/A"} name="nonSkidSurfaceBathtubOrShower" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Oxygen in the home</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.oxygenInHome ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.oxygenInHome ?? "N/A"} name="oxygenInHome" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>

                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Proper lifting aids present for immobile clients or those client’s requiring assistance with transfers</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.properLiftingAids ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.properLiftingAids ?? "N/A"} name="properLiftingAids" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Client/Responsible Party verbalizes an understanding of the fire escape plan and route from the home</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.clientVerbalizesUnderstandingFireEscapePlan ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.clientVerbalizesUnderstandingFireEscapePlan ?? "N/A"} name="clientVerbalizesUnderstandingFireEscapePlan" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row m-0 p-0 mt-2">
                                <div className="col-12 m-0 p-0">
                                    <span className="text-success">
                                        The following items are in secured areas and out of the reach of children and confused individuals
                                        <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Medications</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.medicationsSecured ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.medicationsSecured ?? "N/A"} name="medicationsSecured" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Sharp Objects</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.sharpObjectsSecured ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.sharpObjectsSecured ?? "N/A"} name="sharpObjectsSecured" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Cleaning substances</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.cleaningSubstancesSecured ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.cleaningSubstancesSecured ?? "N/A"} name="cleaningSubstancesSecured" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Poisons (bug killer, weed killer, etc.)</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.poisonsSecured ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.poisonsSecured ?? "N/A"} name="poisonsSecured" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row m-0 p-0  mt-2">
                                <div className="col-12 m-0 p-0">
                                    <span className="text-success">
                                        If Oxygen in the home <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Oxygen is placed in a non-smoking area and away from an open flame</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.oxygenPlacedNonSmokingAreaAndAwayOpenFlame ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.oxygenPlacedNonSmokingAreaAndAwayOpenFlame ?? "N/A"} name="oxygenPlacedNonSmokingAreaAndAwayOpenFlame" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status) || !["Yes"].includes(homeSafetyData?.oxygenInHome)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>

                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">The client can manage oxygen tubing safely during transfers and ambulation</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.clientCanManageOxygenTubingSafelyDuringTransfers ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.clientCanManageOxygenTubingSafelyDuringTransfers ?? "N/A"} name="clientCanManageOxygenTubingSafelyDuringTransfers" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status) || !["Yes"].includes(homeSafetyData?.oxygenInHome)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>

                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">There is back up oxygen in case of a power outage</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.backupOxygen ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.backupOxygen ?? "N/A"} name="backupOxygen" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status) || !["Yes"].includes(homeSafetyData?.oxygenInHome)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row m-0 p-0 mt-2">
                                <div className="col-12 m-0 p-0">
                                    <span className="text-success">
                                        The client has been instructed and agrees that the following items will be secured away from the service area when Caring People staff are present
                                        <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Guns/Firearms/Weapons</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.gunsSecured ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.gunsSecured ?? "N/A"} name="gunsSecured" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>

                                <div className="col-8 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Illicit/offensive materials deemed not appropriate for a professional care setting</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.illicitOffensiveSecured ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.illicitOffensiveSecured ?? "N/A"} name="illicitOffensiveSecured" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row m-0 p-0 mt-2">
                                <div className="col-12 m-0 p-0">
                                    <span className="text-success">
                                        Home environment is suitable for care
                                        <div className="form-check form-check-inline ms-5 ps-5 me-0 pe-0">
                                            <input name="suitableForCare" className="form-check-input" type="radio" id="suitableForCareYes" value="Yes" checked={homeSafetyData?.suitableForCare === "Yes"} onClick={handleRadioClick} disabled={!["Applicable"].includes(homeSafetyData?.status) || readOnly} />
                                            <label className="form-check-label" htmlFor="suitableForCareYes">
                                                Yes
                                            </label>
                                            &nbsp;
                                        </div>
                                        <div className="form-check form-check-inline ms-2 me-0 pe-0">
                                            <input name="suitableForCare" className="form-check-input" type="radio" id="suitableForCareNo" value="No" checked={homeSafetyData?.suitableForCare === "No"} onClick={handleRadioClick} disabled={!["Applicable"].includes(homeSafetyData?.status) || readOnly} />
                                            <label className="form-check-label" htmlFor="suitableForCareNo">
                                                No
                                            </label>
                                        </div>
                                        <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 ">
                                    <span className="my-1 d-block">Reasons</span>
                                    {readOnly && (
                                        <div className="text-success pb-2">
                                            {homeSafetyData?.status === "Applicable"
                                                ? homeSafetyData?.reasonsIsNotSuitableForCare?.map((item, index) => {
                                                      if (index === 0) return item;
                                                      else return `, ${item}`;
                                                  }) ?? "-"
                                                : "-"}
                                        </div>
                                    )}
                                    {!readOnly && (
                                        <>
                                            <Select
                                                isMulti
                                                name="reasonsIsNotSuitableForCare"
                                                id="reasonsIsNotSuitableForCare"
                                                menuPlacement="bottom"
                                                options={listOfReasons}
                                                value={(homeSafetyData?.reasonsIsNotSuitableForCare ?? []).map((dev) => listOfReasons?.find((i) => i.value === dev))}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                onChange={handleReasonsIsNotSuitableForCareChange}
                                                isDisabled={!["Applicable"].includes(homeSafetyData?.status) || !["No"].includes(homeSafetyData?.suitableForCare)}
                                            ></Select>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row m-0 p-0 mt-2">
                                <div className="col-12 m-0 p-0">
                                    <span className="text-success">
                                        Outdoor Safety
                                        <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Handrails/banisters in place in good repair</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.handrailsBanistersInPlaceInGoodRepair ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.handrailsBanistersInPlaceInGoodRepair ?? "N/A"} name="handrailsBanistersInPlaceInGoodRepair" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Walkway clear and clutter free</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.walkwayClearAndClutterFree ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.walkwayClearAndClutterFree ?? "N/A"} name="walkwayClearAndClutterFree" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Areas of incline/curb are marked and visible</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.areasOfInclineCurbMarkedAndVisible ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.areasOfInclineCurbMarkedAndVisible ?? "N/A"} name="areasOfInclineCurbMarkedAndVisible" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Outdoor lighting in working order</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.outdoorLightingWorkingOrder ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.outdoorLightingWorkingOrder ?? "N/A"} name="outdoorLightingWorkingOrder" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-4 me-0 pe-0 ">
                                    <span className="my-1 d-block py-2">Stairs/ramp/egress access in good repair</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.stairsRampEgressAccessGoodRepair ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.stairsRampEgressAccessGoodRepair ?? "N/A"} name="stairsRampEgressAccessGoodRepair" onChange={toggleValueChange} disabled={!["Applicable"].includes(homeSafetyData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row m-0 p-0 mt-2">
                                <div className="col-12 m-0 p-0">
                                    <span className="text-success">
                                        Recommendations
                                        <div className="form-check form-check-inline ms-5 ps-5 me-0 pe-0">
                                            <input name="hasRecommendations" className="form-check-input" type="radio" id="hasRecommendationsYes" value="Yes" checked={homeSafetyData?.hasRecommendations === "Yes"} onClick={handleRadioClick} disabled={!["Applicable"].includes(homeSafetyData?.status) || readOnly} />
                                            <label className="form-check-label" htmlFor="hasRecommendationsYes">
                                                Yes
                                            </label>
                                            &nbsp;
                                        </div>
                                        <div className="form-check form-check-inline ms-2 me-0 pe-0">
                                            <input name="hasRecommendations" className="form-check-input" type="radio" id="hasRecommendationsNo" value="No" checked={homeSafetyData?.hasRecommendations === "No"} onClick={handleRadioClick} disabled={!["Applicable"].includes(homeSafetyData?.status) || readOnly} />
                                            <label className="form-check-label" htmlFor="hasRecommendationsNo">
                                                No
                                            </label>
                                        </div>
                                        <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mt-3">
                                    <span className="my-1 d-block"></span>
                                    {readOnly && (
                                        <div className="text-success pb-2">
                                            {homeSafetyData?.status === "Applicable"
                                                ? homeSafetyData?.recommendations?.map((item, index) => {
                                                      if (index === 0) return item;
                                                      else return `, ${item}`;
                                                  }) ?? "-"
                                                : "-"}
                                        </div>
                                    )}
                                    {!readOnly && (
                                        <>
                                            <Select
                                                isMulti
                                                name="recommendations"
                                                id="recommendations"
                                                menuPlacement="bottom"
                                                options={listOfRecommendations}
                                                value={(homeSafetyData?.recommendations ?? []).map((dev) => listOfRecommendations?.find((i) => i.value === dev))}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                onChange={handleRecommendationsChange}
                                                isDisabled={!["Applicable"].includes(homeSafetyData?.status) || homeSafetyData?.hasRecommendations !== "Yes"}
                                            ></Select>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <span className="my-1 d-block">If Other Recommendation (Describe)</span>
                                    {readOnly && <div className="text-success pb-2">{homeSafetyData?.status === "Applicable" ? homeSafetyData?.otherRecommendation ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <input name="otherRecommendation" type="text" className="form-control" placeholder={`Describe`} value={homeSafetyData?.otherRecommendation ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(homeSafetyData?.status) || !homeSafetyData?.recommendations?.includes("Other")} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, homeSafetySection: data?.homeSafetySection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
