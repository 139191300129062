//css
import "./neuromuscularDialog.css";
//Icons
import icon from "../../../../assets/brain-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateNeuromuscularSection } from "../../../../api/nurseAssessment/neuromuscularAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const NeuromuscularDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Neuromuscular data state
    const [neuroMuscularData, setNeuroMuscularData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.neuromuscularSection, status: data?.neuromuscularSection?.status ?? "Applicable" };
        setNeuroMuscularData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: neuroMuscularData?.id, status: neuroMuscularData?.status };
        setNeuroMuscularData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = {
            ...neuroMuscularData,
        };

        //===========================================================

        //Saving NeuromuscularSection
        updateNeuromuscularSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", neuromuscularSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...neuroMuscularData, [name]: value };
        setNeuroMuscularData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...neuroMuscularData, [name]: checked };
        setNeuroMuscularData(newData);
    };

    return (
        <Modal dialogClassName="neuromuscularModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Neuromuscular - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select className="form-select" name="status" onChange={handleFieldChange} value={neuroMuscularData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-3 me-0 pe-0 ">
                            <span className="my-1 d-block">Pupils</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" ? neuroMuscularData?.pupils ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="pupils" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.pupils ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status)}>
                                        <option value="">Select</option>
                                        <option value="PERRLA">PERRLA</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-9">
                            <span className="my-1 d-block">If Other (Describe)</span>
                            {readOnly && <div className="text-success pb-3 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.pupils === "Other" ? neuroMuscularData?.pupilsOtherDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="pupilsOtherDescription" type="text" className="form-control" placeholder={`Description`} value={neuroMuscularData?.pupilsOtherDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(neuroMuscularData?.status) || neuroMuscularData.pupils !== "Other"} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Grasp Right</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" ? `${neuroMuscularData?.graspRight} ${neuroMuscularData?.graspRight && neuroMuscularData?.graspRightLevel ? `(${neuroMuscularData?.graspRightLevel})` : ""}` ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="graspRight" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.graspRight ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Equal">Equal</option>
                                        <option value="Unequal">Unequal</option>
                                        <option value="None">None</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        {!readOnly && (
                            <>
                                <div className="col-3">
                                    <span className="my-1 d-block">&nbsp;</span>
                                    <select name="graspRightLevel" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.graspRightLevel ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status) || !["Equal", "Unequal"].includes(neuroMuscularData?.graspRight)}>
                                        <option value="">Select</option>
                                        <option value="+1">+1</option>
                                        <option value="+2">+2</option>
                                        <option value="+3">+3</option>
                                        <option value="+4">+4</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </div>
                            </>
                        )}

                        <div className="col-3">
                            <span className="my-1 d-block">Grasp Left</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" ? `${neuroMuscularData?.graspLeft} ${neuroMuscularData?.graspLeft && neuroMuscularData?.graspLeftLevel ? `(${neuroMuscularData?.graspLeftLevel})` : ""}` ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="graspLeft" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.graspLeft ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Equal">Equal</option>
                                        <option value="Unequal">Unequal</option>
                                        <option value="None">None</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        {!readOnly && (
                            <>
                                <div className="col-3">
                                    <span className="my-1 d-block">&nbsp;</span>
                                    <select name="graspLeftLevel" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.graspLeftLevel ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status) || !["Equal", "Unequal"].includes(neuroMuscularData?.graspLeft)}>
                                        <option value="">Select</option>
                                        <option value="+1">+1</option>
                                        <option value="+2">+2</option>
                                        <option value="+3">+3</option>
                                        <option value="+4">+4</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Headache</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" ? neuroMuscularData?.headache ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="headache" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.headache ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Acute">Acute</option>
                                        <option value="Chronic">Chronic</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Length of time</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.headache ? neuroMuscularData?.headacheLengthOfTime ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="headacheLengthOfTime" type="text" className="form-control" placeholder={`Length of time`} value={neuroMuscularData?.headacheLengthOfTime ?? ""} onChange={handleFieldChange} maxLength="20" disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.headache} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Relief with</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.headache ? neuroMuscularData?.headacheReliefWith ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="headacheReliefWith" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.headacheReliefWith ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.headache}>
                                        <option value="">Select</option>
                                        <option value="Medication">Medication</option>
                                        <option value="Position">Position</option>
                                        <option value="Rest">Rest</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Worsens With</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.headache ? neuroMuscularData?.headacheWorsensWith ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="headacheWorsensWith" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.headacheWorsensWith ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.headache}>
                                        <option value="">Select</option>
                                        <option value="N/A">N/A</option>
                                        <option value="Activity">Activity</option>
                                        <option value="Light">Light</option>
                                        <option value="Position">Position</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Syncope</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" ? neuroMuscularData?.syncope ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="syncope" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.syncope ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Sitting">Sitting</option>
                                        <option value="Standing">Standing</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Blood Pressure</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" ? neuroMuscularData?.bloodPressure ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="bloodPressure" type="text" className="form-control" placeholder={`Blood Pressure`} value={neuroMuscularData?.bloodPressure ?? ""} onChange={handleFieldChange} maxLength="20" disabled={!["Applicable"].includes(neuroMuscularData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Unsteady Gait</span>
                            {readOnly && <div className="text-success pb-2">{neuroMuscularData?.status === "Applicable" ? neuroMuscularData?.unsteadyGait ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="unsteadyGait" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.unsteadyGait ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Debility">Debility</option>
                                        <option value="Fatigue">Fatigue</option>
                                        <option value="Impulsivity ">Impulsivity</option>
                                        <option value="Weakness">Weakness</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3">
                            <span className="my-2 d-block">&nbsp;</span>
                            <input name="ataxia" id="ataxia" type="checkbox" className="form-check-input" checked={neuroMuscularData?.ataxia ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(neuroMuscularData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="ataxia">
                                Ataxia
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2">
                            <span className="my-2 d-block">&nbsp;</span>
                            <input name="vertigo" id="vertigo" type="checkbox" className="form-check-input" checked={neuroMuscularData?.vertigo ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(neuroMuscularData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="vertigo">
                                Vertigo
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-2">
                            <span className="my-1 d-block">Relief with</span>
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.vertigo ? neuroMuscularData?.vertigoReliefWith ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="vertigoReliefWith" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.vertigoReliefWith ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.vertigo}>
                                        <option value="">Select</option>
                                        <option value="Medication">Medication</option>
                                        <option value="Position">Position</option>
                                        <option value="Rest">Rest</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">&nbsp;</span>
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.vertigo ? neuroMuscularData?.vertigoReliefWithOther ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="vertigoReliefWithOther" type="text" className="form-control" placeholder={`Describe Other`} value={neuroMuscularData?.vertigoReliefWithOther ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.vertigo || !["Other"].includes(neuroMuscularData?.vertigoReliefWith)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-2">
                            <span className="my-1 d-block">Worsens With</span>
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.vertigo ? neuroMuscularData?.vertigoWorsensWith ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="vertigoWorsensWith" className="form-select" onChange={handleFieldChange} value={neuroMuscularData?.vertigoWorsensWith ?? ""} disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.vertigo}>
                                        <option value="">Select</option>
                                        <option value="N/A">N/A</option>
                                        <option value="Activity">Activity</option>
                                        <option value="Position">Position</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">&nbsp;</span>
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.vertigo ? neuroMuscularData?.vertigoWorsensWithOther ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="vertigoWorsensWithOther" type="text" className="form-control" placeholder={`Describe Other`} value={neuroMuscularData?.vertigoWorsensWithOther ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.vertigo || !["Other"].includes(neuroMuscularData?.vertigoWorsensWith)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="decreasedSensation" id="decreasedSensation" type="checkbox" className="form-check-input" checked={neuroMuscularData?.decreasedSensation ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(neuroMuscularData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="decreasedSensation">
                                Decreased Sensation
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-1 pt-2">Location</div>
                        <div className="col-8">
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.decreasedSensation ? neuroMuscularData?.decreasedSensationLocation ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="decreasedSensationLocation" type="text" className="form-control" placeholder={`Location`} value={neuroMuscularData?.decreasedSensationLocation ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.decreasedSensation} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="tremors" id="tremors" type="checkbox" className="form-check-input" checked={neuroMuscularData?.tremors ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(neuroMuscularData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="tremors">
                                Tremors
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-1 pt-2">Location</div>
                        <div className="col-8">
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.tremors ? neuroMuscularData?.tremorsLocation ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="tremorsLocation" type="text" className="form-control" placeholder={`Location`} value={neuroMuscularData?.tremorsLocation ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.tremors} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="numbnessTingling" id="numbnessTingling" type="checkbox" className="form-check-input" checked={neuroMuscularData?.numbnessTingling ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(neuroMuscularData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="numbnessTingling">
                                Numbness/Tingling
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-1 pt-2">Location</div>
                        <div className="col-8">
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.numbnessTingling ? neuroMuscularData?.numbnessTinglingLocation ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="numbnessTinglingLocation" type="text" className="form-control" placeholder={`Location`} value={neuroMuscularData?.numbnessTinglingLocation ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.numbnessTingling} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="weakness" id="weakness" type="checkbox" className="form-check-input" checked={neuroMuscularData?.weakness ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(neuroMuscularData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="weakness">
                                Weakness
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-1 pt-2">Describe</div>
                        <div className="col-8">
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.weakness ? neuroMuscularData?.weaknessDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="weaknessDescription" type="text" className="form-control" placeholder={`Description`} value={neuroMuscularData?.weaknessDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.weakness} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="changeInADL" id="changeInADL" type="checkbox" className="form-check-input" checked={neuroMuscularData?.changeInADL ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(neuroMuscularData?.status)} disabled={readOnly} />
                            <label className="form-check-label ps-2" htmlFor="changeInADL">
                                Change in ADL
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-1 pt-2">Describe</div>
                        <div className="col-8">
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" && neuroMuscularData?.changeInADL ? neuroMuscularData?.changeInADLDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="changeInADLDescription" type="text" className="form-control" placeholder={`Description`} value={neuroMuscularData?.changeInADLDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(neuroMuscularData?.status) || !neuroMuscularData?.changeInADL} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Comments</span>
                            {readOnly && <div className="text-success pb-2 pt-2">{neuroMuscularData?.status === "Applicable" ? neuroMuscularData?.comments ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <textarea name="comments" className="form-control" placeholder={`Comments`} maxLength="200" onChange={handleFieldChange} disabled={!["Applicable"].includes(neuroMuscularData?.status)} value={neuroMuscularData?.comments ?? ""} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, neuromuscularSection: data?.neuromuscularSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
