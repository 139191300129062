import "./card.css";

export const Card = ({ index, title, description, cardClickHandler, icon }) => {
    return (
        <div className="card assessmentCard" onClick={cardClickHandler}>
            <div className="card-body">
                <h5 className="card-title assessmentCardTitle">
                    <div className="text-success" style={{ display: "inline-flex", alignItems: "center" }}>
                        <span>{<img src="/assets/images/prescription-bottle-solid-green.svg" width="36px" height="36px" alt=""></img>}</span>
                        <span className="ps-2 h5">{title}</span>
                    </div>
                </h5>
                <p className="card-text small">{description}</p>
            </div>
        </div>
    );
};
