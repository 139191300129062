//css
import "./physicalDialog.css";

//Icons
import icon from "../../../../assets/clipboard-user-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "react-select";
import { updatePhysicalSection } from "../../../../api/nurseAssessment/physicalAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const PhysicalDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Physical data state
    const [physicalData, setPhysicalData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of head findings
    const listOfHeadFindings = [
        { label: "Alopecia", value: "Alopecia" },
        { label: "Asymmetrical Face Movement", value: "Asymmetrical Face Movement" },
        { label: "Limited ROM of Neck", value: "Limited ROM of Neck" },
        { label: "Mass/Bump on Skull", value: "Mass/Bump on Skull" },
        { label: "Tender Skull", value: "Tender Skull" },
        { label: "Other", value: "Other" },
    ];

    //list of nose findings
    const listOfNoseFindings = [
        { label: "Asymmetrical", value: "Asymmetrical" },
        { label: "Deviated Septum", value: "Deviated Septum" },
        { label: "Loss of Smell", value: "Loss of Smell" },
        { label: "Nose is tender on palpation", value: "Nose is tender on palpation" },
        { label: "Sinuses is tender on palpation", value: "Sinuses is tender on palpation" },
        { label: "Unable to breathe through nostril (Left)", value: "Unable to breathe through nostril (Left)" },
        { label: "Unable to breathe through nostril (Right)", value: "Unable to breathe through nostril (Right)" },
        { label: "Other", value: "Other" },
    ];

    //list of eyes findings
    const listOfEyesFindings = [
        { label: "Asymmetrical", value: "Asymmetrical" },
        { label: "Peri Orbital Edema (Right)", value: "Peri Orbital Edema (Right)" },
        { label: "Peri Orbital Edema (Left)", value: "Peri Orbital Edema (Left)" },
        { label: "Sclera shows evidence of injury", value: "Sclera shows evidence of injury" },
        { label: "Sclera shows evidence of jaundice", value: "Nose is tender on jaundice" },
        { label: "Sclera shows evidence of discoloration", value: "Sclera shows evidence of discoloration" },
        { label: "Pupils are outside normal limits", value: "Pupils are outside normal limits" },
        { label: "Other", value: "Other" },
    ];

    //list of Eyes Conjuctiva findings
    const listOfEyesConjuctivaFindings = [
        { label: "Inflamed", value: "Inflamed" },
        { label: "S/SX of Infection", value: "S/SX of Infection" },
        { label: "Other", value: "Other" },
    ];

    //list of mouth findings
    const listOfMouthFindings = [
        { label: "Bleeding Gums", value: "Bleeding Gums" },
        { label: "Candidiasis (thrush)", value: "Candidiasis (thrush)" },
        { label: "Difficulty Swallowing", value: "Difficulty Swallowing" },
        { label: "Hoarse", value: "Hoarse" },
        { label: "Loss of Taste", value: "Loss of Taste" },
        { label: "Poor Dentition", value: "Poor Dentition" },
        { label: "Other", value: "Other" },
    ];

    //list of mouth dentures findings
    const listOfMouthDentures = [
        { label: "Bottom", value: "Bottom" },
        { label: "Fit Poorly", value: "Fit Poorly" },
        { label: "Fit Well", value: "Fit Well" },
        { label: "Full", value: "Full" },
        { label: "Not Worn", value: "Not Worn" },
        { label: "Partial", value: "Partial" },
        { label: "Top", value: "Top" },
    ];

    //list of mouth lesions
    const listOfMouthLesions = [
        { label: "Lips", value: "Lips" },
        { label: "Mouth", value: "Mouth" },
        { label: "Tongue", value: "Tongue" },
        { label: "Other", value: "Other" },
    ];

    //list of ears findings
    const listOfEarsFindings = [
        { label: "Deaf (Left)", value: "Deaf (Left)" },
        { label: "Deaf (Right)", value: "Deaf (Right)" },
        { label: "Hard of Hearing (Left)", value: "Hard of Hearing (Left)" },
        { label: "Hard of Hearing (Right)", value: "Hard of Hearing (Right)" },
        { label: "Hearing Aide (Left)", value: "Hearing Aide (Left)" },
        { label: "Hearing Aide (Right)", value: "Hearing Aide (Right)" },
        { label: "Lesions", value: "Lesions" },
        { label: "Tender to Touch (Left)", value: "Tender to Touch (Left)" },
        { label: "Tender to Touch (Right)", value: "Tender to Touch (Right)" },
        { label: "Tinnitus (Left)", value: "Tinnitus (Left)" },
        { label: "Tinnitus (Right)", value: "Tinnitus (Right)" },
        { label: "Other", value: "Other" },
    ];

    //list of sides
    const listLeftRight = [
        { label: "Left", value: "Left" },
        { label: "Right", value: "Right" },
    ];

    //list of Breasts Axilla findings
    const listOfBreastsAxillaFindings = [
        { label: "Cystic Breasts", value: "Cystic Breasts" },
        { label: "Discharge", value: "Discharge" },
        { label: "Enlarged axillary lymph node", value: "Enlarged axillary lymph node" },
        { label: "Lesions", value: "Lesions" },
        { label: "Lump/Nodes in breast", value: "Lump/Nodes in breast" },
        { label: "Lumpectomy", value: "Lumpectomy" },
        { label: "Mastectomy (Right)", value: "Mastectomy (Right)" },
        { label: "Mastectomy (Left)", value: "Mastectomy (Left)" },
        { label: "Lesions", value: "Lesions" },
        { label: "Other", value: "Other" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.physicalSection, status: data?.physicalSection?.status ?? "Applicable" };
        setPhysicalData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: physicalData?.id, status: physicalData?.status };
        setPhysicalData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...physicalData };

        //===========================================================

        //Saving WoundCareSection
        updatePhysicalSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", physicalSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...physicalData, [name]: value };
        setPhysicalData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...physicalData, [name]: checked };
        setPhysicalData(newData);
    };

    const handleRadioClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...physicalData, [name]: value };
        setPhysicalData(newData);
    };

    const handleHeadFindingsChange = (e) => {
        let headFindingsNew = e?.map((i) => i.value);
        const newData = { ...physicalData, headFindings: headFindingsNew };
        setPhysicalData(newData);
    };

    const handleNoseFindingsChange = (e) => {
        let noseFindingsNew = e?.map((i) => i.value);
        const newData = { ...physicalData, noseFindings: noseFindingsNew };
        setPhysicalData(newData);
    };

    const handleEyesFindingsChange = (e) => {
        let eyesFindingsNew = e?.map((i) => i.value);
        const newData = { ...physicalData, eyesFindings: eyesFindingsNew };
        setPhysicalData(newData);
    };

    const handleEyesConjuctivaFindingsChange = (e) => {
        let eyesConjuctivaFindingsNew = e?.map((i) => i.value);
        const newData = { ...physicalData, eyesConjuctivaFindings: eyesConjuctivaFindingsNew };
        setPhysicalData(newData);
    };

    const handleMouthFindingsChange = (e) => {
        let mouthFindingsNew = e?.map((i) => i.value);
        const newData = { ...physicalData, mouthFindings: mouthFindingsNew };
        setPhysicalData(newData);
    };

    const handleMouthDenturesChange = (e) => {
        let mouthDenturesNew = e?.map((i) => i.value);
        const newData = { ...physicalData, mouthDentures: mouthDenturesNew };
        setPhysicalData(newData);
    };

    const handleMouthLesionsChange = (e) => {
        let mouthLesionsNew = e?.map((i) => i.value);
        const newData = { ...physicalData, mouthLesions: mouthLesionsNew };
        setPhysicalData(newData);
    };

    const handleEarsFindingsChange = (e) => {
        let earsFindingsNew = e?.map((i) => i.value);
        const newData = { ...physicalData, earsFindings: earsFindingsNew };
        setPhysicalData(newData);
    };

    const handleEarsHearingAideGoodWorkingOrderChange = (e) => {
        let earsHearingAideGoodWorkingOrderNew = e?.map((i) => i.value);
        const newData = { ...physicalData, earsHearingAideGoodWorkingOrder: earsHearingAideGoodWorkingOrderNew };
        setPhysicalData(newData);
    };

    const handleEarsHearingAideNeedRepairChange = (e) => {
        let earsHearingAideNeedRepairNew = e?.map((i) => i.value);
        const newData = { ...physicalData, earsHearingAideNeedRepair: earsHearingAideNeedRepairNew };
        setPhysicalData(newData);
    };

    const handleBreastsAxillaFindingsChange = (e) => {
        let breastsAxillaFindingsNew = e?.map((i) => i.value);
        const newData = { ...physicalData, breastsAxillaFindings: breastsAxillaFindingsNew };
        setPhysicalData(newData);
    };

    return (
        <Modal dialogClassName="physicalSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Physical - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{physicalData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={physicalData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                General Health <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12">
                            <input name="pastYearStayedOvernightHospitalOrSkilledNursingFacility" id="pastYearStayedOvernightHospitalOrSkilledNursingFacility" type="checkbox" className="form-check-input" checked={physicalData?.pastYearStayedOvernightHospitalOrSkilledNursingFacility ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(physicalData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="pastYearStayedOvernightHospitalOrSkilledNursingFacility">
                                In the past year, have you stayed overnight in a hospital or skilled nursing facility?
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 mb-2 d-block">In the past year, how many times did you visit a physician?</span>
                            {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.visitsPhysician ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="visitsPhysician" className="form-select" onChange={handleFieldChange} value={physicalData?.visitsPhysician ?? ""} disabled={!["Applicable"].includes(physicalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2-3">2-3</option>
                                        <option value="4-6">4-6</option>
                                        <option value="Over 6">Over 6</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <Tabs defaultActiveKey="head" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="head" title="Head and Neck">
                            <div className="row m-0 p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 mb-2 d-block">Status</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.headStatus ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <select name="headStatus" className="form-select" onChange={handleFieldChange} value={physicalData?.headStatus ?? ""} disabled={!["Applicable"].includes(physicalData?.status)}>
                                                    <option value="">Select</option>
                                                    <option value="Not Assessed">Not Assessed</option>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Assessed with findings">Assessed with findings</option>
                                                </select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Findings</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable" && physicalData?.headStatus === "Assessed with findings"
                                                    ? physicalData?.headFindings?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="headFindings"
                                                    id="headFindings"
                                                    menuPlacement="bottom"
                                                    options={listOfHeadFindings}
                                                    //value={listOfHeadFindings.value}
                                                    //defaultValue={physicalData?.headFindings?.map((dev) => listOfHeadFindings?.find((i) => i.value === dev))}
                                                    value={(physicalData?.headFindings ?? []).map((dev) => listOfHeadFindings?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleHeadFindingsChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.headStatus)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Finding</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.headFindingOther ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="headFindingOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.headFindingOther ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.headFindings?.includes("Other") || !["Assessed with findings"].includes(physicalData?.headStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <label className="my-1 d-block" htmlFor="comments">
                                            Hx/Comments
                                        </label>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.headComments ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <textarea name="headComments" id="headComments" className="form-control" placeholder={`Comments`} value={physicalData?.headComments ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(physicalData?.status)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="nose" title="Nose">
                            <div className="row m-0 p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 mb-2 d-block">Status</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.noseStatus ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <select name="noseStatus" className="form-select" onChange={handleFieldChange} value={physicalData?.noseStatus ?? ""} disabled={!["Applicable"].includes(physicalData?.status)}>
                                                    <option value="">Select</option>
                                                    <option value="Not Assessed">Not Assessed</option>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Assessed with findings">Assessed with findings</option>
                                                </select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Findings</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable" && physicalData?.noseStatus === "Assessed with findings"
                                                    ? physicalData?.noseFindings?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="noseFindings"
                                                    id="noseFindings"
                                                    menuPlacement="bottom"
                                                    options={listOfNoseFindings}
                                                    //value={listOfNoseFindings.value}
                                                    //defaultValue={physicalData?.noseFindings?.map((dev) => listOfNoseFindings?.find((i) => i.value === dev))}
                                                    value={(physicalData?.noseFindings ?? []).map((dev) => listOfNoseFindings?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleNoseFindingsChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.noseStatus)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Finding</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.noseFindingOther ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="noseFindingOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.noseFindingOther ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.noseFindings?.includes("Other") || !["Assessed with findings"].includes(physicalData?.noseStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <label className="my-1 d-block" htmlFor="comments">
                                            Hx/Comments
                                        </label>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.noseComments ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <textarea name="noseComments" id="headComments" className="form-control" placeholder={`Comments`} value={physicalData?.noseComments ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(physicalData?.status)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="eyes" title="Eyes">
                            <div className="row m-0 p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 mb-2 d-block">Status</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.eyesStatus ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <select name="eyesStatus" className="form-select" onChange={handleFieldChange} value={physicalData?.eyesStatus ?? ""} disabled={!["Applicable"].includes(physicalData?.status)}>
                                                    <option value="">Select</option>
                                                    <option value="Not Assessed">Not Assessed</option>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Assessed with findings">Assessed with findings</option>
                                                </select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Findings</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable" && physicalData?.eyesStatus === "Assessed with findings"
                                                    ? physicalData?.eyesFindings?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="eyesFindings"
                                                    id="eyesFindings"
                                                    menuPlacement="bottom"
                                                    options={listOfEyesFindings}
                                                    //value={listOfEyesFindings.value}
                                                    //defaultValue={physicalData?.eyesFindings?.map((dev) => listOfEyesFindings?.find((i) => i.value === dev))}
                                                    value={(physicalData?.eyesFindings ?? []).map((dev) => listOfEyesFindings?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleEyesFindingsChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.eyesStatus)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Finding</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.eyesFindingOther ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="eyesFindingOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.eyesFindingOther ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.eyesFindings?.includes("Other") || !["Assessed with findings"].includes(physicalData?.eyesStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Conjuctiva Findings</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable"
                                                    ? physicalData?.eyesConjuctivaFindings?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="eyesConjuctivaFindings"
                                                    id="eyesConjuctivaFindings"
                                                    menuPlacement="bottom"
                                                    options={listOfEyesConjuctivaFindings}
                                                    //value={listOfEyesConjuctivaFindings.value}
                                                    //defaultValue={physicalData?.eyesConjuctivaFindings?.map((dev) => listOfEyesConjuctivaFindings?.find((i) => i.value === dev))}
                                                    value={(physicalData?.eyesConjuctivaFindings ?? []).map((dev) => listOfEyesConjuctivaFindings?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleEyesConjuctivaFindingsChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.eyesStatus)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Finding</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.eyesConjuctivaFindingOther ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="eyesConjuctivaFindingOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.eyesConjuctivaFindingOther ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.eyesConjuctivaFindings?.includes("Other") || !["Assessed with findings"].includes(physicalData?.eyesStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 d-block">if Pupils are outside normal limits, describe</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.eyesPupilsOutsideNormalLimitsDescription ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="eyesPupilsOutsideNormalLimitsDescription" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.eyesPupilsOutsideNormalLimitsDescription ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.eyesFindings?.includes("Pupils are outside normal limits") || !["Assessed with findings"].includes(physicalData?.eyesStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <label className="my-1 d-block" htmlFor="eyesComments">
                                            Hx/Comments
                                        </label>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.eyesComments ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <textarea name="eyesComments" id="eyesComments" className="form-control" placeholder={`Comments`} value={physicalData?.eyesComments ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(physicalData?.status)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="mouth" title="Mouth">
                            <div className="row m-0 p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 mb-2 d-block">Status</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.mouthStatus ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <select name="mouthStatus" className="form-select" onChange={handleFieldChange} value={physicalData?.mouthStatus ?? ""} disabled={!["Applicable"].includes(physicalData?.status)}>
                                                    <option value="">Select</option>
                                                    <option value="Not Assessed">Not Assessed</option>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Assessed with findings">Assessed with findings</option>
                                                </select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Findings</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable" && physicalData?.mouthStatus === "Assessed with findings"
                                                    ? physicalData?.mouthFindings?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="mouthFindings"
                                                    id="mouthFindings"
                                                    menuPlacement="bottom"
                                                    options={listOfMouthFindings}
                                                    //value={listOfMouthFindings.value}
                                                    //defaultValue={physicalData?.mouthFindings?.map((dev) => listOfMouthFindings?.find((i) => i.value === dev))}
                                                    value={(physicalData?.mouthFindings ?? []).map((dev) => listOfMouthFindings?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleMouthFindingsChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.mouthStatus)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Finding</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.mouthFindingOther ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="mouthFindingOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.mouthFindingOther ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.mouthFindings?.includes("Other") || !["Assessed with findings"].includes(physicalData?.mouthStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 d-block">Dentures</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable"
                                                    ? physicalData?.mouthDentures?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="mouthDentures"
                                                    id="mouthDentures"
                                                    menuPlacement="bottom"
                                                    options={listOfMouthDentures}
                                                    //value={listOfMouthDentures.value}
                                                    //defaultValue={physicalData?.mouthDentures?.map((dev) => listOfMouthDentures?.find((i) => i.value === dev))}
                                                    value={(physicalData?.mouthDentures ?? []).map((dev) => listOfMouthDentures?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleMouthDenturesChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Lesions</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable"
                                                    ? physicalData?.mouthLesions?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="mouthLesions"
                                                    id="mouthLesions"
                                                    menuPlacement="bottom"
                                                    options={listOfMouthLesions}
                                                    //value={listOfMouthLesions.value}
                                                    //defaultValue={physicalData?.mouthLesions?.map((dev) => listOfMouthLesions?.find((i) => i.value === dev))}
                                                    value={(physicalData?.mouthLesions ?? []).map((dev) => listOfMouthLesions?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleMouthLesionsChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.mouthStatus)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Lesions</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.mouthLesionsOther ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="mouthLesionsOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.mouthLesionsOther ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.mouthLesions?.includes("Other") || !["Assessed with findings"].includes(physicalData?.mouthStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <label className="my-1 d-block" htmlFor="mouthComments">
                                            Hx/Comments
                                        </label>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.mouthComments ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <textarea name="mouthComments" id="mouthComments" className="form-control" placeholder={`Comments`} value={physicalData?.mouthComments ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(physicalData?.status)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="ears" title="Ears">
                            <div className="row m-0 p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 mb-2 d-block">Status</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.earsStatus ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <select name="earsStatus" className="form-select" onChange={handleFieldChange} value={physicalData?.earsStatus ?? ""} disabled={!["Applicable"].includes(physicalData?.status)}>
                                                    <option value="">Select</option>
                                                    <option value="Not Assessed">Not Assessed</option>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Assessed with findings">Assessed with findings</option>
                                                </select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Findings</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable" && physicalData.earsStatus === "Assessed with findings"
                                                    ? physicalData?.earsFindings?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="earsFindings"
                                                    id="earsFindings"
                                                    menuPlacement="bottom"
                                                    options={listOfEarsFindings}
                                                    //value={listOfEarsFindings.value}
                                                    //defaultValue={physicalData?.earsFindings?.map((dev) => listOfEarsFindings?.find((i) => i.value === dev))}
                                                    value={(physicalData?.earsFindings ?? []).map((dev) => listOfEarsFindings?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleEarsFindingsChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.earsStatus)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Finding</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.earsFindingOther ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="earsFindingOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.earsFindingOther ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.earsFindings?.includes("Other") || !["Assessed with findings"].includes(physicalData?.earsStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <span className="my-1 d-block">Hearing Aide in Good Working Order</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable"
                                                    ? physicalData?.earsHearingAideGoodWorkingOrder?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="earsHearingAideGoodWorkingOrder"
                                                    id="earsHearingAideGoodWorkingOrder"
                                                    menuPlacement="bottom"
                                                    options={listLeftRight}
                                                    //value={listLeftRight.value}
                                                    //defaultValue={physicalData?.earsHearingAideGoodWorkingOrder?.map((dev) => listLeftRight?.find((i) => i.value === dev))}
                                                    value={(physicalData?.earsHearingAideGoodWorkingOrder ?? []).map((dev) => listLeftRight?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleEarsHearingAideGoodWorkingOrderChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.earsStatus) || !(physicalData?.earsFindings?.includes("Hearing Aide (Left)") || physicalData?.earsFindings?.includes("Hearing Aide (Right)"))}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-6">
                                        <span className="my-1 d-block">Hearing Aide in Need of Repair</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable"
                                                    ? physicalData?.earsHearingAideNeedRepair?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="earsHearingAideNeedRepair"
                                                    id="earsHearingAideNeedRepair"
                                                    menuPlacement="bottom"
                                                    options={listLeftRight}
                                                    //value={listLeftRight.value}
                                                    //defaultValue={physicalData?.earsHearingAideNeedRepair?.map((dev) => listLeftRight?.find((i) => i.value === dev))}
                                                    value={(physicalData?.earsHearingAideNeedRepair ?? []).map((dev) => listLeftRight?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleEarsHearingAideNeedRepairChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.earsStatus) || !(physicalData?.earsFindings?.includes("Hearing Aide (Left)") || physicalData?.earsFindings?.includes("Hearing Aide (Right)"))}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 d-block">if Lesions, describe</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.earsLesionsDescription ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="earsLesionsDescription" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.earsLesionsDescription ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.earsFindings?.includes("Lesions") || !["Assessed with findings"].includes(physicalData?.earsStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <label className="my-1 d-block" htmlFor="earsComments">
                                            Hx/Comments
                                        </label>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.earsComments ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <textarea name="earsComments" id="earsComments" className="form-control" placeholder={`Comments`} value={physicalData?.earsComments ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(physicalData?.status)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="breastsAxilla" title="Breasts/Axilla">
                            <div className="row m-0 p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 mb-2 d-block">Status</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.breastsAxillaStatus ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <select name="breastsAxillaStatus" className="form-select" onChange={handleFieldChange} value={physicalData?.breastsAxillaStatus ?? ""} disabled={!["Applicable"].includes(physicalData?.status)}>
                                                    <option value="">Select</option>
                                                    <option value="Not Assessed">Not Assessed</option>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Assessed with findings">Assessed with findings</option>
                                                </select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-9">
                                        <span className="my-1 d-block">Findings</span>
                                        {readOnly && (
                                            <div className="text-success pb-2">
                                                {physicalData?.status === "Applicable" && physicalData.earsStatus === "Assessed with findings"
                                                    ? physicalData?.breastsAxillaFindings?.map((item, index) => {
                                                          if (index === 0) return item;
                                                          else return `, ${item}`;
                                                      }) ?? "-"
                                                    : "-"}
                                            </div>
                                        )}
                                        {!readOnly && (
                                            <>
                                                <Select
                                                    isMulti
                                                    name="breastsAxillaFindings"
                                                    id="breastsAxillaFindings"
                                                    menuPlacement="bottom"
                                                    options={listOfBreastsAxillaFindings}
                                                    //value={listOfEarsFindings.value}
                                                    //defaultValue={physicalData?.earsFindings?.map((dev) => listOfEarsFindings?.find((i) => i.value === dev))}
                                                    value={(physicalData?.breastsAxillaFindings ?? []).map((dev) => listOfBreastsAxillaFindings?.find((i) => i.value === dev))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={handleBreastsAxillaFindingsChange}
                                                    isDisabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.breastsAxillaStatus)}
                                                ></Select>
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Other Finding</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.breastsAxillaFindingOther ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="text" className="form-control" name="breastsAxillaFindingOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={physicalData?.breastsAxillaFindingOther ?? ""} maxLength="50" disabled={!["Applicable"].includes(physicalData?.status) || !physicalData?.breastsAxillaFindings?.includes("Other") || !["Assessed with findings"].includes(physicalData?.breastsAxillaStatus)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <span className="my-1 d-block">if Mastectomy, when</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.breastsAxillaMastectomyDate?.split("T")[0] ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="breastsAxillaMastectomyDate"
                                                    onChange={handleFieldChange}
                                                    value={physicalData?.breastsAxillaMastectomyDate?.split("T")[0] ?? ""}
                                                    disabled={!["Applicable"].includes(physicalData?.status) || !(physicalData?.breastsAxillaFindings?.includes("Mastectomy (Right)") || physicalData?.breastsAxillaFindings?.includes("Mastectomy (Left)")) || !["Assessed with findings"].includes(physicalData?.breastsAxillaStatus)}
                                                />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-6">
                                        <span className="my-1 d-block">if Lumpectomy, when</span>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.breastsAxillaLumpectomyDate?.split("T")[0] ?? "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <input type="date" className="form-control" name="breastsAxillaLumpectomyDate" onChange={handleFieldChange} value={physicalData?.breastsAxillaLumpectomyDate?.split("T")[0] ?? ""} disabled={!["Applicable"].includes(physicalData?.status) || !["Assessed with findings"].includes(physicalData?.breastsAxillaStatus) || !physicalData?.breastsAxillaFindings?.includes("Lumpectomy")} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <label className="my-1 d-block" htmlFor="breastsAxillaComments">
                                            Hx/Comments
                                        </label>
                                        {readOnly && <div className="text-success pb-2">{physicalData?.status === "Applicable" ? physicalData?.breastsAxillaComments ?? "-" : "-"}</div>}
                                        {!readOnly && (
                                            <>
                                                <textarea name="breastsAxillaComments" id="breastsAxillaComments" className="form-control" placeholder={`Comments`} value={physicalData?.breastsAxillaComments ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(physicalData?.status)} />
                                                <div className="d-block text-danger small text-end">&nbsp;</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, physicalSection: data?.physicalSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
