import React from "react";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { ErrorMessage } from "../errorMessage/errorMessage";

export const ModalDialogFooter = ({ operationError, saving, handleClose, handleSave, handleReset, children, showReset = true, disableSave = false, showPrint = false, readOnly = false }) => {
    let componentRef = useRef();

    return (
        <>
            {operationError && <ErrorMessage msg={operationError} size="small" />}
            {showPrint && (
                <>
                    <div>
                        {/* button to trigger printing of target component */}
                        <ReactToPrint trigger={() => <button className="btn btn-outline-secondary">Print</button>} content={() => componentRef} />
                        {/* component to be printed */}
                        <div style={{ display: "none" }}>
                            <div ref={(el) => (componentRef = el)}>{children}</div>
                        </div>
                    </div>
                </>
            )}
            {!readOnly && (
                <button type="button" className="btn btn-success" disabled={disableSave || saving} onClick={handleSave}>
                    {!saving && "Save Changes"}
                    {saving && (
                        <span className="ps-2">
                            <span className="pe-2">Saving...</span>
                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                        </span>
                    )}
                </button>
            )}

            {!readOnly && showReset && (
                <button type="button" className="btn btn-outline-secondary" disabled={disableSave || saving} onClick={handleReset}>
                    Reset
                </button>
            )}
            <button type="button" className="btn btn-outline-secondary" disabled={saving} onClick={handleClose}>
                Close
            </button>
        </>
    );
};
