//Icons
import iconFallRiskGreen from "../../../assets/person-falling-burst-solid-green.svg";
import iconFallRiskRed from "../../../assets/person-falling-burst-solid-red.svg";

import { updateFallRiskSection } from "../../../api/nurseAssessment/fallRiskAPI";
import { isError } from "../../../lib/isError";

export const getFallRiskSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowFallRiskDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateFallRiskSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving FallRisk Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.fallRiskSection, status: status };
        const saveObj = { id: patient?.fallRiskSection?.id, status: status };
        updateFallRiskSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", fallRiskSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Fall Risk",
        description: null,
        progress: data?.fallRiskSection?.progress,
        status: data?.fallRiskSection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconFallRiskRed,
        iconCompleted: iconFallRiskGreen,
        saving: false,
        onClick: () => {
            setShowFallRiskDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateFallRiskSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateFallRiskSectionStatus(notApplicable);
            }
        },
    };
};
