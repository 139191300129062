import { useState, useEffect } from "react";
import "./errorMessage.css";

export const ErrorMessage = ({ msg, size }) => {
    const [messageClass, setMessageClass] = useState(null);

    useEffect(() => {
        if (size === "small") setMessageClass(`alert alert-danger d-flex align-items-center py-1 mb-0 alert-dismissible ${msg ? "fadeIn" : "fadeOut"}`);
        else setMessageClass(`alert alert-danger d-flex align-items-center py-1 mt-3 alert-dismissible ${msg ? "fadeIn" : "fadeOut"}`);
    }, [msg]);

    return (
        <span className={messageClass} role="alert">
            {size === "small" && (
                <>
                    <i className={"fa fa-exclamation-triangle fa-1x me-3"}></i>
                    <span>
                        <small>{msg}</small>
                    </span>
                </>
            )}
            {size !== "small" && (
                <>
                    <i className={"fa fa-exclamation-triangle fa-2x me-3"}></i>
                    <span>{msg}</span>
                </>
            )}
        </span>
    );
};
