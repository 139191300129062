export const depressionScreenAnswerDescription = (value) => {
    switch (value) {
        case 0:
            return "Not at all";
            break;

        case 1:
            return "Several days";
            break;

        case 2:
            return "More than half the days";
            break;

        case 3:
            return "Nearly every day";
            break;

        default:
            return "";
    }
};
