//css
import "./depressionScreenDialog.css";

//Icons
import icon from "../../../../assets/pen-ruler-solid-green.svg";

import { useEffect, useState } from "react";
import { Badge, Modal, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";

import { updateDepressionScreenSection } from "../../../../api/nurseAssessment/depressionScreenAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { ScoreDepressionScreen } from "../../../../components/scoreDepressionScreen/scoreDepressionScreen";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";
import { depressionScreenAnswerDescription } from "../../../../lib/depressionScreenAnswerDescription";

export const DepressionScreenDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Depression Screen data state
    const [depressionScreenData, setDepressionScreenData] = useState(null);

    //Depression Screen score  state
    const [score, setScore] = useState(10);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.depressionScreenSection, status: data?.depressionScreenSection?.status ?? "Applicable" };
        setDepressionScreenData(sectionData);
    }, [data]);

    useEffect(() => {
        let score =
            0 +
            Number(depressionScreenData?.littleInterestPleasureDoingThings || 0) +
            Number(depressionScreenData?.feelingDownDepressedHopeless || 0) +
            Number(depressionScreenData?.troubleFallingOrStayingAsleepOrSleepingTooMuch || 0) +
            Number(depressionScreenData?.feelingTiredOrHavingLittleEnergy || 0) +
            Number(depressionScreenData?.poorAppetiteOrOvereating || 0) +
            Number(depressionScreenData?.feelingBadAboutYourselfOrYouAreAFailureOrHaveLetYourselfOrYourFamilyDown || 0) +
            Number(depressionScreenData?.troubleConcentrating || 0) +
            Number(depressionScreenData?.movingOrSpeakingSoSlowlyOtherCouldHaveNoticedOrTheOpposite || 0) +
            Number(depressionScreenData?.thoughtsYouWouldBeBetterDeadOrHurtingYourself || 0);

        setScore(score);
    }, [depressionScreenData]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: depressionScreenData?.id, status: depressionScreenData?.status };
        setDepressionScreenData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...depressionScreenData };

        //===========================================================

        //Saving WoundCareSection
        updateDepressionScreenSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", depressionScreenSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...depressionScreenData, [name]: value };
        setDepressionScreenData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...depressionScreenData, [name]: checked };
        setDepressionScreenData(newData);
    };

    //=== Render ============================================================

    return (
        <Modal dialogClassName="depressionScreenSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Depression Screen - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{depressionScreenData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <input name="unableToComplete" id="unableToComplete" type="checkbox" className="form-check-input" checked={depressionScreenData?.unableToComplete ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(depressionScreenData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="unableToComplete">
                                Unable to complete due to cognitive deficits
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-9 m-0 p-0">
                            <span className="text-success">Over the last 2 weeks, how often have you been bothered by any of the following problems?</span>
                        </div>
                        <div className="col-3 text-end">
                            <ScoreDepressionScreen score={score} />
                        </div>

                        <hr className="my-1" />
                    </div>

                    <div className="row small">
                        <div className="col-6">
                            <span className="my-2 d-block">1. Little interest or pleasure in doing things</span>
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.littleInterestPleasureDoingThings) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="littleInterestPleasureDoingThings" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.littleInterestPleasureDoingThings ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <span className="my-2 d-block">2. Feeling down, depressed or hopeless</span>
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.feelingDownDepressedHopeless) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="feelingDownDepressedHopeless" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.feelingDownDepressedHopeless ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row small">
                        <div className="col-6">
                            <span className="my-2 d-block">3. Trouble falling or staying asleep, or sleeping too much</span>
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.troubleFallingOrStayingAsleepOrSleepingTooMuch) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="troubleFallingOrStayingAsleepOrSleepingTooMuch" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.troubleFallingOrStayingAsleepOrSleepingTooMuch ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <span className="my-2 d-block">4. Feeling tired or having little energy</span>
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.feelingTiredOrHavingLittleEnergy) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="feelingTiredOrHavingLittleEnergy" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.feelingTiredOrHavingLittleEnergy ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row small">
                        <div className="col-6">
                            <span className="my-2 d-block">5. Poor appetite or overeating</span>
                        </div>

                        <div className="col-6">
                            <span className="my-2 d-block">6. Feeling bad about yourself or that you are a failure or have let yourself or your family down</span>
                        </div>
                    </div>

                    <div className="row small">
                        <div className="col-6">
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.poorAppetiteOrOvereating) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="poorAppetiteOrOvereating" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.poorAppetiteOrOvereating ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.feelingBadAboutYourselfOrYouAreAFailureOrHaveLetYourselfOrYourFamilyDown) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="feelingBadAboutYourselfOrYouAreAFailureOrHaveLetYourselfOrYourFamilyDown" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.feelingBadAboutYourselfOrYouAreAFailureOrHaveLetYourselfOrYourFamilyDown ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row small">
                        <div className="col-6">
                            <span className="my-2 d-block">7. Trouble concentrating on things, such as reading the newspaper or watching TV</span>
                        </div>

                        <div className="col-6">
                            <span className="my-2 d-block">8. Moving or speaking so slowly that other could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual</span>
                        </div>
                    </div>

                    <div className="row small">
                        <div className="col-6">
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.troubleConcentrating) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="troubleConcentrating" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.troubleConcentrating ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.movingOrSpeakingSoSlowlyOtherCouldHaveNoticedOrTheOpposite) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="movingOrSpeakingSoSlowlyOtherCouldHaveNoticedOrTheOpposite" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.movingOrSpeakingSoSlowlyOtherCouldHaveNoticedOrTheOpposite ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row small">
                        <div className="col-6">
                            <span className="my-2 d-block">9. Thoughts that you would be better off dead, or of hurting yourself</span>
                        </div>
                    </div>

                    <div className="row small">
                        <div className="col-6">
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenAnswerDescription(depressionScreenData?.thoughtsYouWouldBeBetterDeadOrHurtingYourself) ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="thoughtsYouWouldBeBetterDeadOrHurtingYourself" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.thoughtsYouWouldBeBetterDeadOrHurtingYourself ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="0">Not at all</option>
                                        <option value="1">Several days</option>
                                        <option value="2">More than half the days</option>
                                        <option value="3">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <hr />

                    <div className="row small">
                        <div className="col-12">
                            <span className="my-2 d-block">If you selected any problems, how difficult have these problems made it for to do your work, take care of things at home, or get along with others?</span>
                        </div>
                    </div>

                    <div className="row small">
                        <div className="col-12">
                            {readOnly && <div className="text-success pb-2">{depressionScreenData?.status === "Applicable" ? depressionScreenData?.ifSelectedAnyProblemsHowDifficultToDoYourWork ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="ifSelectedAnyProblemsHowDifficultToDoYourWork" className="form-select" onChange={handleFieldChange} value={depressionScreenData?.ifSelectedAnyProblemsHowDifficultToDoYourWork ?? ""} disabled={depressionScreenData?.unableToComplete}>
                                        <option value="">Select</option>
                                        <option value="Not at all">Not at all</option>
                                        <option value="Several days">Several days</option>
                                        <option value="More than half the days">More than half the days</option>
                                        <option value="Nearly every day">Nearly every day</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, depressionScreenSection: data?.depressionScreenSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
