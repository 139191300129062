import { acquireAccessToken, acquireMSALTokens } from "../lib/acquireAccessToken";
import { fileTimeStamp } from "../lib/fileTimeStamp";
import { Http_BadRequest, Http_OK } from "../lib/httpStatusCodes";
import { parseJwt } from "../lib/parseJwt";
import { sortJson } from "../lib/sortJson";
import { headers } from "./headers";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}ClientMAR`;

//=======================================================================

export const getClientMARById = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientMARByClientAndPeriod = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;
    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/GetByClientAndPeriod/${data.clientId}/${data.month}/${data.year}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientMARAuditLog = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/AuditLog/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const createClientMAR = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;
    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const addMedicationClientMAR = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;
    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/addMedication`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const removeMedicationClientMAR = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;
    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/removeMedication`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const updateMedicationClientMAR = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/updateMedication`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getMARReport = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/Report/${data?.id}`, {
            method: "GET",
            headers: headers,
            //body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            rej("Bad Request Error: Failed to download");
                            break;

                        case Http_OK:
                            return res(response.blob());
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((blob) => {
                //1. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                console.log("data", data);
                link.setAttribute("download", `ClientMAR_${data?.patientName}_${fileTimeStamp()}.pdf`);
                link.href = url;

                //2. Append to html page
                document.body.appendChild(link);

                //3. Force download
                link.click();

                //4. Clean up and remove link
                link.parentNode.removeChild(link);

                resolve();
            })
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================
