//css
import "./responseTeachingDialog.css";

//Icons
import icon from "../../../../assets/person-chalkboard-solid-green.svg";

import Select from "react-select";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateResponseTeachingSection } from "../../../../api/nurseAssessment/responseTeachingAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const ResponseTeachingDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Response Teaching data state
    const [responseTeachingData, setResponseTeachingData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of who received teaching
    const listOfWhoReceivedTeaching = [
        { label: "Caregiver", value: "Caregiver" },
        { label: "Client", value: "Client" },
        { label: "Family", value: "Family" },
        { label: "Responsible Party", value: "Responsible Party" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.responseTeachingSection, status: data?.responseTeachingSection?.status ?? "Applicable" };
        setResponseTeachingData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleWhoReceivedTeachingChange = (e) => {
        let whoReceivedTeachingNew = e?.map((i) => i.value);
        const newData = { ...responseTeachingData, whoReceivedTeaching: whoReceivedTeachingNew };
        setResponseTeachingData(newData);
    };

    const handleReset = () => {
        const newData = { id: responseTeachingData?.id, status: responseTeachingData?.status };
        setResponseTeachingData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...responseTeachingData };

        //===========================================================
        //Saving Response Teaching Section
        updateResponseTeachingSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", responseTeachingSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...responseTeachingData, [name]: value };
        setResponseTeachingData(newData);
    };

    const handleRadioClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...responseTeachingData, [name]: value };
        setResponseTeachingData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...responseTeachingData, [name]: checked };
        setResponseTeachingData(newData);
    };

    return (
        <Modal dialogClassName="responseTeachingModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Response Teaching - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{responseTeachingData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={responseTeachingData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Who received teaching</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {responseTeachingData?.status === "Applicable"
                                        ? responseTeachingData?.whoReceivedTeaching?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="whoReceivedTeaching"
                                        id="whoReceivedTeaching"
                                        menuPlacement="bottom"
                                        options={listOfWhoReceivedTeaching}
                                        value={(responseTeachingData?.whoReceivedTeaching ?? []).map((dev) => listOfWhoReceivedTeaching?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleWhoReceivedTeachingChange}
                                        isDisabled={!["Applicable"].includes(responseTeachingData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8 me-0 pe-0 ">
                            <span className="my-1 mb-3 d-block">Understanding</span>
                            {readOnly && <div className="text-success pb-2">{responseTeachingData?.status === "Applicable" ? responseTeachingData?.understanding ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="understanding" id="understandingVerbalizes" value="Verbalizes Understanding" onChange={handleFieldChange} disabled={!["Applicable"].includes(responseTeachingData?.status)} checked={responseTeachingData?.understanding === "Verbalizes Understanding"} />
                                        <label className="form-check-label" htmlFor="understandingVerbalizes">
                                            Verbalizes Understanding
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="understanding" id="understandingPTPCG" value="PT/PCG Comprehension" onChange={handleFieldChange} disabled={!["Applicable"].includes(responseTeachingData?.status)} checked={responseTeachingData?.understanding === "PT/PCG Comprehension"} />
                                        <label className="form-check-label" htmlFor="understandingPTPCG">
                                            PT/PCG Comprehension
                                        </label>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Understanding Level</span>
                            {readOnly && <div className="text-success pb-2">{responseTeachingData?.status === "Applicable" ? responseTeachingData?.understandingLevel ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="understandingLevel" className="form-select" onChange={handleFieldChange} value={responseTeachingData?.understandingLevel ?? ""} disabled={!["Applicable"].includes(responseTeachingData?.status)}>
                                        <option value="">Select</option>
                                        <option value="25% or less">25% or less</option>
                                        <option value="50% or less">50% or less</option>
                                        <option value="75% or less">75% or less</option>
                                        <option value="85% or less">85% or less</option>
                                        <option value="100%">100%</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8 me-0 pe-0 ">
                            <span className="my-1 mb-3 d-block">Demonstration</span>
                            {readOnly && <div className="text-success pb-2">{responseTeachingData?.status === "Applicable" ? responseTeachingData?.demonstration ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="demonstration" id="demonstrationReturn" value="Return Demonstration" onChange={handleFieldChange} disabled={!["Applicable"].includes(responseTeachingData?.status)} checked={responseTeachingData?.demonstration === "Return Demonstration"} />
                                        <label className="form-check-label" htmlFor="demonstrationReturn">
                                            Return Demonstration
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="demonstration" id="demonstrationPTPCG" value="PT/PCG Demonstration" onChange={handleFieldChange} disabled={!["Applicable"].includes(responseTeachingData?.status)} checked={responseTeachingData?.demonstration === "PT/PCG Demonstration"} />
                                        <label className="form-check-label" htmlFor="demonstrationPTPCG">
                                            PT/PCG Demonstration
                                        </label>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Demonstration Level</span>
                            {readOnly && <div className="text-success pb-2">{responseTeachingData?.status === "Applicable" ? responseTeachingData?.demonstrationLevel ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="demonstrationLevel" className="form-select" onChange={handleFieldChange} value={responseTeachingData?.demonstrationLevel ?? ""} disabled={!["Applicable"].includes(responseTeachingData?.status)}>
                                        <option value="">Select</option>
                                        <option value="25% or less">25% or less</option>
                                        <option value="50% or less">50% or less</option>
                                        <option value="75% or less">75% or less</option>
                                        <option value="85% or less">85% or less</option>
                                        <option value="100%">100%</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Comments</span>
                            {readOnly && <div className="text-success pb-2 pt-2">{responseTeachingData?.status === "Applicable" ? responseTeachingData?.comments ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <textarea name="comments" className="form-control" placeholder={`Comments`} value={null} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(responseTeachingData?.status)} value={responseTeachingData?.comments ?? ""} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, responseTeachingSection: data?.responseTeachingSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
