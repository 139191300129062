//Icons
import iconToiletRed from "../../../assets/toilet-solid-red.svg";
import iconToiletGreen from "../../../assets/toilet-solid-green.svg";

import { updateGenitourinarySection } from "../../../api/nurseAssessment/genitourinaryAPI";
import { isError } from "../../../lib/isError";

export const getGenitourinarySectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowGenitourinaryDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateGenitourinarySectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving Genitourinary Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.genitourinarySection, status: status };
        const saveObj = { id: patient?.genitourinarySection?.id, status: status };
        updateGenitourinarySection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", genitourinarySection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Genitourinary",
        description: null,
        progress: data?.genitourinarySection?.progress,
        status: data?.genitourinarySection?.status,
        enableStatusField: true,
        iconClass: "fa-hospital-o",
        iconPending: iconToiletRed,
        iconCompleted: iconToiletGreen,
        saving: false,
        onClick: () => {
            setShowGenitourinaryDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateGenitourinarySectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateGenitourinarySectionStatus(notApplicable);
            }
        },
    };
};
