//=== Style =====
import "./clientAdditionalInformation.css";

import { useEffect, useState } from "react";
import { isError } from "../../../../lib/isError";
import { Card } from "./components/card/card";
import { clientAdditionalInformationLib_Medications } from "./lib/clientAdditionalInformationLib_Medications";
import { MedicationsDialog } from "./components/medicationsDialog/medicationsDialog";

export const ClientAdditionalInformation = ({ clientData }) => {
    //= States ===================================================================

    const [data, setData] = useState(null);

    const [cardList, setCardList] = useState([]);

    const [showMedicationsDialog, setShowMedicationsDialog] = useState(false);

    //= Use Effects ==============================================================

    useEffect(() => {
        //Setting Cards
        const cards = [];

        const sectionContent = clientAdditionalInformationLib_Medications(setShowMedicationsDialog);
        cards.push(sectionContent);

        //Setting cards...
        setCardList(cards);
    }, [data]); // eslint-disable-line

    //= Render ===================================================================

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="row pb-0 mb-0">
                            <div className="col-12 pb-0 mb-0">
                                <h5 className="pt-3 text-success ps-3">Additional Information</h5>
                            </div>
                        </div>
                        <hr className="pt-0 mt-0"></hr>
                    </div>
                </div>

                <section id="ClientCards">
                    <div className="acontainer mt-2 mx-0 px-0">
                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 mx-0 px-0 mb-4 pb-4 clientAdditionalInformationCard">
                            {cardList.map((item, index) => {
                                return (
                                    <div className="col mt-3" key={index}>
                                        <Card index={index} icon={item?.icon} title={item?.title} description={item?.description} cardClickHandler={item?.onClick} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </div>
            {showMedicationsDialog && <MedicationsDialog data={clientData} closeDialog={() => setShowMedicationsDialog(false)} readOnly={false} />}
        </>
    );
};
