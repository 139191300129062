import { useState, useEffect } from "react";
import "./successMessage.css";

export const SuccessMessage = ({ msg }) => {
    const [messageClass, setMessageClass] = useState(null);

    useEffect(() => {
        setMessageClass(`alert alert-success d-flex align-items-center py-2 mt-3 ${msg ? "fadeIn" : "fadeOut"}`);
    }, [msg]);

    return (
        <div className={messageClass} role="alert">
            <i className={"fa fa-check-circle fa-2x me-3"}></i>
            <div>{msg}</div>
        </div>
    );
};
