import { DateFormatted } from "../../../../../../../../components/dateFormatted/dateFormatted";

export const SummaryMedication = ({ data }) => {
    const discontinuedStyle = {
        textDecoration: "line-through",
        textDecorationColor: "green",
    };

    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src="/assets/images/prescription-bottle-solid-red.svg" alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Medications
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt">
                        <div className="col-prt-12">
                            <table className="table table-sm medicationList mt-1">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-center">Dose</th>
                                        <th>Type</th>
                                        <th>Frequency</th>
                                        <th>Route</th>
                                        <th className="text-center">Start</th>
                                        <th className="text-center">End</th>
                                        <th className="text-center">Crush</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.medications && data?.medications.length === 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="8" align="center">
                                                    No medication order
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {data?.medications &&
                                        data?.medications.map((medication, index) => {
                                            return (
                                                <>
                                                    <tr key={`${index}-r1`} style={medication?.discontinued || (medication?.endDate?.length > 0 && (medication?.endDate ?? new Date().toISOString().split("T")[0]) < new Date().toISOString().split("T")[0]) ? discontinuedStyle : {}}>
                                                        <td className="small text-primary">{medication?.name}</td>
                                                        <td className="small text-primary text-center">{medication?.dose ?? "-"}</td>
                                                        <td className="small text-primary">{medication?.doseType ?? "-"}</td>
                                                        <td className="small text-primary">{(medication?.frequency ?? "-") === "Other" ? medication?.frequencyOtherDescription : medication?.frequency ?? "-"}</td>
                                                        <td className="small text-primary">{medication?.route ?? "-"}</td>
                                                        <td className="small text-primary text-center">
                                                            <DateFormatted date={medication?.startDate} dateFormat="MM/DD/YY" />
                                                        </td>
                                                        <td className="small text-primary text-center">
                                                            <DateFormatted date={medication?.endDate} dateFormat="MM/DD/YY" />
                                                        </td>
                                                        <td className="small text-primary text-center">{medication?.okToCrush ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr key={`${index}-r2`}>
                                                        <td className="small mb-3" colSpan={8}>
                                                            <span>Order Details: </span>
                                                            <span className="text-primary">{medication?.orderDetails ?? "-"}</span>
                                                            <p />
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
