import icon from "../../../../../../../../assets/brain-solid-red.svg";

export const SummaryNeuromuscularAssessment = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Neuromuscular
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Pupils</div>
                        <div className="col-prt-10 fw-bold">Description</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.pupils ?? "-"}</div>
                        <div className="col-prt-10 text-primary">{data?.pupilsOtherDescription ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Grasp Right</div>
                        <div className="col-prt-2 fw-bold">Grasp Left</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.graspLeft ? `${data?.graspLeft} ${data?.graspLeftLevel ? `(${data?.graspLeftLevel})` : data?.graspLeftLevel}` : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.graspRight ? `${data?.graspRight} ${data?.graspRightLevel ? `(${data?.graspRightLevel})` : data?.graspRightLevel}` : "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Headache</div>
                        <div className="col-prt-2 fw-bold">Length of time</div>
                        <div className="col-prt-2 fw-bold">Relief with</div>
                        <div className="col-prt-2 fw-bold">Worsens with</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.headache ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.headacheLengthOfTime ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.headacheReliefWith ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.headacheWorsensWith ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Syncope</div>
                        <div className="col-prt-2 fw-bold">Blood Pressure</div>
                        <div className="col-prt-2 fw-bold">Unsteady Gait</div>
                        <div className="col-prt-2 fw-bold">Ataxia</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.syncope ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.bloodPressure ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.unsteadyGait ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.ataxia ? "Yes" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Vertigo</div>
                        <div className="col-prt-4 fw-bold">Relief with</div>
                        <div className="col-prt-4 fw-bold">Worsens with</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.vertigo ? "Yes" : "-"}</div>
                        <div className="col-prt-4 text-primary">{(data?.vertigoReliefWith ?? "-") !== "Other" ? data?.vertigoReliefWith ?? "-" : data?.vertigoReliefWithOther ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{(data?.vertigoWorsensWith ?? "-") !== "Other" ? data?.vertigoWorsensWith ?? "-" : data?.vertigoWorsensWithOther ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Decreased Sensation</div>
                        <div className="col-prt-4 fw-bold">Location</div>
                        <div className="col-prt-2 fw-bold">Tremors</div>
                        <div className="col-prt-4 fw-bold">Location</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.decreasedSensation ? "Yes" : "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.decreasedSensationLocation ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.tremors ? "Yes" : "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.tremorsLocation ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Weakness</div>
                        <div className="col-prt-4 fw-bold">Describe</div>
                        <div className="col-prt-2 fw-bold">Change in ADL</div>
                        <div className="col-prt-4 fw-bold">Describe</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.weakness ? "Yes" : "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.weaknessDescription ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.changeInADL ? "Yes" : "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.changeInADLDescription ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Comments</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.comments ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
