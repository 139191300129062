import icon from "../../../../../../../../assets/stethoscope-solid-red.svg";
import { ScorePAINAD } from "../../../../../../../../components/scorePAINAD/scorePAINAD";

export const SummarySkilledObservationAssessment = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small style={{ pageBreakInside: "avoid" }}>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Skilled Observation / Assessment
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-8 fw-bold">Mental Status</div>
                        <div className="col-prt-4 fw-bold">Details</div>
                    </div>

                    <div className="row-prt  ms-2 mb-2">
                        <div className="col-prt-8 text-primary">
                            {(data?.mentalStatus ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 5,
                                }}
                            >
                                {data?.mentalStatus?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-prt-4 text-primary">{data?.mentalStatusDetails ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-4 fw-bold">Temp. (°F)</div>
                        <div className="col-prt-4 fw-bold">Pulse</div>
                        <div className="col-prt-4 fw-bold">Weight (lbs)</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-4 text-primary">
                            {data?.temperature ?? "-"} {data?.temperatureSource ? `(${data?.temperatureSource})` : data?.temperatureSource}
                        </div>
                        <div className="col-prt-4 text-primary">
                            {data?.pulse ?? "-"} {data?.pulseSource ? `(${data?.pulseSource})` : data?.pulseSource}
                        </div>
                        <div className="col-prt-4 text-primary">
                            {data?.weight ?? "-"} {data?.weightType ? `(${data?.weightType})` : data?.weightType}
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Blood Pressure</div>
                        <div className="col-prt-2 fw-bold">Arm</div>
                        <div className="col-prt-2 fw-bold">Position</div>
                        <div className="col-prt-2 fw-bold">Appetite</div>
                        <div className="col-prt-4 fw-bold">Hydration Adequate</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.bloodPressure ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.bloodPressureArm ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.bloodPressurePosition ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.appetite ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.hydrationAdequate ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-8 fw-bold">Skin</div>
                        <div className="col-prt-4 fw-bold">Skin Details</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-8 text-primary">
                            {(data?.skin ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 5,
                                }}
                            >
                                {data?.skin?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-prt-4 text-primary">{data?.skinDetails ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-4 fw-bold">Vision</div>
                        <div className="col-prt-8 fw-bold">Vision Details</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-4 text-primary">{data?.vision ?? "-"}</div>
                        <div className="col-prt-8 text-primary">{data?.visionDetails ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-3 fw-bold">Chest Sounds</div>
                        <div className="col-prt-3 fw-bold">Location</div>
                        <div className="col-prt-3 fw-bold">% O² Saturation at</div>
                        <div className="col-prt-3 fw-bold">Type</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-3 text-primary">{data?.chestSound ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.chestSoundLocation ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.o2Saturation ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.o2SaturationType ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-6 fw-bold">Last Fall Info</div>
                        <div className="col-prt-6 fw-bold">Reported Fall(s)</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-6 text-primary">{data?.lastFallInfo ?? "-"}</div>
                        <div className="col-prt-6 text-primary">{data?.reportedFalls ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Pain (last 24hs)</div>
                        <div className="col-prt-2 fw-bold">Pain Origin</div>
                        <div className="col-prt-2 fw-bold">Pain Type</div>
                        <div className="col-prt-2 fw-bold">Pain Location</div>
                        <div className="col-prt-2 fw-bold">Duration</div>
                        <div className="col-prt-2 fw-bold">Intensity</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.painLast24hs ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.painOrigin ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.painType ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.painLocation ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.painDuration ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.painIntensity ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>PAINAD Scale</li>
                        </div>
                    </div>

                    <div className="row-prt ms-4 mb-2">
                        <div className="col-prt-2 ms-3">
                            <ScorePAINAD score={data?.scorePAINAD} />
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Behavior</div>
                                <div className="col-prt-4 fw-bold">Negative Vocalization</div>
                                <div className="col-prt-4 fw-bold">Facial Expression</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.behaviorPAINAD ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.negativeVocalizationPAINAD ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.facialExpressionPAINAD ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Body Language</div>
                                <div className="col-prt-4 fw-bold">Consolability</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.bodyLanguagePAINAD ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.consolabilityPAINAD ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Comments</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.comments ?? "-"}</div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
