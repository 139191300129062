import icon from "../../../../../../../../assets/person-falling-burst-solid-red.svg";
import { ScoreFallRisk } from "../../../../../../../../components/scoreFallRisk/scoreFallRisk";

export const SummaryFallRisk = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Fall Risk
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>High Fall Risk</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2 mt-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">History of more than one fall within 6 months before admission</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.historyMoreThanOneFall ? `Yes` : "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Patient is deemed high fall-risk per protocol (e.g., seizure precautions)</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.patientDeemedHighFallRisk ? `Yes` : "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Low Fall Risk</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2 mt-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-6 fw-bold">Complete paralysis or completely immobilized</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-6 text-primary">{data?.completeParalysis ? `Yes` : "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Fall Risk Score</li>
                        </div>
                    </div>

                    <div className="row-prt ms-4 mb-2">
                        <div className="col-prt-2 ms-3">
                            <ScoreFallRisk score={data?.score} />
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Age</div>
                                <div className="col-prt-4 fw-bold">Fall History</div>
                                <div className="col-prt-4 fw-bold">Elimination, Bowel and Urine</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.scoreCalculationAge ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.scoreCalculationFallHistory ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.scoreCalculationEliminationBowelAndUrine ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Medications</div>
                                <div className="col-prt-4 fw-bold">Patient Care Equipment</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.scoreCalculationMedications ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.scoreCalculationPatientCareEquipment ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Mobility</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.scoreCalculationMobility ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 1,
                                        }}
                                    >
                                        {data?.scoreCalculationMobility?.map((item, index) => {
                                            if (item !== "") return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Cognition</div>
                            </div>

                            <div className="row-prt  ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.scoreCalculationCognition ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 1,
                                        }}
                                    >
                                        {data?.scoreCalculationCognition?.map((item, index) => {
                                            if (item !== "") return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
