export const Http_Continue = 100;
export const Http_SwitchingProtocols = 101;
export const Http_Processing = 102;
export const Http_OK = 200;
export const Http_Created = 201;
export const Http_Accepted = 202;
export const Http_NonAuthoritativeInformation = 203;
export const Http_NoContent = 204;
export const Http_ResetContent = 205;
export const Http_PartialContent = 206;
export const Http_MultiStatus = 207;
export const Http_AlreadyReported = 208;
export const Http_IMUsed = 226;
export const Http_MultipleChoices = 300;
export const Http_MovedPermanently = 301;
export const Http_Found = 302;
export const Http_SeeOther = 303;
export const Http_NotModified = 304;
export const Http_UseProxy = 305;
export const Http_TemporaryRedirect = 307;
export const Http_PermanentRedirect = 308;
export const Http_BadRequest = 400;
export const Http_Unauthorized = 401;
export const Http_PaymentRequired = 402;
export const Http_Forbidden = 403;
export const Http_NotFound = 404;
export const Http_MethodNotAllowed = 405;
export const Http_NotAcceptable = 406;
export const Http_ProxyAuthenticationRequired = 407;
export const Http_RequestTimeout = 408;
export const Http_Conflict = 409;
export const Http_Gone = 410;
export const Http_LengthRequired = 411;
export const Http_PreconditionFailed = 412;
export const Http_PayloadTooLarge = 413;
export const Http_RequestURITooLong = 414;
export const Http_UnsupportedMediaType = 415;
export const Http_RequestedRangeNotSatisfiable = 416;
export const Http_ExpectationFailed = 417;
export const Http_ImTeapot = 418;
export const Http_MisdirectedRequest = 421;
export const Http_UnprocessableEntity = 422;
export const Http_Locked = 423;
export const Http_FailedDependency = 424;
export const Http_UpgradeRequired = 426;
export const Http_PreconditionRequired = 428;
export const Http_TooManyRequests = 429;
export const Http_RequestHeaderFieldsTooLarge = 431;
export const Http_ConnectionClosedWithoutResponse = 444;
export const Http_UnavailableForLegalReasons = 451;
export const Http_ClientClosedRequest = 499;
export const Http_InternalServerError = 500;
export const Http_NotImplemented = 501;
export const Http_BadGateway = 502;
export const Http_ServiceUnavailable = 503;
export const Http_GatewayTimeout = 504;
export const Http_VersionNotSupported = 505;
export const Http_VariantAlsoNegotiates = 506;
export const Http_InsufficientStorage = 507;
export const Http_LoopDetected = 508;
export const Http_NotExtended = 510;
export const Http_NetworkAuthenticationRequired = 511;
export const Http_NetworkConnectTimeoutError = 599;
