export const skilledObservationAssessmentDialogAlerts = (data) => {
    const ret = {
        alerts: [],
    };

    if (data?.temperature) {
        const temperature = parseFloat(data?.temperature);
        if (isNaN(temperature)) ret.alerts.push({ field: "temperature", alertMessage: "Invalid value." });
        else if (temperature < 96.8) ret.alerts.push({ field: "temperature", alertMessage: "Alert: T < 96.8 °F " });
        else if (temperature > 100.4) ret.alerts.push({ field: "temperature", alertMessage: "Alert: T > 100.4 °F " });
    }

    if (data?.pulse) {
        const pulse = parseFloat(data?.pulse);
        if (!data?.pulse || isNaN(pulse)) ret.alerts.push({ field: "pulse", alertMessage: "Invalid value." });
        else if (pulse < 60) ret.alerts.push({ field: "pulse", alertMessage: "Alert: P < 60 beats/min " });
        else if (pulse > 100) ret.alerts.push({ field: "pulse", alertMessage: "Alert: P > 100 beats/min" });
    }

    if (data?.painIntensity) {
        const painIntensity = parseFloat(data?.painIntensity);
        if (!data?.painIntensity || isNaN(painIntensity)) ret.alerts.push({ field: "painIntensity", alertMessage: "Invalid value." });
        else if (painIntensity >= 7) ret.alerts.push({ field: "painIntensity", alertMessage: "Alert: Pain Intensity > 7" });
    }

    if (data?.o2Saturation) {
        const o2Saturation = parseFloat(data?.o2Saturation);
        if (!data?.o2Saturation || isNaN(o2Saturation)) ret.alerts.push({ field: "o2Saturation", alertMessage: "Invalid value." });
        else if (o2Saturation < 90) ret.alerts.push({ field: "o2Saturation", alertMessage: "Alert: O² < 90%" });
    }

    if (data?.o2Saturation) {
        const o2Saturation = parseFloat(data?.o2Saturation);
        if (!data?.o2Saturation || isNaN(o2Saturation)) ret.alerts.push({ field: "o2Saturation", alertMessage: "Invalid value." });
        else if (o2Saturation < 90) ret.alerts.push({ field: "o2Saturation", alertMessage: "Alert: O² < 90%" });
    }

    if (data?.respirationRate) {
        const respirationRate = parseFloat(data?.respirationRate);
        if (!data?.respirationRate || isNaN(respirationRate)) ret.alerts.push({ field: "respirationRate", alertMessage: "Invalid value." });
        else if (respirationRate < 12) ret.alerts.push({ field: "respirationRate", alertMessage: "Alert: Rate < 12" });
        else if (respirationRate > 24) ret.alerts.push({ field: "respirationRate", alertMessage: "Alert: Rate > 24" });
    }

    if (data?.bloodPressure) {
        const vetBloodPressureData = data?.bloodPressure.split("/");
        if (vetBloodPressureData.length !== 2) ret.alerts.push({ field: "bloodPressure", alertMessage: "Invalid value." });
        else {
            const bloodPressureSystolic = parseFloat(vetBloodPressureData[0]);
            const bloodPressureDiastolic = parseFloat(vetBloodPressureData[1]);
            if (isNaN(bloodPressureSystolic) || isNaN(bloodPressureDiastolic)) ret.alerts.push({ field: "bloodPressure", alertMessage: "Invalid value." });
            else if (bloodPressureSystolic < 95 || bloodPressureSystolic > 140 || bloodPressureDiastolic < 60 || bloodPressureDiastolic > 89) {
                let strAlert = "Alert: ";

                if (bloodPressureSystolic < 95) strAlert += "Systolic < 95 ";
                if (bloodPressureSystolic > 140) strAlert += "Systolic > 140 ";
                if (bloodPressureDiastolic < 60) strAlert += `${strAlert !== "Alert: " ? "/ " : ""} Diastolic < 60`;
                if (bloodPressureDiastolic > 89) strAlert += `${strAlert !== "Alert: " ? "/ " : ""}Diastolic > 89`;

                ret.alerts.push({ field: "bloodPressure", alertMessage: strAlert });
            }
        }

        //const o2Saturation = parseFloat(data?.o2Saturation);
        //if (!data?.o2Saturation || isNaN(o2Saturation)) ret.alerts.push({ field: "o2Saturation", alertMessage: "Invalid value." });
        //else if (o2Saturation < 90) ret.alerts.push({ field: "o2Saturation", alertMessage: "Alert: O² < 90%" });
    }

    ret.isValid = ret.alerts.length === 0;

    return ret;
};
