import { useEffect } from "react";
import { useState } from "react";
import { useFocus } from "../../lib/hooks/useFocus";

//css
import "./autoComplete.css";

export const AutoComplete = ({ suggestions, value, className, maxLength, onNameChange, onBlur, disabled }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [inputRef, setInputFocus] = useFocus();

    useEffect(() => {
        //setInput(value);
    }, [value]);

    const onBlurHandle = (e) => {
        const relatedTarget = e.relatedTarget;

        if (relatedTarget?.tagName === "INPUT" || relatedTarget?.tagName === "SELECT") {
            setShowSuggestions(false);
            onBlur();
        } else {
            //setShowSuggestions(false);
            //console.log("saiu pra outro lugar");
        }
    };

    const onClick = (e) => {
        if (e.target?.innerText) {
            setFilteredSuggestions([]);
            onNameChange(e.target.innerText);
            setActiveSuggestionIndex(0);
            setInputFocus();
            setShowSuggestions(false);
        }
    };

    const onChange = (e) => {
        const userInput = e.target.value;
        onNameChange(e.target.value);

        if (userInput?.length >= 3) {
            // Filter our suggestions that don't contain the user's input
            const unLinked = suggestions.filter((suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1);

            setFilteredSuggestions(unLinked);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const SuggestionsListComponent = () => {
        return filteredSuggestions.length ? (
            <ul className="suggestions">
                {filteredSuggestions.map((suggestion, index) => {
                    let className;
                    // Flag the active suggestion with a class
                    if (index === activeSuggestionIndex) {
                        className = "suggestion-active";
                    }
                    if (className) {
                        return (
                            <li className={className} key={index} onClick={onClick}>
                                {suggestion}
                            </li>
                        );
                    } else {
                        return (
                            <li key={index} onClick={onClick}>
                                {suggestion}
                            </li>
                        );
                    }
                })}
            </ul>
        ) : (
            <></>
            /*
            <div className="no-suggestions">
                <small>
                    <em>No suggestions, you're on your own!</em>
                </small>
            </div>
            */
        );
    };

    const onKeyDown = (key: any) => {
        if (key.keyCode === 13 /*|| key.keyCode === 9*/) {
            onNameChange(filteredSuggestions[activeSuggestionIndex]);
            setFilteredSuggestions([]);
        }

        //up
        if (key.keyCode === 38 && activeSuggestionIndex > 0) setActiveSuggestionIndex(activeSuggestionIndex - 1);

        //down
        if (key.keyCode === 40 && activeSuggestionIndex < filteredSuggestions?.length) setActiveSuggestionIndex(activeSuggestionIndex + 1);
    };

    return (
        <>
            <input type="text" className={className} ref={inputRef} maxLength={maxLength} disabled={disabled} onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlurHandle} value={value} />
            {showSuggestions && /*input*/ value && <SuggestionsListComponent />}
        </>
    );
};
