import { useNavigate } from "react-router-dom";
import "./reportCard.css";

export const ReportCard = ({ index, title, description, icon, iconHover, cardLink }) => {
    const navigate = useNavigate();

    const cardClickHandler = () => {
        navigate(cardLink); // Navega para a rota '/'
    };

    return (
        <div className="card reportCard" onClick={cardClickHandler}>
            <div className="card-body">
                <h5 className="card-title reportCardTitle">
                    <div className="card-container pe-0 me-0">
                        <span className="card-image-span">
                            <img className="cardDefaultIcon" src={`${process.env.PUBLIC_URL}/assets/images/${icon}`} width="36px" height="36px" alt=""></img>
                            <img className="cardHoverIcon" src={`${process.env.PUBLIC_URL}/assets/images/${iconHover}`} width="40px" height="40px" alt=""></img>
                            {/*!icon && <i className={`fa ${iconClass} h1`} aria-hidden="true"></i>*/}
                        </span>
                        <span className="ps-2 mt-2 h6 card-text-span pe-0 me-0">{title}</span>
                    </div>
                </h5>
                <p className="card-text small">{description}</p>
            </div>
        </div>
    );
};
