import { acquireAccessToken, acquireMSALTokens } from "../lib/acquireAccessToken";
import { Http_BadRequest, Http_OK } from "../lib/httpStatusCodes";
import { sortJson } from "../lib/sortJson";
import { headers } from "./headers";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}Physician`;

//=======================================================================

export const getAllActivePhysicians = async () => {
    const tokens = await acquireMSALTokens();
    headers.Authorization = tokens?.accessToken ? `bearer ${tokens.accessToken}` : null;
    headers.AuthorizationIdToken = tokens?.idToken ? `bearer ${tokens.idToken}` : null;
    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}`, { method: "GET", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                //sorting data by name
                                let data = sortJson(json.data, "fullName", "string", true);

                                //destructuring address
                                data = data.map((item) => {
                                    return { ...item, address: item?.address?.address, city: item?.address?.city, state: item?.address?.state, zipCode: item?.address?.zipCode };
                                });

                                return res(data);
                            });
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================
