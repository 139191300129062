//css
import "./woundCareDialog.css";

//Icons
import icon from "../../../../assets/bandaid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isError } from "../../../../lib/isError";
import { getWoundCarePreviousVisitById, updateWoundCareSection } from "../../../../api/nurseAssessment/woundCareAPI";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";
import { woundCareDialogValidation } from "./woundCareDialogValidation";

export const WoundCareDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //wound details data
    const woundDetailsData = {
        id: null,
        location: "",
        classification: "",
        length: "",
        width: "",
        depth: "",
        tunneling: "",
        odor: "",
        stoma: "",
        woundHealingProgress: "",
        woundHealingProgressDetails: "",
        previousWoundCareDressingChangePerformedBy: "",
        previousWoundCareDressingChangePerformedByOther: "",
        previousWoundCareDressingChangePerformedOn: "",
        previousWoundResolved: "",
        typeOfWound: "",
        burnsDescription: "",
        modeOfHealing: "",
        soiledDressingRemovedDisposedProperly: "",
        clientReceiveNPWT: "",
        numberOfPiecesOfFoamGauzeRemoved: "",
        contactLayer: "",
        unitSettingsCheckedAndPressureAndVariabilityConfirmed: "",
        drainageCanisterDisposed: "",
        woundCleaned: "",
        woundCleanedDescription: "",
        woundCleanedDescriptionOther: "",
        woundIrrigation: "",
        woundIrrigationNoReason: "",
        exudateContainerEmptied: "",
        exudateLevel: "",
        exudateType: "",
        drainInPlace: "",
        drainageAmount: "",
        dressingsApplied: "",
        dressingsAppliedDescription: "",
        woundDebridement: "",
        woundDebridementNoReason: "",
        patientToleratedProcedureWell: "",
        patientToleratedProcedureWellDescription: "",
        instructedOnWoundCareOstomyDisposalSoiledDressing: "",
        performWoundCareOstomyDressingChange: "",
        nursingSupervisorNotifiedImpairedHealingAndPotentialBarriersToHealing: "",
        nursingSupervisorNotifiedDate: "",
        mdNotifiedImpairedHealingAndPotentialBarriersToHealing: "",
        mdNotifiedDate: "",
        comments: "",
    };

    //Wound Care Data state
    const [woundCareData, setWoundCareData] = useState(null);

    //Wound Item data state
    const [woundItemData, setWoundItemData] = useState(null);

    //Form Validation Errors
    const [formValidationErrors, setFormValidationErrors] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //show Wound Form state
    const [showWoundForm, setShowWoundForm] = useState(false);

    //Form mode state (new or edit)
    const [formMode, setFormMode] = useState(null);

    //Selected Medication index
    const [selectedWoundIndex, setSelectedWoundIndex] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.woundCareSection, status: data?.woundCareSection?.status ?? "Applicable" };
        setWoundCareData(sectionData);
    }, [data]);

    //=== Methods ============================================================

    const getIsValidClass = (fieldName) => ((formValidationErrors ?? []).find((err) => err.field === fieldName)?.errorMessage ? "is-invalid" : "");

    const getErrorMessage = (fieldName) => (formValidationErrors ?? []).find((err) => err.field === fieldName)?.errorMessage;

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: woundCareData?.id, status: woundCareData?.status, wounds: [] };
        setWoundCareData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...woundCareData };

        //===========================================================

        //Saving WoundCareSection
        updateWoundCareSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", woundCareSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleLoadDataPreviousVisitClick = () => {
        setLoading(true);
        getWoundCarePreviousVisitById(data.id)
            .then((ret) => {
                //clear wounds ids
                ret.wounds = ret.wounds.map((item) => {
                    return { ...item, id: null };
                });
                const currentData = data?.woundCareSection;
                const newData = { ...data, woundCareSection: { ...ret, id: currentData?.id, progress: currentData?.progress } };
                setData(newData);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data.");
                }
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => setTimeout(() => setLoading(false), 1000));
    };

    const handleFieldStatusChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...woundCareData, [name]: value };

        setWoundCareData(newData);
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...woundItemData, [name]: value };
        setWoundItemData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...woundItemData, [name]: checked };
        setWoundItemData(newData);
    };

    const handleAddNewWound = () => {
        const newData = woundDetailsData;
        setWoundItemData(newData);
        setFormMode("New");
        setShowWoundForm(true);
        setFormValidationErrors(null);
    };

    const handleAddWound = () => {
        const wounds = woundCareData?.wounds ? woundCareData?.wounds : [];
        const newData = { ...woundCareData, wounds: [...wounds, woundItemData] };

        const formValidation = woundCareDialogValidation(woundItemData);

        if (!formValidation?.isValid) {
            setFormValidationErrors(formValidation?.errors);
            setOperationError("Error: Unable to save. Please review the highlighted fields and try again.");
            //hide message after 5s
            setTimeout(() => setOperationError(null), 5000);
        } else {
            setWoundCareData(newData);
            setShowWoundForm(false);
        }
    };

    const handleUpdateWound = () => {
        let wounds = woundCareData?.wounds ? woundCareData?.wounds : [];
        wounds[selectedWoundIndex] = woundItemData;
        const newData = { ...woundCareData, wounds: wounds };

        const formValidation = woundCareDialogValidation(woundItemData);

        if (!formValidation?.isValid) {
            setFormValidationErrors(formValidation?.errors);
            setOperationError("Error: Unable to save. Please review the highlighted fields and try again.");
            //hide message after 5s
            setTimeout(() => setOperationError(null), 5000);
        } else {
            setWoundCareData(newData);
            setShowWoundForm(false);
        }
    };

    const handleRemoveWound = () => {
        let wounds = [...woundCareData?.wounds];
        wounds.splice(selectedWoundIndex, 1);
        const newData = { ...woundCareData, wounds: wounds };
        setWoundCareData(newData);
        setShowWoundForm(false);
    };

    const editWound = (index) => {
        const newData = woundCareData?.wounds[index];
        setSelectedWoundIndex(index);
        setWoundItemData(newData);
        setFormMode("Edit");
        setShowWoundForm(true);
        setFormValidationErrors(null);
    };

    return (
        <Modal dialogClassName="woundcareModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Wound Care - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-3">{woundCareData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldStatusChange} value={woundCareData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {!readOnly && (
                        <div className="row">
                            <div className="col-12 text-end">
                                <button className="btn btn-sm btn-outline-secondary" onClick={handleLoadDataPreviousVisitClick}>
                                    {!loading && "Load Data from Previous Visit"}
                                    {loading && (
                                        <span className="ps-2">
                                            <span className="pe-2">Loading...</span>
                                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Current Wounds <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm woundList mt-1">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Location</th>
                                        <th>Healing Progress</th>
                                        <th className="text-center">Addressed</th>
                                        <th className="text-center">Supervisor Notified</th>
                                        <th className="text-center">MD Notified</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {woundCareData?.wounds && woundCareData?.wounds?.length === 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="6" align="center">
                                                    No Wounds reported
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {woundCareData?.wounds &&
                                        woundCareData?.wounds.map((wound, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="small text-primary" onClick={() => editWound(index)}>
                                                        <a href="#woundForm">{wound?.typeOfWound}</a>
                                                    </td>
                                                    <td className="small" onClick={() => editWound(index)}>
                                                        {wound?.location}
                                                    </td>
                                                    <td className="small">{wound?.woundHealingProgress}</td>
                                                    <td className="small text-center">{wound?.soiledDressingRemovedDisposedProperly ? "Yes" : "No"}</td>
                                                    <td className="small text-center">{wound?.nursingSupervisorNotifiedImpairedHealingAndPotentialBarriersToHealing ? "Yes" : "No"}</td>
                                                    <td className="small text-center">{wound?.mdNotifiedImpairedHealingAndPotentialBarriersToHealing ? "Yes" : "No"}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                            {!readOnly && (
                                <a href="#woundForm">
                                    <button className="btn btn-sm btn-success" onClick={handleAddNewWound} disabled={!["Applicable"].includes(woundCareData?.status)}>
                                        Add New Wound
                                    </button>
                                </a>
                            )}
                        </div>
                    </div>

                    {showWoundForm && (
                        <>
                            <div className="row mx-0 px-0 mt-4" id="woundForm">
                                <div className="col-12 mx-0 px-0">
                                    <span className="text-success">
                                        Wound <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="p-2" style={{ backgroundColor: "rgba(88, 174, 137, 0.15)" }}>
                                <div className="row">
                                    <div className="col-6">
                                        <span className="my-1 d-block">Wound Type</span>
                                        <select name="typeOfWound" className={`form-select ${getIsValidClass("typeOfWound")}`} onChange={handleFieldChange} value={woundItemData?.typeOfWound} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Abrasions">Abrasions</option>
                                            <option value="Arterial">Arterial</option>
                                            <option value="Burns">Burns</option>
                                            <option value="Lacerations">Lacerations</option>
                                            <option value="Neoplastic">Neoplastic</option>
                                            <option value="Neuropathic">Neuropathic</option>
                                            <option value="Metabolic">Metabolic</option>
                                            <option value="Pressure Ulcers">Pressure Ulcers</option>
                                            <option value="Surgical">Surgical</option>
                                            <option value="Vascular">Vascular</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">{getErrorMessage("typeOfWound")}</div>
                                    </div>
                                    <div className="col-6">
                                        <span className="my-1 d-block">if Burns</span>
                                        <select name="burnsDescription" className="form-select" onChange={handleFieldChange} value={woundItemData?.burnsDescription} disabled={!["Applicable"].includes(woundCareData?.status) || !["Burns"].includes(woundItemData?.typeOfWound) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Full thickness">Full thickness</option>
                                            <option value="Partial thickness">Partial thickness</option>
                                            <option value="Superficial">Superficial</option>
                                            <option value="Superficial deep">Superficial deep</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <span className="my-1 d-block">Wound Location</span>
                                        <input name="location" type="text" className="form-control" placeholder={`Wound Location`} value={woundItemData?.location} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-2">
                                        <span className="my-1 d-block">Classification</span>
                                        <input name="classification" type="text" className="form-control" placeholder={`Wound Classification`} value={woundItemData?.classification} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-2">
                                        <span className="my-1 d-block">Length</span>
                                        <input name="length" type="text" className="form-control" placeholder={`Length`} value={woundItemData?.length} onChange={handleFieldChange} maxLength="10" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-2">
                                        <span className="my-1 d-block">Width</span>
                                        <input name="width" type="text" className="form-control" placeholder={`Width`} value={woundItemData?.width} onChange={handleFieldChange} maxLength="10" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-2">
                                        <span className="my-1 d-block">Depth</span>
                                        <input name="depth" type="text" className="form-control" placeholder={`Depth`} value={woundItemData?.depth} onChange={handleFieldChange} maxLength="10" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <span className="my-1 d-block">Tunneling</span>
                                        <input name="tunneling" type="text" className="form-control" placeholder={`Tunneling`} value={woundItemData?.tunneling} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4">
                                        <span className="my-1 d-block">Odor</span>
                                        <input name="odor" type="text" className="form-control" placeholder={`Odor`} value={woundItemData?.odor} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4">
                                        <span className="my-1 d-block">Stoma</span>
                                        <input name="stoma" type="text" className="form-control" placeholder={`Stoma`} value={woundItemData?.stoma} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <span className="my-1 d-block">Wound Healing Progress</span>
                                        <select name="woundHealingProgress" className="form-select" onChange={handleFieldChange} value={woundItemData?.woundHealingProgress} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Progressing as expected">Progressing as expected</option>
                                            <option value="Resolved">Resolved</option>
                                            <option value="Stalled">Stalled</option>
                                            <option value="Impaired Healing">Impaired Healing</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4">
                                        <span className="my-1 d-block">Describe</span>
                                        <input name="woundHealingProgressDetails" type="text" className="form-control" placeholder={`Describe`} value={woundItemData?.woundHealingProgressDetails} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(woundCareData?.status) || [""].includes(woundItemData?.woundHealingProgress) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4">
                                        <span className="my-1 d-block">Mode of Healing</span>
                                        <select name="modeOfHealing" className="form-select" onChange={handleFieldChange} value={woundItemData?.modeOfHealing} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Delayed primary intention">Delayed primary intention</option>
                                            <option value="Flap">Flap</option>
                                            <option value="Graft">Graft</option>
                                            <option value="Primary intention">Primary intention</option>
                                            <option value="Secondary intention">Secondary intention</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8">
                                        <span className="my-1 d-block">Previous Wound Care/Dressing Change Performed by</span>
                                        <select name="previousWoundCareDressingChangePerformedBy" className="form-select" onChange={handleFieldChange} value={woundItemData?.previousWoundCareDressingChangePerformedBy} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Client">Client</option>
                                            <option value="Family/Responsible party">Family/Responsible party</option>
                                            <option value="Documenting Nurse">Documenting Nurse</option>
                                            <option value="Other Medical/Nursing Provider">Other Medical/Nursing Provider</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4">
                                        <span className="my-1 d-block">Describe if Other</span>
                                        <input name="previousWoundCareDressingChangePerformedByOther" type="text" className="form-control" placeholder={`Other`} value={woundItemData?.previousWoundCareDressingChangePerformedByOther} onChange={handleFieldChange} maxLength="30" disabled={!["Applicable"].includes(woundCareData?.status) || !["Other Medical/Nursing Provider"].includes(woundItemData?.previousWoundCareDressingChangePerformedBy) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <span className="my-1 d-block">Performed On</span>
                                        <input name="previousWoundCareDressingChangePerformedOn" type="date" className="form-control" value={woundItemData?.previousWoundCareDressingChangePerformedOn?.split("T")[0]} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4 pt-2">
                                        <span className="my-1 d-block">&nbsp;</span>
                                        <input name="previousWoundResolved" id="previousWoundResolved" type="checkbox" className="form-check-input" checked={woundItemData?.previousWoundResolved} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                        <label className="form-check-label ps-2" htmlFor="previousWoundResolved">
                                            Previous Wound Resolved
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-3">
                                        <span className="my-1 d-block">Wound Irrigation</span>
                                        <select name="woundIrrigation" className="form-select" onChange={handleFieldChange} value={woundItemData?.woundIrrigation} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="N/A">N/A (not ordered)</option>
                                            <option value="Yes">Yes - per order</option>
                                            <option value="Not Performed">Not Performed</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-3">
                                        <span className="my-1 d-block">Reason if "Not Performed"</span>
                                        <select name="woundIrrigationNoReason" className="form-select" onChange={handleFieldChange} value={woundItemData?.woundIrrigationNoReason} disabled={!["Applicable"].includes(woundCareData?.status) || !["Not Performed"].includes(woundItemData?.woundIrrigation) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Supplies not available">Supplies not available</option>
                                            <option value="Patient refused">Patient refused</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-3">
                                        <span className="my-1 d-block">Wound Debridement</span>
                                        <select name="woundDebridement" className="form-select" onChange={handleFieldChange} value={woundItemData?.woundDebridement} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Not ordered">Not ordered</option>
                                            <option value="Autolytic Debridement">Autolytic Debridement</option>
                                            <option value="Enzymatic Debridement">Enzymatic Debridement</option>
                                            <option value="Not Performed">Not Performed</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-3">
                                        <span className="my-1 d-block">Reason if "Not Performed"</span>
                                        <select name="woundDebridementNoReason" className="form-select" onChange={handleFieldChange} value={woundItemData?.woundDebridementNoReason} disabled={!["Applicable"].includes(woundCareData?.status) || !["Not Performed"].includes(woundItemData?.woundDebridement) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Supplies not available">Supplies not available</option>
                                            <option value="Patient refused">Patient refused</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <span className="my-1 d-block">Does Client Receive NPWT</span>
                                        <select name="clientReceiveNPWT" className="form-select" onChange={handleFieldChange} value={woundItemData?.clientReceiveNPWT} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4">
                                        <span className="my-1 d-block"># Pieces of Foam/Gauze removed</span>
                                        <input name="numberOfPiecesOfFoamGauzeRemoved" type="number" className="form-control" placeholder={`# Pieces`} value={woundItemData?.numberOfPiecesOfFoamGauzeRemoved} onChange={handleFieldChange} maxLength="3" disabled={!["Applicable"].includes(woundCareData?.status) || !["Yes"].includes(woundItemData?.clientReceiveNPWT) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-4">
                                        <span className="my-1 d-block">Contact Layer</span>
                                        <select name="contactLayer" className="form-select" onChange={handleFieldChange} value={woundItemData?.contactLayer} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="N/A">N/A</option>
                                            <option value="Removed">Removed</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 pt-2">
                                            <input name="soiledDressingRemovedDisposedProperly" id="soiledDressingRemovedDisposedProperly" type="checkbox" className="form-check-input" checked={woundItemData?.soiledDressingRemovedDisposedProperly} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="soiledDressingRemovedDisposedProperly">
                                                Soiled dressing removed/disposed of properly
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 pt-2">
                                            <input name="unitSettingsCheckedAndPressureAndVariabilityConfirmed" id="unitSettingsCheckedAndPressureAndVariabilityConfirmed" type="checkbox" className="form-check-input" checked={woundItemData?.unitSettingsCheckedAndPressureAndVariabilityConfirmed} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="unitSettingsCheckedAndPressureAndVariabilityConfirmed">
                                                Unit Settings have been checked and Pressure and Variability setting confirmed against order
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 pt-2">
                                            <input name="drainageCanisterDisposed" id="drainageCanisterDisposed" type="checkbox" className="form-check-input" checked={woundItemData?.drainageCanisterDisposed} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="drainageCanisterDisposed">
                                                Drainage canister disposed of appropriately as required
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-5 pt-2">
                                            <input name="woundCleaned" id="woundCleaned" type="checkbox" className="form-check-input" checked={woundItemData?.woundCleaned} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="woundCleaned">
                                                Wound cleaned
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-1 pt-2 text-end">Specify</div>
                                        <div className="col-3">
                                            <select name="woundCleanedDescription" className="form-select" onChange={handleFieldChange} value={woundItemData?.woundCleanedDescription} disabled={!["Applicable"].includes(woundCareData?.status) || !woundItemData?.woundCleaned || readOnly}>
                                                <option value="">Select</option>
                                                <option value="Normal Saline">Normal Saline</option>
                                                <option value="Gentle Cleanser">Gentle Cleanser</option>
                                                <option value="Warm Water">Warm Water</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-3">
                                            <input name="woundCleanedDescriptionOther" type="text" className="form-control" placeholder={`Describe if Other`} value={woundItemData?.woundCleanedDescriptionOther} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(woundCareData?.status) || !["Other"].includes(woundItemData?.woundCleanedDescription) || !woundItemData?.woundCleaned || readOnly} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-5 pt-2">
                                            <input name="exudateContainerEmptied" id="exudateContainerEmptied" type="checkbox" className="form-check-input" checked={woundItemData?.exudateContainerEmptied} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="exudateContainerEmptied">
                                                Exudate container emptied
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-1 pt-2 text-end">Level</div>
                                        <div className="col-2">
                                            <select name="exudateLevel" className="form-select" onChange={handleFieldChange} value={woundItemData?.exudateLevel} disabled={!["Applicable"].includes(woundCareData?.status) || !woundItemData?.exudateContainerEmptied || readOnly}>
                                                <option value="">Select</option>
                                                <option value="Dry">Dry</option>
                                                <option value="Low">Low</option>
                                                <option value="Medium">Medium</option>
                                                <option value="High">High</option>
                                            </select>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-1 pt-2 text-end">Type</div>
                                        <div className="col-3">
                                            <select name="exudateType" className="form-select" onChange={handleFieldChange} value={woundItemData?.exudateType} disabled={!["Applicable"].includes(woundCareData?.status) || !woundItemData?.exudateContainerEmptied || readOnly}>
                                                <option value="">Select</option>
                                                <option value="Thin/Watery">Thin/Watery</option>
                                                <option value="Thick">Thick</option>
                                                <option value="Cloudy">Cloudy</option>
                                                <option value="Purulent (Yellow/Brown/Green)">Purulent (Yellow/Brown/Green)</option>
                                                <option value="Pink/Red">Pink/Red</option>
                                            </select>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4 pt-2">
                                            <input name="drainInPlace" id="drainInPlace" type="checkbox" className="form-check-input" checked={woundItemData?.drainInPlace} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="drainInPlace">
                                                Drain In Place?
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-2 pt-2 text-end">Amount (mL)</div>
                                        <div className="col-6">
                                            <input name="drainageAmount" type="text" className="form-control" placeholder={`Amount (mL)`} value={woundItemData?.drainageAmount} onChange={handleFieldChange} maxLength="10" disabled={!["Applicable"].includes(woundCareData?.status) || !woundItemData?.drainInPlace || readOnly} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4 pt-2">
                                            <input name="dressingsApplied" id="dressingsApplied" type="checkbox" className="form-check-input" checked={woundItemData?.dressingsApplied} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="dressingsApplied">
                                                Dressing Applied as ordered
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-2 pt-2 text-end">Describe</div>
                                        <div className="col-6">
                                            <input name="dressingsAppliedDescription" type="text" className="form-control" placeholder={`Description`} value={woundItemData?.dressingsAppliedDescription} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(woundCareData?.status) || !woundItemData?.dressingsApplied || readOnly} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4 pt-2">
                                            <input name="patientToleratedProcedureWell" id="patientToleratedProcedureWell" type="checkbox" className="form-check-input" checked={woundItemData?.patientToleratedProcedureWell} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="patientToleratedProcedureWell">
                                                Patient tolerated procedure well
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-2 pt-2 text-end">Describe</div>
                                        <div className="col-6">
                                            <input name="patientToleratedProcedureWellDescription" type="text" className="form-control" placeholder={`Description`} value={woundItemData?.patientToleratedProcedureWellDescription} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(woundCareData?.status) || !woundItemData?.patientToleratedProcedureWell || readOnly} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 pt-2">
                                            <input name="instructedOnWoundCareOstomyDisposalSoiledDressing" id="instructedOnWoundCareOstomyDisposalSoiledDressing" type="checkbox" className="form-check-input" checked={woundItemData?.instructedOnWoundCareOstomyDisposalSoiledDressing} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="instructedOnWoundCareOstomyDisposalSoiledDressing">
                                                Patient/family/caregiver instructed on wound care / ostomy / disposal soiled dressing
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 pt-2">
                                            <input name="performWoundCareOstomyDressingChange" id="performWoundCareOstomyDressingChange" type="checkbox" className="form-check-input" checked={woundItemData?.performWoundCareOstomyDressingChange} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="performWoundCareOstomyDressingChange">
                                                Patient/family/caregiver to perform wound care / ostomy / dressing change
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8 pt-2">
                                            <input name="nursingSupervisorNotifiedImpairedHealingAndPotentialBarriersToHealing" id="nursingSupervisorNotifiedImpairedHealingAndPotentialBarriersToHealing" type="checkbox" className="form-check-input" checked={woundItemData?.nursingSupervisorNotifiedImpairedHealingAndPotentialBarriersToHealing} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="nursingSupervisorNotifiedImpairedHealingAndPotentialBarriersToHealing">
                                                Nursing Supervisor aware
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-2 pt-2 text-end">Date</div>
                                        <div className="col-2">
                                            <input name="nursingSupervisorNotifiedDate" type="date" className="form-control" value={woundItemData?.nursingSupervisorNotifiedDate?.split("T")[0]} onChange={handleFieldChange} disabled={!["Applicable"].includes(woundCareData?.status) || !woundItemData?.nursingSupervisorNotifiedImpairedHealingAndPotentialBarriersToHealing || readOnly} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8 pt-2">
                                            <input name="mdNotifiedImpairedHealingAndPotentialBarriersToHealing" id="mdNotifiedImpairedHealingAndPotentialBarriersToHealing" type="checkbox" className="form-check-input" checked={woundItemData?.mdNotifiedImpairedHealingAndPotentialBarriersToHealing} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <label className="form-check-label ps-2" htmlFor="mdNotifiedImpairedHealingAndPotentialBarriersToHealing">
                                                MD aware
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-2 pt-2 text-end">Date</div>
                                        <div className="col-2">
                                            <input name="mdNotifiedDate" type="date" className="form-control" value={woundItemData?.mdNotifiedDate?.split("T")[0]} onChange={handleFieldChange} disabled={!["Applicable"].includes(woundCareData?.status) || !woundItemData?.mdNotifiedImpairedHealingAndPotentialBarriersToHealing || readOnly} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <span className="my-1 d-block">Comments</span>
                                            <textarea name="comments" className="form-control" placeholder={`Comments`} value={woundItemData?.comments} onChange={handleFieldChange} maxLength="1000" disabled={!["Applicable"].includes(woundCareData?.status) || readOnly} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!readOnly && (
                                <div className="row">
                                    <div className="col-12 mt-2">
                                        {formMode === "New" ? (
                                            <button type="button" className="btn btn-sm btn-outline-success" onClick={handleAddWound}>
                                                Add
                                            </button>
                                        ) : (
                                            <>
                                                <button type="button" className="btn btn-sm btn-outline-success" onClick={handleUpdateWound}>
                                                    Update
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-danger ms-2" onClick={handleRemoveWound}>
                                                    Remove
                                                </button>
                                            </>
                                        )}

                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-secondary ms-2"
                                            onClick={() => {
                                                setShowWoundForm(false);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} disableSave={showWoundForm} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, woundCareSection: data?.woundCareSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
