import React from "react";
import { PageLayout } from "./components/pageLayout/PageLayout.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home/home.jsx";
import { PatientAssessments } from "./pages/patientAssessments/patientAssessments.jsx";
import { useMsal } from "@azure/msal-react";
import { ClientList } from "./pages/clientList/clientList.jsx";
import { ClientProfile } from "./pages/clientProfile/clientProfile.jsx";
import { Reports } from "./pages/reports/reports.jsx";
import { VisitCompletionCompliance } from "./pages/reports/components/visitCompletionCompliance/visitCompletionCompliance.jsx";
import { SupervisionReportByClient } from "./pages/reports/components/supervisionReportByClient/supervisionReportByClient.jsx";
import { SupervisionReportByEmployee } from "./pages/reports/components/supervisionReportByEmployee/supervisionReportByEmployee.jsx";
import { WoundCareReport } from "./pages/reports/components/woundCareReport/woundCareReport.jsx";

function App() {
    return (
        <>
            <BrowserRouter>
                <PageLayout />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/clientList" element={<ClientList />} />
                    <Route path="/patientAssessments" element={<PatientAssessments />} />
                    <Route path="/clientProfile/:id" element={<ClientProfile />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/reports/visitCompletionCompliance" element={<VisitCompletionCompliance />} />
                    <Route path="/reports/supervisionReportByClient" element={<SupervisionReportByClient />} />
                    <Route path="/reports/supervisionReportByEmployee" element={<SupervisionReportByEmployee />} />
                    <Route path="/reports/woundCare" element={<WoundCareReport />} />
                </Routes>
            </BrowserRouter>
            {/* 
            <AuthenticatedTemplate>
                <p>This will only render if a user is signed-in.</p>
                <WelcomeUser />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>This will only render if a user is not signed-in.</p>
            </UnauthenticatedTemplate>
            */}
        </>
    );
}

function WelcomeUser() {
    const { accounts } = useMsal();
    const username = accounts[0].username;

    return <p>Welcome, {username}</p>;
}

export default App;
