//css
import "./commentsDialog.css";

//Icons
import icon from "../../../../assets/note-sticky-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateCommentsSection, addComment_CommentsSection, updateComment_CommentsSection, removeComment_CommentsSection } from "../../../../api/nurseAssessment/commentsAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { getAllJournalSubCategories } from "../../../../api/journalSubCategoryAPI";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const CommentsDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //comment details data
    const commentDetailsData = {
        id: null,
        copyToCPAJournal: "",
        comments: "",
        journalSubCategoryId: "",
    };

    //Comments Section data state
    const [commentsData, setCommentsData] = useState(null);

    //Comment Item data state
    const [commentItemData, setCommentItemData] = useState(null);

    //Comments data state
    const [journalSubCategories, setJournalSubCategories] = useState([]);

    //saving state
    const [saving, setSaving] = useState(false);
    const [adding, setAdding] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //show Comment Form state
    const [showCommentForm, setShowCommentForm] = useState(false);

    //Form mode state (new or edit)
    const [formMode, setFormMode] = useState(null);

    //Selected Comment index
    const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        getAllJournalSubCategories()
            .then((data) => {
                console.log("JournalSubCategories", data);
                setJournalSubCategories(data);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const sectionData = { ...data?.commentsSection, status: data?.commentsSection?.status ?? "Applicable" };
        console.log("sectionData", sectionData);
        setCommentsData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: commentsData?.id, status: commentsData?.status };
        setCommentsData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...commentsData };

        //===========================================================

        //Saving CommentsSection
        updateCommentsSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", commentsSection: ret };
                setData(newData);
                closeDialog();
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => {
                setSaving(false);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...commentItemData, [name]: value };
        setCommentItemData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...commentItemData, [name]: checked };

        setCommentItemData(newData);
    };

    const handleAddNewComment = () => {
        const newData = commentDetailsData;
        setCommentItemData(newData);
        setFormMode("New");
        setShowCommentForm(true);
    };

    const handleAddComment = () => {
        setAdding(true);
        const addObj = { ...commentItemData, CommentSectionId: data?.id };

        //alert(JSON.stringify(addObj));

        //Saving CommentSection
        addComment_CommentsSection(addObj)
            .then((ret) => {
                //alert(JSON.stringify(ret));

                let comments = commentsData?.comments ? commentsData?.comments : [];

                const newCommentData = { ...commentsData, comments: [...comments, { ...commentItemData, id: ret?.id }] };
                setCommentsData(newCommentData);

                const newData = { ...data, status: "In Progress", commentsSection: { ...newCommentData, progress: newCommentData?.comments?.length === 0 ? "Pending" : "Completed" } };
                setData(newData);

                setShowCommentForm(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => {
                setAdding(false);
            });
    };

    const handleUpdateComment = () => {
        setUpdating(true);
        const updateObj = { ...commentItemData, CommentSectionId: data?.id };

        //Saving CommentSection
        updateComment_CommentsSection(updateObj)
            .then((ret) => {
                let comments = commentsData?.comments ? commentsData?.comments : [];
                comments[selectedCommentIndex] = commentItemData;
                const newData = { ...commentsData, comments: comments };
                setCommentsData(newData);
                setShowCommentForm(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => {
                setUpdating(false);
            });
    };

    const handleRemoveComment = () => {
        setRemoving(true);
        const removeObj = commentItemData;

        //Saving CommentSection
        removeComment_CommentsSection(removeObj)
            .then((ret) => {
                let comments = [...commentsData?.comments];
                comments.splice(selectedCommentIndex, 1);

                const newCommentData = { ...commentsData, comments: comments };
                setCommentsData(newCommentData);

                const newData = { ...data, status: "In Progress", commentsSection: { ...newCommentData, progress: (newCommentData?.comments?.length ?? []) === 0 ? "Pending" : "Completed" } };
                setData(newData);

                setShowCommentForm(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => {
                setRemoving(false);
            });
    };

    const editComment = (index) => {
        const newData = commentsData?.comments[index];
        setSelectedCommentIndex(index);
        setCommentItemData(newData);
        setFormMode("Edit");
        setShowCommentForm(true);
    };

    //=== Render =============================================================
    return (
        <Modal dialogClassName="commentsSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Comments - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{commentsData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={commentsData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row m-0 p-0  mt-3">
                        <div className="col-4 m-0 p-0 pt-2">
                            <span className="text-success">Comments</span>
                        </div>
                        <hr className="my-1" />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm commentList mt-1">
                                <thead>
                                    <tr>
                                        <th>Comment</th>
                                        <th>Copy to CPA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(commentsData?.comments ?? []).length === 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="2" align="center">
                                                    No comments
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {commentsData?.comments &&
                                        commentsData?.comments.map((comment, index) => {
                                            console.log("comment", comment);
                                            return (
                                                <tr key={index}>
                                                    <td className="small text-primary" onClick={() => editComment(index)}>
                                                        <a href="#commentForm">{comment?.comments}</a>
                                                    </td>
                                                    <td className="small text-center">{comment?.copyToCPAJournal ? "Yes" : "No"}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                            {!readOnly && (
                                <a href="#commentForm">
                                    <button className="btn btn-sm btn-success" onClick={handleAddNewComment} disabled={!["Applicable"].includes(commentsData?.status)}>
                                        Add New Comment
                                    </button>
                                </a>
                            )}
                        </div>
                    </div>

                    {showCommentForm && (
                        <>
                            <div className="row mx-0 px-0 mt-4" id="commentForm">
                                <div className="col-12 mx-0 px-0">
                                    <span className="text-success">
                                        <hr className="my-1" />
                                    </span>
                                </div>
                            </div>

                            <div className="p-2" style={{ backgroundColor: "rgba(88, 174, 137, 0.15)" }}>
                                <div className="row my-0 py-0">
                                    <div className="col-6 pt-2">
                                        <label htmlFor="comments">Comments</label>
                                    </div>

                                    <div className="col-2 pt-1">
                                        <small>
                                            <input name="copyToCPAJournal" id="copyToCPAJournal" type="checkbox" className="form-check-input" checked={commentItemData?.copyToCPAJournal ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(commentsData?.status)} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2" htmlFor="copyToCPAJournal">
                                                Copy to CPA Journal
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </small>
                                    </div>

                                    <div className="col-3">
                                        <select name="journalSubCategoryId" className="form-select form-select-sm" onChange={handleFieldChange} value={commentItemData?.journalSubCategoryId ?? ""} disabled={!["Applicable"].includes(commentsData?.status) || !commentItemData?.copyToCPAJournal || readOnly}>
                                            <option value="">Select</option>
                                            {journalSubCategories?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>
                                                        {item.description}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="row mt-0 pt-0">
                                        <div className="col-12 mt-0 pt-0">
                                            <textarea name="comments" id="comments" className="form-control" rows="6" placeholder={`Write a brief summary of any new events, changes or information that needs to be communicated to the office or care team.`} value={commentItemData?.comments ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(commentsData?.status) || readOnly} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!readOnly && (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <CommentButtons
                                                formMode={formMode}
                                                adding={adding}
                                                handleAdd={handleAddComment}
                                                updating={updating}
                                                handleUpdate={handleUpdateComment}
                                                removing={removing}
                                                handleRemove={handleRemoveComment}
                                                handleCancel={() => {
                                                    setShowCommentForm(false);
                                                }}
                                                readOnly={false}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={true}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, commentsSection: data?.commentsSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};

export const CommentButtons = ({ formMode, adding, handleAdd, updating, handleUpdate, removing, handleRemove, handleCancel, readOnly = false }) => {
    return (
        <>
            {formMode === "New" ? (
                <button type="button" className="btn btn-sm btn-outline-success" onClick={handleAdd}>
                    {!adding && "Add"}
                    {adding && (
                        <span className="ps-2">
                            <span className="pe-2">Adding...</span>
                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                        </span>
                    )}
                </button>
            ) : (
                <>
                    <button type="button" className="btn btn-sm btn-outline-success" onClick={handleUpdate}>
                        {!updating && "Update"}
                        {updating && (
                            <span className="ps-2">
                                <span className="pe-2">Updating...</span>
                                <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                            </span>
                        )}
                    </button>
                    <button type="button" className="btn btn-sm btn-outline-danger ms-2" onClick={handleRemove}>
                        {!removing && "Remove"}
                        {removing && (
                            <span className="ps-2">
                                <span className="pe-2">Removing...</span>
                                <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                            </span>
                        )}
                    </button>
                </>
            )}

            <button
                type="button"
                className="btn btn-sm btn-outline-secondary ms-2"
                onClick={() => {
                    handleCancel(false);
                }}
            >
                Cancel
            </button>
        </>
    );
};
