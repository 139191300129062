//css
import "./summaryDialog.css";

import { Modal } from "react-bootstrap";
import { AssessmentSummary } from "./components/assessmentSummary/assessmentSummary";
import { PrintAssessments } from "./components/printAssessments/printAssessments";

export const SummaryDialog = ({ data, closeDialog }) => {
    return (
        <Modal dialogClassName="summaryModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="ps-2">Summary</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                    paddingLeft: "0px",
                    marginLeft: "0px",
                    paddingRight: "0px",
                    marginRight: "0px",
                }}
            >
                <AssessmentSummary data={data} />
            </Modal.Body>

            <Modal.Footer>
                <>
                    <PrintAssessments data={data} />
                    <button type="button" className="btn btn-outline-secondary" onClick={closeDialog}>
                        Close
                    </button>
                </>
            </Modal.Footer>
        </Modal>
    );
};
