import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
//import { ProfileContent } from "../../components/ProfileContent";
import { SignInButton } from "../../components/SignInButton";
import "./home.css";
import { VisitList } from "./components/visitList/visitList";
import { WelcomePanel } from "./components/welcomePanel/welcomePanel";

export const Home = () => {
    return (
        <section>
            <UnauthenticatedTemplate>
                <div className="bgimg-home" />
                <center>
                    <WelcomePanel />
                </center>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <VisitList />
                {/*
                    <ProfileContent />  
                */}
            </AuthenticatedTemplate>
        </section>
    );
};
