//css
import "./clientSection.css";

export const ClientSection = ({ data }) => {
    return (
        <section className="summaryClientSection">
            <small>
                <div className="row-prt ms-2">
                    <div className="col-prt-3 fw-bold">Client Name</div>
                    <div className="col-prt-3 fw-bold">Phone</div>
                    <div className="col-prt-6 fw-bold">Address</div>
                </div>
                <div className="row-prt ms-2 mb-2">
                    <div className="col-prt-3 text-primary">{data?.name ?? "-"}</div>
                    <div className="col-prt-3 text-primary">{data?.phone ?? "-"}</div>
                    <div className="col-prt-6 text-primary">{data?.address ?? "-"}</div>
                </div>

                <div className="row-prt ms-2">
                    <div className="col-prt-3 fw-bold">Caregiver Name</div>
                    <div className="col-prt-3 fw-bold">Assessment Type</div>
                    <div className="col-prt-3 fw-bold">Status</div>
                    <div className="col-prt-3 fw-bold">Date</div>
                </div>

                <div className="row-prt ms-2 mb-2">
                    <div className="col-prt-3 text-primary">{data?.careGiverName ?? "-"}</div>
                    <div className="col-prt-3 text-primary">{data?.type ?? "-"}</div>
                    <div className="col-prt-3 text-primary">{data?.status ?? "-"}</div>
                    <div className="col-prt-3 text-primary">{data?.date ?? "-"}</div>
                </div>

                <div className="row-prt ms-2">
                    <div className="col-prt-12 fw-bold">Allergies</div>
                </div>

                <div className="row-prt ms-2 mb-0 pb-0">
                    <div className="col-prt-12 text-primary">{data?.client?.allergies ?? "-"}</div>
                </div>

                <div className="row-prt ms-2">
                    <div className="col-prt-12 fw-bold">Special Notes</div>
                </div>

                <div className="row-prt ms-2 mb-0 pb-0">
                    <div className="col-prt-12 text-primary">{data?.client?.specialNotes ?? "-"}</div>
                </div>

                <div className="row-prt my-0 py-0">
                    <div className="col-prt-12">
                        <hr />
                    </div>
                </div>
            </small>
        </section>
    );
};
