//css
import "./header.css";

import logo from "../../../../../../../../assets/cplogo.png";

export const Header = () => {
    return (
        <section className="summaryHeader">
            <div className="row-prt2 ms-2">
                <div className="col-prt2-6">
                    <img src={logo} alt="Caring People" height="30px" width="39px" style={{ position: "absolute", top: "15px", left: "30px" }} />
                    &nbsp;&nbsp;
                    <span className="text-muted h5 ps-2" style={{ position: "absolute", top: "17px", left: "70px" }}>
                        Assessment Toolkit
                    </span>
                </div>
                <div className="col-prt-6 mt-3 text-end">Client Summary</div>
            </div>
            <div className="row-prt amt-2">
                <div className="col-prt-12">
                    <hr />
                </div>
            </div>
        </section>
    );
};
