//css
import "./fallRiskDialog.css";

//Icons
import icon from "../../../../assets/person-falling-burst-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import Select from "react-select";
import { updateFallRiskSection } from "../../../../api/nurseAssessment/fallRiskAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { ScoreFallRisk } from "../../../../components/scoreFallRisk/scoreFallRisk";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const FallRiskDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Fall Risk data state
    const [fallRiskData, setFallRiskData] = useState(null);

    //Fall Risk score  state
    const [score, setScore] = useState(10);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of Mobility
    const listOfMobility = [
        { label: "Requires assistance or supervision for mobility, transfer, or ambulation", value: "Requires assistance or supervision for mobility, transfer, or ambulation" },
        { label: "Unsteady gait", value: "Unsteady gait" },
        { label: "Visual or auditory impairment affecting mobility", value: "Visual or auditory impairment affecting mobility" },
        { label: "WNL", value: "WNL" },
    ];

    //list of Cognition
    const listOfCognition = [
        { label: "Altered awareness of immediate physical environment", value: "Altered awareness of immediate physical environment" },
        { label: "Impulsive", value: "Impulsive" },
        { label: "Lack of understanding of one's physical and cognitive limitations", value: "Lack of understanding of one's physical and cognitive limitations" },
        { label: "WNL", value: "WNL" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.fallRiskSection, status: data?.fallRiskSection?.status ?? "Applicable" };
        setFallRiskData(sectionData);
    }, [data]);

    useEffect(() => {
        let score = 0;

        switch (fallRiskData?.scoreCalculationAge) {
            case "60-69 years":
                score = score + 1;
                break;

            case "70-79 years":
                score = score + 2;
                break;

            case ">= 80":
                score = score + 3;
                break;

            default:
                score = score;
        }

        switch (fallRiskData?.scoreCalculationFallHistory) {
            case "One falls within 6 months before admission":
                score = score + 5;
                break;

            default:
                score = score;
        }

        switch (fallRiskData?.scoreCalculationEliminationBowelAndUrine) {
            case "Incontinence":
                score = score + 2;
                break;

            case "Urgency or frequency":
                score = score + 2;
                break;

            case "Urgency/frequency and incontinence":
                score = score + 4;
                break;

            default:
                score = score;
        }

        switch (fallRiskData?.scoreCalculationMedications) {
            case "On 1 high fall risk drug":
                score = score + 3;
                break;

            case "On 2 or more high fall risk drugs":
                score = score + 5;
                break;

            case "Sedated procedure within past 24 hours":
                score = score + 7;
                break;

            default:
                score = score;
        }

        switch (fallRiskData?.scoreCalculationPatientCareEquipment) {
            case "One present":
                score = score + 1;
                break;

            case "Two present":
                score = score + 2;
                break;

            case "Three or more present":
                score = score + 3;
                break;

            default:
                score = score;
        }

        fallRiskData?.scoreCalculationMobility?.forEach((mobility) => {
            switch (mobility) {
                case "Requires assistance or supervision for mobility, transfer, or ambulation":
                    score = score + 2;
                    break;

                case "Unsteady gait":
                    score = score + 2;
                    break;

                case "Visual or auditory impairment affecting mobility":
                    score = score + 2;
                    break;

                default:
                    score = score;
            }
        });

        fallRiskData?.scoreCalculationCognition?.forEach((cognition) => {
            switch (cognition) {
                case "Altered awareness of immediate physical environment":
                    score = score + 1;
                    break;

                case "Impulsive":
                    score = score + 2;
                    break;

                case "Lack of understanding of one's physical and cognitive limitations":
                    score = score + 4;
                    break;

                default:
                    score = score;
            }
        });

        setScore(score);
    }, [fallRiskData]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: fallRiskData?.id, status: fallRiskData?.status };
        setFallRiskData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...fallRiskData };

        //===========================================================

        //Saving WoundCareSection
        updateFallRiskSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", fallRiskSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const medicationsPopover = (
        <Popover id="medicationsPopover">
            <PopoverBody> Includes PCA/opiates, anticonvulsants, anti-hypertensives, diuretics, hypnotics, laxatives, sedatives, and psychotropics.</PopoverBody>
        </Popover>
    );

    const patientCareEquipmentPopover = (
        <Popover id="patientCareEquipmentPopover">
            <PopoverBody>Any equipment that tethers patient (e.g., IV infusion, chest tube, indwelling catheter, SCDs, etc.)</PopoverBody>
        </Popover>
    );

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...fallRiskData, [name]: value };
        setFallRiskData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...fallRiskData, [name]: checked };
        setFallRiskData(newData);
    };

    const handleRadioClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...fallRiskData, [name]: value };
        setFallRiskData(newData);
    };

    const handleScoreCalculationMobilityChange = (e) => {
        let mobilityNew = e?.map((i) => i.value);
        const newData = { ...fallRiskData, scoreCalculationMobility: mobilityNew };
        setFallRiskData(newData);
    };

    const handleScoreCalculationCognitionChange = (e) => {
        let cognitionNew = e?.map((i) => i.value);
        const newData = { ...fallRiskData, scoreCalculationCognition: cognitionNew };
        setFallRiskData(newData);
    };

    return (
        <Modal dialogClassName="fallRiskSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Fall Risk - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{fallRiskData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={fallRiskData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">High Fall Risk</span>
                            <div className="row">
                                <div className="col-12 pt-2 ms-4">
                                    <input name="historyMoreThanOneFall" id="historyMoreThanOneFall" type="checkbox" className="form-check-input" checked={fallRiskData?.historyMoreThanOneFall ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(fallRiskData?.status) || readOnly} />
                                    <label className="form-check-label ps-2" htmlFor="historyMoreThanOneFall">
                                        History of more than one fall within 6 months before admission
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 ms-4 me-0">
                                    <input name="patientDeemedHighFallRisk" id="patientDeemedHighFallRisk" type="checkbox" className="form-check-input" checked={fallRiskData?.patientDeemedHighFallRisk ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(fallRiskData?.status) || readOnly} />
                                    <label className="form-check-label ps-2" htmlFor="patientDeemedHighFallRisk">
                                        Patient is deemed high fall-risk per protocol (e.g., seizure precautions)
                                    </label>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-12">
                            <span className="my-1 d-block">Low Fall Risk</span>
                            <div className="row">
                                <div className="col-12 ms-4 me-0">
                                    <input name="completeParalysis" id="completeParalysis" type="checkbox" className="form-check-input" checked={fallRiskData?.completeParalysis ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(fallRiskData?.status) || readOnly} />
                                    <label className="form-check-label ps-2" htmlFor="completeParalysis">
                                        Complete paralysis or completely immobilized
                                    </label>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-4 m-0 p-0">
                            <span className="text-success">Fall Risk Score Calculation</span>
                        </div>
                        <div className="col-8 text-end">
                            <ScoreFallRisk score={score} />
                        </div>

                        <hr className="my-1" />
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Age</span>
                            {readOnly && <div className="text-success pb-2">{fallRiskData?.status === "Applicable" ? fallRiskData?.scoreCalculationAge ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="scoreCalculationAge" className="form-select" onChange={handleFieldChange} value={fallRiskData?.scoreCalculationAge ?? ""} disabled={!["Applicable"].includes(fallRiskData?.status)}>
                                        <option value="">Select</option>
                                        <option value="< 60">{"< 60"}</option>
                                        <option value="60-69 years">60-69 years</option>
                                        <option value="70-79 years">70-79 years</option>
                                        <option value=">= 80">>= 80</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Fall History</span>
                            {readOnly && <div className="text-success pb-2">{fallRiskData?.status === "Applicable" ? fallRiskData?.scoreCalculationFallHistory ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="scoreCalculationFallHistory" className="form-select" onChange={handleFieldChange} value={fallRiskData?.scoreCalculationFallHistory ?? ""} disabled={!["Applicable"].includes(fallRiskData?.status)}>
                                        <option value="">Select</option>
                                        <option value="No falls within 6 months of admission">No falls within 6 months of admission</option>
                                        <option value="One falls within 6 months before admission">One falls within 6 months before admission</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Elimination, Bowel and Urine</span>
                            {readOnly && <div className="text-success pb-2">{fallRiskData?.status === "Applicable" ? fallRiskData?.scoreCalculationEliminationBowelAndUrine ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="scoreCalculationEliminationBowelAndUrine" className="form-select" onChange={handleFieldChange} value={fallRiskData?.scoreCalculationEliminationBowelAndUrine ?? ""} disabled={!["Applicable"].includes(fallRiskData?.status)}>
                                        <option value="">Select</option>
                                        <option value="None">None</option>
                                        <option value="Incontinence">Incontinence</option>
                                        <option value="Urgency or frequency">Urgency or frequency</option>
                                        <option value="Urgency/frequency and incontinence">Urgency/frequency and incontinence</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">
                                Medications
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={medicationsPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>
                            {readOnly && <div className="text-success pb-2">{fallRiskData?.status === "Applicable" ? fallRiskData?.scoreCalculationMedications ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="scoreCalculationMedications" className="form-select" onChange={handleFieldChange} value={fallRiskData?.scoreCalculationMedications ?? ""} disabled={!["Applicable"].includes(fallRiskData?.status)}>
                                        <option value="">Select</option>
                                        <option value="None">None</option>
                                        <option value="On 1 high fall risk drug">On 1 high fall risk drug</option>
                                        <option value="On 2 or more high fall risk drugs">On 2 or more high fall risk drugs</option>
                                        <option value="Sedated procedure within past 24 hours">Sedated procedure within past 24 hours</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">
                                Patient Care Equipment
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={patientCareEquipmentPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>
                            {readOnly && <div className="text-success pb-2">{fallRiskData?.status === "Applicable" ? fallRiskData?.scoreCalculationPatientCareEquipment ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="scoreCalculationPatientCareEquipment" className="form-select" onChange={handleFieldChange} value={fallRiskData?.scoreCalculationPatientCareEquipment ?? ""} disabled={!["Applicable"].includes(fallRiskData?.status)}>
                                        <option value="">Select</option>
                                        <option value="None">None</option>
                                        <option value="One present">One present</option>
                                        <option value="Two present">Two present</option>
                                        <option value="Three or more present">Three or more present</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Mobility</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {fallRiskData?.status === "Applicable"
                                        ? fallRiskData?.scoreCalculationMobility?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select isMulti name="scoreCalculationMobility" id="scoreCalculationMobility" menuPlacement="bottom" options={listOfMobility} value={(fallRiskData?.scoreCalculationMobility ?? [])?.map((med) => listOfMobility?.find((i) => i.value === med))} menuPortalTarget={document.body} styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} onChange={handleScoreCalculationMobilityChange} isDisabled={!["Applicable"].includes(fallRiskData?.status)}></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Cognition</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {fallRiskData?.status === "Applicable"
                                        ? fallRiskData?.scoreCalculationCognition?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select isMulti name="scoreCalculationCognition" id="scoreCalculationCognition" menuPlacement="bottom" options={listOfCognition} value={(fallRiskData?.scoreCalculationCognition ?? [])?.map((med) => listOfCognition?.find((i) => i.value === med))} menuPortalTarget={document.body} styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} onChange={handleScoreCalculationCognitionChange} isDisabled={!["Applicable"].includes(fallRiskData?.status)}></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, fallRiskSection: data?.fallRiskSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
