import React from "react";
import { ClientSection } from "./components/clientSection/clientSection";
import { Header } from "./components/header/header";
import { SummaryAssistiveDevices } from "./components/summaryAssistiveDevices/summaryAssistiveDevices";
import { SummaryBradenScale } from "./components/summaryBradenScale/summaryBradenScale";
import { SummaryCardiopulmonaryAssessment } from "./components/summaryCardiopulmonary/summaryCardiopulmonary";
import { SummaryCaregiverClinicalOrientation } from "./components/summaryCaregiverClinicalOrientation/summaryCaregiverClinicalOrientation";
import { SummaryComments } from "./components/summaryComments/summaryComments";
import { SummaryDiabeticFocusExam } from "./components/summaryDiabeticFocusExam/summaryDiabeticFocusExam";
import { SummaryDepressionScreen } from "./components/summaryDepressionScreen/summaryDepressionScreen";
import { SummaryFallRisk } from "./components/summaryFallRisk/summaryFallRisk";
import { SummaryGastrointestinal } from "./components/summaryGastrointestinal/summaryGastrointestinal";
import { SummaryGenitourinary } from "./components/summaryGenitourinary/summaryGenitourinary";
import { SummaryHomeSafety } from "./components/summaryHomeSafety/summaryHomeSafety";
import { SummaryInterventionsInstructionsExam } from "./components/summaryInterventionsInstructions/summaryInterventionsInstructions";
import { SummaryMedication } from "./components/summaryMedications/summaryMedications";
import { SummaryNeuromuscularAssessment } from "./components/summaryNeuromuscular/summaryNeuromuscular";
import { SummaryNurseOrdersAssessment } from "./components/summaryNurseOrders/summaryNurseOrders";
import { SummaryNurseReviewAssessment } from "./components/summaryNurseReview/summaryNurseReview";
import { SummaryPhysicalAssessment } from "./components/summaryPhysical/summaryPhysical";
import { SummaryResponseTeachingExam } from "./components/summaryResponseTeaching/summaryResponseTeaching";
import { SummarySkilledNurseVisitNote } from "./components/summarySkilledNurseVisitNote/summarySkilledNurseVisitNote";
import { SummarySkilledObservationAssessment } from "./components/summarySkilledObservationAssessment/summarySkilledObservationAssessment";
import { SummaryWoundCare } from "./components/summaryWoundCare/summaryWoundCare";

export class AssessmentSummary extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <ClientSection data={{ name: this.props?.data?.patient, phone: this.props?.data?.contactPhone, address: this.props?.data?.address, type: this.props?.data?.assessmentType, status: this.props?.data?.status, date: this.props?.data?.appointmentDate, client: this.props?.data?.client, careGiverName: this.props?.data?.worker?.fullName }} />
                {this.props?.data?.skilledNurseVisitNoteSection && <SummarySkilledNurseVisitNote data={this.props?.data?.skilledNurseVisitNoteSection} />}
                {this.props?.data?.skilledObservationSection && <SummarySkilledObservationAssessment data={this.props?.data?.skilledObservationSection} />}
                {this.props?.data?.cardiopulmonarySection && <SummaryCardiopulmonaryAssessment data={this.props?.data?.cardiopulmonarySection} />}
                {this.props?.data?.neuromuscularSection && <SummaryNeuromuscularAssessment data={this.props?.data?.neuromuscularSection} />}
                {this.props?.data?.woundCareSection && <SummaryWoundCare data={this.props?.data?.woundCareSection} />}
                {this.props?.data?.gastrointestinalSection && <SummaryGastrointestinal data={this.props?.data?.gastrointestinalSection} />}
                {this.props?.data?.genitourinarySection && <SummaryGenitourinary data={this.props?.data?.genitourinarySection} />}
                {this.props?.data?.medicationsSection && <SummaryMedication data={this.props?.data?.medicationsSection} />}
                {this.props?.data?.assistiveDevicesSection && <SummaryAssistiveDevices data={this.props?.data?.assistiveDevicesSection} />}
                {this.props?.data?.diabeticExamSection && <SummaryDiabeticFocusExam data={this.props?.data?.diabeticExamSection} />}
                {this.props?.data?.interventionsInstructionsSection && <SummaryInterventionsInstructionsExam data={this.props?.data?.interventionsInstructionsSection} />}
                {this.props?.data?.responseTeachingSection && <SummaryResponseTeachingExam data={this.props?.data?.responseTeachingSection} />}
                {this.props?.data?.nurseOrdersSection && <SummaryNurseOrdersAssessment data={this.props?.data?.nurseOrdersSection} />}
                {this.props?.data?.nurseReviewSection && <SummaryNurseReviewAssessment data={this.props?.data?.nurseReviewSection} />}
                {this.props?.data?.physicalSection && <SummaryPhysicalAssessment data={this.props?.data?.physicalSection} />}
                {this.props?.data?.caregiverClinicalOrientationSection && <SummaryCaregiverClinicalOrientation data={{ ...this.props?.data?.caregiverClinicalOrientationSection, worker: this.props?.data?.worker }} />}
                {this.props?.data?.bradenScaleSection && <SummaryBradenScale data={this.props?.data?.bradenScaleSection} />}
                {this.props?.data?.fallRiskSection && <SummaryFallRisk data={this.props?.data?.fallRiskSection} />}
                {this.props?.data?.depressionScreenSection && <SummaryDepressionScreen data={this.props?.data?.depressionScreenSection} />}
                {this.props?.data?.homeSafetySection && <SummaryHomeSafety data={this.props?.data?.homeSafetySection} />}
                {this.props?.data?.commentsSection && <SummaryComments data={this.props?.data?.commentsSection} />}
            </div>
        );
    }
}
