import "./clientProfileSummary.css";

import React from "react";
import { SummaryMedication } from "../../../../../patientAssessments/components/summaryDialog/components/assessmentSummary/components/summaryMedications/summaryMedications";
import { ClientSection } from "./components/clientSection/clientSection";
import { Header } from "./components/header/header";

export class ClientProfileSummary extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <ClientSection data={{ name: this.props?.data?.patient, phone: this.props?.data?.contactPhone, address: this.props?.data?.address, allergies: this.props?.data?.allergies, specialNotes: this.props?.data?.specialNotes }} />
                {this.props?.data?.medicationsSection && <SummaryMedication data={this.props?.data?.medicationsSection} />}
            </div>
        );
    }
}
