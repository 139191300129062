import icon from "../../../../../../../../assets/toilet-solid-red.svg";

export const SummaryGenitourinary = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Genitourinary
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Disorders</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.disorders?.length === 0 || !data?.disorders) && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 6,
                                }}
                            >
                                {data?.disorders?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Dialysis Patient</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-2 fw-bold">Bruit</div>
                                <div className="col-prt-2 fw-bold">Thrill</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-2 text-primary">{data?.bruit ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.thrill ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Urostomy</div>
                        <div className="col-prt-4 fw-bold">Continent Urinary Diversion</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.urostomy ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.continentUrinaryDiversion ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Type of Stoma</div>
                        <div className="col-prt-4 fw-bold">Appearance of Stoma</div>
                        <div className="col-prt-4 fw-bold">Color of Stoma Mucosa</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.typeOfStoma ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.appearanceOfStoma ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.colorOfStoma ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Stints/Rods/Drains</div>
                        <div className="col-prt-2 fw-bold">Height of Stoma</div>
                        <div className="col-prt-4 fw-bold">
                            Size of Stoma <small>(mm)</small>
                        </div>
                        <div className="col-prt-4 fw-bold">Stoma/Peristomal Skin Junction</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.stintsRodsDrains ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.heightOfStoma ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.sizeOfStoma ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.stomaPeristomalSkinJunction ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Lumen (#)</div>
                        <div className="col-prt-4 fw-bold">Location of Lumen</div>
                        <div className="col-prt-2 fw-bold">Odor</div>
                        <div className="col-prt-4 fw-bold">Stoma Functioning Status</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.numLumen ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.locationOfLumen ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.odor ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.functioningStatusStoma ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Peristomal Assessment</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-2 fw-bold">Integrity</div>
                                <div className="col-prt-2 fw-bold">Lesions</div>
                                <div className="col-prt-2 fw-bold">Texture</div>
                                <div className="col-prt-2 fw-bold">Turgor</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-2 text-primary">{data?.peristomalIntegrity ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.peristomalLesions ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.peristomalTexture ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.turgor ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Peristomal Pain (PQRST Assessment)</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-2 fw-bold">(P) Provocation / Palliation</div>
                                <div className="col-prt-2 fw-bold">(Q) Quality/Quantity</div>
                                <div className="col-prt-2 fw-bold">(R) Region/Radiation</div>
                                <div className="col-prt-2 fw-bold">(S) Severity Scale</div>
                                <div className="col-prt-2 fw-bold">(T) Timing</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-2 text-primary">{data?.peristomalPainP ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.peristomalPainQ ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.peristomalPainR ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.peristomalPainS ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.peristomalPainT ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Foley Catheter</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-3 fw-bold">Catheter Type</div>
                                <div className="col-prt-2 fw-bold">
                                    Size <small>(Fr)</small>
                                </div>
                                <div className="col-prt-3 fw-bold">
                                    Foley Irrigation <small>(mL)</small>
                                </div>
                                <div className="col-prt-2 fw-bold">Urine Color</div>
                                <div className="col-prt-2 fw-bold">Consistency</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-3 text-primary">{data?.catheterType ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.catheterSize ?? "-"}</div>
                                <div className="col-prt-3 text-primary">{data?.foleyIrrigation ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.urineColor ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.consistency ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-3 fw-bold">To be changed by</div>
                                <div className="col-prt-2 fw-bold">Odor</div>
                                <div className="col-prt-3 fw-bold">
                                    Output <small>(mL)</small>
                                </div>
                                <div className="col-prt-3 fw-bold">Foley Change Date</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-3 text-primary">{data?.foleyToBeChangedBy ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.foleyOdor ?? "-"}</div>
                                <div className="col-prt-3 text-primary">{data?.foleyOutput ?? "-"}</div>
                                <div className="col-prt-3 text-primary">{data?.foleyChangeDate?.split("T")[0] ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Comments</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.comments ?? "-"}</div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
