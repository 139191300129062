import { acquireAccessToken, acquireMSALTokens } from "../lib/acquireAccessToken";
import { fileTimeStamp } from "../lib/fileTimeStamp";
import { Http_BadRequest, Http_OK } from "../lib/httpStatusCodes";
import { parseJwt } from "../lib/parseJwt";
import { sortJson } from "../lib/sortJson";
import { headers } from "./headers";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}Visit`;

//=======================================================================

export const completeVisit = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/CompleteVisit/${data.id}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to update data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const updateVisit = (data) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${data.id}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to update data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getAllVisits = async () => {
    const tokens = await acquireMSALTokens();
    //const token = await acquireAccessToken();
    //console.log("access token 2", tokens.accessToken);
    //console.log("id token 2", tokens.idToken);
    headers.Authorization = tokens?.accessToken ? `bearer ${tokens.accessToken}` : null;
    headers.AuthorizationIdToken = tokens?.idToken ? `bearer ${tokens.idToken}` : null;
    return new Promise((resolve, reject) => {
        fetch(apiUrl, { method: "GET", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                //get MSAL user roles
                                const userRoles = parseJwt(tokens.idToken)?.roles;

                                //sorting data by name
                                let data = sortJson(json.data, "patient", "string", true);
                                //converting date
                                data = data.map((obj) => {
                                    const myLocalDate = new Date(obj?.appointmentDate + "Z").toLocaleString();
                                    return { ...obj, appointmentDate: myLocalDate };
                                });

                                data = { displayAssignedToField: userRoles?.includes("Supervisor"), data: data };

                                return res(data);
                            });
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================

export const getVisitById = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getVisitSummaryForm = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/VisitSummaryPdf/${data?.id}`, {
            method: "GET",
            headers: headers,
            //body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            rej("Bad Request Error: Failed to download");
                            break;

                        case Http_OK:
                            return res(response.blob());
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((blob) => {
                //1. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                console.log("data", data);
                link.setAttribute("download", `VisitSummary_${data?.patientName}_${fileTimeStamp()}.pdf`);
                link.href = url;

                //2. Append to html page
                document.body.appendChild(link);

                //3. Force download
                link.click();

                //4. Clean up and remove link
                link.parentNode.removeChild(link);

                resolve();
            })
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================

export const getChangeOrderForm = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/ChangeOrderPdf/${data?.id}`, {
            method: "GET",
            headers: headers,
            //body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            rej("Bad Request Error: Failed to download");
                            break;

                        case Http_OK:
                            return res(response.blob());
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((blob) => {
                //1. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                console.log("data", data);
                link.setAttribute("download", `ChangeOrder_${data?.patientName}_${fileTimeStamp()}.pdf`);
                link.href = url;

                //2. Append to html page
                document.body.appendChild(link);

                //3. Force download
                link.click();

                //4. Clean up and remove link
                link.parentNode.removeChild(link);

                resolve();
            })
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================
