//Icons
import iconDepressionScreenGreen from "../../../assets/cloud-showers-heavy-solid-green.svg";
import iconDepressionScreenRed from "../../../assets/cloud-showers-heavy-solid-red.svg";

import { updateDepressionScreenSection } from "../../../api/nurseAssessment/depressionScreenAPI";
import { isError } from "../../../lib/isError";

export const getDepressionScreenSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowDepressionScreenDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateDepressionScreenSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving DepressionScreen Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.depressionScreenSection, status: status };
        const saveObj = { id: patient?.depressionScreenSection?.id, status: status };
        updateDepressionScreenSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", depressionScreenSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Depression Screen",
        description: null,
        progress: data?.depressionScreenSection?.progress,
        status: data?.depressionScreenSection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconDepressionScreenRed,
        iconCompleted: iconDepressionScreenGreen,
        saving: false,
        onClick: () => {
            setShowDepressionScreenDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateDepressionScreenSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateDepressionScreenSectionStatus(notApplicable);
            }
        },
    };
};
