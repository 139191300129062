//css
import "./caregiverClinicalOrientationDialog.css";

//Icons
import icon from "../../../../assets/caregiver-clinical-orientation-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { updateCaregiverClinicalOrientationSection } from "../../../../api/nurseAssessment/caregiverClinicalOrientationAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";
import { getAllActiveWorkers } from "../../../../api/workerAPI";
import { callMsGraph } from "../../../../graph";
import { loginRequest } from "../../../../authConfig";
import { useMsal } from "@azure/msal-react";

export const CaregiverClinicalOrientationDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //CaregiverClinicalOrientation data state
    const [caregiverClinicalOrientationData, setCaregiverClinicalOrientationData] = useState(null);

    //CaregiverClinicalOrientation data state
    const [caregiversData, setCaregiversData] = useState([]);

    //Graph data with logged user information
    const [graphData, setGraphData] = useState(null);
    const { instance, accounts } = useMsal();

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        //Get info user logged in
        RequestProfileData();

        getAllActiveWorkers()
            .then((data) => {
                console.log("Workers", data);

                data = data.map((item) => {
                    return { value: item.id, text: item.fullName };
                });

                setCaregiversData(data);
            })
            .catch((err) => {
                try {
                    setCaregiversData([]);
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
            });
    }, []);

    useEffect(() => {
        const sectionData = { ...data?.caregiverClinicalOrientationSection, status: data?.caregiverClinicalOrientationSection?.status ?? "Applicable" };
        setCaregiverClinicalOrientationData(sectionData);
    }, [data]);

    //list of observations
    const listOfObservations = [
        { label: "Aide is Competent to Carry Out Plan of Care", value: "Aide is Competent to Carry Out Plan of Care" },
        { label: "Ambulation", value: "Ambulation" },
        { label: "Bathing", value: "Bathing" },
        { label: "Blood", value: "Blood" },
        { label: "Blood Sugar", value: "Blood Sugar" },
        { label: "Blood Pressure", value: "Blood Pressure" },
        { label: "Clinical Skills Observed/Reviewed", value: "Clinical Skills Observed/Reviewed" },
        { label: "Cognitive/Mental Status", value: "Cognitive/Mental Status" },
        { label: "Contact Guard", value: "Contact Guard" },
        { label: "Feeding", value: "Feeding" },
        { label: "Flu prevention protocol reviewed (if applicable)", value: "Flu prevention protocol reviewed (if applicable)" },
        { label: "Foot Care", value: "Foot Care" },
        { label: "Grooming", value: "Grooming" },
        { label: "Handwashing", value: "Handwashing" },
        { label: "Hoyer Lift", value: "Hoyer Lift" },
        { label: "Meal Preparation", value: "Meal Preparation" },
        { label: "Nail Care", value: "Nail Care" },
        { label: "Name Badge", value: "Name Badge" },
        { label: "Nursing Supervisor Signs/Dates Care Plan/Nursing Plan of Care", value: "Nursing Supervisor Signs/Dates Care Plan/Nursing Plan of Care" },
        { label: "Oral Hygiene", value: "Oral Hygiene" },
        { label: "Pain", value: "Pain" },
        { label: "Plan of Care Reviewed", value: "Plan of Care Reviewed" },
        { label: "Pressure", value: "Pressure" },
        { label: "Proper Attire", value: "Proper Attire" },
        { label: "Pulse", value: "Pulse" },
        { label: "Respiration", value: "Respiration" },
        { label: "ROM Exercises", value: "ROM Exercises" },
        { label: "Safety management program reviewed", value: "Safety management program reviewed" },
        { label: "Signs/Symptoms of infection reviewed", value: "Signs/Symptoms of infection reviewed" },
        { label: "Skin Care", value: "Skin Care" },
        { label: "Temperature", value: "Temperature" },
        { label: "Toileting", value: "Toileting" },
        { label: "Transfer", value: "Transfer" },
        { label: "Weight", value: "Weight" },
        { label: "Other", value: "Other" },
    ];

    //=== Methods =============================================================

    const RequestProfileData = () => {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance
            .acquireTokenSilent(request)
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            })
            .catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    callMsGraph(response.accessToken).then((response) => setGraphData(response));
                });
            });
    };

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: caregiverClinicalOrientationData?.id, status: caregiverClinicalOrientationData?.status };
        setCaregiverClinicalOrientationData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...caregiverClinicalOrientationData };

        //===========================================================

        //Saving CaregiverClinicalOrientationSection
        updateCaregiverClinicalOrientationSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", caregiverClinicalOrientationSection: ret };
                console.log("updateCaregiverClinicalOrientationSection", newData);
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...caregiverClinicalOrientationData, [name]: value };
        setCaregiverClinicalOrientationData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...caregiverClinicalOrientationData, [name]: checked };
        setCaregiverClinicalOrientationData(newData);
    };

    const handleRadioClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...caregiverClinicalOrientationData, [name]: value };
        setCaregiverClinicalOrientationData(newData);
    };

    const handleObservationsChange = (e) => {
        let observationsNew = e?.map((i) => i.value);
        const newData = { ...caregiverClinicalOrientationData, observations: observationsNew };
        setCaregiverClinicalOrientationData(newData);
    };

    return (
        <Modal dialogClassName="caregiverClinicalOrientationSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Caregiver Clinical Orientation - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{caregiverClinicalOrientationData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={caregiverClinicalOrientationData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Caregiver Name</span>
                            {readOnly && <div className="text-success pb-2">{caregiverClinicalOrientationData?.status === "Applicable" ? caregiverClinicalOrientationData?.worker?.fullName ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="workerId" className="form-select" onChange={handleFieldChange} value={caregiverClinicalOrientationData?.workerId ?? ""} disabled={!["Applicable"].includes(caregiverClinicalOrientationData?.status)}>
                                        <option value="">Select</option>
                                        {caregiversData.map((item, index) => (
                                            <option key={index} value={item.value}>
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3">
                            <span className="my-1 d-block">Caregiver Position</span>
                            {readOnly && <div className="text-success pb-2">{caregiverClinicalOrientationData?.status === "Applicable" ? caregiverClinicalOrientationData?.caregiverPosition ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="caregiverPosition" className="form-select" onChange={handleFieldChange} value={caregiverClinicalOrientationData?.caregiverPosition ?? ""} disabled={!["Applicable"].includes(caregiverClinicalOrientationData?.status)}>
                                        <option value="">Select</option>
                                        <option value="CNA">CNA</option>
                                        <option value="HHA">HHA</option>
                                        <option value="LPN">LPN</option>
                                        <option value="PCA">PCA</option>
                                        <option value="RN">RN</option>
                                        <option value="Companion">Companion</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3">
                            <span className="my-1 d-block">Clinical Orientation Type</span>
                            {readOnly && <div className="text-success pb-2">{caregiverClinicalOrientationData?.status === "Applicable" ? caregiverClinicalOrientationData?.clinicalOrientationType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="clinicalOrientationType" className="form-select" onChange={handleFieldChange} value={caregiverClinicalOrientationData?.clinicalOrientationType ?? ""} disabled={!["Applicable"].includes(caregiverClinicalOrientationData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Orientation">Orientation</option>
                                        <option value="Supervision">Supervision</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3">
                            <span className="my-1 d-block">Location</span>
                            {readOnly && <div className="text-success pb-2">{caregiverClinicalOrientationData?.status === "Applicable" ? caregiverClinicalOrientationData?.location ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="location" className="form-select" onChange={handleFieldChange} value={caregiverClinicalOrientationData?.location ?? ""} disabled={!["Applicable"].includes(caregiverClinicalOrientationData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Home">Home</option>
                                        <option value="Office">Office</option>
                                        <option value="Telephone">Telephone</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Supervisor</span>
                            <div className="text-success pb-2">
                                {data?.worker?.fullName ?? "-"}
                                {/*graphData?.displayName ?? "-"*/}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Observations</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {caregiverClinicalOrientationData?.status === "Applicable"
                                        ? caregiverClinicalOrientationData?.observations?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="observations"
                                        id="observations"
                                        menuPlacement="bottom"
                                        options={listOfObservations}
                                        //value={listOfSymptoms.value}
                                        //defaultValue={caregiverClinicalOrientationData?.symptoms?.map((med) => listOfSymptoms?.find((i) => i.value === med))}
                                        value={(caregiverClinicalOrientationData?.observations ?? [])?.map((med) => listOfObservations?.find((i) => i.value === med))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleObservationsChange}
                                        isDisabled={!["Applicable"].includes(caregiverClinicalOrientationData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Other Observation</span>
                            {readOnly && <div className="text-success pb-2">{caregiverClinicalOrientationData?.observationOther ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="observationOther" placeholder={`Enter Details`} onChange={handleFieldChange} value={caregiverClinicalOrientationData?.observationOther ?? ""} maxLength="300" disabled={!["Applicable"].includes(caregiverClinicalOrientationData?.status) || !caregiverClinicalOrientationData?.observations?.includes("Other")} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <label className="my-1 d-block" htmlFor="comments">
                                Instructions Related to Plan of Care
                            </label>
                            {readOnly && <div className="text-success pb-2">{caregiverClinicalOrientationData?.status === "Applicable" ? caregiverClinicalOrientationData?.instructionsPlanOfCare ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <textarea name="instructionsPlanOfCare" id="instructionsPlanOfCare" className="form-control" placeholder={`Instructions`} value={caregiverClinicalOrientationData?.instructionsPlanOfCare ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(caregiverClinicalOrientationData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                {console.log("datax", data)}
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, worker: data?.worker, caregiverClinicalOrientationSection: data?.caregiverClinicalOrientationSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
