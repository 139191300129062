//=== Style =====
import "./clientMAR.css";
import "react-datepicker/dist/react-datepicker.css";

import { useEffect, useState } from "react";
import { isError } from "../../../../lib/isError";
import ReactDatePicker from "react-datepicker";
import { createClientMAR, getClientMARAuditLog, getClientMARByClientAndPeriod, getClientMARById } from "../../../../api/clientMARAPI";
import { ClientMARTable } from "./components/clientMARTable/clientMARTable";
import { MARLegend } from "./components/marLegend/MARLegend";
import { MARLog } from "./components/marLog/marLog";
import { Accordion } from "react-bootstrap";
import { Loading } from "../../../../components/loading/loading";
import { ErrorMessage } from "../../../../components/errorMessage/errorMessage";

export const ClientMAR = ({ clientData }) => {
    //= States ===================================================================

    const [data, setData] = useState(null);
    const [logData, setLogData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingComponent, setLoadingComponent] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [creatingMAR, setCreatingMAR] = useState(false);

    //==========================================================================

    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleButtonClick = () => {
        // Chamar a função loadClientMARById passando o valor do input como parâmetro
        loadClientMARById(inputValue);
    };

    //= Use Effects ==============================================================

    useEffect(() => {
        loadClientMARLog(data?.id);
    }, [data]); // eslint-disable-line

    //=== Methods ================================================================

    const loadClientMARById = async (id) => {
        setLoadingError(null);
        setLogData(null);
        setLoading(true);
        setData(null);

        await getClientMARById(id)
            .then((MARData) => {
                setData(MARData);
                setLoadingComponent(false);
                console.log("MARData", MARData);
                //if (MARData?.id) loadClientMARLog(MARData?.id);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
            })
            .finally((_) => setLoading(false));
    };

    const loadClientMAR = async (data) => {
        setLoadingError(null);
        setLogData(null);
        setLoading(true);
        setData(null);

        await getClientMARByClientAndPeriod(data)
            .then((MARData) => {
                setData(MARData);
                setLoadingComponent(false);
                console.log("MARData", MARData);
                //if (MARData?.id) loadClientMARLog(MARData?.id);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
            })
            .finally((_) => setLoading(false));
    };

    const loadClientMARLog = async (id) => {
        setLoadingError(null);
        setLoading(true);

        await getClientMARAuditLog(id)
            .then((logData) => {
                const MARLogData = logData.map((item) => {
                    //before store my log, I must convert string to object
                    item.keyValues = JSON.parse(item.keyValues);
                    item.oldValues = JSON.parse(item.oldValues);
                    item.newValues = JSON.parse(item.newValues);
                    item.changes = JSON.parse(item.changes);

                    //now, getting Medication and period from MARData
                    //console.log("data in", data);
                    //console.log("data?.medicationRecordsMAR", data?.medicationRecordsMAR);
                    //console.log("item", item);
                    const medRecord = data?.medicationRecordsMAR.filter((rec) => rec.id === item.keyValues.Id);
                    console.log("item.keyValues.id", item.keyValues.Id);
                    console.log("medRecord", medRecord);

                    item.period = medRecord[0]?.period;
                    item.medication = medRecord[0]?.medication?.name;

                    console.log("item", item);

                    if (item.medication) return item;
                });

                setLogData(MARLogData);
            })
            .catch((err) => {
                try {
                    if (err !== "Error to fetch data (404)") setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
            })
            .finally((_) => setLoading(false));
    };

    const addNewClientMAR = async (data) => {
        setLoadingError(null);
        setCreatingMAR(true);

        await createClientMAR(data)
            .then((MARData) => {
                setData(MARData);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error creating MAR");
                }
            })
            .finally((_) => setCreatingMAR(false));
    };

    const getDistinctInitials = (jsonData) => {
        const distinctValues = new Set();

        jsonData?.medicationRecordsMAR.forEach((record) => {
            for (let day = 1; day <= 31; day++) {
                const value = record[`day${day}`];
                if (value !== "-" && !distinctValues.has(value)) {
                    distinctValues.add(value);
                }
            }
        });

        return Array.from(distinctValues);
    };

    //= Handlers =================================================================

    const handleDateChange = (date) => {
        setLoadingComponent(true);
        setSelectedDate(date);

        const searchData = { clientId: clientData?.id, month: date.getMonth() + 1, year: date.getFullYear() };
        loadClientMAR(searchData);
    };

    const handleCreateMARClick = () => {
        const date = selectedDate;

        const newData = { clientId: clientData?.id, month: date.getMonth() + 1, year: date.getFullYear() };

        addNewClientMAR(newData);
    };

    //= Render ===================================================================

    return (
        <>
            <div className="container-fluid">
                {/*
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <input type="text" className="form-control mb-3" placeholder="Digite o ID do cliente" value={inputValue} onChange={handleInputChange} />
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-primary mb-3" onClick={handleButtonClick}>
                                Carregar ClientMAR
                            </button>
                        </div>
                    </div>
                </div>
                */}

                <div className="row align-items-center pb-0 mb-0">
                    <div className="col-md-6">
                        <h5 className="pt-3 text-success ps-3">Medication Administration Record</h5>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        {loading === true ? (
                            <Loading />
                        ) : (
                            <>
                                {loadingError && (
                                    <span className="mt-3 mb-1 me-1">
                                        <ErrorMessage msg={loadingError} size="small" />
                                    </span>
                                )}

                                <ReactDatePicker selected={selectedDate} onChange={handleDateChange} dateFormat="MM/yyyy" showMonthYearPicker id="period" className="form-control mt-3" customInput={<button className="btn btn-sm btn-primary border">{selectedDate ? selectedDate.toLocaleDateString("en-US", { month: "short", year: "numeric" }) : "Select Month and Year"}</button>} />
                            </>
                        )}
                    </div>
                </div>
                <hr className="pt-0 mt-0" />

                <section id="ClientMAR">
                    <div className="acontainer mt-2 mx-0 px-0">
                        <div className="row">
                            {!data?.id && selectedDate /*&& selectedDate.getMonth() >= new Date().getMonth() && selectedDate.getFullYear() >= new Date().getFullYear() */ && !loading && !loadingError && (
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary" onClick={handleCreateMARClick}>
                                        Create MAR ({selectedDate.toLocaleDateString("en-US", { month: "short", year: "numeric" })})
                                    </button>
                                </div>
                            )}

                            {data?.id && <ClientMARTable clientMARData={data} setClientMARData={setData} selectedDate={selectedDate} />}
                            {/*selectedDate && (selectedDate.getMonth() !== new Date().getMonth() || selectedDate.getFullYear() !== new Date().getFullYear()) && !data?.id && <div className="text-center">MAR not available for this Client/Month</div>*/}
                        </div>
                        <hr />

                        <Accordion defaultActiveKey="-1">
                            {data && (
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Initials Legend</Accordion.Header>
                                    <Accordion.Body>
                                        <MARLegend initials={getDistinctInitials(data)} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                            {logData && (
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Change Log</Accordion.Header>
                                    <Accordion.Body>
                                        <MARLog logData={logData} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                        </Accordion>
                    </div>
                </section>
            </div>
        </>
    );
};
