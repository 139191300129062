//Icons
import iconCaregiverClinicalOrientationGreen from "../../../assets/caregiver-clinical-orientation-green.svg";
import iconCaregiverClinicalOrientationRed from "../../../assets/caregiver-clinical-orientation-red.svg";

import { updateCaregiverClinicalOrientationSection } from "../../../api/nurseAssessment/caregiverClinicalOrientationAPI";
import { isError } from "../../../lib/isError";

export const getCaregiverClinicalOrientationSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowCaregiverClinicalOrientationDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateCaregiverClinicalOrientationSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving CaregiverClinicalOrientation Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.caregiverClinicalOrientationSection, status: status };
        const saveObj = { id: patient?.caregiverClinicalOrientationSection?.id, status: status };
        updateCaregiverClinicalOrientationSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", caregiverClinicalOrientationSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Caregiver Clinical Orientation",
        description: null,
        progress: data?.caregiverClinicalOrientationSection?.progress,
        status: data?.caregiverClinicalOrientationSection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconCaregiverClinicalOrientationRed,
        iconCompleted: iconCaregiverClinicalOrientationGreen,
        saving: false,
        onClick: () => {
            setShowCaregiverClinicalOrientationDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateCaregiverClinicalOrientationSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateCaregiverClinicalOrientationSectionStatus(notApplicable);
            }
        },
    };
};
