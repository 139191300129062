//=== Style =====
import "./clientMARTable.css";

import { useEffect, useState } from "react";
import { isError } from "../../../../../../lib/isError";
import { AddMedicationDialog } from "./components/addMedicationDialog/addMedicationDialog";
import { ClientMARTableBody } from "./components/clientMARTableBody/clientMARTableBody";
import { MedicationAdministrationDialog } from "./components/medicationAdministrationDialog/medicationAdministrationDialog";
import { getMARReport } from "../../../../../../api/clientMARAPI";
import { DownloadMARButton } from "../../../../../../components/downloadMARButton/downloadMARButton";

export const ClientMARTable = ({ clientMARData, setClientMARData, selectedDate }) => {
    //= States ===================================================================

    const [data, setData] = useState(null);
    //const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [downloadingReport, setDownloadingReport] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [creatingMAR, setCreatingMAR] = useState(false);
    const [selectedMedicationAdministrationPeriod, setSelectedMedicationAdministrationPeriod] = useState(null);

    const [showAddMedicationDialog, setShowAddMedicationDialog] = useState(false);
    const [showMedicationAdministrationDialog, setShowMedicationAdministrationDialog] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(false);

    //= Use Effects ==============================================================

    useEffect(() => {}, [data]); // eslint-disable-line

    //=== Methods ================================================================

    const handleDownloadReportClick = async () => {
        setDownloadingReport(true);

        const dataReport = { id: clientMARData.id };

        await getMARReport(dataReport)
            .then((ret) => {})
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally((_) => {
                setDownloadingReport(false);
            });
    };

    const loadClientMAR = async (data) => {
        setLoadingError(null);
        setLoading(true);

        /*
        await getClientMARByClientAndPeriod(data)
            .then((MARData) => {
                setData(MARData);
                if (!MARData) {
                    addNewClientMAR(data);
                }
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
            })
            .finally((_) => setLoading(false));
            */
    };

    const addNewClientMAR = async (data) => {
        setLoadingError(null);
        setCreatingMAR(true);

        /*
        await createClientMAR(data)
            .then((MARData) => {
                setData(MARData);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error creating MAR");
                }
            })
            .finally((_) => setCreatingMAR(false));
            */
    };

    //= Handlers =================================================================

    //= Render ===================================================================

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3 pb-4 ps-4 me-0 pe-0">
                        <button
                            class="btn btn-primary btn-sm me-2"
                            onClick={() => {
                                setShowAddMedicationDialog(true);
                            }}
                        >
                            Add Medication
                        </button>
                        <DownloadMARButton className="btn btn-outline-success btn-sm" handleDownload={handleDownloadReportClick} loading={downloadingReport} />
                    </div>

                    <div className="col-9 pb-4 ps-0 text-end small">
                        <span className="pe-3">Legend:</span>
                        <span className="MAR-legend-item">
                            Administered <sup>(1)</sup>
                        </span>
                        <span className="MAR-legend-item">
                            Pre-pour <sup>(2)</sup>
                        </span>
                        <span className="MAR-legend-item medicationMAR-value-H">
                            Hold<sup>(3)</sup>
                        </span>

                        <span className="MAR-legend-item medicationMAR-value-NA">
                            Not Available<sup>(4)</sup>
                        </span>
                        <span className="MAR-legend-item medicationMAR-value-NT">
                            Not Taken<sup>(5)</sup>
                        </span>
                        <span className="MAR-legend-item medicationMAR-value-R">
                            Refused<sup>(6)</sup>
                        </span>
                        <span className="MAR-legend-item medicationMAR-value-DC">
                            Discontinued<sup>(7)</sup>
                        </span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <table className="table MARTable">
                            <thead>
                                <tr>
                                    <th>Medication</th>
                                    <th>Period</th>
                                    {[...Array(31)].map((_, index) => (
                                        <th key={index + 1} className="text-center">
                                            {index + 1}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <ClientMARTableBody medicationRecordsMAR={clientMARData?.medicationRecordsMAR} selectedDate={selectedDate} setClientMARData={setClientMARData} setShowMedicationAdministrationDialog={setShowMedicationAdministrationDialog} setSelectedMedicationAdministrationPeriod={setSelectedMedicationAdministrationPeriod} />
                        </table>
                    </div>
                </div>
            </div>
            {showAddMedicationDialog && <AddMedicationDialog clientId={clientMARData?.clientId} clientMARId={clientMARData?.id} closeDialog={() => setShowAddMedicationDialog(false)} setClientMARData={setClientMARData} />}
            {showMedicationAdministrationDialog && <MedicationAdministrationDialog selectedMedicationAdministrationPeriod={selectedMedicationAdministrationPeriod} closeDialog={() => setShowMedicationAdministrationDialog(false)} setClientMARData={setClientMARData} />}
        </>
    );
};
