//=== Style =====
import "./clientMARTableBody.css";

import { useEffect, useState } from "react";
import React from "react";
import { removeMedicationClientMAR } from "../../../../../../../../api/clientMARAPI";
import { isError } from "../../../../../../../../lib/isError";
import { getMARPeriodText } from "../../../../lib/getMARPeriodText";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ClientMARTableBody = ({ medicationRecordsMAR, selectedDate, setClientMARData, setShowMedicationAdministrationDialog, setSelectedMedicationAdministrationPeriod }) => {
    //= States ===================================================================

    const [data, setData] = useState(null);
    //const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [creatingMAR, setCreatingMAR] = useState(false);
    const [selectedMedication, setSelectedMedication] = useState(null);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //= Use Effects ==============================================================

    useEffect(() => {
        if (medicationRecordsMAR) {
            const jsonData = medicationRecordsMAR;
            const vet = [];

            // Itera sobre os registros de medicação no JSON original
            jsonData.forEach((record) => {
                // Encontre ou crie um objeto de medicação correspondente no array `vet`
                const existingMed = vet.find((item) => item.medication.id === record.medication.id);

                if (existingMed) {
                    // Se o objeto de medicação já existe, adicione o período ao objeto
                    // Use um loop para criar os campos de day1 a day31
                    const period = { id: record.id, period: record.period };
                    for (let day = 1; day <= 31; day++) {
                        period[`day${day}`] = record[`day${day}`];
                        period[`statusDay${day}`] = statusDay(record, day); //record[`statusDay${day}`];
                    }
                    existingMed.periods.push(period);
                } else {
                    // Se o objeto de medicação não existe, crie um novo objeto
                    vet.push({
                        clientMARid: record?.clientMARId,
                        medication: {
                            id: record.medication.id,
                            name: record.medication.name,
                            dose: record.medication.dose,
                            frequency: record.medication.frequency,
                            frequencyOtherDescription: record.medication.frequencyOtherDescription,
                            route: record.medication.route,
                            startDate: record.medication.startDate,
                            endDate: record.medication.endDate,
                            selfManaged: record.medication.selfManaged,
                            orderDetails: record.medication.orderDetails,
                        },
                        periods: [
                            {
                                id: record.id,
                                period: record.period,
                                day1: record.day1,
                                day2: record.day2,
                                day3: record.day3,
                                day4: record.day4,
                                day5: record.day5,
                                day6: record.day6,
                                day7: record.day7,
                                day8: record.day8,
                                day9: record.day9,
                                day10: record.day10,
                                day11: record.day11,
                                day12: record.day12,
                                day13: record.day13,
                                day14: record.day14,
                                day15: record.day15,
                                day16: record.day16,
                                day17: record.day17,
                                day18: record.day18,
                                day19: record.day19,
                                day20: record.day20,
                                day21: record.day21,
                                day22: record.day22,
                                day23: record.day23,
                                day24: record.day24,
                                day25: record.day25,
                                day26: record.day26,
                                day27: record.day27,
                                day28: record.day28,
                                day29: record.day29,
                                day30: record.day30,
                                day31: record.day31,
                                statusDay1: statusDay(record, 1),
                                statusDay2: statusDay(record, 2),
                                statusDay3: statusDay(record, 3),
                                statusDay4: statusDay(record, 4),
                                statusDay5: statusDay(record, 5),
                                statusDay6: statusDay(record, 6),
                                statusDay7: statusDay(record, 7),
                                statusDay8: statusDay(record, 8),
                                statusDay9: statusDay(record, 9),
                                statusDay10: statusDay(record, 10),
                                statusDay11: statusDay(record, 11),
                                statusDay12: statusDay(record, 12),
                                statusDay13: statusDay(record, 13),
                                statusDay14: statusDay(record, 14),
                                statusDay15: statusDay(record, 15),
                                statusDay16: statusDay(record, 16),
                                statusDay17: statusDay(record, 17),
                                statusDay18: statusDay(record, 18),
                                statusDay19: statusDay(record, 19),
                                statusDay20: statusDay(record, 20),
                                statusDay21: statusDay(record, 21),
                                statusDay22: statusDay(record, 22),
                                statusDay23: statusDay(record, 23),
                                statusDay24: statusDay(record, 24),
                                statusDay25: statusDay(record, 25),
                                statusDay26: statusDay(record, 26),
                                statusDay27: statusDay(record, 27),
                                statusDay28: statusDay(record, 28),
                                statusDay29: statusDay(record, 29),
                                statusDay30: statusDay(record, 30),
                                statusDay31: statusDay(record, 31, record.statusDay1),
                            },
                        ],
                    });
                }
            });

            setData(vet);
        }
    }, [medicationRecordsMAR]); // eslint-disable-line

    //=== Methods ================================================================
    const statusDay = (record, day) => {
        const medicationEndData = record.medication.endDate;
        const statusDay = record[`statusDay${day}`];
        const marYear = selectedDate.getFullYear();
        const marMonth = (selectedDate.getMonth() + 1).toString().padStart(2, "0");

        const recordDate = `${marYear}-${marMonth}-${day.toString().padStart(2, "0")}`;

        if (medicationEndData && isValidDate(recordDate) && new Date(recordDate) > new Date(medicationEndData)) return "DC";

        return statusDay;
    };

    const isValidDate = (dateString) => {
        const date = new Date(dateString);

        // Verifica se a data é "Invalid Date"
        if (isNaN(date.getTime())) {
            return false;
        }

        // Verifica se a string da data corresponde à data criada
        // Isso é útil para verificar casos como "2021-02-29", que não é uma data válida
        // pois 2021 não é um ano bissexto.
        // O método toISOString() retorna a data no formato "YYYY-MM-DDTHH:mm:ss.sssZ",
        // então usamos split('T')[0] para obter apenas a parte da data.
        const dateStringISO = date.toISOString().split("T")[0];
        if (dateString !== dateStringISO) {
            return false;
        }

        return true;
    };

    //= Handlers =================================================================

    const handleTableRowClick = (record, period) => {
        setSelectedMedication({ medication: record.medication, period });
        //alert(`Medication: ${record.medication.name}\nPeriod: ${period}`);
        setSelectedMedicationAdministrationPeriod({ ...record.periods[period], medicationName: record.medication.name });
        setShowMedicationAdministrationDialog(true);
    };

    const handleRemoveMedication = (event, record) => {
        event.stopPropagation();

        const medicationId = record?.medication?.id;
        const clientMARId = record?.clientMARid;

        if (medicationId && clientMARId) {
            setRemoving(true);

            const saveObj = { medicationId: medicationId, clientMARId: clientMARId };

            //===========================================================
            //Adding Medication MAR
            removeMedicationClientMAR(saveObj)
                .then((data) => {
                    setClientMARData(data);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error removing data");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                })
                .finally((_) => setRemoving(false));

            //===========================================================
        }
    };

    const handleMouseOver = (event) => {
        if (!event.target.classList.contains("medication-cell")) {
            event.target.parentNode.classList.add("highlighted");
        }
    };

    const handleMouseOut = (event) => {
        if (!event.target.classList.contains("medication-cell")) {
            event.target.parentNode.classList.remove("highlighted");
        }
    };

    //= Render ===================================================================

    return (
        <>
            <tbody>
                {data &&
                    data.map((record, recordIndex) => (
                        <React.Fragment key={recordIndex}>
                            {Array.from({ length: 4 }, (_, periodIndex) => (
                                <>
                                    <tr key={periodIndex} onClick={() => handleTableRowClick(record, periodIndex)} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`${selectedMedication?.medication.id === record.medication.id && selectedMedication?.period === periodIndex ? "highlighted" : ""} ${periodIndex === 0 ? "new-medication" : ""}`}>
                                        {periodIndex === 0 && (
                                            <td rowSpan="4" className="medication-cell">
                                                {record.medication.name && <div className="fw-bold medicationMAR-div-with-break">{record.medication.name}</div>}
                                                {record.medication.dose && <div className="small">{record.medication.dose}</div>}
                                                {record.medication.frequency && <div className="small">{record.medication.frequency == "Other" ? record.medication.frequencyOtherDescription : record.medication.frequency}</div>}
                                                {record.medication.route && <div className="small">{record.medication.route}</div>}
                                                {record.medication.startDate && <div className="small">Start Date: {record.medication.startDate.split("T")[0]}</div>}
                                                {record.medication.endDate && <div className="small">End Date: {record.medication.endDate.split("T")[0]}</div>}
                                                {record.medication.selfManaged && <div className="small">Self Managed</div>}
                                                {record.medication.orderDetails && (
                                                    <div className="text-success">
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-id">{record.medication.orderDetails}</Tooltip>}>
                                                            <i className="fa fa-info-circle"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                )}
                                                {/*
                                            <div>
                                                <button className="btn btn-sm btn-outline-danger mt-2 py-0 px-2 small" onClick={(event) => handleRemoveMedication(event, record)}>
                                                    <small>Remove</small>
                                                </button>
                                            </div>
                                            */}
                                            </td>
                                        )}
                                        <td>{getMARPeriodText(record.periods[periodIndex].period)}</td>
                                        {Array.from({ length: 31 }, (_, dayIndex) => (
                                            <td key={dayIndex} className={`text-center small medicationMAR-value medicationMAR-value-${record.periods[periodIndex][`statusDay${dayIndex + 1}`]}`}>
                                                {record.periods[periodIndex][`statusDay${dayIndex + 1}`] == "-" ? "-" : record.periods[periodIndex][`day${dayIndex + 1}`]}

                                                {record.periods[periodIndex][`statusDay${dayIndex + 1}`] == "I" ? (
                                                    <>
                                                        &nbsp;<sup>(1)</sup>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {record.periods[periodIndex][`statusDay${dayIndex + 1}`] == "PP" ? (
                                                    <>
                                                        &nbsp;<sup>(2)</sup>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {record.periods[periodIndex][`statusDay${dayIndex + 1}`] == "H" ? (
                                                    <>
                                                        &nbsp;<sup>(3)</sup>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {record.periods[periodIndex][`statusDay${dayIndex + 1}`] == "NA" ? (
                                                    <>
                                                        &nbsp;<sup>(4)</sup>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {record.periods[periodIndex][`statusDay${dayIndex + 1}`] == "NT" ? (
                                                    <>
                                                        &nbsp;<sup>(5)</sup>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {record.periods[periodIndex][`statusDay${dayIndex + 1}`] == "R" ? (
                                                    <>
                                                        &nbsp;<sup>(6)</sup>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {record.periods[periodIndex][`statusDay${dayIndex + 1}`] == "DC" ? (
                                                    <>
                                                        &nbsp;<sup>(7)</sup>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                </>
                            ))}
                        </React.Fragment>
                    ))}
                {(!data || data.length === 0) && (
                    <tr>
                        <td className="text-center" colSpan={33}>
                            No medications recorded in MAR
                        </td>
                    </tr>
                )}
            </tbody>
        </>
    );
};
