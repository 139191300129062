//css
import "./nurseReviewDialog.css";

//Icons
import icon from "../../../../assets/comment-medical-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { updateNurseReviewSection } from "../../../../api/nurseAssessment/nurseReviewAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const NurseReviewDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Nurse Review data state
    const [nurseReviewData, setNurseReviewData] = useState(null);

    //list of reviews
    const listOfReviews = [
        { label: "Nursing Care Plan Appropriate", value: "Nursing Care Plan Appropriate" },
        { label: "Care Plan Revision/Update", value: "Care Plan Revision/Update" },
        { label: "S.N. Frequency Reviewed", value: "S.N. Frequency Reviewed" },
        { label: "Progressing toward goal", value: "Progressing toward goal" },
        { label: "Barriers towards progress", value: "Barriers towards progress" },
        { label: "Discharge Plan reviewed", value: "Discharge Plan reviewed" },
        { label: "Need for continued service", value: "Need for continued service" },
        { label: "Skilled assessment", value: "Skilled assessment" },
        { label: "Continue teaching", value: "Continue teaching" },
        { label: "Procedure as ordered", value: "Procedure as ordered" },
    ];

    //list of reviews
    const listOfCareCoordination = [
        { label: "Physician", value: "Physician" },
        { label: "SN", value: "SN" },
        { label: "PT", value: "PT" },
        { label: "OT", value: "OT" },
        { label: "ST", value: "ST" },
        { label: "MSW", value: "MSW" },
        { label: "HHA", value: "HHA" },
        { label: "Other", value: "Other" },
    ];

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.nurseReviewSection, status: data?.nurseReviewSection?.status ?? "Applicable" };
        setNurseReviewData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: nurseReviewData?.id, status: nurseReviewData?.status };
        setNurseReviewData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...nurseReviewData };

        //===========================================================
        //Saving Nurse Review Section
        updateNurseReviewSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", nurseReviewSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...nurseReviewData, [name]: value };
        setNurseReviewData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...nurseReviewData, [name]: checked };
        setNurseReviewData(newData);
    };

    const handleRadioClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...nurseReviewData, [name]: value };
        setNurseReviewData(newData);
    };

    const handleReviewChange = (e) => {
        let reviewsNew = e?.map((i) => i.value);
        const newData = { ...nurseReviewData, reviews: reviewsNew };
        setNurseReviewData(newData);
    };

    const handleCareCoordinationChange = (e) => {
        let careCoordinationNew = e?.map((i) => i.value);
        const newData = { ...nurseReviewData, careCoordination: careCoordinationNew };
        setNurseReviewData(newData);
    };

    return (
        <Modal dialogClassName="nurseReviewSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Nursing Review - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{nurseReviewData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={nurseReviewData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Reviews</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {nurseReviewData?.status === "Applicable"
                                        ? nurseReviewData?.reviews?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="reviews"
                                        id="reviews"
                                        menuPlacement="bottom"
                                        options={listOfReviews}
                                        //value={listOfReviews.value}
                                        //defaultValue={nurseReviewData?.reviews?.map((rev) => listOfReviews?.find((i) => i.value === rev))}
                                        value={(nurseReviewData?.reviews ?? []).map((rev) => listOfReviews?.find((i) => i.value === rev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleReviewChange}
                                        isDisabled={!["Applicable"].includes(nurseReviewData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8">
                            <span className="my-1 d-block">Care Coordination</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {nurseReviewData?.status === "Applicable"
                                        ? nurseReviewData?.careCoordination?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="careCoordination"
                                        id="careCoordination"
                                        menuPlacement="bottom"
                                        options={listOfCareCoordination}
                                        //value={listOfCareCoordination.value}
                                        //defaultValue={nurseReviewData?.careCoordination?.map((cc) => listOfCareCoordination?.find((i) => i.value === cc))}
                                        value={(nurseReviewData?.careCoordination ?? []).map((cc) => listOfCareCoordination?.find((i) => i.value === cc))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleCareCoordinationChange}
                                        isDisabled={!["Applicable"].includes(nurseReviewData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">If other, specify</span>
                            {readOnly && <div className="text-success pb-2">{nurseReviewData?.status === "Applicable" ? nurseReviewData?.careCoordinationOtherName ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" placeholder={`Enter name`} value={nurseReviewData?.careCoordinationOtherName ?? ""} name="careCoordinationOtherName" onChange={handleFieldChange} maxLength="20" disabled={!["Applicable"].includes(nurseReviewData?.status) || nurseReviewData?.careCoordination?.indexOf("Other") === -1} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <label className="my-1 d-block" htmlFor="comments">
                                Regarding
                            </label>
                            {readOnly && <div className="text-success pb-2">{nurseReviewData?.status === "Applicable" ? nurseReviewData?.regarding ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <textarea name="regarding" id="regarding" className="form-control" placeholder={`Regarding`} value={nurseReviewData?.regarding ?? ""} onChange={handleFieldChange} maxLength="300" disabled={!["Applicable"].includes(nurseReviewData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, nurseReviewSection: data?.nurseReviewSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
