//Icons
import iconCloudMeatballRed from "../../../assets/cloud-meatball-solid-red.svg";
import iconCloudMeatballGreen from "../../../assets/cloud-meatball-solid-green.svg";

import { updateGastrointestinalSection } from "../../../api/nurseAssessment/gastrointestinalAPI";
import { isError } from "../../../lib/isError";

export const getGastrointestinalSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowGastrointestinalDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateGastrointestinalSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving Gastrointestinal Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.gastrointestinalSection, status: status };
        const saveObj = { id: patient?.gastrointestinalSection?.id, status: status };
        updateGastrointestinalSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", gastrointestinalSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Gastrointestinal",
        description: null,
        progress: data?.gastrointestinalSection?.progress,
        status: data?.gastrointestinalSection?.status,
        enableStatusField: true,
        iconClass: "fa-medkit",
        iconPending: iconCloudMeatballRed,
        iconCompleted: iconCloudMeatballGreen,
        saving: false,
        onClick: () => {
            setShowGastrointestinalDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateGastrointestinalSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateGastrointestinalSectionStatus(notApplicable);
            }
        },
    };
};
