// A custom hook that builds on useLocation to parse

import { useLocation } from "react-router-dom";
import React from "react";

// the query string for you.
export default function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
