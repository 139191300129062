export const woundCareDialogValidation = (data) => {
    const ret = {
        isValid: true,
        errors: [],
    };

    if (!data?.typeOfWound) {
        ret.errors.push({ field: "typeOfWound", errorMessage: "Wound Type is required." });
    }

    ret.isValid = ret.errors.length === 0;

    return ret;
};
