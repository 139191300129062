import { useEffect } from "react";
import { useState } from "react";
import { Badge } from "react-bootstrap";

export const ScorePAINAD = ({ score }) => {
    const [bg, setBg] = useState("primary");
    const [label, setLabel] = useState("");

    useEffect(() => {
        if (score >= 1 && score <= 3) {
            setBg("warning");
            setLabel("Mild Pain");
        }

        if (score >= 4 && score <= 6) {
            setBg("warning");
            setLabel("Moderate Pain");
        }

        if (score > 6) {
            setBg("danger");
            setLabel("Severe Pain");
        }
    }, [score]);

    return <>{score > 0 && <Badge bg={bg} className="p-2">{`${label} (${score})`}</Badge>}</>;
};
