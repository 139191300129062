//css
import "./interventionsInstructionsDialog.css";

//Icons
import icon from "../../../../assets/clipboard-list-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { updateInterventionsInstructionsSection } from "../../../../api/nurseAssessment/interventionsInstructionsAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const InterventionsInstructionsDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Interventions Instructions data state
    const [interventionsInstructionsData, setInterventionsInstructionsData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of additionals
    const listOfAdditionals = [
        { label: "Admin of Vitamine B12", value: "Admin of Vitamine B12" },
        { label: "Bowel/Bladder Training", value: "Bowel/Bladder Training" },
        { label: "Change NG/G tube", value: "Change NG/G tube" },
        { label: "Chest physio/Postural", value: "Chest physio/Postural" },
        { label: "Decubitus care", value: "Decubitus care" },
        { label: "Depression Intervention", value: "Depression Intervention" },
        { label: "Foley Care", value: "Foley Care" },
        { label: "Hypo/Hyperglycemia", value: "Hypo/Hyperglycemia" },
        { label: "IM/Subcutaneous Injection", value: "IM/Subcutaneous Injection" },
        { label: "Post-cataract care", value: "Post-cataract care" },
        { label: "Prep./Admin. Insulin", value: "Prep./Admin. Insulin" },
        { label: "Teach diabetic care/foot care", value: "Teach diabetic care/foot care" },
        { label: "Urine Testing", value: "Urine Testing" },
        { label: "Venipuncture", value: "Venipuncture" },
    ];

    //list of drainages
    const listOfWhenToCall = [
        { label: "Agency", value: "Agency" },
        { label: "Physician", value: "Physician" },
        { label: "911", value: "911" },
    ];

    //list of safety options
    const listOfSafety = [
        { label: "Fall", value: "Fall" },
        { label: "Fire", value: "Fire" },
        { label: "Medications", value: "Medications" },
        { label: "Other", value: "Other" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.interventionsInstructionsSection, status: data?.interventionsInstructionsSection?.status ?? "Applicable" };
        setInterventionsInstructionsData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: interventionsInstructionsData?.id, status: interventionsInstructionsData?.status };
        setInterventionsInstructionsData(newData);
    };

    const handleSave = () => {
        setSaving(true);

        const saveObj = {
            ...interventionsInstructionsData,
        };

        //===========================================================

        //Saving InterventionsInstructionsSection
        updateInterventionsInstructionsSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", interventionsInstructionsSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...interventionsInstructionsData, [name]: value };
        setInterventionsInstructionsData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...interventionsInstructionsData, [name]: checked };
        setInterventionsInstructionsData(newData);
    };

    const handleDrainageChange = (e) => {
        let drainageNew = e?.map((i) => i.value);
        const newData = { ...interventionsInstructionsData, drainage: drainageNew };
        setInterventionsInstructionsData(newData);
    };

    const handleAdditionalChange = (e) => {
        let additionalNew = e?.map((i) => i.value);
        const newData = { ...interventionsInstructionsData, additional: additionalNew };
        setInterventionsInstructionsData(newData);
    };

    const handleSafetyChange = (e) => {
        let safetyNew = e?.map((i) => i.value);
        const newData = { ...interventionsInstructionsData, safety: safetyNew };
        setInterventionsInstructionsData(newData);
    };

    const handleWhenToCallChange = (e) => {
        let whenToCallNew = e?.map((i) => i.value);
        const newData = { ...interventionsInstructionsData, whenToCall: whenToCallNew };
        setInterventionsInstructionsData(newData);
    };

    return (
        <Modal dialogClassName="interventionsInstructionsmodal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Interventions - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-3">{interventionsInstructionsData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={interventionsInstructionsData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-6">
                            <input name="standardPrecautions" id="standardPrecautions" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.standardPrecautions ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="standardPrecautions">
                                Standard Precautions
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-6">
                            <input name="observedSS" id="observedSS" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.observedSS ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="observedSS">
                                Observed S/S
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row m-0 p-0 mb-3">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Observe / Teach <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 pt-2">
                            <input name="diseaseProcess" id="diseaseProcess" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.diseaseProcess ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="diseaseProcess">
                                Disease process
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            {readOnly && <div className="text-success pt-2 pb-2">{interventionsInstructionsData?.status === "Applicable" && interventionsInstructionsData?.diseaseProcess ? interventionsInstructionsData?.diseaseProcessDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="diseaseProcessDescription" type="text" className="form-control" placeholder={`Specify`} value={interventionsInstructionsData?.diseaseProcessDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || !interventionsInstructionsData?.diseaseProcess} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-1 pt-2">
                            <input name="diet" id="diet" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.diet ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="diet">
                                Diet
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-5">
                            {readOnly && <div className="text-success pt-2 pb-2">{interventionsInstructionsData?.status === "Applicable" && interventionsInstructionsData?.diet ? interventionsInstructionsData?.dietDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="dietDescription" type="text" className="form-control" placeholder={`Specify`} value={interventionsInstructionsData?.dietDescription ?? ""} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || !interventionsInstructionsData?.diet} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 me-0 pe-0 ">
                            <span className="my-1 d-block">Safety</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {interventionsInstructionsData?.status === "Applicable"
                                        ? interventionsInstructionsData?.safety?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="safety"
                                        id="safety"
                                        menuPlacement="bottom"
                                        options={listOfSafety}
                                        //value={listOfSafety.value}
                                        //defaultValue={interventionsInstructionsData?.safety?.map((med) => listOfSafety?.find((i) => i.value === med))}
                                        value={(interventionsInstructionsData?.safety ?? []).map((med) => listOfSafety?.find((i) => i.value === med))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleSafetyChange}
                                        isDisabled={!["Applicable"].includes(interventionsInstructionsData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">If Other, describe</span>
                            {readOnly && <div className="text-success pb-2">{interventionsInstructionsData?.status === "Applicable" && interventionsInstructionsData?.safety === "Other" ? interventionsInstructionsData?.safetyOther ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="safetyOther" type="text" className="form-control" placeholder={`Describe`} value={interventionsInstructionsData?.safetyOther ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || !interventionsInstructionsData?.safety?.includes("Other")} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-5">
                            <span className="my-1 d-block">When to Call</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {interventionsInstructionsData?.status === "Applicable"
                                        ? interventionsInstructionsData?.whenToCall?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="whenToCall"
                                        id="whenToCall"
                                        menuPlacement="bottom"
                                        options={listOfWhenToCall}
                                        //value={listOfWhenToCall.value}
                                        //defaultValue={interventionsInstructionsData?.whenToCall?.map((med) => listOfWhenToCall?.find((i) => i.value === med))}
                                        value={(interventionsInstructionsData?.whenToCall ?? []).map((med) => listOfWhenToCall?.find((i) => i.value === med))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleWhenToCallChange}
                                        isDisabled={!["Applicable"].includes(interventionsInstructionsData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="tubeFeedings" id="tubeFeedings" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.tubeFeedings ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="tubeFeedings">
                                Tube Feeding
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-9">
                            {readOnly && <div className="text-success pt-2 pb-2">{interventionsInstructionsData?.status === "Applicable" && interventionsInstructionsData?.tubeFeedings ? interventionsInstructionsData?.tubeFeedingsDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="tubeFeedingsDescription" type="text" className="form-control" placeholder={`Specify`} value={interventionsInstructionsData?.tubeFeedingsDescription ?? ""} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || !interventionsInstructionsData?.tubeFeedings} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="careOfTracheotomy" id="careOfTracheotomy" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.careOfTracheotomy ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="careOfTracheotomy">
                                Care of Tracheotomy
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-9">
                            {readOnly && <div className="text-success pt-2 pb-2">{interventionsInstructionsData?.status === "Applicable" && interventionsInstructionsData?.careOfTracheotomy ? interventionsInstructionsData?.careOfTracheotomyDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="careOfTracheotomyDescription" type="text" className="form-control" placeholder={`Specify`} value={interventionsInstructionsData?.careOfTracheotomyDescription ?? ""} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || !interventionsInstructionsData?.careOfTracheotomy} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="inhalationRx" id="inhalationRx" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.inhalationRx ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="inhalationRx">
                                Inhalation Rx
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-9">
                            {readOnly && <div className="text-success pt-2 pb-2">{interventionsInstructionsData?.status === "Applicable" && interventionsInstructionsData?.inhalationRx ? interventionsInstructionsData?.inhalationRxDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="inhalationRxDescription" type="text" className="form-control" placeholder={`Specify`} value={interventionsInstructionsData?.inhalationRxDescription ?? ""} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || !interventionsInstructionsData?.inhalationRx} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 pt-2">
                            <input name="painManagement" id="painManagement" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.painManagement ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="painManagement">
                                Pain Management
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-9">
                            {readOnly && <div className="text-success pt-2 pb-2">{interventionsInstructionsData?.status === "Applicable" && interventionsInstructionsData?.painManagement ? interventionsInstructionsData?.painManagementDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="painManagementDescription" type="text" className="form-control" placeholder={`Specify`} value={interventionsInstructionsData?.painManagementDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || !interventionsInstructionsData?.painManagement} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 me-0 pe-0 ">
                            <span className="my-1 d-block">Additional</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {interventionsInstructionsData?.status === "Applicable"
                                        ? interventionsInstructionsData?.additional?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select isMulti name="additional" id="additional" menuPlacement="bottom" options={listOfAdditionals} value={(interventionsInstructionsData?.additional ?? []).map((med) => listOfAdditionals?.find((i) => i.value === med))} menuPortalTarget={document.body} styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} onChange={handleAdditionalChange} isDisabled={!["Applicable"].includes(interventionsInstructionsData?.status)}></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <input name="observeSSInfection" id="observeSSInfection" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.observeSSInfection ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="observeSSInfection">
                                Observe S/S infection
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            <input name="safetyFactors" id="safetyFactors" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.safetyFactors ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="safetyFactors">
                                Safety Factors
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            <input name="diabeticObservation" id="diabeticObservation" type="checkbox" className="form-check-input" checked={interventionsInstructionsData?.diabeticObservation ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(interventionsInstructionsData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="diabeticObservation">
                                Diabetic Observation
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Other Skilled Intervention</span>
                            {readOnly && <div className="text-success pb-2">{interventionsInstructionsData?.status === "Applicable" ? interventionsInstructionsData?.otherSkilledIntervention ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="otherSkilledIntervention" type="text" className="form-control" placeholder={`Describe`} value={interventionsInstructionsData?.otherSkilledIntervention ?? ""} onChange={handleFieldChange} maxLength="250" disabled={!["Applicable"].includes(interventionsInstructionsData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, interventionsInstructionsSection: data?.interventionsInstructionsSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
