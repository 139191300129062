import "./supervisionReportByEmployee.css";

import { useEffect, useState } from "react";
import useWindowDimensions from "../../../../lib/hooks/useWindowDimensions";
import { getSupervisionReport } from "../../../../api/reports/supervisionReportAPI";
import { sortJson } from "../../../../lib/sortJson";
import { JSONToCSVConvertor } from "../../../../lib/jsonToCSVConvertor";
import DatePicker from "react-datepicker";
import { fileTimeStamp } from "../../../../lib/fileTimeStamp";
import { EmployeeReportListTableBody } from "./reportListTableBody/EmployeeReportListTableBody";
import { EmployeeReportListTableFooter } from "./reportListTableFooter/EmployeeReportListTableFooter";
import { isError } from "../../../../lib/isError";
import { Loading } from "../../../../components/loading/loading";

export const SupervisionReportByEmployee = () => {
    //order by direction state
    const [sortAsc, setSortAsc] = useState(true);

    //Report data state
    const [data, setData] = useState([]);

    //Report filtered data state
    const [displayData, setDisplayData] = useState([]);

    //filter value state
    const [filter, setFilter] = useState(null);

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //errors state
    const [loadingError, setLoadingError] = useState(null);

    //Grid records per page state
    const [pageSize, setPageSize] = useState(15);

    //Grid page # state
    const [pageNumber, setPageNumber] = useState(1);

    //Grid number of pages state
    const [numPages, setNumPages] = useState(null);

    //date start and end range
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    //Display AssignedTo field state
    const [displayAssignedToField, setDisplayAssignedToField] = useState(true);

    const { height } = useWindowDimensions();

    //=== Handlers ============================================================

    //handle download csv
    const handleDownloadClick = () => {
        JSONToCSVConvertor(
            displayData?.map((item) => ({
                ...item,
                appointmentDate: item?.appointmentDate.replace("T", " "),
                status: item?.status ?? "Not Started",
            })),
            `SupervisionByEmployee_${fileTimeStamp()}`,
            true
        );
    };

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //handle page number changes
    const handlePageNumberChange = (e) => {
        setPageNumber(e.target.value);
    };

    //handle page size changes
    const handlePageSizeChange = (e) => {
        e.target.value === "All" ? setPageSize(data.length) : setPageSize(e.target.value);
        //when pagesize changes, reset to page 1
        setPageNumber(1);
    };

    //handle reload button click
    const handleReloadClick = () => {
        loadSupervisionByEmployeeReport();
    };

    //handle sort columns
    const handleSortClick = (prop, propType) => {
        setSortAsc((sortAsc) => !sortAsc);
        setDisplayData(sortJson(displayData, prop, propType, sortAsc));
    };

    const loadSupervisionByEmployeeReport = () => {
        setLoadingError(null);
        setLoading(true);
        getSupervisionReport()
            .then((data) => {
                setData(data);
                setDisplayData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    //=== Use Effects =========================================================

    //Loading Clients and defining list page size depending window height...
    useEffect(() => {
        //Defining page size depending window height
        if (height > 600 && height < 900) setPageSize(10);
        else if (height > 900) setPageSize(15);
        else setPageSize(5);

        loadSupervisionByEmployeeReport();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //update display data when data || filter changes
    useEffect(() => {
        let filteredData = data;

        if (startDate && endDate) {
            filteredData = filteredData.filter((item) => {
                const itemDate = new Date(item.appointmentDate);
                return itemDate >= startDate && itemDate <= endDate;
            });
        }

        if (filter) {
            const lowerCaseFilter = filter.toLowerCase();
            filteredData = filteredData.filter(({ officeName, clientName, workerCPAUserId, caregiverName, appointmentDate, assessmentType, workerStatus, workerName }) => {
                return (
                    officeName?.toLowerCase().includes(lowerCaseFilter) ||
                    clientName?.toLowerCase().includes(lowerCaseFilter) ||
                    workerCPAUserId?.toString().toLowerCase().includes(lowerCaseFilter) ||
                    appointmentDate?.toLowerCase().includes(lowerCaseFilter) ||
                    caregiverName?.toLowerCase().includes(lowerCaseFilter) ||
                    workerName?.toLowerCase().includes(lowerCaseFilter) ||
                    caregiverName?.toLowerCase().includes(lowerCaseFilter) ||
                    workerStatus?.toLowerCase().includes(lowerCaseFilter) ||
                    assessmentType?.toLowerCase().includes(lowerCaseFilter)
                );
            });
        }

        setDisplayData(filteredData);

        // Reset to page 1 when filter changes
        setPageNumber(1);
    }, [data, startDate, endDate, filter]);

    //update # pages when displayData || pageSize changes
    useEffect(() => {
        pageSize === "All" ? setNumPages(1) : setNumPages(Math.ceil(displayData?.length ?? 0 / pageSize));
    }, [displayData, pageSize]);

    //=== Render ==============================================================
    return (
        <>
            <section id="report1">
                <div className="container-fluid mt-5 pt-4">
                    <div className="row">
                        <div className="col-8 col-md-9 col-xl-10 pb-0 mb-0">
                            <h4 className="pt-4 pb-0 mb-0 ps-3 float-start text-primary">Supervision Report by Employee</h4>
                        </div>
                        {loading && (
                            <div className="col-4 col-md-3 col-xl-2 pt-4 text-end pe-4 pb-0 mb-0">
                                <Loading />
                            </div>
                        )}
                    </div>

                    <hr />
                    <div className="row">
                        {data?.length == 0 ? (
                            <div className="col-sm-2 col-md-9 col-lg-9 text-success"></div>
                        ) : (
                            <div className="col-sm-2 col-md-9 col-lg-9 text-success">
                                <div className="row mb-1">
                                    <div className="col-sm-4 col-md-3 col-lg-2 custom-datepicker">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            customInput={
                                                <button className="btn custom-width btn-sm btn-success">
                                                    {startDate
                                                        ? startDate.toLocaleDateString("en-US", {
                                                              month: "short",
                                                              day: "2-digit",
                                                              year: "numeric",
                                                          })
                                                        : "From Start Date"}
                                                </button>
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-4 col-md-3 col-lg-2  custom-datepicker">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            customInput={
                                                <button className="btn ml-5 custom-width btn-sm btn-success">
                                                    {endDate
                                                        ? endDate.toLocaleDateString("en-US", {
                                                              month: "short",
                                                              day: "2-digit",
                                                              year: "numeric",
                                                          })
                                                        : "To End Date"}
                                                </button>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-sm-5 col-md-3 col-lg-3 align-end">
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sm" placeholder="Search...." onChange={handleSearchInput} />
                                &nbsp;
                                <button className="btn btn-sm btn-success" title="Reload" onClick={handleReloadClick}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                                &nbsp;
                                <button className="btn btn-sm btn-success" title="Download" onClick={handleDownloadClick}>
                                    <i className="fa fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <table className={`table table-striped table-hover table-sm visitCompletionComplianceList visitCompletionComplianceList8 mt-1`}>
                        <thead>
                            <tr>
                                <th className="sort" onClick={() => handleSortClick("officeName", "string")}>
                                    <span className="d-none d-lg-block">Office</span>
                                </th>
                                <th className="sort" onClick={() => handleSortClick("workerName", "string")}>
                                    <span className="d-none d-lg-block">Supervisor</span>
                                    <span className="d-block d-lg-none">Supervisor</span>
                                </th>
                                <th className="sort" onClick={() => handleSortClick("workerCPAUserId", "int")}>
                                    <span className="d-none d-lg-block">Employee #</span>
                                </th>
                                <th className="sort d-none d-md-table-cell" onClick={() => handleSortClick("caregiverName", "string")}>
                                    Caregiver
                                </th>
                                <th className="sort" onClick={() => handleSortClick("appointmentDate", "datetime")}>
                                    <span className="d-none d-lg-block">Date</span>
                                </th>
                                <th className="sort d-none d-md-table-cell" onClick={() => handleSortClick("assessmentType", "string")}>
                                    Visit Type
                                </th>
                                <th className="sort d-none d-md-table-cell" onClick={() => handleSortClick("workerStatus", "string")}>
                                    Status
                                </th>
                                <th className="sort d-none d-md-table-cell" onClick={() => handleSortClick("clientName", "string")}>
                                    Clients
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <EmployeeReportListTableBody items={displayData} loading={loading} loadingError={loadingError} currentPage={Number(pageNumber)} numberPerPage={Number(pageSize)} filter={filter} />
                        </tbody>
                        <tfoot>
                            <EmployeeReportListTableFooter handlePageNumberChange={handlePageNumberChange} handlePageSizeChange={handlePageSizeChange} numPages={numPages} numRecords={displayData?.length ?? 0} pageSize={pageSize} displayAssignedToField={displayAssignedToField} />
                        </tfoot>
                    </table>
                </div>
            </section>
        </>
    );
};
