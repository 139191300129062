import icon from "../../../../../../../../assets/pen-ruler-solid-red.svg";
import { ScoreBradenScale } from "../../../../../../../../components/scoreBradenScale/scoreBradenScale";

export const SummaryBradenScale = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Braden Scale (For Predicting Pressure Sore Risk)
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2">
                            <ScoreBradenScale score={data?.score} />
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-4 fw-bold">Sensory Perception</div>
                        <div className="col-prt-4 fw-bold">Moisture</div>
                        <div className="col-prt-4 fw-bold">Activity</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-4 text-primary">{data?.sensoryPerception ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.moisture ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.activity ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-4 fw-bold">Mobility</div>
                        <div className="col-prt-4 fw-bold">Nutrition</div>
                        <div className="col-prt-4 fw-bold">Friction and Shear</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-4 text-primary">{data?.mobility ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.nutrition ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.frictionAndShear ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
