import icon from "../../../../../../../../assets/wheelchair-solid-red.svg";

export const SummaryAssistiveDevices = ({ data }) => {
    return (
        <>
        {data?.status !== "Not Applicable" && (
        <small>
            <div className="row-prt">
                <div className="col-prt-12">
                    <div className="mb-4">
                        <img src={icon} alt="" height="30px" width="39px" />
                        &nbsp;&nbsp;
                        <span className="h5" style={{ position: "relative", top: "5px" }}>
                            Assistive Devices
                        </span>
                    </div>
                </div>
            </div>

            
                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Devices</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.devices?.length === 0 || !data?.devices) && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 6,
                                }}
                            >
                                {data?.devices?.map((item, index) => {
                                    if (item !== "Other" && item !== "") return <li key={index}>{item}</li>;
                                })}
                                {data?.deviceOtherDescription && <li>{data?.deviceOtherDescription}</li>}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Requires Assistance for</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.requiresAssistanceFor?.length === 0 || !data?.requiresAssistanceFor) && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 6,
                                }}
                            >
                                {data?.requiresAssistanceFor?.map((item, index) => {
                                    return <li key={index}>{item}</li>;
                                })}
                            </ul>
                        </div>
                    </div>

            <div className="row-prt ms-2">
                <div className="col-prt-12">
                    <hr />
                </div>
            </div>
        </small>
        )}
        </>

    );
};
