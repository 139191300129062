import icon from "../../../../../../../../assets/house-circle-check-solid-red.svg";

export const SummaryHomeSafety = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Home Safety
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Living Space</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Unsecured firearms in the home</div>
                                <div className="col-prt-4 fw-bold">Steps or stairs have accompanying sturdy handrails or banisters</div>
                                <div className="col-prt-4 fw-bold">Pathways are clear in heavy traffic areas</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.unsecuredFirearms ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.stepsOrStairsAccompanyingSturdyHandrailsOrBanisters ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.pathwaysClearHeavyTrafficAreas ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Stairways/hallways/exits are clear of clutter and loose objects</div>
                                <div className="col-prt-4 fw-bold">Throw rugs are eliminated or fastened down</div>
                                <div className="col-prt-4 fw-bold">Electrical cords are placed close to walls and out of the pathway</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.stairwaysHallwaysExitsClearClutterAndLooseObjects ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.throwRugsEliminatedOfFastenedDown ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.electricalCordsCloseWallsAndOutPathway ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Lighting is adequate in client care area(s)</div>
                                <div className="col-prt-4 fw-bold">A flashlight, light switch or lamp is located within easy reach of the client</div>
                                <div className="col-prt-4 fw-bold">Furniture is arranged to allow free movement</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.lightingAdequate ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.lightLocatedEasyReachClient ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.furnitureArrangedFreeMovement ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Hand grippers are installed in the bathroom, when appropriate</div>
                                <div className="col-prt-4 fw-bold">All medications are clearly labeled</div>
                                <div className="col-prt-4 fw-bold">Refrigeration is available for proper storage of solutions/medications</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.handGrippersInstalledBathroom ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.allMedicationsClearlyLabeled ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.refrigerationAvailableForStorageSolutionsMedications ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">There is access to a working telephone</div>
                                <div className="col-prt-4 fw-bold">A list of emergency telephone numbers is present in the home</div>
                                <div className="col-prt-4 fw-bold">Smoke alarms are present on each floor</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.accessWorkingTelephone ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.listEmergencyTelephoneNumbers ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.smokeAlarmsPresent ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">There is a non-skid surface or mat on the bathtub or shower floor</div>
                                <div className="col-prt-4 fw-bold">Oxygen in the home</div>
                                <div className="col-prt-4 fw-bold">Proper lifting aids present for immobile clients or those client’s requiring assistance with transfers</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.nonSkidSurfaceBathtubOrShower ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.oxygenInHome ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.properLiftingAids ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Client/Responsible Party verbalizes an understanding of the fire escape plan and route from the home</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.clientVerbalizesUnderstandingFireEscapePlan ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>The following items are in secured areas and out of the reach of children and confused individuals</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-3 fw-bold">Medications</div>
                                <div className="col-prt-3 fw-bold">Sharp Objects</div>
                                <div className="col-prt-3 fw-bold">Cleaning substances</div>
                                <div className="col-prt-3 fw-bold">Poisons (bug killer, weed killer, etc.)</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-3 text-primary">{data?.medicationsSecured ?? "-"}</div>
                                <div className="col-prt-3 text-primary">{data?.sharpObjectsSecured ?? "-"}</div>
                                <div className="col-prt-3 text-primary">{data?.cleaningSubstancesSecured ?? "-"}</div>
                                <div className="col-prt-3 text-primary">{data?.poisonsSecured ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>If Oxygen in the home</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Oxygen is placed in a non-smoking area and away from an open flame</div>
                                <div className="col-prt-4 fw-bold">The client can manage oxygen tubing safely during transfers and ambulation</div>
                                <div className="col-prt-4 fw-bold">There is back up oxygen in case of a power outage</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.oxygenPlacedNonSmokingAreaAndAwayOpenFlame ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.clientCanManageOxygenTubingSafelyDuringTransfers ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.backupOxygen ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>The client has been instructed and agrees that the following items will be secured away from the service area when Caring People staff are present</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-3 fw-bold">Guns/Firearms/Weapons</div>
                                <div className="col-prt-9 fw-bold">Illicit/offensive materials deemed not appropriate for a professional care setting</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-3 text-primary">{data?.gunsSecured ?? "-"}</div>
                                <div className="col-prt-9 text-primary">{data?.illicitOffensiveSecured ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-5 fw-bold fst-italic">
                            <li>Home environment is suitable for care</li>
                        </div>
                        <div className="col-prt-7 text-primary">{data?.suitableForCare ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.reasonsIsNotSuitableForCare?.length === 0 || !data?.reasonsIsNotSuitableForCare) && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 3,
                                        }}
                                    >
                                        {data?.reasonsIsNotSuitableForCare?.map((item, index) => {
                                            if (item !== "Other" && item !== "") return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Outdoor Safety</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Handrails/banisters in place in good repair</div>
                                <div className="col-prt-4 fw-bold">Walkway clear and clutter free</div>
                                <div className="col-prt-4 fw-bold">Areas of incline/curb are marked and visible</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.handrailsBanistersInPlaceInGoodRepair ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.walkwayClearAndClutterFree ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.areasOfInclineCurbMarkedAndVisible ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Outdoor lighting in working order</div>
                                <div className="col-prt-4 fw-bold">Stairs/ramp/egress access in good repair</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.outdoorLightingWorkingOrder ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.stairsRampEgressAccessGoodRepair ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-5 fw-bold fst-italic">
                            <li>Recommendations</li>
                        </div>
                        <div className="col-prt-7 text-primary">{data?.hasRecommendations ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.recommendations?.length === 0 || !data?.recommendations) && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 3,
                                        }}
                                    >
                                        {data?.recommendations?.map((item, index) => {
                                            if (item !== "Other" && item !== "") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.otherRecommendation && <li>{data?.otherRecommendation}</li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
