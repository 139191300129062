//Icons
import iconMedicationGreen from "../../../../../assets/prescription-bottle-solid-green.svg";

export const clientAdditionalInformationLib_Medications = (setShowMedicationsDialog) => {
    return {
        title: "Medications",
        description: "Manage the medications used by the client.",
        icon: iconMedicationGreen,
        onClick: () => {
            setShowMedicationsDialog(true);
        },
    };
};
