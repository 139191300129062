import { useEffect } from "react";
import { useState } from "react";
import { Badge } from "react-bootstrap";

export const ScoreFallRisk = ({ score }) => {
    const [bg, setBg] = useState("primary");
    const [label, setLabel] = useState("");

    useEffect(() => {
        if (score > 13) {
            setBg("danger");
            setLabel("High Fall Risk");
        }

        if (score >= 6 && score <= 13) {
            setBg("warning");
            setLabel("Moderate Fall Risk");
        }

        if (score > 0 && score < 6) {
            setBg("primary");
            setLabel("Mild Fall Risk");
        }

        if (score === 0) {
            setBg("");
            setLabel("");
        }
    }, [score]);

    return <Badge bg={bg} className="p-2">{`${label} (${score})`}</Badge>;
};
