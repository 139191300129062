import icon from "../../../../../../../../assets/person-chalkboard-solid-red.svg";

export const SummaryResponseTeachingExam = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Response Teaching
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Who received teaching</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.whoReceivedTeaching?.length === 0 || !data?.whoReceivedTeaching) && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 2,
                                }}
                            >
                                {data?.whoReceivedTeaching?.map((item, index) => {
                                    if (item !== "Other" && item !== "") return <li key={index}>{item}</li>;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-3 fw-bold">Understanding</div>
                        <div className="col-prt-3 fw-bold">Understanding Level</div>
                        <div className="col-prt-3 fw-bold">Demonstration</div>
                        <div className="col-prt-3 fw-bold">Demonstration Level</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-3 text-primary">{data?.understanding ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.understandingLevel ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.demonstration ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.demonstrationLevel ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Comments</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.comments ?? "-"}</div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
