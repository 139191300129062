//css
import "./nurseOrdersDialog.css";

//Icons
import icon from "../../../../assets/hand-holding-heart-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getNurseOrdersPreviousVisitById, updateNurseOrdersSection } from "../../../../api/nurseAssessment/nurseOrdersAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import Select from "react-select";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const NurseOrdersDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //nurse Order details data
    const nurseOrderDetailsData = {
        id: null,
        skill: "",
        daysForWeek: "",
        hoursForDay: "",
        durationPeriod: "",
        durationValue: "0",
        orderDetails: "",
        hhaAssistClient: "",
        rnCompleteTaskMDOrders: "",
        rnInitialAssessmentLPNMedicationPRNMedChanges: "",
        rnInitialAssessmentPRNOversight: "",
        rnSupervisoryVisit: "",
        comments: "",
    };

    //Nurse Review data state
    const [nurseOrdersData, setNurseOrdersData] = useState(null);

    //Nurse Order Item data state
    const [nurseOrderItemData, setNurseOrderItemData] = useState(null);

    //show Nurse Order Form state
    const [showNurseOrderForm, setShowNurseOrderForm] = useState(false);

    //Form mode state (new or edit)
    const [formMode, setFormMode] = useState(null);

    //Selected Nurse Order index
    const [selectedNurseOrderIndex, setSelectedNurseOrderIndex] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of functional limitations
    const listOfFunctionalLimitations = [
        { label: "Ambulation", value: "Ambulation" },
        { label: "Amputation", value: "Amputation" },
        { label: "Bowel incontinence", value: "Bowel incontinence" },
        { label: "Bladder incontinence", value: "Bladder incontinence" },
        { label: "Cognitive Impairment", value: "Cognitive Impairment" },
        { label: "Contracture", value: "Contracture" },
        { label: "Dyspnea w/ min exertion", value: "Dyspnea w/ min exertion" },
        { label: "Endurance", value: "Endurance" },
        { label: "Hearing Impaired", value: "Hearing Impaired" },
        { label: "Legally Blind", value: "Legally Blind" },
        { label: "Paralysis", value: "Paralysis" },
        { label: "Speech", value: "Speech" },
    ];

    //list of activities permitted
    const listOfActivitiesPermitted = [
        { label: "No Restrictions", value: "No Restrictions" },
        { label: "Bedrest", value: "Bedrest" },
        { label: "Cane", value: "Cane" },
        { label: "Complete Bedrest", value: "Complete Bedrest" },
        { label: "Crutches", value: "Crutches" },
        { label: "Exercises as Prescribed", value: "Exercises as Prescribed" },
        { label: "Independent at Home", value: "Independent at Home" },
        { label: "Partial Weight Bearing", value: "Partial Weight Bearing" },
        { label: "Transfer Bed/Chair", value: "Transfer Bed/Chair" },
        { label: "Up as Tolerated", value: "Up as Tolerated" },
        { label: "Walker", value: "Walker" },
        { label: "Wheelchair", value: "Wheelchair" },
        { label: "Other", value: "Other" },
    ];

    //list of functional limitations
    const listOfClientAssistanceNeeds = [
        { label: "Ambulating", value: "Ambulating" },
        { label: "Bathing", value: "Bathing" },
        { label: "Continence", value: "Continence" },
        { label: "Dressing", value: "Dressing" },
        { label: "Eating", value: "Eating" },
        { label: "Feeding", value: "Feeding" },
        { label: "Toileting", value: "Toileting" },
        { label: "Transferring", value: "Transferring" },
    ];

    //list of order details
    const listOfOrderDetails = [
        { label: "RN to conduct initial visit and re-assessment visits to complete a comprehensive assessment and to develop a professional care plan.", value: "RN to conduct initial visit and re-assessment visits to complete a comprehensive assessment and to develop a professional care plan." },
        { label: "RN to conduct medication management visits to pre-pour prescribed medications; teach and observe for efficacy, side effects; report to physician any changes in physical and emotional status.", value: "RN to conduct medication management visits to pre-pour prescribed medications; teach and observe for efficacy, side effects; report to physician any changes in physical and emotional status." },
        { label: "RN to conduct regular visits to assess, monitor, and evaluate client status; measure vital signs; review medication profile; revise and implement client care plan.", value: "RN to conduct regular visits to assess, monitor, and evaluate client status; measure vital signs; review medication profile; revise and implement client care plan." },
        { label: "RN to teach, supervise and counsel the family about the safe provision of care at home and to initiate preventative measures for safety.", value: "RN to teach, supervise and counsel the family about the safe provision of care at home and to initiate preventative measures for safety." },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        setNurseOrdersData(data?.nurseOrdersSection);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: nurseOrdersData?.id, status: nurseOrdersData?.status, nurseOrders: [] };
        setNurseOrdersData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...nurseOrdersData };

        //===========================================================
        //Saving Nurse Review Section
        updateNurseOrdersSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", nurseOrdersSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleLoadDataPreviousVisitClick = () => {
        setLoading(true);
        getNurseOrdersPreviousVisitById(data.id)
            .then((ret) => {
                //clear nurse orders ids
                ret.nurseOrders = ret.nurseOrders.map((item) => {
                    return { ...item, id: null };
                });

                const currentData = data?.nurseOrdersSection;
                const newData = { ...data, nurseOrdersSection: { ...ret, id: currentData?.id, progress: currentData?.progress } };
                setData(newData);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data.");
                }
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => setTimeout(() => setLoading(false), 1000));
    };

    const handleMainFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...nurseOrdersData, [name]: value };
        setNurseOrdersData(newData);
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...nurseOrderItemData, [name]: value };
        setNurseOrderItemData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...nurseOrdersData, [name]: checked };
        setNurseOrdersData(newData);
    };

    const handleOrderCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...nurseOrderItemData, [name]: checked };
        setNurseOrderItemData(newData);
    };

    const handleFunctionalLimitationChange = (e) => {
        let functionalLimitationsNew = e?.map((i) => i.value);
        const newData = { ...nurseOrdersData, functionalLimitations: functionalLimitationsNew };
        setNurseOrdersData(newData);
    };

    const handleActivitiesPermittedChange = (e) => {
        let activitiesPermittedNew = e?.map((i) => i.value);
        const newData = { ...nurseOrdersData, activitiesPermitted: activitiesPermittedNew };
        setNurseOrdersData(newData);
    };

    const handleClientAssistanceNeedsChange = (e) => {
        let clientAssistanceNeedsNew = e?.map((i) => i.value);
        const newData = { ...nurseOrdersData, clientAssistanceNeeds: clientAssistanceNeedsNew };
        setNurseOrdersData(newData);
    };

    const handleAddNewNurseOrder = () => {
        const newData = nurseOrderDetailsData;
        setNurseOrderItemData(newData);
        setFormMode("New");
        setShowNurseOrderForm(true);
    };

    const handleAddNurseOrder = () => {
        let nurseOrders = nurseOrdersData?.nurseOrders ? nurseOrdersData?.nurseOrders : [];
        const newData = { ...nurseOrdersData, nurseOrders: [...nurseOrders, nurseOrderItemData] };
        setNurseOrdersData(newData);
        setShowNurseOrderForm(false);
    };

    const handleUpdateNurseOrder = () => {
        let nurseOrders = nurseOrdersData?.nurseOrders ? nurseOrdersData?.nurseOrders : [];
        nurseOrders[selectedNurseOrderIndex] = nurseOrderItemData;
        const newData = { ...nurseOrdersData, nurseOrders: nurseOrders };
        setNurseOrdersData(newData);
        setShowNurseOrderForm(false);
    };

    const handleRemoveNurseOrder = () => {
        let nurseOrders = [...nurseOrdersData?.nurseOrders];
        nurseOrders.splice(selectedNurseOrderIndex, 1);
        const newData = { ...nurseOrdersData, nurseOrders: nurseOrders };
        setNurseOrdersData(newData);
        setShowNurseOrderForm(false);
    };

    const editNurseOrder = (index) => {
        const newData = nurseOrdersData?.nurseOrders[index];
        setSelectedNurseOrderIndex(index);
        setNurseOrderItemData(newData);
        setFormMode("Edit");
        setShowNurseOrderForm(true);
    };

    return (
        <Modal dialogClassName="nurseOrdersSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Plan of Care - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{nurseOrdersData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleMainFieldChange} value={nurseOrdersData?.status}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {!readOnly && (
                        <div className="row">
                            <div className="col-12 text-end">
                                <button className="btn btn-sm btn-outline-secondary" onClick={handleLoadDataPreviousVisitClick}>
                                    {!loading && "Load Data from Previous Visit"}
                                    {loading && (
                                        <span className="ps-2">
                                            <span className="pe-2">Loading...</span>
                                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Prognosis</span>
                            {readOnly && <div className="text-success pb-2">{nurseOrdersData?.status ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="prognosis" className="form-select" onChange={handleMainFieldChange} value={nurseOrdersData?.prognosis ?? ""} disabled={!["Applicable"].includes(nurseOrdersData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Poor">Poor</option>
                                        <option value="Guarded">Guarded</option>
                                        <option value="Fair">Fair</option>
                                        <option value="Good">Good</option>
                                        <option value="Excellent">Excellent</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Functional Limitations</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {nurseOrdersData?.status === "Applicable"
                                        ? nurseOrdersData?.functionalLimitations?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="functionalLimitations"
                                        id="functionalLimitations"
                                        menuPlacement="bottom"
                                        options={listOfFunctionalLimitations}
                                        //value={listOfFunctionalLimitations.value}
                                        //defaultValue={nurseOrdersData?.functionalLimitations?.map((dev) => listOfFunctionalLimitations?.find((i) => i.value === dev))}
                                        value={(nurseOrdersData?.functionalLimitations ?? []).map((dev) => listOfFunctionalLimitations?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleFunctionalLimitationChange}
                                        isDisabled={!["Applicable"].includes(nurseOrdersData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Activities Permitted</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {nurseOrdersData?.status === "Applicable"
                                        ? nurseOrdersData?.activitiesPermitted?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select isMulti name="activitiesPermitted" id="activitiesPermitted" menuPlacement="bottom" options={listOfActivitiesPermitted} value={(nurseOrdersData?.activitiesPermitted ?? []).map((dev) => listOfActivitiesPermitted?.find((i) => i.value === dev))} menuPortalTarget={document.body} styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} onChange={handleActivitiesPermittedChange} isDisabled={!["Applicable"].includes(nurseOrdersData?.status)}></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">If Other Activity Permitted (Describe)</span>
                            {readOnly && <div className="text-success pb-2">{nurseOrdersData?.status === "Applicable" ? nurseOrdersData?.activitiesPermittedOther ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="activitiesPermittedOther" type="text" className="form-control" placeholder={`Describe`} value={nurseOrdersData?.activitiesPermittedOther ?? ""} onChange={handleMainFieldChange} maxLength="50" disabled={!["Applicable"].includes(nurseOrdersData?.status) || !nurseOrdersData?.activitiesPermitted?.includes("Other")} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">This assessment confirms the client has suffered a loss of functional capacity which necessitates assistance with the following Activities of Daily Living</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {nurseOrdersData?.status === "Applicable"
                                        ? nurseOrdersData?.clientAssistanceNeeds?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="activitiesPermitted"
                                        id="activitiesPermitted"
                                        menuPlacement="bottom"
                                        options={listOfClientAssistanceNeeds}
                                        value={(nurseOrdersData?.clientAssistanceNeeds ?? []).map((dev) => listOfClientAssistanceNeeds?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleClientAssistanceNeedsChange}
                                        isDisabled={!["Applicable"].includes(nurseOrdersData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-1 text-end ">
                            <input name="clientRequiresCare" id="clientRequiresCare" type="checkbox" className="form-check-input" checked={nurseOrdersData?.clientRequiresCare ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(nurseOrdersData?.status)} disabled={readOnly} value="" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-11 ps-0 ms-0">
                            <label className="form-check-label ps-2" htmlFor="clientRequiresCare">
                                Based on this clinician’s assessment the client requires care due to the presence of a cognitive impairment requiring supervision, including verbal cueing by another person in order to protect the client or others.
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-1 text-end ">
                            <input name="clientRequiresCareAtLeast90Days" id="clientRequiresCareAtLeast90Days" type="checkbox" className="form-check-input" checked={nurseOrdersData?.clientRequiresCareAtLeast90Days ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(nurseOrdersData?.status)} disabled={readOnly} value="" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-11 ps-0 ms-0">
                            <label className="form-check-label ps-2" htmlFor="clientRequiresCareAtLeast90Days">
                                Client is chronically ill and need for care is expected to last at least 90 days.
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Current Nurse Orders <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm nurseOrdersList mt-1">
                                <thead>
                                    <tr>
                                        <th>Skill</th>
                                        <th className="text-center">Days/Week</th>
                                        <th className="text-center">Hours/Day</th>
                                        <th>Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!nurseOrdersData?.nurseOrders || nurseOrdersData?.nurseOrders.length === 0) && (
                                        <>
                                            <tr>
                                                <td colSpan="4" align="center">
                                                    No nurse orders
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {nurseOrdersData?.nurseOrders &&
                                        nurseOrdersData?.nurseOrders.map((order, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="small text-primary" onClick={() => editNurseOrder(index)}>
                                                        <a href="#nurseOrderForm">{order?.skill}</a>
                                                    </td>
                                                    <td className="small text-center">{order?.daysForWeek}</td>
                                                    <td className="small text-center">{order?.hoursForDay}</td>
                                                    <td className="small">
                                                        {order?.durationValue} {order?.durationPeriod}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                            {!readOnly && (
                                <a href="#nurseOrderForm">
                                    <button className="btn btn-sm btn-success" onClick={handleAddNewNurseOrder} disabled={!["Applicable"].includes(nurseOrdersData?.status)}>
                                        Add New Order
                                    </button>
                                </a>
                            )}
                        </div>
                    </div>

                    {showNurseOrderForm && (
                        <>
                            <div className="row mx-0 px-0 mt-4" id="medicationForm">
                                <div className="col-12 mx-0 px-0">
                                    <span className="text-success">
                                        Nurse Order <hr className="my-1" />
                                    </span>
                                </div>
                            </div>
                            <div className="p-2" style={{ backgroundColor: "rgba(88, 174, 137, 0.15)" }}>
                                <div className="row">
                                    <div className="col-8"></div>
                                </div>

                                <div className="row">
                                    <div className="col-2">
                                        <span className="my-1 d-block">Skill</span>
                                        <select name="skill" className="form-select" onChange={handleFieldChange} value={nurseOrderItemData?.skill} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            {/*<option value="Care Manager">Care Manager</option>*/}
                                            {/*<option value="Clerical">Clerical</option>*/}
                                            {/*<option value="CNA">CNA</option>*/}
                                            <option value="Companion">Companion</option>
                                            {/*<option value="Dementia Specialist">Dementia Specialist</option>*/}
                                            {/*<option value="HCC">HCC</option>*/}
                                            <option value="HHA">HHA</option>
                                            <option value="LPN">LPN</option>
                                            <option value="LVN">LVN</option>
                                            <option value="MSW">MSW</option>
                                            {/*<option value="Music Therapist">Music Therapist</option>*/}
                                            {/*<option value="Nurse Practitioner">Nurse Practitioner</option>*/}
                                            {/*<option value="Occupational Therapist">Occupational Therapist</option>*/}
                                            {/*<option value="On-Call">On-Call</option>*/}
                                            {/*<option value="OT Assistant">OT Assistant</option>*/}
                                            {/*<option value="PCA">PCA</option>*/}
                                            {/*<option value="Pet Therapist">Pet Therapist</option>*/}
                                            {/*<option value="Physical Therapist">Physical Therapist</option>*/}
                                            {/*<option value="Psychotherapist">Psychotherapist</option>*/}
                                            {/*<option value="PT Assistant">PT Assistant</option>*/}
                                            <option value="PRN">PRN</option>
                                            <option value="PT">PT</option>
                                            <option value="OT">OT</option>
                                            <option value="RN">RN</option>
                                            <option value="ST">ST</option>
                                            {/*<option value="Speech Therapist">Speech Therapist</option>*/}
                                            {/*<option value="UBERHHA">UBERHHA</option>*/}
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Days/Week</span>
                                        <select name="daysForWeek" className="form-select" onChange={handleFieldChange} value={nurseOrderItemData?.daysForWeek} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly}>
                                            <option value="0">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="col-3">
                                        <span className="my-1 d-block">Hours/Day</span>
                                        <select name="hoursForDay" className="form-select" onChange={handleFieldChange} value={nurseOrderItemData?.hoursForDay} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly}>
                                            <option value="0">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="col-2 pe-0 me-0">
                                        <span className="my-1 d-block">Duration</span>
                                        <select name="durationPeriod" className="form-select" onChange={handleFieldChange} value={nurseOrderItemData?.durationPeriod} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            <option value="Days">Days</option>
                                            <option value="Weeks">Weeks</option>
                                            <option value="Months">Months</option>
                                            <option value="Years">Years</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>

                                    <div className="col-2 ps-1">
                                        <span className="my-1 d-block">&nbsp;</span>
                                        <input name="durationValue" type="number" className="form-control" placeholder={`Value`} value={nurseOrderItemData?.durationValue} onChange={handleFieldChange} maxLength="3" disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <span className="my-1 d-block">Order Details</span>
                                        {/*
                                        <select name="orderDetails" className="form-select" onChange={handleFieldChange} value={nurseOrderItemData?.orderDetails} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly}>
                                            <option value="">Select</option>
                                            {listOfOrderDetails.map((i) => (
                                                <option value={i.value}>{i.label}</option>
                                            ))}
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                            */}
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-1 text-end ">
                                        <input name="rnSupervisoryVisit" id="rnSupervisoryVisit" type="checkbox" className="form-check-input" checked={nurseOrderItemData?.rnSupervisoryVisit ?? false} onChange={handleOrderCheckboxChange} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly} value="" />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-11 ps-0 ms-0">
                                        <label className="form-check-label ps-2" htmlFor="rnSupervisoryVisit">
                                            RN supervisory visit every two (2) months and PRN for RN clinical assessment and employee supervision. HHA to notify Nurse Supervisor with any change in condition.
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1 text-end ">
                                        <input name="hhaAssistClient" id="hhaAssistClient" type="checkbox" className="form-check-input" checked={nurseOrderItemData?.hhaAssistClient ?? false} onChange={handleOrderCheckboxChange} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly} value="" />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-11 ps-0 ms-0">
                                        <label className="form-check-label ps-2" htmlFor="hhaAssistClient">
                                            HHA may assist client with the following ADL’s including, but not limited to: bathing, dressing, eating, toileting, transfers, medication reminders, ambulation, meal preparation, light housekeeping, shopping and accompanying to appointments.
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1 text-end ">
                                        <input name="rnInitialAssessmentLPNMedicationPRNMedChanges" id="rnInitialAssessmentLPNMedicationPRNMedChanges" type="checkbox" className="form-check-input" checked={nurseOrderItemData?.rnInitialAssessmentLPNMedicationPRNMedChanges ?? false} onChange={handleOrderCheckboxChange} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly} value="" />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-11 ps-0 ms-0">
                                        <label className="form-check-label ps-2" htmlFor="rnInitialAssessmentLPNMedicationPRNMedChanges">
                                            RN initial assessment with reassessment every 30 days for supervision of LPN. LPN medication pre pour every two (2) weeks and PRN for med changes x 60 days vital signs completed with each visit x 60 days.
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1 text-end ">
                                        <input name="rnInitialAssessmentPRNOversight" id="rnInitialAssessmentPRNOversight" type="checkbox" className="form-check-input" checked={nurseOrderItemData?.rnInitialAssessmentPRNOversight ?? false} onChange={handleOrderCheckboxChange} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly} value="" />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                    <div className="col-11 ps-0 ms-0">
                                        <label className="form-check-label ps-2" htmlFor="rnInitialAssessmentPRNOversight">
                                            RN initial assessment with reassessment every 30 days and PRN for oversight of Plan of care and supervision of LPN.
                                        </label>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1 text-end ">
                                        <input name="rnCompleteTaskMDOrders" id="rnCompleteTaskMDOrders" type="checkbox" className="form-check-input" checked={nurseOrderItemData?.rnCompleteTaskMDOrders ?? false} onChange={handleOrderCheckboxChange} disabled={!["Applicable"].includes(nurseOrdersData?.status) || readOnly} value="" />
                                    </div>
                                    <div className="col-11 ps-0 ms-0">
                                        <label className="form-check-label ps-2" htmlFor="rnCompleteTaskMDOrders">
                                            RN and/or LPN to complete tasks per MD orders as follows
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-11 offset-1 mt-2 ps-0">
                                        <input name="comments" type="text" className="form-control" placeholder={`Comments`} value={nurseOrderItemData?.comments} onChange={handleFieldChange} maxLength="200" disableda={(!["Applicable"].includes(nurseOrdersData?.status) && !nurseOrderItemData?.rnCompleteTaskMDOrders) || readOnly} disabled={!["Applicable"].includes(nurseOrdersData?.status) || !nurseOrderItemData?.rnCompleteTaskMDOrders || readOnly} />
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </div>
                                </div>
                            </div>

                            {!readOnly && (
                                <div className="row">
                                    <div className="col-12 mt-2">
                                        {formMode === "New" ? (
                                            <button type="button" className="btn btn-sm btn-outline-success" onClick={handleAddNurseOrder}>
                                                Add
                                            </button>
                                        ) : (
                                            <>
                                                <button type="button" className="btn btn-sm btn-outline-success" onClick={handleUpdateNurseOrder}>
                                                    Update
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-danger ms-2" onClick={handleRemoveNurseOrder}>
                                                    Remove
                                                </button>
                                            </>
                                        )}

                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-secondary ms-2"
                                            onClick={() => {
                                                setShowNurseOrderForm(false);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} disableSave={showNurseOrderForm} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, nurseOrdersSection: data?.nurseOrdersSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
