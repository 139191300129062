import { useEffect } from "react";
import { useState } from "react";
import { Badge } from "react-bootstrap";

export const ScoreBradenScale = ({ score }) => {
    const [bg, setBg] = useState("primary");
    const [label, setLabel] = useState("");

    useEffect(() => {
        if (score >= 15) {
            setBg("primary");
            setLabel("Mild Risk");
        }

        if (score === 13 || score === 14) {
            setBg("warning");
            setLabel("Moderate Risk");
        }

        if (score >= 10 && score <= 12) {
            setBg("warning");
            setLabel("High Risk");
        }

        if (score > 0 && score <= 9) {
            setBg("danger");
            setLabel("Severe Risk");
        }

        if (score === 0) {
            setBg("");
            setLabel("");
        }
    }, [score]);

    return <Badge bg={bg} className="p-2">{`${label} (${score})`}</Badge>;
};
