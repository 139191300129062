//css
import "./skilledObservationAssessmentDialog.css";

//Icons
import icon from "../../../../assets/stethoscope-solid-green.svg";

import Select from "react-select";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getSkilledObservationAssessmentPreviousVisitById, updateSkilledObservationAssessmentSection } from "../../../../api/nurseAssessment/skilledObservationAssessmentAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { ScorePAINAD } from "../../../../components/scorePAINAD/scorePAINAD";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";
import { skilledObservationAssessmentDialogAlerts } from "./skilledObservationAssessmentDialogAlerts";

export const SkilledObservationAssessmentDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Cardiopulmonary data state
    const [skilledObservationAssessmentData, setSkilledObservationAssessmentData] = useState(null);

    //Form Validation Alerts
    const [formValidationAlerts, setFormValidationAlerts] = useState([]);

    //touched fields
    const [touchedFields, setTouchedFields] = useState([]);

    //PAINAD Scale score  state
    const [score, setScore] = useState(0);

    //saving state
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of mental status
    const listOfMentalStatus = [
        { label: "No Change", value: "No Change" },
        { label: "Agitated", value: "Agitated" },
        { label: "Alert & Oriented", value: "Alert & Oriented" },
        { label: "Anxiety", value: "Anxiety" },
        { label: "Combative", value: "Combative" },
        { label: "Confused", value: "Confused" },
        { label: "Depressed", value: "Depressed" },
        { label: "Forgetful", value: "Forgetful" },
        { label: "Lethargic", value: "Lethargic" },
        { label: "Resistive to Care", value: "Resistive to Care" },
    ];

    //list of skin issues
    const listOfSkinIssues = [
        { label: "WNL", value: "WNL" },
        { label: "Itch", value: "Itch" },
        { label: "Rash", value: "Rash" },
        { label: "Dry", value: "Dry" },
        { label: "Scaling", value: "Scaling" },
        { label: "Redness", value: "Redness" },
        { label: "Bruises", value: "Bruises" },
        { label: "Ecchymosis", value: "Ecchymosis" },
        { label: "Pallor", value: "Pallor" },
        { label: "Jaundice", value: "Jaundice" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        setSkilledObservationAssessmentData(data?.skilledObservationSection);
    }, [data]);

    useEffect(() => {
        let score = 0;

        switch (skilledObservationAssessmentData?.behaviorPAINAD) {
            case "Occasional labored breathing, Short period of hyperventilation":
                score = score + 1;
                break;

            case "Noisy labored breathing, Long period of hyperventilation, Cheyne-Stokes respirations":
                score = score + 2;
                break;

            default:
                score = score;
        }

        switch (skilledObservationAssessmentData?.negativeVocalizationPAINAD) {
            case "Occasional moan or groan, Low-level speech with a negative or disapproving quality":
                score = score + 1;
                break;

            case "Repeated troubled calling out, Loud moaning or groaning, Crying":
                score = score + 2;
                break;

            default:
                score = score;
        }

        switch (skilledObservationAssessmentData?.facialExpressionPAINAD) {
            case "Sad, Frightened, Frown":
                score = score + 1;
                break;

            case "Facial grimacing":
                score = score + 2;
                break;

            default:
                score = score;
        }

        switch (skilledObservationAssessmentData?.bodyLanguagePAINAD) {
            case "Tense, Distressed pacing, Fidgeting":
                score = score + 1;
                break;

            case "Rigid, Fists clenched, Knees pulled up, Pulling or pushing away, Striking out":
                score = score + 2;
                break;

            default:
                score = score;
        }

        switch (skilledObservationAssessmentData?.consolabilityPAINAD) {
            case "Distracted or reassured by voice or touch":
                score = score + 1;
                break;

            case "Unable to console, distract, or reassure":
                score = score + 2;
                break;

            default:
                score = score;
        }

        setScore(score);
    }, [skilledObservationAssessmentData]);

    //=== Methods ============================================================

    //const getIsValidClass = (fieldName) => (touchedFields.includes(fieldName) && (formValidationAlerts ?? []).find((err) => err.field === fieldName)?.alertMessage ? "is-invalid" : "");
    const getIsValidClass = (fieldName) => {
        if (touchedFields.includes(fieldName)) {
            if (Array.isArray(formValidationAlerts)) {
                const alert = formValidationAlerts.find((err) => err.field === fieldName);
                if (alert && alert.alertMessage) {
                    return "is-invalid";
                }
                if (skilledObservationAssessmentData[fieldName]) return "is-valid";
            }
        }
        return "";
    };

    //const getAlertMessage = (fieldName) => (formValidationAlerts ?? []).find((err) => err.field === fieldName)?.alertMessage;
    const getAlertMessage = (fieldName) => {
        if (Array.isArray(formValidationAlerts)) {
            const alert = formValidationAlerts.find((err) => err.field === fieldName);
            if (alert && alert.alertMessage) {
                return alert.alertMessage;
            }
        }
        return null; //
    };

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: skilledObservationAssessmentData?.id, status: skilledObservationAssessmentData?.status, amentalStatus: [null], askin: [""] };
        setSkilledObservationAssessmentData(newData);
    };

    const handleSave = () => {
        setSaving(true);

        const saveObj = {
            ...skilledObservationAssessmentData,
        };

        //===========================================================

        //Saving SkilledObservationAssessmentSection
        updateSkilledObservationAssessmentSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", skilledObservationSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...skilledObservationAssessmentData, [name]: value };
        setSkilledObservationAssessmentData(newData);
    };

    const handleBlur = (e) => {
        const name = e.target.name;
        const newTouchedFields = [...touchedFields];

        if (!newTouchedFields.includes(name)) {
            newTouchedFields.push(name);
            setTouchedFields(newTouchedFields);
        }

        //get alerts (if any)
        const alerts = skilledObservationAssessmentDialogAlerts(skilledObservationAssessmentData);
        setFormValidationAlerts(alerts.alerts);
    };

    const handleMentalStatusChange = (e) => {
        let mentalStatusNew = e?.map((i) => i.value);
        const newData = { ...skilledObservationAssessmentData, mentalStatus: mentalStatusNew };
        setSkilledObservationAssessmentData(newData);
    };

    const handleSkinChange = (e) => {
        let skinNew = e?.map((i) => i.value);
        const newData = { ...skilledObservationAssessmentData, skin: skinNew };
        setSkilledObservationAssessmentData(newData);
    };

    // const handleLoadDataPreviousVisitClick = () => {
    //     setLoading(true);
    //     getSkilledObservationAssessmentPreviousVisitById(data.id)
    //         .then((ret) => {
    //             const currentData = data?.skilledObservationSection;
    //             const newData = { ...data, skilledObservationSection: { ...ret, id: currentData?.id, progress: currentData?.progress } };
    //             setData(newData);

    //             //hide message after 1s
    //             setTimeout(() => setLoading(false), 1000);
    //         })
    //         .catch((err) => {
    //             try {
    //                 setOperationError(() => (isError(err) ? err.message : err));
    //             } catch (error) {
    //                 setOperationError("Error loading data.");
    //             }
    //             setLoading(false);
    //             //hide message after 5s
    //             setTimeout(() => setOperationError(null), 5000);
    //         });
    // };

    return (
        <Modal dialogClassName="skilledObservationModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Skilled / Observation Assessment - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                <select name="status" className="form-select" onChange={handleFieldChange} value={skilledObservationAssessmentData?.status ?? ""}>
                                    <option value="">Select</option>
                                    <option value="Not Applicable">Not Applicable</option>
                                    <option value="Applicable">Applicable</option>
                                </select>
                                <div className="d-block text-danger small text-end">&nbsp;</div>
                            </div>
                        </div>
                    )}

                    {/* {!readOnly && (
                        <div className="row">
                            <div className="col-12 text-end">
                                <button className="btn btn-sm btn-outline-secondary" onClick={handleLoadDataPreviousVisitClick}>
                                    {!loading && "Load Data from Previous Visit"}
                                    {loading && (
                                        <span className="ps-2">
                                            <span className="pe-2">Loading...</span>
                                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    )} */}

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Mental Status</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {skilledObservationAssessmentData?.status === "Applicable"
                                        ? skilledObservationAssessmentData?.mentalStatus?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="mentalStatus"
                                        id="mentalStatus"
                                        menuPlacement="bottom"
                                        options={listOfMentalStatus}
                                        //value={listOfMentalStatus.value}
                                        //defaultValue={skilledObservationAssessmentData?.mentalStatus?.map((dev) => listOfMentalStatus?.find((i) => i.value === dev)) ?? ""}
                                        value={(skilledObservationAssessmentData?.mentalStatus ?? []).map((dev) => listOfMentalStatus?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleMentalStatusChange}
                                        isDisabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <span className="my-1 d-block">Details</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.mentalStatusDetails ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="mentalStatusDetails" placeholder={`Enter Details`} onChange={handleFieldChange} value={skilledObservationAssessmentData?.mentalStatusDetails ?? ""} maxLength="50" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status) || [""].includes(skilledObservationAssessmentData?.mentalStatus)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2">
                            <span className="my-1 d-block">Temp. (°F)</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.temperature ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className={`form-control ${getIsValidClass("temperature")}`} name="temperature" placeholder={`Temp`} value={skilledObservationAssessmentData?.temperature ?? ""} onChange={handleFieldChange} onBlur={handleBlur} maxLength="10" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">{getAlertMessage("temperature")}</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">&nbsp;</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.temperatureSource ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="temperatureSource" onChange={handleFieldChange} value={skilledObservationAssessmentData?.temperatureSource ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Oral">Oral</option>
                                        <option value="Axillary">Axillary</option>
                                        <option value="Temporal">Temporal</option>
                                        <option value="Tympanic">Tympanic</option>
                                        <option value="Rectal">Rectal</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Pulse</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.pulse ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className={`form-control ${getIsValidClass("pulse")}`} name="pulse" placeholder={`Pulse`} value={skilledObservationAssessmentData?.pulse ?? ""} onChange={handleFieldChange} onBlur={handleBlur} maxLength="15" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">{getAlertMessage("pulse")}</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">&nbsp;</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.pulseSource ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="pulseSource" onChange={handleFieldChange} value={skilledObservationAssessmentData?.pulseSource ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Radial">Radial</option>
                                        <option value="Apical">Apical</option>
                                        <option value="Brachial">Brachial</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Weight (lbs)</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.weight ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="weight" placeholder={`Weight`} value={skilledObservationAssessmentData?.weight ?? ""} onChange={handleFieldChange} maxLength="10" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">&nbsp;</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.weightType ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="weightType" onChange={handleFieldChange} value={skilledObservationAssessmentData?.weightType ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Actual">Actual</option>
                                        <option value="Reported">Reported</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        {/* 
                        <div className="col-2">
                            <span className="my-1 d-block">Respirations</span>
                            <input type="text" className="form-control" placeholder={`Resp`} value={null} name="respirations" onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">&nbsp;</span>
                            <select className="form-select" name="respirationType" onChange={handleFieldChange} value={null} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                <option value="">Select</option>
                                <option value="Regular">Regular</option>
                                <option value="Irregular">Irregular</option>
                            </select>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        */}
                    </div>

                    <div className="row">
                        <div className="col-2">
                            <span className="my-1 d-block">Blood Pressure</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.bloodPressure ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className={`form-control ${getIsValidClass("bloodPressure")}`} name="bloodPressure" placeholder={`Pressure`} value={skilledObservationAssessmentData?.bloodPressure ?? ""} onChange={handleFieldChange} onBlur={handleBlur} maxLength="15" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">{getAlertMessage("bloodPressure")}</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Arm</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.bloodPressureArm ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="bloodPressureArm" onChange={handleFieldChange} value={skilledObservationAssessmentData?.bloodPressureArm ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">-</option>
                                        <option value="Left">Left</option>
                                        <option value="Right">Right</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Position</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.bloodPressurePosition ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="bloodPressurePosition" onChange={handleFieldChange} value={skilledObservationAssessmentData?.bloodPressurePosition ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Lying">Lying</option>
                                        <option value="Sitting">Sitting</option>
                                        <option value="Standing">Standing</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Appetite</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.appetite ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="appetite" onChange={handleFieldChange} value={skilledObservationAssessmentData?.appetite ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Good">Good</option>
                                        <option value="Fair">Fair</option>
                                        <option value="Poor">Poor</option>
                                        <option value="NPO">NPO</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 mb-1 d-block">Hydration Adequate</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.hydrationAdequate ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="hydrationAdequate" onChange={handleFieldChange} value={skilledObservationAssessmentData?.hydrationAdequate ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Respiration Rate</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.respirationRate ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className={`form-control ${getIsValidClass("respirationRate")}`} name="respirationRate" placeholder={`Rate`} value={skilledObservationAssessmentData?.respirationRate ?? ""} onChange={handleFieldChange} onBlur={handleBlur} maxLength="5" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">{getAlertMessage("respirationRate")}</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Skin</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {skilledObservationAssessmentData?.status === "Applicable"
                                        ? skilledObservationAssessmentData?.skin?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="skin"
                                        id="skin"
                                        menuPlacement="bottom"
                                        options={listOfSkinIssues}
                                        //value={listOfSkinIssues.value}
                                        //defaultValue={skilledObservationAssessmentData?.skin?.map((dev) => listOfSkinIssues?.find((i) => i.value === dev))}
                                        value={(skilledObservationAssessmentData?.skin ?? []).map((dev) => listOfSkinIssues?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleSkinChange}
                                        isDisabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-6">
                            <span className="my-1 d-block">Skin Details</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.skinDetails ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="skinDetails" placeholder={`Skin Details`} value={skilledObservationAssessmentData?.skinDetails ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        {/* 
                        <div className="col-2">
                            <span className="my-1 d-block">Right Eye</span>
                            <select className="form-select" name="visionRightEye" onChange={handleFieldChange} value={null} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                <option value="">Select</option>
                                <option value="Good">Good</option>
                                <option value="Fair">Fair</option>
                                <option value="Poor">Poor</option>
                            </select>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Left Eye</span>
                            <select className="form-select" name="visionLeftEye" onChange={handleFieldChange} value={null} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                <option value="">Select</option>
                                <option value="Good">Good</option>
                                <option value="Fair">Fair</option>
                                <option value="Poor">Poor</option>
                            </select>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        */}
                        <div className="col-4">
                            <span className="my-1 d-block">Vision</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.vision ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="vision" onChange={handleFieldChange} value={skilledObservationAssessmentData?.vision ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Good">Good</option>
                                        <option value="Fair">Fair</option>
                                        <option value="Poor">Poor</option>
                                        <option value="Legally Blind">Legally Blind</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-8">
                            <span className="my-1 d-block">More Details</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.visionDetails ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="visionDetails" placeholder={`Details`} value={skilledObservationAssessmentData?.visionDetails ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Chest Sounds</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.chestSound ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="chestSound" onChange={handleFieldChange} value={skilledObservationAssessmentData?.chestSound ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Clear">Clear</option>
                                        <option value="Crackles/Rales">Crackles/Rales</option>
                                        <option value="Rhonchi/Wheeze">Rhonchi/Wheeze</option>
                                        <option value="Diminished">Diminished</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Location</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.chestSoundLocation ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="chestSoundLocation" placeholder={`Location`} value={skilledObservationAssessmentData?.chestSoundLocation ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">% O² Saturation at</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.o2Saturation ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className={`form-control ${getIsValidClass("o2Saturation")}`} name="o2Saturation" placeholder={`Value`} value={skilledObservationAssessmentData?.o2Saturation ?? ""} onChange={handleFieldChange} onBlur={handleBlur} maxLength="10" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">{getAlertMessage("o2Saturation")}</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Type</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.o2SaturationType ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="o2SaturationType" onChange={handleFieldChange} value={skilledObservationAssessmentData?.o2SaturationType ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="O2">O2</option>
                                        <option value="Room air">Room air</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Last Fall Info</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.lastFallInfo ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="lastFallInfo" placeholder={`Enter info`} onChange={handleFieldChange} value={skilledObservationAssessmentData?.lastFallInfo ?? ""} maxLength="100" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Reported Fall(s)</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.reportedFalls ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="reportedFalls" placeholder={`Describe reported fall(s)`} onChange={handleFieldChange} value={skilledObservationAssessmentData?.reportedFalls ?? ""} maxLength="100" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">
                                Pain<small> (last 24hs)</small>
                            </span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.painLast24hs ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="painLast24hs" onChange={handleFieldChange} value={skilledObservationAssessmentData?.painLast24hs ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Pain Origin</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.painOrigin ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="painOrigin" placeholder={`Pain Origin`} value={skilledObservationAssessmentData?.painOrigin ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Pain Type</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.painType ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="painType" onChange={handleFieldChange} value={skilledObservationAssessmentData?.painType ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Acute">Acute</option>
                                        <option value="Chronic">Chronic</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Pain Location</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.painLocation ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="painLocation" placeholder={`Location`} value={skilledObservationAssessmentData?.painLocation ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Duration</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.painDuration ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" name="painDuration" placeholder={`Duration`} value={skilledObservationAssessmentData?.painDuration ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Intensity</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.painIntensity ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className={`form-select ${getIsValidClass("painIntensity")}`} name="painIntensity" onChange={handleFieldChange} onBlur={handleBlur} value={skilledObservationAssessmentData?.painIntensity ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">{getAlertMessage("painIntensity")}</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-3 m-0 p-0">
                            <span className="text-success">PAINAD scale</span>
                        </div>
                        <div className="col-9 text-end">
                            <ScorePAINAD score={score} />
                        </div>
                        <hr className="my-1" />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Behavior</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.behaviorPAINAD ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="behaviorPAINAD" onChange={handleFieldChange} value={skilledObservationAssessmentData?.behaviorPAINAD ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Normal">Normal</option>
                                        <option value="Occasional labored breathing, Short period of hyperventilation">Occasional labored breathing, Short period of hyperventilation</option>
                                        <option value="Noisy labored breathing, Long period of hyperventilation, Cheyne-Stokes respirations">Noisy labored breathing, Long period of hyperventilation, Cheyne-Stokes respirations</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Negative Vocalization</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.negativeVocalizationPAINAD ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="negativeVocalizationPAINAD" onChange={handleFieldChange} value={skilledObservationAssessmentData?.negativeVocalizationPAINAD ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="None">None</option>
                                        <option value="Occasional moan or groan, Low-level speech with a negative or disapproving quality">Occasional moan or groan, Low-level speech with a negative or disapproving quality</option>
                                        <option value="Repeated troubled calling out, Loud moaning or groaning, Crying">Repeated troubled calling out, Loud moaning or groaning, Crying</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Facial Expression</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.facialExpressionPAINAD ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="facialExpressionPAINAD" onChange={handleFieldChange} value={skilledObservationAssessmentData?.facialExpressionPAINAD ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Smiling or Impressive">Smiling or Impressive</option>
                                        <option value="Sad, Frightened, Frown">Sad, Frightened, Frown</option>
                                        <option value="Facial grimacing">Facial grimacing</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Body Language</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.bodyLanguagePAINAD ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="bodyLanguagePAINAD" onChange={handleFieldChange} value={skilledObservationAssessmentData?.bodyLanguagePAINAD ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Relaxed">Relaxed</option>
                                        <option value="Tense, Distressed pacing, Fidgeting">Tense, Distressed pacing, Fidgeting</option>
                                        <option value="Rigid, Fists clenched, Knees pulled up, Pulling or pushing away, Striking out">Rigid, Fists clenched, Knees pulled up, Pulling or pushing away, Striking out</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Consolability</span>
                            {readOnly && <div className="text-success pb-2">{skilledObservationAssessmentData?.consolabilityPAINAD ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select className="form-select" name="consolabilityPAINAD" onChange={handleFieldChange} value={skilledObservationAssessmentData?.consolabilityPAINAD ?? ""} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)}>
                                        <option value="">Select</option>
                                        <option value="No need to console">No need to console</option>
                                        <option value="Distracted or reassured by voice or touch">Distracted or reassured by voice or touch</option>
                                        <option value="Unable to console, distract, or reassure">Unable to console, distract, or reassure</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <label className="my-1 d-block" htmlFor="comments">
                                Comments
                            </label>
                            {readOnly && <div className="text-success pb-3 pt-2">{skilledObservationAssessmentData?.status === "Applicable" ? skilledObservationAssessmentData?.comments ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <textarea name="comments" id="comments" className="form-control" placeholder={`Comments`} value={skilledObservationAssessmentData?.comments ?? ""} maxLength="1000" onChange={handleFieldChange} disabled={!["Applicable"].includes(skilledObservationAssessmentData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, skilledObservationSection: data?.skilledObservationSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
