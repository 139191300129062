export const getSupervisionReportByClientData = () => {
    return {
        title: "Supervision by Client",
        description: "Supervision insights: Your compass to client excellence.",
        icon: "person-circle-check-solid-red.svg",
        iconHover: "person-circle-check-solid-green.svg",
        cardLink: "/reports/supervisionReportByClient",
        section: "ATK",
    };
};
