import icon from "../../../../../../../../assets/clipboard-user-solid-red.svg";

export const SummaryPhysicalAssessment = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Physical
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>General Health</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">In the past year, have you stayed overnight in a hospital or skilled nursing facility?</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.pastYearStayedOvernightHospitalOrSkilledNursingFacility ? "Yes" : "No"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">In the past year, how many times did you visit a physician?</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.visitsPhysician ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Head and Neck</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Status</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.headStatus ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Findings</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.headFindings ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.headFindings?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.headFindingOther && <li>{data?.headFindingOther}</li>}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Hx/Comments</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.headComments ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Nose</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Status</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.noseStatus ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Findings</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.noseFindings ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.noseFindings?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.noseFindingOther && <li>{data?.noseFindingOther}</li>}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Hx/Comments</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.noseComments ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Eyes</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Status</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.eyesStatus ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Findings</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.eyesFindings ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.eyesFindings?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.eyesFindingOther && <li>{data?.eyesFindingOther}</li>}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Conjuctiva Findings</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.eyesConjuctivaFindings ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.eyesConjuctivaFindings?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.eyesConjuctivaFindingOther && <li>{data?.eyesConjuctivaFindingOther}</li>}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">if Pupils are outside normal limits, describe</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.eyesPupilsOutsideNormalLimitsDescription ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Hx/Comments</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.eyesComments ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Mouth</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Status</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.mouthStatus ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Findings</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.mouthFindings ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.mouthFindings?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.mouthFindingOther && <li>{data?.mouthFindingOther}</li>}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Dentures</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.mouthDentures ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.mouthDentures?.map((item, index) => {
                                            return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Lesions</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.mouthLesions ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.mouthLesions?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.mouthLesionsOther && <li>{data?.mouthLesionsOther}</li>}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Hx/Comments</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.mouthComments ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Ears</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Status</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.earsStatus ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Findings</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.earsFindings ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.earsFindings?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.earsFindingOther && <li>{data?.earsFindingOther}</li>}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-6 fw-bold">Hearing Aide in Good Working Order</div>
                                <div className="col-prt-6 fw-bold">Hearing Aide in Need of Repair</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">
                                    {(data?.earsHearingAideGoodWorkingOrder ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 2,
                                            width: "40%",
                                        }}
                                    >
                                        {data?.earsHearingAideGoodWorkingOrder?.map((item, index) => {
                                            return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                </div>

                                <div className="col-prt-4 text-primary">
                                    {(data?.earsHearingAideNeedRepair ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 2,
                                            width: "40%",
                                        }}
                                    >
                                        {data?.earsHearingAideNeedRepair?.map((item, index) => {
                                            return <li key={index}>{item}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">if Lesions, describe</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.earsLesionsDescription ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Hx/Comments</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.earsComments ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Breasts/Axilla</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Status</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.breastsAxillaStatus ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Findings</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.breastsAxillaFindings ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 4,
                                        }}
                                    >
                                        {data?.breastsAxillaFindings?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.breastsAxillaFindingOther && <li>{data?.breastsAxillaFindingOther}</li>}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">if Mastectomy, when</div>
                                <div className="col-prt-4 fw-bold">if Lumpectomy, when</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.breastsAxillaMastectomyDate?.split("T")[0] ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.breastsAxillaLumpectomyDate?.split("T")[0] ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Hx/Comments</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.breastsAxillaComments ?? "-"}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
