export const getSupervisionReportByEmployeeData = () => {
    return {
        title: "Supervision by Employee",
        description: "Employee supervision made simple with this report.",
        icon: "people-fill-red.svg",
        iconHover: "people-fill-green.svg",
        cardLink: "/reports/supervisionReportByEmployee",
        section: "ATK",
    };
};

/*
1. "Client-focused insights with the Supervision Report."
2. "Unlock client-centric details in the Supervision Report."
3. "Dive into client supervision with a single click."
4. "Navigate client oversight effortlessly with this report."
5. "Empower your decisions with the Supervision Report."
6. "Enhance client understanding through this report."
7. "Client scrutiny made easy with our Supervision Report."
8. "Elevate client relations using the Supervision Report."
9. "Your guide to client oversight: Supervision Report."
10. "Explore client perspectives in the Supervision Report."
11. "Navigate client dynamics seamlessly with this report."
12. "Supervision insights at the tip of your fingers."
13. "Client supervision made simple with this report."
14. "Discover client-centric data in the Supervision Report."
15. "Effortless client management through this report."
16. "Supervision Report: Your window into client success."
17. "Optimize client interactions with this insightful report."
18. "Supervision Report: Elevating your client strategies."
19. "Client supervision mastery starts with this report."
20. "Amplify client understanding using the Supervision Report."
21. "Supervision insights for strategic client partnerships."
22. "Navigate client relationships with the Supervision Report."
23. "Unlock the power of client insights with this report."
24. "Supervision Report: Your key to client satisfaction."
25. "Mastering client dynamics with our Supervision Report."
26. "Supervision at your fingertips for client excellence."
27. "Client-centric success through the Supervision Report."
28. "Elevate your client approach with this report."
29. "Unlocking client potential through Supervision insights."
30. "Supervision Report: Tailoring success for each client."
31. "Client management redefined with our Supervision Report."
32. "Maximize client potential using the Supervision Report."
(x) 33. "Supervision insights: Your compass to client excellence."
34. "Transform client relations with the Supervision Report."
35. "Supervision Report: Navigating success in client partnerships."
36. "Client supervision mastery begins here with this report."
37. "Elevate your client strategy using the Supervision Report."
38. "Unlocking client success with the Supervision Report."
39. "Client insights at your fingertips: Supervision Report."
40. "Supervision mastery for strategic client relationships."
41. "Optimize client interactions through the Supervision Report."
42. "Supervision Report: Your guide to client excellence."
43. "Client-centric decisions start with the Supervision Report."
44. "Elevate your client approach with this comprehensive report."
45. "Unlocking client potential through the Supervision Report."
46. "Supervision insights: Tailoring success for each client."
47. "Navigate client relations seamlessly with the Supervision Report."
48. "Supervision Report: Your key to mastering client dynamics."
49. "Client success unveiled through the power of this report."
50. "Elevate client satisfaction with the insights in our Supervision Report."
*/
