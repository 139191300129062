export const ClientInfo = ({ data }) => {
    return (
        <section id="ClientInfo">
            <div className="row">
                <div className="col-2">
                    <span className="d-block fw-bolder">Phone Number</span>
                    <span className="text-success">
                        <i className="fa fa-phone pe-2" aria-hidden="true"></i>
                        <a href={`tel:${data?.phone?.replace(/\D/g, "")}`}>{data?.phone ?? "-"}</a>
                    </span>
                </div>
                <div className="col-2">
                    <span className="d-block fw-bolder">Email</span>
                    {!data?.email && "-"}
                    {data?.email && (
                        <span className="text-success">
                            <i className="fa fa-envelope-o pe-2" aria-hidden="true"></i>
                            <a href={`mailto:${data?.email?.replace(/\D/g, "")}`}>{data?.email}</a>
                        </span>
                    )}
                </div>
                <div className="col-3">
                    <span className="d-block fw-bolder">Address</span>
                    <span className="text-success">{data?.address?.address ?? "-"}</span>
                </div>
                <div className="col-1">
                    <span className="d-block fw-bolder">City</span>
                    <span className="text-success">{data?.address?.city ?? "-"}</span>
                </div>
                <div className="col-1">
                    <span className="d-block fw-bolder">State</span>
                    <span className="text-success">{data?.address?.state ?? "-"}</span>
                </div>
                <div className="col-1">
                    <span className="d-block fw-bolder">Zip</span>
                    <span className="text-success">{data?.address?.zipCode ?? "-"}</span>
                </div>
                <div className="col-2">
                    <span className="d-block fw-bolder">CP Office</span>
                    <span className="text-success">{data?.officeName ?? "-"}</span>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <hr />
                </div>
            </div>
        </section>
    );
};
