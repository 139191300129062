//=== Style =====
import "./marLog.css";

import { useEffect, useState } from "react";
import { isError } from "../../../../../../lib/isError";
import Moment from "react-moment";
import { StatusChanges } from "./components/statusChanges/statusChanges";
import { getMARPeriodText } from "../../lib/getMARPeriodText";

export const MARLog = ({ logData }) => {
    //= States ===================================================================

    const [workers, setWorkers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [cardList, setCardList] = useState([]);
    const [showMedicationsDialog, setShowMedicationsDialog] = useState(false);

    //= Use Effects ==============================================================

    useEffect(() => {
        //Setting workers...
        setWorkers([]);
    }, []); // eslint-disable-line

    useEffect(() => {}, [logData]); // eslint-disable-line

    //=== Methods ================================================================

    const localDateTime = (date) => {
        // Valor de data e hora em time zone 0 (UTC)
        const dateTimeInUtc = new Date(date);

        // Obtenha a hora local
        const offset = new Date().getTimezoneOffset(); // Offset em minutos
        const offsetMilliseconds = offset * 60 * 1000;
        const dateTimeLocal = new Date(dateTimeInUtc - offsetMilliseconds);

        return dateTimeLocal.toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });
    };

    const formatName = (inputName) => {
        const words = inputName.split(".");
        const formattedWords = words.map((word) => {
            if (word.length > 0) {
                const formattedWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                return formattedWord;
            }
            return "";
        });
        const formattedName = formattedWords.join(" ");
        return formattedName;
    };

    //= Render ===================================================================

    return (
        <>
            {logData && (
                <>
                    <div className="container-fluid">
                        <section id="ClientCards">
                            <div className="container mt-2 mx-0 px-2 py-2">
                                <div className="pt-2 pb-1">
                                    {logData?.length > 0 ? (
                                        <table className="MARLogTable table atable-success table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>User</th>
                                                    <th>Medication</th>
                                                    <th>Period</th>
                                                    <th>Changes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {logData.map((log) => {
                                                    if (log?.operation == "update") {
                                                        const statusChangesComponent = <StatusChanges jsonData={log} />;
                                                        if (statusChangesComponent)
                                                            return (
                                                                <tr key={log.auditLogId}>
                                                                    <td>
                                                                        <Moment format="MM/DD/YYYY hh:mm A">{localDateTime(log.dateTime)}</Moment>
                                                                    </td>
                                                                    <td>{formatName(log.userName.split("@")[0])}</td>
                                                                    <td>{log.medication}</td>
                                                                    <td>{getMARPeriodText(log.period)}</td>
                                                                    <td>{statusChangesComponent}</td>
                                                                </tr>
                                                            );
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p>No log data available.</p>
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            )}
        </>
    );
};
