import icon from "../../../../../../../../assets/user-nurse-solid-red.svg";

export const SummarySkilledNurseVisitNote = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Skilled Nurse Visit Note
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Initial Diagnosis for Homecare</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.reasonForNeedingHomecare ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Medication Administered this Visit</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.medicationAdministeredThisVisit ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 3,
                                }}
                            >
                                {data?.medicationAdministeredThisVisit?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Diagnoses</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.diagnoses ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 3,
                                }}
                            >
                                {data?.diagnoses?.map((item, index) => {
                                    if (item) return <li key={index}>{item}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
