import icon from "../../../../../../../../assets/person-rays-solid-red.svg";

export const SummaryDiabeticFocusExam = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Diabetic Focused Exam
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-4 fw-bold">Frequency of diabetic foot exam</div>
                        <div className="col-prt-2 fw-bold">Done By</div>
                        <div className="col-prt-3 fw-bold">Integument findings</div>
                        <div className="col-prt-3 fw-bold">Loss of sense of</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-4 text-primary">{data?.frequencyDiabeticFootExam ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{(data?.doneBy ?? "-") === "Other" ? data?.doneByOtherName : data?.doneBy ?? "-"}</div>
                        <div className="col-prt-3 text-primary">{data?.integumentFindings ?? "-"}</div>
                        <div className="col-prt-3 text-primary">
                            {(data?.lossOfSense?.length === 0 || !data?.lossOfSense) && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 2,
                                }}
                            >
                                {data?.lossOfSense?.map((item, index) => {
                                    if (item) return <li key={index}>{item}</li>;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Neuropathy</div>
                        <div className="col-prt-2 fw-bold">Tingling</div>
                        <div className="col-prt-2 fw-bold">Burning</div>
                        <div className="col-prt-2 fw-bold">Blood Sugar</div>
                        <div className="col-prt-4 fw-bold">Client test their Blood Glucose?</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">
                            {(data?.neuropathy ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 2,
                                    width: "70%",
                                }}
                            >
                                {data?.neuropathy?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-prt-2 text-primary">
                            {(data?.tingling ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 2,
                                    width: "70%",
                                }}
                            >
                                {data?.tingling?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-prt-2 text-primary">
                            {(data?.burning ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 2,
                                    width: "70%",
                                }}
                            >
                                {data?.burning?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-prt-2 text-primary">{data?.bloodSugar ? `${data?.bloodSugar} ${data?.bloodSugarReported ? `(${data?.bloodSugarReported})` : ""}` : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.clientTestBloodGlucose ? "Yes" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Glucometer type</div>
                        <div className="col-prt-5 fw-bold">Are acquiring supplies difficult for the client?</div>
                        <div className="col-prt-5 fw-bold">Independent with use of the machine?</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.glucometerType ?? "-"}</div>
                        <div className="col-prt-5 text-primary">{data?.acquiringSuppliesAreDifficult ? "Yes" : "-"}</div>
                        <div className="col-prt-5 text-primary">{data?.independentUseMachine ? "Yes" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-4 fw-bold">Average fasting range (last 30 days)</div>
                        <div className="col-prt-8 fw-bold">How many days did they test (last 30 days)</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-4 text-primary">{data?.averageFastingRange ?? "-"}</div>
                        <div className="col-prt-8 text-primary">{data?.numDaysTested ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Since the last assessment. Has the patient experienced the following</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.symptomsSinceLastAssessment ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 4,
                                }}
                            >
                                {data?.symptomsSinceLastAssessment?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
