import { Http_BadRequest, Http_OK } from "../lib/httpStatusCodes";
import { headers } from "./headers";

//const apiUrl = `${process.env.REACT_APP_CPAURI}Assessment`;
const apiUrl = `${process.env.REACT_APP_API_BASE_URL}Visit/GetForm485`;
//=======================================================================

export const get485Form = async (data, id = "") => {
    headers.ApiKey = process.env.REACT_APP_CPAURI_KEY;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${data?.assessmentID}`, {
            //fetch(`${apiUrl}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            rej("Bad Request Error: Failed to download");
                            break;

                        case Http_OK:
                            return res(response.blob());
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((blob) => {
                //1. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.setAttribute("download", `${data?.patientName}_Form485_${data?.visitDate}.pdf`);
                link.href = url;

                //2. Append to html page
                document.body.appendChild(link);

                //3. Force download
                link.click();

                //4. Clean up and remove link
                link.parentNode.removeChild(link);

                resolve();
            })
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================
