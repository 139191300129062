//=== Style =====
import "./visitListStyle.css";
import "react-multi-toggle/style.css";

import { useEffect, useState } from "react";
import { VisitListTableBody } from "./components/visitListTableBody";
import { VisitListTableFooter } from "./components/visitListTableFooter";
import { ErrorMessage } from "../../../../components/errorMessage/errorMessage.jsx";
import { SuccessMessage } from "../../../../components/successMessage/successMessage.jsx";
import { Loading } from "../../../../components/loading/loading";
import useWindowDimensions from "../../../../lib/hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { getAllVisits, getVisitSummaryForm } from "../../../../api/visitAPI";
import { createNurseAssessment } from "../../../../api/nurseAssessment/nurseAssessmentAPI";
import moment from "moment";
import { sortJson } from "../../../../lib/sortJson";
import { JSONToCSVConvertor } from "../../../../lib/jsonToCSVConvertor";
import { fileTimeStamp } from "../../../../lib/fileTimeStamp";
import { ToggleSwitch4 } from "../../../../components/toogleSwitch/toogleSwitch4";
import { PdfViewerModal } from "../../../../components/pdfViewerModal/pdfViewerModal.jsx";

export const VisitList = () => {
    //Using react-router-dom navigate
    let navigate = useNavigate();

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);

    //success message state
    const [successMsg] = useState(null);

    //Period filter
    const [periodFilter, setPeriodFilter] = useState("Today");

    //Patient data state
    const [data, setData] = useState([]);

    //Patient filtered data state
    const [displayData, setDisplayData] = useState([]);

    //Grid records per page state
    const [pageSize, setPageSize] = useState(15);

    //Grid page # state
    const [pageNumber, setPageNumber] = useState(1);

    //Grid number of pages state
    const [numPages, setNumPages] = useState(null);

    //Display AssignedTo field state
    const [displayAssignedToField, setDisplayAssignedToField] = useState(false);

    //order by direction state
    const [sortAsc, setSortAsc] = useState(true);

    //filter value state
    const [filter, setFilter] = useState(null);

    //show summary modal
    const [showSummary, setShowSummary] = useState(false);

    const { height } = useWindowDimensions();

    //Event handlers =====================================================

    //handle download csv
    const handleDownloadClick = () => {
        JSONToCSVConvertor(
            displayData?.map((item) => ({ ...item, status: item?.status ?? "Not Started" })),
            `ClientsVisits_${fileTimeStamp()}`,
            true
        );
    };

    //handle page number changes
    const handlePageNumberChange = (e) => {
        setPageNumber(e.target.value);
    };

    //handle page size changes
    const handlePageSizeChange = (e) => {
        e.target.value === "All" ? setPageSize(data.length) : setPageSize(e.target.value);
        //when pagesize changes, reset to page 1
        setPageNumber(1);
    };

    //handle reload button click
    const handleReloadClick = () => {
        loadAllPatients();
    };

    //handle row click
    const handleRowClick = (nurseAssessmentId, id, assessmentType) => {
        if (!nurseAssessmentId) {
            const newData = { ClientAssessmentId: id };

            createNurseAssessment(newData)
                .then((ret) => {
                    navigate(`/patientAssessments?id=${ret?.nurseAssessmentId}`);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        } else navigate(`/patientAssessments?id=${nurseAssessmentId}`);
    };

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //handle sort columns
    const handleSortClick = (prop, propType) => {
        setSortAsc((sortAsc) => !sortAsc);
        setDisplayData(sortJson(displayData, prop, propType, sortAsc));
    };

    //=== Methods ============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadAllPatients = () => {
        setLoadingError(null);
        setLoading(true);
        getAllVisits()
            .then((data) => {
                setDisplayAssignedToField(data?.displayAssignedToField);
                setData(data?.data);
                setDisplayData(data?.data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    const periodValueChangeHandler = (e) => {
        const newPeriodFilter = e.value;
        setPeriodFilter(newPeriodFilter);
    };

    //Use Effects ===========================================================

    //Loading Patients and defining list page size depending window height...
    useEffect(() => {
        //Defining page size depending window height
        if (height > 600 && height < 900) setPageSize(10);
        else if (height > 900) setPageSize(15);
        else setPageSize(5);

        loadAllPatients();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //update # pages when displayData || pageSize changes
    useEffect(() => {
        pageSize === "All" ? setNumPages(1) : setNumPages(Math.ceil(displayData.length / pageSize));
    }, [displayData, pageSize]);

    //update display data when data || filter changes
    useEffect(() => {
        const today = moment().startOf("day");
        const next7Days = moment().startOf("day").add(8, "days");
        const next30Days = moment().startOf("day").add(31, "days");

        setDisplayData(
            //!filter && periodFilter === "Today"
            //    ? data :
            data.filter(({ patient, assignedTo, contactPhone, address, assessmentType, appointmentDate, progress }) => {
                //filter text
                let matchText = true;

                if (filter)
                    if (displayAssignedToField) {
                        matchText = patient?.toLowerCase().includes(filter) || contactPhone?.toLowerCase().includes(filter) || address?.toLowerCase().includes(filter) || assessmentType?.toLowerCase().includes(filter) || appointmentDate?.toLowerCase().includes(filter) || progress?.toLowerCase().includes(filter) || assignedTo?.toLowerCase().includes(filter);
                    } else {
                        matchText = patient?.toLowerCase().includes(filter) || contactPhone?.toLowerCase().includes(filter) || address?.toLowerCase().includes(filter) || assessmentType?.toLowerCase().includes(filter) || appointmentDate?.toLowerCase().includes(filter) || progress?.toLowerCase().includes(filter);
                    }

                //filter period
                let matchPeriod = true;
                switch (periodFilter) {
                    case "Previous":
                        matchPeriod = moment(appointmentDate).startOf("day").isBefore(today);
                        break;

                    case "Today":
                        matchPeriod = moment(appointmentDate).startOf("day").isSame(today);
                        break;

                    case "Next 7 days":
                        matchPeriod = moment(appointmentDate).startOf("day").isAfter(today) && moment(appointmentDate).startOf("day").isBefore(next7Days);
                        break;

                    case "Next 30 days":
                        matchPeriod = moment(appointmentDate).startOf("day").isAfter(today) && moment(appointmentDate).startOf("day").isBefore(next30Days);
                        break;

                    default:
                        matchPeriod = true;
                }
                return matchText && matchPeriod;
            })
        );

        //when filter, reset to page 1
        setPageNumber(1);
    }, [data, periodFilter, filter]); // eslint-disable-line

    //========================================================================

    return (
        <div className="container-fluid mt-5 pt-4">
            <div className="row">
                {/*JSON.stringify(displayData)*/}
                <div className="col-12">
                    <div className="row pb-0 mb-0">
                        <div className="col-4 col-md-3 col-xl-2 pb-0 mb-0">
                            <h4 className="pt-3 text-success ps-3">Visits</h4>
                        </div>
                        {loading && (
                            <div className="col-8 col-md-9- col-xl-10  pt-4 text-end pe-4 pb-0 mb-0">
                                <Loading />
                            </div>
                        )}
                        {operationError && (
                            <div className="col-8 col-md-9 col-xl-10 text-end pe-4 pb-0 mb-0 pt-3">
                                <ErrorMessage msg={operationError} size="small" />
                            </div>
                        )}
                    </div>
                    <hr className="pt-0 mt-0"></hr>
                    <section id="VisitListSection">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">{successMsg && <SuccessMessage msg={successMsg} />}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-7 col-md-9 col-lg-9 text-success">
                                            <ToggleSwitch4 values={["Previous", "Today", "Next 7 days", "Next 30 days"]} selected={periodFilter} name="periodFilter" onChange={periodValueChangeHandler} />
                                        </div>
                                        <div className="col-sm-5 col-md-3 col-lg-3 align-end">
                                            <div className="input-group">
                                                <input type="text" className="form-control form-control-sm" placeholder="Search...." onChange={handleSearchInput} />
                                                &nbsp;
                                                <button className="btn btn-sm btn-success" title="Reload" onClick={handleReloadClick}>
                                                    <i className="fa fa-refresh"></i>
                                                </button>
                                                &nbsp;
                                                <button className="btn btn-sm btn-success" title="Download" onClick={handleDownloadClick}>
                                                    <i className="fa fa-download"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <table className={`table table-striped table-hover table-sm visitList ${displayAssignedToField ? "visitList8" : "visitList7"} mt-1`}>
                                        <thead>
                                            <tr>
                                                <th className="sort" onClick={() => handleSortClick("patient", "string")}>
                                                    <span className="d-none d-lg-block">Client</span>
                                                    <span className="d-block d-lg-none">Client</span>
                                                </th>

                                                <th className="sort d-none d-md-table-cell" onClick={() => handleSortClick("cpOffice", "string")}>
                                                    Office
                                                </th>

                                                {displayAssignedToField && (
                                                    <th className="sort d-none d-md-table-cell" onClick={() => handleSortClick("assignedTo", "string")}>
                                                        <span className="d-none d-lg-block">Assigned To</span>
                                                        <span className="d-block d-lg-none">To</span>
                                                    </th>
                                                )}

                                                <th className="sort d-none d-md-table-cell" onClick={() => handleSortClick("address", "string")}>
                                                    Address
                                                </th>

                                                <th className="sort" onClick={() => handleSortClick("contactPhone", "string")}>
                                                    <span className="d-none d-lg-block">Phone</span>
                                                    <span className="d-block d-lg-none">Phone</span>
                                                </th>

                                                <th className="sort  d-none d-md-table-cell" onClick={() => handleSortClick("assessmentType", "string")}>
                                                    <span className="d-none d-lg-block">Visit Type</span>
                                                    <span className="d-block d-lg-none">Type</span>
                                                </th>

                                                <th className="sort" onClick={() => handleSortClick("appointmentDate", "datetime")}>
                                                    <span className="d-none d-xl-block">Appointment Date</span>
                                                    <span className="d-block d-xl-none">Appt. Date</span>
                                                </th>
                                                <th className="sort d-none d-md-table-cell text-center" onClick={() => handleSortClick("status", "string")}>
                                                    Progress
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VisitListTableBody currentPage={Number(pageNumber)} numberPerPage={Number(pageSize)} items={displayData} displayAssignedToField={displayAssignedToField} filter={filter} loading={loading} loadingError={loadingError} handleItemClick={handleRowClick} />
                                        </tbody>
                                        <tfoot>
                                            <VisitListTableFooter handlePageNumberChange={handlePageNumberChange} handlePageSizeChange={handlePageSizeChange} numPages={numPages} displayAssignedToField={displayAssignedToField} numRecords={displayData.length} pageSize={pageSize} />
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <PdfViewerModal title="Visit Summary" show={false} setShow={setShowSummary} />
                    </section>
                </div>
            </div>
        </div>
    );
};
