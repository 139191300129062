//Icons
import iconHeartRed from "../../../assets/heart-pulse-red.svg";
import iconHeartGreen from "../../../assets/heart-pulse-green.svg";

import { updateCardiopulmonarySection } from "../../../api/nurseAssessment/cardiopulmonaryAPI";
import { isError } from "../../../lib/isError";

export const getCardiopulmonarySectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowCardiopulmonaryDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateCardiopulmonarySectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving Cardiopulmonary Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.cardiopulmonarySection, status: status };
        const saveObj = { id: patient?.cardiopulmonarySection?.id, status: status };
        updateCardiopulmonarySection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", cardiopulmonarySection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Cardiopulmonary",
        description: null,
        progress: data?.cardiopulmonarySection?.progress,
        status: data?.cardiopulmonarySection?.status,
        enableStatusField: true,
        iconClass: "fa-user-md",
        iconPending: iconHeartRed,
        iconCompleted: iconHeartGreen,
        saving: false,
        onClick: () => {
            setShowCardiopulmonaryDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateCardiopulmonarySectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateCardiopulmonarySectionStatus(notApplicable);
            }
        },
    };
};
