//=== Style =====
import "./statusChanges.css";

import React, { useEffect, useState } from "react";

export const StatusChanges = ({ jsonData }) => {
    //= States ===================================================================
    const [statusChanges, setStatusChanges] = useState([]);

    //= Use Effects ==============================================================

    useEffect(() => {
        const newValues = jsonData.newValues;
        const oldValues = jsonData.oldValues;

        let changes = [];

        for (const key in newValues) {
            if (key.startsWith("StatusDay")) {
                const day = key.replace("StatusDay", "");
                const from = oldValues[key];
                const to = newValues[key];

                if (from !== to) {
                    changes.push({ Day: parseInt(day), From: from, To: to });
                }
            }
        }

        // Ordenar as mudanças com base no dia em ordem crescente numérica
        changes.sort((a, b) => a.Day - b.Day);

        //removing items
        changes = changes.filter((i) => i.From != "-");

        setStatusChanges(changes);
    }, [jsonData]);

    //=== Methods ================================================================
    const getStatusValue = (value) => {
        switch (value) {
            case "I":
                return "Administered";
                break;

            case "PP":
                return "Pre-pour";
                break;

            case "H":
                return "Hold";
                break;

            case "NA":
                return "Not Available";
                break;

            case "NT":
                return "Not Taken";
                break;

            case "R":
                return "Refused";
                break;

            case "DC":
                return "Discontinued";
                break;

            case "-":
                return "Empty";
                break;

            default:
                return value;
        }
    };

    //= Render ===================================================================

    return (
        <div>
            <ul>
                {statusChanges.map((change) => {
                    return (
                        <li key={change.Day}>
                            Day {change.Day}: From <b>{getStatusValue(change.From)}</b> to <b>{getStatusValue(change.To)}</b>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
