export const medicationDialogValidation = (data) => {
    const ret = {
        isValid: true,
        errors: [],
    };

    if (!data?.name) {
        ret.errors.push({ field: "name", errorMessage: "Medication name is required." });
    }

    if (!data?.dose) {
        ret.errors.push({ field: "dose", errorMessage: "Dose is required." });
    }

    if (!data?.doseType) {
        ret.errors.push({ field: "doseType", errorMessage: "Dose Type is required." });
    }

    if (!data?.frequency) {
        ret.errors.push({ field: "frequency", errorMessage: "Frequency is required." });
    }

    if (!data?.route) {
        ret.errors.push({ field: "route", errorMessage: "Route is required." });
    }

    if (data?.discontinued && !data?.endDate) {
        ret.errors.push({ field: "endDate", errorMessage: "End date is required." });
    }

    ret.isValid = ret.errors.length === 0;

    return ret;
};
