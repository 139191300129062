//css
import "./assistiveDevicesDialog.css";

//Icons
import icon from "../../../../assets/wheelchair-solid-green.svg";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { updateAssistiveDevicesSection, getAssistiveDevicesPreviousVisitById } from "../../../../api/nurseAssessment/assistiveDevicesAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const AssistiveDevicesDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Assistive Devices data state
    const [assistiveDevicesData, setAssistiveDevicesData] = useState(null);

    //list of devices
    const listOfDevices = [
        { label: "Bedside Commode", value: "Bedside Commode" },
        { label: "Cane", value: "Cane" },
        { label: "Crutches", value: "Crutches" },
        { label: "Diabetic Supplies", value: "Diabetic Supplies" },
        { label: "Glasses", value: "Glasses" },
        { label: "Grab Bars", value: "Grab Bars" },
        { label: "Hearing Aids", value: "Hearing Aids" },
        { label: "Hospital Bed", value: "Hospital Bed" },
        { label: "Hoyer Lift", value: "Hoyer Lift" },
        { label: "Incontinent Supplies", value: "Incontinent Supplies" },
        { label: "Life Alert", value: "Life Alert" },
        { label: "Oxygen", value: "Oxygen" },
        { label: "Prothesis", value: "Prothesis" },
        { label: "Raised Toilet Seat", value: "Raised Toilet Seat" },
        { label: "Shower Bench", value: "Shower Bench" },
        { label: "TED Support Stockings", value: "TED Support Stockings" },
        { label: "Urinal", value: "Urinal" },
        { label: "Walker", value: "Walker" },
        { label: "Wound Care Supplies", value: "Wound Care Supplies" },
        { label: "Wheelchair", value: "Wheelchair" },
        { label: "Other", value: "Other" },
    ];

    //list of required assistance
    const listOfRequiredAssistance = [
        { label: "Ambulation", value: "Ambulation" },
        { label: "Personal Care", value: "Personal Care" },
        { label: "Positioning", value: "Positioning" },
        { label: "Transfers Bed/Chair", value: "Transfers Bed/Chair" },
    ];

    //saving state
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = {
            ...data?.assistiveDevicesSection,
            status: data?.assistiveDevicesSection?.status ?? "Applicable",
        };
        setAssistiveDevicesData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = {
            id: assistiveDevicesData?.id,
            status: assistiveDevicesData?.status,
        };
        setAssistiveDevicesData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...assistiveDevicesData };

        //===========================================================
        //Saving Assistive Devices Section
        updateAssistiveDevicesSection(saveObj)
            .then((ret) => {
                const newData = {
                    ...data,
                    status: "In Progress",
                    assistiveDevicesSection: ret,
                };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleDeviceChange = (e) => {
        let devicesNew = e?.map((i) => i.value);
        const newData = { ...assistiveDevicesData, devices: devicesNew };
        setAssistiveDevicesData(newData);
    };

    const handleRequiredAssistanceChange = (e) => {
        let requiresAssistanceNew = e?.map((i) => i.value);
        const newData = {
            ...assistiveDevicesData,
            requiresAssistanceFor: requiresAssistanceNew,
        };
        setAssistiveDevicesData(newData);
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...assistiveDevicesData, [name]: value };
        setAssistiveDevicesData(newData);
    };

    const handleLoadDataPreviousVisitClick = () => {
        setLoading(true);
        getAssistiveDevicesPreviousVisitById(data.id)
            .then((ret) => {
                const currentData = data?.assistiveDevicesSection;
                const newData = {
                    ...data,
                    assistiveDevicesSection: {
                        ...ret,
                        id: currentData?.id,
                        progress: currentData?.progress,
                    },
                };
                setData(newData);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data.");
                }
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => setTimeout(() => setLoading(false), 1000));
    };

    //=== Render ==============================================================

    return (
        <Modal dialogClassName="assistiveDeviceSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt="" className="icon mt-0 pt-0"></img>
                        </span>
                        <span className="ps-2">Assistive Devices - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{assistiveDevicesData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={assistiveDevicesData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {!readOnly && (
                        <div className="row">
                            <div className="col-12 text-end">
                                <button className="btn btn-sm btn-outline-secondary" onClick={handleLoadDataPreviousVisitClick}>
                                    {!loading && "Load Data from Previous Visit"}
                                    {loading && (
                                        <span className="ps-2">
                                            <span className="pe-2">Loading...</span>
                                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Devices</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {assistiveDevicesData?.status === "Applicable"
                                        ? assistiveDevicesData?.devices?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="devices"
                                        id="devices"
                                        menuPlacement="bottom"
                                        options={listOfDevices}
                                        //value={listOfDevices.value}
                                        //defaultValue={assistiveDevicesData?.devices?.map((dev) => listOfDevices?.find((i) => i.value === dev))}
                                        value={(assistiveDevicesData?.devices ?? []).map((dev) => listOfDevices?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                        onChange={handleDeviceChange}
                                        isDisabled={!["Applicable"].includes(assistiveDevicesData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">If Other Device (Describe)</span>
                            {readOnly && <div className="text-success pb-2">{assistiveDevicesData?.status === "Applicable" ? assistiveDevicesData?.deviceOtherDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="deviceOtherDescription" type="text" className="form-control" placeholder={`Describe`} value={assistiveDevicesData?.deviceOtherDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(assistiveDevicesData?.status) || !assistiveDevicesData?.devices?.includes("Other")} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Requires Assistance for</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {assistiveDevicesData?.status === "Applicable"
                                        ? assistiveDevicesData?.requiresAssistanceFor?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="requiresAssistanceFor"
                                        id="requiresAssistanceFor"
                                        menuPlacement="bottom"
                                        options={listOfRequiredAssistance}
                                        //value={listOfRequiredAssistance.value}
                                        //defaultValue={assistiveDevicesData?.requiresAssistanceFor?.map((dev) => listOfRequiredAssistance?.find((i) => i.value === dev))}
                                        value={(assistiveDevicesData?.requiresAssistanceFor ?? []).map((dev) => listOfRequiredAssistance?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                        onChange={handleRequiredAssistanceChange}
                                        isDisabled={!["Applicable"].includes(assistiveDevicesData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary
                        data={{
                            patient: data?.patient,
                            contactPhone: data?.contactPhone,
                            address: data?.address,
                            assessmentType: data?.assessmentType,
                            status: data?.status,
                            appointmentDate: data?.appointmentDate,
                            client: data?.client,
                            assistiveDevicesSection: data?.assistiveDevicesSection,
                        }}
                    />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
