import icon from "../../../../../../../../assets/cloud-showers-heavy-solid-red.svg";
import { ScoreDepressionScreen } from "../../../../../../../../components/scoreDepressionScreen/scoreDepressionScreen";
import { depressionScreenAnswerDescription } from "../../../../../../../../lib/depressionScreenAnswerDescription";

export const SummaryDepressionScreen = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Depression Screen
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-6 fw-bold">Unable to complete due to cognitive deficits</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-6 text-primary">{data?.unableToComplete ? "Yes" : "-"}</div>
                    </div>

                    {!data?.unableToComplete && (
                        <>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-2">
                                    <ScoreDepressionScreen score={data?.score} />
                                </div>
                            </div>

                            <div className="row-prt ms-2 ">
                                <div className="col-prt-6 fw-bold">1. Little interest or pleasure in doing things</div>
                                <div className="col-prt-6 fw-bold">2. Feeling down, depressed or hopeless</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-6 text-primary">{depressionScreenAnswerDescription(data?.littleInterestPleasureDoingThings) ?? "-"}</div>
                                <div className="col-prt-6 text-primary">{depressionScreenAnswerDescription(data?.feelingDownDepressedHopeless) ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-6 fw-bold">3. Trouble falling or staying asleep, or sleeping too much</div>
                                <div className="col-prt-6 fw-bold">4. Feeling tired or having little energy</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-6 text-primary">{depressionScreenAnswerDescription(data?.troubleFallingOrStayingAsleepOrSleepingTooMuch) ?? "-"}</div>
                                <div className="col-prt-6 text-primary">{depressionScreenAnswerDescription(data?.feelingTiredOrHavingLittleEnergy) ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-6 fw-bold">5. Poor appetite or overeating</div>
                                <div className="col-prt-6 fw-bold">6. Feeling bad about yourself or that you are a failure or have let yourself or your family down</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-6 text-primary">{depressionScreenAnswerDescription(data?.poorAppetiteOrOvereating) ?? "-"}</div>
                                <div className="col-prt-6 text-primary">{depressionScreenAnswerDescription(data?.feelingBadAboutYourselfOrYouAreAFailureOrHaveLetYourselfOrYourFamilyDown) ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-6 fw-bold">7. Trouble concentrating on things, such as reading the newspaper or watching TV</div>
                                <div className="col-prt-6 fw-bold">8. Moving or speaking so slowly that other could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-6 text-primary">{depressionScreenAnswerDescription(data?.troubleConcentrating) ?? "-"}</div>
                                <div className="col-prt-6 text-primary">{depressionScreenAnswerDescription(data?.movingOrSpeakingSoSlowlyOtherCouldHaveNoticedOrTheOpposite) ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">9. Thoughts that you would be better off dead, or of hurting yourself</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{depressionScreenAnswerDescription(data?.thoughtsYouWouldBeBetterDeadOrHurtingYourself) ?? "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">
                                    <hr />
                                    If you selected any problems, how difficult have these problems made it for to do your work, take care of things at home, or get along with others?
                                </div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.ifSelectedAnyProblemsHowDifficultToDoYourWork ?? "-"}</div>
                            </div>
                        </>
                    )}
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
