import { acquireAccessToken } from "../../lib/acquireAccessToken";
import { Http_BadRequest, Http_OK } from "../../lib/httpStatusCodes";
import { sortJson } from "../../lib/sortJson";
import { headers } from "../headers";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}FDAMedication`;

//=======================================================================

export const getFDAMedications = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                //sorting data by name
                                let data = sortJson(json.data, "name", "string", true);

                                return res(data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getFDAMedicationsById = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(apiUrl + `/${id}`, { method: "GET", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                //sorting data by name
                                let data = json.data;

                                return res(data);
                            });
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================
