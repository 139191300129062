//Icons
import iconHomeSafetyGreen from "../../../assets/house-circle-check-solid-green.svg";
import iconHomeSafetyRed from "../../../assets/house-circle-check-solid-red.svg";

import { updateHomeSafetySection } from "../../../api/nurseAssessment/homeSafetyAPI";
import { isError } from "../../../lib/isError";

export const getHomeSafetySectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowHomeSafetyDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateHomeSafetySectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving HomeSafety Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.homeSafetySection, status: status };
        const saveObj = { id: patient?.homeSafetySection?.id, status: status };
        updateHomeSafetySection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", homeSafetySection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Home Safety",
        description: null,
        progress: data?.homeSafetySection?.progress,
        status: data?.homeSafetySection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconHomeSafetyRed,
        iconCompleted: iconHomeSafetyGreen,
        saving: false,
        onClick: () => {
            setShowHomeSafetyDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateHomeSafetySectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateHomeSafetySectionStatus(notApplicable);
            }
        },
    };
};
