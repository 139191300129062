//css
import "./medicationAdministrationDialog.css";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isError } from "../../../../../../../../lib/isError";
import { ModalDialogFooter } from "../../../../../../../../components/modalDialogFooter/modalDialogFooter";
import { getMedicationByClientId } from "../../../../../../../../api/nurseAssessment/medicationsAPI";
import { addMedicationClientMAR, updateMedicationClientMAR } from "../../../../../../../../api/clientMARAPI";

export const MedicationAdministrationDialog = ({ closeDialog, selectedMedicationAdministrationPeriod, setClientMARData }) => {
    //Medication data state
    const [medicationAdministrationData, setMedicationAdministrationData] = useState(null);

    //list of current Medications
    const [listOfCurrentMedications, setListOfCurrentMedications] = useState([]);

    //saving state
    const [saving, setSaving] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    const dayOptions = Array.from({ length: 31 }, (_, i) => i + 1);

    //=== Use Effects ========================================================

    useEffect(() => {}, []);

    //=== Methods ============================================================

    const loadMedicationsByClientId = (id) => {
        setLoadingError(null);
        setLoading(true);
        getMedicationByClientId(id)
            .then((data) => {
                setListOfCurrentMedications(data);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
            })
            .finally((_) => setLoading(false));
    };

    const getPeriodText = (periodValue) => {
        switch (periodValue) {
            case 0:
                return "Morning";
            case 1:
                return "Afternoon";
            case 2:
                return "Evening";
            case 3:
                return "Night";
            default:
                return "Unknown";
        }
    };

    //=== Handlers ===========================================================

    const handleReset = () => {
        //const newData = { id: nurseReviewData?.id, status: nurseReviewData?.status };
        //setNurseReviewData(newData);
    };

    const handleSave = () => {
        if (selectedMedicationAdministrationPeriod.id) {
            setSaving(true);

            const saveObj = { MedicationRecordMARId: selectedMedicationAdministrationPeriod?.id, ...medicationAdministrationData }; // {/*medicationId: medicationId, clientMARId: clientMARId */};

            //===========================================================
            //Adding Medication MAR
            updateMedicationClientMAR(saveObj)
                .then((MARData) => {
                    setClientMARData(MARData);

                    closeDialog();
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error loading data");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                })
                .finally((_) => setSaving(false));

            //===========================================================
        }
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...medicationAdministrationData, [name]: value };
        setMedicationAdministrationData(newData);
    };

    //= Render ===================================================================

    return (
        <Modal dialogClassName="nurseReviewSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="ps-2">Medication Administration</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {/*JSON.stringify(selectedMedicationAdministrationPeriod)*/}
                    <div className="row">
                        <div className="col-10">
                            <span className="my-1 d-block fw-bolder">Medication</span>
                            <div className="div-com-quebra text-primary">{selectedMedicationAdministrationPeriod.medicationName}</div>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block fw-bolder">Period</span>
                            <div className="text-primary">{getPeriodText(selectedMedicationAdministrationPeriod.period)}</div>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Start Day</span>
                            <select name="startDay" className="form-select" onChange={handleFieldChange} value={medicationAdministrationData?.startDay ?? ""}>
                                <option value="">Select</option>
                                {dayOptions.map((day) => (
                                    <option key={day} value={day}>
                                        {day}
                                    </option>
                                ))}
                            </select>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">End Day</span>
                            <select name="endDay" className="form-select" onChange={handleFieldChange} value={medicationAdministrationData?.endDay ?? ""}>
                                <option value="">Select</option>
                                {dayOptions.slice(medicationAdministrationData?.startDay - 1).map((day) => (
                                    <option key={day} value={day}>
                                        {day}
                                    </option>
                                ))}
                            </select>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Status</span>
                            <select name="value" className="form-select" onChange={handleFieldChange} value={medicationAdministrationData?.value ?? ""}>
                                <option value="">Select</option>
                                <option value="I">Administered</option>
                                <option value="-">Clear</option>
                                <option value="H">Hold</option>
                                <option value="NA">Not Available</option>
                                <option value="NT">Not Taken</option>
                                <option value="PP">Pre-pour</option>
                                <option value="R">Refused</option>

                                {/*<option value="DC">Discontinued</option>*/}
                            </select>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={false} readOnly={false}></ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
