import { useEffect } from "react";
import { useState } from "react";
import { Badge } from "react-bootstrap";

export const ScoreDepressionScreen = ({ score }) => {
    const [bg, setBg] = useState("primary");
    const [label, setLabel] = useState("");

    useEffect(() => {
        if (score <= 4) {
            setBg("primary");
            setLabel("No Depression");
        }

        if (score >= 5 && score <= 9) {
            setBg("warning");
            setLabel("Mild Depression");
        }

        if (score >= 10 && score <= 14) {
            setBg("warning");
            setLabel("Moderate Depression");
        }

        if (score >= 15 && score <= 19) {
            setBg("warning");
            setLabel("Moderately severe Depression");
        }

        if (score >= 20) {
            setBg("danger");
            setLabel("Severe Depression");
        }

        if (score === 0) {
            setBg("");
            setLabel("");
        }
    }, [score]);

    return <Badge bg={bg} className="p-2">{`${label} (${score})`}</Badge>;
};
