//Icons
import iconClipboardListRed from "../../../assets/clipboard-list-solid-red.svg";
import iconClipboardListGreen from "../../../assets/clipboard-list-solid-green.svg";

import { updateInterventionsInstructionsSection } from "../../../api/nurseAssessment/interventionsInstructionsAPI";
import { isError } from "../../../lib/isError";

export const getInterventionsInstructionsSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowInterventionsInstructionsDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateInterventionsInstructionsSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving InterventionsInstructions Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.interventionsInstructionsSection, status: status };
        const saveObj = { id: patient?.interventionsInstructionsSection?.id, status: status };
        updateInterventionsInstructionsSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", interventionsInstructionsSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Interventions",
        description: null,
        progress: data?.interventionsInstructionsSection?.progress,
        status: data?.interventionsInstructionsSection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconClipboardListRed,
        iconCompleted: iconClipboardListGreen,
        saving: false,
        onClick: () => {
            setShowInterventionsInstructionsDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateInterventionsInstructionsSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateInterventionsInstructionsSectionStatus(notApplicable);
            }
        },
    };
};
