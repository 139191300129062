//css
import "./clientSection.css";

export const ClientSection = ({ data }) => {
    return (
        <section className="clientSummaryClientSection">
            <small>
                <div className="row-prt2 ms-2">
                    <div className="col-prt2-3 fw-bold">Name</div>
                    <div className="col-prt2-3 fw-bold">Phone</div>
                    <div className="col-prt2-6 fw-bold">Address</div>
                </div>
                <div className="row-prt2 ms-2 mb-2">
                    <div className="col-prt2-3 text-primary">{data?.name ?? "-"}</div>
                    <div className="col-prt2-3 text-primary">{data?.phone ?? "-"}</div>
                    <div className="col-prt2-6 text-primary">{data?.address ?? "-"}</div>
                </div>

                <div className="row-prt2 ms-2">
                    <div className="col-prt2-12 fw-bold">Allergies</div>
                </div>

                <div className="row-prt2 ms-2 mb-0 pb-0">
                    <div className="col-prt2-12 text-primary">{data?.allergies ?? "-"}</div>
                </div>

                <div className="row-prt2 my-0 py-0">
                    <div className="col-prt2-12">
                        <hr />
                    </div>
                </div>
            </small>
        </section>
    );
};
