import { Link } from "react-router-dom";

export const PatientNotFound = () => {
    return (
        <center>
            <div className="text-danger pt-5 mt-5">
                <i className="fa fa-times-circle fa-4x" aria-hidden="true"></i>
            </div>
            <div className="pt-2 fw-bold text-danger h5">Visit Not Found or you don't have permission to access this data!</div>
            <div className="fw-bold h6">If there is any mistake, please contact your supervisor.</div>
            <Link className="btn btn-sm btn-secondary mt-3" to="/">
                Return to Client Visits List
            </Link>
        </center>
    );
};
