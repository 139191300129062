import Moment from "react-moment";

export const ReportListTableBody = ({
  items,
  loadingError,
  loading,
  currentPage,
  numberPerPage,
}) => {
  const begin = (Number(currentPage) - 1) * Number(numberPerPage);
  const end = begin + Number(numberPerPage);
  const data = items.slice(begin, end) ?? [];

  if (loadingError)
    return (
      <tr>
        <td colSpan={"9"} className="table-danger text-center">
          {loadingError}
        </td>
      </tr>
    );

  return data.length === 0
    ? !loading && (
        <tr>
          <td colSpan={"9"} className="table-danger text-center">
            No Client wounds to display
          </td>
        </tr>
      )
    : data.map(
        ({
          officeName,
          clientName,
          typeOfWound,
          length,
          width,
          depth,
          performedOn,
          appointmentDate,
          woundHealingProgress,
          id,
        }) => {
          return (
            <tr
              key={id}
              //onClick={() => handleItemClick(nurseAssessmentId, id)}
              style={{ cursor: "pointer" }}
            >
              <td className="small d-none d-md-table-cell">{officeName}</td>
              <td className="small d-none d-md-table-cell">{clientName}</td>
              <td className="small d-none d-md-table-cell">{typeOfWound}</td>
              <td className="small d-none d-md-table-cell">{length}</td>
              <td className="small d-none d-md-table-cell">{width}</td>
              <td className="small d-none d-md-table-cell">{depth}</td>
              {performedOn == null ? (
                <td className="small d-none d-md-table-cell">-</td>
              ) : (
                <td className="small d-none d-md-table-cell">
                  <Moment format="MM/DD/YYYY hh:mm A">{performedOn}</Moment>
                </td>
              )}
              {appointmentDate == null ? (
                <td className="small d-none d-md-table-cell">-</td>
              ) : (
                <td className="small d-none d-md-table-cell">
                  <Moment format="MM/DD/YYYY hh:mm A">{appointmentDate}</Moment>
                </td>
              )}
              <td className="small d-none d-md-table-cell">
                {woundHealingProgress}
              </td>
            </tr>
          );
        }
      );
};
