import icon from "../../../../../../../../assets/heart-pulse-red.svg";

export const SummaryCardiopulmonaryAssessment = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Cardiopulmonary
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Cardio</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-2 fw-bold">Pacemaker</div>
                                <div className="col-prt-2 fw-bold">Last Checked</div>
                                <div className="col-prt-2 fw-bold">Pacemaker Type</div>
                                <div className="col-prt-2 fw-bold">Chest Pain</div>
                                <div className="col-prt-2 fw-bold">Palpitation</div>
                                <div className="col-prt-2 fw-bold">Headache/Dizziness</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-2 text-primary">{data?.pacemaker ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.pacemakerLastChecked?.split("T")[0] ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.pacemakerType ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.chestPain ? "Yes" : "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.palpitation ? "Yes" : "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.headacheDizziness ? "Yes" : "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold fst-italic">
                                    <li>Pitting Edema</li>
                                </div>
                            </div>
                            <div className="row-prt ms-5 mb-12">
                                <div className="col-prt-2 text-primary">{data?.pittingEdema ? "Yes" : "-"}</div>
                            </div>

                            <div className="row-prt ms-2 mt-2">
                                <div className="col-prt-12">
                                    <div className="row-prt ms-2">
                                        <div className="col-prt-6 fw-bold">LUE</div>
                                        <div className="col-prt-6 fw-bold">LLE</div>
                                    </div>
                                    <div className="row-prt ms-2 mb-2">
                                        <div className="col-prt-6 text-primary">{data?.pittingEdemaLUE ? `Yes ${data?.pittingEdemaLUEDescription ? `(${data?.pittingEdemaLUEDescription})` : data?.pittingEdemaLUEDescription}` : "-"}</div>
                                        <div className="col-prt-6 text-primary">{data?.pittingEdemaLLE ? `Yes ${data?.pittingEdemaLLEDescription ? `(${data?.pittingEdemaLLEDescription})` : data?.pittingEdemaLLEDescription}` : "-"}</div>
                                    </div>

                                    <div className="row-prt ms-2">
                                        <div className="col-prt-6 fw-bold">RUE</div>
                                        <div className="col-prt-6 fw-bold">RLE</div>
                                    </div>
                                    <div className="row-prt ms-2 mb-2">
                                        <div className="col-prt-6 text-primary">{data?.pittingEdemaRUE ? `Yes ${data?.pittingEdemaRUEDescription ? `(${data?.pittingEdemaRUEDescription})` : data?.pittingEdemaRUEDescription}` : "-"}</div>
                                        <div className="col-prt-6 text-primary">{data?.pittingEdemaRLE ? `Yes ${data?.pittingEdemaRLEDescription ? `(${data?.pittingEdemaRLEDescription})` : data?.pittingEdemaRLEDescription}` : "-"}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold fst-italic">
                                    <li>Non-Pitting Edema</li>
                                </div>
                            </div>
                            <div className="row-prt ms-5 mb-12">
                                <div className="col-prt-2 text-primary">{data?.nonPittingEdema ? "Yes" : "-"}</div>
                            </div>

                            <div className="row-prt ms-2 mt-2">
                                <div className="col-prt-12">
                                    <div className="row-prt ms-2">
                                        <div className="col-prt-6 fw-bold">LUE</div>
                                        <div className="col-prt-6 fw-bold">LLE</div>
                                    </div>
                                    <div className="row-prt ms-2 mb-2">
                                        <div className="col-prt-6 text-primary">{data?.nonPittingEdemaLUE ? `Yes ${data?.nonPittingEdemaLUEDescription ? `(${data?.nonPittingEdemaLUEDescription})` : data?.nonPittingEdemaLUEDescription}` : "-"}</div>
                                        <div className="col-prt-6 text-primary">{data?.nonPittingEdemaLLE ? `Yes ${data?.nonPittingEdemaLLEDescription ? `(${data?.nonPittingEdemaLLEDescription})` : data?.nonPittingEdemaLLEDescription}` : "-"}</div>
                                    </div>

                                    <div className="row-prt ms-2">
                                        <div className="col-prt-6 fw-bold">RUE</div>
                                        <div className="col-prt-6 fw-bold">RLE</div>
                                    </div>
                                    <div className="row-prt ms-2 mb-2">
                                        <div className="col-prt-6 text-primary">{data?.nonPittingEdemaRUE ? `Yes ${data?.nonPittingEdemaRUEDescription ? `(${data?.nonPittingEdemaRUEDescription})` : data?.nonPittingEdemaRUEDescription}` : "-"}</div>
                                        <div className="col-prt-6 text-primary">{data?.nonPittingEdemaRLE ? `Yes ${data?.nonPittingEdemaRLEDescription ? `(${data?.nonPittingEdemaRLEDescription})` : data?.nonPittingEdemaRLEDescription}` : "-"}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Other</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">{data?.other ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Pulmonary</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-2 fw-bold">Cough</div>
                                <div className="col-prt-2 fw-bold">Color</div>
                                <div className="col-prt-2 fw-bold">Character</div>
                                <div className="col-prt-2 fw-bold">Dyspnea</div>
                                <div className="col-prt-2 fw-bold">Orthopnea</div>
                                <div className="col-prt-2 fw-bold">Cyanosis</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-2 text-primary">{data?.cough ? `Yes ${data?.coughType ? `(${data?.coughType})` : data?.coughType}` : "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.coughProductiveColor?.split("T")[0] ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.coughProductiveCharacter ?? "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.dyspnea ? "Yes" : "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.orthopnea ? "Yes" : "-"}</div>
                                <div className="col-prt-2 text-primary">{data?.cyanosis ? "Yes" : "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">O² (liters/minute)</div>
                                <div className="col-prt-4 fw-bold">O² via (liters/minute)</div>
                                <div className="col-prt-4 fw-bold">O² Prescription</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.o2Flow ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.o2Via ?? "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.o2Prescription ?? "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Comments</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.comments ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
