export const getWoundCareReportData = () => {
    return {
        title: "Wound Care",
        description: "Client-centric wound care decisions start with this report.",
        icon: "bandaid-red.svg",
        iconHover: "bandaid-green.svg",
        cardLink: "/reports/woundCare",
        section: "ATK",
    };
};
