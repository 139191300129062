import { acquireAccessToken } from "../../lib/acquireAccessToken";
import { Http_BadRequest, Http_OK } from "../../lib/httpStatusCodes";
import { headers } from "../headers";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}reports/visitCompletionCompliance`;

export const getCompletionComplianceReport = async () => {
  const token = await acquireAccessToken();
  headers.Authorization = token ? `bearer ${token}` : null;

  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        return new Promise((res, rej) => {
          switch (response.status) {
            case Http_BadRequest:
              response.json().then((json) => {
                return rej(json.errors);
              });
              break;

            case Http_OK:
              response.json().then((json) => {
                return res(json.data);
              });
              break;
            default:
              rej(`Error to fetch data (${response.status})`);
          }
        });
      })
      .then((ret) => resolve(ret))
      .catch((err) => reject(err));
  });
};
