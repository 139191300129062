//Icons
import iconPhysicalGreen from "../../../assets/clipboard-user-solid-green.svg";
import iconPhysicalRed from "../../../assets/clipboard-user-solid-red.svg";

import { updatePhysicalSection } from "../../../api/nurseAssessment/physicalAPI";
import { isError } from "../../../lib/isError";

export const getPhysicalSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowPhysicalDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updatePhysicalSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving Physical Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.physicalSection, status: status };
        const saveObj = { id: patient?.physicalSection?.id, status: status };
        updatePhysicalSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", physicalSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Physical ",
        description: null,
        progress: data?.physicalSection?.progress,
        status: data?.physicalSection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconPhysicalRed,
        iconCompleted: iconPhysicalGreen,
        saving: false,
        onClick: () => {
            setShowPhysicalDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updatePhysicalSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updatePhysicalSectionStatus(notApplicable);
            }
        },
    };
};
