//Icons
import iconCommentsGreen from "../../../assets/note-sticky-solid-green.svg";
import iconCommentsRed from "../../../assets/note-sticky-solid-red.svg";

import { updateCommentsSection } from "../../../api/nurseAssessment/commentsAPI";
import { isError } from "../../../lib/isError";

export const getCommentsSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowCommentsDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateCommentsSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving Comments Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.commentsSection, status: status };
        const saveObj = { id: patient?.commentsSection?.id, status: status };
        updateCommentsSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", commentsSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Comments",
        description: null,
        progress: data?.commentsSection?.progress,
        status: data?.commentsSection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconCommentsRed,
        iconCompleted: iconCommentsGreen,
        saving: false,
        onClick: () => {
            setShowCommentsDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateCommentsSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateCommentsSectionStatus(notApplicable);
            }
        },
    };
};
