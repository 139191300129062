import "./assessmentCard.css";

export const AssessmentCard = ({ index, title, description, progress, cardClickHandler, statusClickHandler, iconCompleted, iconPending, iconClass, enableStatusField, saving, checked, readOnly }) => {
    const handleNotApplicableClick = (e) => {
        e.stopPropagation(); // USED HERE!
        //if (statusClickHandler) statusClickHandler(e.target.checked);
    };

    const handleNotApplicableChange = (e) => {
        e.stopPropagation(); // USED HERE!
        if (statusClickHandler) statusClickHandler(e.target.checked);
    };

    return (
        <div className="card assessmentCard" onClick={cardClickHandler}>
            <div className="card-body">
                <h5 className="card-title assessmentCardTitle">
                    <div className={progress === "Completed" ? "text-success" : "text-danger"} style={{ display: "inline-flex", alignItems: "center" }}>
                        <span>
                            {<img src={progress === "Completed" ? iconCompleted : iconPending} width="36px" height="36px" alt=""></img>}
                            {/*!icon && <i className={`fa ${iconClass} h1`} aria-hidden="true"></i>*/}
                        </span>
                        <span className="ps-2 h5">{title}</span>
                    </div>
                </h5>
                <p className="card-text small">{description}</p>
                <div className="row">
                    <div className="col-6 me-0 pe-0">
                        {progress === "Completed" && !checked && (
                            <div className="text-success small">
                                <i className="fa fa-check" aria-hidden="true"></i> Completed
                            </div>
                        )}
                        {progress === "Pending" && !checked && (
                            <div className="text-muted small">
                                <i className="fa fa-hourglass-o" aria-hidden="true"></i> Pending
                            </div>
                        )}
                    </div>
                    {enableStatusField && !saving && (
                        <div className="col-6 mx-0 px-0">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id={`notApplicable${index}`} name="notApplicable" onClick={handleNotApplicableClick} onChange={handleNotApplicableChange} checked={checked} disabled={readOnly} />
                                <label
                                    className={progress === "Completed" ? "form-check-label small text-success" : "form-check-label small text-danger"}
                                    htmlFor={`notApplicable${index}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    Not Applicable
                                </label>
                            </div>
                        </div>
                    )}

                    {enableStatusField && saving && (
                        <div className="col-6 mx-0 pe-2 text-end">
                            <span className={progress === "Completed" ? "text-success" : "text-danger"}>
                                <small>
                                    <span className="pe-2">Saving...</span>
                                    <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                                </small>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
