//=== Style =====
import "./MARLegend.css";

import { useEffect, useState } from "react";
import { getWorkerByInitials } from "../../../../../../api/workerAPI";
import { isError } from "../../../../../../lib/isError";

export const MARLegend = ({ initials }) => {
    //= States ===================================================================

    const [workers, setWorkers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [cardList, setCardList] = useState([]);
    const [showMedicationsDialog, setShowMedicationsDialog] = useState(false);

    //= Use Effects ==============================================================

    useEffect(() => {
        //Setting workers...
        setWorkers([]);
    }, []); // eslint-disable-line

    useEffect(() => {
        //Setting Cards
        const vetWorkers = [];

        if (Array.isArray(initials)) {
            const workerPromises = initials.map((initial) => getWorker(initial));
            Promise.all(workerPromises)
                .then((workers) => {
                    // workers é um array com os resultados resolvidos das promessas
                    setWorkers(workers);
                })
                .catch((error) => {
                    // Lida com erros
                    console.error("Error loading workers:", error);
                });
        }
    }, [initials]); // eslint-disable-line

    //=== Methods ================================================================
    const getWorker = async (data) => {
        setLoadingError(null);
        setLoading(true);

        return getWorkerByInitials(data)
            .then((worker) => {
                return worker;
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
            })
            .finally((_) => setLoading(false));
    };

    //= Render ===================================================================

    return (
        <>
            {(initials ?? []).length > 0 && (
                <>
                    <div className="container-fluid">
                        <section id="ClientCards">
                            <div className="mt-2 mx-0 px-2 py-2">
                                <div className="pt-2 pb-1">
                                    <ul>
                                        {workers.map((w) => (
                                            <li className="small" key={w.initials}>
                                                <b>
                                                    <span className="pe-2" style={{ display: "inline-block", minWidth: "50px" }}>
                                                        {w.initials}:
                                                    </span>
                                                </b>
                                                {w.fullName}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            )}
        </>
    );
};
