//Icons
import iconBrainRed from "../../../assets/brain-solid-red.svg";
import iconBrainGreen from "../../../assets/brain-solid-green.svg";

import { updateNeuromuscularSection } from "../../../api/nurseAssessment/neuromuscularAPI";
import { isError } from "../../../lib/isError";

export const getNeuromuscularSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowNeuromuscularDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateNeuromuscularSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving Neuromuscular Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.neuromuscularSection, status: status };
        const saveObj = { id: patient?.neuromuscularSection?.id, status: status };
        updateNeuromuscularSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", neuromuscularSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Neuromuscular",
        description: null,
        progress: data?.neuromuscularSection?.progress,
        status: data?.neuromuscularSection?.status,
        enableStatusField: true,
        iconClass: "fa-bed",
        iconPending: iconBrainRed,
        iconCompleted: iconBrainGreen,
        saving: false,
        onClick: () => {
            setShowNeuromuscularDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateNeuromuscularSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateNeuromuscularSectionStatus(notApplicable);
            }
        },
    };
};
