//Icons
import iconStethoscopeRed from "../../../assets/stethoscope-solid-red.svg";
import iconStethoscopeGreen from "../../../assets/stethoscope-solid-green.svg";

import { updateSkilledObservationAssessmentSection } from "../../../api/nurseAssessment/skilledObservationAssessmentAPI";
import { isError } from "../../../lib/isError";

export const getSkilledObservationSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowSkilledObservationDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateSkilledObservationSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving SkilledObservation Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.skilledObservationSection, status: status };
        const saveObj = { id: patient?.skilledObservationSection?.id, status: status };
        updateSkilledObservationAssessmentSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", skilledObservationSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Skilled Observation / Assessment",
        description: null,
        progress: data?.skilledObservationSection?.progress,
        status: data?.skilledObservationSection?.status,
        enableStatusField: false,
        iconPending: iconStethoscopeRed,
        iconCompleted: iconStethoscopeGreen,
        saving: false,
        onClick: () => {
            setShowSkilledObservationDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateSkilledObservationSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateSkilledObservationSectionStatus(notApplicable);
            }
        },
    };
};
