import { useState } from "react";
import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getClientById } from "../../api/clientAPI";
import { isError } from "../../lib/isError";
import { ClientAdditionalInformation } from "./components/clientAdditionalInformation/clientAdditionalInformation";
import { ClientInfo } from "./components/clientInfo/clientInfo";
import { ClientVisitList } from "./components/clientVisitList/clientVisitList";
import { ClientMAR } from "./components/clientMAR/clientMAR";
import { ClientDocuments } from "./components/clientDocuments/clientDocuments";

export const ClientProfile = () => {
    //Use States ===========================================================

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);

    //Client data state
    const [data, setData] = useState(null);

    let { id } = useParams();

    //Use Effects ===========================================================

    //Loading Patients and defining list page size depending window height...
    useEffect(() => {
        loadClientData(id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //=== Methods ============================================================

    const loadClientData = (id) => {
        //setLoadingError(null);
        //setLoading(true);

        getClientById(id)
            .then((data) => {
                setData(data);
                console.log("clientData", data);
                //setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    //=== Render ============================================================

    return (
        <section id="clientProfile">
            <div className="container-fluid mt-5 pt-4">
                <div className="row mb-0 pb-0">
                    <div className="col-md-12 pb-0 mb-0">
                        <h4 className="pt-3 pb-0 mb-0 ps-3 text-success float-start">Client Profile - {data?.fullName}</h4>
                        <Link className="navLink text-success float-end pt-4 small" to="/clientList">
                            Return to Client List
                        </Link>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-12 px-4 mx-2">
                        <ClientInfo data={data} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 px-4 mx-2">
                        <Tabs defaultActiveKey="visits" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="visits" title="Visits">
                                <div className="row">
                                    <div className="col-12 px-4 mx-2">
                                        <ClientVisitList clientData={data} />
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey="additionalInformation" title="Additional Information">
                                <div className="row">
                                    <div className="col-12 px-4 mx-2">
                                        <ClientAdditionalInformation clientData={data} />
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey="medicationAdministrationRecord" title="MAR">
                                <div className="row">
                                    <div className="col-12 px-4 mx-2">
                                        <ClientMAR clientData={data} />
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey="documents" title="Documents">
                                <div className="row">
                                    <div className="col-12 px-4 mx-2">
                                        <ClientDocuments clientData={data} setClientData={setData} />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </section>
    );
};
