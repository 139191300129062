import icon from "../../../../../../../../assets/hand-holding-heart-solid-red.svg";

export const SummaryNurseOrdersAssessment = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Plan of Care
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Prognosis</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.prognosis ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Functional Limitations</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.functionalLimitations ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 6,
                                }}
                            >
                                {data?.functionalLimitations?.map((item, index) => {
                                    return <li key={index}>{item}</li>;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Activities Permitted</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.activitiesPermitted ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 6,
                                }}
                            >
                                {data?.activitiesPermitted?.map((item, index) => {
                                    if (item !== "Other") return <li key={index}>{item}</li>;
                                })}
                                {data?.activitiesPermittedOther && <li>{data?.activitiesPermittedOther}</li>}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">This assessment confirms the client has suffered a loss of functional capacity which necessitates assistance with the following Activities of Daily Living</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.clientAssistanceNeeds ?? []).length === 0 && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 6,
                                }}
                            >
                                {data?.clientAssistanceNeeds?.map((item, index) => {
                                    return <li key={index}>{item}</li>;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Based on this clinician’s assessment the client requires care due to the presence of a cognitive impairment requiring supervision, including verbal cueing by another person in order to protect the client or others.</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.clientRequiresCare ? "Yes" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Client is chronically ill and need for care is expected to last at least 90 days.</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.clientRequiresCareAtLeast90Days ? "Yes" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Current Nurse Orders</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-2 fw-bold">Skill</div>
                                <div className="col-prt-2 fw-bold">Days/Week</div>
                                <div className="col-prt-2 fw-bold">Hours/Day</div>
                                <div className="col-prt-6 fw-bold"></div>
                            </div>

                            {(data?.nurseOrders ?? []).length === 0 && (
                                <div className="row-prt ms-2">
                                    <div className="col-prt-2 text-primary">-</div>
                                    <div className="col-prt-2 text-primary">-</div>
                                    <div className="col-prt-2 text-primary">-</div>
                                    <div className="col-prt-6 text-primary">-</div>
                                </div>
                            )}

                            {data?.nurseOrders?.map((order, index) => {
                                return (
                                    <>
                                        <div className="row-prt ms-2" key={index}>
                                            <div className="col-prt-2 text-primary">{order?.skill ?? "-"}</div>
                                            <div className="col-prt-2 text-primary">{order?.daysForWeek ?? "-"}</div>
                                            <div className="col-prt-2 text-primary">{order?.hoursForDay ?? "-"}</div>
                                            <div className="col-prt-6 text-primary">{/*order?.comments ?? "-"*/}</div>
                                        </div>
                                        <div className="row-prt ms-2" key={`${index}_nurseOrders`}>
                                            <div className="col-prt-12 text-primary">{order?.orderDetails ?? "-"}</div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
