//css
import "./printAssessments.css";

import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { AssessmentSummary } from "../assessmentSummary/assessmentSummary";

export const PrintAssessments = ({ data }) => {
    let componentRef = useRef();

    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint trigger={() => <button className="btn btn-outline-success">Print</button>} content={() => componentRef} />

                {/* component to be printed */}
                <div style={{ display: "none" }}>
                    <AssessmentSummary data={data} ref={(el) => (componentRef = el)} />
                </div>
            </div>
        </>
    );
};
