import icon from "../../../../../../../../assets/cloud-meatball-solid-red.svg";

export const SummaryGastrointestinal = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Gastrointestinal
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Oral Assessment</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">
                            {(data?.oralAssessment?.length === 0 || !data?.oralAssessment) && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 6,
                                }}
                            >
                                {data?.oralAssessment?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Abdominal Assessment</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.abdominalAssessment ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Tube Feeding</div>
                        <div className="col-prt-4 fw-bold">Description</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.tubeFeeding ? `Yes ${data?.tubeFeedingType ? `(${data?.tubeFeedingType})` : ""}` : "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.tubeFeedingDescription ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Auscultation</div>
                        <div className="col-prt-2 fw-bold">RLQ</div>
                        <div className="col-prt-2 fw-bold">RUQ</div>
                        <div className="col-prt-2 fw-bold">LLQ</div>
                        <div className="col-prt-2 fw-bold">LUQ</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.auscultation ? "Yes" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.auscultation ? data?.auscultationRLQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.auscultation ? data?.auscultationRUQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.auscultation ? data?.auscultationLLQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.auscultation ? data?.auscultationLUQ ?? "-" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Percussion</div>
                        <div className="col-prt-2 fw-bold">RLQ</div>
                        <div className="col-prt-2 fw-bold">RUQ</div>
                        <div className="col-prt-2 fw-bold">LLQ</div>
                        <div className="col-prt-2 fw-bold">LUQ</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.percussion ? "Yes" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.percussion ? data?.percussionRLQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.percussion ? data?.percussionRUQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.percussion ? data?.percussionLLQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.percussion ? data?.percussionLUQ ?? "-" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Palpation</div>
                        <div className="col-prt-2 fw-bold">RLQ</div>
                        <div className="col-prt-2 fw-bold">RUQ</div>
                        <div className="col-prt-2 fw-bold">LLQ</div>
                        <div className="col-prt-2 fw-bold">LUQ</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.palpation ? "Yes" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.palpation ? data?.palpationRLQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.palpation ? data?.palpationRUQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.palpation ? data?.palpationLLQ ?? "-" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.palpation ? data?.palpationLUQ ?? "-" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-6 fw-bold">Negative Symptoms Reported or Observed</div>
                        <div className="col-prt-6 fw-bold">Symptoms</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-6 text-primary">{data?.negativeSymptomsReported ? `Yes` : "-"}</div>
                        <div className="col-prt-6 text-primary">
                            {(data?.symptoms?.length === 0 || !data?.symptoms) && "-"}
                            <ul
                                className="mb-0 ps-4"
                                style={{
                                    columns: 3,
                                }}
                            >
                                {data?.symptoms?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                                {data?.symptomOther && <li>{data?.symptomOther}</li>}
                            </ul>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-2 fw-bold">Elimination</div>
                        <div className="col-prt-2 fw-bold">Last BM</div>
                        <div className="col-prt-4 fw-bold">Description Last BM</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-2 text-primary">{data?.elimination ?? "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.lastBM?.split("T")[0] ?? "-"}</div>
                        <div className="col-prt-4 text-primary">{data?.lastBMDescription ?? "-"}</div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Comments</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.comments ?? "-"}</div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
