import Moment from "react-moment";
import "../../clientVisitList.css";

export const ClientVisitListTableBody = ({ currentPage, numberPerPage, items, loadingError, loading, displayAssignedToField, handleItemClick }) => {
    const begin = (Number(currentPage) - 1) * Number(numberPerPage);
    const end = begin + Number(numberPerPage);
    const data = items?.slice(begin, end) ?? [];

    if (loadingError)
        return (
            <tr>
                <td colSpan={displayAssignedToField ? "7" : "6"} className="table-danger text-center">
                    {loadingError}
                </td>
            </tr>
        );

    return data.length === 0
        ? !loading && (
              <tr>
                  <td colSpan={displayAssignedToField ? "7" : "6"} className="table-danger text-center">
                      No Visits to display
                  </td>
              </tr>
          )
        : data.map(({ patient, assignedTo, contactName, contactPhone, address, assessmentType, appointmentDate, assignedToId, assignedToName, nurseAssessmentId, status, id }) => {
              return (
                  <tr key={id} onClick={() => handleItemClick(nurseAssessmentId, id)} style={{ cursor: "pointer" }}>
                      <td className="small" valign="middle">
                          {patient}
                      </td>
                      {displayAssignedToField && (
                          <td className="small d-none d-md-table-cell" valign="middle">
                              {assignedTo}
                          </td>
                      )}
                      <td className="small" valign="middle">
                          {contactPhone}
                      </td>
                      <td className="small d-none d-md-table-cell" valign="middle">
                          {address}
                      </td>
                      <td className="small d-none d-md-table-cell" valign="middle">
                          {assessmentType}
                      </td>

                      {/*
                      <td className="small" valign="middle">
                          {assignedToId != null ? assignedToName : "unassigned"}
                      </td>
                    */}
                      <td className="small" valign="middle">
                          <Moment format="MM/DD/YYYY hh:mm A">{appointmentDate}</Moment>
                      </td>
                      <td className="small text-center d-none d-md-table-cell" valign="middle">
                          {status ?? "Not Started"}
                      </td>
                      {/* 
                      <td align="right" className="text-right" valign="middle">
                          {assignedToId && (
                              <>
                                  <button className="btn btn-sm btn-outline-secondary" title="Release Assignment">
                                      <i className="fa fa-arrow-left"></i>
                                  </button>
                                  <button className="btn btn-sm btn-success ms-1" title="Edit Assessments">
                                      <i className="fa fa-pencil"></i>
                                  </button>
                              </>
                          )}
                          {!assignedToId && (
                              <button className="btn btn-sm btn-success" title="Take over patient">
                                  <i className="fa fa-plus"></i>
                              </button>
                          )}
                      </td>
                      */}
                  </tr>
              );
          });
};
