//css
import "./bradenScaleDialog.css";

//Icons
import icon from "../../../../assets/pen-ruler-solid-green.svg";

import { useEffect, useState } from "react";
import { Badge, Modal, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";

import { updateBradenScaleSection } from "../../../../api/nurseAssessment/bradenScaleAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { ScoreBradenScale } from "../../../../components/scoreBradenScale/scoreBradenScale";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const BradenScaleDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Braden Scale data state
    const [bradenScaleData, setBradenScaleData] = useState(null);

    //Braden Scale score  state
    const [score, setScore] = useState(10);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.bradenScaleSection, status: data?.bradenScaleSection?.status ?? "Applicable" };
        setBradenScaleData(sectionData);
    }, [data]);

    useEffect(() => {
        let score = 0;

        switch (bradenScaleData?.sensoryPerception) {
            case "Completely Limited":
                score = score + 1;
                break;

            case "Very Limited":
                score = score + 2;
                break;

            case "Slightly Limited":
                score = score + 3;
                break;

            case "No Impairment":
                score = score + 4;
                break;

            default:
                score = score;
        }

        switch (bradenScaleData?.moisture) {
            case "Constantly":
                score = score + 1;
                break;

            case "Often Moist":
                score = score + 2;
                break;

            case "Occasionally":
                score = score + 3;
                break;

            case "Rarely Moist":
                score = score + 4;
                break;

            default:
                score = score;
        }

        switch (bradenScaleData?.activity) {
            case "Bedfast":
                score = score + 1;
                break;

            case "Chairfast":
                score = score + 2;
                break;

            case "Walks Occasionally":
                score = score + 3;
                break;

            case "Walks Frequently":
                score = score + 4;
                break;

            default:
                score = score;
        }

        switch (bradenScaleData?.mobility) {
            case "Completely Immobile":
                score = score + 1;
                break;

            case "Very Limited":
                score = score + 2;
                break;

            case "Slightly Limited":
                score = score + 3;
                break;

            case "No Limitations":
                score = score + 4;
                break;

            default:
                score = score;
        }

        switch (bradenScaleData?.nutrition) {
            case "Very Poor":
                score = score + 1;
                break;

            case "Probably Inadequate":
                score = score + 2;
                break;

            case "Adequate":
                score = score + 3;
                break;

            case "Excellent":
                score = score + 4;
                break;

            default:
                score = score;
        }

        switch (bradenScaleData?.frictionAndShear) {
            case "Problem":
                score = score + 1;
                break;

            case "Potential Problem":
                score = score + 2;
                break;

            case "No Apparent Problem":
                score = score + 3;
                break;

            default:
                score = score;
        }

        setScore(score);
    }, [bradenScaleData]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: bradenScaleData?.id, status: bradenScaleData?.status };
        setBradenScaleData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = { ...bradenScaleData };

        //===========================================================

        //Saving WoundCareSection
        updateBradenScaleSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", bradenScaleSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const sensoryPerceptionPopover = (
        <Popover id="sensoryPerceptionPopover">
            <PopoverBody>Ability to respond meaningfully to pressure-related discomfort.</PopoverBody>
        </Popover>
    );

    const sensoryPerceptionCompletelyLimitedPopover = (
        <Popover id="sensoryPerceptionCompletelyLimitedPopover">
            <PopoverBody>
                Unresponsive (does not moan, flinch, or grasp) to painful stimuli, due to diminished level of consciousness or sedation.
                <br /> OR <br /> Limited ability to feel pain over most of body surface.
            </PopoverBody>
        </Popover>
    );

    const sensoryPerceptionVeryLimitedPopover = (
        <Popover id="sensoryPerceptionVeryLimitedPopover">
            <PopoverBody>
                Responds only to painful stimuli. Cannot communicate discomfort except by moaning or restlessness.
                <br />
                OR
                <br />
                Has a sensory impairment which limits the ability to feel pain or discomfort over 1⁄2 of body.
            </PopoverBody>
        </Popover>
    );

    const sensoryPerceptionSlightlyLimitedPopover = (
        <Popover id="sensoryPerceptionSlightlyLimitedPopover">
            <PopoverBody>
                Responds to verbal commands but cannot always communicate discomfort or need to be turned,
                <br />
                OR
                <br />
                has some sensory impairment which limits ability to feel pain or discomfort in 1 or 2 extremities.
            </PopoverBody>
        </Popover>
    );

    const sensoryPerceptionNoImpairmentPopover = (
        <Popover id="sensoryPerceptionNoImpairmentPopover">
            <PopoverBody>Responds to verbal commands. Has no sensory deficit which would limit ability to feel or voice pain or discomfort.</PopoverBody>
        </Popover>
    );

    const moisturePopover = (
        <Popover id="moisturePopover">
            <PopoverBody>Degree to which skin is exposed to moisture.</PopoverBody>
        </Popover>
    );

    const moistureConstantlyPopover = (
        <Popover id="moistureConstantlyPopover">
            <PopoverBody>Skin is kept moist almost constantly by perspiration, urine, etc. Dampness is detected every time patient is moved or turned.</PopoverBody>
        </Popover>
    );

    const moistureOftenMoistPopover = (
        <Popover id="moistureOftenMoistPopover">
            <PopoverBody>Skin is often but not always moist. Linen must be changed at least once a shift.</PopoverBody>
        </Popover>
    );

    const moistureOccasionallyPopover = (
        <Popover id="moistureOccasionallyPopover">
            <PopoverBody>Skin is occasionally moist, requiring an extra linen change approximately once a day.</PopoverBody>
        </Popover>
    );

    const moistureRarelyMoistPopover = (
        <Popover id="moistureRarelyMoistPopover">
            <PopoverBody>Skin is usually dry; linen only requires changing at routine intervals.</PopoverBody>
        </Popover>
    );

    const activityPopover = (
        <Popover id="activityPopover">
            <PopoverBody>Degree of physical activity.</PopoverBody>
        </Popover>
    );

    const activityBedfastPopover = (
        <Popover id="activityBedfastPopover">
            <PopoverBody>Confined to bed.</PopoverBody>
        </Popover>
    );

    const activityChairfastPopover = (
        <Popover id="activityChairfastPopover">
            <PopoverBody>Ability to walk severely limited or nonexistent. Cannot bear own weight and/or must be assisted into chair or wheelchair.</PopoverBody>
        </Popover>
    );

    const activityWalksOccasionallyPopover = (
        <Popover id="activityWalksOccasionallyPopover">
            <PopoverBody>Walks occasionally during day, but for very short distances, with or without assistance. Spends majority of each shift in bed or chair.</PopoverBody>
        </Popover>
    );

    const activityWalksFrequentlyPopover = (
        <Popover id="activityWalksFrequentlyPopover">
            <PopoverBody>Walks outside the room at least twice a day and inside room at least once every 2 hours during waking hours.</PopoverBody>
        </Popover>
    );

    const mobilityPopover = (
        <Popover id="mobilityPopover">
            <PopoverBody>Ability to change and control body position.</PopoverBody>
        </Popover>
    );

    const mobilityCompletelyImmobilePopover = (
        <Popover id="mobilityCompletelyImmobilePopover">
            <PopoverBody>Does not make even slight changes in body or extremity position without assistance.</PopoverBody>
        </Popover>
    );

    const mobilityVeryLimitedPopover = (
        <Popover id="mobilityVeryLimitedPopover">
            <PopoverBody>Makes occasional slight changes in body or extremity position but unable to make frequent or significant changes independently.</PopoverBody>
        </Popover>
    );

    const mobilitySlightlyLimitedPopover = (
        <Popover id="mobilitySlightlyLimitedPopover">
            <PopoverBody>Makes frequent though slight changes in body or extremity position independently.</PopoverBody>
        </Popover>
    );

    const mobilityNoLimitationsPopover = (
        <Popover id="mobilityNoLimitationsPopover">
            <PopoverBody>Makes major and frequent changes in position without assistance.</PopoverBody>
        </Popover>
    );

    const nutritionPopover = (
        <Popover id="nutritionPopover">
            <PopoverBody>
                Usual food intake pattern.
                <br />
                1.NPO: Nothing by mouth
                <br />
                2. IV: Intravenously.
                <br />3 .TPN: Total parenteral nutrition.
            </PopoverBody>
        </Popover>
    );

    const nutritionVeryPoorPopover = (
        <Popover id="nutritionVeryPoorPopover">
            <PopoverBody>
                Never eats a complete meal. Rarely eats more than 1/3 of any food offered. Eats two servings or less of protein (meat or dairy products) per day. Takes fluids poorly. Does not take a liquid dietary supplement,
                <br />
                OR
                <br />
                Is NPO(1) and/or maintained on clear liquids or IV (2) for more than 5 days.
            </PopoverBody>
        </Popover>
    );

    const nutritionProbablyInadequatePopover = (
        <Popover id="nutritionProbablyInadequatePopover">
            <PopoverBody>
                Rarely eats a complete meal and generally eats only about 1⁄2 of any food offered. Protein intake includes only 3 servings of meat or daily products per day. Occasionally will take a dietary supplement
                <br />
                OR
                <br />
                Receives less than optimum amount of liquid diet or tube feeding.
            </PopoverBody>
        </Popover>
    );

    const nutritionAdequatePopover = (
        <Popover id="nutritionAdequatePopover">
            <PopoverBody>
                Eats over half of most meals. Eats a total of 4 servings of protein (meat, dairy products) each day. Occasionally refuses a meal, but will usually take a supplement if offered,
                <br />
                OR
                <br />
                is on a tube feeding or TPN (3) regimen, which probably meets most of nutritional needs.
            </PopoverBody>
        </Popover>
    );

    const nutritionExcellentPopover = (
        <Popover id="nutritionExcellentPopover">
            <PopoverBody>Eats most of every meal. Never refuses a meal. Usually eats a total of 4 or more servings of meat and dairy products. Occasionally eats between meals. Does not require supplementation.</PopoverBody>
        </Popover>
    );

    const frictionAndShearPopover = (
        <Popover id="frictionAndShearPopover">
            <PopoverBody>Requires moderate to maximum assistance in moving. Complete lifting without sliding against sheets is impossible. Frequently slides down in bed or chair, requiring frequent repositioning with maximum assistance. Spasticity, contractures, or agitation leads to almost constant friction.</PopoverBody>
        </Popover>
    );

    const frictionAndShearProblemPopover = (
        <Popover id="frictionAndShearProblemPopover">
            <PopoverBody></PopoverBody>
        </Popover>
    );

    const frictionAndShearPotentialProblemPopover = (
        <Popover id="frictionAndShearPotentialProblemPopover">
            <PopoverBody>Moves feebly or requires minimum assistance. During a move, skin probably slides to some extent against sheets, chair, restraints, or other devices. Maintains relatively good position in chair or bed most of the time but occasionally slides down.</PopoverBody>
        </Popover>
    );

    const frictionAndShearNoApparentProblemPopover = (
        <Popover id="frictionAndShearNoApparentProblemPopover">
            <PopoverBody>Moves in bed and in chair independently and has sufficient muscle strength to lift up completely during move. Maintains good position in bed or chair at all times.</PopoverBody>
        </Popover>
    );

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...bradenScaleData, [name]: value };
        setBradenScaleData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...bradenScaleData, [name]: checked };
        setBradenScaleData(newData);
    };

    const handleRadioClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...bradenScaleData, [name]: value };
        setBradenScaleData(newData);
    };

    return (
        <Modal dialogClassName="bradenScaleSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Braden Scale (For Predicting Pressure Sore Risk) - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{bradenScaleData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={bradenScaleData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row m-0 p-0">
                        <div className="col-2 m-0 p-0">
                            <span className="text-success">Risk Factors</span>
                        </div>
                        <div className="col-10 text-end">
                            <ScoreBradenScale score={score} />
                        </div>

                        <hr className="my-1" />
                    </div>

                    <div className="row small">
                        <div className="col-4">
                            <span className="my-3 d-block">
                                1. Sensory Perception
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={sensoryPerceptionPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>
                            {readOnly && <div className="text-success pb-2">{bradenScaleData?.status === "Applicable" ? bradenScaleData?.sensoryPerception ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Completely Limited" name="sensoryPerception" id="sensoryPerceptionCompletelyLimited" onClick={handleRadioClick} checked={bradenScaleData?.sensoryPerception === "Completely Limited"} />
                                        <label className="form-check-label" htmlFor="sensoryPerceptionCompletelyLimited">
                                            Completely Limited
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={sensoryPerceptionCompletelyLimitedPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Very Limited" name="sensoryPerception" id="sensoryPerceptionVeryLimited" onClick={handleRadioClick} checked={bradenScaleData?.sensoryPerception === "Very Limited"} />
                                        <label className="form-check-label" htmlFor="sensoryPerceptionVeryLimited">
                                            Very Limited
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={sensoryPerceptionVeryLimitedPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Slightly Limited" name="sensoryPerception" id="sensoryPerceptionSlightlyLimited" onClick={handleRadioClick} checked={bradenScaleData?.sensoryPerception === "Slightly Limited"} />
                                        <label className="form-check-label" htmlFor="sensoryPerceptionSlightlyLimited">
                                            Slightly Limited
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={sensoryPerceptionSlightlyLimitedPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="No Impairment" name="sensoryPerception" id="sensoryPerceptionNoImpairment" onClick={handleRadioClick} checked={bradenScaleData?.sensoryPerception === "No Impairment"} />
                                        <label className="form-check-label" htmlFor="sensoryPerceptionNoImpairment">
                                            No Impairment
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={sensoryPerceptionNoImpairmentPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-3 d-block">
                                2. Moisture
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={moisturePopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>
                            {readOnly && <div className="text-success pb-2">{bradenScaleData?.status === "Applicable" ? bradenScaleData?.moisture ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Constantly" name="moisture" id="moistureConstantly" onClick={handleRadioClick} checked={bradenScaleData?.moisture === "Constantly"} />
                                        <label className="form-check-label" htmlFor="moistureConstantly">
                                            Constantly
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={moistureConstantlyPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check  ms-3">
                                        <input type="radio" className="form-check-input" value="Often Moist" name="moisture" id="moistureOftenMoist" onClick={handleRadioClick} checked={bradenScaleData?.moisture === "Often Moist"} />
                                        <label className="form-check-label" htmlFor="moistureOftenMoist">
                                            Often Moist
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={moistureOftenMoistPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Occasionally" name="moisture" id="moistureOccasionally" onClick={handleRadioClick} checked={bradenScaleData?.moisture === "Occasionally"} />
                                        <label className="form-check-label" htmlFor="moistureOccasionally">
                                            Occasionally
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={moistureOccasionallyPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Rarely Moist" name="moisture" id="moistureRarelyMoist" onClick={handleRadioClick} checked={bradenScaleData?.moisture === "Rarely Moist"} />
                                        <label className="form-check-label" htmlFor="moistureRarelyMoist">
                                            Rarely Moist
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={moistureRarelyMoistPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-3 d-block">
                                3. Activity
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={activityPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>
                            {readOnly && <div className="text-success pb-2">{bradenScaleData?.status === "Applicable" ? bradenScaleData?.activity ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Bedfast" name="activity" id="activityBedfast" onClick={handleRadioClick} checked={bradenScaleData?.activity === "Bedfast"} />
                                        <label className="form-check-label" htmlFor="activityBedfast">
                                            Bedfast
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={activityBedfastPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Chairfast" name="activity" id="activityChairfast" onClick={handleRadioClick} checked={bradenScaleData?.activity === "Chairfast"} />
                                        <label className="form-check-label" htmlFor="activityChairfast">
                                            Chairfast
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={activityChairfastPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Walks Occasionally" name="activity" id="activityWalksOccasionally" onClick={handleRadioClick} checked={bradenScaleData?.activity === "Walks Occasionally"} />
                                        <label className="form-check-label" htmlFor="activityWalksOccasionally">
                                            Walks Occasionally
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={activityWalksOccasionallyPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Walks Frequently" name="activity" id="activityWalksFrequently" onClick={handleRadioClick} checked={bradenScaleData?.activity === "Walks Frequently"} />
                                        <label className="form-check-label" htmlFor="activityWalksFrequently">
                                            Walks Frequently
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={activityWalksFrequentlyPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <hr />

                    <div className="row small">
                        <div className="col-4">
                            <span className="my-3 d-block">
                                4. Mobility
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={mobilityPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>
                            {readOnly && <div className="text-success pb-2">{bradenScaleData?.status === "Applicable" ? bradenScaleData?.mobility ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Completely Immobile" name="mobility" id="mobilityCompletelyImmobile" onClick={handleRadioClick} checked={bradenScaleData?.mobility === "Completely Immobile"} />
                                        <label className="form-check-label" htmlFor="mobilityCompletelyImmobile">
                                            Completely Immobile
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={mobilityCompletelyImmobilePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Very Limited" name="mobility" id="mobilityVeryLimited" onClick={handleRadioClick} checked={bradenScaleData?.mobility === "Very Limited"} />
                                        <label className="form-check-label" htmlFor="mobilityVeryLimited">
                                            Very Limited
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={mobilityVeryLimitedPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Slightly Limited" name="mobility" id="mobilitySlightlyLimited" onClick={handleRadioClick} checked={bradenScaleData?.mobility === "Slightly Limited"} />
                                        <label className="form-check-label" htmlFor="mobilitySlightlyLimited">
                                            Slightly Limited
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={mobilitySlightlyLimitedPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="No Limitations" name="mobility" id="mobilityNoLimitations" onClick={handleRadioClick} checked={bradenScaleData?.mobility === "No Limitations"} />
                                        <label className="form-check-label" htmlFor="mobilityNoLimitations">
                                            No Limitations
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={mobilityNoLimitationsPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-3 d-block">
                                5. Nutrition
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={nutritionPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>
                            {readOnly && <div className="text-success pb-2">{bradenScaleData?.status === "Applicable" ? bradenScaleData?.nutrition ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Very Poor" name="nutrition" id="nutritionVeryPoor" onClick={handleRadioClick} checked={bradenScaleData?.nutrition === "Very Poor"} />
                                        <label className="form-check-label" htmlFor="nutritionVeryPoor">
                                            Very Poor
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={nutritionVeryPoorPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Probably Inadequate" name="nutrition" id="nutritionProbablyInadequate" onClick={handleRadioClick} checked={bradenScaleData?.nutrition === "Probably Inadequate"} />
                                        <label className="form-check-label" htmlFor="nutritionProbablyInadequate">
                                            Probably Inadequate
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={nutritionProbablyInadequatePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Adequate" name="nutrition" id="nutritionAdequate" onClick={handleRadioClick} checked={bradenScaleData?.nutrition === "Adequate"} />
                                        <label className="form-check-label" htmlFor="nutritionAdequate">
                                            Adequate
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={nutritionAdequatePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Excellent" name="nutrition" id="nutritionExcellent" onClick={handleRadioClick} checked={bradenScaleData?.nutrition === "Excellent"} />
                                        <label className="form-check-label" htmlFor="nutritionExcellent">
                                            Excellent
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={nutritionExcellentPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-3 d-block">6. Friction and Shear</span>
                            {readOnly && <div className="text-success pb-2">{bradenScaleData?.status === "Applicable" ? bradenScaleData?.frictionAndShear ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Problem" name="frictionAndShear" id="frictionAndShearProblem" onClick={handleRadioClick} checked={bradenScaleData?.frictionAndShear === "Problem"} />
                                        <label className="form-check-label" htmlFor="frictionAndShearProblem">
                                            Problem
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={frictionAndShearProblemPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="Potential Problem" name="frictionAndShear" id="frictionAndShearPotentialProblem" onClick={handleRadioClick} checked={bradenScaleData?.frictionAndShear === "Potential Problem"} />
                                        <label className="form-check-label" htmlFor="frictionAndShearPotentialProblem">
                                            Potential Problem
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={frictionAndShearPotentialProblemPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>

                                    <div className="form-check ms-3">
                                        <input type="radio" className="form-check-input" value="No Apparent Problem" name="frictionAndShear" id="frictionAndShearNoApparentProblem" onClick={handleRadioClick} checked={bradenScaleData?.frictionAndShear === "No Apparent Problem"} />
                                        <label className="form-check-label" htmlFor="frictionAndShearNoApparentProblem">
                                            No Apparent Problem
                                        </label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={frictionAndShearNoApparentProblemPopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, bradenScaleSection: data?.bradenScaleSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
