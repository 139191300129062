import { acquireAccessToken, acquireMSALTokens } from "../lib/acquireAccessToken";
import { fileTimeStamp } from "../lib/fileTimeStamp";
import { Http_BadRequest, Http_OK } from "../lib/httpStatusCodes";
import { sortJson } from "../lib/sortJson";
import { headers } from "./headers";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}Client`;

//=======================================================================

export const getAllClients = async () => {
    const tokens = await acquireMSALTokens();
    headers.Authorization = tokens?.accessToken ? `bearer ${tokens.accessToken}` : null;
    headers.AuthorizationIdToken = tokens?.idToken ? `bearer ${tokens.idToken}` : null;
    return new Promise((resolve, reject) => {
        fetch(apiUrl, { method: "GET", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                //sorting data by name
                                let data = sortJson(json.data, "fullName", "string", true);

                                //destructuring address
                                data = data.map((item) => {
                                    return { ...item, address: item?.address?.address, city: item?.address?.city, state: item?.address?.state, zipCode: item?.address?.zipCode };
                                });

                                return res(data);
                            });
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================

export const getClientById = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                let visitData = [...json?.data?.visits];

                                //converting date
                                visitData = visitData.map((obj) => {
                                    const myLocalDate = new Date(obj?.appointmentDate + "Z").toLocaleString();
                                    return { ...obj, appointmentDate: myLocalDate };
                                });

                                const returnData = { ...json.data, visits: visitData };

                                return res(returnData);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientDocument = async (id) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/Document/${id}`, {
            method: "GET",
            headers: headers,
            //body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            rej("Bad Request Error: Failed to download");
                            break;

                        case Http_OK:
                            return res(response.blob());
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((blob) => {
                //1. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.setAttribute("download", `ClientDocument_${fileTimeStamp()}.pdf`);
                link.href = url;

                //2. Append to html page
                document.body.appendChild(link);

                //3. Force download
                link.click();

                //4. Clean up and remove link
                link.parentNode.removeChild(link);

                resolve();
            })
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================

export const sendClientDocumentByFax = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/Document/SendFax`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;

                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const createChangeOrder = async (data) => {
    const token = await acquireAccessToken();
    headers.Authorization = token ? `bearer ${token}` : null;
    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/Document/CreateChangeOrder/${data.id}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================
