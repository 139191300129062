import icon from "../../../../../../../../assets/clipboard-list-solid-red.svg";

export const SummaryInterventionsInstructionsExam = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Interventions
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-4 fw-bold">Standard Precautions</div>
                        <div className="col-prt-2 fw-bold">Observed S/S</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-4 text-primary">{data?.standardPrecautions ? "Yes" : "-"}</div>
                        <div className="col-prt-2 text-primary">{data?.observedSS ? "Yes" : "-"}</div>
                    </div>

                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-12 fw-bold fst-italic">
                            <li>Observe / Teach</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Disease process</div>
                                <div className="col-prt-4 fw-bold">Diet</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.diseaseProcess ? `Yes ${data?.diseaseProcessDescription ? `(${data?.diseaseProcessDescription})` : ""}` : "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.diet ? `Yes ${data?.dietDescription ? `(${data?.dietDescription})` : ""}` : "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Safety</div>
                                <div className="col-prt-4 fw-bold">When to Call</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">
                                    {(data?.safety ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 2,
                                        }}
                                    >
                                        {data?.safety?.map((item, index) => {
                                            if (item !== "Other") return <li key={index}>{item}</li>;
                                        })}
                                        {data?.safetyOther && <li>{data?.safetyOther}</li>}
                                    </ul>
                                </div>
                                <div className="col-prt-4 text-primary">
                                    {(data?.whenToCall ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 2,
                                        }}
                                    >
                                        {data?.whenToCall?.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Tube Feeding</div>
                                <div className="col-prt-4 fw-bold">Care of Tracheotomy</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.tubeFeedings ? `Yes ${data?.tubeFeedingsDescription ? `(${data?.tubeFeedingsDescription})` : ""}` : "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.careOfTracheotomy ? `Yes ${data?.careOfTracheotomyDescription ? `(${data?.careOfTracheotomyDescription})` : ""}` : "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Inhalation Rx</div>
                                <div className="col-prt-4 fw-bold">Pain Management</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.inhalationRx ? `Yes ${data?.inhalationRxDescription ? `(${data?.inhalationRxDescription})` : ""}` : "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.painManagement ? `Yes ${data?.painManagementDescription ? `(${data?.painManagementDescription})` : ""}` : "-"}</div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-12 fw-bold">Additional</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-12 text-primary">
                                    {(data?.additional ?? []).length === 0 && "-"}
                                    <ul
                                        className="mb-0 ps-4"
                                        style={{
                                            columns: 6,
                                        }}
                                    >
                                        {data?.additional?.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="row-prt ms-2">
                                <div className="col-prt-4 fw-bold">Observe S/S infection</div>
                                <div className="col-prt-4 fw-bold">Safety Factors</div>
                                <div className="col-prt-4 fw-bold">Diabetic Observation</div>
                            </div>
                            <div className="row-prt ms-2 mb-2">
                                <div className="col-prt-4 text-primary">{data?.observeSSInfection ? "Yes" : "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.safetyFactors ? "Yes" : "-"}</div>
                                <div className="col-prt-4 text-primary">{data?.diabeticObservation ? "Yes" : "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12 fw-bold">Other Skilled Intervention</div>
                    </div>
                    <div className="row-prt ms-2 mb-2">
                        <div className="col-prt-12 text-primary">{data?.otherSkilledIntervention ?? "-"}</div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
