//css
import "./skilledNurseVisitNoteDialog.css";

//Icons
import icon from "../../../../assets/user-nurse-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { getSkilledNurseVisitNotePreviousVisitById, updateSkilledNurseVisitNoteSection } from "../../../../api/nurseAssessment/skilledNurseVisitNoteAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const SkilledNurseVisitNoteDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //SkilledNurseVisitNote data state
    const [skilledNurseVisitData, setSkilledNurseVisitData] = useState(null);

    //list of current Medications
    const [listOfCurrentMedications, setListOfCurrentMedications] = useState([]);

    //saving state
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        if (data?.medicationsSection?.medications)
            setListOfCurrentMedications(
                data?.medicationsSection?.medications
                    ?.filter((item) => !item.discontinued)
                    .map((item) => {
                        return { label: item.name, value: item.name };
                    })
            );
        setSkilledNurseVisitData(data?.skilledNurseVisitNoteSection);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: skilledNurseVisitData?.id, status: skilledNurseVisitData?.status };
        setSkilledNurseVisitData(newData);
    };

    const handleSave = () => {
        setSaving(true);

        const saveObj = {
            ...skilledNurseVisitData,
        };

        //===========================================================

        //Saving SkilledNurseVisitNoteSection
        updateSkilledNurseVisitNoteSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", skilledNurseVisitNoteSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...skilledNurseVisitData, [name]: value };
        setSkilledNurseVisitData(newData);
    };

    const handleAddDiagnosis = () => {
        let diagnosis = skilledNurseVisitData?.diagnoses ? skilledNurseVisitData?.diagnoses : [];
        diagnosis.push("");
        const newData = { ...skilledNurseVisitData, diagnoses: diagnosis };
        setSkilledNurseVisitData(newData);
    };

    const handleDiagnosisFieldChange = (e, index) => {
        const value = e.target.value;
        const diags = skilledNurseVisitData?.diagnoses;
        diags[index] = value;
        const newData = { ...skilledNurseVisitData, diagnoses: diags };
        setSkilledNurseVisitData(newData);
    };

    const handleMedicationChange = (e) => {
        let medicationsNew = e?.map((i) => i.value);
        const newData = { ...skilledNurseVisitData, medicationAdministeredThisVisit: medicationsNew };
        setSkilledNurseVisitData(newData);
    };

    const handleLoadDataPreviousVisitClick = () => {
        setLoading(true);
        getSkilledNurseVisitNotePreviousVisitById(data.id)
            .then((ret) => {
                const newData = { ...data, skilledNurseVisitNoteSection: { ...data.skilledNurseVisitNoteSection, reasonForNeedingHomecare: ret.reasonForNeedingHomecare, diagnoses: ret.diagnoses } };
                setData(newData);

                //hide message after 1s
                setTimeout(() => setLoading(false), 1000);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data.");
                }
                setLoading(false);
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    return (
        <Modal dialogClassName="skilledNurseVisitSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>{" "}
                        </span>
                        <span className="ps-2">Skilled / Nurse Visit Note - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                <select name="status" className="form-select" onChange={handleFieldChange} value={skilledNurseVisitData?.status}>
                                    <option value="">Select</option>
                                    <option value="Not Applicable">Not Applicable</option>
                                    <option value="Applicable">Applicable</option>
                                </select>
                                <div className="d-block text-danger small text-end">&nbsp;</div>
                            </div>
                        </div>
                    )}

                    {!readOnly && (
                        <div className="row">
                            <div className="col-12 text-end">
                                <button className="btn btn-sm btn-outline-secondary" onClick={handleLoadDataPreviousVisitClick}>
                                    {!loading && "Load Data from Previous Visit"}
                                    {loading && (
                                        <span className="ps-2">
                                            <span className="pe-2">Loading...</span>
                                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">
                                Initial Diagnosis for Homecare <b>*</b>
                            </span>
                            {readOnly && <div className="text-success pb-2">{skilledNurseVisitData?.reasonForNeedingHomecare ?? "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" placeholder={`Enter Initial Diagnosis`} value={skilledNurseVisitData?.reasonForNeedingHomecare ?? ""} name="reasonForNeedingHomecare" onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(skilledNurseVisitData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Medication Administered this Visit</span>

                            {readOnly && (
                                <div className="text-success pb-2">
                                    {skilledNurseVisitData?.medicationAdministeredThisVisit?.map((item, index) => {
                                        if (index === 0) return item;
                                        else return `, ${item}`;
                                    }) ?? "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="medicationAdministeredThisVisit"
                                        id="medicationAdministeredThisVisit"
                                        menuPlacement="bottom"
                                        options={listOfCurrentMedications}
                                        //value={listOfCurrentMedications.value}
                                        //defaultValue={skilledNurseVisitData?.medicationAdministeredThisVisit?.map((med) => listOfCurrentMedications?.find((i) => i.value === med))}
                                        value={(skilledNurseVisitData?.medicationAdministeredThisVisit ?? []).map((med) => listOfCurrentMedications?.find((i) => i.value === med))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleMedicationChange}
                                        isDisabled={!["Applicable"].includes(skilledNurseVisitData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div> */}

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Diagnoses
                                <hr className="mt-2 pt-0" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {skilledNurseVisitData?.diagnoses?.map((item, index) => {
                                        if (index === 0) return item;
                                        else return `, ${item}`;
                                    }) ?? "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <button className="btn btn-sm btn-success" onClick={handleAddDiagnosis} disabled={!["Applicable"].includes(skilledNurseVisitData?.status)}>
                                        Add New Diagnosis
                                    </button>
                                    <table className="table table-sm woundList mt-1">
                                        <thead>
                                            <tr>
                                                <th>Diagnosis</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {skilledNurseVisitData?.diagnoses?.length === 0 && (
                                                <>
                                                    <tr>
                                                        <td align="center">No secondary diagnoses reported on this visit </td>
                                                    </tr>
                                                </>
                                            )}
                                            {skilledNurseVisitData?.diagnoses &&
                                                skilledNurseVisitData?.diagnoses.map((diagnosis, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <input name="diagnosis" type="text" value={diagnosis} placeholder={`Enter Diagnosis`} className="form-control form-control-sm" onChange={(e) => handleDiagnosisFieldChange(e, index)} disabled={!["Applicable"].includes(skilledNurseVisitData?.status)} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, skilledNurseVisitNoteSection: data?.skilledNurseVisitNoteSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
