//css
import "./addMedicationDialog.css";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isError } from "../../../../../../../../lib/isError";
import { ModalDialogFooter } from "../../../../../../../../components/modalDialogFooter/modalDialogFooter";
import { getMedicationByClientId } from "../../../../../../../../api/nurseAssessment/medicationsAPI";
import { addMedicationClientMAR } from "../../../../../../../../api/clientMARAPI";

export const AddMedicationDialog = ({ clientId, clientMARId, closeDialog, setClientMARData }) => {
    //Medication data state
    const [medicationId, setMedicationId] = useState(null);

    //list of current Medications
    const [listOfCurrentMedications, setListOfCurrentMedications] = useState([]);

    //saving state
    const [saving, setSaving] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        if (clientId) loadMedicationsByClientId(clientId);
    }, [clientId]);

    //=== Methods ============================================================

    const loadMedicationsByClientId = (id) => {
        setLoadingError(null);
        setLoading(true);
        getMedicationByClientId(id)
            .then((data) => {
                console.log("modalData", data);
                setListOfCurrentMedications(data);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
            })
            .finally((_) => setLoading(false));
    };

    //=== Handlers ===========================================================

    const handleReset = () => {
        //const newData = { id: nurseReviewData?.id, status: nurseReviewData?.status };
        //setNurseReviewData(newData);
    };

    const handleSave = () => {
        if (medicationId && clientMARId) {
            //setSaving(true);

            const saveObj = { medicationId: medicationId, clientMARId: clientMARId };

            //===========================================================
            //Adding Medication MAR
            addMedicationClientMAR(saveObj)
                .then((data) => {
                    setClientMARData(data);
                    closeDialog();
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error loading data");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                })
                .finally((_) => setSaving(false));

            //===========================================================
        }
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        //const newData = { ...nurseReviewData, [name]: value };
        setMedicationId(value);
    };

    //= Render ===================================================================

    return (
        <Modal dialogClassName="nurseReviewSectionModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="ps-2">Add MAR Medication</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Medication</span>
                            <select name="status" className="form-select" onChange={handleFieldChange} value={medicationId}>
                                <option value="">Select</option>
                                {listOfCurrentMedications.map((med) => (
                                    <option value={med.id}>{med.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={false} readOnly={false}></ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
