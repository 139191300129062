//css
import "./sendFaxModal.css";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { sendClientDocumentByFax } from "../../../../../../api/clientAPI";
import { isError } from "../../../../../../lib/isError";
import { ErrorMessage } from "../../../../../../components/errorMessage/errorMessage";

export const SendFaxModal = ({ closeDialog, selectedDocument, faxNumber, setDocumentSent }) => {
    //Medication data state
    const [data, setData] = useState({ showCoverPage: true, from: "Caring People", to: null, subject: null, toFaxNumber: faxNumber, urgent: true, forReview: false, pleaseReply: false, confidential: true });

    //Medication data state
    const [medicationId, setMedicationId] = useState(null);

    //list of current Medications
    const [listOfCurrentMedications, setListOfCurrentMedications] = useState([]);

    //saving state
    const [sending, setSending] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [loading, setLoading] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        if (faxNumber && faxNumber.length > 2) {
            setData({ ...data, toFaxNumber: faxNumber.substring(2) });
        }
    }, [faxNumber]);

    //=== Methods ============================================================

    const formatPhoneNumber = (number) => {
        const cleaned = `${number}`.replace(/\D/g, "");

        if (cleaned.length === 0) {
            return ""; // Retorna uma string vazia se não houver número
        }

        if (cleaned.length <= 3) {
            return `(${cleaned}`;
        }

        if (cleaned.length <= 6) {
            return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
        }

        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    };

    //=== Handlers ===========================================================

    const handleSendFaxClick = async () => {
        const requestData = {
            ...data,
            toFaxNumber: `+1${data?.toFaxNumber}`,
            options: [],
            clientDocumentId: selectedDocument,
        };

        const optionsArray = [];

        if (data.urgent) {
            optionsArray.push("urgent");
        }

        if (data.forReview) {
            optionsArray.push("for_review");
        }

        if (data.pleaseReply) {
            optionsArray.push("please_reply");
        }

        if (data.confidential) {
            optionsArray.push("confidential");
        }

        requestData.options = optionsArray;

        if (data?.toFaxNumber?.length !== 10) {
            setOperationError("Error: Invalid Fax Number");

            //hide message after 5s
            setTimeout(() => setOperationError(null), 5000);
        } else {
            setSending(true);

            await sendClientDocumentByFax(requestData)
                .then((ret) => {
                    setDocumentSent(ret);
                    closeDialog();
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error loading data");
                    }

                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                })
                .finally((_) => {
                    setSending(false);
                });
        }
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...data, [name]: checked };
        setData(newData);
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...data, [name]: value };
        setData(newData);
    };

    const handleFaxChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        // Apenas números são permitidos
        value = value.replace(/\D/g, "");

        // Limita o número de caracteres
        const maxLength = 10; // Defina o número desejado de caracteres
        value = value.slice(0, maxLength);

        // Atualiza o valor no estado
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    //= Render ===================================================================

    // Formate o número ao exibir no campo
    const formattedNumber = formatPhoneNumber(data?.faxNumber);

    return (
        <Modal show={true} onHide={closeDialog} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="ps-2">Send Fax</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    <div className="row">
                        {/*
                        <div className="col-6">
                            <label className="my-1 d-block small">Patient's Physician Name</label>
                            <input name="patientPhysicianName" className="form-control form-control-sm" onChange={handleFieldChange} placeholder="Name" value={data?.patientPhysicianName ?? ""} />
                        </div>
                        */}
                        <div className="col-12">
                            <label className="my-1 d-block">Fax Number</label>
                            <div className="input-group">
                                <span className="input-group-text">+1</span>
                                <input
                                    name="toFaxNumber"
                                    type="tel"
                                    pattern="[0-9]*" // Aceitar apenas números
                                    className="form-control form-control-sm"
                                    onChange={handleFaxChange}
                                    placeholder="Fax Number"
                                    value={formatPhoneNumber(data?.toFaxNumber) || ""}
                                />
                            </div>
                        </div>
                    </div>

                    <hr />

                    {/*
                    <div className="row">
                        <div className="col-12">
                            <input name="showCoverPage" type="checkbox" className="form-check-input" checked={data.showCoverPage ?? false} id="showCoverPage" onChange={handleCheckboxChange} value="" />
                            <label className="form-check-label ps-2" htmlFor="showCoverPage">
                                Add Cover Sheet
                            </label>
                        </div>
                    </div>
                    */}

                    <div className="row pt-2">
                        <div className="col-6">
                            <label className="my-1 d-block">From</label>
                            <input name="from" type="text" className="form-control" placeholder={`From...`} value={data.from ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!data?.showCoverPage} />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-6">
                            <label className="my-1 d-block">To</label>
                            <input name="to" type="text" className="form-control" placeholder={`To...`} value={data.to ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!data?.showCoverPage} />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <label className="my-1 d-block">Subject</label>
                            <input name="subject" type="text" className="form-control" placeholder={`Subject...`} value={data.subject ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!data?.showCoverPage} />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>
                </>

                <div className="row">
                    <div className="col-3">
                        <input name="urgent" type="checkbox" className="form-check-input" checked={data.urgent ?? false} id="urgent" onChange={handleCheckboxChange} value="" disabled={!data?.showCoverPage} />
                        <label className="form-check-label ps-2" htmlFor="urgent">
                            Urgent
                        </label>
                    </div>
                    <div className="col-3">
                        <input name="forReview" type="checkbox" className="form-check-input" checked={data.forReview ?? false} id="forReview" onChange={handleCheckboxChange} value="" disabled={!data?.showCoverPage} />
                        <label className="form-check-label ps-2" htmlFor="forReview">
                            For Review
                        </label>
                    </div>
                    <div className="col-3">
                        <input name="pleaseReply" type="checkbox" className="form-check-input" checked={data.pleaseReply ?? false} id="pleaseReply" onChange={handleCheckboxChange} value="" disabled={!data?.showCoverPage} />
                        <label className="form-check-label ps-2" htmlFor="pleaseReply">
                            Please Reply
                        </label>
                    </div>
                    <div className="col-3">
                        <input name="confidential" type="checkbox" className="form-check-input" checked={data.confidential ?? false} id="confidential" onChange={handleCheckboxChange} value="" disabled={!data?.showCoverPage} />
                        <label className="form-check-label ps-2" htmlFor="confidential">
                            Confidential
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {operationError && <ErrorMessage msg={operationError} size="small" />}
                <button type="button" className="btn btn-success" disabled={sending} onClick={handleSendFaxClick}>
                    {!sending && "Send Fax"}
                    {sending && (
                        <span className="ps-2">
                            <span className="pe-2">Sending...</span>
                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                        </span>
                    )}
                </button>

                <button type="button" className="btn btn-outline-secondary" disabled={sending} onClick={closeDialog}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};
