import "./clientDocuments.css";

import Moment from "react-moment";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../../lib/hooks/useWindowDimensions";
import { getSupervisionReport } from "../../../../api/reports/supervisionReportAPI";
import { sortJson } from "../../../../lib/sortJson";
import { JSONToCSVConvertor } from "../../../../lib/jsonToCSVConvertor";
import DatePicker from "react-datepicker";
import { fileTimeStamp } from "../../../../lib/fileTimeStamp";
import { isError } from "../../../../lib/isError";
import { Loading } from "../../../../components/loading/loading";
import { DownloadButton } from "../../../../components/downloadButton/downloadButton";
import { ErrorMessage } from "../../../../components/errorMessage/errorMessage";
import { createChangeOrder, getClientDocument } from "../../../../api/clientAPI";
import { getChangeOrderForm } from "../../../../api/visitAPI";
import { ChangeOrderModal } from "./components/changeOrderModal/changeOrderModal";
import { SendFaxModal } from "./components/sendFaxModal/sendFaxModal";

export const ClientDocuments = ({ clientData, setClientData }) => {
    //=== Use States =========================================================

    // state for documents
    const [documents, setDocuments] = useState([]);

    // selected document
    const [selectedDocument, setSelectedDocument] = useState(null);

    // document sent by Fax
    const [documentSentByFax, setDocumentSentByFax] = useState(null);

    // Estados para controle individual de download
    const [downloadStates, setDownloadStates] = useState({});

    //Downloading form flag state
    const [showChangeOrderModal, setShowChangeOrderModal] = useState(false);
    const [showSendFaxModal, setShowSendFaxModal] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(false);

    //=== Use Effects =========================================================
    useEffect(() => {
        if (clientData?.documents) {
            let newData = sortJson(clientData?.documents, "createdDate", "datetime", true);

            //converting date
            newData = newData.map((obj) => {
                const myLocalDate = new Date(obj?.createdDate + "Z").toLocaleString();
                return { ...obj, createdDate: myLocalDate };
            });

            setDocuments(newData);
        }
    }, [clientData]);

    useEffect(() => {
        // Verificar se documentSentByFax não é nulo e documents não está vazio
        if (documentSentByFax && documents.length > 0) {
            // Encontrar o índice do objeto com o mesmo id que documentSentByFax
            const indexToUpdate = documents.findIndex((doc) => doc.id === documentSentByFax.id);

            // Se o objeto for encontrado, atualizar o array documents
            if (indexToUpdate !== -1) {
                setDocuments((prevDocuments) => [...prevDocuments.slice(0, indexToUpdate), documentSentByFax, ...prevDocuments.slice(indexToUpdate + 1)]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentSentByFax]);

    //=== Handlers ============================================================

    const handleDownloadDocumentClick = async (id) => {
        //setDownloading(true);

        setDownloadStates((prevStates) => ({
            ...prevStates,
            [id]: true,
        }));

        await getClientDocument(id)
            .then((ret) => {})
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => {
                setDownloadStates((prevStates) => ({
                    ...prevStates,
                    [id]: false,
                }));
            });
    };

    const handleDownloadChangeOrderClick = async (id) => {
        //setDownloadingSummary(true);

        //const data = { id: patient.id, patientName: patient?.patient?.replace(/\s/g, "") }; //{ patientName: patient?.patient?.replace(/\s/g, ""), visitDate: moment(patient?.appointmentDate).format("YYYYMMDD"), cpaVisitId: patient?.cpaVisitId ?? 0, assessmentID: patient?.id, prognosis: patient?.nurseOrdersSection?.prognosis, assessments: assessments };
        const data = { id: id };

        await getChangeOrderForm(data)
            .then((ret) => {})
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally((_) => {
                //setDownloadingSummary(false);
            });
    };

    const handleCreateChangeOrderClick = async (id) => {
        //setDownloadingSummary(true);

        //const data = { id: patient.id, patientName: patient?.patient?.replace(/\s/g, "") }; //{ patientName: patient?.patient?.replace(/\s/g, ""), visitDate: moment(patient?.appointmentDate).format("YYYYMMDD"), cpaVisitId: patient?.cpaVisitId ?? 0, assessmentID: patient?.id, prognosis: patient?.nurseOrdersSection?.prognosis, assessments: assessments };
        const data = { id: id };

        await createChangeOrder(data)
            .then((ret) => {
                const newClientData = { ...clientData, documents: [...clientData.documents, { id: ret?.id, typeDescription: ret?.typeDescription, createdDate: ret?.createdDate, createdBy: ret?.createdBy }] };
                setClientData(newClientData);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally((_) => {
                //setDownloadingSummary(false);
            });
    };

    //

    /*
    const handleDownloadSummaryClick = async () => {
        setDownloadingSummary(true);

        const data = { id: patient.id, patientName: patient?.patient?.replace(/\s/g, "") }; //{ patientName: patient?.patient?.replace(/\s/g, ""), visitDate: moment(patient?.appointmentDate).format("YYYYMMDD"), cpaVisitId: patient?.cpaVisitId ?? 0, assessmentID: patient?.id, prognosis: patient?.nurseOrdersSection?.prognosis, assessments: assessments };

        await getVisitSummaryForm(data)
            .then((ret) => {})
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally((_) => {
                setDownloadingSummary(false);
            });
    };
    */

    //=== Methods =============================================================

    const formatName = (inputName) => {
        const words = inputName.split(".");
        const formattedWords = words.map((word) => {
            if (word.length > 0) {
                const formattedWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                return formattedWord;
            }
            return "";
        });
        const formattedName = formattedWords.join(" ");
        return formattedName;
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Verificar se o número de telefone é nulo
        if (!phoneNumber) {
            return "-"; // Ou algum valor padrão, dependendo dos requisitos
        }
        const cleaned = phoneNumber.replace(/\D/g, "");
        const countryCode = cleaned.substring(0, 1); // Pegar o código do país (assumindo que é o primeiro dígito)
        const areaCode = cleaned.substring(1, 4);
        const firstPart = cleaned.substring(4, 7);
        const secondPart = cleaned.substring(7, 11);
        const formatted = `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
        return formatted;
    };

    const getLocalDate = (date) => {
        if (date) return new Date(date.endsWith("Z") ? date : date + "Z").toLocaleString();
        else return null;
    };

    const capitalize = (str) => {
        // Verificar se a string é nula ou vazia
        if (!str) {
            return str; // Retorna a string original se for nula ou vazia
        }

        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    //=== Render ==============================================================
    return (
        <>
            <section id="ClientDocuments">
                <div className="container-fluid">
                    <div className="row pb-0 mb-0">
                        <div className="col-4 col-md-3 col-xl-2 pb-0 mb-0">
                            <h5 className="pt-3 text-success ps-3">Documents</h5>
                        </div>

                        {operationError && (
                            <div className="col-8 col-md-9 col-xl-10 text-end pe-4 pb-0 mb-1 pt-3">
                                <ErrorMessage msg={operationError} size="small" />
                            </div>
                        )}
                    </div>
                    <hr className="pt-0 mt-0"></hr>

                    <div className="row documentContainer">
                        <div className="row">
                            {/*
                            <div className="col-2">
                                <button className="btn btn-sm btn-primary" onClick={() => handleDownloadChangeOrderClick(clientData.id)}>
                                    Download Change Order
                                </button>
                            </div>
                            */}

                            {clientData?.documents && clientData.documents.length > 0 && (
                                <div className="col-2">
                                    <button className="btn btn-sm btn-primary" onClick={() => setShowChangeOrderModal(true) /*handleCreateChangeOrderClick(clientData.id)*/}>
                                        Create Change Order
                                    </button>
                                </div>
                            )}
                        </div>

                        <table className="table table-striped small">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Created by</th>
                                    <th>Fax Status</th>
                                    <th>Fax Number</th>
                                    <th>Fax Sent by</th>
                                    <th>Fax Queue Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!documents ||
                                    (documents && documents.length == 0 && (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                No documents to display
                                            </td>
                                        </tr>
                                    ))}
                                {documents &&
                                    documents.length > 0 &&
                                    documents.map((document, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{document?.typeDescription ?? "-"}</td>
                                                <td>
                                                    <Moment format="MM/DD/YYYY hh:mm A">{document?.createdDate ?? "-"}</Moment>
                                                </td>
                                                <td>{formatName(document?.createdBy.split("@")[0]) ?? "-"}</td>
                                                <td>{capitalize(document?.faxStatus) ?? "-"}</td>
                                                <td>{formatPhoneNumber(document?.faxNumber) ?? "-"}</td>
                                                <td>{document?.faxSentBy ?? "-"}</td>
                                                <td>{document?.faxSentToQueueDate ? <Moment format="MM/DD/YYYY hh:mm A">{getLocalDate(document?.faxSentToQueueDate)}</Moment> : "-"}</td>
                                                <td>
                                                    <DownloadButton
                                                        className="btn btn-outline-success btn-sm"
                                                        handleDownload={() => {
                                                            handleDownloadDocumentClick(document.id);
                                                        }}
                                                        loading={downloadStates[document.id]}
                                                        label={"Download"}
                                                    ></DownloadButton>

                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success btn-sm ms-2"
                                                        onClick={() => {
                                                            setSelectedDocument(document);
                                                            setShowSendFaxModal(true);
                                                        }}
                                                    >
                                                        Send Fax
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>

                    <hr />
                </div>
                {showChangeOrderModal && <ChangeOrderModal closeDialog={() => setShowChangeOrderModal(false)} setClientData={setClientData} clientData={clientData} />}
                {showSendFaxModal && <SendFaxModal closeDialog={() => setShowSendFaxModal(false)} selectedDocument={selectedDocument?.id} faxNumber={selectedDocument?.faxNumber} setDocumentSent={setDocumentSentByFax} />}
            </section>
        </>
    );
};
