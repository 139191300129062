/* Medications Form data */
export const doseTypeData = [
    "Aerosol",
    "Aerosol, Foam",
    "Aerosol, Metered",
    "Aerosol, Powder",
    "Aerosol, Spray",
    "Bar, Chewable",
    "Bead",
    "Capsule",
    "Capsule, Coated",
    "Capsule, Coated Pellets",
    "Capsule, Coated, Extended Release",
    "Capsule, Delayed Release",
    "Capsule, Delayed Release Pellets",
    "Capsule, Extended Release",
    "Capsule, Gelatin Coated",
    "Capsule, Liquid Filled",
    "Cellular Sheet",
    "Chewable Gel",
    "Cloth",
    "Concentrate",
    "Cream",
    "Cream, Augmented",
    "Crystal",
    "Disc",
    "Douche",
    "Dressing",
    "Drug-Eluting Contact Lens",
    "Elixir",
    "Emulsion",
    "Enema",
    "Film",
    "Film, Extended Release",
    "Film, Soluble",
    "For Solution",
    "For Suspension",
    "Gas",
    "Gel",
    "Gel, Dentifrice",
    "Gel, Metered",
    "Globule",
    "Granule",
    "Granule, Delayed Release",
    "Granule, Effervescent",
    "Granule, For Solution",
    "Granule, For Suspension",
    "Granule, For Suspension, Extended Release",
    "Gum, Chewing",
    "Implant",
    "Inhalant",
    "Injectable, Liposomal",
    "Injection",
    "Injection, Emulsion",
    "Injection, Lipid Complex",
    "Injection, Powder, For Solution",
    "Injection, Powder, Lyophilized, For Solution",
    "Injection, Powder, Lyophilized, For Suspension",
    "Injection, Solution",
    "Injection, Solution, Concentrate",
    "Injection, Suspension",
    "Injection, Suspension, Extended Release",
    "Injection, Suspension, Liposomal",
    "Insert",
    "Insert, Extended Release",
    "Intrauterine Device",
    "Irrigant",
    "Jelly",
    "Kit",
    "Liniment",
    "Lipstick",
    "Liquid",
    "Lotion",
    "Lotion, Augmented",
    "Lotion/Shampoo",
    "Lozenge",
    "Mouthwash",
    "Oil",
    "Ointment",
    "Ointment, Augmented",
    "Paste",
    "Paste, Dentifrice",
    "Pastille",
    "Patch",
    "Patch, Extended Release",
    "Pellet",
    "Pellet, Implantable",
    "Pill",
    "Plaster",
    "Poultice",
    "Powder",
    "Powder, Dentifrice",
    "Powder, For Solution",
    "Powder, For Suspension",
    "Powder, Metered",
    "Ring",
    "Rinse",
    "Salve",
    "Shampoo",
    "Shampoo, Suspension",
    "Soap",
    "Solution",
    "Solution, Concentrate",
    "Solution, Gel Forming / Drops",
    "Solution/ Drops",
    "Sponge",
    "Spray",
    "Spray, Metered",
    "Spray, Suspension",
    "Stick",
    "Strip",
    "Suppository",
    "Suspension",
    "Suspension, Extended Release",
    "Suspension/ Drops",
    "Swab",
    "Syrup",
    "System",
    "Tablet",
    "Tablet With Sensor",
    "Tablet, Chewable",
    "Tablet, Chewable, Extended Release",
    "Tablet, Coated",
    "Tablet, Delayed Release",
    "Tablet, Effervescent",
    "Tablet, Extended Release",
    "Tablet, Film Coated",
    "Tablet, Film Coated, Extended Release",
    "Tablet, For Solution",
    "Tablet, For Suspension",
    "Tablet, Multilayer",
    "Tablet, Multilayer, Extended Release",
    "Tablet, Orally Disintegrating",
    "Tablet, Orally Disintegrating, Delayed Release",
    "Tablet, Soluble",
    "Tablet, Sugar Coated",
    "Tape",
    "Tincture",
    "Wafer",
];

export const routeData = [
    "Oral",
    "Topical",
    "Ocular",
    "Nasal",
    "Inhalation",
    "Subcutaneous",
    "Auricular (Otic)",
    "Auricular (Otic); Intratympanic",
    "Buccal",
    "Buccal; Sublingual",
    "Cutaneous",
    "Cutaneous; Intradermal; Subcutaneous",
    "Cutaneous; Respiratory (Inhalation)",
    "Cutaneous; Topical",
    "Cutaneous; Topical; Transdermal",
    "Cutaneous; Vaginal",
    "Dental",
    "Dental; Oral",
    "Dental; Oral; Periodontal",
    "Dental; Oral; Periodontal; Subgingival",
    "Dental; Oral; Topical",
    "Dental; Periodontal",
    "Dental; Periodontal; Subgingival",
    "Dental; Sublingual",
    "Dental; Sublingual; Topical",
    "Dental; Topical",
    "Endotracheal",
    "Endotracheal; Intracardiac; Intravenous",
    "Endotracheal; Intramedullary; Intramuscular; Intravenous; Subcutaneous",
    "Endotracheal; Intramuscular; Intravenous; Subcutaneous",
    "Enteral",
    "Enteral; Vaginal",
    "Epidural",
    "Epidural; Infiltration",
    "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Intravenous; Soft Tissue; Subcutaneous; Topical",
    "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Soft Tissue; Topical",
    "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Topical",
    "Epidural; Infiltration; Intra-Articular; Intramuscular",
    "Epidural; Infiltration; Intra-Articular; Intramuscular; Topical",
    "Epidural; Infiltration; Intra-Articular; Topical",
    "Epidural; Infiltration; Intracaudal",
    "Epidural; Infiltration; Intracaudal; Perineural",
    "Epidural; Infiltration; Intramuscular; Intrasynovial; Soft Tissue; Topical",
    "Epidural; Infiltration; Intramuscular; Intravenous; Perineural; Topical",
    "Epidural; Infiltration; Intramuscular; Intravenous; Topical",
    "Epidural; Infiltration; Perineural",
    "Epidural; Infiltration; Topical",
    "Epidural; Intra-Articular; Intramuscular; Topical",
    "Epidural; Intracaudal",
    "Epidural; Intracaudal; Perineural",
    "Epidural; Intrathecal",
    "Epidural; Intrathecal; Intravenous",
    "Epidural; Intravenous",
    "Epidural; Perineural",
    "Epidural; Retrobulbar",
    "Extracorporeal",
    "Extracorporeal; Topical",
    "Hemodialysis",
    "Infiltration",
    "Infiltration; Intra-Articular; Intralesional; Intramuscular; Perineural; Topical",
    "Infiltration; Intra-Articular; Intralesional; Intramuscular; Soft Tissue; Topical",
    "Infiltration; Intramuscular",
    "Infiltration; Intramuscular; Intravenous; Topical",
    "Infiltration; Intravenous",
    "Infiltration; Perineural",
    "Infiltration; Soft Tissue; Topical",
    "Intra-Arterial",
    "Intra-Arterial; Intralesional; Intramuscular; Soft Tissue",
    "Intra-Arterial; Intralymphatic; Intrauterine",
    "Intra-Arterial; Intramuscular",
    "Intra-Arterial; Intramuscular; Intrathecal; Intravenous",
    "Intra-Arterial; Intramuscular; Intravenous",
    "Intra-Arterial; Intravenous",
    "Intra-Articular",
    "Intra-Articular; Intradermal; Intramuscular; Intravenous; Subcutaneous",
    "Intra-Articular; Intralesional",
    "Intra-Articular; Intralesional; Intramuscular",
    "Intra-Articular; Intralesional; Intramuscular; Intrasynovial; Soft Tissue",
    "Intra-Articular; Intralesional; Intramuscular; Intravenous; Soft Tissue",
    "Intra-Articular; Intralesional; Intramuscular; Soft Tissue",
    "Intra-Articular; Intramuscular",
    "Intra-Articular; Intrathecal; Intravascular; Intravenous; Oral; Rectal",
    "Intra-Articular; Intravascular; Intravenous; Oral",
    "Intrabronchial",
    "Intracameral",
    "Intracanalicular; Ophthalmic",
    "Intracardiac; Intramuscular; Intravenous; Subcutaneous",
    "Intracardiac; Intravenous",
    "Intracavernous",
    "Intracavitary",
    "Intracavitary; Intravenous; Intravesical",
    "Intracoronary; Respiratory (Inhalation)",
    "Intradermal",
    "Intradermal; Intramuscular",
    "Intradermal; Intramuscular; Intravenous; Subcutaneous",
    "Intradermal; Percutaneous",
    "Intradermal; Percutaneous; Subcutaneous",
    "Intradermal; Subcutaneous",
    "Intralesional",
    "Intralesional; Intramuscular; Intrasynovial; Soft Tissue",
    "Intralesional; Intramuscular; Subcutaneous",
    "Intramuscular",
    "Intramuscular; Intravenous",
    "Intramuscular; Intrapleural; Intrathecal; Intravenous",
    "Intramuscular; Intrapleural; Intravenous; Subcutaneous",
    "Intramuscular; Intrathecal; Intravenous",
    "Intramuscular; Intrathecal; Intravenous; Ophthalmic",
    "Intramuscular; Intrathecal; Intravenous; Subcutaneous",
    "Intramuscular; Intravenous; Parenteral",
    "Intramuscular; Intravenous; Rectal",
    "Intramuscular; Intravenous; Subconjunctival",
    "Intramuscular; Intravenous; Subcutaneous",
    "Intramuscular; Intravenous; Topical",
    "Intramuscular; Intravitreal",
    "Intramuscular; Subcutaneous",
    "Intramuscular; Subcutaneous; Topical",
    "Intraocular",
    "Intraocular; Ophthalmic",
    "Intraperitoneal",
    "Intraperitoneal; Intravenous",
    "Intrapleural",
    "Intrasinal",
    "Intraspinal",
    "Intrathecal",
    "Intrathecal; Intravascular; Intravenous; Oral",
    "Intrathecal; Intravascular; Intravenous; Oral; Rectal",
    "Intrathecal; Intravenous; Subcutaneous",
    "Intrathecal; Oral; Rectal",
    "Intratympanic",
    "Intrauterine",
    "Intravascular",
    "Intravascular; Intravenous",
    "Intravascular; Intravenous; Oral",
    "Intravascular; Intravenous; Oral; Rectal",
    "Intravenous",
    "Intravenous; Intraventricular",
    "Intravenous; Intravesical",
    "Intravenous; Intravesical; Ophthalmic",
    "Intravenous; Oral",
    "Intravenous; Parenteral",
    "Intravenous; Respiratory (Inhalation)",
    "Intravenous; Subcutaneous",
    "Intraventricular",
    "Intravesical",
    "Intravitreal",
    "Irrigation",
    "Nasal",
    "Nasal; Respiratory (Inhalation)",
    "Nasal; Topical",
    "Ophthalmic",
    "Ophthalmic; Oral; Topical",
    "Ophthalmic; Topical",
    "Oral; Rectal",
    "Oral; Respiratory (Inhalation)",
    "Oral; Sublingual",
    "Oral; Topical",
    "Oral; Transmucosal",
    "Oropharyngeal",
    "Parenteral",
    "Percutaneous",
    "Percutaneous; Endoscopic Gastrostomy",
    "Percutaneous; Subcutaneous",
    "Percutaneous; Topical; Transdermal",
    "Percutaneous; Transdermal",
    "Perineural",
    "Periodontal",
    "Rectal",
    "Rectal; Topical",
    "Rectal; Vaginal",
    "Respiratory (Inhalation)",
    "Respiratory (Inhalation); Topical",
    "Retrobulbar",
    "Retrobulbar; Topical; Transtracheal",
    "Subarachnoid",
    "Subcutaneous",
    "Subgingival",
    "Sublingual",
    "Submucosal",
    "Topical",
    "Topical; Transdermal",
    "Topical; Vaginal",
    "Transdermal",
    "Transmucosal",
    "Ureteral",
    "Urethral",
    "Vaginal",
];

/*
Source Data:

SELECT 
  qryMedication.PROPRIETARYNAME as Medication, 
  DoseTypes.DOSAGEFORMNAME as DoseType,
  Routes.ROUTENAME as Route
from
  (select DISTINCT PROPRIETARYNAME from tblFDAMedication) as qryMedication
  JOIN 
    (select DISTINCT PROPRIETARYNAME, DOSAGEFORMNAME from tblFDAMedication) as DoseTypes
	ON DoseTypes.PROPRIETARYNAME = qryMedication.PROPRIETARYNAME
  JOIN 
    (select DISTINCT PROPRIETARYNAME, DOSAGEFORMNAME, ROUTENAME from tblFDAMedication) as Routes
	ON Routes.PROPRIETARYNAME = DoseTypes.PROPRIETARYNAME AND Routes.DOSAGEFORMNAME = DoseTypes.DOSAGEFORMNAME 
ORDER BY
  Medication, 
  DoseType,
  Route
FOR JSON AUTO

*/

export const medicationJson = [
    { Medication: "(Re) Setting 100% Mineral Powder Deep Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "(Re) Setting 100% Mineral Powder Light Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "(Re) Setting 100% Mineral Powder Medium Broad Spectrum Sunscreen Spf 35", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "(Re) Setting 100% Mineral Powder Translucent Broad Spectrum Sunscreen Spf 35", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "(Re)Setting Refreshing Mist Spf 40", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "(S)Undercover", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "(Sotradecol) Sodium Tetradecyl Sulfate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: ".Insulin Aspart Protamine And Insulin Aspart", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "0.7", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "0.9% Sodium Chloride",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intravenous" }] },
            { DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] },
        ],
    },
    { Medication: "0.91", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "0.9G Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1 Bladder", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "1 Detoxification", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "1% Lidocaine Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }] }] },
    { Medication: "1.8Oz Armstrong Hand Sanitizer With Aloe Vera And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1.8Oz Hand Sanitizer With Clip -Assorted", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "10 Acne Med", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "10 Armani Prima Control Glow Moisturizer Sbs Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "10 Parasite Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "10 Tree Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "100 % Mineral Spf Mini Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "100 Toothpast E Afternoon", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "100 Toothpast E Morning", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "100 Toothpast E Night", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "100% Mineral Body Mist With Green Tea Extract Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "100% Mineral Broad Spectrum Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "100% Mineral Broad Spectrum Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "100% Pure", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "100% Pure Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "100% Pure Yerba Mate Mist", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1000 Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "100Ml Cucumber Hand Purifying", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "100Ml Gardenia Hand Purifying", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "100Ml Lemon Hand Purifying", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1012 Antimicrobial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "10G Colgate Plus Toothbrush Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "10G Colgate Plus Toothbrush Plus Floss Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "11 Tree Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "111 Medco Benzoyl Peroxide", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1177, 1178 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "12 Hour Allergy And Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "12 Hour Allergy D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "12 Hour Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "12 Hour Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "12 Hour Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "12 Hour Nasal Decongestant Extra Moisturizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "12 Hour Original Nasal Decongestant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "12Hr Allergy And Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "12Hr Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "14-Panel Toxicology Medicated Collection System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "1438 Dynasoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "15Pcs Antibactrial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "16Oz Hydorgen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1902 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1904 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1Med Clotrimazole 1% Antifungal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1St Medxpatch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1St Medxpatch With Lidocaine 4%-Rx", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "1St Relief Topical", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2 Cockroach Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "2 Gallbladder", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "2 In 1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2 In 1 Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2 In 1 Relief Value Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "2 In 1Laxative Stool Softener And Stiulant Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "2 Lymphatic Drainage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "2 Maple Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "2 To 12 Years Childrens Cold And Cough Day And Night Time", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "2 To 12 Years Childrens Cold And Mucus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "2 Transform", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "2% Lidocaine Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }, { Route: "Intravenous" }] }] },
    { Medication: "2-Count Heat Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2-Pack Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2-Power Fabric Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2.5 Acne Med", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2.5Oz Everdry Antiperspirant Invisible", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "24 Hour Acne Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "24 Hour Allergy",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "24 Hour Allergy Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "24 Hour Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "24 Hour Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "24 Hr Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "24/7 Life By 7- Eleven", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "24/7 Life Kids Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "24/7 Life Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "24/7 Life Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "24Hr Allergy Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "24K Skin Tone Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "25% Dextrose Infant", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "28 Mile Fools Gin Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "28 Mile Tough Town Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "29 St. Honore Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2Pack 2Oz Hand Sanitizer- Classic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2Pk 2Oz Hand Sanitiizer-Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2Pk Traveler Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2Pk Traveler Hand Sanitizer Classic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2Pk Traveler Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2X Med Liq", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "2Xl Mega Roll Sanitizing Wipes 2300 Count Megaroll", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "2Xl Mega Rolls Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3 Grass Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "3 Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3 In 1 Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3 In 1 First Aid Wound Care", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3 Maple Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "3 Weed Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "3% Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3% Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "3-1 Acne Treatment Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "30 Spf", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "360 By Qur Lemon Verbena Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "360 By Qur Spanish Lime Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "360 By Qur Vanilla Lace Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "360 By Qur Watercress Mint Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "365 Everday Value Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "365 Everyday Value",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "365 Everyday Value Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "365 Everyday Value Arnica", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "365 Everyday Value Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "365 Everyday Value Be Well Cough Ease", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "365 Everyday Value Be Well Cough Ease For Kids", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "365 Everyday Value Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "365 Medicated Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "365 Whole Foods Market Anticavity/Antigingivitis Mouthwash", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "365 Whole Foods Market Antigingivitis Mouthwash", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "37.4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "3Lab Aqua Bb Spf 40 Broad Spectrum 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Aqua Bb Spf 40 Broad Spectrum 02", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Aqua Bb Spf 40 Broad Spectrum 03", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Hydra Day Spf 20 Broad Spectrum Water-Based Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Perfect Bb Spf 40 Broad Spectrum 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Perfect Bb Spf 40 Broad Spectrum 02", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Perfect Bb Spf 40 Broad Spectrum 03", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Perfect Lite Sunscreen Spf 30 Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Perfect Sunscreen Spf 50 Plus Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3Lab Ww Day Spf40 Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3M Avagard", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3M Avagard D", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3M Avagard Foaming Instant Hand Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3M Cavilon Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3M Cavilon Durable Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3M Duraprep Surgical", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3M Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3M Skin And Nasal Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "3X Medicated Mouth Sore Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "3X Medicated Toothache And Gum Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Detox-Virus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Hour Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Hour Power Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4 Kidney", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Canker Sore Soothing Dots", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Cold And Cough Day And Night Grape Flavor", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Cold And Cough Daytime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Cold And Cough Daytime And Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4 Kids Cold And Cough Nighttime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Cold And Cough Nighttime Natural Grape Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Cold And Mucus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4 Kids Cold And Mucus Nighttime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Cold N Cough Day And Night Combo Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4 Kids Cold N Cough Day And Night Value Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4 Kids Cold N Cough Grape Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Cold N Mucus Day And Night Value Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4 Kids Complete Allergy Daytime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Complete Cold And Mucus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "4 Kids Earache Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "4 Kids Leg Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Oral Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Oral Pain Relief Nighttime", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Pain Relief Grape", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Sore Throat Grape", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Kids Stuffy Nose And Sinus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4 Lidocaine Topical Anesthetic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4 Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4 Way", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "4 Weed Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "40% Oxygen/Nitrogen Mix", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "400 Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4007 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4013 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4017 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4019 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4022 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4032 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4065 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4066 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4067 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4068 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4072First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4074 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4078 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4079 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4100 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4109 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4111 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4114 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4115 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4116 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4117 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4118 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4125 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4128 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4129 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4130 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4131 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4132 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4133 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4134 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4135 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4136 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4137 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4138 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4139 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4140 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4141 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4142 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4143 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4144 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4145 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4146 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4147 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4148 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4149 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4150 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4151 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4152 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4153 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4154 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4155 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4156 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4157 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4158 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4159 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4161 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4162 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4163 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4164 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4165 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4166 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4167 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4168 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4169 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "4171 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4172 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4173 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4174 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4175 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4176 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4178 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4183 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4184 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4185 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4186 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4187 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4188 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4189 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4190 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4191 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4192 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4193 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4195 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4196 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4197 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4198 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4199 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4200 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4201 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4202 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4203 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4204 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4205 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4206 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4207 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4208 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4209 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4210 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4211 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4212 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4213 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4214 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4215 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4216 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4217 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4218 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4219 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4220 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4221 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4222 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4223 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4224 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4225 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4226 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4227 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4228 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4229 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4230 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4231First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4232 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4233 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4234 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4235 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4236 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4237 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4238 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4239 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4240 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4241 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4242 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4243 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4244 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4245 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4246 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4247 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4248 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4249 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4250 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4251 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4252 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4253 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4254 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4255 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4256 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4257 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4258 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4259 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4260 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4261 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4262 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4263 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4264 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4265 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4266 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4267 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4268 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4269 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4270 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4271 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4272 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4273 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4274 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4275 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4276 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4277 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4278 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4279 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4280 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4281 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4282 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4283 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4284 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4285 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4286 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4287 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4288 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4289 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4290 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4291 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4292 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4293 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4294 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4295 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4298 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4299 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4300 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4301 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4302 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4303 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4304 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4305 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4306 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4307 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4308 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4309 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4310 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4311 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4312 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4313 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4316 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4317 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4318 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4319 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4320 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4321 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4322 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4323 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4324 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4325 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4326 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4327 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4328 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4329 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4330 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4331 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4332 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4333 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4334 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4335 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4336 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4337 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4338 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4339 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4340 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4342 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4344 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4345 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4346 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4347 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4348 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4349 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4350 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4351 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4352 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4353 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4354 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4355 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4356 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4357 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4358 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4359 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4360 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4361 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4362 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4363 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4364 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4365 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4366 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "4367 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4368 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "4369 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4370 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4371 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4372 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4373 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic; Oral; Topical" }] }] },
    { Medication: "4374 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4375 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4376 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4378 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4379 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4380 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4381 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4382 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4383 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4384 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4385 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4386 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4387 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4388 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4389 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4390 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4391 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4392 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4393 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4395 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4396 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4397 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4398 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4399 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4400 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4401 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4402 First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "4718 Enema, Mineral Oil", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "4Jointz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4Oz Medicated Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4Rlf Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "4X Medicated Gum Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4X Medicated Severe Toothache And Gum Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4X Medicated Severe Toothache And Gum Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4X Medicated Toothache And Gum Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Oral" }] }] },
    { Medication: "4X Medicated Toothache And Gum Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "5 Acne Med", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5 Day", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5 Grass Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "5% Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "5% Minoxidil Topical Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5-Htp Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "5-In-1 Tinted Face Sunscreen - Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5-In-1 Tinted Face Sunscreen - Golden Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5-In-1 Tinted Face Sunscreen - Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5-In-1 Tinted Face Sunscreen - Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "50 Percent Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "50% Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "50% Isopropyl Alcohol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "50% Magnesium Sulfate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "50% Rubbing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "50Ct Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "50Ml Lychee Rose Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5206 Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5247Op Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5253 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5257 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5259 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5281 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5298 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5299 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5406 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5413 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5414 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "574H Cell Care", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "59Ml Citrus Delight Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "59Ml Lychee Rose Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "59Ml Summer Verbena Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "5Ewa5 Antibacterial Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "5G Colgate Plus Toothbrush Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "5G Colgate Plus Toothbrush Plus Floss Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "6 Grass Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "6 Liver", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "6% Hetastarch In 0.9% Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "60 Second Fluoride Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "6000X Elemental Screen Broad Spectrum Spf30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "62% Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "62% Ethyl Alcohol Antiseptic Nasal", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "62% Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "65% Alcohol Hand Sanitizer (No Water)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7 Eleven Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7 Eleven Hand Sanitizer Mountain Spring Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7 Grass Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "7 Heavy Metal Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "7 Select", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "7 Select Cold Sore Symptom Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7 Select Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7 Select Oral Pain Maximum Strength Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7 Select Redness Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "7 Select Sport Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7 Standardized Grass Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "7-Select After Sun Lidicaine Hcl Pain-Relieving With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7-Select Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "7.5% Sodium Bicarbonate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "70 Isopropyl Alcohol First Aid Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Alcohol Hand Sanitizer (No Water)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Alcohol Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Alcohol/Alcool Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Ethanol 16Oz.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Isopropanol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Isoproplyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "70% Isopropyl Alcohol",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "70% Isopropyl Alcohol First Aid Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Isopropyl Alcohol With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "70% Isopropyl Rubbing Alcohol",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "70% Isopropyl Rubbing Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Pana Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70% Rubbing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "70%Alcohol Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "701 Dieda Zhengtong Yaogao Medicated", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "7030 Calcium Gold", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "7030 Calcium Premium", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "71% Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75 Alcohol Antiseptic Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75% Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75% Alcohol Antiseptic Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75% Alcohol Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "75% Alcohol Disinfectant Cleaning Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75% Alcohol Disnfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75% Alcohol Disposable Disinfectant Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "75% Alcohol Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "75% Alcohol Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75% Alcohol Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75% Alcohol Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "75% Alcohol Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "75% Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Extracorporeal" }, { Route: "Topical" }] }] },
    { Medication: "75% Ethyl Alcohol Disinfecting Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "75% Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel, Metered", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "75%Alcohol Disinfectant",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Extracorporeal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Extracorporeal" }] },
        ],
    },
    { Medication: "75%Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "8 Fungal Yeast Infection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "8 Hour Arthritis Pain", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "8 Hour Pain Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "8 Southern Grass Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "8.4% Sodium Bicarbonate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "80% Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "80% With Denaturant And Fragrance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "8Hr Arthritis Pain", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "8Hr Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "8Hr Muscle Aches And Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "8Oz Hand Sanitizer W/Pump Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "8Oz Hand Sanitizer With Pump", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "8Oz Instant Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "9 Southern Grass Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "9 Southern Grass Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "9 Spleen Pancreas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "9 Tree Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "90013 Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "90018 Sanitizer Pen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "90041 Sanitizer Stylus Pen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "9054 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "91% Isopropyl Alcohol",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "91% Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "911", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "99 Percent Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "99 Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "A And D",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "A And D First Aid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A And D Original", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A Perfect World Age Defense Eye With White Tea Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A Perfect World Spf 40 Age Defense Broad Spectrum Moisturizer With White Tea", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A-1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-10", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-11", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-12", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-13", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-14", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-15", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-16", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-17", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-18", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-19", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-20", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-21", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-22", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-23", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-24", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-25", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-26", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-27", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-28", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-29", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-30", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-31", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-32", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-33", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-34", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-35", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-36", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-4", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-5", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-6", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-7", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-8", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-9", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A-L-O Liquid Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "A-Med", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "A-Med Brand First Aid Eye And Skin-Rinse", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "A.M", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A01", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A02", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A03", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A04", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A05", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A06", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A09", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A1 American Defender Collection", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A11", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A12", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A13", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A14", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "A24 Naturalism Sensitive Skin Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A2A Anti-Fungal Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A2A Arthritis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "A2A Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aape Continuous Renewal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aape Continuous Renewal Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aape Continuous Renewal Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aape Continuous Renewal Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aape Hair Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aape Nutrient Facial Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aape Skin Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aarica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aawesome Conditioner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aawesome Scalp Essence", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aawesomeshampoo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ab 3000", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Abacavir",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Abacavir And Lamivudine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abacavir Sulfate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abacavir, Lamivudine And Zidovudine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abatuss Dmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Abc Arbonne Baby Care",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Abc Arbonne Baby Care Sample Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Abc Supplies Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Abdominal Cramps", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abdominal Pain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abecma", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Abee Med", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Abejas Y Viboras Brand Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Abejas Y Viboras Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Abelcet", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Abella", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Abelmoschus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Abies Canadensis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abies Nigra", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abilify", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abilify Maintena", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Abilify Mycite", DoseTypes: [{ DoseType: "Tablet With Sensor", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Abiraterone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Abiraterone Acetate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aboniki Balm - Topical Pain Relief Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Above Hand Sani Antiseptic", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Above Hand Sanitizer Antiseptic", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Abrasa", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Abraxane", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Abreva", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Abridge Cold Sore Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Abrotanum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Absinthium", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Absoclean-S Sanitizing Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absolue Premium Bx Sunscreen Broad Spectrum Spf 15 Absolute Replenishing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "Absolutely Natural Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absolutely Natural Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absolutely Natural Broad Spectrum Spf 8 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absolutely Natural Cherry Blossom Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absolutely Natural Rosemary And Mint Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absorbine Jr", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absorbine Jr Plus Ultra Strength Pain", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absorbine Jr.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absorbine Jr. Lidocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absorbine Jr. Plus Knee", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absorbine Jr. Plus Pain Relief Micro", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Absorica", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Absorica Ld", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ac Care Bees Sun Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ac Fast", DoseTypes: [{ DoseType: "Capsule, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acacia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Acacia Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Academy Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Academy Sport Smart Care Kids Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acaifusion Lip Balm Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acam2000", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Acamprosate Calcium", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acanya", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acarbose", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Accessory Workshop Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Accident - Injury Rescue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acclean Chlorhexidine Gluconate 0.12% Oral Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acclean Foam Fluoride 60 Second Application", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Acclean Plus", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Accolate", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Accrufer", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Accucaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration" }] }] },
    { Medication: "Accumed Antibacterial Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Accupril", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Accurate Labs Antiseptic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Accuretic", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Accutane", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acd", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Acd Blood-Pack Units (Pl 146 Plastic)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Acd-A", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Acebutolol Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acer Negundo Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Acer Rubrum Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Acer Saccharum Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Acetadote", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Acetaldehyde", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaldehyde Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acetaminophen",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acetaminophen (Red)", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen - Apap 8 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen - Apap Arthritis", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen 250Mg Aspirin 250Mg Caffeine 65Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen 325 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen 325Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen 500 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen 500Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen 500Mg Caffeine 60Mg Pyrilamine Maleate 15Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen 80 Mg Fruit Chew", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen And Codeine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acetaminophen And Codeine Phosphate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acetaminophen And Diphenhydramine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Aspirin And Caffeine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Aspirin Caffeine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Caplets", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Caplets, 500 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Congestion And Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acetaminophen Diphenhydramine Hcl",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acetaminophen Easy Tabs", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Extended Release", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Extended-Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Extended-Release Tablets, 650 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Extra Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Gelcaps", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acetaminophen Pm",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acetaminophen Pm Rapid Release", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Rapid Release", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen Regular Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Aspirin And Caffeine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Aspirin, Caffeine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Caffeine, Dihydrocodeine Bitartrate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Chlorpheniramine Maleate, Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Dextromethorphan Hbr, Guailfenesin,Phenlyephrine Hcl", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Dextromethorphan Hbr, Phenylephrine Hcl, Doxylamine Succinate", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Dextromethorphan Hydrobromide, Doxylamine Succinate", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Dextromethorphan Hydrobromide, Guaifenesin, Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Dextromethorphan Hydrobromide, Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Diphenhydramine Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Diphenhydramine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Guaifenesin, Dextromethorphan Hbr, Phenylephrine Hcl", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetaminophen, Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetamiophen", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acetazolamide",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acetazolamide Extended-Release", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acetic Acid",
        DoseTypes: [
            { DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] },
            { DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] },
        ],
    },
    { Medication: "Aceticum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetylcholine Chloride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acetylcholine Chloride Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acetylcysteine",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Oral; Respiratory (Inhalation)" }, { Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral; Respiratory (Inhalation)" }] },
        ],
    },
    {
        Medication: "Aches - Pains",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aching Head Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acid Concentrate For Hemodialysis", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Hemodialysis" }] }] },
    {
        Medication: "Acid Controller",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Acid Gone Antacid",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acid Oxidation Potential Water Disinfection Wet Towel", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Acid Reducer",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acid Reducer 150", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acid Reducer Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acid Reducer Plus Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acid Reflux Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Acid Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acidil",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acidum Benzoicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acidum Phosphoricum Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Aciphex", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acitretin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acne",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acne - Cystic Acne", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acne Advanced Clarifying Hydrator", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Advanced Clarifying Masque", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Advanced Cleansing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Advanced Treatment System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acne And Blemish Control Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne And Line Correcting Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Blu Salicylic Acid Pads", DoseTypes: [{ DoseType: "Disc", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Calming Water Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Clarifying Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Cleanser W/ Salicylic Acid", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Cleansing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Cleansing Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Clearing Sulfur Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Acne Body Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Clarifying Body Spry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Clarifying Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Clarifying Cream Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Clarifying Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Outsmart Acne Clarifying Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Rapid Relief Acne Spot Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Control Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Deep Pore Cleansing Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Defense Daily Treatment Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Defense Face Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Defense Foaming Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Defense Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acne Drying", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Drying Topical", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Face And Body", DoseTypes: [{ DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Face And Body Wash", DoseTypes: [{ DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Face Wash", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Fighting Foundation Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Fighting Putty Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Fighting Spot Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Fighting Spot With Aloe Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Forte Acne Treatment Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Free", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Free Blackhead Removing Scrub 2 Salicylic Acid With Charcoal", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Free Free 24 Hr Acne Clearing System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acne Free Oil Free Acne Cleanser 2.5 Benzoyl Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Free Sensitive Skin 24 Hr Acne Clearing System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acne Free Severe Acne 24 Hr Acne Clearing System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acne Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acne Lotionsulfur", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Med", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Oil Control Primer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Patchs", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Acne Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acne Relief Calming Treatment And Hydrator", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Relief Maximum Strength Spot Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Relief Retinol Treatment And Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Reparatif", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Roll On", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Acne Scrub",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Acne Skin Eruptions", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acne Solution", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Acne Solutions",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Acne Solutions Clarifying", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Solutions Liquid Makeup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Solutions Oil Control Cleansing Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Spot Repair", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Acne Spot Treatment",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Acne Therapy", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acne Treatment",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Acne Treatment Daily Cleansing Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Treatment Daytime", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Treatment Maximum Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Treatment Pads", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Treatment System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acne Treatment With Sulfur", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Vanishing Gel- 10% With Sulfur", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Vanishing Gel- 5%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Vanishing Gel-10%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Warrior Clearing Astringent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Acne Wash",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Acne Wash Facial Cleanser Pink Grapefruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Wash Facial Cleanser Tea Tree Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Wipeout All Day Breakout Control", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Wipeout Clear Pore Oil-Free Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acne Wipeout Clinical Acne System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acne/Pimple Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acnefree Dermatology Inspired Care Blackhead Removing Scrub Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acnesic Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acnetone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aconite",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Aconite Napellis", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aconitum", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aconitum Belladonna",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aconitum Bryonia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aconitum Bryonia Adult", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Aconitum Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aconitum E Tub. 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aconitum Lycoctonum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aconitum Napellus",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Aconitum Napellus 200C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aconitum Napellus Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acquaint Antibacterial Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Acremonium Strictum",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Acs Antiseptic Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acs Foaming Antibacterial Handsoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acs Golden Touch Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Anticavity Fluoride Arctic Blast", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Anticavity Fluoride Cinnamon", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Anticavity Fluoride Iced Berry", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Act Anticavity Fluoride Iced Sweet Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Act Anticavity Fluoride Kids Bubblegum", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Anticavity Fluoride Kids Groovy Grape", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Anticavity Fluoride Kids Wild Watermelon", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Anticavity Fluoride Mint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Braces Care Anticavity", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Act Kids Anticavity Fluoride",
        DoseTypes: [
            { DoseType: "Paste", Routes: [{ Route: "Topical" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Act Kids Anticavity Fluoride Groovy Grape", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Kids Anticavity Fluoride Pineapple Punch", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Restoring Anticavity Fluoride Cool Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Restoring Anticavity Fluoride Mint Burst", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Act Total Care Anticavity Fluoride Fresh Mint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Total Care Anticavity Fluoride Icy Clean Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Total Care Dry Mouth Anticavity Mouth", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Total Care Sensitive Anticavity Mint Mouth", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Act Whitening Anticavity Gentle Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Actaea Racemosa", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actaea Spicata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acteane", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Actemra",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Actemra Actpen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Acthar", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Acthib", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acthrel", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Acti Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Acticlate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Acticon",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Actidogesic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actidogesic Df", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actidom Da", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actidom Dmx", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actidose", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actidose Aqua", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actimmune", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Actinel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actinel Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actinel Pediatric", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Actiq", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral; Transmucosal" }] }] },
    {
        Medication: "Actisep",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Activa", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Activase", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Activate Joint And Muscle Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Activated Charcoal", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Activcin", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Active", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Again", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Argan 365 Spf 30 Broad Spectrum Activated Argan Complex", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Guard", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Guard Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Guard Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Guard Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Hydrogen Professional Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Radiance Day Moisture Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Active Sport", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Activella", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Activessence", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Activex Antibacterial Hand Sanitizer Active", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Activex Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Activice",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Activive", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Activmend", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Activmend Pro", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Actonel", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Actoplus Met",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Actos", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acular", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Acular Ls", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Acunistat-30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acunistat-60", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acunivive 15", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Acunivive 30", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Acunivive 60", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Acunivive 90", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Acunol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Acuplus Advanced Therapy For Pain And Recovery", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acuplus Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acure Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acusine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Acute-Kare", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Acuvail", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Acuvue Theravision With Ketotifen", DoseTypes: [{ DoseType: "Drug-Eluting Contact Lens", Routes: [{ Route: "Intraocular" }] }] },
    {
        Medication: "Acyclovir",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Acyclovir Sodium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Acyclovix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Aczone", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adacel", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Adakveo", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Adapalene",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Adapalene And Benzoyl Peroxide", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adapalene Topical Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adaptasun Sea And Tropics Protective Face Care Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adaptasun Sun Care Sea And Tropics Face Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adaptopath", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adasuve", DoseTypes: [{ DoseType: "Aerosol, Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Adbry", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Adcetris", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Adcirca", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adclean - Medical", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adclean Medical Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Add", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Add Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Addastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Adderall",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Addiclenz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Additive Formula 3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Additivestat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Additox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Addyi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adefovir Dipivoxil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adempas", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adenine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adenocard", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Adenosine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Adenosine Injection, 6Mg/2Ml (3Mg/Ml) Vial", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Adenovirus Type 4 And Type 7 Vaccine, Live", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Adhansia Xr", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adhesive Remover Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adios Antiseptic Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Adipex-P",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Adirondack Botanical Organics Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adjustment Disorders Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adlarity", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    {
        Medication: "Adlyxin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Admelog", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Adonis Crataegus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adonis Crataegus Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adoptaplatoon", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adore Bubble Gum Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adore Cherry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adore Lavender Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adore Lemonade Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adore Peppermint Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adore Watermelon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adr-Bal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenal And Dhea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenal Cortex Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenal Energy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenal Formula", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Adrenal Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenal Medulla Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenal Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenal-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenalin", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Adrenalin (Epinephrine)", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Adrenalin(R)", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Adrenalinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenapath", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adrenoplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adreview", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Adriamycin",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Adriana 12Oz Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adriana 12Oz Hand Sanitizer -Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adriana 8Oz Hand Sanitizer - Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adriana 8Oz Hand Sanitizer -Original", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adriana Clip On Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adriana Gold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adsol Red Cell Preservation", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Adsol Red Cell Preservation Solution System In Plastic Container (Pl 146 Plastic)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aduhelm", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Adult Acnomel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adult Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Allergy Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Adult Aspirin Regimen",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Adult Chewable Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Cough And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Cough And Chest Congestion Relief Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Cough Relief Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Dm Max Cough And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Infuvite Multiple Vitamins", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Adult Low Dose", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Low Dose Aspirin", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Low Dose Aspirin Enteric Coated", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Adult Low Dose Enteric Coated Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Adult Low Strength Aspirin", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Tussin Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Tussin Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Tussin Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Wal Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adult Wash Gloves With Skin Protectant", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Adults Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advaced First Aid A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advair", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Advair Hfa", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Advance Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advance Hand Sanitizer Moisturizing Formula With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advance Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advance Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advance Relief Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Advance Topical Anesthetic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Advance White",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Advance White Extreme Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Advanced",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Advanced Acne Therapy", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Alcohol Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Alcohol Gel Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Alcohol Gel Sanitizer Iii", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Advanced Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Antacid Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Antacid Mint", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Antacid Regular Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Anti Aging Unscented With Sunscreen Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Anti-Itch Therapy", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Anti-Wrinkle Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Ceramide Lift And Firm Day Cream", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Cherry Flavored Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Eye Relief Dry Eye Rejuvenation", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Advanced Eye Relief Eye Wash", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Advanced Eye Relief/ Redness Instant Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Advanced Eye Relief/ Redness Maximum Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Advanced First Aid Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Formula Estro-Life", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Formula Progesto-Life", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Gel Hand Sanitizer Refreshing Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Gel Hand Sanitizer Soothing Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Guard", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Advanced Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Advanced Hand Sanitizer Foam With Aloe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Homeopathic Cold-Eeze", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Instant Hand Sanitizer Alcohol Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Numb Topical Anesthetic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Petrolatum", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Protection Sunscreen Spf-30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Psoriasis Therapy", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Regular Strength Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advanced Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Advanced Relief A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Seal Barrier Plus Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Seborrheic Dermatitis Therapy", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Time Zone Age Reversing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advanced Whitening Anti Cavity Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Advantage Chemical Premium Antibacterial Foaming Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advantage Sanitizing 900 Wipes Per Roll", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Advate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Advent Calendar 2021", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Adventure First Aid .5 Tin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Adventure First Aid Water Resistant", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Adventure Medical Kits Dental Medic", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral; Topical" }] }] },
    {
        Medication: "Advil",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Advil Allergy And Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Allergy Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Allergy Sinus", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Advil Cold And Sinus",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Advil Dual Action With Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Liqui-Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Liquigels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Menstrual Pain", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Migraine", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Multi-Symptom Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Advil Pm",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Advil Pm- Diphenhydramine Citrate And Ibuprofen Tablet, Coated", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Sinus Congestion And Pain", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Advil Sinus Congestion And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adwe Childrens Pain Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adwe Dye-Free Childrens Allergy", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adwe Feverx Acetaminophen Caplets, 500 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Adynovate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Adzenys Xr-Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aekyung Luna Long Lasting Tip Concealer Big Light Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aekyung Luna Long Lasting Tip Concealer Big Natural Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aekyungluna Long Lasting Conceal Fixing Cushion 21C Cool Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aekyungluna Long Lasting Conceal Fixing Cushion 21W Warm Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aekyungluna Long Lasting Conceal Fixing Cushion 23M Medium Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aekyungluna Long Lasting Conceal Fixing Cushion Cool Ivory 21C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aekyungluna Long Lasting Conceal Fixing Cushion Medium Beige 23M", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aekyungluna Long Lasting Conceal Fixing Cushion Warm Beige 21W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aemcolo", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Rectal; Topical" }] }] },
    { Medication: "Aerisguard Actisan Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aerium Clean Clear Sanitizer Gel 500Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aero Care Handy Quat Antiseptic Handwash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aerocleanse Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aerosoothe", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aerotab Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aerowipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aesculus",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aesculus (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aesculus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aesculus Glabra", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aesculus Hipp Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aesculus Hippocastanum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aethusa Cynapium",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aevi Here And Now Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aevi Whenever Wherever Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aewajin Hasuoshampoo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afassco 1% Hydrocortisone", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afassco Burn Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afassco First Aid Burn Cream", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afassco Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afassco Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afassco Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afco 5515 Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Affex Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Affex Antimicrobial Foaming Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Affex Care Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Affex Care Ultra Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Affex Foaming Antimicrobial Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Affinia", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Affinia Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Affirmcare Scalp Therapy",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Afia Alcohol-Free Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afia Foaming Anti-Bacterial Hand Cleaner", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Afia Foaming E2 Sanitizing Hand Cleaner", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Afinitor",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Afirmelle", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Afluria Quadrivalent", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Afrezza",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    { Medication: "African Black Acne-Prone Face And Body Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "African Black Eczema Therapy", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "African Black Facial Cleansing Bar Anti-Acne Clarifying", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "African Opium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Afrin",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }, { Route: "Topical" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Afrin Allergy Sinus Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Afrin No Drip", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Afrin No Drip Night", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Afrin No-Drip", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Afrin Nodrip Allergy Sinus Night", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Afrin Original", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Afrin Original Nasal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Afstyla", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aftaseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aftate Athlete Foot Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "After Bite Advanced", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "After Bite Natural", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "After Bite Sensitive", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "After Bite Wipe",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "After Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "After Cuts And Scrapes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "After-Bite", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aftera", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Afterpill", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Afterplan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aftersun", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aftertest Pain Relief", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ag-X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Agaricus E Pl. Tota 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Agaricus Muscarius",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Agaricus Muscarius 30", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Agaricus Muscarius Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Agaricus Phosphorus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Age 20S Signature Essence Cover Pact Long Stay 13 Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age 20S Signature Essence Cover Pact Long Stay 23 Medium Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age 20S Signature Essence Cover Pact Moisture 13 Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age 20S Signature Essence Cover Pact Moisture 23 Medium Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age 20S Signature Essence Cover Tone-Up Base 01 Pink", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age 20S Signature Essence Cover Tone-Up Base 03 Purple", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age Defiance Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Age Defying Hydrating Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age Defying Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age Intervention Duality", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age Intervention Duality Md", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age Renewal Firming", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age Renewal Firming And Hydrating Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age20S Signature Essence Cover Pact Intense Cover 13 Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age20S Signature Essence Cover Pact Intense Cover 21 Light Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age20S Signature Essence Cover Pact Intense Cover 23 Medium Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age20S Signature Essence Cover Pact Long Stay 21 Light Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age20S Signature Essence Cover Pact Moisture 21 Light Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age20S Signature Essence Cover Tone-Up Base 01 Pink", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age20S Signature Essence Cover Tone-Up Base 02 Green", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Age20S Signature Essence Cover Tone-Up Base 03 Purple", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ageless Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ageless Solar Defense (Spf 30)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ageloc Lumispa Treatment Cleanser Acne", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aggrastat", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Aging Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Agingeye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Agnus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Agnus Castus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Agnus Castus E Sem. 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Agraphis Nutans", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Agri-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Agrobac Pure Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Agrylin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ahava Active Deadsea Minerals", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ahava Moisturizing Hand Sanitizing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ahava Protecting Body Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ahava Protecting Moisturizing Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ahealon", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ahh Mold Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Ai Kungang", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ai Miasm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aid And First Antiseptic Cleansing Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aid And First Sting Relief Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aiken Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ail-1", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ailanthus Glandulosa", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ailanthus Glandulosus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ailgeno", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aim", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Aim Cavity Protection",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Aim Tartar Control", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Aim Whitening", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Aim-X Antibacterial Hand Cleansing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aimersie Hand Santizer 500Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aimovig",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Air", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Air 90% Carbon Dioxide 10%", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Air And Water Christmas Berry Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Air And Water Sugar Plum Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Air Canada Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Air Co Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Air Compressed", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Air Pollution Detox", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Air Power", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Air Shield Sanitizer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Air Sinsinpas-Ex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Air, Compressed", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Airborne", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Airdefender All Day Antibacterial Sanitizing Solution Refill Pack", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Airdefender Daily Antibacterial Sanitizing Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Airdefender Daily Antibacterial Sanitizing Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Airduo Digihaler", DoseTypes: [{ DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Airduo Respiclick", DoseTypes: [{ DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Aire-Master",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aire-Master Sanitizer Gel Vit E/A", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Airline Dental Products With Crest And Manual Toothbrush", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Airline Pesticide Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Airmit Ace", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Airocare", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] }] },
    {
        Medication: "Airosol 75 Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Airway Ease",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aiwina Sanitizing Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ajovy", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Ak-Fluor", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ak-Poly-Bac", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ak47 Cooling Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ak47 Menthol Cooling", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ak47 Menthol Cooling Cream Packet", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ak47 Menthol Warming", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aklief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Akovaz", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Akten", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Akutur", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Akwa Daystar Shade", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Akynzeo",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Al-Inflam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Al-Shields", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al-Shields Antibacterial Hand Wipe With Fresh Aloe Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al-Shields Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al-Shields Antibacterial Hand Wipes With Fresh Aloe Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al-Shields Disposable Antibacterial Hand Wipes With Clean Lemon Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al-Shields Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al00378 Alba Cool Sport Sunscreen Spf50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0038100 Alba Hw Sunscreen Coconut Milk Spf50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0038100 Alba Ve Sunscreen Fragrance Free Spf50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0038200 Alba Ve Sunscreen Kids Spf50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0038400 Alba Botanica Fast Fix Sun Spf30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0038502 Alba Tropical Fruit Kids Sunscreen Spf40", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0039006 Alba Sport Sunscreen Fragrance Free Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0040400 Alba Ve Mineral Sunscreen Spf35", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0040500 Alba Ve Mineral Sunscreen Herbal Fresh Spf35", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0040600 Alba Cooling Aloe Burn Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0043600 Alba Botanica Ve Herbal Healing Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0043700 Alba Botanica Baby Mineral Sunscreen Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0043800 Alba Botanica Sheer Shield Sunscreen Ff Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0076001 Alba Botanica Acnedote Deep Pore Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al00761 Alba Botanica Acnedote Face And Body Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0076900 Alba Botanica Acnedoteclearing Peel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0078101 Alba Botanica Good And Clean Acne Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0082706 Alba Botanica Hw Sunscreen Green Tea Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al0083103 Alba Hawaiian Dry Coconut Sunscreen Spf15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Al00933 Alba Botanica Fast Fix Papaya Anti Acne Sheet Mask", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ala-Hist Ir", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ala-Scalp", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ala-Septic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alabaster Beige Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alabaster Beige Perfecting Makeup Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alabaster Perfecting Makeup Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alacort", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alahist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alahist Cf", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alahist D", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alahist Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alan623 Hand Clean Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alaska Airlines", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alaska Beauty Elements Fresh Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alaska Beauty Fresh Hand Antiseptic Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alaska Beauty Hand Antiseptic Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alavert",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Alavert Allergy Sinus D-12", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alaway", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Alaway Preservative Free", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Alba Botanica Acnedote Oil Control", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Baby Mineral Sunscreen Spf45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Even Advanced Moisturizer Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Fast Fix For Pimple", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Hw Sunscreen Aloe Vera Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Hw Sunscreen Green Tea Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Kids Mineral Sunscreen Ff Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Kids Sunscreentropical Fruit Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Mineral Sport Ff Sunscreen Spf45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Mineral Sunscreen Ff Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Pure Lavender Sunscreen Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Sensitive Ff Sunscreen Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alba Botanica Ve Daily Shade Spf15 Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Albendazole",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Albenza", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Albertson Lidocaine, Menthol Pain Relief Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Albertsons Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Albuforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Albuked", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Albumin (Human)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Albuminar-25", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Albuminex", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Albuminuriaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alburx", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Albutein", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Albuterol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Albuterol Sulate", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Albuterol Sulfate",
        DoseTypes: [
            { DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intrabronchial" }, { Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Albuterol Sulfate Hfa", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Albuterol Sulfate Inhalation Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Alcaine", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Alcare Antiseptic Handrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcare Elevate Antiseptic Handrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcare Enhanced Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcare Extra Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcare Foamed Antiseptic Handrub", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcare Or Foamed Antiseptic Handrub", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcare Plus Foamed Antiseptic Handrub", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alchemilla Comp.", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alclene Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alclometasone Dipropionate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alco Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alco San", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alco-Cloth", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alco-San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcogenix Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol 80% Topical Antiseptic, Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol Antiseptic",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol Antiseptic 70% Topical Solution", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Antiseptic 80", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Antiseptic 80 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Antiseptic 80 Topical - Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol Antiseptic 80%",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol Antiseptic 80% Topical Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Antispetic 62% Topical Solution Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Based Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol Based Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Alcohol Cleansing",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Disinfecting Cleaning Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Disinfecting Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Formula Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Free", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alcohol Free Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Free Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alcohol Free Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol Free Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol Free Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Free Refreshing Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alcohol Free Sanitizing Wipes Citrus Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Free Sanitizing Wipes Lemon Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Free Sanitizing Wipes Unscented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Global Care (Ethyl)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Global Care (Isopropyl)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol Hand Sanitizer 80%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Extracorporeal" }, { Route: "Topical" }] }] },
    { Medication: "Alcohol Isopropyl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol Pad",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Alcohol Pads",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Alcohol Prep",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Alcohol Prep Pad",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Sponge", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol Prep Pad 70 Isopropyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pad Large Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pad Medium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pad Medium Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pad Non-Sterile", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pad Single Use", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pad Sterile", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pad- Isopropyl Alcohol Swab", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pad-Large", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol Prep Pads",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Extracorporeal; Topical" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol Prep Pads Large Sterile", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pads Medium", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Prep Pads Medium Sterile", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Preps Sterile", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Safeguard", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alcohol Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Sterile", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Swab", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Swab Sticks", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Swabstick", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Swabstick - Antiseptic", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Wet Tissue", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol Wet Wipe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alcohol Wet Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Extracorporeal; Topical" }, { Route: "Topical" }] },
        ],
    },
    {
        Medication: "Alcohol Wipe",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Alcohol Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alcohol Wipes 75% Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol-Free Anticavity", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alcohol-Free Antiseptic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol-Free Hand Sanitizing Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol-Free Hand Sanitizing Wipes 800Ct", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcohol-Free Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcoholada Gel Pain Relieving Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcoholada Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcon Tears Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Alcoshield", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcosm Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcosm Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alcostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aldactazide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aldactone", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aldana Skin Life - Sunscreen Broad Spectrum Spf 55 Water Resistant (80 Mins.)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aldara", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alder, White Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Aldi Lacura Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aldicom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] }] },
    { Medication: "Aldurazyme", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Alecensa", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alegria Neo", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alendronate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Alendronate Sodium",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aleroff", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Alert",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Alert Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alertness Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aletris Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aletris Farinosa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aleva Sani Gel Alcohol Antiseptic 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alevai Stem Cell Anti-Dandruff", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alevai Stem Cell Anti-Dandruff Conditioner", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aleve",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aleve Arthritis Pain Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aleve Back And Muscle Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aleve Headache Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aleve Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aleve-D Sinus And Cold", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aleve-D Sinus And Headache", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Alevex",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alex Md Phd Antidotal Skincare Daily Defense Tinted Mineral Anti-Aging Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alexander Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alfa Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alfalfa",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Alfalfa Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Alfentanil Hydrochloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Alferon", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Alfuzosin Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Algodefense Rides Multi Protectrice Multi-Protective Wrinkle Cream Sunscreen Broad Spectrum Spf20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Algomend Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Algonquin Hanclean Foam", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Algonquin Hansan", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alh Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alike Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alimta", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Alinia",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aliqopa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Aliskiren", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alive Prebiotic Balancing Moisturizer Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alivio", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    {
        Medication: "Alix Avien",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alka Seltzer Original", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka Seltzer Originial 1S Blister Pack", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka Seltzer Plus 1S Blister Pack", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka Seltzer Plus 2S Blister Pack", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka Seltzer Plus Cold", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka Seltzer Plus Cold Day And Night Effervescent", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka Seltzer Plus Maximum Strength Cough Chest Congestion Powerfast Fizz", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka Seltzer Plus Maximum Strength Sinus, Allergy And Cough Power Max Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka Seltzer Plus Severe Cold And Flu Powerfast Fizz", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Alka-Seltzer",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Alka-Seltzer Cool Action Extra Strength Reliefchews Mint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Cool Action Heartburn Relief", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Extra Strength", DoseTypes: [{ DoseType: "Granule, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Extra Strength Heartburn Reliefchews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Gold", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Hangover Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Heartburn", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Heartburn Plus Gas Reliefchews Tropical Punch", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Heartburn Relief", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Heartburn Relief Extra Strength", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Heartburn Relief Lemon Lime", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Heartburn Reliefchews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Heartburn Reliefchews Strawberry And Orange", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Kids Tummy Reliefchews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Original", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Original Flavor", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Cold And Cough", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Cold Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Cold Medicine Sparkling Original", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Cold Night", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Cold Orange Zest", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Cold Sparkling Original", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Cold Sparkling Original Powerfast Fizz", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Cough, Mucus And Congestion Day And Night Powermax", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Day And Night Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Alka-Seltzer Plus Day Severe Cold Plus Flu", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Cold And Cough Power Max Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Cough And Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Cough, Mucus And Congestion Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Cough, Mucus And Congestion Powermax", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Day And Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Day And Night Cold And Flu Powermax Gels", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Day Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Day Cold And Flu Powermax Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Night Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Night Cold And Flu Powermax Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Severe Sinus Congestion And Cough Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Severe Sinus, Allergy And Cough", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Sinus Congestion And Pain Powerfast Fizz", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Maximum Strength Sinus, Congestion And Pain Power Max Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Multi-Symptom Cold And Flu Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Night Severe Cold And Flu", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Severe Cold And Cough Powerfast Fizz", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Severe Cold And Flu Powerfast Fizz", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Severe Cold Night Powerfast Fizz", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Alka-Seltzer Plus Severe Cold Powerfast Fizz",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Alka-Seltzer Plus Severe Cold Powerfast Fizz Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Severe Cold Powerfast Fizz Night", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Severe Cold Powerfast Fizz Non Drowsy", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Severe Cough Mucus And Congestion", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Severe Cough Mucus And Congestion Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Plus Severe Sinus Cold And Cough", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alka-Seltzer Ultra Strength Heartburn Reliefchews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alkali Blite Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Alkeran", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alkindi", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "All A Eco Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Calm Clinical Redness Corrector Spf 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clean Natural Antibacterial Foaming Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clean Natural Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clean Natural Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clean Premium Hand Sanitizer Bergamot", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clean Premium Hand Sanitizer Blood Orange", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clean Premium Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clean Premium Hand Sanitizer Lemon Mint", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clean Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clear Exfoliating Face Wash", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clear Face Wash Acne Control", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Clear Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Day", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "All Day Allergy",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "All Day Allergy D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "All Day Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "All Day Allergy Relief D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "All Day Allergy-D",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "All Day Back And Muscle Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "All Day Err Day", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Day Moisture", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Day Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "All Day Pain Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "All Day Pain Relief Back And Muscle Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "All Day Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "All Day Sinus And Cold D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "All Flu", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "All Good", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Good Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Good Lavender Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Good Rose Geranium Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Good Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Hands Lychee And Asian Pear Moisturizing Hand Cleansing Gel With Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Hands Raspberry And Honeydew Moisturizing Hand Cleansing Gel With Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Hands Zingy Pineapple And Lime Moisturizing Hand Cleansing Gel With Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Hours Foundation Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Hours Primer Spf 18", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Natural Eye Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Natural Wound And Burn", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Night Pain Relief Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "All Nite", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "All Physical Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Purpose Cleaning And Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Purpose Hand Sanitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Safe Antiseptic Hand Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Safe Antiseptic Hand Wipe Fairmont", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Safe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Safe Hand Sanitizer Fairmont", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Stop Medicated Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "All Weather Spf 15 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Allantoin",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Allday 5000", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allegenal-M", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Allegra Allergy",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allegra Hives 24Hr", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allegra-D Allergy And Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aller Balance", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aller-Ben", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aller-Chlor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aller-Chord A", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aller-Chord F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allerfed", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergease", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergena",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergena Fragrance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergena Trees, Weeds And Grasses - Zone 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergena Trees, Weeds And Grasses - Zone 6 Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergic Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allergiemittel", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }, { Route: "Sublingual; Sublingual; Sublingual; Sublingual" }] }] },
    {
        Medication: "Allergies",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergiplex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Allergy",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy 24-Hr", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy And Congestion", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy And Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergy And Congestion Relief D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy And Sinus Headache", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergy And Sinus Pe",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy Antihistamine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Atlantic Mold Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Bee Gone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Allergy Bee Gone For Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Allergy D", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy D Childrens", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Desensitization", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Allergy Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Dyefree", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Escape", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Allergy Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Allergy Eyes Day And Night Relief Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Allergy Eyes Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Allergy Eyes Relief Nighttime Formula", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allergy Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Medicine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Mix", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Allergy Mix Las Vegas",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Allergy Mix Los Angeles",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Allergy Mix Phoenix",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy Multi Symptom", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergy Multi-Symptom",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy Multisymptom", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Allergy New England Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Plus Cold", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergy Plus Sinus Headache",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy Pollen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy Relief 24Hr", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief 25 Mg", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief Antihistamine", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief Caplet", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergy Relief D",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy Relief D 12", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief D12", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief Diphenhydramine Hci 25 Mg Antihistamine", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief Diphenhydramine Hci, 25 Mg Antihistamine", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief Diphenhydramine Hcl", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief Dye-Free Softgel", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief Homeopathic Pollinosan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Allergy Relief Plus Sinus Headache", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergy Relief-D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allergy Relief-D 24 Hour", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Relief-D24 Hour", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Rescue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy South East Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy Time", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy, Circle K", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allergy, Lil Drug Store", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allergycalm",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allerhale", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allermi Skin Savior", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allermilk", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allernest", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allerphenex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allerpoll", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allerpoll Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allerstat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allertone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allervarx", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alleviate", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Allg Essentials", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allg Essentials Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allg Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alli", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Allium Cepa",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allium Cepa Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allium Cepa Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Allium Cuprum Comp. Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Allium Sativum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Allopurinol",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Allopurinol Sodium", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Alloy", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alloy Antiperspirant And Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allpure Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allpure Hand Sanitizer Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allscale", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Allure Chemicals Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allure Chemicals Instant Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allure Chemicals Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allure Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allure Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allure Isopropyl Rubbing Alcohol With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allvino", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allwipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Allzital", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Almacone Antacid Antigas", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Almacone Double Strength", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Almay Age Smart Shade Cc Luminous Primer Spf 15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Anti Aging Lip Care Spf 20", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Clear Complexion Blemish Armor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Clear Complexion Blemish Healing Spot Concealer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Clear Complexion Concealer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Clear Complexion Liquid Makeup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Clear Complexion Makeup", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Clear Complexion Pressed Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Clear Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Health Glow 3-In-1 Makeup Spf 20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Healthy Biome Makeup", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Roll-On For Sensitive Skin - Fragrance-Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Smart Shade Anti-Aging Skintone Matching Makeup Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almay Smart Shade Skintone Matching Makeup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almond", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Almond Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Almost Powder Makeup Broad Spectrum Spf 18", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Almotriptan", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Almotriptan Malate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alnus Incana Ssp Rugosa Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Alo Therapeutic Massage", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alo Therapeutic Massage Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alocane", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alocane Emergency Burn", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alocane Emergency Burn Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alocane On The Go", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alocane Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alocane Severe Sunburn", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alocril", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Aloe",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aloe Barrier", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Chok Chok Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Coal 01", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Coal 02 Conditioner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Cort", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Ice", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Ice Sunburn Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Kote", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Socotrina", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aloe Socotrina Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aloe Soothing Moisture Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aloe Up",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aloe Up Coco-Mango", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Dark Tanning Oil Spf 4", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Kids Spf 50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Kids Sport Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Light Tanning Oil", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Medicated Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Medicated Lip Ice Sunscreen", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Natural Lip Ice Sunscreen", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Pro 15", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Pro 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Pro 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Pro Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Pro Spf 50 Advanced Formula", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Spf 30 Coco-Mango C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Spf 50 Coco-Mango C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Sport", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Sport Natural Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Sport Spf 30 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Sport Spf 50 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Up Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Vera Center Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Vera Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Vera Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Vera Hand Sanitizer W/Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Vera Leaf Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Vera Scent Anti Germ", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aloe Vesta",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aloe Vesta Clear Antifungal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Vesta Clear Barrier", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe Vesta Protective", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloe You", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloesafe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloesept Hs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aloetouch Protect", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alogliptin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alogliptin And Metformin Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alogliptin And Pioglitazone", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alomide", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Alopecia Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alophen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aloprim", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Alora",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Alosetron", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Alosetron Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Aloxi",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Alpet E2 Sanitizing Foam",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Alpet E3 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpet E3 Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpet E3 Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpet Q E2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpet Qe2 Sanitizing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpha", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpha Bac Stop E-2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpha Cf", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }, { Route: "Sublingual; Sublingual; Sublingual" }] }] },
    { Medication: "Alpha E-3", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpha E-3 Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpha Foaming E-2", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpha Handrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpha Handrub Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpha Sh", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Alpha-Caine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Periodontal" }] }] },
    { Medication: "Alpha-Pro", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Alphagan P", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Alphanate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Alphanine", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Alphapromed Alphacol Sterile Isopropyl Alcohol Preparation Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alpine Clenz Unscented Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alprazolam",
        DoseTypes: [
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Alprolix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Alprostadil", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravascular" }] }] },
    { Medication: "Alrex", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Alsoft V Professional Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alsoft W Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altabax", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altace", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Altachlore Sodium Chloride Hypertonicity", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Altafluor", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Altalube", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Altavera", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Alternaria", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Alternaria Alternata",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Alternaria Hormodendrum Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Alternaria Tenuis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Alternaria/Hormodendrum Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Alticotron", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Altidesp", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Altidome", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Altilan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Altipres", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Altipres-B", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Altitude Medical Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel, Metered", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Altituss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Altoprev", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Altor Safety 80% Alcohol Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altra Antimicrobial Foaming Hand Wash With 0.3% Pcmx", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altra Foaming Hand Sanitizer Fragrance Free With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altra Foaming Hand Sanitizer With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altra Hand Sanitizer With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altra Non-Alcohol Foaming Hand Sanitizer With Allantoin And Aloe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altreno", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Altrufuel Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alumen",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Alumier Md Acne Balancing Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Acne Clarifying Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Clear Shield Broad Spectrum Spf 42 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Hydrasmooth Non-Comedogenic Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Intensive Recovery", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Moisture Matte Broad Spectrum Spf 40 Sunscreen Amber", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Moisture Matte Broad Spectrum Spf 40 Sunscreen Ivory", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Moisture Matte Broad Spectrum Spf 40 Sunscreen Sand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Post Procedure Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Post Procedure Kit With Hydrocortisone", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Sheer Hydration", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alumier Md Sheer Hydration Tinted Moisturizing Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Alumina",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Alumina Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alumina Silicata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alumina, Magnesia, And Simethicone", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alumina, Magnesia, And Simethicone Oral Suspension Regular Strength", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aluminum Acetate Astringent", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aluminum Hydroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aluminum Hydroxide And Magnesium Carbonate", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aluminum Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alumizen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Alunbrig",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Alvesco", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Alvimopan", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Bisque", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Buff", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Cocoa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Deluxe Sample Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Golden Tan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Honey", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Ivory", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Natural", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Natural Tan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Shell", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Foundation Makeup Broad Spectrum Spf 15 Soft Ivory", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Always Golden Daily Moisturizer (Tinted)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Alyacen 1/35", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Alyacen 7/7/7", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Alymsys", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Alyq", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Alz Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Am-1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-10", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-11", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-12", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-13", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-14", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-4", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-5", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-6", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-7", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-8", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Am-9", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Amabelz", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amalgam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Amantadine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amantadine Hcl", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Amantadine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amaranthus Palmeri Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Amaranthus Retroflexus Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Amaranthus Spinosus Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Amaranthus Tuberculatus Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Amaryl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amass Basilisk Breath Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amass Basilisk Breath Sanitizer Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amass Botanics Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amass Four Thieves Sanitizer Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amass Lemon Tisane Botanic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amass Pseudo Citrine Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amass Pseudo Citrine Sanitizer Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amazapet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amazon Commercial Alcohol Free Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amazon Commercial Antibacterial Foaming Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amazon Commercial Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amazonian Clay 12-Hour Full Coverage Foundation Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amazonian Clay Bb Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amazonic Natural Sunscreen Spf 30 Broad Spectrum Uva/Uvb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amber", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amber Amethyst", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amber And Black", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ambi Even And Clear Exfoliating Wash Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ambi Even And Clear Facial Moisturizer Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ambi Skincare Even And Clear Daily Facial Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ambi Soothing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ambien",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ambisome", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ambling Plant Probiotics Aqua Role Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ambling Plant Probiotics Balance Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ambling Plant Probiotics Into Nature Essence I", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ambra Grisea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ambre Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ambrisentan", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ambrosia Acanthicarpa Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Ambrosia Artemisiaefolia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ambrosia Artemisiifolia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Ambrosia Psilostachya Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Ambrosia Tenuifolia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Ambrosia Trifida Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Amcinonide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ameluz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amerfresh", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }, { Route: "Topical" }] }] },
    { Medication: "Amerfresh Antibacterial Unwrapped", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amerfresh Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amerfresh Fluoride Gel", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amerfresh Tooth", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Amerge", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "America1 Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Americaine",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "American Beech Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "American Bling Protective Disinfecting Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Cockroach", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "American Crew", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Crew All In One Face Balm Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "American Elm Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "American Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "American Hazelnut Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "American Red Cross 3 Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Red Cross 50 Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Red Cross 50% Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Redcross 70% Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Redcross 70% Isopropyl Rubbing Alcohol With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Redcross 91% Isopropyl Rubbing Alcohol With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Savings Bank Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "American Sycamore Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Ameriderm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ameriderm Antibacterial Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amerisource Bergen Good Neighbor Pharmacy Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ameriwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ameriwipes Disinfectant Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amethia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Amethyst",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amethyst Rosa", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Amicar",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amidate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Amidate(Tm) Etomidate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Amikacin Sulfate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Amiloride Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amiloride Hydrochloride And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amiloride Hydrocloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amino Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amino Rip Muscle Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aminocaproic Acid",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aminophylline", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Aminosyn Ii", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Aminosyn-Pf", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Amiodarone Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Amiodarone Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Amiodarone Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amish Origins Spf 15 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amitiza", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amitriptyline Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Amitriptyline Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Amlodipine And Atorvastatin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amlodipine And Benazepril Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Amlodipine And Olmesartan Medoxomil",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Amlodipine And Valsartan",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amlodipine Besylate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amlodipine Besylate And Atorvastatin Calcium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amlodipine Besylate And Benazepril Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amlodipine Besylate And Olmesartan Medoxomil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amlodipine Besylate And Olmesartran Medoxomil", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amlodipine, Valsartan And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amlodipine, Valsartan, Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ammi Nicotiana Adult Size", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Ammonia",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Ammonia Inhalant", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Ammonia Inhalants", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Ammonia Inhalent", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Ammonia N 13",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Ammonia N-13",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Ammonium Carbonicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ammonium Causticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ammonium Lactate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Ammonium Muriaticum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ammonium Phosphoricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ammonul", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Amnesteem", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amnion 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amoe Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amoeba", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amoeba-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amoeba/Protozoan Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amoebatox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amoldipine Benazepril Hcl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amondys 45", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Amoray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoray 70% Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoray 70% Isopropyl Rubbing Alcohol With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoray Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoray Multipurpose Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoray Premium Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoray Premium Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amorepacific", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amorepacific Color Control Cushion Compact No.102", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amosan Oral Wound Cleanser", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amosan Wound Cleanser", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amouage", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amour Cbd", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoveo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoveo Original", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoveo Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amoxapine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Amoxicillin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Amoxicillin And Clavulanate Potassium",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amoxicillin Clav/Pot", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amoxicillin/Clav Pot", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Amoxil",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Amp Relief Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amphadase", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Amphenol-40", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Amphetamine Sulfate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amphisept Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Amphotericin B",
        DoseTypes: [
            { DoseType: "Injectable, Liposomal", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Ampicillin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Ampicillin And Sulbactam", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] }] },
    { Medication: "Ampicillin Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ampicillin Sodium And Sulbactam Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Ampigrin Ultra Forte Night", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ampyra", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amrix", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amrutanjan Relief Cold Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amrutanjan Relief Pain Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amrutanjan Relief Strong Pain Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amsino Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amuldy S", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amuldy Sports Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Amygdalus Persica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amyl Nitrite", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Amyl Nitrosum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Amytal Sodium", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Amyvid", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Amzeeq", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "An Adc Sp F", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "An Adc Sp Intensive Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "An Adc Sp Intensive Moisture Essence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "An Adc Sp Intensive Wrinkle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "An Adc Sp Intensive Wrinkle Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "An Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "An12 Anus Premium Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "An12 Secret Therapy Feminine Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "An12 Skin Therapy Vita-B Aloe Soothing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ana Cool Rapid Soothing Cooling Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ana-Dent Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anacard Orient Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anacardium",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anacardium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anacardium Occidentale", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anacardium Orientale",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anacell", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anacin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anacoccinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Anadent", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anadent Kanka Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Anafranil", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anagallis Arvensis",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Anagallis Taraxacum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anagrelide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anagrelide Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anaitzer S 70% Isopropyl", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Analgesic",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Analgesic Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Analpram Hc",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ananassa Arnica", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ananassa Resina",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anap Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anaplasma Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anaprox", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anaprox Ds", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anas Barbariae",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anascorp", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Anaspaz", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral; Sublingual" }] }] },
    {
        Medication: "Anastrozole",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anatherum Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anatizer Gel 75% Ethanol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anatizer S 70% Ethanol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anatizer S 75% Ethanol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anatizer S 75% Isopropyl", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anavip", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Anbesol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ancalima", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ancalima Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ancalima Hydrocortisone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ancalima Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ancalima-Good Morning", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ancient Secrets", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Ancobon", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Andexxa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Androbalance", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Androderm", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Androgel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Androstat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Androxy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anecream 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anectine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Parenteral" }] }] },
    { Medication: "Anefrin Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Anesgerm La Muela", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anestenka", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Angel Of Mine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Angelica Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Angeliq", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anger", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Angidol Cherry Flavor", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Angidol Honey Lemon Flavor", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Angina Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anginacid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Angiomax", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Angiomax Rtu", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Angiplex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Anhydrous Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Animal Allergens, Ap Cattle Hair And Dander", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Animal Allergens, Ap Dog Hair And Dander Canis Spp", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Animal Allergens, Ap Horse Hair And Dander", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Animal Allergens, Dog Hair And Dander Canis Spp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Animal Allergens, Feather Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Animal Allergens, Guinea Pig Hair And Dander", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Animal Antigen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Animal Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Animal First Aid", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Animal Pain Relief", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Animal Pathogens Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aniosgel 85", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anise Pyrite", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anistem", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anitseptic Mouth Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anjeso", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Anmeikang Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anmeikang Disinfectant Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anna Guanche Md Miracle Cream The Pimple Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anna Lotan Clear Silky Sunscreen Gel Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anna Lotan Lightly Tinted Mineral Day Cream Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Annalonium Lewinii", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Annie", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Annmarie Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Annovera", DoseTypes: [{ DoseType: "Ring", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Annual Saltbush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Anodyne Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anorectal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anorectal Lidocaine Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anoro Ellipta", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Anpitua Stim", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anpituapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ans/Cns", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Antacid",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Antacid - Anti-Gas Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid And Anti-Gas Chew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Antacid And Gasrelief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Antacid Anti-Gas Relief Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Antigas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Berry Bites", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Extra Strength Assorted Berries", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Extra Strength Assorted Berry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Extra Strength Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Extra Strength Wintergreen", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Flavor Chew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Flavor Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Fruit Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Maximum Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Mint", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Relief Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Relief Extra Strength Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Relief Extra Strength Tropical Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Soft Chew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Strawberry Relief Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antacid Ultra Strength Assorted Berries", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antara", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antedred Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anthem Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anthem Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anthim", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Anthrasil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Anti Acne Natural Volcanic Sulfur", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti Age Skin", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Age Stress", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Age Vein", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Bac Acne Clearing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti Bacter Hand Sanitizer Med Clean No Water Needed With Aloe Vera And Glycerine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Anti Bacterial Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Anti Bye Disinfecting Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti Bye Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti Bye Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti Cavity Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Anti Diarrheal",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anti Diarrheal And Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Diarrheal Plus Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Fungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] }] },
    { Medication: "Anti Gas", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Germ", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti Hive", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Inflammatory", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anti Itch",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Anti Itch And Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti Mucedine Feminine Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti Nausea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti Nausea Liquid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Aging Complex Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Aging Complex Eye Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Aging Complex Spf 30", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Aging Daily Moisturizer Sunscreen Broad-Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Aging Tinted Moisturizer Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bac Hand Sanitizer Dragon Fruit Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bac Hand Sanitizer Mixed Berries Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bac Hand Sanitizer Strawberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bac Hand Sanitizer Sweet Berries Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bac Hand Sanitizer Watermelon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bac Hand Wash Strawberry Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacter Hand Sanitizer 70 Ethyl Alcohol Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacter Hand Sanitizer 70 Ethyl Alcohol Mango", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacter Hand Sanitizer 70 Ethyl Alcohol Med-Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacter Hand Sanitizer 70 Ethyl Alcohol Melon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacter Hand Sanitizer 70 Ethyl Alcohol Mint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacter Hand Sanitizer 70 Ethyl Alcohol Pineapple", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacter Hand Sanitizer 70 Ethyl Alcohol Red Berry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacter Hand Sanitizer Melon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Dayoff Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Disinfectant Cleaning Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Dough", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Foaming Mousse Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Anti-Bacterial Hand",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Anti-Bacterial Hand Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Anti-Bacterial Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Anti-Bacterial Hand Sanitizer Blueberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer Caramel Taffy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer Peppermint Cream Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer Warm Vanilla Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer With Goat Milk Creamy Coconut And Oats", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer With Goat Milk Lavender Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer With Goat Milk Milk And Honey", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer With Goat Milk Sea Treasures", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer With Goat Milk Vanilla Bean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sanitizer With Goat Milk Verbena And Cream", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Sparay", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Hand Spary", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Sticker", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Bacterial Woodstock Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Blemish", DoseTypes: [{ DoseType: "Disc", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Constipation Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Diarrhea Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anti-Diarrheal",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anti-Diarrheal Loperamide Hcl, 2 Mg Caplets", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Diarrheal, Circle K", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Empacho", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Flatulence Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Frizz Sheets", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Anti-Fungal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Anti-Fungal Buttery Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Fungal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Anti-Gas",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anti-Hives Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Inflammation Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anti-Itch",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Anti-Itch Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Itch Feminine Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Itch Medicated Maximum Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Itch Regular Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Itch Wal-Dryl", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Itch With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Anti-Perspirant Deodorant",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Anti-Perspirant Deodorant Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anti-Rheumatic Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Scar Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Smoking I Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Smoking Ii Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Stress Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anti-Wart", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antibac Hand Wash Spring Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibac On The Go", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibac-4000", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibac-7000", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Antiseptic Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Aqua Clear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Bandages", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Bathing", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Clean And Smooth", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Eco Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Foam Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Foaming Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Foamy Mango High Foaming Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Hand",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Hand Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Green Apple Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Lavender Sage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Milk And Honey Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Refill", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Rose Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Hand Sanitizer Apple Blast Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Blueberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Bubble Gum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Cherry Blossom Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Cinnamon Apple", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Coconut Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Cotton Candy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Hand Sanitizer Cucumber Scent",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Hand Sanitizer Fresh Lemon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Hawaiian Coconut Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Hand Sanitizer Lavender Scent",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Hand Sanitizer Lavnder Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Lemon Verbena Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Original 1000Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Original 100Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Original 236Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Original 500Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Original Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Peppermint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Hand Sanitizer Rose Scent",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Hand Sanitizer Strawberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Sugar Cookie", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer Vanilla Almond Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer With Aloe And Vitamine E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sanitizer With Aloe And Vitamine E 100Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Hand Sanitizerspray",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Hand Sanitizing 3 Wipes And 2 Non-Medical Masks", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Antibacterial Hand Shea Butter Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Sugar Cookie", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Tropical Twist", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Unicorn Sprinkles", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Hand Wash",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Hand Wash Cool Coconut Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Wash Cotton Blossom Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Wash For Kids Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Wash For Kids Pineapple And Coconut Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Wash Fresh Citrus Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Wash Lavender Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Wash Peach Splash Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Wash Sage Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hand Wipe Mitts", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Hand Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Hand Wipes With Aloe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Handsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Handsoap With Moisturizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Hnad Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Lidocaine Wound Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Liquid Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Lotiontissue", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Medicated Toner -2% Salicylic Acid", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Moist Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Moisturizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Refreshing Hand Wash Aloe Vera Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Refreshing Hand Wash Coconut Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Refreshing Hand Wash Lemon Lime Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Skin Rain Forest Extract From Entiere", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibacterial Wet Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Wet Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial White Pearl", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibacterial Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibacterial Wipes Unscented", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibiotic",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Antibiotic And Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibiotic Application", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibiotic Free Wound Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antibiotic Free Wound Gel And Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antibiotic Plus Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antibiotic, Pain And Scar", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Anticavity",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anticavity Fluoride", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anticavity Fluoride Rinse",
        DoseTypes: [
            { DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anticavity Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anticoagulant Citrate Dextrose A", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Anticoagulant Citrate Dextrose A Acd-A", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Anticoagulant Citrate Phosphate Dextrose (Cpd) Blood-Pack Units In Pl 146 Plastic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Anticoagulant Sodium Citrate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Antidandruffshampoo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antidandruffshampooconditioner", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antidiarrheal Multi Symptom Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antidiarrheal Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antiflatulent", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antiflu Des", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Antifungal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antifungal Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antifungal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antifungal Cream Tolnaftate 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antifungal Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antigas", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antigen Component", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Antigrip Daytime", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antigrip Nighttime", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antihistamine Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial 652", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Alcohol Hand", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Cleansing Gel", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Antimicrobial Face Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Foam Fragrance And Dye Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Foam So Ap With Pcmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antimicrobial Hand",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antimicrobial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Hand Sanitizing Mango Scented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Sanitizing Hand Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobial Skin Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimicrobialsoap", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antimonite Belladonna", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antimonite Echincea Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antimonium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Antimonium Crudum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Antimonium Crudum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antimonium Iodatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antimonium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antimonium Oxydatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antimonium Tart Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Antimonium Tartaricum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Antiochpharma Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antioxidant Day Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiperspirant",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiperspirant Deodorant Extra Dry Men", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiperspirant Deodorant Extra Dry Women", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiperspirant Deodorant Fresh Women", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiperspirant Deodorant Powder Fresh Women", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiperspirant Dry 1.6 Oz Lady", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiperspirant Dry 1.6 Oz Men", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiperspirant For Hands Feet Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiperspirant Wipe For Women", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiperspirant-Deodorant Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiphlamine",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiplaque Solution", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Antisepation", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }] }] },
    {
        Medication: "Antiseptic",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Alcohol Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Bandages", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Bzk Towelette", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Cleansing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Cleansing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Gel Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Hand Cleaner", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiseptic Hand Rub",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Hand Rub Mint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiseptic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Hand Sanitizer Gel 128 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Hand Sanitizer Gel Ethyl Alcohol 70", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Hand Sanitizer Gel For Life", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Hand Sanitizer Wipe Benzalkonium Chloride", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiseptic Hand Sanitizer Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Hand Sanitizer Wipes Benzalkonium Chloride", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiseptic Hand Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Liquid Hand Sanitizer 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Ltion Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Mouth Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antiseptic Mouth Sore", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Periodontal" }] }] },
    { Medication: "Antiseptic Mouthrinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antiseptic Oral Cleanser", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antiseptic Oral Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Antiseptic Patient Preoperative Skin Preparation And Surgical Hand Scrub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Rinse Spring Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Antiseptic Sanitizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiseptic Sanitizing Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Sanitizing Wipes (0.13% Benzalkonium Chloride)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Sinofresh Kool Blast Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Antiseptic Skin Cleanser",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Tissue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Towel Benzalkonium Chloride", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiseptic Towelette",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Towelettes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Antiseptic Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiseptic Wipe",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Antiseptic Wipe Gel For Life", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antiseptic Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Antispetic",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Antivenin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Antivenom Workout Enhancer Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Antivert",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anucort-Hc", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Anusol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anusol Hc", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Anven", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Anx Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anxietin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Anxiety",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Anxiety Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anxiety Ii Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anxiety Plus Tension", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anxiety Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Anxiety Stress Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anxiolix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anxious", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anxious Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Anxiousrelief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Any Where Tooth Tabs", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Anzemet", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aoliben 75% Alcohol Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aoliben Disposable Alcohol Disinfectant Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aoliben Zhushi Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aomi Hand Sanitizer Cleansing Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aopar Water Based Personal Lubricant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Rectal; Vaginal" }] }] },
    { Medication: "Aorta Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ap Hansan", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ap Ii Neckcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ap-24", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ap-24 Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ap-24 Whitening", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Apache Service And Supply Company Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apadaz", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apap", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apartment House Supply Antiseptic Hand Sanitizer Gel 1 Gallon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apatite Cucurbita", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aperol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apetite Relief", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apex Crown Anti Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apexicon E", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aphedrid Cold And Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aphen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apidra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Apidra Solostar", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Apiol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Apis Aconitum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Apis Arnica",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Apis Belladonna",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Apis Belladonna Mercurius",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Apis Belladonna Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Apis Bryonia",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Apis Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Eucalyptus Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Ex Animale 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Apis Ex Animale 30 Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Apis Ex Animale 4 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Ex Animale 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Gelsemium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Apis Levisticum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Apis Mell.", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Mellifera", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Apis Mellifica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Apis Mellifica Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Phosphorus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Apis Regina Cerebri",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Apis Rhus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Venenum Purum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apis Venenum Purum 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apixaban", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apizastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apizelen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aplaus Hand And Skin Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplenzin", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aplicare", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplicare Antiseptic Hand Rinse", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplicare Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplicare Povidone Iodine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplicare Povidone Iodine Swabstick", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aplicare Povidone-Iodine",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aplicare Povidone-Iodine Paint", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplicare Povidone-Iodine Prep Pad", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplicare Povidone-Iodine Scrub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplicare Povidone-Iodine Triples", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aplisol", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intradermal" }] }] },
    { Medication: "Apnetem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Dolor", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Enterit", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Hepat", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Infekt", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Oedem", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Pulm", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Rheum", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Stom", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Strum", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Tuss", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apo-Varenicline", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apocynum Cannabinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apokyn", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Apollo Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apomorphine Hydrocloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Apostrophe Screen Spf43", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apothecare", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apothecary Home", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apothecary Home Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apothecary Home Men", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Appalachian Botanical", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Appeal Alcohol Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Appeal Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Appeal Non-Alcohol Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Appendix Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Appetite - Craving Control",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Appetite Control", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Appetite Increase", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Applause", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Apple",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Apple Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apple Of Sodom", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apple Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apple Splash Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Applelicious", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Appletini Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apraclonidine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Apraclonidine Ophthalmic", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Apremilast",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Aprepitant",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Apres Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Apretude", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Apri", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Apricot",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Apriso", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aprizio Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aprodine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Apt T3X Tetracycline Hydrochloride First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aptensio Xr", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aptiom",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aptivus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aqua Blue Mouthwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aqua Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aqua Hq", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aqua Kavi", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aqua Marina", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aqua Maris Baby", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Aqua Maris Classic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Aqua Maris Clean", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Aqua-Nu", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquabain Antibacterial Fresh And Relax", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquabain Antibacterial Grapefruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquabain Antibacterial Passion Berrys", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquabain Antibacterial Red Fruits", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquabain Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquacil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquacool", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquacool Blue 120", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquacool Blue Roll", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquacool Multi 120", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquacool Multi Roll", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquacool Shower", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquafresh", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Aquagard", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aquagel Advanced Formula",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aquagel Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquamarine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aquanaz", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aquanil Hc", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquaphor Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquaphor Healing Balm Stick", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquaphor Healing Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquaphor Healing Fast Relief Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquaphor Itch Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquaphor Lip Protectant And Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquaphor Lip Repair Stick And Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquaphor Original", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aquarelle",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aquasol A", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Aquatec Alonglife", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquatide Resurface Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquatide Soothing Lifting Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquavit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aquavit 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aquax", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquax Deo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquax Repellent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquax Whitening", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquax-H", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aquilegia Vulgaris", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aquilinum Taraxacum",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aquilinum Taraxacum Ampules", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aquilinum Taraxicum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arabsabio Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arakoda", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aralast", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aralia Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aralia Quinquefolia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aralia Racemosa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aralia Racemosa Mt", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aram Hand Sanitizing Wipe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Antacid Chewable", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aramark Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aramark Back Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aramark Blood Clotting", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Burn", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Burn Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Burn Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Extra Strength Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aramark Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Hydrogen Peroxide", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Ibuprofen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aramark Isopropyl", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Medi-Band", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramark Multi-Symptom Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aramark Pain Free", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aramark Pain-Free Extra Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aramark Star Burn Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramis 24 Hour High Performance Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aramis Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aranea Diadema", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aranelle", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Aranesp",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] },
        ],
    },
    { Medication: "Araneus Diadematus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aratinga Arythrogenys", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arava", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arazlo", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Agewell Moisture Restoring Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Funsun Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Got You Covered Mineral Foundation Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Intelligence Pollution Defense Spf30 Cc Cream - Dark Shade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Intelligence Pollution Defense Spf30 Cc Cream - Fair Shade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Intelligence Pollution Defense Spf30 Cc Cream - Light Shade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Intelligence Pollution Defense Spf30 Cc Cream - Medium Shade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Re9 Advanced", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arbonne Setting Pretty Translucent Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arc",
        DoseTypes: [
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arc Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arcalyst", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Archangelica Eucalyptus", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arctic Blast Pain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arctic Blast Pain Releiving", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arctic Heat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arctic Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arctic Ice Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arctic Ice Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arctic Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arctic Relief Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arestin", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arex Sinsin Pas", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Cutaneous; Topical; Transdermal" }] }] },
    { Medication: "Arformoterol Tartrate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Arformoterol Tartrate Inhalation", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Arg Nit", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argan Air Water Bang Essence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Argatroban",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Argentite Urtica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum 0.4", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Argentum 0.4 Adult Size (Cocoa Butter) Special Order", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Argentum 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum 17 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum 21X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Argentum 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum 6X", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Bryophyllum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Calendula", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Argentum Carbonicum 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Iodatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Malachite", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Argentum Metallicum",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Argentum Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Argentum Nitricum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Argentum Nitricum 4X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Nitricum 6X", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Nitricum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Phosphoricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Argentum Quartz",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Argentum Saccharum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Saccharum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Veratrum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentum Viscum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Argentyn 23", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arginase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arhtri Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aricept", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aridol Bronchial Challenge Test Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Arikayce", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Arilac Anti Wrinkle Mask Sheet Pack", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arilac Brightening Mask Sheet Pack", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arilac Soothing Mask Sheet Pack", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arimidex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aripiprazole",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aristada", DoseTypes: [{ DoseType: "Injection, Suspension, Extended Release", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Aristada Initio", DoseTypes: [{ DoseType: "Injection, Suspension, Extended Release", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Aristolochia Clematitis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arixtra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Arizona Ash Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Arizona Biltmore Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arizona Cypress Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Arizona Fremont Cottonwood Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Arizona Gambel Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Arizona Velvet Ash Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Arm And Hammer Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arm And Hammer Baking Soda", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Armadillidium Vulgare", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Armicare Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Armodafinil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Armonair Digihaler", DoseTypes: [{ DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Armour Thyroid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Army Health Pain Reliever", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arnica",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica 10%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica 12C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Arnica 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Arnica 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Arnica Aconitum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Active Montana Nartex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica Advantage", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Apis Nervus Trigeminus Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Aurum 15/10 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Aurum 20/30",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica Aurum 6 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Aurum 6/10", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Aurum Equisetum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica Aurum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Berberis Teucrium", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Betula A",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Arnica Betula B",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica Bryonia Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Carbo Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Cordifolia Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica De La Abuela", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica E Pl. Tota 10X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica E Pl. Tota 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica E Pl. Tota 20X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica E Pl. Tota 3",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Arnica E Pl. Tota 30",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Arnica E Pl. Tota 6",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica E Pl. Tota 6X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica E Rad. 12", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica E Rad. 20X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica E Rad. 6 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Echinacea",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica Forte", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica Hypericum Dks", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica Hypericum Special Order", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arnica Montana",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Arnica Montana 200C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Montana 30C",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual; Sublingual; Sublingual" }] },
        ],
    },
    { Medication: "Arnica Montana 30X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual; Sublingual; Sublingual" }] }] },
    { Medication: "Arnica Montana 30X Bonus Pack", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Montana 3X, 6X, 12X, 30X, 200X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Montana 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral; Oral; Oral" }] }] },
    { Medication: "Arnica Montana Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Montana Nartex",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Arnica Montana Power Healing", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Montana Radix", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Montana, Radix",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Arnica Nettle",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Arnica Nettle Burn And Bite", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arnica Plumbum Mel",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Arnica Plus",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Arnica Plus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Power", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Recovery", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica Retina", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Retina Quartz Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnica Rosa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnica Scorodite",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica Silver", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arnica Stannum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnica Treatment Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Arnica Value Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Arnica Well", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnica Wh Montana Nartex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnicaid", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnicare",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Cutaneous" }] },
        ],
    },
    { Medication: "Arnicare Arnica", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arnicare Arthritis",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Arnicare Bruise",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arnicare Foot Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnicare Leg Cramps", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnicare Procedure Recovery", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnicare Value Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Arnicool", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arniflora", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arniflora Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "Arnimax Penetrating Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arnisport", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arnuity Ellipta", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Arocell Eye And Neck Super", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arocell Milky Drop Finish Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arocell Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arocell Super Power", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arocell Time Reverse K I T", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Arocell Tone Up Perfect Sun", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arocell Vita20 Power", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arocell X Belle J Super Power Cell Ampoule", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aroma Guru Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aroma Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromacura Shower Filter", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromalief Calming Pain Relief Cream Lavender", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromalief Lidocaine Roll On", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromalief Pain Relief Cream Orange Ginger", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromalief Soothing Pain Relief Cream Spearmint", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromasin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Sanitizer Cherry Blossom Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Sanitizer Coconut Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Sanitizer Lavender Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Sanitizer Lavnder Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Sanitizer Lemongrass Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Sanitizer Peppermint Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Wash Chamomile Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Wash Coconut Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aromatherapy Antibacterial Hand Wash Lavender Scented",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aromatherapy Antibacterial Hand Wash Lemon Verbena Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Antibacterial Hand Wash Lemongrass Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Hand Sanitizer Chamomile And Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Hand Sanitizer Charcoal And Caffeine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Hand Sanitizer English Rose And Hemp", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Hand Sanitizer Eucalyptus And Spearmint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Hand Sanitizer Lavender And Chamomile", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Hand Sanitizer Lemongrass And Sweet Orange", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Hand Sanitizer Red Poppy And Hemp", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Rituals Hand Wash Tea Tree And Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Rituals Hand Wash Tea Tree And Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatherapy Rituals Hand Wash Tea Tree And Peppermint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aromatic Ammonia Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aromatique Apricot Mint Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Aromatique Fresh Air Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Aromatique Kiwi Pear Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Aromatique Valencia Orange Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Arranon", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Array Anitbacterial Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Array Antibacterial Handwash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Array Plum Scent Antibacterial Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arrhythmiaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arrid Extra Dry",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Arrid Extra Extra Dry - Cool Shower", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arrid Extra Extra Dry Cool Shower", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arrid Extra Extra Dry Regular", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arrid Extra Extra Dry Regular Solid", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arrid Extra Extra Dry Ultra Fresh",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Arrid Extra Extra Dry Unscented Solid", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arrid Extra Extra Dry Xx", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arrid Extra Extra Unscented Solid", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arrid Xx Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arropure 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arroyo Willow Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Ars Alb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arsenic Trioxide",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Arsenicum", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Arsenicum 6/12/30 Special Order", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arsenicum Album",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arsenicum Album 200C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Album 30", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arsenicum Album 30C",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Arsenicum Album 30X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Album 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Arsenicum Album 6X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Album Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Bromatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arsenicum Cerebrum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arsenicum Cerebrum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Iodatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arsenicum Metallicum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arsenicum Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Oxydatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Sulphuratum Flavum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsenicum Sulphuratum Rubrum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arsmedic Step 01 Secret Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arsmedic Step 02 After Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Naturals", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Naturals Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Naturals Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Of Sport Anti Dandruff Compete", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Of Sport Anti Dandruff Victory", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Of Sport Antiperspirant Compete", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Of Sport Antiperspirant Defy", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Of Sport Antiperspirant Plus Deodorant Compete", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Of Sport Antiperspirant Rise", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Art Of Sport Antiperspirant Victory", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artalgia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Artemes 3R Effect Hyaluronic Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artemesia Absinthium 5 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Artemisia Annua Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Artemisia Frigida Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }] }] },
    { Medication: "Artemisia Tridentata Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Artemisia Vulgaris", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Artemisia Vulgaris Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Arterioforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arteriotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Artesunate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Arth Arrest", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arth Rx Topical Analgesic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthmed Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthri-Joint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arthricream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthrit-Ease", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthriten", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arthritis",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arthritis Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthritis Ease", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arthritis Hot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthritis Joints", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arthritis Pain",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arthritis Pain Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Arthritis Pain Relief",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arthritis Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arthritis Pain Reliever",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Arthritis Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Arthritis Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Arthritis Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthritis-Ease", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arthritis/Joints", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arthritispain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arthro Pharma Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthrocare Maximum Arthritis Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthroneo", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arthros", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arthrotec", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Articadent", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Articaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Articaine Hcl And Epinephrine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Submucosal" }] }] },
    { Medication: "Articaine Hydrochloride And Epinephrine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }, { Route: "Submucosal" }] }] },
    { Medication: "Articula", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Articulatio Humeri 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Articulatio Interphalangea 17 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Artifical Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Artificial Eye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Artificial Sweetener Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Artificial Tears",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    {
        Medication: "Artificial Tears Lubricant Eye",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Artificial Tears Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Artisan Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artiss", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Beauty Balm Perfecting Primer Spf 35 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Brule", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Buff", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Cappuccino", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Chablis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Chiffon", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Golden", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Mink", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Ochre", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Plush", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Soleil", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Shade Walnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Exact Fit Longwearing Foundation Spf 15 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Plush", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Shade Buff", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Shade Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Shade Chablis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Shade Chiffon", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Shade Golden", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Shade Ochre", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Shade Soleil", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydra V Sheer Weightless Foundation Walnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Hydro-V Sheer Weightless Foundation Spf 15 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Ideal Radiance Illuminating Cc Spf 50 Broad Spectrum Med/Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Ideal Radiance Illuminating Cc Spf 50 Broad Spectrum Sunscreen Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Ideal Radiance Illuminating Cc Spf 50 Broad Spectrum Sunscreen Light/Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Ideal Radiance Illuminating Cc Spf 50 Broad Spectrum Sunscreen Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Ideal Radiance Illuminating Cc Spf50 Broad Spectrum Sunscreen - Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Ideal Radiance Uv Protect Spf 50 Plus Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Skin Nutrition Balancing Matte Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Skin Nutrition Hydrating Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Skin Nutrition Renewing Reactivation Day Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Skin Nutrition Renewing Reactivation Day Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Studio Done With Zit Acne Treatment Clearing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Studio Rose All Day Anti Acne Toner Pore Refresher", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Brule", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Buff", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Cappuccino", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Chablis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Chiffon", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Golden", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Mink", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Ochre", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Plush", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Soleil", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Lifting Smoothing Foundation Shade Walnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Protecting Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artistry Youth Xtend Protecting Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artnaturals Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artnaturals Hand Sanitizer Moisturizing Formula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artnaturals Hand Sanitizer Natural Elements Cleansing Formula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artnaturals Scent Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Artrex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aruba Aloe Hand Sanitizer 80% Alcohol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aruba Aloe Lip Sunscreen And Moisturizer", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Spf 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Spf 50 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Spf 50 Mineral Sunscreen Babies", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Spf 50 Mineral Sunscreen Faces", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Sport Line Broad Spectrum Spf 50 Anti Chafing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Sport Line Lip Balm Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Very Water Resistant Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Very Water Resistant Sunscreen - For Faces", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Aloe Very Water Resistant Sunscreen For Kids", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Sun Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Sun Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aruba Sun Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arum Maculatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arum Maculatum Pteridium 5/5 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arum Triphyllum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arumacil", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arumacil Protectant Cold Sore", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Arundo Mauritanica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Arzerra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "As", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "As I Am",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "As-3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "As03", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Asacol Hd", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asafoetida", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asafortida", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asana Kisser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asarum Europaeum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asbestox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asce Plus Derma Signal Kit Srlv", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Asce Plus Scalp Care Hrlv", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asceniv", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Asclera", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ascomp With Codeine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ascor", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ascorbic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asenapine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Asenapine Maleate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Asepso Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asepso Sani Care Alcohol Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aseptil Rojo", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asepxia Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asepxia Acne Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asepxia Charcoal Acne Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asepxia Neutral Acne Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asepxia Oil-Free Acne Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asepxia Scrub Acne Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asepxia Softening Acne Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ash Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Ashford Instant Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ashlyna", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Asi Chemicals Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Askindr Moist Moisture", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asmanex", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Asmanex Hfa", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Asn Guard Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asp Daytime Pe", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asp Nighttime Max", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asparagus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Asparagus Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asparlas", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Aspartame/Msg Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspen Moisturizing Advanced Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aspen Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Aspercreme", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme Arthritis", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme Lidocaine Foot (Diabetic)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme Lidocaine No-Mess Bergamot Orange", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme Lidocaine No-Mess Plus Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme Warming", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme With Lidocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme With Lidocaine Dry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme With Lidocaine Essential Oils Rosemary Mint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme With Lidocaine Foot Pain Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme With Lidocaine No-Mess Applicator", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspercreme With Lidocaine Pain Relieving Creme", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asperflex", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asperflex 24Hrs", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asperflex Advance", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Asperflex Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Asperflex Max", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asperflex Original Maximum Strength", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspergillus Amstelodami", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Aspergillus Flavus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Aspergillus Fumigatus",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Aspergillus Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Aspergillus Nidulans", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Aspergillus Niger", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Aspergillus Niger Var Niger", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Aspi-Cor", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspica (Aspirin)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspicare", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspir Low", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspire Cleanser So Fresh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspire Congestion And Cough Relief Dm Softgels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspire Day Congestion And Headache Softgel", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspire Daytime Mucus And Sinus Softgel", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspire Mucus And Pain Relief Softgels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspire Night Cold And Flu Softgel", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspire Scrub So Clean", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aspire Treat So Deep", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aspirin",
        DoseTypes: [
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aspirin 325", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aspirin 325 Mg",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aspirin 325 Mg Ec", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin 325Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin 5 Grain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aspirin 81",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Aspirin 81 Mg",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aspirin 81 Mg Enteric Coated", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aspirin 81Mg",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Aspirin 81Mg Enteric Coated",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aspirin Adult Low Dose", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin And Dipyridamole", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin And Extended - Release Dipyridamole Capsules, 25 Mg / 200 Mg", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aspirin And Extended-Release Dipyridamole",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aspirin Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin E-C", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin Ec", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin Low Dose", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin Low Dose Chewable Orange", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin Nsaid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin Pain Reliver", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin Regimen", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin Regular Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin Safety Coated", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspirin-Dipyridamole", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asprin And Extended-Release Dipyridamole", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aspruzyo Sprinkle", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ass Kisser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assassin Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assist Ii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assorted Fruit Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assorted Fruit Antacid Flavor Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assorted Fruit Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assorted Fruit Flavor Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assorted Fruit Panned Chew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assorted Scented Hand Sanitizers", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Assured",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Assured Allergy Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Assured Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Aloe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Anticavity Fluoride Kids Bubblegum", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assured Aspirin", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assured Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Cold N Hot Pain Relief Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Extra Strength Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assured Headache Pm (Aspirin Free)", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Assured Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Assured Instant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Instant Hand Sanitizer Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Instant Hand Sanitizer Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Instant Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Instant Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Instant Hand Sanitizer Refill 11.2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Maximum Strength Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Assured Medicated Body Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Nasal Relief Original", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Assured Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Assured Pain Relief Hot Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Assured Vaporizing Chest Rub",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Astagraf Xl", DoseTypes: [{ DoseType: "Capsule, Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asterias Rubens", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asth-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Asthma",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Asthma Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asthma Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asthma Therapy", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asthmaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Asthmanefrin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Asthmatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Astonea",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Astonea-Vitamins A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Astra-Dent Topical Anesthetic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Astragalus Arnica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Astragalus E Rad. 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Astragalus Formica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Astragalus Formica Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Astral Organica Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Astrapharm Chilli Brand", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Astrapharm Chilli Brand External Medicated", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Astringent",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Asutra Wellheal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asystem Daily Defense Moisturizer Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Asystem Radical Relief Gel Roll-On 300Mg Cbd", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "At Home Signature Collection Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "At Once Hand Sanitizer (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atacand", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atacand Hct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Atazanavir",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Atazanavir Sulfate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atelvia", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atenolol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atenolol And Chlorthalidone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aterra7 Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atgam", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Athentia Next", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Athlete S Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Athletes Foot",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Athletes Foot Ultra", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Athletes Foot/Jock Itch", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Athomer Baby", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Athomer Propolis", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Ativan",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Atlantic Cod", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Atmosphere Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atnaa Atropine And Pralidoxime Chloride", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Ato Standard Intensivecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ato Standard Premium Mildcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atomic Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atomoxetine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atomoxetine Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atomy Bb Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atomy Derma Calming Sun", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atomy Lip Glow", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atomy Propolis Toothpas Te 200G", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atomy Propolis Toothpas Te 50G", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atomy Sun", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atomy Sunscreen Beige Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atomy Sunscreen White Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atopis Eczema Therapy Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atopis Med", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atoq30 Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atoren Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atoren Premium Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Atorvastatin Calcium",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Atovaquone", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atovaquone And Proguanil Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atovaquone And Proguanil Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atovaquone And Proguanil Hydrochloride Pediatric", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atovaquone Oral Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atracurium Besylate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Atralin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atripla", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Atriplex Wrightii Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Atrix Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atrix Antibacterial Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atrix Clarifying Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atrix System 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Atropen Auto-Injector", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Atropine", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Atropine Sulfate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Endotracheal; Intramedullary; Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intravenous" }, { Route: "Parenteral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Endotracheal; Intramedullary; Intramuscular; Intravenous; Subcutaneous" }, { Route: "Endotracheal; Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intravenous" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Atrovent", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Attention And Focus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Attention Deficit Mild Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Attention Deficit Severe Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Attention Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Attitude", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Attitude Adult Fluoride-Free - Sensitive - Spearmint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Attitude Kids Mineral Sunscreen Face Unscented Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Attitude Kids Mineral Sunscreen Tropical Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Attitude Kids Mineral Sunscreen Unscented Spf 30",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Attitude Kids Oatmeal Sensitive Mineral Sunscreen Unscented Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Attitude Mineral Sunscreen Face Unscented Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Attitude Mineral Sunscreen Orange Blossom Spf 30",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Attitude Mineral Sunscreen Tropical Spf 30",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Attitude Mineral Sunscreen Unscented Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Attitude Oatmeal Sensitive Mineral Sunscreen Face Unscented Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Attitude Oatmeal Sensitive Mineral Sunscreen Unscented Spf 30",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Attitude Tinted Mineral Sunscreen Face Unscented Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Attitude Tinted Mineral Sunscreen Unscented Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Atuss Da", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Au Kah Chuen Fugical", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aubagio", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aubra", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aubra Eq", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Audiologist Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Audiologists Choice Earwax Removal Aid Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Audiologists Choice Earwax Removal System", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aulani Disney Resort And Spa Hawaii Alcohol Wet Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aulief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aura Cacia Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Auraglow Daily", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Auraglow Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Auralyt", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aureli", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aureobasidium Pullulans",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Aureobasidium Pullulans Var Pullulans", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Auro Dri", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Auro Homeopathic Ear Relief Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Auroflu Day Time Severe Cold And Cough", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Auroflu Night Time Severe Cold And Cough", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Auromucus Fast Maximum Night Time Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurophen Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurophen Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aurophen Extra Strength For Adults", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurophen Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurophen Pain Away Plus Sleep Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurophen Pm Extra Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurophen Regular Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurora Alcohol Free Clean Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Aurora Blue Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Aurora Fresh", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aurora Fresh Burst", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Aurora Fresh Ea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aurora Fresh Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aurora Fresh Gel 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aurora Fresh Gel 71", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aurorae Healing Pain Relieving Roll-On", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aurovela 1.5/30", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurovela 1/20", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurovela 24 Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aurovela Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aurovela Fe 1/20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aurum 10X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 17 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 21X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 21X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 30X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aurum 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 6 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 6X", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum 8X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Belladonna Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Bromatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aurum Crataegus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aurum Equisetum 10/15 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aurum Equisetum A",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Aurum Equisetum B",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aurum Ferrum Sidereum 10/10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aurum Hypericum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aurum Hypericum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aurum Hypericum Stibium",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aurum Hypericum Stibium Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Iodatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Lavender Rose", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aurum Met", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aurum Metallicum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aurum Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Muriaticum Kalinatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Muriaticum Natronatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Muriaticum Natronum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Olibanum Myrrha", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aurum Onopordon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aurum Plumbum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Aurum Prunus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aurum Stibium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Aurum Stibium Hyoscyamus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Aurum Stibium Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Aurum Strophanthus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Aurum Strophanthus Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Auryxia", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Austalian Gold",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Austedo",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Australian Gold",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Australian Gold Botanical",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Australian Gold Defense Zone",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Australian Gold Hello Kitty", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Australian Pine Beefwood Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Australian Pine Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Autumn Bliss Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Auvi-Q", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Av-1000", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Av100", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Av3630000 Avalon Organics Eczema Relief Intensive", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Av3630100 Avalon Organics Eczema Relief Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Av3630200 Avalon Organics Eczema Relief Gentle Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Av453902 Avalon Organics Intense Defense Sheer Moisture Spf10", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ava Isa Sun Lip Sun Whip", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ava Isa Sun Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avaderm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avagard D", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avail Body With Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avail Facial Sunscreen Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avalide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Avalon Organics Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avalon Organics Antidandruff Conditioner", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avance Cure Anti-Bacterial Perineal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avance Cure Antibacterial Perineal Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avance Cure Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avance Cure Foaming Body Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avance Cure Incontinence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avance Cure Moisturizing Incontinence Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Avant",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Dental" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Avant Alcohol-Free Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avant Eco-Premium Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avant Ex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avant Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avant Original - Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avapro", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Avar", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avar Ls", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avar-E", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avar-E Green", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avar-E Ls", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avastin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ave Silvergen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveda Outer Peace Acne Relief Pads", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avedana Cool Hot Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avedana Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Avedana Glycerin", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Avedana Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Avedana Hemorrhoidal Cooling Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avedana Hemorrhoidal Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avedana Hydrocortisone", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avedana Hydrocortisone Cream With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avedana Medicated Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avedana Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avedana Pain Relieving Roll On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveed", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Aveeno Absolutely Ageless Daily Moisturizer Sunscreen Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Active Naturals Daily Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Anti Itch Concentrated", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Anti-Itch Concentrated", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Bathtime Solutions Baby And Me Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aveeno Baby Bathtime Solutions Baby And Mommy Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aveeno Baby Bathtime Solutions Mommy And Me Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aveeno Baby Calming Comfort", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Calming Comfort Bath Plus Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aveeno Baby Continuous Protection Sensitive Skin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Continuous Protection Sensitive Skin Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Daily Care Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aveeno Baby Daily Moisture", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Eczema Therapy Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Eczema Therapy Nighttime Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Eczema Therapy Soothing Bath Treatment", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Essential Daily Care Mommy And Me Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aveeno Baby Soothing Hydration Creamy Oil", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Soothing Multi-Purpose", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Baby Welcome Little One Gift Basket", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aveeno Calamine And Pramoxine Hydrochloride Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Clear Complexion Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Clear Complexion Daily Cleansing Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Clear Complexion Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Clear Complexion Foaming Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Clear Complexion Sheer Daily Moisturizer Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Cracked Skin Relief Cica Balm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Daily Moisturizing Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Diabetics Dry Skin Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Eczema Therapy Daily Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Eczema Therapy Hand And Face", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Eczema Therapy Itch Relief Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Favorites Morning Radiance Regimen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Aveeno Hydrocortisone Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Kids Continuous Protection Sensitive Skin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Kids Continuous Protection Sensitive Skin Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Positively Ageless", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Positively Ageless Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Positively Mineral Sensitive Skin Face Milk Sunscreen Broad Spectrum Spf 40 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aveeno Positively Mineral Sensitive Skin Sunscreen Broad Spectrum Spf 50",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Aveeno Positively Mineral Sensitive Skin Sunscreen For Face Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Positively Radiant Cc Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Positively Radiant Daily Moisturizer Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Positively Radiant Daily Moisturizer Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Positively Radiant Sheer Daily Moisturizer Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Protect Plus Hydrate", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Protect Plus Hydrate Sunscreen Broad Spectrum Body Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Protect Plus Hydrate Sunscreen Broad Spectrum Body Spf 60", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Protect Plus Hydrate Sunscreen Broad Spectrum Face Spf 60", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Protect Plus Hydrate Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Protect Plus Hydrate Sunscreen Broad Spectrum Spf 50 For Face", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Protect Plus Refresh Sunscreen Broad Spectrum Body Spf 60", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Restorative Skin Therapy Itch Relief Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Skin Relief Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Skin Relief Moisturizing Coconut Scented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Skin Relief Overnight", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Soothing Bath Treatment", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Ultra Calming Daily Moisturizer Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aveeno Ultra-Calming Daily Moisturizer Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avena Sativa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Avena Valeriana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Avenoc",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Avenova Lubricant Eye Drops High Performance", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Aventurine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Averr Aglow Pure Protection Invisible Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Aviane", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Avid Hand Sanitizing Wipe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Avita",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Avobenzone, Homosalate, Octisalate, Octocrylene",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Avobenzone, Homosalate, Octisalate, Octocrylene, Oxybenzone", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avoca Flexible Caustic Applicator", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avocado", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Avodart", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Avon Anew Platinum Day Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Hexashield Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Hexashield Arthritis Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Hexashield Athletes Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Hexashield Congestion Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Avon Hexashield Pain Relief For Muscle, Back And Joint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Hexashield Triple Antibiotic With Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Pure Cica Shea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Sun Protection Natural Mineral Sunscreen For Body Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Sun Protection Natural Mineral Sunscreen For Face Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Ultimate Day Multi-Performance Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Ultimate Day Multi-Performance Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Veilment Natural Spa Jasmine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Avon Whitening Essentials", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Avonex",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Kit", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    { Medication: "Avonex Pen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Avsola", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Avva", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Avycaz", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Awake", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Awaken", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Awaken By Quality Choice Foaming Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Awaken By Quality Choice Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Awaye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Axe",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Axe Brand Medicated", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Axelofein", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Axim Allergy", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Axim Day And Night Time 48 Softgels", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Axim Day And Night Time 72 Softgels", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Axim Daytime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Axim Night Time", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Axim Sinux Severe Mucus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Axumin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Aygestin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aypanal Ex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aypanal Non-Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ayrzinc", DoseTypes: [{ DoseType: "Spray, Suspension", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Aytumune",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ayuna", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ayvakit", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Azacitidine", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Azacitidine For", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Azactam", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Azalea Pfcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Azasan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Azasite", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Azathioprine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Azathioprine Sodium", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Azedra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Azelaic Acid", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Azelaic Acid Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Azelastine", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Azelastine Hcl Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Azelastine Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Intraocular" }, { Route: "Ophthalmic" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Azelastine Hydrochloride And Fluticasone Propionate", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Azelastine Hydrochloride Ophthalmic Solution 0.05%", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Azelex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Azelomax Forte", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Azilect", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Azithromycin",
        DoseTypes: [
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }, { Route: "Parenteral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Azithromycin Dihydrate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Azithromycin Monohydrate",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Azo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Azo Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Azo Urinary Tract Defense", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Azolen", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Azopt", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Azor", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Azstarys", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Aztreonam", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Azulfidine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Azurette", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Azurite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Azx Wellness Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B Clean Premium Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B Complex Mix System Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "B Force", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "B Force Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "B Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B Free Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "B Free Hand Sanitizer With Aloe Vera And Vitamin E",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "B Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "B-Ageless Broad Spectrum Spf55 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B-Ageless Tinted Mineral Broad Spectrum Spf30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B-Balance Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "B-Sure Traveler Towelette", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Rectal; Topical" }] }] },
    { Medication: "B.Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B.Clean Hand Sanitizer (Foam)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B.Tan Beach Please", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "B.Tan Spf Is Your Bff",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "B.Tan Sun Safe Af",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "B.Tan Sun Safe Af Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B.Tan Tropic Like Its Hot", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B01", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B02", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B03", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B04", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B05", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B06", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B07", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B08", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B09", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B100", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "B11", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B13", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B14", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B19", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "B20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babience Kids Sunscreen Pinkfong Baby Shark Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bablab Super Clean Calming Cleansing Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Baby Face Mineral Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Baby Skin Mineral Sunscreen Ff", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Botanicals Daily Sheer Fluid Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Botanicals Daily Sheer Fluid Tinted Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Botanicals Daily Sheer Mineral Sunscreen For Face Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Botanicals Sensitive Baby Fragrance Free Diaper Rash", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Botanicals Spf 30 Daily Sheer Tinted Sunscreen - Natural Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Fragrance Free Diaper Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Sensitive Baby All Natural Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Soothing Diaper Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Spf 30 Clear Zinc Fragrance Free", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Spf 30 Clear Zinc Fragrance Free Sport", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Spf 30 Sheer Zinc Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babo Spf 50 Sheer Mineral Sensitive Gentle", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babor Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Baby",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Baby Acne", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Baby Anti Monkey Butt Diaper Rash",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Baby Balm", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Baby Bare Republic Mineral Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Baby Bare Republic Mineral Sunscreen Softstick Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Bum", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Bum Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Bum Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Bum Mineral Spf50 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Bum Spf50 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Bumps Buises Arnica", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baby Butz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Calming", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baby Colic", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baby Diaper Changing Gloves With Skin Protectant", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Baby Diaper Rash",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Salve", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Baby Diaper Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Baby Dove",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Baby Eczema Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Essentials Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Baby Face Stick Mineral Sunscreen Spf 40", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Fresh Scent Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Gas Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baby Infant Earache Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Baby Love", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Baby Love Personal Care",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Baby Mineral Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Mineral Sunscreen Roll-On", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Mineral Sunscreen, Spf 30", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Mucus Cold Relief Day And Night Value Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Baby Mucus Plus Cold Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baby Mucus Plus Cold Relief Daytime And Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Baby Nighttime Mucus Cold Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baby Nighttime Mucus Plus Cold Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baby Nighttime Oral Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Baby Nighttime Tiny Cold",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Baby Oral Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baby Petrolatum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Petroleum Jelly", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Skin", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Skin Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Soft Scent Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Tiny Cold Daytime And Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Baby Wipe Premium", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baby Xtracare Oatmeal", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babybig", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Babydiapercream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyfresh Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Alcohol Free Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Alcohol-Free Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Alcohol-Free Foaming Hand Sanitizer Mandarin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Alcohol-Free Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Eczema", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Kids Alcohol Free Foaming Hand Sanitizer Berry Berry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Kids Alcohol Free Foaming Hand Sanitizer Cupcake", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babyganics Spf 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Babyganics Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Babys Butt Aid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Babysons", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bac Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bac Off", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bac-D",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Bac-D Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Baccharis", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Bach Original Flower Remedies", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bach Original Flower Remedies Holly", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bach Original Flower Remedies Hornbeam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bach Pastilles Orange And Elderflower", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bacillinum Pulmo", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bacitracin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Bacitracin With Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bacitracin Zinc And Polymyxin B Sulfate", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Bacitracin Zinc, Neomycin Sulfate And Polymyxin B Sulfate", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bacitraycin Max Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bacitraycin Original", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bacitraycin Plus", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Back And Body", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Back And Large Extra Strength Cold-Hot Medicated Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Back And Leg Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Back And Muscle Pain",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Back And Neck Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Back Pain", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Back Pain And Sciatica", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Back Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Back To Normal Arnica", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Back, Neck And Sciatic Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Back-Up Plan Acne-Control Body Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Backache Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Backaid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Baclofen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intrathecal" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intrathecal" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Baclofen (Intrathecal)", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Bact Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bact-In", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bactakleen Bactashield (30Ml)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactakleen Bactashield (500Ml)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactakleen Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactakleen Kleen Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactakleen Marvekleen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactakleen Odor Killer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactakleen Ultra Mist (60Ml)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bacteda", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactekill Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bacteria Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bacteria Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bacteria Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bacteria Zero Premium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bacteria-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bacterial Immune Stimulator", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bacterial Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bacteriostatic Sodium Chloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
        ],
    },
    { Medication: "Bacteriostatic Water", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Bactestat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bacti-Gone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactimicina Childrens Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bactimicina Childrens Cough And Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bactimicina Childrens Pain And Fever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bactimicina Cough And Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bactimicina For Sore Throat", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bactine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Bactine Max", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactine Max Antiseptic Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactoflam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bactoshield Chg",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bactrim", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bactrim Ds", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bactro Disinfecting Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bactro Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baczol Cold Medicine", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baczol Expectorant", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bad Breath Killer", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Badass Botanicals Hemp Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Active Spf 30 Clear Zinc Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Active Spf 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Adventure Sport Clear Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger After-Bug Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Baby Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Baby Sunscreen Cream Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Broad Spectrum Spf 15 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Broad Spectrum Spf 35 Active Face Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Clear Lavender Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Clear Sport Spf 35 Kids All Season Face Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Clear Sport Spf 35 Natural Mineral All Season Face Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Clear Zinc Sunscreen Cream Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Diaper", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Essential Baby Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Badger Kids Broad Spectrum Spf 40 Natural Mineral Sunscreen Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Kids Mineral Sunscreen Cream Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Kids Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Lavender Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Spf 34 Anti-Bug Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Spf 35 Kids Clear Face Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Sport Clear Mineral Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Sport Mineral Sunscreen Cream Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Sport Spf 35 Clear Zinc Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Sport Spf 35 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Tinted Unscented Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Unscented Broad Spectrum Spf 30 Natural Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Unscented Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badger Unscented Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Badiaga", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baekcho-Ds", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bafiertam", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bag Balm First Aid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bag Balm Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bahama Bo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bahama Bo S Ocean Fresh Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bahama Bos Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bahama Bos Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bahia Grass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Bahia Grass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Bai Healing Cold Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bai Healing Heat Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bain De Soleil",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Baird Good Night", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Bak", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bak-Off Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baker'S Best Maximum Strength Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bakers Best Arthritis Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bakers Best Arthritis Pain Relief Cream With Hemp And Lavender", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bakers Best Arthritis Pain Relief With Hemp Seed", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bakewell Chemical", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bakewell Chemical Alcohol Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bakewell Chemical Foaming Alcohol Hand Santizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bakewell Chemical Foaming Antimicrobial Hs", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bal", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Balanced Salt", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Irrigation" }] }] },
    { Medication: "Balanceforce V", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Balancer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balancing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balcoltra", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bald Cypress Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Bali Bloc Spf 30 Cocoa Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Bloc Spf 30 Vanilla Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Bb Cream Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Bb Cream Spf 15 Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Bb Cream Spf 15 Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Cacao Tanning Oil Spf15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Cacao Tanning Oil Spf6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Face And Body Sunscreen Spf 50", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Hydrating Body Sunscreen Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Hydrating Face Sunscreen Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Pineapple Tanning Oil Spf6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Spf 15 Cacao Tanning Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Spf Cacao Tanning Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Tanning And Body Oil Spf 15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bali Body Tanning And Body Oil Spf 6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balm Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balma Septic Antiseptic Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balmex Adult Care Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balmex Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balmex Multi-Purpose", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balsalazide Disodium", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Balsamo De Ubre", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balsamo De Vaca Mascura", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balsang Pain Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balshi Md Calming Hemp Seed For Eczema Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Balversa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Balziva", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bama Bio Tech Hand Sanitizer 70 Ethanol Lemongrass Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bama-Bio Tech Hand Sanitizer 75 Ethanol Lemongrass Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bama-Bio Tech Hand Sanitizer 80 Ethanol Lemongrass Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bama-Bio Tech Hand Sanitizer 80 Ethanol Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bamboo Antibacterial Multi Surface Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bamboo Rosemary", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bamboo Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bambusa Aesculus",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bambusa Argentum",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Bambusa Aurum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bambusa Formica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bambusa Nicotiana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bambusa Pulsatilla",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bambusa Rhus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bambusa Stannum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bambusa Stibium",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bambusa Viscum Stannum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bamlanivimab", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Ban",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ban Purely Gentle", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ban-Aid", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banacocica-Aid Centella Peptide Emulsion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banacos Centellamide Cica Peptidecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banacos Cica-Aid Centella Peptide Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banacos Cica-Aid Centella Peptide Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Banana",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Banana Boat",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Banana Boat Baby Mineral Enriched Sunscreen Uva/Uvb Protection Broad Spectrum Spf 50Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Deep Tanning Sunscreen Spf 4", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Free 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral 100% Mineral Based Sunscreen Uva/Uvb Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral 100% Sunscreen Uva/Uvb Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral Foam Sunscreen Broad Spectrum Spf 50 Plus Amazing Apple", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral Foam Sunscreen Broad Spectrum Spf 50 Plus Berry Blast", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral Foam Sunscreen Broad Spectrum Spf 50 Plus Citrus Squeeze", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral Foam Sunscreen Broad Spectrum Spf 50 Plus Tropical Twist", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral Protection Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral Vanishing Blue Foam Spf 50 Berry Blast", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Mineral Vanishing Green Foam Spf 50 Amazing Apple", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Spf 65 Clear Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Kids Sport Sunscreen Roll-On Uva/Uvb Broad Spectrum Spf 60 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Light As Air Face Sunscreen Uva Uvb Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Mineral Sensitive Face Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Mineral Sensitive Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Simply Protect Sensitive Mineral Enriched Sunscreen Uva/Uvb Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Coolzone Clear Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Coolzone Clear Sunscreen Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Mineral 100% Mineral Based Sunscreen Uva/Uvb Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Mineral 100% Sunscreen Uva/Uvb Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Mineral Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Ultra Broad Spectrum Spf 50 Plus Sunscreen Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Ultra Clear Sunscreen Broad Spectrum Spf 65", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Ultra Face Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Ultra Sunscreen Broad Spectrum Spf 65", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sport Ultra Sunscreen Roll-On Uva/Uvb Broad Spectrum Spf 60 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Suncomfort Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Sunscreen Lip Balm Uva/Uvb Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Ultra Defense Clear Sunscreen Broad Spectrum Spf 100", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Ultra Sport Faces Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Ultra Sport Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Ultra Sport Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Boat Ultra Sport Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banana Scent Anti Germ", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Band Aid Antiseptic Cleansing",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Band Aid Antiseptic Cleansing Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Band Aid Antiseptic Cleansing To Go", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Band Leemyungnae Goyak", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Band-Aid Brand Hurt-Free Antiseptic Pain Relieving", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banda-Sil Gel Silver Wound Dressing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banda-Sil Liquid Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Banitore Alcohol Hand Rub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Banophen",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Banophen Diphenhydramine Hcl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Banzel",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Baptisia Tinctoria",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Baptisia Tinctoria Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baqsimi", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Baraclude",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Barbie Anticavity Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Barbie Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bare 40", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bare 40 Plus Ha", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bare 40 Plus Sa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bare Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bare Hands",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bare Perfecting Makeup", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bare Republic Bare Hands", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bare Republic Big Adventure Stick Spf 70", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bare Republic Clearscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bare Republic Mineral Stick Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bare Soak Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bareminerals Blemish Rescue Skin-Clearing Loose Foundation", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bareminerals Complexion Rescue Defense Radiant Protective Veil Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bareminerals Complexion Rescue Tinted Hydrating Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bareminerals Multi-Tasking Spf 20 Concealer", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bareminerals Original Foundation Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bareminerals Prime Time Bb Primer-Cream Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bareminerals Spf 20 Correcting Concealer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Barhemsys", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Baricitinib", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Barium Conchae", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Barium Conchae Mercurius Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Barley", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Barmicil Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Barrier Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Barrier Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bart Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bart Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bartonella Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bartonella Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bartonella Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baryta", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Baryta Acetica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baryta Bromata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Baryta Carbonica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Baryta Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baryta Iodata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baryta Muriatica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Baryta Phosphorica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basaglar", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Base Laboratories Base Numb Extra Strength Topical Anorectal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Base Laboratories Base Numb Topical Anorectal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Base Laboratories Hemorrhoidal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Base Laboratories Numb Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Base Numb Topical Anesthetic",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Base Numb Topical Anorectal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basf Cares Antiseptic Hand Rub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basf Cares Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Basic Care Acetaminophen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Acetaminophen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Acid Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care All Day Allergy",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Basic Care Antacid Fruit Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Arthritis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basic Care Arthritis Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Basic Care Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Berry Antacid Hbg", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Childrens Allergy Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Childrens Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Basic Care Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Childrens Cold And Cough And Sore Throat", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Childrens Cold Plus Flu", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Childrens Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Childrens Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Childrens Mucus Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Cough Dm",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Day Time Night Time Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Daytime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Daytime Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Daytime Cold And Flu Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Daytime Cold And Flu Severe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Daytime Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Daytime Nighttime Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Basic Care Daytime Severe Cold And Flu",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Daytime Severe Cold And Flu Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Daytime Severe Cold And Flu Nighttime Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Daytime Severe Cold And Flu Nighttime Severe Cold, Cough And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Daytime Severe Cold Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Dual Action Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Endure", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basic Care Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Basic Care Famotidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basic Care First Aid Antibiotic And Burn Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basic Care First Aid Antibiotic Plus Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basic Care Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Hair Regrowth", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Basic Care Hair Regrowth Treatment",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Basic Care Headache Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Heartburn Prevention", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Basic Care Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Basic Care Ibuprofen",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Infants Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Jock Itch Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Basic Care Lansoprazole",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Basic Care Lidocaine",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] },
        ],
    },
    {
        Medication: "Basic Care Loperamide Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Loperamide Hydrochloride And Simethicone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Miconazole", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Basic Care Miconazole 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Basic Care Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Basic Care Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Mucus Er", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Mucus Er Max", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Mucus Relief Cold Flu And Sore Throat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Mucus Relief Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Naproxen Sodium Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Basic Care Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Basic Care Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Nicotine Mini", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Night Time Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Nighttime Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Nighttime Cold And Flu Severe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Basic Care Nighttime Severe Cold And Flu",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Basic Care Nighttime Sleep Aid",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care No Drip Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Basic Care Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Basic Care Omeprazole And Sodium Bicarbonate", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Severe Cold And Flu", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Sf Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Sf Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Sinus Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Sinus Plus Headahce", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Sleep Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Tioconazole", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Basic Care Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Basic Care Tussin Cf Severe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Care Tussin Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Case Hydrocortisone Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basic Detox Core Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Basic Essentials Astringent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Basic Essentials Oil Free Acne Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bask", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bask Suncare",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bassia Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Bat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Bath And Beauty",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bath And Body Works", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bavencio", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Baxdela",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Baxter Of California Oil Free Moisturizer Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bay Rum Valle Verde", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bayer", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayer Aleve", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayer Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayer Aspirin Regimen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayer Aspirin Regimen Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayer Back And Body", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayer Chewable - Aspirin Regimen", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bayer Genuine Aspirin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bayer Headache", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayer Plus", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayer Womens", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bayes Alcohol Free Foaming Hand Sanitizer Eucalyptus Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bayes Alcohol Free Foaming Hand Sanitizer Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bayes Alcohol Free Hydrating Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bayes Alcohol Free Hydrating Hand Sanitizer Eucalyptus Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baylor Scott And White Hybrid Comfort", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Baza Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baza Cleanse And Protect", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baza Cleanse And Protect Odor Control", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baza Clear", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Baza Protect", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Broad Spectrum Spf 15 Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Broad Spectrum Spf 15 Light To Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Broad Spectrum Spf 15 Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Care Ultra Light Sunscreen Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Cream Broad Spectrum Spf 25 Light 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Cream Broad Spectrum Spf 25 Medium 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Cream Broad Spectrum Spf 25 Medium Light 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Cream Spf 20 Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Cream Spf 20 Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Cream Spf 20 Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Cream Spf 20 Nude", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Spf20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Tinted Treatment 12-Hour Primer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bb Tinted Treatment Primer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bc", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bc Arthritis", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bc Cherry", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bc Hygiene Max Ethyl Alcohol Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bc Max", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bc3 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bcg Vaccine", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Bd E-Z Scrub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bd E-Z Scrub 107", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bd Persist", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bdferm Biospray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bdnf", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Be Beautiful Makeup Base", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Beautiful Natural Bb Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Dazzle", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Flat. Frecent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Gone Bruises And Bumps", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Gone Insomnia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Be Gone Minor Arthritic Pain", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Gone Motion Sickness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Be Gone Poison Ivy", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Be Gone Scars", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Gone Sinus Congestion", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Be Gone Smoking", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Be Gone Stomach Disorders", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Be Gone Warts And Tired Feet", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Natural Organics Broad Spectrum Nude Tint Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Natural Organics Broad Spectrum Tan Tint Spf 30 73369-1003", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Natural Organics Broad Spectrum Tint Free Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Be Smart Get Prepared Hand Sanitizer", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beach Days Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beach Dream Glittery Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beach Dream Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beach Gypsy Glitter Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beach Gypsy Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beam", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Beanguard Gargle", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Beanguard Gargle Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Beard Society American Edition Antibacterial Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beast Hydrating Face Spf-20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beast Spit And Polish Face Spf-20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beautaime Hand Doctor", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beautaime Hand Doctor A", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beautaniq Beauty Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beautifier Alcohol Wipes 50Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beautox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Beauty 360 Accelerated Wrinkle Repair Moisturizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty 360 Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty And Health Research, Inc.", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Balm", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Before Age", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Concepts Antibacterial Hand Wipes Coconut Vanilla Sugar Plum Winter Berry 3 Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Beauty Concepts Antibacterial Hand Wipes Vanilla Sugar Peppermint Winter Berry 3 Pack Special Offer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Beauty Counter Dew Skin Moisturizer Dark", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Counter Dew Skin Moisturizer Deep", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Counter Dew Skin Moisturizer Fair", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Counter Dew Skin Moisturizer Light", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Counter Dew Skin Moisturizer Medium", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Counter Dew Skin Moisturizer Tan", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Formation Hand Sanitizer Individual", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Hydrating", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Secrets Antiseptic Hand Sanitizer 2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Secrets Antiseptic Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beauty Shield Broad Spectrum Sunscreen (Napoleon Perdis)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Beautycounter Countersun Mineral Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Beaver Brand Alcohol Antiseptic 80% Topical Solution Hand Sanitizer Non-Sterile Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bebelyn Colic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bebelyn Constipation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bebelyn Diarreah", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bebelyn Teething", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bebesup Hand Sanitizer(Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bebtelovimab", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Because", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beconase", DoseTypes: [{ DoseType: "Spray, Suspension", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Bed Bug Instant Bite Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bed Sore And Wound", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bedwetting",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bee Brand Medicated", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bee Clean - Beeswax Hand Sanitizer - Citrus And Peppermint", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bee Clean - Beeswax Hand Sanitizer - Lavender Oil", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bee Pollen", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bee Safe Hand Sanitizer With Manuka Honey And Lemongrass", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beech Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Beef",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Beekman 1802 Happy Place Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beeswax Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beeswax Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beeswax Spf 15 Lip", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beeswax Spf 30 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Behavior-Rite", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Being Well Hand Sanitizer 2 Pack", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Being Well Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Being Well Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Beis Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beis Advanced Hand Sanitizer (Promotional)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beis Clean Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Beis Hot Mess Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beis Hot Mess Antibacterial Wipes (Promotional)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beklyn Absolute Purifying Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beklyn Absolute Purifying Hand Gel Refreshing Aloe 180Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beklyn Absolute Purifying Hand Gel Refreshing Aloe 60Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beklyn Absolute Purifying Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beklyn Absolute Purifying-Spray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bekyree", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Belar Antibacterial Whith Chamomile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Belar Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Belbuca", DoseTypes: [{ DoseType: "Film, Soluble", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Belei Oilfree Facial Cleansing Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beleodaq", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Belif The True Cream Aqua Bomb Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bell Pepper", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Bella Flore", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Belladonna",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Belladonna And Opium", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Belladonna Betula Formica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belladonna Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belladonna Ex Herba 12 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belladonna Ex Herba 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belladonna Ex Herba 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belladonna Ex Herba 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belladonna Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belli Acne Control Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Belli Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Belli Pure Radiance Facial Sunscreen Tinted Protection Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bellis Perennis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bellis Perennis Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belly Balm", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Belmora Flanax", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belmora Melox Agrura", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belrapzo", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Belsomra", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Belson Alcohol Free Hand Saniziter", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Belson Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Belson Hand Sanitizer Ethanol 75 Percent Tissue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ben E. Keith Fihs", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ben Sherman Antibacterial Hand Sanitizer Sandalwood Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ben Sherman Antibacterial Hand Sanitizer Suede Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ben Sherman Antibacterial Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ben Sherman Antibacterial Hand Wash Charcoal Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ben Sherman Antibacterial Hand Wash Reserve Blend Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ben Sherman Antibacterial Hand Wash Sandalwood Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ben Sherman Antibacterial Hand Wash Suede Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Benadryl",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Benadryl Allergy Liqui-Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benadryl Allergy Plus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benadryl Allergy Ultratab", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benadryl Extra Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benadryl Extra Strength Itch Cooling", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benadryl Extra Strength Itch Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benadryl Extra Strength Itch Stopping", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benadryl Original Strength Itch Stopping", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benadryl Ultra Tab", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benadryl Ultratabs", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benazepril", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Benazepril Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Benazepril Hydrochloride And Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Benchmark Shield", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benchmark Shield Alcohol Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benchmark Shield Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benchmarks Derma Shield", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bencocaine Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Bendeka", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Benefit Hello Flawless Oxygen Wow Broad Spectrum Spf 25 Brightening Makeup - Im Plush And Precious", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benefit Hello Happy Air Foundation", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benefit Hello Happy Flawless Brightening Foundation Spf15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benefit Hello Happy Soft Blur Foundation Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benefit Plus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Benefit Weightless Moisture Face Moisturizer Broad Spectrum Spf 15 Sunscreen",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Benefix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Benemax", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benemax Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bengama Antifungal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bengay", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bengay Greaseless", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bengay Ultra Strength", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bengay Ultra Strength Non-Greasy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bengay Vanishing Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benicar", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benicar Hct", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benjamins Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Benlysta",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Benoa Acm Hand Sanitiser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benoa Premium Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bensal Hp", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benson Bottom Paint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bentyl", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Benzaclin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Benzalkonium Chloride",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Benzalkonium Chloride Antiseptic", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzalkonium Chloride Antiseptic Towelette", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzalkonium Chloride Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzamycin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Benzedrex", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Benzefoam", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzefoam Ultra", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzepro", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzethonium Chloride And Dyclonine Hydrochloride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzethonium Chloride Plus Dyclonine Hydrochloride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzhydrocodone And Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benzinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benznidazole", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benzo Jel", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Benzo-Jel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Benzocaine",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Dental" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Benzocaine And Benzethonium", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzocaine,Isopropyl Alcohol", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzodent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzoicum Ac", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benzoicum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Benzoil 12Hr Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzoin", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzoin Compound Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzoin Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Benzonatate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Benzoyl Acne Wash -2.5% Benzoyl Peroxide", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Benzoyl Peroxide",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Benzoyl Peroxide 5% Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Benzoyl Peroxide Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Benzphetamine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Benztropine Mesylate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Beovu", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravitreal" }] }] },
    { Medication: "Bepotastine Besilate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Bepreve", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Berberex Wound Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berberis Aquifolium", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berberis Aurum Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berberis Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berberis E Fruct. 10% Special Order", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berberis E Rad. 2 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Berberis Larix",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Berberis Oxalis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Berberis Oxalis Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Berberis Populus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Berberis Populus Adult Size", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Berberis Prunus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berberis Pyrite Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Berberis Quartz",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Berberis Sambucus Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berberis Urtica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Berberis Viscum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Berberis Vulgaris",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Berberis Vulgaris Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berinert", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Berkley And Jensen Allergy Relief",
        DoseTypes: [
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Berkley And Jensen Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berkley And Jensen Arthritis Pain", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Berkley And Jensen Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Childrens Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Famotidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Heartburn Treatment", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Minoxidil", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berkley And Jensen Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Mucus Relief Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Berkley And Jensen Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Berkley And Jensen Nicotine Polacrilex", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Berkley And Jensen Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Berkley And Jensen Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Sleep Aid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley And Jensen Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berkley And Jensen Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Berkley Jensen",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Berkley Jensen Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berkley Jensen Dishwashing And Antibacterial Hand", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berkley Jensen Foaming Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berkley Jensen Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berkley Jenson Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berley And Jensen Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bermuda Grass Smut", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Bermuda Grass, Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Bernard Jensens Povdine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berries And Cream Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berro Y Cebolla", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berry Blast Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Blast Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Bliss", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Blitz Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Breezer Throat Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berry Breezer Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berry Breezer Throat Relief Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berry Flavored Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Medley Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berry Punch Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Scent Anti Germ", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Scented Clip On Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Soother Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Berry Tart Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Berry Throat Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Beryllium Fluoratum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Beryllium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Beryllium Oxydatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Beryllium Sulphuricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Besafe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Besafe Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Besivance", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Besponsa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Besremi", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Besser Health", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Accessory Group Cotton Candy Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Accessory Group Sweet Strawberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Age Spot Remover", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Best Choice",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Best Choice Anti Nausea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Anti-Itch Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Bisacodyl Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Childrens Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Earwax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Best Choice Earwax Removal Kit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Effervescent Antacid Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Fiber Therapy", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Itch Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Itch Stopping", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Lice Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Medicated Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Medicated Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Choice Pain Relief Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Sugar Free Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Choice Vapor Steam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Best Ground Antiseptic Hand Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Health Berry Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Health Berry Soothers Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Health Cherry Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Health Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Health Severe Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Best Mom Moisturizing Hand Sanitizer, Peony And Cherry Blossom", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Sister Moisturizing Hand Sanitizer, Tuberose And Jasmine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Best Western Ecossential Antiseptic Hand Wipe 70% Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Besthealth Herbal Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Besthealth Orange Antacid Soft Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bet-R-Prep Bisacodyl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bet-R-Prep Magnesium Citrate Oral", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beta 1 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular" }] }] },
    {
        Medication: "Betadine",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }, { Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Betadine Antiseptic Oral Rinse", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betadine Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Betadine Dry Powder", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Betadine Gargle", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betagan", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Betaine Anhydrous For Oral Solution",
        DoseTypes: [
            { DoseType: "For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Betalido Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Infiltration; Intra-Articular; Intralesional; Intramuscular; Perineural; Topical" }] }] },
    { Medication: "Betaloan Suik", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular" }] }] },
    {
        Medication: "Betamethasone Dipropionate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream, Augmented", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion, Augmented", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment, Augmented", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Betamethasone Dipropionate Usp, 0.05%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Betamethasone Sodium Phosphate And Betamethasone Acetate", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular" }, { Route: "Intra-Articular; Intralesional; Intramuscular; Soft Tissue" }] }] },
    {
        Medication: "Betamethasone Valerate",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Betapace", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betapace Af", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betasept", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Betaseron", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Betaxolol",
        DoseTypes: [
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Betaxolol Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bethanechol Chloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bethkis", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Betimol", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Betonica Rosmarinus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betoptic S", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Betr Acid Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betr All Day Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Betr Allergy Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Betr Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Betr Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betr Cold And Flu Daytime Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betr Cold And Flu Nighttime Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betr Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betr Headache Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Betr Pain Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Betr Sleep Aid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betr Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Better For Daily Use Eaae Disinfectant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Better Living", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Better Living Brands Childrens Fever And Pain Reliver", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Better Nature Pain Relief Balmx", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Better Nature Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Better Nature Pain Relief Sport Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Betula Argentum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Betula E Cort. 2 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betula E Fol. 4 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Betula Lenta Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Betula Nigra Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Betula Rhus Special Order", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Betula Spiraea",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Between The Lines", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beverhill Hair Tonic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bevespi Aerosphere", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Bexarotene",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bexsero", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Beyaz", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Beyond Activated Coconut Charcoal And Sea Salt", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Beyond Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beyond Sunscreen Broad Spectrum Spf 55", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Beyou Orange", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Beyou Pink", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Bezalkonium Chloride Towelettes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bf-Paradac", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bff Skin Perfector Spf 30 Broad Spectrum Sunscreen Deep", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bff Skin Perfector Spf 30 Broad Spectrum Sunscreen Deepest", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bff Skin Perfector Spf 30 Broad Spectrum Sunscreen Light", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bff Skin Perfector Spf 30 Broad Spectrum Sunscreen Light/Medium", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bff Skin Perfector Spf 30 Broad Spectrum Sunscreen Lightest", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bff Skin Perfector Spf 30 Broad Spectrum Sunscreen Medium", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bff Skin Perfector Spf 30 Broad Spectrum Sunscreen Medium/Deep", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bgecare Alcohol Prep Pad Isopropyl Alcohol Swab", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bh Supplies", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bha Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bhawins Isopropyl Alcohol Antiseptic 75 Percent Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bhi Acne", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Arnica Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Arthritis", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Back", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Bodypure", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Calming", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Cold", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Constipation", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Cough", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Diarrhea", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Flu Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Hemorrhoid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Migraine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Nausea", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Sinus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Skin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Spasm Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Throat", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Uri-Control", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bhi Uri-Control Rx", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bhs Beautiful Hair And Scalp",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bht", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bi Electro", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bi-Mart Extra Strength Antacid Assorted Berry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bi-Mart Extra Strength Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bi-Mart Regular Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bi-Mart Regular Strength Antacid Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bi-Mart Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bi-Mart Triple Antibiotic Plus Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bi-Mart Ultra Strength Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bi-Mart Ultra Strength Antacid Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bibilip Aloe Cica Dual Moisturizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bibilip Honey Coco Total Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bicalutamide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bicillin C-R 900/300", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Bicillin Cr", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Bicillin L-A", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Bicnu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bidil", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biest 2.5 Estrogen Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Biest 2.5 Estrogen Cream (Lavender Scented)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Biest 5.0 Estrogen Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Big Cloud Defend", DoseTypes: [{ DoseType: "Spray, Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Cloud Sky Master Lip Balm Spf 15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Island Basics", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Lots Soundbody Sheer Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Lots Soundbody Spf 30 Sport Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Lots Soundbody Spf 50 Sport Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Lots Soundbody Spf 70 Sun Defense", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Lots Soundbody Sun Defense Screen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Mood Spf 30 Milky Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Wipes Heavy Duty", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Big Wipes Multi-Purpose", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bijuva", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biktarvy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bile Acids Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biliousness Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biliousness Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Billion Dollar Beauty Hand Sanitizer Formulated With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Billiousness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biltricide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bimatoprost",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Bing Cherry - Cough Relief", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bing Cherry Cough Antitussif Relief", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Binosto", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Argimony", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Aspen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Beech", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Biological Detox", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Centaury", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Chem Detox", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Cherry Plum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Chestnut Bud", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Chicory", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Co-Enzyme Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Crab Apple", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Cytotox Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Dtuss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Electrolyte", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Elm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Galium Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Gentian", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Glyoxal Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Gorse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Hand Sanitizer Ge L", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bio Heather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Hornbeam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Impatiens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Larch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Lymph Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Lymphomyosot", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Mimulus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Mood Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Mustard", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Myelin Protein", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Olive", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Pine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Placenta Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Psorinum Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Rad Detox", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Red Chestnut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Res Q", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Rock Rose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Rock Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Scleranthus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bio Spectra",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bio Star Of Bethlehem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Thalamus Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Tonico", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Tonsilla Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio V Antacid Berry 96Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio V Antacid Fruit Flavors", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio V Antacid Ultra Berry 72Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio V Antacid Ultra Fruit 72Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio V Antacid Wintergreen 96Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Vast Dr.Cure Cell New Clean Safe Hand Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bio Vervain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Viscum Phase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Walnut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio White Chestnut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Wild Oat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Wild Rose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio Willow", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio-B Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio-Cheer", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio-Cnex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio-G-Tuss", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio-Immune", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio-Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bio-Relief H", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bio-Rytuss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio-S-Pres", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bio-Sorb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bio-Z-Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bioarmour Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioblast Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biobron", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Biocence",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Biocentris E2 Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biochemic Phosphate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biochemistry Pain Relief Foot Active", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biocof", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biocool", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biocool Focus On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biocorneum Advanced Scar Treatment Anti-Itch", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biocorneum Plus Broad Spectrum Spf 30 Advanced Scar Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biocorneum Plus Broad Spectrum Spf 30 Advanced Scar Treatment Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biocorneum Plus Spf 30 Advanced Scar Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biocotron", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biocotron-D", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biodcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biodesp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bioelectro Extra Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bioflexor", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Biofreeze",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Biofreeze Colorless", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Colorless Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Flexible Relief Strip", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Foot Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Foot Pain Relief Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Biofreeze Overnight Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Patch Multi Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Biofreeze Precision Relief Pen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Biofreeze Professional",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Biofreeze Professional Colorless", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Professional Colorless Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Professional Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biofreeze Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biogeno Care Hand Sanitizer Gel (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biogerm Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biogesic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bioglo", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Biogtuss",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Biohazard Bag, Antiseptic Towelettes And Towels", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biological Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biolustre Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biolustre Disposable Hand Cleansing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biolustre Hygienic Hand Cleaning Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biolustre Hygienic Hand Cleansing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biolustre Liquid Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biom Sanitizing Alcohol Wipes - Bergamot Bloom", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biom Sanitizing Alcohol Wipes - Eucalyptus Chill", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biomedik Premium Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Biomedik Premium Hand Sanitizer Mist",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Biomedisun Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Biomee Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biomi Action De Gala Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bion Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Bionatuss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bionel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bionica Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bioorbil Antibacterial Handsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopar Delta-Forte", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biophresh", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    {
        Medication: "Bioplasma",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bioplasma Sport With Electrolytes", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bioprotect Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioprotect Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioprotect Hand Sanitizer Isopropyl Alcohol Antiseptic 75 Percent", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure 50Ct Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipe (1400 Ct)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipe 0.12% Benzalkonim Chloride (250 Ct)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipe Benzalkonium Chloride 0.12% (250 Ct)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipe Unscented (1400 Ct)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipes Benzalkonium Chloride 0.12% - 250 Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipes Citrus Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipes Citrus Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipes Fresh Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Antibacterial Hand Wipes Unscented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biopure Personal Protective", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Biopure Shield", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Biore",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bioresist Reinforcing Effect Face Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biorestore Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biorphen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Biosafe Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioshell", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bioshield 99", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioshield 99 Alcohol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biosolis Extreme Fluid Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biosolis Face Anti Aging Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biosolis Face Uva Sunscreen Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biosolis Sun Milk Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biosolis Sun Milk Spf 50 Kids", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biosolis Sun Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biosolis Sun Spf50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biospec", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biossance Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biossance Squalane Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biotemper", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biotemper Foot Cream", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biotene", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biothrax", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Biotin Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotipo Antibacterial Handwashing", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biotipo Pharma Medical Biogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biotitum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Biotop Professional Antiseptic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Biotox Bac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotox Dental Foci Repair", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotox Dip", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotox P", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotox Pest Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotox Std", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotox T", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotpres", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biotrue Hydration Boost", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Biovanta Double Action", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biovanta Trip Action Green Apple And Honey", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biovanta Trip Action Lemon And Honey", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biovanta Trip Action Tart Cherry And Honey", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biowash", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biowash First Aid And Wound Care", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biowash Hand Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biowash Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biowash Surface Cleaner", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioxterminate Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioxterminate Hand Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioxterminate Pro Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioxygen Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bioyouth Advanced Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biozone", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bipolaris Sorokiniana",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Birch Juniper Rejuvenation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Birch Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Birch Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Bird Flu H5N1 Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Birthday Cake Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bisac-10 Bisacodyl Stimulant Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Bisacody", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bisacodyl",
        DoseTypes: [
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bisacodyl 5Mg", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bisacodyl Enteric", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bisacodyl Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bismatrol",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bismuth",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bismuth Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bismuth Magnesite Comp.", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bismuth Stibium", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bismuth Subsalicylate",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bismuth Subsalicylate 262 Mg", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bismuthum Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bismuthum Oxydatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bismuthum Phosphoricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bismuthum Subnitricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bismutina", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bisolvine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bisoprolol", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bisoprolol Fumarate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bisoprolol Fumarate And Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bite And Sting Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bite And Sting Relief Stick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bitemd External Analgesic First Aid Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Biting Insect Antigen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biting Insect Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Biting Insect Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bittol Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bittol Anti-Bacterial Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bittol Anti-Bacterial Hygienic Hand Cleaning Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bittol Anti-Bacterial Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bittol Anti-Bacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bivalirudin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intracavernous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Bivalirudin In 0.9% Sodium Chloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Bivigam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Bjwc Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Black And White Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Bass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Black Bean Anti Hair Loss", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Bean Anti Hair Loss Root Tonic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Bean Anti Hair Loss Sham Poo Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Black Bean Anti Hair Loss Treatment", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Carpenter Ant", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Black Cherry Champ Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Cherry Cough Drops", DoseTypes: [{ DoseType: "Pastille", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Black Cottonwood Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Black Fly Guard", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Black Girl Sunscreen",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Black Girl Sunscreen Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Girl Sunscreen Spf 50 Kids", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Locust Blossom", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Black Oak Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Black Ops 70 Percent Alcohol Foaming Skin Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Ops Ab Antibacterial Foaming", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Ops Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Black Pepper",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Black Repair Hair Boosting Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Snail Collagen Eyecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Snail Collagen Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Snail Collagen Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Snail Collagencream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Snail Collagenemulsion", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Black Walnut Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Black Walnut Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Black Willow Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Black-Sweet Birch Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Blackberry Smoothie Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blackhead Clearing Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blackhead Clearing Scrug", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blackhead Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blackhead Solutions 7 Day Deep Pore Cleanse And Scrub Acne Medication", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blackhead Solutions Self-Heating Blackhead Extractor Acne Medication", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blad-R", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bladder - Kidney",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bladder Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladder Irritation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladder Irritation And Uti", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladder Kidney Meridian Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladder Kidney Meridian Opener", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladder Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladder Tone", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladder Urinary", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladder Uti", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bladdertone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Blade For Men Medicated Body Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blanc Diva Gleam Coverage Cushion Natural Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blanc Diva Gleam Coverage Cushion Pink", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blanc Diva Gleam Coverage Cushion White", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blanc Diva Gleam Coverage Cushion Yellow", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blanc Diva Multi Protection Fitting Sunbase", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blanc Diva Rebirth", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blanc Diva Rebirth Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blastocystis Hominis Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Blatella Germanica", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Blatrol Bladder Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Blatta Orientalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ble Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blemfree", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blemish", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blemish Bully Acne Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blemish Buster Solution Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blemish Clearing Facial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blemish Control Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blemish Control Basics Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Blemish Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blend Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blenrep", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Bleomycin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intrapleural; Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intra-Arterial; Intramuscular; Intravenous" }, { Route: "Intramuscular; Intrapleural; Intravenous; Subcutaneous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Intramuscular; Intrapleural; Intravenous; Subcutaneous" }] },
        ],
    },
    { Medication: "Bleph-10", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Blephamide",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Bless You", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bli Holding", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bli Holdings", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blinc Antibacterial Sanitizer Moisturizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blincyto", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bling Day Everyday Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blink Gel Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Blink Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blink Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Blink Triple Care", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Blippi Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blisovi 24 Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Blisovi Fe 1.5/30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Blisovi Fe 1/20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bliss", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bliss Block Star - Invisible Daily Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bliss Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bliss Gvs", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Bliss Gvs Hemorrhoidal", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Blisterzone", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Blistex",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Blistex Advanced Care (Blistex Medicated Balm, Blistex Complete Moisture, Blistex Five Star Lip Balm - Broad Spectrum, Blistex Superfruit Soother)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Blistex Complete Care (Blistex Lip Medex, Blistex Medicated Lip, Blistex Five Star Lip Protection Broad Spectrum, And Blistex Complete Moisture)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Blistex Global Blend", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blistex Ivarest Poison Ivy Itch", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blistex Lip Medex", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blistex Medicated Berry Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blistex Medicated Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blistex Medicated Lip Balm Variety Pack (Blistex Medicated Berry Lip Balm, Blistex Medicated Lip Balm, Blistex Medicated Lip)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Blistex Medicated Lip Balm Variety Pack (Blistex Medicated Berry Lip Balm, Blistex Medicated Lip Balm, Blistex Medicated Mint Lip Balm)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Blistex Medicated Mint Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blistex Superfruit Soother", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blistex Triple Essentials", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blitz Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blockaid", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blood Clenz Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Blood Clotting First Aid", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blood Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Blood Sugar", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bloom Health Advanced Hand Sanitizer With Moisturizer And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bloom Health Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bloom Wellness", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blossom Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blowfish", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Blowhale Deodorant Senitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bloxiverz", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Blt 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blt 2", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blt 3", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blt Skin Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Blue Arrow Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Blue Arrow Hand Sanitizer 1 Gallon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Arrow Hand Sanitizer 1.8Oz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Devil Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Gel Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Green", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Green Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Herbal Spot Treatment Salicylic Acid Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Ice Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Active Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Active Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Active Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Baby", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Baby Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Baby Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Face", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Blue Lizard Kids Spf 50",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Blue Lizard Kids Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Kids Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Regular", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Sensitive Face Sunscreen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Blue Lizard Sensitive Spf 50",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Blue Lizard Sensitive Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Sensitive Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Sheer Face Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Sheer Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Sport Original Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Sport Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Lizard Sport Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Monarch Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Raspberry Radiance Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Sandalwood", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Save The Animals", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Blue Spring Foot And Leg Comfort Cream With Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Star Medicated", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue Tube", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue-Emu Lidocaine Dry", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue-Emu Lidocaine Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue-Emu Maximum Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue-Emu Pain Relief Micro-Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blue-Emu Ultra With Hemp Seed", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blueberry", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Blueberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bluegum Eucalyptus Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Blues - Mood - Emotions",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bluevado Sunfix", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Blur Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intraocular" }] }] },
    { Medication: "Bnc Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bnc Hand Sanitizer Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boa Vida Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boa Vida Calvida", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boa Vida Canopy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boa Vida Cleansing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boa Vida Recovery Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boardwalk Antibacterial Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boardwalk Antibacterial Ltion So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boardwalk Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bob Barker Antibacterial Un-Wrapped Body", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Bob Barker Antibacterial Unwrapped Body", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] }] },
    { Medication: "Bob Barker Antibacterial Wrapped Body", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] }] },
    { Medication: "Boba Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bobbarker Antibacterial Wrapped Body", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Cc Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Extra", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Extra Spf 25 Tinted Broad Spectrum Moisturizing Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Intensive Serum Foundation Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Intensive Serum Foundation Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Intensive Skin Serum Foundation Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Lip Balm Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Skin Foundation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Spf 50 Protective Face Base", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bobbi Brown Tinted Moisturizer Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bodewell Eczema Daily Calming", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bodewell Eczema Daily Soothing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bodewell Psoriasis Calming", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bodewell Psoriasis Scalp Relief Treatment", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bodewell Psoriasis Soothing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Acne Treatment", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Action Products Anal Glide Extra", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Action Products Butt Eze Benzocaine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Action Products Climax Control Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Action Products Endless Love For Men", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Action Products Higher Control Male Climax", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Action Products Prolong Lubricating", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Body Balance",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Body Butter Broad Spectrum Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Glove For Men Antibacterial Hand Sanitizer Charcoal And Bamboo Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Glove For Men Antibacterial Hand Sanitizer Tea Tree And Vitamin E Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Glove For Men Antibacterial Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Glove For Men Antibacterial Hand Wash Charcoal And Bamboo Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Glove For Men Antibacterial Hand Wash Hemp And Lemongrass Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Glove For Men Antibacterial Hand Wash Sea And Surf Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Glove For Men Antibacterial Hand Wash Tea Tree And Vitamin E Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Melt", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Merry Hand Sanitizer Scent Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Merry Spf 15 Sunscreen Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions 65 Percent Alcohol Antibacterial Hand Sanitizer Peppermint", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions 65 Percent Alcohol Antibacterial Hand Sanitizer Sugar Cookie", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions 65 Percent Alcohol Antibacterial Hand Sanitizer Sweet Pine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions 65 Percent Alcohol Antibacterial Hand Sanitizer Winterberry", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Aloha 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Aloha Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Aloha Infused With Vitamin E Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Antibacterial Hand Wipes Vanilla Sugar Winter Berry Honey Almond 3 Pack Special Offer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Body Prescriptions Antibacterial Hand Wipes Warm Vanilla Spiced Cider Winter Berry 3 Pack Special Offer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Body Prescriptions Apple Cider 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Apple Cider Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Apple Cider Infused With Vitamin E Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Butter Pecan 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Butter Pecan Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Butter Pecan Infused With Vitamin E Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Coconut 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Coconut Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Coconut Infused With Vitamin E Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Eucalyptus Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Fern 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Fern Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Fern Infused With Vitamin E Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions French Lavender Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Grapefruit 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Grapefruit Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Grapefruit Infused With Vitamin E Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Infused With Vitamin E Peppermint Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Infused With Vitamin E Sugar Cookie Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Infused With Vitamin E Sweet Pine Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Infused With Vitamin E Winterberry Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Lemon Citrus 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Peppermint Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Pumpkin Spice 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Pumpkin Spice Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Pumpkin Spice Infused With Vitamin E Antibacterial Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Rose Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Sugar Cookie Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Sweet Pine Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Vanilla 65 Percent Alcohol Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Vanilla Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Prescriptions Winterberry Infused With Vitamin E Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Seal Ultra Reparing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bodyanew", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bodyanew Multipack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bodymed Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bodyographypro Anti-Bacterial Sanitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bodyvita Shower Filter", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boil Ease", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boils Eczema", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Boilx", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bold Wipes Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bolym-Ex Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bon Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bonafide Innovations Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bonajour Green Tea Water Bomb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bonblissity Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bondi Sands Broad Spectrum Hydra Uv Protect Spf 70 Face Fluid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bondi Sands Broad Spectrum Hydra Uv Protect Spf 70 Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bondi Sands Fragrance Free Face Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bondi Sands Fragrance Free Spf 30 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Bondi Sands Fragrance Free Spf 50 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bondi Sands Sport Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bondi Sands Sport Spf 50 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bondi Sands Sunny Daze Hydrating Broad Spectrum Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bondi Sands Toasted Coconut Lip Balm With Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bone Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bone Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bone Injuries", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bone Marrow Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bone Repair", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bone Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bone Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bonearth Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Boneforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bongin Boncell Ecm Boost 195 Ha", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bongin Boncell Rejuvenating Mask Pack", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bongin Rebalancing Gin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bongin Revitalizing Gin Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bonine", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bonita Alcohol Sanitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boniva", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bonjesta", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Boo Boo Magic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boogie Bottoms", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boosietizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boosietizer Peach Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boostrix", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Booyoung Mask", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bop Whiteningtoothpaste", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Borago Lavender", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Borago Lavender Cum Cupro", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Borago Nicotiana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Borax",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Borel Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Born Basic. Anti-Bac Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Born To Shine Hand Sanitizer Candy Apple Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Born To Shine Hand Sanitizer Watermelon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boron", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Boron Oxydatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Borr Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Borrelia Babesia Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Borrelia Burgdorferi", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Borrelia-Babesia Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bortek Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bortek E-2 Foaming Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bortek E-3 Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bortek Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bortek Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bortezomib", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }, { Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Bos Taurus Skin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Boscia All In One B.B. Eye Brightener Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boscia B.B. Light Broad Spectrum Spf 27 Pa Self-Adjusting Shade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boscia Bb Broad Spectrum Spf 27 Pa Self Adjusting Shade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boscia Bb Bronze Broad Spectrum Spf 27 Pa Self-Adjusting Shade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boscia Bb Spf 27 Pa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boscia Daily Defense Sunscreen Broad Spectrum Spf 50 Pa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boscia Daily Hydration Spf 15 Broad Spectrum For Normal To Oily Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boscia Restorative Day Moisture Broad Spectrum Spf 15 For Dry Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boscia Self-Defense Vital Antioxidant Moisture Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bosentan",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bosley Md Extra Strength For Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bosley Md Regular Strength For Women", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bosley Professional Strength Hair Regrowth Treatment Regular Strength For Men", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bosley Professional Strength Hair Regrowth Treatment Regular Strength For Women", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bosulif", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bota C Aqua Rejuvenating Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bota C Reset", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botaneco Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botanic Skincare Acne Defense Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botanical Soothing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botanics All Bright Hydrating Day Cream Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botanics Shine Away Mattifying Day Cream Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botanics Triple Age Renewal Day Cream Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Botanika Life",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Botanimedix Onychorx Antifungal Nail Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botanimedix Tinearx Antifungal Drying Emulsion", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botao Baby Diaper Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botao Baby Mineral Foam", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botao Kids Mineral Foam", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bothinns Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bothrops Lanceolatus", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Botoki V Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Botox", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intradermal; Intramuscular" }] }] },
    { Medication: "Botox Cosmetic", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Botrytis", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Botrytis Cinerea",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Bottlebrush Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Boudreauxs",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Boudreauxs Butt Barrier", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boudreauxs Butt Diaper Rash Max Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boudreauxs Butt Diaper Rash Original", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Boudreauxs Rash Kicking", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Boudreauxs Rash Kicking Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bouquet Cooling Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bourn Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bovista", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bowel - Digestive Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bowel Pathogen Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bowel-Digestive Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Box Elder Maple Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Box Elder Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Boys Lie Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bp Cleansing Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bp Reg", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bp Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bp Wash", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bpo 4%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bpo 6%", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bpo 8%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bpt10.1 Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bpt3.1 Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bqcell Revirgin Gung", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Enteral; Vaginal" }] }] },
    { Medication: "Brace Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brachial Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Braco Manufacturing Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brady Clear Antimicrobial Foaming", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Braftovi", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brain Balance Core Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brain Enhancement Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brain Power", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brain Supp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brain Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Brazil Nut",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Brazilian Menthol Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brazilian Menthol Pain Relieving Roll On", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brazmedics Arnica Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breacold Adult", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breacold Children", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bread Cravings", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breakout Control", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breast Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breast Care - Fibrocystic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breath Fresh Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breath Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breath Mellow Azalea Peach", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breath Mellow Water Lily", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Almond", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Amber", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Cocoa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Pearl", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Taupe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflage Light Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Camouflagelight Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Concealer Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Concealer Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Concealer Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Tint 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Tint 2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Tint 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathable Tint 4", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathaway", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breathe Easier Formula", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Breathe Easy Chest", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathe In Breathe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breathe-Ease", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Breathrx With Zytex",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Dental; Oral" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Breathtone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breeze", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial 150 Ml", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial Handsoap Berry Mixture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial Handsoap Chamomile Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial Handsoap Chamomile And Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial Handsoap Lavender And Jasmine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial Handsoap Lavender Jasmine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial Handsoap Pink Orchid And Coconut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial Handsoap Pink Orchid Coconut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Antibacterial Wet Wipes 60 Pcs", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Hand Sanitizer With Vitamin E And Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Multipurpose Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Sanitizing Berry Fresh Scent", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Sanitizing Citrus Fresh Scent", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Sanitizing Crisp Linen Fresh", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breeze Sanitizing Ocean Fresh Scent", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Breezers", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breo Ellipta", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Brevibloc", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Brevital Sodium", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous; Rectal" }] }] },
    { Medication: "Brexafemme", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Breyanzi", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Breyna", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Breztri", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Brickell Daily Defense Face Moisturizer Spf-20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brickell Element Defense Moisturizer Spf-45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bridge Street Hemp Full Spectrum Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bridge Street Hemp Full Spectrum Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bridged Chlorinated Ring Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bridged Phenyl Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bridged Ring Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bridion", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Briellyn", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bright Diamond", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 11 Ivoire Pur Pure", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 12 Ecru Ecru", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 20 Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 25 Beige Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 31 Amande Almond", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 33 Noix Walnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 35 Bronze", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 44 Praline", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 46 Noix De Pecan Pecan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Future Skin Tint Spf 25 7 Ivoire Clair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Guard Spf-30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Guard Sport Spf", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Guard Water-Resistant Spf-30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Science Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Solutions", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Solutions Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Solutions Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Solutions Antimicrobial Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Solutions Antiseptic", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Solutions Fdf", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright Solutions Foaming Antimicrobial So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bright-Eyed 100% Mineral Eye Cream Broad Spectrum Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brightening Primer Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brightening Trio", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brightenup Illuminating With Mineral Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brighter Day Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brighton Professional Antibacterial Foaming Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brighton Professional Antibacterial Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brighton Professional Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brighton Professional Premium Antibacterial Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brightspark", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brightuning Peptide Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brilinta", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brille Brille Sterilizing Water Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brillia", DoseTypes: [{ DoseType: "Pill", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brillia Health Cold-Flu Recovery", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brimonidine", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Brimonidine Tartrate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Brimonidine Tartrate And Timolol Maleate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Brimonidine Tartrate/Timolol Maleate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Brineura", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bring Green Carrot Vita Dark Spot Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bring Green Tea Tree Cica Tone Up Sun Cushion", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bring Green Tea Tree Tone Up Sun Cushion", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bringgreen Tea Tree Cica Soothing Sun", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brinzolamide", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Brioppe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brioschi Effervescent Antacid", DoseTypes: [{ DoseType: "Granule, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brisa", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brisdelle", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Briviact",
        DoseTypes: [
            { DoseType: "Injection, Suspension", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Broad Spectrum Moisturizing Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Moisturizing Sunscreen-Spf 15, Octinoxate, Oxybenzone", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Cherry Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Citrus Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Coconut Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Iced Pear Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Mint Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Pomegranate Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Tropical Smoothie Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Unflavored Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Vanilla Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 15 Vanilla Mint Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 For Face And Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Mineral Sun Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Sun Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Tinted Mineral Defense Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Tinted Physical Defense Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Tropical Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Up The Anti", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Water Resistant (80 Minutes)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 30 Water Resistant Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 35 Eco", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 50 Babies Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 50 Faces Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf 50 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf45", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Spf50 Water Resistant (80 Minutes) Mineral Sunscreen Uva Uvb Protection Stick For Kids", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Water Resistant Spf 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Water Resistant Spf 50 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Water Resistant Spf 50 Mineral Sunscreen Babies", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Broad Spectrum Water Resistant Spf 50 Mineral Sunscreen Faces", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Broccoli",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Broda Acne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bromfed Dm", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bromfenac", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Bromfenac Ophthalmic Solution 0.09%", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Bromium",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bromo Seltzer", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bromocriptine Mesylate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bromoethanum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Brompheniramine Maleate, Pseudoephedrine Hydrochloride And Dextromethorphan Hydrobromide",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Brompheniramine Maleate, Pseudoephedrine Hydrochloride And Dextromethorphanhydrobromide", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brompheniramine Maleate, Pseudoephedrine Hydrochloride,", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brompheniramine Maleate, Pseudoephedrine Hydrochloride, And Dextromethorphan Hydrobromide", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brompheniramine Maleate, Pseudoephedrine Hydrochloride, Dextromethorphan Hydrobromide", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brompheniramine, Pseudoephedrine, Dextromethorphan", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brompton And Langley Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bromsite", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Bromus Inermis Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Broncare",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bronchi", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronchi 17 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronchi Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronchi-Pertu", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronchial And Breathing Aide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }, { Route: "Sublingual; Sublingual" }] }] },
    {
        Medication: "Bronchial Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bronchial Cough Therapy", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronchiforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronchitis Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronchitol", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Bronchitone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronci", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronco Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Broncochem",
        DoseTypes: [
            { DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Broncofin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Broncolin",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bronkaid", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bronz Repair Sea And Tropics Face Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bronzing Primer Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brookstone Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brotapp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brotapp Dm Cold And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brotapp Pe-Dm Cough And Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brovana", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Brown Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bruc Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brucella Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bruise Md", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bruises-Muscle Soreness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brukinsa", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Brush And Go", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brush Buddies Value 2 Pack Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Brush On Block Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Brush On Block Nude Tint Protective Lip Oil Broad Spectrum Spf 32", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Brut Classic Anti-Perspirant And Deodorant",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bryhali", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bryonia",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Bryonia Alba",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Bryonia Alba 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Bryonia Alba 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Bryonia Alba Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bryonia Apis Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bryonia Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bryonia Eucalyptus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bryonia Spongia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bryonia Stannum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bryophyllum", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bryophyllum 50%", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bryophyllum Argentum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bryophyllum Argentum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bryophyllum Avena", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bryophyllum Conchae", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bryophyllum Conchae Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bryophyllum E Fol. 10",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bryophyllum E Fol. 5%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bryophyllum E Fol. 50% Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bryophyllum Quartz", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bsafe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bsafe3 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bsafe3 Sanitizing",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bsp 0820", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular" }] }] },
    { Medication: "Bss", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Bss Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Bt Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bt100S", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bt21 Aloe Vera Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bu Spf 30 Broad Spectrum Alcohol-Free Performance Sunscreen - Natural Citrus Scent", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bu Spf 30 Broad Spectrum On Alcohol-Free Performance Sunscreen - Fragrance Free", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bu Spf 50 Kids Broad Spectrum Alcohol-Free Performance Sunscreen - Fragrance Free", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bu Spf 50 S Broad Spectrum Alcohol-Free Performance Sunscreen - Natural White Sage Scent", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bubble Cheee Gold Plus Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bubble Gum Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bubble Gum Hand Sanitizer Girl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bubble Gum Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bubblegum Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bubbles R Better Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Buckleys Formo Cresol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Buckwheat",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Buddate Inonotus Obliquus Water Brilliance Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Buddate Lotus Moisture Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Buddate Reges Intensive Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Budesonide",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Inhalant", Routes: [{ Route: "Oral" }, { Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Budesonide (Enteric Coated)", DoseTypes: [{ DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Budesonide And Formoterol Fumarate Dihydrate", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Budesonide Inhalation", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Budesonide Inhalation Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Budesonide Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Budpak Vitamin A And D", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Buffered Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Buffered Penicillin G Potassium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Bufferin Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bufferin Regular Strength Pain Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bufo Rana", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bug Bites - Itch Stopper", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bug Bouncer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bulbinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bulbinum 4Ch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bull Frog Spf 50", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bullet Line", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bullfrog", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bumetanide",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bumex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bump And Bruise Reliever", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bunee Hair Growth Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bupap", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Buphenyl",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bupivacaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subarachnoid" }] }] },
    { Medication: "Bupivacaine Hci And Epinephrine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Perineural" }] }] },
    {
        Medication: "Bupivacaine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Epidural; Intracaudal; Perineural" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal" }, { Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Epidural; Intracaudal" }, { Route: "Epidural; Intracaudal; Perineural" }, { Route: "Epidural; Retrobulbar" }, { Route: "Infiltration; Perineural" }, { Route: "Intraspinal" }, { Route: "Perineural" }] },
        ],
    },
    { Medication: "Bupivacaine Hydrochloride And Epinephrine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Epidural; Intracaudal; Perineural" }, { Route: "Infiltration; Perineural" }, { Route: "Perineural" }] }] },
    { Medication: "Bupivacaine Hydrochloride With Dextrose", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subarachnoid" }] }] },
    { Medication: "Bupivicaine Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] }] },
    { Medication: "Bupivilog Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Intra-Articular; Intramuscular; Topical" }] }] },
    { Medication: "Buprenex", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Buprenorphene", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    {
        Medication: "Buprenorphine",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    {
        Medication: "Buprenorphine And Naloxone",
        DoseTypes: [
            { DoseType: "Film", Routes: [{ Route: "Buccal; Sublingual" }] },
            { DoseType: "Film, Soluble", Routes: [{ Route: "Buccal; Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Buprenorphine Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Buprenorphine Hcl And Naloxone Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Buprenorphine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Buprenorphine Hydrochloride And Naloxone Hydrochloride Dihydrate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Bupropion", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bupropion Hcl Er", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Bupropion Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Bupropion Hydrochloride (Sr)", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bupropion Hydrochloride (Xl)", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bupropion Hydrochloride Extended Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bupropion Hydrochloride Sr", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bur Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Burapren 0.5%", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Perineural" }] }] },
    { Medication: "Burapren 0.75%", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Retrobulbar" }] }] },
    {
        Medication: "Burkhart",
        DoseTypes: [
            { DoseType: "Concentrate", Routes: [{ Route: "Dental" }] },
            { DoseType: "Gel", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Burkhart Neutral Ph", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Burkhart Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Burn",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Burn Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn First Aid", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Jel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Jel Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn N Bite", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Burn Relief",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Burn Relief Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Relief Aloe Vera Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Relief Extra Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Relief Max Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Relief Professional", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Solution Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn Water Soluble", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burn With Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burning Bush Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Burning Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Burning Runny Nose", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Burnrelief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burns Itchy Skin", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Burnx", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burnzone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burrobrush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Burrobrush Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Burts Bees", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Burts Bees Baby Bee Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burts Bees Calming Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burts Bees Firming Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burts Bees Naturally Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Burts Bees Throat Soothing", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Burweed Giant Poverty Marsh Elder", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Burweed Marshelder Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Buscapina", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Business Class Kit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Buspirone Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Buspirone Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Busulfan",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Busulfex", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "But First Be Clean Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "But First Be Clean Alcohol Free Hand Sanitizer Eucalyptus Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "But First Be Clean Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Butalbita,Acetaminophen And Caffeine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Butalbital And Acetaminophen",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Butalbital, Acetaminophen And Caffeine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Butalbital, Acetaminophen, And Caffeine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Butalbital, Acetaminophen, Caffeine", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Butalbital, Acetaminophen, Caffeine And Codeine Phosphate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Butalbital, Acetaminophen, Caffeine, And Codeine Phosphate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Butalbital, Aspirin And Caffeine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Butalbital, Aspirin, And Caffeine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Butalbital, Aspirin, Caffeine And Codeine Phosphate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Butalbital, Aspirin, Caffeine, And Codeine Phosphate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Butenafine Hydrochloride", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Butenafine Hydrochloride 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Butler Clean Hands", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Butorphanol Tartrate",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Butrans", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Bux Guard", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Buybuybaby", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bvblab Cellular Body Firming", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bvblab Squalan Serum Sti Ck 70", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bvg Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bvg Zero Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bwell First Aid Antibiotic",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "By Selected Anti Wrinkle Day Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "By Selected Daily Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "By Selected Hyaluron Day Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "By Selected Recovering Almighty", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "By Selected Reinforce Day Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "By Selected Repair Vitality", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "By Soak Antiseptic Hand Sanitizer 62 Ethyl Alcohol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Byd Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Byd Care Hand Sanitizer Xd-A04", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bydureon", DoseTypes: [{ DoseType: "Injection, Suspension, Extended Release", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Bydureon Bcise", DoseTypes: [{ DoseType: "Injection, Suspension, Extended Release", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Bye Bye (Toc Toc)",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bye Bye Foundation Full Coverage Moisturizer Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bye Bye Germs Antibacterial Hand Wash With Aloe And Tea Tree Enriched With Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bye Bye Germs Antibacterial Hand Wash With Orange Zest And Vitamin C", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bye Bye Germs Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bye Bye Germs Hand Sanitizer Advanced Formula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bye Bye Germs Hand Sanitizer Cranberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bye Bye Germs Hand Sanitizer Pine And Eucaluptus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bye Bye Germs Hand Sanitizer Sugar Cookie", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bye Bye Germs Hand Sanitizer Vanilla Sugar", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bye-Bye Pimples", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Byetta", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Byfavo", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Bylvay", DoseTypes: [{ DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Byooviz", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravitreal" }] }] },
    { Medication: "Byotrol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bystolic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Bzk", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bzk Alcohol Free Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bzk Antibacterial", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bzk Antiseptic",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Bzk Antiseptic Swab",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bzk Antiseptic Swabsticks 4 Triple", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bzk Antiseptic Towelette",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Bzk Antiseptic Towelette Non-Sterile", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bzk Antiseptic Towelette Sterile", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bzk Antiseptic Towelettes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bzk Antiseptic Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bzk Cleansing Swabsticks 4 Triple", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bzk Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bzk Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bzk Sanitizing Hand Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Bzk Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Bzk Towelette",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "C Beyond Triple Serum (Tone 1)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "C Beyond Triple Serum (Tone 2)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "C Beyond Triple Serum (Tone 3)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "C Kids Hand Sanitizer Gel Wacky Watermelon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "C P Re", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "C-Balance Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "C-Lax Laxative", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "C-Nate Dha", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "C.D.M.A Icy Cool", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "C.O.Bigelow Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "C02", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "C04", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "C07", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "C08", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "C19 Antimicrobial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "C2020 Ipa Hand Senitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "C3 Da Balmo Antioxidant",
        DoseTypes: [
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ca-Rezz - Long Term Care - Incontinent Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ca-Rezz - Norisc - Incontinent Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ca-Rezz Norisc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cabbage",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Cabeau Sanitizer To Go", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cabenuva", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cabergoline", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cabinet Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cabinet Cough Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cabinet Pain Reliever And Fever Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cablivi", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cabometyx", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cacao Tanning Oil Spf15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cacao Tanning Oil Spf6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cactus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cactus Cinis Avenae",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cactus Cinis Avenae Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cactus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cactus Crataegus",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cactus Crataegus Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cactus Grandiflorus",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cactus Grandiflorus Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cadira Compliant Blood Stat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cadmium Bromatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cadmium Fluoratum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cadmium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cadmium Metalllicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cadmium Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cadmium Sulphuratum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cadmium Sulphuricum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Caduet", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caf Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cafcit", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cafergot", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caffeic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Caffeine",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Caffeine And Sodium Benzoate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Caffeine Citrate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }, { Route: "Oral" }] },
        ],
    },
    { Medication: "Caffeine Citrate Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caffeine Free", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caffeine Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caffeinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cainetips", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cairina Moschata Heart/Liver Autolysate", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cairs Clean Plus Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cajun Hot Ice Muscle And Joint Pain Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cajuputum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cal-5-Revive", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cal-Shine Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cala Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calaclear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caladium Seguinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caladryl", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caladryl Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calamicare", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }, { Route: "Topical; Topical" }] }] },
    { Medication: "Calamine Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calamine Phenolated Topical Suspension", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calamine Plus", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calamine Plus Pramoxine Hcl", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calan Sr", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Calc Carb",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Calc Fluor",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Calc Fluor 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Calc Phos",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Calc Phos 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calc Sulph", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calc Sulph 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calc. Carb. Cortex Quercus Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Acetica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Arsenicica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Bromata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Carbonic", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Calcarea Carbonic 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Calcarea Carbonic 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Calcarea Carbonica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Calcarea Carbonica 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Calcarea Carbonica Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Caustica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Calcarea Fluorica",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Calcarea Fluorica 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Calcarea Fluorica Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Iodata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Muriatica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Nitrica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Oxalica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Phos Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Calcarea Phosphorica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Calcarea Phosphorica 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Calcarea Silicata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Sulfurica", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Sulfurica 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Calcarea Sulphurica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Calcarea Sulphurica 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Calcarea Sulphurica 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Calcarea Sulphurica 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcarea Sulphurica Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calciforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Calcipotriene",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Calcipotriene 0.005% 60 Gram Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Calcipotriene And Betamethasone Dipropionate",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Calcite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Calcitonin Salmon",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Subcutaneous" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Calcitrene", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Calcitriol",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Calcium Acetate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Calcium Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Carb. Cortex Quercus 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Calcium Carbonate",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Calcium Carbonate 10 Gr (648 Mg)", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Carbonate 500 Mg", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Carbonate Oral Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Carbonicum 3 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Carbonicum 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Calcium Chloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous; Intraventricular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }, { Route: "Intravenous; Intraventricular" }] },
        ],
    },
    { Medication: "Calcium Disodium Versenate", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Calcium Folic Acid Plus D Chewable", DoseTypes: [{ DoseType: "Wafer", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Gluconate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Calcium Phosphoricum 13 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Polycarbophil", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Polycarbophil 625 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calcium Sulphate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caldesene Baby", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caldesene Medicated Protecting", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caldolor", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Caldyphen Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Calendula",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Calendula 10%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calendula 3X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calendula 5% Special Order", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calendula Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calendula Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Calendula Officinalis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Calendula Stibium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calendula Thuja Adult Size", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Calendulax", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calgonate Calcium Gluconate Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cali Distillery Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cali Distillery Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calif. Black Walnut Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Califlora", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Califlora Calendula Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "California Aloe Daily Sun Block", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "California Aloe Fresh Pow Dery Sun", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "California Aloe Moisturizing Tone Up Sun Block", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "California Black Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "California Black Walnut Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "California Juniper Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "California Live Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "California Pure Naturals Natural Baby Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "California Scrub Oak Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "California Tan",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "California Western Sycamore Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "California White Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Callicos Cellawake Mask Madecassol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Callicos Cellawake Mask Peptide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Callicos Hand Gel Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Callicos Safe Good Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Callington Anti-Bacterial Hand Sanitiser", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Callyssee", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calm And Heal Melting Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calm Anxiety And Stress", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calm Eczema Therapy Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calm Eczema Therapy Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calm Five", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calm Keeper", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calm Mood", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Calm N Restful 4 Kids", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calm Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calmadol", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calmadol Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calmease Rescue Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calmicid Ac", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calming Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calming Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calming Itch And Irritation", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calming Primer Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calmol 4", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Calmoseptine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calms Forte", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calms Nerve Tension Sleeplessness", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calquence", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calsium Carbonate 10 Gr", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Calypxo Hp Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Calypxo Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cambia", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Camcevi", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Camera Ready Bb Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camila", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Camilia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Camo Cc Cream Spf30 Deep 500 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Deep 510 C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Deep 540 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Deep 560 C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Fair 120 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Fair 140 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Fair 150 C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Light 210 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Light 240 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Light 280 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Medium 310 C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Medium 330 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Medium 355 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Medium 375 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Rich 620 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Rich 640 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Rich 660 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Tan 415 C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Tan 425 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Cream Spf30 Tan 450 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Deep 530 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Fair 100 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Fair 125 C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Light 205 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Light 250 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Medium 370 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Rich 610 N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Rich 650 C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Tan 400 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camo Cc Spf 30 Tan 460 W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Campath", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Campho-Phenique",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Camphor Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Camphor, Menthol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Camphora",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Camphoricum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Camphotrex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camphotrol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Camptosar", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Camrese", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Camrese Lo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Camzyos", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Can Albex 30X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Can Albex 500X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Can1 Pc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Can200 Pc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Canafarma Spot On Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Canafarma Spot On Acne Treatment Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Canagyn", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Canary Feathers", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Canary Grass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Canasa", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Cancidas", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cancordistat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cand Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cand Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cand Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cand-Ex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candee Caine Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Candesartan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candesartan Cilexetil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candesartan Cilexetil And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candid- Y", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Candid-Y",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Candida",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Candida Alb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Alb Combo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Candida Albicans",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Candida Albicans 30C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Albicans Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Albicans Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Candida Albicans Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Combo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Ease", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida High Potency 9", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida I", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Parapsilosis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Candida Plus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Candida Spp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candida Yeast", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candidate S.O.S", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Candin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }] }] },
    { Medication: "Cando Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cando Ultra Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Candy Apple Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Candy Apple Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Candy Apple Scented Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Candy Coated Apple Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Candy Coated Apple Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Candy Cutie Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cangust", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Canis Lupus Familiaris Skin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Canker Plus Cold Sore Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Canker Sore Healing Dots", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Canna Numb Pain Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Canna Numb Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cannabicool Pain Relieving Lidocaine Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cannacell Sun Buddy Facial Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cannaflex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cannaserene Hot And Cold Cbd Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Canpaco Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cantaloupe", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Cantaloupe Muskmelon", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Cantharis",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Cantharis 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Cantharis 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Cantharis Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cantharis Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cantu Guava Ginger Anti Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Canyon Ragweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Capasil", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capcof", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cape Cod Sunscreen Plus Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Capecitabine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Capecitabine 150Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Capecitabine 500Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Capewest Hygiene Cleansing Hand Wash Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capewest Hygiene Cleansing Hand Wash Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capewest Hygiene Cleansing Hand Wash Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capewest Hygiene Cleansing Hand Wash Rose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capewest Waterless Gel Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capex", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capillus Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capizonne Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caplyta", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Capmist Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caprelsa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Capri Blue Volcano Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capron Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Capron Dmt", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Capsaicin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Capsaicin 0.075 Percent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capsaicin 0.1 Percent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capsaicin Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capsaicin Heat", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capsaicin Heat Patches Back And Large Areas", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capsaicin Hot Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capsaicin Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Capsicum Annuum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Capsicum Chamomilla Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Capsiva", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Captopril", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Capture Dreamskin Fresh And Perfect Cushion With Sunscreen Broad Spectrum Spf 50 000", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Dreamskin Fresh And Perfect Cushion With Sunscreen Broad Spectrum Spf 50 010", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Dreamskin Fresh And Perfect Cushion With Sunscreen Broad Spectrum Spf 50 012", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Dreamskin Fresh And Perfect Cushion With Sunscreen Broad Spectrum Spf 50 020", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Dreamskin Fresh And Perfect Cushion With Sunscreen Broad Spectrum Spf 50 025", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Dreamskin Fresh And Perfect Cushion With Sunscreen Broad Spectrum Spf 50 030", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Dreamskin Fresh And Perfect Cushion With Sunscreen Broad Spectrum Spf 50 040", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 0N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 1,5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 1N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 2Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 2N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 2W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 3N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 4N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 6N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capture Totale C.E.L.L. Energy Super Potent Serum Foundation Broad Spectrum Spf 20 7N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capzasin Hp Arthritis Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capzasin No Mess Applicator", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capzasin Quick Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Capzix", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carac", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Carafate",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Caragala - Botanical Defense Day Cream Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caramel Latte Tinted Moisturizer Broad Spectrum Spf 25 Sunscreen Light To Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caraway Oil 1 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbaglu", DoseTypes: [{ DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbamazepin", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Carbamazepine",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Carbamazepine Er", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbatrol", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbidopa", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Carbidopa And Levodopa",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Carbidopa Tablets, 25 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbidopa, Levodopa And Entacapone", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbidopa, Levodopa, And Entacapone", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Carbinoxamine Maleate",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Carbo Animalis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Carbo Betulae", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbo Betulae 20 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbo Betulae 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbo Betulae 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbo Betulae Cum Methano 3 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Carbo Chamomilla",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Carbo Equiseti 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbo Sulfur", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Carbo Vegetabilis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Carbo Vegetabilis 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Carbo Vegetabilis 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Carbo Vegetabilis Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbocaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Carbocaine With Neo-Cobefrin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Carbogen", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbolicum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carbon Dioxide", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide Air", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide Air Mixture", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide And Oxygen Mixture, Compressed, Oxidizing, N.O.S.", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide Nitrogen", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide Oxygen", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide Oxygen Mix", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide Oxygen Mixture", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide Oxygen Mixture 5/95", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide Refrigerated", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide-Air Mixture", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide-Oxygen Mixture", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide/Oxygen 5/95", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carbon Dioxide/Oxygen Mix", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Carboneum Sulphuratum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Carboplatin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Carboprost Tromethamine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Carboxymethylcellulose Sodium",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Carboxymethylcellulose Sodium Ophthalmic Solution 0.5%", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Carbuncles Boils", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carcinosin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carcinosin Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carcinosin Miasm Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardcare", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardene Iv", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cardiacforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardinal Extra Strength Smooth Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cardinal Health",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cardinal Health Antiseptic Skin Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinal Health Infants Gas Relief", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardinal Health Leader", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinal Health Leader Baby Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinal Health Leader General Protection Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinal Health Leader General Protection Sunscreen Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinal Health Leader General Protection Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinal Health Leader Kids Sunscreen Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinal Health Leader Spf 50 General Protection Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinal Health Leader Sport Sunscreen Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinalextra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardinalhealth Antibacterial Bathing Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cardinorma", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardinum 4Ch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardio Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardioforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardiogen-82", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cardiolite", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cardiopar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardioplegic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intra-Arterial" }] }] },
    { Medication: "Cardioplegic Solution", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intra-Arterial" }] }] },
    { Medication: "Cardiotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardizem", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardizem Cd", DoseTypes: [{ DoseType: "Capsule, Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardizem La", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cardon", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cardura",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Carduus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carduus Mar", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Carduus Marianus",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tincture", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Carduus Marianus E Fruct. 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carduus Oxalis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carduus Paeonia Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carduus Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carduus Viscum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Care And Co Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Care And Comfort Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Cleansing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Disinfectant Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care One", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Care One 70 Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care One Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Calcium Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Care One Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Care One Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care One Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Care One Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Care One Nasal Decongestant Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Care One Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Care One Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Original Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Care One Restore And Defend", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Care Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Plus 1 Llc", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Plus Hand Sanitizer 16Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Plus Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Plus Issue", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Touch Antibacterial Handsoap Coconut Lime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Touch Antibacterial Handsoap Pink Grapefruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Touch Antibacterial Handsoap Rose Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Care Wipes 70 Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careall Arthritis And Muscle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Careall Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careall Bacitracin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Cetirizine Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careall Clotrimazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Creamy Cocoa Butter Petroleum Jelly", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Creamy Petroleum Jelly", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Diphenhydramine", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careall Eyedrops Original", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Careall Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Ibuprofen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careall Loratadine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careall Medicated Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Miconazole Nitrate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Musle And Joint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Careall Naproxen",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careall Non Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careall Non-Aspirin Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careall Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Sennosides", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careall Tolnaftate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Triple Antibiotic Plus Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Vaporizing Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Vitamin A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careall Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carecella Daily Sun Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carecella Velvet Cover Bb Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carecella Velvet Cover Bb Cream No 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carecom Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caredo Professional Cavities Treatment Toothpastes", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Caredo Repair Cracked Teeth Toothpastes", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Caredo Repairing Dental Caries For Kids Toothpastes", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Caredo Treating Gingivitis Toothpastes", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Caredo Treating Periodontitis Toothpastes", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Careful Hand Sanitizer Lily", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careful Hand Sanitizer Mint", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careful Hand Sanitizer Orange", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careful Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careless Weed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Careless Weed, Amaranth Green", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Caremotion", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caren Hand Sanitizer Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Careone",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Careone 8 Hour Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Careone Acetaminophen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Careone Acetaminophen Pm",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Careone Acid Reducer",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careone Acid Reducer Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Acid Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Allergy And Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Careone Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careone Allergy Relief D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Aloe And Vitamin E Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Aloe Vitamin E Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Amber", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Amber Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Arthritis Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Artificial Tears Eye Drops 15Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Careone Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careone Childrens Acetaminophen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Careone Childrens Allergy Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careone Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Childrens Cold And Flu", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Childrens Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Childrens Mucus Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Childrens Multi Symptom Fever And Cold", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Cold And Sinus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Careone Cold Sore Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Complete Kit Lice", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Day Cold And Flu Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Day Severe Cold Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Daytime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Daytime Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Daytime Nighttime Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Daytime Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Daytime Severe Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Diarrhea And Gas Control", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Careone Diarrhea Control",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careone Earwax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Careone Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Extra Strength Gas Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Fast Acting Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Careone Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Careone Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Careone Hydrocortisone 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Infants Acetaminophen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Itchy Relief Eye Drops 15Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Careone Lemon Meringue Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Lidocaine Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Liquid Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Maximum Redness Relief Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Careone Maximum Strength Redness Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Careone Medicated Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Medicated Dandruff With Menthol", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Careone Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Milk Of Magnesia Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Milk Of Magnesia Original", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Mini Nicotine Polacrilex", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Moisturizing Dandruff With Aloe", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Mucus Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Naproxen Sodium Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Careone Nasal And Sinus Decongestant",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Careone Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careone Night Time Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Nighttime Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Nighttime Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Careone Nighttime Sleep Aid",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Careone Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Careone Regular Strength Calcium Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Severe Cold", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Severe Congestion Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Careone Sinus Relief Day Time Night Time", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Sinus Relief Day Time Sinus Relief Night Time", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Careone Spring Shower", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Stay Awake", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Careone Triacting Night Time Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Careone Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Careone Vanilla Buttercream Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Careone Vitamin E Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Caretouch", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caretouch 70% Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caretouch Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carex Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carglumic Acid", DoseTypes: [{ DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caribbean Breeze Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caribbean Mist Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caribbean Sol Natural Sunscreen Spf-30", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caring Mill", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caring Mill Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Acetaminophen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Acne Control Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caring Mill Acne Drying", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caring Mill Acne Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caring Mill Adult Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Caring Mill All Day Allergy",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Caring Mill All Day Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Caring Mill Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Caring Mill Childrens Acetaminophen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Childrens Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Childrens Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Caring Mill Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Childrens Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Caring Mill Childrens Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Caring Mill Childrens Mucus Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Daytime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Daytime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Famotidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Caring Mill Ibuprofen",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Caring Mill Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Caring Mill Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Mucus Er", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Nasal Four", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Caring Mill Night Time Sleep Aid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Nighttime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caring Mill One-Step Corn Removers", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Caring Mill Sinus Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carisoprodol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carisoprodol, Aspirin And Codeine Phosphate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carlotta Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Classic Lip Balm Medicated", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Classic Lip Balm Medicated Free Wintergreen Stick", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Carmex Classic Lip Balm Medicated Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Cold Sore Treatment External Analgesic Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Cupcake Batter Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Favorites Spf 15", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Free Wintergreen And Fresh Cherry Spf 15", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Fresh Cherry Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Holiday Spf 15", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Honey Dew Melon Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Orange Dream Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Spf 15", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Spf 15 Assorted", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Spf 15 Minis", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Strawberry Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Sweets Spf 15", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Tropical Colada Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Winter Mint Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Daily Care Moisturizing Lip Balm Wintergreen Spf 15", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmex Multi-Symptom 3 In 1 Cold Sore Treatment External Analgesic Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carmustine", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Intravenous" }] }] },
    { Medication: "Carneol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carnexiv", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Carnitor",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Carnitor Sf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Carnival Adventures",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Carnival Adventures 30 Spf Sport Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carols Daughter Monoi Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carospir", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carotid Nerve Plexus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carpal Comfort", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpal Tunnel Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Carpe Antiperspirant For Feet", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant For Hands", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant For Underarms", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant Underarm Bergamot Scent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant Underarm Coastal Orange Scent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant Underarm Cypress Scent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant Underarm Fresh Cotton Scent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant Underarm Fresh Powder Scent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant Underarm Fresh Vanilla Scent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant Underarm Sandalwood Scent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Antiperspirant Underarm Vanilla Scent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpe Extremegrips", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carpellum Mali Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carricare Premium Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carrington Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carrington Moisture Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carrollclean", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Carrot",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Cars Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cart Wipes Clean Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carteolol Hydrochloride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Carters Little Pills", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cartia", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cartia Xt", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cartilage Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cartilage Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cartilago Argentum",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cartilago Argentum Joint Support", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cartilago Betula",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cartilago Betula Adult Size Special Order", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Cartilago Comp. Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cartilago Quartz",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Carum Carvi", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carvanum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Carvedilol", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carvedilol Phosphate", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Carvykti", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Carya Illinoinensis Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Carya Ovata Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }] }] },
    { Medication: "Casa Limpia By Insular Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Casein", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Casey Products", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cashew Nut", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Casirivimab", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Casirivimab With Imdevimab", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Casodex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Caspofungin Acetate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Casporyn Hc", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Castellani Paint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Castellani Paint 1.5%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Castiva Cooling", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Castiva Warming", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Castor And Shea Spf Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Castor And Shea Spf Face", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Castoreum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Casuarina Equisetifolia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Cat Hair, Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    {
        Medication: "Cat Pelt, Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Cataflam", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Catapres-Tts", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Catapres-Tts-1", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Catapres-Tts-2", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Catapres-Tts-3", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Cataracc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cataract", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cataract Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Category I", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Category Ii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Category Iii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Category Iv", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Category V", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Catfish", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Cathflo Activase", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cats Claw", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cattle Epithelia",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Cauliflower", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Caulophyllum", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Caulophyllum Thal Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Caulophyllum Thalictroides",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Causticum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Causticum Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Causticum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Caverject", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intracavernous" }] }] },
    { Medication: "Caverject Impulse", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intracavernous" }] }] },
    { Medication: "Cavia Porcellus Skin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Caviall Superior Caviar Nourinkle Mask Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cavity Protection Fresh Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Cavity Protection Fresh Mint Anticavity Mouthwash", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cavum Tympani 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cay Skin Isle Body Oil Spf 30", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cay Skin Isle Glow Body Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cay Skin Isle Glow Face Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cay Skin Isle Lip Balm Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cay Skin Universal Mineral Body Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cay Skin Universal Mineral Face Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cayston", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Caziant", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cb Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cba All-Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cba All-Clean Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Bee Doo Arthritis Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Blue Light Defense Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Clinic Deep-Rub Pain Relief - Level 1", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Clinic Deep-Rub Pain Relief - Level 3", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Clinic Deep-Rub Pain Relief - Level 5", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Clinic Pain Relief - Level 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Clinic Pain Relief - Level 2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Clinic Pain Relief - Level 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Clinic Pain Relief - Level 4", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cbd Clinic Pain Relief - Level 5",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Cbd Clinic Relax Massage",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Cbd Clinic Revive Massage",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Cbd Clinic Ultimate Massage",
        DoseTypes: [
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cbd Cryotherapy Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Cryotherapy Pain Relief Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Menthol Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Pain Freeze Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Pain Freeze Shrink", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Pain Relief Roll On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Unlimited Balm Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Unlimited Balmx Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Unlimited Mistx Topical Analgesic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd Unlimited Topical Analgesic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbd-Kings", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbdaf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cbdmedic Acne Treatment",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Cbdmedic Active Sport Pain Relief",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Cbdmedic Arthritis Aches And Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cbdmedic Arthritis Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbdmedic Back And Neck Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbdmedic Eczema", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbdmedic Eczema Therapy", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cbdmedic Foot And Ankle Pain Relief",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cbdmedic Itch And Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cbdmedic Massage Therapy Pain Relief", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cbdmedic Muscle And Joint Pain Relief",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cbdq.(9) Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Deep220", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Deep230", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Deep240", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Fair30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Light100", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Light80", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Light90", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Medium120", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Medium130", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Medium140", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Medium150", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Tan160", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Tan170", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Tan190", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Cream Tan200", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Creme 02 Soin Perfecteur De Peau Skin Perfecting", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Creme Soin Perfecteur De Peau Skin Perfecting", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Luminessence Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 100C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 105N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 110C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 206W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 215N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 226W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 230C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 306W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 315N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 326W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 336W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 346W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 400C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 416W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Screen 100% Mineral Cc Cream Broad Spectrum Spf 50 426W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cc Total Cover Cushion", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ccc Clean Corrective With Vitamin C Tinted Moisturizer Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 010", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 020", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 021", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 022", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 023", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 030", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 031", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 032", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 040", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Capture Totale Triple Correcting Serum Foundation Wrinkles-Dark Spots-Radiance With Sunscreen Broad Spectrum Spf 25 050", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Diorskin City Defense Toxin Shield Pollution Uv Advanced Protection With Sunscreen Broad Spectrum Spf50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Diorskin Star Studio Makeup Spectacular Brightening With Sunscreen Broad Spectrum Spf30 010", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Hydralife Bb Creme Enhancing Sunscreen Moisturizer For Immediate Beauty Golden Peach Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cd Hydralife Bb Creme Enhancing Sunscreen Moisturizer For Immediate Beauty Luminous Beige Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cdif Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cdma Fiber Therapy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ceanothus Americanus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ceanothus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cebashin Cap.", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cedaprin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cedar Elm Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Cedar X Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cedron", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cefaclor",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cefadroxil",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cefazolin",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intramuscular; Intravenous; Parenteral" }, { Route: "Intravenous" }, { Route: "Intravenous; Parenteral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
        ],
    },
    { Medication: "Cefazolin Sodium", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cefdinir",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cefepime",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Cefepime Hydrochloride", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Cefepime Hydrochloride And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cefixime",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cefotaxime",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Cefotetan", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Cefoxitin",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Cefoxitin And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cefpodoxime Proxetil",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cefprozil",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ceftazidime", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] }] },
    { Medication: "Ceftazidime And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Ceftriaxone",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Ceftriaxone And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ceftriaxone Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] }] },
    { Medication: "Cefuroxime", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] }] },
    { Medication: "Cefuroxime And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cefuroxime Axetil",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cefuroxime Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] }] },
    { Medication: "Ceiba Pentandra Fiber", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Celandine Honey", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Celazome", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celebrex", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Celecoxib", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Celecoxib 200 Mg", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Celeragesic",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Celery",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Celestone Soluspan", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular; Soft Tissue" }] }] },
    {
        Medication: "Celesty",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Celesty Colors Special Edition With Alix Avien",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Celexa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Celgen Advanced Hair Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celgen Pearl Calming Solution Mask", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celgen Pearl Moisture Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celgen Pearl Moisture Solution Mask", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celgen Pearl Moisturecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cell 97.7 Re-Activated Perfect Lifting Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cell 97.7 Re-Activated Perfect Lifting Eye Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cell Energy Performance Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cell Salt Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cell Salts",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cellamo Phytoboosting Tonic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellamo Phytocareshampoo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellandir Scalp Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellbn Blemish Spotcream And Black Spotpatch", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellbn Milky Tone-Upcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cellcept",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cellergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cellexosome He Hr", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellexosome He Sr", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellexosome Hr", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellexosome Sb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellexosome St", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellisys Ex Egf Wrinkle 29 Activator", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellisys Ex Wrinkle Solution", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellisys Ex Wrinkle Solution Emulsion", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellisys Ex Wrinkle Solution Eye Contour", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cellskin Clean Pine",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cellskin Clean Pine Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellskin The Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celltem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cellular Laboratories De-Aging Sunscreen Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellular Oxygenator Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cellular Swiss Uv Protection Veil Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellzyme On-Tox", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellzyme Secret Therapy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cellzyme Secret Therapy 3845", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Bio Home Solution Concentrated Skin Booster Rejuvenation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Signature Bio", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Signature Bio Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Signature Bio Cushion Pact Pink Ivory", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Signature Bio Essence Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Signature Bio Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Signature Bio Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Signature Bio Sheet Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celonia Signature Bio Tone Up Sun Block", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Celontin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Celtis Occidentalis Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Cemics", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cenchris Contortrix", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Centany", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Center-Al - Acacia Longifolia Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Acer Negundo Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Acer Saccharum Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Agrostis Gigantea", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Alnus Incana Ssp. Rugosa Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Amaranthus Retroflexus Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Ambrosia Acanthicarpa Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Ambrosia Artemisiifolia Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Ambrosia Psilostachya Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Ambrosia Trifida Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Artemisia Tridentata Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Artemisia Vulgaris Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Atriplex Wrightii Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Avena Sativa Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Betula Lenta Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Bromus Inermis Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Carya Illinoinensis Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Carya Ovata Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Chenopodium Album Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Corylus Americana Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Cynodon Dactylon Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Dactylis Glomerata Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Festuca Pratensis Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Fraxinus Americana Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Holcus Lanatus Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Juglans Californica Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Juglans Nigra Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Kochia Scoparia Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Lolium Perenne Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Morus Rubra Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Olea Europaea Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Paspalum Notatum Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Phleum Pratense Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Plantago Lanceolata Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Platanus Occidentalis Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Poa Annua Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Poa Pratensis Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Populus Alba Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Populus Deltoides Ssp. Monilifera Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Prosopis Juliflora Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Quercus Alba Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Quercus Virginiana Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Rumex Acetosella Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Rumex Crispus Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Salix Nigra Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Salsola Kali Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Sorghum Halepense Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Ulmus Americana Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Ulmus Pumila Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Center-Al - Xanthium Strumarium Pollen", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Centipaine For Pain Control", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Central Eastern 4 Tree Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Central Western Weed Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Centratex", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Centricity/Star Products Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Centrocode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Centroll Nap Advanced Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Centroll Nap Advanced Hand Sanitizerspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ceo-Two", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Cepacol",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cepacol Extra Strength Sore Throat Cherry", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cepacol Instamax", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cepastat", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cephalexin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cephalexin Oral Susp", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cephalosporium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Ceprotin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cequa", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "Ceracos The Black", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ceracos The Black Hair Tonic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ceramide Lift And Firm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ceramide Lift And Firm Eye Spf 15", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ceramide Makeup Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ceramide Premiere Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Acne Foaming Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Am Facial Moisturizing Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Acne Control Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Acne Control Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Baby Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cerave Developed With Dermatologists Baby Moisturizing",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cerave Developed With Dermatologists Baby Sunscreen Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Eczema Relief Creamy", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Healing Lip Balm Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Hydrating Mineral Sunscreen Broad Spectrum Spf 30 Face Sheer Tint Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Hydrating Sunscreen Spf 30 Body Lightweight, Non-Greasy Feel 3 Essential Ceramides Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Hydrating Sunscreen Spf 30 Face Lightweight, Non-Greasy Feel 3 Essential Ceramides Niacinamide Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Hydrating Sunscreen Spf 50 Body Lightweight, Non-Greasy Feel 3 Essential Ceramides Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Hydrating Sunscreen Spf 50 Face Lightweight, Non-Greasy Feel 3 Essential Ceramides Niacinamide Mineral", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cerave Developed With Dermatologists Itch Relief Moisturizing",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cerave Developed With Dermatologists Psoriasis Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Psoriasis Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Skin Renewing Broad Spectrum Spf 30 Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Sunscreen Body Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Sunscreen Body Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Sunscreen Broad Spectrum Spf 30 Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Sunscreen Broad Spectrum Spf 50 Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermatologists Therapeutic Hand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerave Developed With Dermotologists Ultra Light Moisturizing Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerdelga", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cerebellinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cerebellum 4 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cerebellum Thalamus A",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cerebellum Thalamus A Aurm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cerebellum Thalamus A Aurum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cerebellum Thalamus B",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cerebellum Thalamus B Aurum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cerebellutem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cerebral Blood Stim Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cerebraplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cerebrinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cerebroforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cerebromax", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cerebyx", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Ceretec",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Cerezyme", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cerianna", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cerium Oxydatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Certa Dose Epinephrine Convenience Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    {
        Medication: "Certain Dri",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Certain Dri Am", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Certain Dri Everyday Strength Clinical", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cerussite 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cervical 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cervical Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cervidil", DoseTypes: [{ DoseType: "Insert", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Cesamet", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cesium Chloride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cesium Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cetacaine Anesthetic",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cetacaine Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Acne Relief Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Baby Eczema Soothing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Baby Soothe And Protect Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Cracked Skin Repair", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Cracked Skin Repair Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Daily Facial Moisturizer With Suncreen Spf 35", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Daily Facial Moisturizer With Suncreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Eczema Flare-Up Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Eczema Itch Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Eczema Soothing Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Gentle Clear Bpo Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Gentle Clear Clarifying Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Gentle Clear Mattifying Acne Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Gentle Clear Triple-Action Acne Serum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Healthy Radiance Whipped Day Cream Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Oil Absorbing Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Pro Eczema Soothing Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Pro Oil Absorbing Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Redness Relieving Daily Facial Moisturizer With Sunscreen Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Sheer Mineral Face Liquid Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Sheer Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Sheer Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Sheer Mineral Sunscreen Stick Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cetaphil Soothing Gel Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cetirizine",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cetirizine Hcl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cetirizine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cetirizine Hydrochloride (Allergy Relief)", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cetirizine Hydrochloride (Allergy)",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cetirizine Hydrochloride (Hives Relief)",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cetirizine Hydrochloride And Pseudoephedrine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cetirizine Hydrochloride Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cetirizine Hydrochloride Tablets, 10 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cetirizine Hydrochloride Tablets, 5 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cetirizine Hydrochloride Tablets,10 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cetraxal", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Cetrotide", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Cevimeline", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cevimeline Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chaetomium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Chaetomium Globosum",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Chafezone", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chai Latte Scented Clip On Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chai Latte Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chalcedony", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomile Homeopathic Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Chamomilla",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Chamomilla (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Chamomilla Belladonna", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla Cupro 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla E Pl. Tota 15 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla E Pl. Tota 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla E Pl. Tota 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla E Rad. 12 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla E Rad. 20 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla E Rad. 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla E Rad. 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chamomilla Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Champagne Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Champion Ultra Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Champion Ultra Clean Fragrance And Dye Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Champuregreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chantecaille", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chantecaille Just Skin Tinted Moisturizer Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chantecaille Ultra Sun Protection Sunscreen Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Chantix",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chap-Et", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapice Moisture Spf 15 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapice Original Spf 4 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapice Spf 30 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Chapstick Classic Cherry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick Classic Medicated", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick Classic Spearmint", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick Moisturizer Cool Mint", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick Moisturizer Original", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick Moisturizer Variety Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Chapstick Supreme Classic Cherry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick Total Hydration Moisture Tint Peachy Keen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick Total Hydration Moisture Tint Pretty In Pink", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chapstick Total Hydration Moisture Tint Very Berry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Charcoal Acne Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Charcoal Activated", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Charcoal Blackhead Clearing Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Charlotte 24Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Charlotte Tilbury Broad Spectrum Spf 50 Sunscreen Invisible Uv Flawless Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Charm Tex",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental; Oral" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental; Oral" }] },
        ],
    },
    { Medication: "Charm Tex 3 In 1 Antibacterial Shave Gel Body Wash", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Charm Tex Lightly Scented Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Charm-Tex Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Charmtex Anti Bacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chateal", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Chateal Eq", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Chavita 7", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cheat Grass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Check This Out Dishsoap Orange Scent", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Check This Out Orange Scent Dishwashing Liquid", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cheee Gold Plus Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cheeky Bonsai Uti Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cheerful Choco Orange Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cheezheng Pain Relieveing Plaster", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cheiranthus Cheiri", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cheliderm Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chelidonium Cichorium", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chelidonium Colocynthis Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chelidonium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chelidonium Curcuma",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chelidonium Curcuma P", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chelidonium Curcuma Thuja", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chelidonium Ferro 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chelidonium Majus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chelidonium Majus Radix", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chelidonium Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chelidonium Rosa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chem Free Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemcor Chemical Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemdetox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chemet", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chemical Free Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemical Guys Eightyaid Hand Sanitizer 80% Alcohol Antiseptic Wild Pear Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemicals", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chemseptic", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemstar", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemstar Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemstar Retail Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemstar Sterilox Fresh Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemstat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chemstation E2", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemstation E3 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemstation Haccp Q E2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemstation Q E2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chemtox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chenodal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chenopodium", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chenopodium Album Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Chenopodium Anthelminticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cheratussin Ac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cherry",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Cherry Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Antacid Relief Chew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Antacid Soft Chew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Antacid Soft Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Antacid Softchew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Birch Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Cherry Blossom Scented Antiseptic Hand Sanitizer 80 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cherry Blossom Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cherry Cold Relief Lozenge", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Hand Sanitizer Boy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cherry Plum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cherry Sore Throat Lozenge", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Sore Throat Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Sore Throat Lozenze", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Sore Throat Relief Lozenge", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cherry Therastat", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Throat Relief Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cherry Zinc Cold Relief Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chest Comfort", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chest Congestion And Cough Hbp", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chest Congestion Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Chest Rub",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Chest Rub And Cough Suppressant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chestal", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chestal Adult Pellets", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chestal Childrens Cough And Cold", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chestal Cough And Cold", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chestal For Children", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chestal Kids Pellets", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chestrub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chewable Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chewable Adult Low Dose Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chewable Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chewable Aspirin Adult Low Dose", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chewable Low Dose", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chewable Low Dose Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chewable Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chi Kung Huo Shiueh Kao", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chicken Egg White", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Chicken Egg Whole", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Chicken Egg Yolk", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Chicken Feathers", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Chicken Meat",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Chicory Ginger Bitters", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chigarid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chigg Away", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chigger Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chiggerex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Childbirth Care", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childhood Diseases Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childhood Immunization Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childhood Vaccination Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Children Acetaminophen Oral Solution",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Children Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Children Allergy Relief Dye Free Cherry", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Children Claritin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Children Coldcalm", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Children Cough And Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Children Pain And Fever Reliever", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Children Pain And Fever Reliver", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens 24 Hour Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Acetaminophen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Acetaminophen Oral Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Advil", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens All Day Allergy",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Childrens Allegra Allergy",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Childrens Allergy",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Allergy Dye Free Wal Dryl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Allergy Dye Free Wal-Dryl", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Allergy Dye-Free Wal Dryl", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Allergy Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Childrens Allergy Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Childrens Allergy Plus Congestion",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Allergy Plus Congestion Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Allergy Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Alpha Cf", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual; Sublingual" }] }] },
    { Medication: "Childrens Alpha Cold Flu", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Childrens Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Antacid Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Benadryl Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Benadryl Allergy Plus Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Benadryl Chewables", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Benadryl Dye-Free Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Benadryl Itch Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Childrens Cetirizine Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Cetirizine Hydrochloride Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cetirizine Hydrochloride Hives Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Chest Congestion Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Chest Congestion Relief Grape", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Chewable Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Chewable Mapap", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Chewables", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cold And Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cold And Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Cold And Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Cold And Cough Day And Night Twin Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Childrens Cold And Cough Day And Night Value Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Childrens Cold And Cough Grape Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cold And Cough Nighttime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cold And Flu", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Coltalin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cough And Bronchial", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cough And Cold Cf", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cough And Sore Throat", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Cough Mucus Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Dayclear Allergy Cough Chewables", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Delsym", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Delsym Cough Plus Chest Congestion Dm And Delsym Cough Plus Cold Night Time", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Childrens Dibromm Cold And Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Dibromm Cold And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Dimetapp Cold And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Dimetapp Multi-Symptom Cold And Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Dye Free Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Dye Free Allergy Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Dye Free Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Dye Free Wal Zyr", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Earache Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Childrens Fever Reducer And Pain Reliever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Fexofenadine Hydrochloride", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens First Aid", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Childrens Flonase", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Childrens Giltuss Ex Expectorant", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Giltuss Honey Cough And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Giltuss Honey Dm Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Childrens Ibuprofen 100",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Ibuprofen Blue Raspberry", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Ibuprofen Bubblegum", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Ibuprofen Fruit", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Ibuprofen Grape", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Ibuprofen Oral Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Loratadine",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Loratadine Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Loratadine Oral", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Mapap",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Mapap Acetaminophen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Metapp Daytime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Motrin",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Mucinex", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Mucinex Multi-Symptom Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Mucinex Stuffy Nose And Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Mucus And Cough Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Mucus Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Mucus Relief Chest Congestion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Mucus Relief Chest Congestion Plus Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Mucus Relief Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Mucus Relief Cough, Cold And Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Mucus Relief Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Mucus Relief Multi Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Multi Symptom Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Multi Symptom Fever And Cold", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Multi-Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Multi-Symptom Cold And Fever Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Mylicon All In One Tummy Relief For Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Mylicon Tummy Relief For Kids", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Nanomol Dye-Free Cherry", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Nasacort Allergy 24Hr", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Childrens Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Childrens Nasopro", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Childrens Nighttime Cold And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Nighttime Cold And Mucus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Non Aspirin", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Oral Saline Laxative", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Pain And Fever",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Pain And Fever Cherry Flavor", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pain And Fever, Grape Flavor", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Pain Relief",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Pain Relief And Fever Reducer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pain Relief Cold And Cough And Sore Throat", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pain Relief Cold And Flu", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pain Relief Dye-Free", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pain Relief Oral Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pain Relief Oral Suspension Dye Free", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pain Relief Plus Multi Symptom Cold", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Pain Reliever",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Pediaclear 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pediaclear Pd", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Pink Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Childrens Plus Cough And Runny Nose", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Plus Flu", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Plus Multi Symptom Cold",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Robitussin 12 Hour Cough Relief", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Robitussin Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Robitussin Cough And Cold Cf", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Robitussin Cough And Cold Long-Acting", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Robitussin Cough Long-Acting", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Robitussin Elderberry Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Robitussin Honey Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Robitussin Honey Nighttime Cough Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Robitussin Nighttime Cough Long-Acting Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Sabadil", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Silapap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Silfedrine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Sudafed", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Sudafed Pe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Sudafed Pe Cold Plus Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Triacting Nighttime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Tukol Acetaminophen Fever Reducer Pain Reducer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Tukol Multi-Symptom Cold And Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Tussnex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Tussnex Multi-Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Tylenol",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Childrens Wal Dryl Pe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Wal Tap", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Wal Tap Elixer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Wal Tussin Cough And Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Wal-Dryl Allergy", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Walitin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Childrens Xyzal Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Childrens Zyrtec",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Childrens Zyrtec Allergy",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chill Pain Away", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chills And Fevers", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chimaphila Umbellata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "China 30C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "China Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "China-Gel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chinese Elm Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Chinese Herb Essence Bacteriostatic Feminine Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chinese Herb Essence Bacteriostatic Foaming Agent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ching Wan Hung Soothing Herbal Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chininum Arsenicosum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chininum Salicylicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chininum Sulphuricum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chionanthus Virginica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chip Cravings", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chirhostim", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Chirhostim 40", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Chla Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlamydia Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlo Hist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlo Tuss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlophedianol Hydrochloride, Dexchlorpheniramine Maleate, And Pseudoephedrine Hydrochloride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlor-Allergy 4-Hour", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloramphenicol Sodium Succinate", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Chloraprep One-Step", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chloraseptic", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloraseptic Max", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloraseptic Sore Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloraseptic Sore Throat Cherry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloraseptic Sore Throat Citrus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloraseptic Sore Throat Max", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloraseptic Sore Throat Menthol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloraseptic Total Sore Throat And Cough", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloraseptic Total Sore Throat And Cough, Sugar Free", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chloraseptic Warming Sore Throat",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chloraspetic", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlordiazepoxide", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlordiazepoxide And Amitriptyline Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chlordiazepoxide Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chlordiazepoxide Hydrochloride And Clidinium Bromide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlordiazepoxide Hydrochloride/Clidinium Bromide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloresin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorhexidine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorhexidine Gluconante", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chlorhexidine Gluconate",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Buccal" }, { Route: "Oral" }] },
        ],
    },
    { Medication: "Chlorhexidine Gluconate 0.12% Oral Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Chlorhexidine Gluconate Solution 0.75% Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chlorhexidine Gluconate, 0.12% Oral Rinse Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Chlorinated Ring Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloroformum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorogenic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chloroprocaine Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Infiltration; Perineural" }] }] },
    { Medication: "Chloroprocaine Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] }] },
    { Medication: "Chloroqcare Antiseptic Hand Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Chloroquine Phosphate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chloroquinum Phos.", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorothiazide", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Chlorothiazide Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Chloroxylenol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chloroxylenol 0.3%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chlorphen-12", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorpheniramine Maleate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorpheniramine Maleate 4 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorpheniramine Maleate 4Mg", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorpromazine Hci", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Chlorpromazine Hydrochloride",
        DoseTypes: [
            { DoseType: "Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chlortabs", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorthalidone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chlorzoxazone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cho-A Care Lot 1%", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cho-A Derm", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cho-A Derm Beauty Care", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cho-A Derm-R", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cho-A Pap", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cho-A Point Plaster", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Choco Mallow Melt Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chocolate Cacao Bean", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Chocolate Laxative", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chocolated Laxative", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Choice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Choice Liquid Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cholbam", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cholenest", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cholesterinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cholesterinum Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cholesterol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cholestop", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Cholestyramine", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cholestyramine Light", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cholestyramine Powder For Suspension", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Choletec", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Choleystyramine Light", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Choline C 11", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Choline Magnesium Trisalicylate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chooz", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chorionic Gonadotropin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Christina Moss Naturals Natural Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chromacleanse Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chromagen", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chromasafe Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chromasafe Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chromium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Chromium Bromatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chromium Chromic Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Chromium Fluoratum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chromium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chromium Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chromium Nitricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chromium Oxydatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chromium Phosphoricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chromium Sulphuricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chron Sino", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chronagesic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chronic Fatigue Therapy", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Chrysolith 6X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chrysolith Retina",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chrysoprase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Chrysosplenium Chamomilla",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Chuda", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chung Sol Disinfection Ethanol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Chungwidan-F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cialis", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cibinqo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cic Antibacterial Unscented Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cicatricure Aqua Defense Day With Spf", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cicatricure Brightening Face With Spf", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cicatrisan Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cichorium E Rad. 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cichorium Intybus", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cichorium Oxalis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cichorium Oxalis Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cichorium Stibium", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cichorium Stibium Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ciclodan", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cicloferon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ciclopirox",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Ciclopirox Olamine",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cicuta Virosa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cidacin Antifungal Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cidofovir", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cidofovir Dihydrate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cilostazol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ciloxan",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Cimduo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cimetidine",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cimetidine Hydrochloride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cimetidine Hydrochloride Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cimicifuga Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cimicifuga Racemosa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cimicifuga Racemosa Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cimifuga Racemosa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cimzia",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    {
        Medication: "Cina",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cinacalcet",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cinacalcet Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cinchona Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cinchona Officinalis",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cinchona Officinalis Bark", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Cinchona Officinalis Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinchona Succirubra", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cineraria Maritima", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinis Comp A 21 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinis Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinis Comp. Ci 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinis Equiseti 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinis Equiseti 6X Cinis Avenae 6X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinis Equiseti 6X/Cinis Avenae 6X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinis Quercus 17 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinis Quercus 3 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinnabar Comp.", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cinnabar Dandelion",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cinnabaris", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinnafresh", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Cinnamic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cinnamomum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cinnamon",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Cinqair", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cinryze",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Cinvanti", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cipro",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ciprodex", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    {
        Medication: "Ciprofloxacin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }, { Route: "Ophthalmic; Topical" }, { Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ciprofloxacin And Dexamethasone", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ciprofloxacin And Fluocinolone Acetonide", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ciprofloxacin Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ciprofloxacin Hcl Ophthalmic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ciprofloxacin Hydrochloride", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ciprofloxacin Otic", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Circata", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Circatrix", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Circle B Manufacturing Foaming Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Circle K", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Circle K Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circle K Anti-Diarrheal", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circle K Aspirin 325", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circle K Cold", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Circle K Daytime Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Circle K Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Circle K Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Circle K Headache Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Circle K Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Circle K Multi-Symptom Sinus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Circle K Nighttime Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Circle K Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circle K Nosnooze", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Circle K Pain Reliever",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Circle K Stomach", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circle K Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Circulapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circulation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circulation Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circulation Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circulatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Circulopath", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cirlutone", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cisatracurium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cisatracurium Besylate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Cisplatin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Citalopram",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Citalopram Hydrobromide",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Citanest Forte Dental", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Parenteral" }] }] },
    { Medication: "Citanest Plain", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Submucosal" }] }] },
    { Medication: "Citomix", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citranatal 90 Dha", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citranatal Assure", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Citranatal B-Calm", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Citranatal Bloom", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citranatal Harmony 3.0", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citranatal Medley", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citric Buffered Normal Saline", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Citrine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citriplus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Citroma",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Citroma Magnesium Citrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Citrucel",
        DoseTypes: [
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Citrus Crush Scent Hand Sanitizer Panda", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Crush Scent Hand Sanitizer Robot", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Cydonia 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Cydonia 1%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Cydonia 1% Special", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Cydonia 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Cydonia 5%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Cydonia 7", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Fresh Foaming Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Fresh Liquid Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Grove", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Limonum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Pericarpium 10% Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Quartz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Quartz Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Sunshine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Citrus Urtica Comp. Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Citrus Zest Lip Balm", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "City Block Sheer Oil Free Daily Face Protector Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "City Skin Age Defense Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "City Sunscreen Serum Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cj Essentials Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cj Essentials Lavender Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cj Essentials Vanilla Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cl-N", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cladosporium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Cladosporium Cladosporioides", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Cladosporium Herbarum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Cladosporium Sphaerospermum",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Cladribine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Clairfore", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clairfore Natural Antibacterial Sanitizerspray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clam",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Claravis", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clarifying Detox Mask With Green Tea Plus Sulfur", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarifying Face And Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarifying Liquid Peel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarifying Spot With Sulfur Plus Green Clay", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarinex", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clarinex-D 12 Hour", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clarins - Extra-Firming Jour Spf 15 - Wrinkle, Firming Day All Skin Types - Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarins - Roll-On Deodorant Aluminum Chlorohydrate - Antiperspirant, Long-Lasting, Alcohol-Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarins Super Restorative Day Illuminating Lifting Replenishing Broad Spectrum - Spf 20 - Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarins Uv Plus Anti-Pollution B.S. Spf 50, Sunscreen Multi-Protection Tint Medium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarins Uv Plus Anti-Pollution Broad Spectrum Spf 50 Non-Tinted Sunscreen Multi-Protection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarins Uv Plus Anti-Pollution Sunscreen Multi-Protection Broad Spectrum Spf 50 Oil-Free -Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarins Uv Plus Anti-Pollution Sunscreen Multi-Protection Broad Spectrum Spf 50 Oil-Free -Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clariscan", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Clarisonic Pore And Blemish Cleanser Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarisse Antiperspirant Deodoranrt", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarisse Antiperspirant Deodoranrt Cherry Blossom", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarisse Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarite Makeup For A Cleaner Appearance Spf 30 420-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarite Makeup Foundation For A Clearer Appearance Spf 30 170-N", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarite Makeup Foundation For A Clearer Appearance Spf 30 180-F", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarite Makeup Foundation For A Clearer Appearance Spf 30 220-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarite Makeup Foundation For A Clearer Appearance Spf 30 230-N", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarite Makeup Foundation For A Clearer Appearance Spf 30 310-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clarite Makeup Foundation For A Clearer Appearance Spf 30 390-N", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clarithromycin",
        DoseTypes: [
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Claritin",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Claritin Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Claritin-D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Clarity Clear Skin Essentials",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clarus Ac Antifungal Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clarus Antifungal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Classic Care Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Classic Control", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Classic Control Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Classic Touch Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Classic Touch Foaming Antimicrobial Ltion So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Classic Touch Foaming Antimicrobial So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Claudia Elaine Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clauparest", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clbio Dental Clear", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cle Ccc Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Golden Medium Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Medium Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Medium Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Warm Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Warm Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Warm Medium Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle Ccc Warm Medium Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Brightening Enhancer Veil", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Concealer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Concealer N", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Correcting Veil N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Long-Lasting Hydrating Veil", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cle De Peau Beaute Protective Fortifying N",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cle De Peau Beaute Radiant Cream To Powder Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cle De Peau Beaute Radiant Fluid Foundation",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Cle De Peau Beaute Radiant Foundation",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cle De Peau Beaute Radiant Foundation N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Sheer Fluid Veil", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Synactif Daytime Moisturizer N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute The Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute The Foundation N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Uv Protective", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Uv Protective Lip Treatment", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Uv Protective Tinted", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cle De Peau Beaute Uv Protective Very High Protection For Body", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleace 75% Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleace 75% Ethyl Alcohol Antiseptic Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleace 75% Ethyl Alcohol Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleace Alcohol Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Acne Triple Clear Exfoliating Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Advantage Acne Control", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clean And Clear Advantage Acne Control 3 In 1 Foaming Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Advantage Acne Control 3-In-1 Foaming Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Advantage Acne Control Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Advantage Acne Control Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Advantage Acne Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Advantage Fast Acting Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Blackhead Eraser Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Continuous Control Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Daily Skincare Essentials", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clean And Clear Dual Action Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Essentials Deep Cleaning", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Essentials Deep Cleansing Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Persa-Gel 10", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Clear Persa-Gel 10 Maximum Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Fresh Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean And Pure Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Antiseptic Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Antiseptic Wipes Non Sterile Solution Hand Sanitizer", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Apple", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Breath Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Clean Buddies", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Care Fresh Hand Sanitizer (Ethanol Gel)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Care Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Care Plus Sterilize Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Choice Alcohol Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Choice Foodservice Antibacterial Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Concept Eucalyptus Mint Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Concept Lavender Calm Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Day Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Disinfectant Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Drops Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Follicle Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean For All Co. Citrus Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clean Force",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clean Force Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Fresh Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Getaway Bag", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clean Hand Raume", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Hands Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Hands Matter", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Hands Matter Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Hands Wash Without Water", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Is In Hand Sanitizer Antiseptic Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Is The New Chic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean N Fresh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean N Fresh 01", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean N Fresh 02", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean N Fresh Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean N Fresh Instant Hand Sanitizer Antibacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean N Natural Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Queen Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Remedies Cr Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Revolution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Revolution Hand Sanitizer - Free Of Artificial Fragrances", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Screen Mineral Spf30 Mattifying Face Sunscreen Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Simple Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Skin Club Hydrating Hand Sanitizer With Aloe Vera And Hyaluronic Acid", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Skin Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Snaps", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Supply", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Supply Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Up Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Up Intensive Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Wash Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Works Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Works Hand Sanitizer Antibacterial", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean Xpress Antiseptic Hand Cleaner", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean-Us Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clean31", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanboss Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanboss Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanest Co Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanflex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleangenic 1 Gallon Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleangenic 8 Oz Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleangenic Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanhands", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleaninful Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanly N5 Sanitizer Wipes 20Pcs", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanpak Products Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanq Antimicrobial Hand Purifier", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleansafe Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanse", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cleanse Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cleanse-Af Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleansense Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanshot Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanshot Sanitizing Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleansing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cleansing Towelette",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cleansing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanslate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanslate Antibacterial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanslate Antibacterial Foaming Floral", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanslate Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanslate Moisturizing Foaming Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanze Hand Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cleanzzzit Wet Wipes With 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear 40", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear 40 Plus Ha", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear 40 Plus Sa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear 50 Nail Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Acne Body", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Acne Face Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Acne Face Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Acne Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clear Acne Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear And Cover Acne Treatment Concealer Clear And Cover Acne Treatment Concealer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Anti Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Bac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Cell Clarifying Acne", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Choice Extra Strength Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clear Choice Out Of Sight", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Choice Regular Strength Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clear Clear Zinc Solar Protection Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Complexion Acne Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Day", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clear Days Ahead",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Clear Defense",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clear Defense Spf 45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Exfoliating Solution", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Eyes", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Advanced Dry And Itchy", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Complete 7 Sympton Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Cooling Itchy Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Cooling Redness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Maximum Itchy Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Maximum Redness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Natural Tears", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Once Daily Eye Allergy Itch Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Redness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Redness Relief Eye Drops Sterile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Redness Relief Handy Pocket Pal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Travelers Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Eyes Triple Action", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Future Corrective Treatment", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Future Deep Pore Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Future Intensive Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Future Mattifying Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Gel Corn Remover", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Glow Radiant Sun Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Hand Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Hani-Tizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Improvement Pore Clearing Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Lax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clear Med", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Clear My Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clear Out", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Proof Acne System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clear Proof Acne System The Go Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clear Proof Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Proof Blemish Control Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Proof Clarifying Cleansing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Proof Pore Purifying Serum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Reflection Complexion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear S Plus L", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear S Suds", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Sinus And Ear", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clear Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Skin Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Skin Acne Treatment Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Skin Days Brightening Peel Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Skin Days Clarifying Cleanser And Mask", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Skin Days Clarifying Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Skin Days Spot Eraser Stick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Smart Charcoal Blackhead Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Smart Clarifying Cleansing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Smart Clear Complexion Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Smart Clear Pore Hydrator", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Smart Shine Control Sunscreen Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Sunscreen Face Stick Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Sunscreen Face Stick Sensitive Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear Vision Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Clear Zit", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clear-N-Smooth", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Active Deep Cleansing Face And Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Active Lightweight Face And Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Clear Skin Everyday Essentials", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clearasil Gentle Prevention Daily Clean Pads", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Gentle Prevention Daily Clean Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Rapid Rescue Deep Treatment Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Rapid Rescue Deep Treatment Scrub", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Rapid Rescue Deep Treatment Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clearasil Rapid Rescue Spot Treatment",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clearasil Rapidly Clear Acne Solutions", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clearasil Stubborn Acne Control 5In1 Concealing Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Stubborn Acne Control 5In1 Daily Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Stubborn Acne Control 5In1 Exfoliating Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Stubborn Acne Control 5In1 Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Stubborn Acne Control 5In1 Weekly Scrub", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Stubborn Acne Plus Marks One Minute Mask", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Ultra", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearasil Ultra Rapid Action", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearcalm 3 Acne Treatment Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearcalm 3 Anti-Acne Starter", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearcalm Non-Drying Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearcanal Ear Wax Softerner Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Cleareyes Maximum Itchy Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cleareyes Redness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cleargenix Advanced Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearguard Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearing Defense Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Clearlife",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Clearlife Extra Strength",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Clearly Better", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearly Better By Intercom Alcohol-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearly Confident", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearskindays Acne Clearing Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clearskindays Overnight Dual Correcting", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Clearsynergy",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Clemastine Fumarate",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Clematis Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clematis Erecta", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clementine Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clementine Hint Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clen Germ Killer-Spray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clenia Plus", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clenpiq", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clenziderm Daily Care Foaming Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clenziderm M.D. System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Clenziderm Pore Therapy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clenziderm Therapeutic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clenziderm Therapeutic Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cleocin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] },
        ],
    },
    { Medication: "Cleocin Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cleocin Pediatric", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cleocin Phosphate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Cleocin T",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cleviprex", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Climara", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Climara Pro", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Climax Control", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clindacin Etz",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clindacin P", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clindacin Pac", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clindagel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clindamycin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Clindamycin And Benzoyl Peroxide",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Clindamycin Hcl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Clindamycin Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Clindamycin In 5 Percent Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Clindamycin Palmitate Hydrochloride",
        DoseTypes: [
            { DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Clindamycin Palmitate Hydrochloride (Pediatric)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clindamycin Phoaphate Topical Solution Usp, 1%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clindamycin Phoaphate Topical Solution, 1%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clindamycin Phosphate",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clindamycin Phosphate 1.2% And Tretinoin 0.025%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clindamycin Phosphate And Benzoyl Peroxide",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Clindamycin Phosphate And Tretinion", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clindamycin Phosphate And Tretinoin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clindavix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clindesse", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Clinere Ear Wax Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Clinical Strength Miracledry Antiperspirant Deodorant Am", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinical Strength Miracledry Antiperspirant Deodorant Pm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinicalm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinicians Complex", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clinimix",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Clinimix E", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Clinique", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Acne Solutions Cleansing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Broad Spectrum Spf 30 Mineral Sunscreen Fluid For Face", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Broad Spectrum Spf 30 Mineral Sunscreen For Body", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Broad Spectrum Spf 30 Oil Free Face With Solar Smart", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Broad Spectrum Spf 50 Sunscreen Body With Solar Smart", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Broad Spectrum Spf 50 Sunscreen Face With Solar Smart", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Even Better Clinical Serum Foundation Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique For Men Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique For Men Broad Spectrum Spf 21 Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique For Men Super Engergizer Anti -Fatigue Hydrating Concentrate Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Smart Broad Spectrum Spf 15 Custom-Repair Moisturizer Dry Combination", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Smart Custom Repair Moisturizer Broad Spectrum Spf 15 - Combination/Oily To Oily", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Smart Custom Repair Moisturizer Broad Spectrum Spf 15 - Very Dry/Dry", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Spf 50 Broad Spectrum Mineral Sunscreen Fluid For Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Super Defense City Block Daily Energy Plus Face Protector Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Superbalanced Silk Makeup Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinique Targeted Protection Spf 45 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinishield Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clinisol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Clinolipid", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Clinplant", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Clinpro 5000", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clinpro Tooth Creme", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clip Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clip Liquid Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clixit Acne Control Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clixit Acne Drying", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clixit Acne Eraser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clixit Acne Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clixit Tinted Blemish Concealer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cloak Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clobal Care Instant Hand Sanitizer 1.8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clobazam",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Clobetasol Propionate",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clobetasol Propionate (Emmolient)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clobetasol Propionate (Emollient)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clobetasol Propionate Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clobetasol Propionate Cream Usp, 0.05%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clobetavix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clobetex", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clobex",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clocortolone Pivalate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clocortolone Pivalate Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clodan", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clodan Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cloderm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clofarabine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Clofenax", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cloh Living Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clolar", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Clomid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clomiphene Citrate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clomipramine Hcl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clomipramine Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Clonazepam",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Clonidine",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural" }] },
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Clonidine Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Clonidine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural" }, { Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Clonidine Transdermal System", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Cloon Stick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clopidogrel",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Clopidogrel Bisulfate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cloralen Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cloralen Tm Antibacterial Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cloravir Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clorazepate Dipotassium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clorotekal", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Clorox Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clorox Antimicrobial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clorox Fraganzia Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clorox Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clorox Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clorox Healthcare Aloeguard Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clorox Healthcare Gbg Aloegel", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clorox Healthcare Gbg Aloegel Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clorpactin Wcs-90", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Close Up", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Close Up Travel", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Clostridium Difficile Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Closys",
        DoseTypes: [
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental; Oral" }, { Route: "Oral" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Clotrimazoil Antifungal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clotrimazol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clotrimazol Dual", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clotrimazole",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }, { Route: "Oral; Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clotrimazole 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clotrimazole 1% Topical Antifungal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clotrimazole And Betamethasone Dipropionate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Clotrimazole Cream 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Clotrimazole Topical Solution Usp, 1%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cloud 9 100% Mineral Sun Balm Broad Spectrum Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cloudz Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Clozapine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Clozaril", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Club Donatello Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Club Roots - Topical Solution Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Club Wipes Alcohol Free Antimicrobial Cleaning Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Club Wipes Usa Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cml", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cmv Stat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cmx Topical Pain", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cnj-016", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Co Bigelow", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Co Bigelow Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Co Cool", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Co Oh So Dazzling Sweet Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Co Q 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Co-Hypert", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Co-Infxn", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Co. To Go", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Coagadex", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Coal Tar",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coartem", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coast Live Oak Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Coast Maple Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Coast Sage Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Coastwide Professional Antibacterial Hand Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coated Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Coats Aloe",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coats Aloe Analgesic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cobaltum Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cobaltum Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cobaltum Nitricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cobrazol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cocaine Hydrochloride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cocaine Hydrochloride Nasal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cocculus Conium", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cocculus Conium Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cocculus Indicus",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cocculus Indicus Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Coccus Cacti",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cochliobolus Sativus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Cocklebur", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Cocklebur Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Cockroach, American", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Cockroach, German", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Coco Glow Sun Balm", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coco12", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cocoa Bean", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    {
        Medication: "Cocoa Butter Petroleum Jelly",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cocokind Postbiotic Acne Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cocokind Probiotic Acne Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coconut",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Coconut And Lime Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coconut Cosmetics 3-In-1 Serum", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coconut Foaming Hand Sanitizer With Natural Vitamin E And Almond Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coconut Ginger Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coconut Lime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coconut Lip Balm Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coconut Macaroon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coconut Mineral Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coconut Scented Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coconut Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Code Blue Scent Elimination", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Codeine Guaifenesin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Codeine Sulfate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Codeine-Guaifenesin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Codfish", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    {
        Medication: "Coffea Cruda",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Coffea Cruda 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Coffea Cruda 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Coffea Cruda Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Coffea Tosta",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Coffea Tosta 30 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Coffee",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Coffee Tea Chocolate Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cofix Rx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cognition", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Colace",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Colazal", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Colchicine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Colchicum Autumnale",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Colchicum Chelidonium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colchicum E Tub. 4 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colchicum Sabina Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colcigel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Colcol Co Tab.", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colcol-Eunkyo Cap.", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colcol-S", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colcrys", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colcynthis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold - Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold - Sinus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold Allergy", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold And Allergy",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold And Allergy D", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Chest Congestion Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Congestion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold And Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold And Cough 4 Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Cough Day Time", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Cough Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Cough Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold And Flu All In One", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Flu Day Time Multi-Symptom Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Flu Daytime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold And Flu Daytime Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold And Flu Daytime Severe", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Flu Daytime-Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold And Flu Daytime/Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold And Flu Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Flu Hbp", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Flu Multi-Symptom", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold And Flu Multi-Symptom Relief/Cold And Flu Nighttime Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold And Flu Night Time Multi-Symptom Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Flu Night-Time Multi Symptom Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Flu Nighttime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Flu Nighttime D", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold And Flu Nighttime Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cold And Flu Nighttime Severe",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral; Sublingual" }, { Route: "Sublingual" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold And Flu Relief Hbp", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold And Flu Severe",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cold And Flu Severe Daytime",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold And Flu Severe Daytime And Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold And Flu Severe Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold And Flu Severe Daytime/Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold And Flu Severe Nighttime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Head Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Head Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Head Congestion Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Heat Lidocaine Patch Plus Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold And Hot Medicated", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold And Hot Medicated Large", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold And Hot Medicated Pain Relief Large", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold And Hot Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold And Hot No Mess Medicated", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold And Hot Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold And Infection Defense", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cold And Mucus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold And Sinus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold And Sinus Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Sinus Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold And Sore Throat", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Armor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Bee Gone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cold Bee Gone For Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cold Crush Adult", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Crush Childrens", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Faid", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold Flu And Sore Throat",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cold Flu And Sore Throat Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold Flu Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Flu Severe Day Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold Flu Sore Throat", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Hands - Feet", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Head Congestion", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold Head Congestion Daytime",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold Head Congestion Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Leaf Nutrition Muscle And Joint Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cold Max",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold Max Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold Max Daytime", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Max Multi Symptom", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Max Multi Symptom Nighttime", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Max Multi Symptom Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Max Nighttime", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Max Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Medicine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Multi Symptom Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Cold Multi-Symptom",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold Multi-Symptom Daytime", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Multi-Symptom Daytime And Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold Multi-Symptom Nighttime", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Or Allergy", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Plasma Plus Neck And Chest Spf25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold Plus Flu", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Plus Head Congestion Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold Plus Head Congestion Daytime", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Plus Head Congestion Nighttime", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Plus Head Congestion Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold Relief",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold Relief Multi-Symptom", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cold Relief Multi-Symptom Daytime", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Relief Severe Head Congestion", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Sore Fever Blister", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold Sore Fever Blister Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold Sore Free", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold Sore Relief", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cold Sore Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cold Sore Treatment",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cold Sores", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Tabs Ii", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold Terminator Max", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold Therapy",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cold Therapy Pain Relief Medicated", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cold, Flu And Sore Throat",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold, Flu And Sore Throat Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold, Flu, And Sore Throat",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold-Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cold-Eeze",
        DoseTypes: [
            { DoseType: "Chewable Gel", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cold-Flu Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cold/Flu Multi-Symptom Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Coldcalm",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Coldcalm Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colds And Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colds Coughs", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colds Dry Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colds Earache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colds Flu", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colds Nausea Vomiting", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colds Runny Nose", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colds Yellow Mucous", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cole Antimicrobial Premium Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Colemerg 75 Alcohol Sanitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Colemerg Premium Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Colemerg Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Colesevelam",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Colesevelam Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Colesevelam Hydrochloride",
        DoseTypes: [
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Colestid",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Colestipol Hydrochloride",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Colgate",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Colgate 10G And Toothbrush Dental Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Colgate 2In1", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate 5G And Toothbrush Dental Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Colgate 5G Toothbrush Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Colgate Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Anticavity Kit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Cavity Protection", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Cavity Protection Fresh Clean", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Cavity Protection Fresh Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Co Oh So Glowing", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Deep Clean Fluoride", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Double Fresh", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Enamel Health Mineral Repair", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Enamel Health Mouthwash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Enamel Health Sensitivity Relief", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Enamel Health Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Enamel Renewal Deep Clean", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Enamel Renewal Sensitivity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Enamel Renewal Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Enamel Strength Mouthwash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Essentials", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Essentials With Coconut Oil", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Fluoride - Trolls - Mild Bubble Fruit", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Great Regular Flavor", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Gum Renewal Deep Clean", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Hemp Seed Oil", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Junior Bubble Fruit", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Junior Kids Batman Mild Bubble Fruit Fluoride", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Junior Mixed Kids Bubble Fruit Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids - Bubblefruit", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Batman Alcohol Free Anticavity Mouthwash Bat-Signal Bubble Fruit", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Bubblefruit", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Hum", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Llama", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Maximum Cavity Protection", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Maximum Cavity Protection Batman", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Minions", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Minions Mouthwash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Ryans Toy World Alcohol Free Anticavity Ryans Bubble Fruit", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Ryans World", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Sloth", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Space Jam", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Trolls", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Trolls Alcohol Free Anti-Cavity Sparkle Time Bubble Fruit", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Unicorn Alcohol Free", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Watermelon Flavor", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Kids Wonder Woman Alcohol Free Anticavity Bubble Fruit", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Max Clean", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Max Fresh", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Max Fresh Clean Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Max Fresh Cool Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Maxclean With Whitening Smartfoam", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Maxfresh Charcoal", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Maxfresh Knockout", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Maxfresh With Mini Breath Clean Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Maxfresh With Mini Breath Cool Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Maxfresh With Whitening Knockout Fluoride", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Maximum Cavity Protection", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Maximum Cavity Protection Wonder Woman", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Advanced Icy Fresh", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Advanced Sparkling White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Advanced Vibrant Clean", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Charcoal", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Platinum Stain-Less", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Pro Series Stain Prevention", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Proseries Enamel Strenght", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Proseries Vividly Fresh", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Renewal Enamel Strength", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Renewal High Impact White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Renewal Lasting Fresh", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Stain Fighter", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Stain Fighter Clean Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Stain Fighter Fresh Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Optic White Stain Prevention", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Phos-Flur Ortho Defense Alcohol Free Anticavity Gushing Grape Fluoride", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Phos-Flur Ortho Defense Alcohol Free Anticavity Mint Fluoride", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Re Reenergize", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Re Refresh", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Re Renew", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Re Repair", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Re Revive", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Re Variety Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Colgate Renewal Enamel Fortify", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Renewal Sensitivity Repair", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Colgate Renewal Whitening Restoration",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Colgate Sensitive Complete Protection", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Sensitive Plus Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Sensitive Prevent And Repair", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Sensitive Whitening Fresh Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Simply Fresh Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Sparkling White Cinnamint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Swish Invigorating Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Swish Mild Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Swish Refreshing Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total 12 Hr Pro-Shield Spearmint Surge", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Advanced Gum Health Mouthwash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Advanced Pro-Shield Peppermint Blast", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Gum Protection", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Sf Advanced Deep Clean", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Sf Advanced Fresh Plus Whitening Gel", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Sf Advanced Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Sf Clean Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Sf Daily Repair", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Sf Mint Stripe Gel", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Sf Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Sf Whitening Gel", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Ultra Fresh", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Whitening Charcoal", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Total Whitening Fresh Boost", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Triple Action", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Ultra Brite Advanced Whitening All In One Clean Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Ultra Fresh", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Ultra Protect", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Ultra Relief", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Ultra White Charcoal", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You Blue Coconut", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You Blue Fresh Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You Blue Vanilla", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You Red Coconut", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You Red Fresh Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You Red Vanilla", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You White Coconut", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You White Fresh Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate You White Vanilla", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Zero Clear Spearmint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Zero Fresh Breath", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Zero Healthy Gums", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colgate Zero Strong Teeth", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Colibacillinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colibacillinum 30C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colibacillinum Cum Natrum Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Colic",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Colic Calm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colic Calm Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colic Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colic Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colic With Gas", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coliccomfort", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colirio Ocusan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Colistimethate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Colistimethate Sodium", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Colitis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Collagen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Collagen Dream 50 All Face Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Collagen Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Collagen Glow Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Collagen Glow Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Collagenase Santyl", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Collinsonia Canadensis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colloidal Oatmeal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Colloidal Silver", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Collyrium For Fresh Eyes - Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Colo-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Colocynthis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Colocynthis Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colognaid Hand Sanitizing Forest", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Colognaid Hand Sanitizing Green Tea", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Colognaid Hand Sanitizing Lime Bergamot", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Colon 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colon 8X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colon Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Colopar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Color Element Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Advanced Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Alcohol Antiseptic Hand Sanitizer 70 Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Alcohol Antiseptic Hand Sanitizer 75", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Antibacterial Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Antibacterial Wipes Saturated With 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Antiseptic Hand", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Antiseptic Hand Wipes Saturated With 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Color Element Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Colostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coltalin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coltalin-Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coltalin-Nd", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coly-Mycin", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Com-Pare2 Flu Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Combigan", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Combipatch (Estradiol/Norethindrone Acetate Transdermal System)", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Combivent Respimat", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Combivir", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Comcast Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comcast Antibacterial Wipes Lemon Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Come Clean Babe Hand Sanitizer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Come In The Sun Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comes In Handy By Julep Antibacterial Hand 24Ozsanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comes In Handy By Julep Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comes In Handy By Julep Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comes In Handy By Julep Hand Sanitizer 24Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cometriq",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Comfort And Ease", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Comfort For Colic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Comfort For Teething", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Comfort Shield Barrier", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comfort-Time", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Comfortcaine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comfortcaine Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Comfortlab Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comfortox Benzocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comfortox Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comfortox Tetracaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Comirnaty", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Comisqueen Uv Proof Radiance Bloom", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Common Cold", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Common Cultivated Oats", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Common Good Hand Sanitizer (Grapefruit)", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Common Mugwort", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Common Sage Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Common Sagebrush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Common Sense", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Common Weed Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Comocladia Dentata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Companions Antibacterial Plum Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Companions Luxury Foam Hand So Ap, Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Compare2 Black Elderberry Cold And Flu Relief", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Compass 70% Ethanol Hand Sanitizer Gel With Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Compass 70% Ethanol Hand Sanitizer Gel With Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Complera", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Complete Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Complete Allergy And Sinus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Complete Allergy Medicine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Complete Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Complete Beauty", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Complete Care", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Complete Care Tartar Control", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Complete Care Whole Mouth Protection", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Complete Cold Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Complete Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Complete Fortify", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Complete Protection Tp", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Complexion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Complexion Perfection Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Complexion Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Complexion Protection Moisturizer Spf", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Complexion Protection Moisturizing Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Complexion Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Compliance", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Compound Benzoin Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Compound W",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Plaster", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Compound W For Kids One Step Strips", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Compound W One Step Pads", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Compound W One Step Plantar Foot Pads", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Compressed Gas Oxidizing, N.O.S.", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Compressed Gas, N.O.S.", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Compressed Oxygen", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Compressed, N.O.S.", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Compro", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Compulsin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Compulsive Eating Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Computer Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Comtan", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Comtrex Cough And Cold", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Conazol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Concentrated Ibuprofen Infants", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Concentre Total Base Serum Base De Maquillaje Ultra-Fluid Foundation Spf 25 Almendra 230-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Concentre Total Base Serum Base De Maquillaje Ultra-Fluid Foundation Spf 25 Beige 220-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Concentre Total Base Serum Base De Maquillaje Ultra-Fluid Foundation Spf 25 Capuccino 310-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Concentre Total Base Serum Base De Maquillaje Ultra-Fluid Foundation Spf 25 Champagne 170-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Concentre Total Base Serum Base De Maquillaje Ultra-Fluid Foundation Spf 25 Latte 180-F", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Concentre Total Base Serum Base De Maquillaje Ultra-Fluid Foundation Spf 25 Mokaccino 390-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Concept Ii", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Concerta", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Conchae 5 Comp.", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Conchae Argentum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Conchae Urtica Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Condurango",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Condylomata Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Condylox", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Conex",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Congestaid Ii", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Congestion And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Congestion And Headache",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Congestion Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Congestion Relief",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Congestive Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coniferyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coniferyl Alcohol Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Conium Maculatum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Conjunctiva Argentum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Conjunctiva Argentum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Conjupri", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Conlax", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Connec-T", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Connectissue", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Connective Tissue 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Connective Tissue Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Conquer Clean 2 Oz Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Conray", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravascular" }] }] },
    { Medication: "Consciousness Stim Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Consensi", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Constipation",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Constipation Billousness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Constipation Cp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Constipation Cramps", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Constipation Relief",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Constulose", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Consult Health Persistence", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Consult Health Persistence Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Consult Health Persistence Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Contac Cold And Flu", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Contact Allergy Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Contessa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Contractor Engineered Tough Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Contrave", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Control Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Control Corrective Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Control Corrective Intensive Skin Brightening", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Control Corrective Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Control Corrective Tinted Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Control Dandruff Therapy", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Control Menstrual Cramp Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Controlling Balm With Tea Tree Oil", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Controlling Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Convallaria Majalis Mt", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Conzerol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Conzip", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cool And Heat Sore Muscle Roller",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cool Azul Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool Breeze", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool Comfort", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool Cure Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool Freeze", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool Mint Lip Balm", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool Mint Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool Mint Scented Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool N Heat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool N Heat Lidocaine Dry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cool Pap Cataplasma", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Cool Relief Lemon Frost Oral Anesthetic", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cool Relief Oral Anesthetic", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cool Sense Care Plus Mouthwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Coola", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola 70 Percent Plus Organic Mineral Body Sunscreen Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Hands By Bare Republic Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Republic Clearscreen Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Republic Clearscreen Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Republic Mineral Body Gel-Lotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Republic Mineral Face Gel-Lotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Republic Mineral Face-Lotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Republic Mineral Sport Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Republic Mineral Sport Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Bare Republic Mineral Sport Spf50 Non-Nano", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Classic Body Sunscreen Spf 70 - Peach Blossom", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Classic Sunscreen Fragrance Free", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Classic Sunscreen Stick Spf 30 - Tropical Coconut", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Full Spectrum 360 Day Eye Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Full Spectrum 360 Refreshing Water Cream Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Liplux Hydrating Oil Spf 30", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Refreshing Water Mist Spf 18", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coola Sun Silk Drops Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cooling Epsom Rub", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cooling Essential Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cooling Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cooling Itch Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cooling Severe Daytime",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cooling Severe Daytime Cooling Severe Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Cooling Severe Nighttime",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Copaiva Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Copaxone", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Copiktra", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Copper", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Copper Oxide 0.4", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Copperfixx", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Copperlife Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Copperlife Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Copperlife Pain Relief Liquid", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Copperstone Water Babies Pure And Simple Whipped Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Clearly Sheer Whipped Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Clearly Sheer Whipped Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coppertone Complete Sunscreen Spf 30",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Coppertone Complete Sunscreen Spf 50",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coppertone Defend And Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Glow Hydragel Spf 50", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Glow Hydragel Sunscreen Spf 30", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Glow Suncreen Spf 30", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Glow Sunscreen Spf 50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Kids Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Kids Sport Sunscreen Spf 100", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Kids Sport Sunscreen Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Kids Sunscreen Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Kids Sunscreen Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Kids Tear Free Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Limited Edition Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coppertone Limited Edition Sunscreen Spf 50",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coppertone Oil Free Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Protect And Tan Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Protect And Tan Sunscreen Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coppertone Pure And Simple Sunscreen Spf 50",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coppertone Sport Mineral Face Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Sport Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Sport Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Sport Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Sport Suncreen Spf 50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coppertone Sport Sunscreen Spf 100",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coppertone Sport Sunscreen Spf 15", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coppertone Sport Sunscreen Spf 30",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coppertone Sport Sunscreen Spf 40", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Sport Sunscreen Spf 50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Sport Sunscreen Spf 70", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Sport Sunscreen Spl 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coppertone Sunscreen Spf 50",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Coppertone Tanning Sunscreen Spf 15",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coppertone Tanning Sunscreen Spf 8", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coppertone Waterbabies Pure And Simple Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coppertone Waterbabies Sunscreen Spf 50",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cor 5 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cor 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cor Aurum 17/10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cor Aurum 6/10 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coral Analgesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coral Kavi", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coral Safe Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coral Safe Spf-30 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coral Sure Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Antibacterial Moist", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Cold And Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Cold And Hot Medicated", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Extra Strength Pain Relief Sleeve Knee", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Extra Strength Pain Relief Sleeve Wrist", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Medicated Heat", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coralite Muscle And Joint Pain Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coralite Odor Free Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Pain Relief Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coralite Ultra Strength Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Corallium Rubrum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Corallium Rubrum 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coralsafe Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cordial Clean And Pure", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cordran",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tape", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Core Values Allergy Relief",
        DoseTypes: [
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Core Values Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Core Values Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Core Values Minoxidil",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Core Values Night Time Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Core Values Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Core Values Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Core Values Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Core Values Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Core Values Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Coreg",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Coretex", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coretex Anti-Itch Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coretex Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coretex Professional Outdoor Skin Protection Wallet", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Coretex Sun X 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coretex Sun X Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coretex Sun X Spf 30 Multipack New", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coretex Sun X Spf 30 New", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coretex Sun X Spf 30 Thin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coreydee Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Corgard", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coriandrum Sativum (Coriander)", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Coricidin Hbp",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Coricidin Hbp Cough And Cold",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Coricidin Hbp Maximum Strength Cold And Flu Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Coricidin Hbp Maximum Strength Cold, Cough And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Coricidin Hbp Maximum Strength Flu", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coricidin Hbp Maximum Strength Multi Symptom Flu", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coricidin Hbp Maximum Strength Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coricidin Hbp Nighttime Multi-Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Corifact", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Corinz", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Corisin Arthritis Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Corlanor",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Corlopam", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Corn Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Corn Pollen Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Corn Smut", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Cornex",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cornstick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coro-Calm", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Corocclean Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coroclean Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Corphedra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Corpus Callosum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Correct And Protect Serum Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cort Sym", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortaren Corticosteroid/Anti-Inflammatory System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cortef", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cortenema", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Cortex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortex Sunx 50 Thick", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Corti-Sav", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortico Surreninum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Corticol-Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortifoam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortisol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cortisol Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cortisone",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cortisone Acetate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cortisone Aceticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cortisonum 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cortisporin Tc", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Auricular (Otic)" }] }] },
    {
        Medication: "Cortizone 10",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cortizone 10 Anti-Itch For Diabetics Skin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 Cooling Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 Easy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 For Psoriasis", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 Intensive Healing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 Intensive Healing Feminine Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 Intensive Healing For Eczema", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 Overnight Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortizone 10 Sensitive Skin Anti-Itch Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cortrosyn", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intramuscular; Intravenous; Parenteral" }] }] },
    { Medication: "Corvatrol 0.9%", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Corvert", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Corylus Americana Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Cosco Hand Saniitizer 80%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cosco Hand Sanitizer 75%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cosedal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cosela", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cosentyx", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Cosforu Safe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cosmegen", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cosmepure Brightening Calming",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cosmepure Brightening Calming Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cosmepure Derma Revital",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cosmepure Derma Revital Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cosmepure Dual Repair Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cosmepure Dual Repair Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cosmestat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cosopt", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cosopt Pf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cosrx", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cosyntropin",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Cotellic", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cotempla Xr-Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cotton Candy Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotton Candy Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cotton Candy Scented Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cotton Fiber", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }] }] },
    {
        Medication: "Cotton Linters",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Cotton Seed", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Cotton Soft", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cottonseed",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Coty Hand Sanitizer Non-Sterile Solution Alcohol Antiseptic 80% Topical Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Baby", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Face Moisture", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Face Prime And Protect Spf 40 (Non-Tinted)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Face Prime And Protect Spf 40 (Tinted)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Flawless Complexion", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Kids Pure Botanicals", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Pure Botanicals", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Sensitive", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Silky Foam - Tinted", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Silky Foam Non-Tinted", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cotz Sport Non-Tinted", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Couch Quack Grass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cough - Airway",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cough And Bronchial", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough And Bronchial Childrens Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral; Oral" }] }] },
    { Medication: "Cough And Bronchial Daytime Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral; Oral" }] }] },
    { Medication: "Cough And Bronchial Nighttime Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral; Oral" }] }] },
    { Medication: "Cough And Bronchial With Zinc", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough And Bronchial With Zinc Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral; Oral" }] }] },
    { Medication: "Cough And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cough And Chest Congestion Dm",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cough And Cold",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Cough And Cold Hbp",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cough And Cold Plus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough And Cold Relief Hbp", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough And Congestion Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough And Mucus Grape Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough And Runny Nose", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough And Sinus Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Baby", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Cold And Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Colds", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cough Dm",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Drops Cherry With Sugar", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Drops Honey Lemon With Sugar", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Drops Menthol Sugar Free", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Flu", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Hay Fever", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Hiccough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Out", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Perspiration", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Plus Bronchial", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cough Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cough Relief Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Runny Nose", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Sore Throat", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Suppressant And Topical Analgesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cough Syrup Dm", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Syrup Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Thick Mucous", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cough With 100% Natural Honey 4 Kids", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coughtone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coumadin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coumarin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Count Down", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Counteract",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Coupling Chamomile Relax Premium Massagelotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coupling Lavender Relax Premium Massagelotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covaguard", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covaguard Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covaguard Mask", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covanil Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cove Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cover Fx Mattifying Primer Plus Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Plus Correct/Tinted Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Recover Spf 30 Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Recover Spf 30 Cocoa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Recover Spf 30 Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Recover Spf 30 Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Recover Spf 30 Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Recover Spf 30 Taupe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Recover Spf 30 Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover Recover Spf 30 Warm Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.1 Fair Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.11 Amber Brown", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.12 Warm Copper", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.2 Neutral Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.3 Cream Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.4 Rosy Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.5 Peach Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.7 Vanilla Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cover-Expert Sunscreen Broad Spectrum Spf 15 No.9 Honey Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Advanced Radiance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Aquasmooth", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Cgsmoothers Bb Tinted Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Outlast Active Foundation Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Outlast Stay Fabulous 3In1 Foundation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Plus Olay Simply Ageless", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Queen Collection All Day Flawless 3In1 Foundation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Trublend Base Business Everyday Defense Primer Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covergirl Vitalist Healthy Foundation With Vitamins Plus Ensulizole Sunscreen Broad Spectrum Spf 20 (All Shades)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coverqueen All In One Sun Roll", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covex Antibacterial Bar Active Protection", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covex Antibacterial Bar Cool Protection", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covex Antibacterial Bar Fresh Protection", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covex Antibacterial Hand Sanitizer, Cool Breeze", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covex Antibacterial Hand Sanitizer, Fresh Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covex Antibacterial Hand Wash Active Protection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covex Antibacterial Hand Wash Fresh Protection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covex Antibacterial Liquid Hand Wash Cool Protection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covid Spanker Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covid-19 Sterilizing", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covid-19 Sterilizing Agent", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Covideze", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Covixyl-G", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cow Milk", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Cows Milk", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Coxiflu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coxir Ceramide Daily Uv Sun Block", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Coxir Intensive Egf Peptide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Coxir Intensive Egf Peptide Mask Pack", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coxir Intensive Egf Peptide Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coxir Intensive Egf Peptide Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Coxs Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coxsackie", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coxsackie Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Coxsackie Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cozaar", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cozaco Man", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cozaco Woman", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cozima", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cpap Mask Cleaning Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cpd", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cpd/Adsol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cpda-1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cpda-1 Blood Collection System", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cph Anti-Bacterial Breath Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cpr Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cr-371059 Tolnaftate 1% Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cr-5667753 Leader Daily Moisturizer", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Craane Safet Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Crab",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Crackleclean Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cramergesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cramp Calm Muscle Pain Relief Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cramp Tabs", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cramp911", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cramps Spasms", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crampy Belly Rub", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cranberry", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Cranberry Chill Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cranberry Garland", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cranberry Orange Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crane Safety Alcohol Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crane Safety Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crane Safety Antiseptic", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Crane Safety Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crane Safety Bismuth", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crane Safety Burn",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Crane Safety Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crane Safety Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crane Safety Cramp", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crane Safety Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Crane Safety Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crane Safety Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crane Safety Hydrogen Peroxide", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crane Safety Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crane Safety Isopropyl Alcohol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crane Safety Sinus Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crane Safety Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crane Safety Xs Non-Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crane Safety Xs Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cranial Sacral Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crataegus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crataegus E Fol. Et. Fruct. 1% Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crataegus Ferrum Saccharum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crataegus Nicotiana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crataegus Onopordon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crataegus Oxyacantha",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crayola", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Crayola Anticavity Fluoride Fruit Explosion", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crazy Clean Hand Sanitizer - Berry Swirl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Clean Hand Sanitizer - Blue Raspberry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Foaming Hand Sanitizer - Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Sanitizer - Frosted Strawberry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Sanitizer - Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Sanitizer - Vanilla Swirl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Sanitizer Berry Swirl Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Crazy Cleanz Hand Sanitizer Eucalyptus Scented",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Crazy Cleanz Hand Sanitizer Frosted Strawberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Sanitizer Strawberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Sanitizer Vanilla Swirl Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazy Cleanz Hand Wipes Lemon Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazycleanz Hand Sanitizer Strawberry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazyskin Beers", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crazyskin Beers Premium", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cre8Tion Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cream",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cream Oil Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creamy Beige Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creamy Beige Perfecting Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creamy Cappuccino Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creamy Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creamy Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creamy Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crema Cero Zinc Oxide Skin Protectant Diaper Rash Baby Original Formula", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crema Nera Extrema Spf 14", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creme21 Antibacterial Ultra Protect Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creme21 Ultra Protect Travel Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cremo All-Season Lip Balm Spf 25 Vanilla Mint", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cremo Anti-Perspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cremo Antiperspirant Blue Cedar", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cremo Antiperspirant Citrus", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cremo Antiperspirant Reserve", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cremo Antiperspirant Sage", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cremo Antiperspirant Sliver Water", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Creomulsion", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Creon",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Creosote Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crescent White Uv Protector Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cresemba",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Cresolum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crest",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crest 24G And Toothbrush Dental Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Crest 3D White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }, { Route: "Oral" }] }] },
    {
        Medication: "Crest 3D White Brilliance",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crest 3D White Luxe", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crest 3D White Whitening Therapy",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Crest 3Dwhite Whitening Therapy", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Crest Aligner Care",
        DoseTypes: [
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crest Anti-Cavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Crest Baking Soda And Peroxide", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Crest Braces Care", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crest Cavity Protection",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Crest Cavity Protection Regular", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Crest Complete Multi Benefit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Crest Complete Multi-Benefit",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    {
        Medication: "Crest Complete Plus",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Crest Gum And Enamel Repair", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Crest Gum Detoxify",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Crest Kids", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Crest Mystic", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Crest Premium Plus", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Crest Pro Active Defense", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Crest Pro Health",
        DoseTypes: [
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Crest Pro Health Gum And Breath Purify", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Crest Pro-Health",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Crest Pro-Health Advanced",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crest Pro-Health For Me", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crest Pro-Health Hd", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Crest Pro-Health Multi-Protection", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crest Pure",
        DoseTypes: [
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crest Scope", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crest Sensitivity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Crest Sensitvity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Crest Tartar Protection",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Crestor", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crew Care Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crew Care Anti Bacterial Hand Sanitizer Ipa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crew Care Anti Bacterial Hand Sanitizer, Ethanol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crinone", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Crisis Comfort", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crisp Apple Hand Sani", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crisp Clean Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crisp Pear", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crisp Pear Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Critic Aid Clear", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Critic Aid Clear Af", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Critic Aid Skin", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Critic-Aid Clear", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crixivan", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crocodilecloth Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Crocus Sativus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crofab", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cromolyn Sodium",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Intrabronchial" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intrabronchial" }, { Route: "Oral" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Cromolyn Sodium Inhalation", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intrabronchial" }] }] },
    { Medication: "Cromolyn Sodium Oral Solution (Concentrate)", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crossco", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crossco Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crotalus Cascavella", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Crotalus Horridus",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crotalus Terrificus 30X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Crotan", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Croton Tiglium",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Crown Solutions Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crown-Pak Max 4-Ply", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Cry For You", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cryofreeze Roll-On Extra Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cryofreeze Roll-On Regular Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cryofreeze Sport", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cryselle", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Crystal Clean Antibacterial Handsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crystal Clean Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crystal Flush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Crysvita", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Cs5412 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Csi E3 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ct Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ct-Micro Guard G", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ct-Mirco Guard", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ctx3 Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ctx4 Gel 1100", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ctx4 Gel 5000", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ctx4 Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Cu Pain Away Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cuadriderma", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cubeba Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cubicin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cubicin Rf", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cubre T Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cucumber",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Cucumber And Melon Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cucumber Hand Gel 70% Etoh (V/V)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cucumber Melon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cucumber Melon Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cuir Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cultivated Corn", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Cultivated Oat Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Cultivated Rye", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Cultivated Wheat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Cupcake Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cupcake Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cupressus Arizonica Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Cuprimine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprite 3 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum 12", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum 17 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum 21 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum 21X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cuprum 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Aceticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Aceticum 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Aceticum 4 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cuprum Aceticum Nicotiana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cuprum Aceticum Nicotiana Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Aceticum Zincum Valerianicum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Aceticum Zincum Valerianicum Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Arsenicosum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Carbonicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cuprum Metallicum",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Cuprum Metallicum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Nicotiana", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cuprum Nitricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Oxydatum Nigrum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Quartz (Red)", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cuprum Stannum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Sulfuricum 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cuprum Sulphuricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cura Hongo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curacid Dermal For Cuts And Burns", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curad Alcohol", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curad Alcohol Prep Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curad Cream Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curad Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curad Maximum Strength Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curaplex Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curaprox Black Is White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Curare", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Curasore", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cure Well Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cure-Aid Corn Plasters", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curefini", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Curefini Dermal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Curefini Skin Protection Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curestin", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curetech", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curetech Ad", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curetech Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curetech Cold Ice Analgesic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curetech Enshield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curetech Vitamins A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curetech-Good Morning", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Curicin Pfcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curist Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Curist Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Curist Hemorrhoid Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curist Lidocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curist Lidocaine Numbing Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curist Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Curist Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Curithys", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curity Povidone-Iodine One-Step Prep", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curodont Repair Fluoride Plus", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Curosurf", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Endotracheal" }] }] },
    { Medication: "Curoxen", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curoxen Oral Care", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curoxen Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Curoxen With Vitamin D For Kids", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Curvularia",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Curx Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cut Cleaner", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cutaquig", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Cutar", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cutis 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cutter Brand Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cutter Brand Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cuvitru", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Cuvposa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cv", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvp Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cvs",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }, { Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cvs 2X Liq (Csr)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs A And D All-Purpose Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Acne Cleansing Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Adult Enema", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Cvs Alcohol Prep", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Alcohol Swabsticks", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Antacid Lime-Berry Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Anti Nausea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Antibacterial Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Antibacterial Sanitizer Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Antiseptic", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Antiseptic Wound Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Arnica", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Arnica Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Arthritis Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Astringent Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Baby Diaper Rash And Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Baby Nighttime Cold And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Berry Antacid Hbg Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Berry Antacid Heartburn And Gas", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Blister Relief Refill Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Calamine Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Capsaicin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Capsaicin Arthritis Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Cherry Zinc Cold Relief 25Ct", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Chest Congestion Relief Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Childrens", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Childrens Babys Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Childrens Cold And Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Childrens Cough Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Clarifying Acne Body Wash-Pink Grapefruit", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Clarifying Body Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Clean Label Throat Relief Pops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Clear Face Spf 30 3 Oz", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Clotrimazole", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Cold And Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Cold And Hot Medicated Xl", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Cold And Hot Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Complete Protection", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Cvs Cools Itch Fast", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Cuts And Scrapes Refill Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Daytime And Nighttime Severe Cold And Flu Relief Bundle", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Daytime Cold And Flu Relief And Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Decolorized Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Ear Drops For Swimmers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Cvs Eczema Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Extra Strength Itch Stopping", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cvs Eye Allergy Itch Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Cvs Feminine Intimate Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Cvs Gentle Tears", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Cvs Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Cvs Health",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cvs Health 30 Zinc Sheer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Acne Control Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Advanced Acne Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Advanced Scar Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health All Purpose Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Health Antacid And Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Anti Gas", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Anti-Diarrheal Vanilla", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Antibacterial Moist Hand Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Antibiotic Free Wound", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Antibiotic Free Wound And Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Antifungal Anthletes Foot", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Antiseptic Wound Wash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Arnica Blend Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Athletes Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Blackhead Clearing Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Cherry Spf 15 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Chest Congestion Relief Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Childrens", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Childrens Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Cold And Hot Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cvs Health Cold And Hot Roll-On",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cvs Health Cold Remedy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Continuous Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Daytime Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cvs Health Diaper Rash",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Cvs Health Extra Strength",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cvs Health Extra Strength Acetaminophen Softgels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Extra Strength Antacid And Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Extra Strength Sugar Free Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health First Aid Outdoor Prep-Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Four Actions Nasal Decongestant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cvs Health Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Healing On The Go Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Health Hemorrhoidal Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Cvs Health Home Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Health Hydrocortisone Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Hydrogen Peroxide Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Infant", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Lidocaine Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cvs Health Lidocaine Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Cvs Health Lidocaine Pain Relief Plus Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    {
        Medication: "Cvs Health Lidocaine Pain-Relieving",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Cvs Health Lidocaine Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Lubricant Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Health Lubricant Eye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Health Maximum Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Maximum Strength Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Maximum Strength Lidocaine Pain Relief Cream Plus Menthol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Maximum Strength Non Drowsy Day And Night Cold And Flu Max Softgels Minis", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Health Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Medicated Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Muscle Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Non Drowsy Day Cold And Flu Plus And Nighttime Cold And Flu Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Health Original Spf 15 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Pain Relief Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Cvs Health Prebiotic Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Roll-On Foot Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Sinus Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cvs Health Sore Throat Fast Relief Oral Anesthetic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Spf 15 Clear Hawaii Compliant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 30 Beach Guard Clear", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 30 Ultra Sheer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 30 Ultra Sheer Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 50 Beach Guard", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 50 Zinc Face Sheer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 50 Zinc Sheer Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 55 Clear Face Paraben And Phthalate Free", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 55 Ultra Sheer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Spf 55 Ultra Sheer Face Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Sport Clear Broad Spectrum Spf 100 Plus Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Sports Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Health Sterile Saline Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cvs Health Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Stool Softener", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Stool Softener Clear", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Triple Antibiotic With Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Ultra Sheer Broad Spectrum Spf 45 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Ultra Sheer Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Ultra Sheer Spf 100 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Health Ultra Strength Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Ultra Strength Natural Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Health Xl Lidocaine Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Cvs Healthy Isopropyl Rubbing Alcohol Pads 70", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Homeopathic Skin Tag Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Honey Lemon Zinc Cold Relief Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Ichthammolointment Salve", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cvs Itch Relief",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Cvs Itch Relief And Repellent", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Leg Cramps", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Lidocaine Pain Relief Dry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Live Better Body Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Long Acting Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Lubricant Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Lubricant Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Lubricant Eye Drops 15Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Lubricant Eye Drops 30 Ct", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Lubricant Eye Drops Original", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Lubricant Eye Drops Ultra", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Maximum Strength Anti Fungal Pen 25", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Maximum Strength Anti-Fungal Pen 25%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Maximum Strength Anti-Itch Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Maximum Strength Boil Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Maximum Strength Cold And Flu Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Maximum Strength Cold And Hot Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Maximum Strength Feminine Anti-Itch Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Maximum Strength Feminine Anti-Itch Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Maximum Strength Lidocaine Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Maximum Strength Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Medicated Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Medicated Heat", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Medicated Heat 1 Ct", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Medicated Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Merthiolate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Mineral Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Mini Enema", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Cvs Motion Sickness Fast Melting", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Mucus Relief Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Mucus Relief Dm And Overnight Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Multi-Symptom Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cvs Nighttime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Nighttime Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Non-Drowsy Flu Relief", DoseTypes: [{ DoseType: "Pill", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Oil Free Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Oil Free Acne Facial Cleanser-Pink Grapefruit", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Oil Free Acne Foaming Scrub-Pink Grapefruit", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Oil-Free Acne Cleansing Towelettes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Original Nasal Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cvs Overnight Cold And Flu Clear And Cool", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Pain Relieving First Aid Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Pet Wound Care Prep-Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Cvs Pharmacy",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Enema", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Dental" }, { Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cvs Pharmacy Glycerin", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Cvs Pharmacy Instant Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Pharmacy,Inc.", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Pinworm Treatment", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Povidone Iodine", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Povidone-Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Rapid Protection Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Cvs Regular Strength Itch Stopping", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Restore And Defend Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Cvs Saline", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cvs Senna Laxative", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Sens Whtng", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Cvs Severe Congestion And Pain Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Severe Nasal Congestion Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Cvs Severe Tussin Cf Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Sf Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Sf Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Sheer Sfp 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Sinus Wash", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cvs Skin Tag", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Sore Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Spray-On Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Stomach Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Stool Softener", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Strawberry-Lime Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Therapeutic Menthol Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Tooth And Gum Pain Relief", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Total Home Antibacterial", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvs Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cvs Vapor Steam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Cvs Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvshealth Antifungal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvslidocaineantisepticspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cvtox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cyanocobalamin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Subcutaneous" }] },
        ],
    },
    { Medication: "Cyanocobalamine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Cyanokit", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cyantific Protecting Daily Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Antibacterial Handsoap Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Antibacterial Handsoap Grapefruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Antibacterial Handsoap Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Antibacterial Handsoap Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Antibacterial Handsoap Orange Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Antibacterial Handsoap Vanilla", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Hand Sanitizer Wipes Eucalyptus", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Hand Sanitizer Wipes Grapefruit", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizer Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizer Grapefruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizer Orange Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizer Vanilla", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizerspray Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizerspray Grapefruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizerspray Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizerspray Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizerspray Orange Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Moisturizing Hand Sanitizerspray Vanilla", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Power Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Power Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Power Sanitizingspray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyber Clean Professional Power Swabs", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyberderm", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyberderm Simply Zinc Lite", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyclamen Europaeum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cycle", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyclease Cramps", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cyclease Menopause", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cyclease Pms", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cyclobenzaprine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cyclobenzaprine Hci", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cyclobenzaprine Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cyclobenzaprine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cyclogyl", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cyclomydril", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cyclopak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cyclopentolate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Cyclopentolate Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    {
        Medication: "Cyclophosphamide",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous; Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cycloserine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cycloset", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Cyclosporine",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cyclosporine, Modfied", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cygzyme", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyklokapron", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cymbalta", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cynara Scolymus (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cyonanz", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cypress Bio-Tech Disinfectant Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cypress Bio-Tech Hand Sanitizing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cypress, Arizona Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Cyproheptadine Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Cyramza", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cyred Eq", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cystadane", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cystadrops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cystagon", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cystaran", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Cysteine Hydrochloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cystex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cysto-Conray Ii", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Ureteral" }] }] },
    { Medication: "Cystoges", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cystografin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cystografin Dilute", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravesical" }] }] },
    { Medication: "Cystotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cysview", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cytalux", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Cytarabine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intrathecal; Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intrathecal; Intravenous; Subcutaneous" }, { Route: "Intravenous" }, { Route: "Intravenous; Subcutaneous" }] },
        ],
    },
    { Medication: "Cyto Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cytogam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cytokine 8", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Cytomegalovirus Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cytomel", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cytotec", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Cytovene", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Almendra 230-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Avellana 270-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Avena 170-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Azucar Blanca 110-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Azucar Moreno 300-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Bombon 400-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Brownie 380-F", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Cacao 350-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Canela 280-F", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Caramelo 210-F", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Chantilly 130-F", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Chocochip 330-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Chocotrufa 430-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Dulce De Leche 260-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Espresso 450-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Miel 200-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Nuez 180-F", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Panela 320-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Toffee 240-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Base De Alta Cobertura Y Larga Duracion Fps 20 Vainilla 140-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Cyzone Studio Look Polvos Compactos De Alta Cobertura Con Fps 20", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "D T B T", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "D-10", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-100", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-101", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-102", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-103", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-104", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-105", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-106", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-107", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-109", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-11", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-110", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-111", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-112", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-113", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-115", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-116", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-117", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-118", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-119", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-12", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-120", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-121", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-122", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-123", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-124", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-125", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-126", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-127", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-128", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-129", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-13", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-130", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-131", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-132", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-133", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-135", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-136", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-137", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-138", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-139", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-15", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-152", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-16", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-18", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-19", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-20", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-22", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-23", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-24", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-25", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-26", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-27", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-28", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-29", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-30", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-31", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-32", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-33", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-34", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-35", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-36", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-37", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-38", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-39", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-4", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-40", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-41", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-42", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-43", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-44", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-45", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-46", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-47", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-48", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-49", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-5", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-50", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-51", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-52", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-53", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-54", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-55", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-57", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-58", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-59", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-6", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-60", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-61", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-62", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-63", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-64", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-65", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-66", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-67", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-68", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-69", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-70", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-71", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-72", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-73", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-74", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-75", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-76", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-77", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-78", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-79", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-8", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-80", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-83-1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-83-2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-83-3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-83-4", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-84", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-85", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-86", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-87", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-88", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-89-1 Eardrops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "D-9", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-90", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-91", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-92", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-93", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-94", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-95", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-96", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-97", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-98", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-99", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D-Cal", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "D-Cal Kids",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "D-Cerin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "D-Col Personal Hygiene Convenience Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "D-Fenz Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "D.Pro Pack Solid", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "D.S Crystal Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "D01", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D02", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D03", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D05", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D06", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D07", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D08", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D09", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D1 Organ Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "D10", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D134", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D14", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D17", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D2 Organ Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "D3 Organ Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "D56", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D81", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D82", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D83", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "D83-5", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Dabida Extreme Skin Tag Remover", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dabigatran Etexilate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dabur Honitus Herbal Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dacarbazine",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dacogen", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dactinomycin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Daily Acne Control Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Anti-Aging Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Baby", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Balance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daily Care Epsom Salt", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daily Correct Cc Cream Broad Sunscreen Spf 35 Light/Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Defence Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Defense", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Defense Antioxidant Day Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Defense Mineral Face Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Deflector Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Dose Hydra-Ceramide Boost Broad Spectrum Sunscreen Spf 40", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Dose Vitamin C And Broad Spectrum Spf 40 Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Driver Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Essentials Antibacterial Hand Sanitizer Aloe Vera Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Essentials Antibacterial Hand Sanitizer Lavender Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Face Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Face Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Facial Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Facial Moisturizer Spf 35", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Fiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daily Leave On Acne Treatment Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Light Guard Defense Fluid Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Moisturizing - Oatmeal", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Moisturizing Antioxidant Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Moisturizing Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Oatmeal", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Protezione", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Replenishing Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Shield", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Spf 20 Oleander Face Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Spf Facial Sunscreenlotion Broad Spectrum Spf 32", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Uniform", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daily Uv Protector Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dailycare Antibacterial Hand Sanitizer Original Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dailycare Antibacterial Hand Sanitizr Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dailycare Hand Sanitizer Ocean Breeze Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dailygard Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dailygard Wipes 75 Percent Ethyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dailygard Wipes 75 Percent Isopropyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dairy Allergen Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dairy Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dairy Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dairy-Grain-Nightshade", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dairy-Grain-Nightshades", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daisy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Daisy Care Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daisy Clean Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dakota Muscle Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dakota Muscle Relief Roll-On", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dalan Therapy Antibacterial Hand Wash, Active Protection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dalan Therapy Antibacterial Hand Wash, Cool Protection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dalan Therapy Antibacterial Hand Wash, Fresh Protection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dalektro N", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dalfampridine",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daliresp", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dalpro Hand Sanitizer Non Sterile", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dalvance", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Damiana", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Damiana Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Danazol", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dandelion",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Dandelion Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Dandi Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dandruff",
        DoseTypes: [
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dandruff 2 In 1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dandruff Relief", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daniel Alain Extra Strength Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daniel Alain Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dans Anti-Bacterial Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dantrium",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dantrolene", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dantrolene Sodium", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Danyelza", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Daphne Mezereum 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daphne Mezereum 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dapiprazole", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Dapsone",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daptacel", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Daptomycin",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dapzura Rt", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Daraprim", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Darby Apf Bubble Gum", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Darby Apf Mint", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Darby Apf Strawberry", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Daredevil Strength Muscle And Arthritis Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daredevil Strength Muscle And Arthritis Pain Relieving Roll On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Darifenacin",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Darifenacin 15 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Darifenacin 7.5 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dark Daylight Tinted Radiance Moisturizer Broad Spectrum Spf 32", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dark Skin Defense Concealer Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dark Spot Corrector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dartisla Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Darzalex", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Darzalex Faspro", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Darzalex Iv", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dasetta 1/35", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dasetta 7/7/7", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dash Chlorhexidine Gluconate 0.12% Oral Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Date Palm Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Datscan", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Daunorubicin Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Daurismo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dawn Ultra Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dawnmist Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dawnmist Antibacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dawnmist Antiperspirant Deodorant",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dawnmist Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dawnmist Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Day And Night Cold And Flu Formula", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day And Night Severe Sinus Congestion And Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day And Night Sinus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day And Night Sinus Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day And Night Vitals", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Day Cold And Flu Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day Cold And Flu Plus And Nighttime Cold And Flu Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Daily Anti-Aging Moisturizing Face Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Day In The Sun Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day L", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Day Maximum Strength Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Severe Cold And Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day Severe Cold Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Day Time",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Day Time And Night Time Cold And Flu Formula", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day Time Cold And Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Time Cold And Flu Formula", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Time Cold And Flu Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Time Cold And Flu Severe", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Time Cold Flu Medicine", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Time Night Time Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Day Time Non-Drowsy Cold - Flu Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Time Severe Cold And Cough", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Time Sinus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day Time Sinus Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day-Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day-Night Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Day-Time", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daybird Tinted Skincare (Shade 1)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daybird Tinted Skincare (Shade 2)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daybird Tinted Skincare (Shade 3)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daybird Tinted Skincare (Shade 4)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dayclear Allergy Immune Therapy", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dayclear Allergy Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dayclear Allergy Relief Chewables", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daycon Antibacterial Foaming Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daycon Essentials Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daydream Cushion", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Antibacterial Foaming Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Antibacterial Foaming Wash Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Antibacterial Gold Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Foaming Sanitizer Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic For Men", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Fresh Pear Foaming", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Oil-Free Cleansing Wipe For Acne-Prone Skin", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Refreshing Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Skin Relief Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic Tinted Daily Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daylogic White Tea Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daypro", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dayquil And Nyquil Severe Plus Vicks Vapocool", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dayquil Severe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dayquil Severe Plus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dayquil Severe Plus Vapocool Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dayquil Severe Plus Vicks Vapocool", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daysee", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Daytime",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daytime And Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Daytime And Nighttime Cold/Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime And Nighttime Cough And Chest Congestion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime And Nighttime Pain Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime And Nighttime Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime And Nighttime Sinus Congestion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime And Nighttime Sinus Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime And Nitetime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Cold And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Cold And Cough And Nighttime Cold And Congestion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Daytime Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daytime Cold And Flu And Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Cold And Flu Multi Symptom", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Cold And Flu Multi-Symptom Relief", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Cold And Flu Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Cold And Flu Nitetime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Daytime Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daytime Cold And Flu Severe And Nighttime Cold And Flu Severe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Cold Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Cold Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Cold, Nighttime Cold", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Cold/Flu Relief Softgels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Congestion Pressure And Pain", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Cough Nighttime Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Flu Relief Therapy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Maximum Strength Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daytime Mucus Relief Severe Cold And Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Mucus Relief Severe Cold Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Mucus Relief Severe Cold/Nighttime Mucus Relief Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Mucus Relief Severe Congestion And Cough And Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Daytime Nighttime Cold And Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Daytime Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nighttime Cold And Flu Relief Combo Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nighttime Cold Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Daytime Nighttime Cold/Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nighttime Multi Symptom Cold", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nighttime Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Daytime Nighttime Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nighttime Severe Cold Cough And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nighttime Sinus Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Daytime Nitetime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nitetime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Nitetime Severe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Non-Drowsy Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Pe Nitetime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Daytime Severe",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daytime Severe Cold", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Daytime Severe Cold And Cough",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daytime Severe Cold And Cough And Nighttime Severe Cold And Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Daytime Severe Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daytime Severe Cold And Flu Nighttime Severe Cold, Cough And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Daytime Severe Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Daytime Severe Cold And Flu Relief Softgels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Severe Cold Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Severe Congestion Relief And Nighttime Severe Cough And Congestion Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Sinus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Sinus And Congestion", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Sinus Nighttime Sinus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime Sinus Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime Sinus Relief And Nighttime Sinus Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytime- Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime-Nighttime Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daytime/Nitetime Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Daytrana", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Dayvigo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Daywear Advanced Multi-Protection Anti-Oxidant Broad Spectrum Creme Spf 15 For Dry Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daywear Anti Oxidant 72H-Hydration Sorbet Creme Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Daywear Multi-Protection Antii-Oxidant 24H-Moisture Creme Broad Spectrum Spf 15 Normal/Combination Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Db Antifungal Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Db Foot Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Db Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dc Batman Moisturizing Hand Cleanser With Alcohol Dark Forest", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dc Robin Moisturizing Hand Cleanser With Alcohol Amber And Ginseng", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dc Superman Moisturizing Hand Cleanser With Alcohol Aqua", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dc Wonder Woman Moisturizing Hand Cleanser With Alcohol Jasmine And Indigo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dch Labs Cool And Heat Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dcr Labs Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dd Tz Forte", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ddavp",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ddm Calaclear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ddm Calagesic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ddm Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ddm Camphor Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ddm Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ddm Day Time And Nite Time Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ddm Decolorized Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ddm Ear Drops For Swimmers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ddm Herbal Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ddm Instant Antiseptic Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ddm Iodine Tincture Mild", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ddm Merthiolate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ddm Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De Haomian", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De La Cruz Camphor", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De La Cruz Castor Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Oral" }] }] },
    { Medication: "De La Cruz Decolorized Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De La Cruz Desempacho", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "De La Cruz Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De La Cruz Gentian Violet", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De La Cruz Sodium Bicarbonate Antacid", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "De La Cruz Sulfur Acne Medication", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De La Cruz Tincture Of Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De-Aging Moisturizing Day Creme With Sunscreen Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "De-Congestion", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dead Down Wind Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dead Down Wind Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dead Down Wind Unscented Soft Solid Antiperspirant And Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dead Sea Collection Antibacterial Hand Spiced Apple", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dead Sea Collection Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dead Sea Essentials", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dead Sea Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dear Brightly Neverskip", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deb Instantfoam Alcohol Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deb Instantfoam Complete", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deb Instantfoam Non-Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deb Instantfoam Non-Alcohol Pure", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deb Instantfoam Pure Non-Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Debacterol Canker Sore Pain Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Debaochun", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Debaosheng", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Debbies Down And Dirty Deep Tissue Joint And Muscle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deblitane", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Debrox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Debrox Swimmers Ear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Decasilin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dechangling", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Decitabine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Decolorized Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decon Hand Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decon-E Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decon-I Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decon7 Antiseptic Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deconex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Deconex Ir", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Decongestant Ii", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Decongestant Pe",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Decorte Aq Uv Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decorte Sheer Brilliance 10", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decorte Sheer Brilliance 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decorte Sheer Brilliance 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decorte Sheer Brilliance 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Decree Daily Shield Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deedol Topical Analgesic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Blue", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Cleaning Astringent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Daylight Tinted Radiance Moisturizer Broad Spectrum Spf 32", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Fresh Antibacterial Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Fresh Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Fresh Antiseptic Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Pore Acne Scrub", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Relief Acne (Blemish) Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Remedy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deep Renewal Broad Spectrum", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deepfresh Antibacterialsoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deeveeant Numb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defaeton", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Defend", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Defend Cold And Mucus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Defend Sinus", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Defend Tattoo Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defender Antiseptic Foam Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defender Antiseptic Foam Hand Sanitizer Alcohol-Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defender By Gsd Sanitzing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Defender Collection",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Defender Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defender Signature", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defenderstick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defendr Antibacterial Foaming", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defendr Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defendr Be Mine Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Defendr Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Defendr Love Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defense Acne Care 2% Salicylic Acid", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defense Antifungal Medicated Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defense Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defense Refresh (Re)Setting Mist Spf 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defense Refresh Setting Mist Broad Spectrum Sunscreen Spf 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Defense Total High Protection Spf 50 No Color Daily Non-Greasy Face Protection", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Defense Zone",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Defensol Adult Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Defensol-Ito", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Defensolito Children Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Deferasirox",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Deferasirox Oral", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Deferasirox Oral Granules", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Deferasorox", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Deferiprone",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Deferoxamine", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Deferoxamine Mesylate", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Definity", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Definity Rt", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Defitelio", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Degasa Hand Sanitizer Lemon Lime Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Degex Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Degex Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Degong Ling", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Degree",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Degree Men",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Deguli", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dehydrated Alcohol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Dekangling", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delay Sprays For External-Use", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Delayed Release Laxative Bisacodyl", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Delestrogen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Delflex", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intraperitoneal" }] }] },
    { Medication: "Delicare Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delicare Non-Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delicate Beige Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delicate Beige Perfecting Makeup Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delice", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delirium Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Delivery Support", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Delli Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delma", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delphis Eco", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Delsan",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Delstrigo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Delsym",
        DoseTypes: [
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Delsym (Dextromethorphan)", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Delsym Cough", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Delsym Cough Plus Chest Congestion Dm And Delsym Cough Plus Cold Night Time", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Delsym Nighttime Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Delta Comfort Plus 2019 Amenity With Colgate Cavity Protection", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Delta Germ Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delta One 2019 Tumi Collins Amenity With Crest Complete Whitening Scope", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Delta One 2019 Tumi Collins And Tumi Collins Bombas Amenity With Crest 3D White Brilliance", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Delta Premium Select 2019 Tumi Morrison Amenity With Colgate Cavity Protection", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Delta Ron 2019 Amenity With Colgate Cavity Protection", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Delta Sky Club", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Delta Travel Kit Comfort Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Deltuss Dmx Cough Suppressant Nasal Decongestant Antihistamine Grape Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Deltuss Dp Nasal Decongestant Antihistamine Cherry Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Deluqing", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Delzicol", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dematiaceae Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Demeclocycline Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dementia Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Demerol",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Demon Gun", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Demser", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Demyc", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dena-Vie First Aid Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Denavir", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dendracin Neurodendraxcin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dengvaxia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Denmat Hydrogen Peroxide Oral Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Denorex Extra Strength", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Denta 5000 Plus", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dentaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dentagel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dental Care Advance Cleaning", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dental City Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dental Clear Premium", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dental Kit Soft Mint", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dental Kit Strong Mint", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dental Pain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dental Plus Extra Mint", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dental Plus Fresh Mint Flavor", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dental Plus Regular Flavor", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dental Plus Whitening", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dental Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dental-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dentalux Charcoal", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dentalux Coconut Minerals", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dentalux Kids Bubble Gum", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dentalux Kids Mixed Berry", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dentalux Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dentalux Total Care", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dentastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dentek Instant Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Dentemp Canker Cover", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Dentemp Canker Sore", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Denti Whoo Blue Tabletgargle", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denti Whoo Bubble Concentrated Gargle", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denti Whoo Cts Germ Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denti Whoo Cts Germ Free-F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denti Whoo Cts Gum Caretoothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denti-Care Denti-Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denti-Care Denti-Pro", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denti-Care Denti-Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denticare Denti-Pro", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Denticare Pro-Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dentisse", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Dentox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Deodorant", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deodorant Antiperspirant Sprays", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deodorant Disinfectant Concentrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deodry Antiperspirant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Depakote",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Depen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Depens Premium Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Depens Premium Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Depo-Estradiol", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Depo-Medrol", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular; Soft Tissue" }, { Route: "Intralesional; Intramuscular; Intrasynovial; Soft Tissue" }] }] },
    { Medication: "Depo-Provera", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Depo-Subq Provera", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Depo-Testosterone", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Depressoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Deqingling", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dercut",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Cutaneous" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dercution", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Derladie Witch Hazel Perfect Vitamin Gelcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derladie Witch Hazel Perfect Vitamin Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derm Assure Hand Sanitizer Aloe And Grapefruit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derm Dude Brightening Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derm Institute Daily Defense Protection Sunscreen Broad Spectrum Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma 1 Healthcare Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Anti-Wrinkles", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Brite", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Charcoal Mask", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma E Ultra Sheer Mineral Body Sunscreen Mist Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Fade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Firm Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Gran", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Hand Repair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Hydration Boosting", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Instant Youth", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Lightening", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Lip Plumper Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Master Lab Hand", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Numb Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Pain Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Peptide Anti-Wrinkle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Peptide Anti-Wrinkle Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Rash Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Derma Scale Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Derma Swiss", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma Under Eye", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma-E All Sport Performance Face Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma-E All Sport Performance Sunscreen Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma-E Eczema Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma-E Kids Active Sunscreen Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma-E Sensitive 3-In-1 Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Derma-Smoothe/Fs", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermablend Cover", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermablend Leg And Body Cover", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermablend Professional Blurring Mousse Camo Foundation Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermablend Professional Cc Continuous Correction Full Coverage Makeup Broad Spectrum Spf 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermablend Professional Leg And Body Makeup Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermablend Professional Smooth Camo Medium Coverage Foundation Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermablend Quick Fix Concealer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermabon Psoriasis And Dandruff", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaced Deep Therapy Eczema", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaced Maximum Strength Anti-Fungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacen Antimicrobial-B Hand", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacen Antimicrobial-P Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacen Foaming Instant Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacen Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacen Instant Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacen Non-Alcohol Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacen Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacerin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacinrx Clorhexacin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dermacinrx Lexitral Pharmapak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dermacinrx Lexitral Pharmapak Ii", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dermacinrx Lido V Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacinrx Lidotral", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacinrx Penetral Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacinrx Phn Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dermacinrx Purefoltin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dermacinrx Skin Moisturizing Ultra Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacinrx Surgical Combopak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dermacinrx Therazole Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dermacinrx Zinc Oxide Skin Healing", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermacinrx Zrm Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dermaclear Bha Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaclear Bha Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaclear Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaclear Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaclear Purity Cleanser Level I", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaclear Purity Cleanser Level Ii", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaclear Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaclear Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermad Anti-Itch Therapeutic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermad Antifungal Therapeutic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermad Atopic Dermatitis Therapeutic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermad Jock Itch Therapeutic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermad Psoriasis Therapeutic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermadoctor Dd Cream Dermatologically Defining Bb Cream Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermadoctor Dd Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaer Psoriasis Therapeutic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermafit Athlete Muscle Maintenance", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermafit Basic Muscle Maintenance", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermafit Slim Muscle Maintenance", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermafoam Premium Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermafungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermagel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dermagesic",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dermahan The Whitening Moisturizing Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermahan Whitening Perfectcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaharmony 1% Pyrithione Zinc", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaharmony 10% Benzoyl Peroxide", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaharmony 10% Sulfur", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaharmony 5% Benzoyl Peroxide", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaharmony Bar", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaharmony Face And Body", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaharmony Pyrithione Zinc", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaharmony Seborrheic Dermatitis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dermaharmony Sulfur And Salicylic Acid",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dermaharmony Zinc Therapy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaheal Ultimatum Ptx Sports", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaka Broad Spectrum Spf-42 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaklear Akne Treatment With Sulfur", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermakleen", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermal Wound Cleanser", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaleve", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermalid", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermall Matrix Eye Zone Dermal Care Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermall Matrix Facial Dermal Care Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermall Matrix Neck Dermal Care Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermall Matrix Quick Vitalizing", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermall Matrix Stratum Basale Restoring", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermalogica Dynamic Skin Recovery Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermamade Spf 33 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermamine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Derman Antifungal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Derman Antifungal Powder Tolnaftate", DoseTypes: [{ DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermanumb Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermapain Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dermapain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dermaphex Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dermarest",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dermaroller Anhydrous Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermasan Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermasarra", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermasco Clean And Fresh Hands Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermasil Advanced", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermasil Dryskin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermasil Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermasil Sensitive", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermaswiss", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermatin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermatitis Face Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermatome Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dermatome Stat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dermatone Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermatone Spf 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermatone Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermatool Fungal Nail Solution", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermatouch Hydrating Clear Moisutrizer Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermavivid Ultra Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermawash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermawash S2", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermawound Wound Care", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermawound Wound Care Venous Stasis", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermeleve", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermend Moisturizing Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermesse Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermesse Pad Treatment 0.5", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermesse Pad Treatment 2.0", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermesse Sunscreen Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermesse Sunscreen Spf30 Medium Tint", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermessentials Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermisa Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermocrem", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermogen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermoplast First Aid", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermoplast Insect Itch And Sting Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermoplast Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermoplast Sunburn And Burn Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermoscribe Diaper", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermoscribe Ichybum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermoscribe Psoriasis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermoscribe Seborrheic Dermatitis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermotic", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Dermovix", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dermshield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Descovy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Desenex", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desenfriol-D", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Desensitizing Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desert Harvest Releveum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desert Ragweed", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Desert Ragweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Desferal", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Desflurane", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Desgen", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Desgen Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Desgen Dm Tabs", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Deshiyou", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desintox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Desipramine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Desitin Daily Defense Zinc Oxide Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desitin Maximum Strength Diaper Rash", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desitin Maximum Strength Zinc Oxide Diaper Rash", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desitin Multi-Purpose Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Desloratadine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Desmaquillante Ojos Weye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Desmopressin Acetate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }, { Route: "Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }, { Route: "Nasal" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Desogestrel And Ethinyl Estradiol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Desogestrel And Ethinyl Estradiol And Ethinyl Estradiol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Desonate", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Desonide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Desonide Ointment, 0.05%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desour Crimson", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Desowen",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Desoximetasone",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Desoximetasone Oint 0.05%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Desoxyn", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Despec",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Desrx", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Destination Brands International", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Desvenlafaxine",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Desvenlafaxine Er", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Desvenlafaxine Succinate",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Detangjiang", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Detectnet", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Deter Outdoor Skin Protection Natural Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Detergent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Detergent For Cars", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Detijian Nadh", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Detox Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Detox Drainage Kidney", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Detox Drainage Liver", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Detox Escort", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Detox Foot Patchs", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Detoxifier",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Detroderm Soft Gel Hand And Skin Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Detroderm Soft Hand And Skin Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Detrol", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Detrol La", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Detrox",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dettol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Devobis Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Devrom",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dew Good Illuminating Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dew Skin Tinted Moisturizer Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dew Skin Tinted Moisturizer Broad Spectrum No2", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dew Skin Tinted Moisturizer Broad Spectrum No3", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dew Skin Tinted Moisturizer Broad Spectrum No4", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dew Skin Tinted Moisturizer Broad Spectrum No5", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dew Skin Tinted Moisturizer Broad Spectrum No6", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dew Skin Tinted Moisturizer Broad Spectrum No7", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dew Skin Tinted Moisturizer Broad Spectrum No8", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dewzi Alcohol Free Hand Sanitizer Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dexamethasone",
        DoseTypes: [
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dexamethasone 6-Day", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dexamethasone Intensol", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dexamethasone Sodium Phosphate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular; Intravenous; Soft Tissue" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Emulsion", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular; Intravenous; Soft Tissue" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Dexbrompheniramine Maleate And Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dexbrompherniramine Maleate And Pseudoephedrine Sulfate", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dexedrine", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dexilant", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dexinling", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dexlansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dexlido Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Infiltration; Intramuscular; Intravenous; Topical" }] }] },
    { Medication: "Dexlido-M Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intramuscular; Intravenous; Topical" }] }] },
    {
        Medication: "Dexmedetomidine",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dexmedetomidine Hcl", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Dexmedetomidine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dexmedetomidine Hydrochloride In 0.9% Sodium Chloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dexmedetomidine Hydrochloride In Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Dexmethylphenidate Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Dexrazoxane",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dexrazoxane Hydrochloride", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dextenza", DoseTypes: [{ DoseType: "Insert", Routes: [{ Route: "Intracanalicular; Ophthalmic" }] }] },
    { Medication: "Dextragel Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dextroamphetamine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextroamphetamine Saccharate And Amphetamine Aspartate And Dextroamphetamine Sulfate And Amphetamine Sulfate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate Monohydrate, Dextroamphetamine Sulfate And Amphetamine Sulfate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate Monohydrate, Dextroamphetamine Sulfate, Amphetamine Sulfate", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate Monohydrate, Dextroamphetamine Sulfate, Amphetamine Sulfate Er", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate Monohydrate, Dextroamphetamine Sulfate, Amphetamine Sulfate Extended-Release", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate Monohydrate, Dextroamphetamine Sulfate, And Amphetamine Sulfate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate Monohydrate, Dextroamphetamine Sulfate, And Amphetamine Sulfate Extended-Release", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate, Dextroamphetamine Sulfate And Amphetamine Sulfate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate, Dextroamphetamine Sulfate, Amphetamine Sulfate Tablets,Cii", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dextroamphetamine Saccharate, Amphetamine Aspartate, Dextroamphetamine Sulfate, And Amphetamine Sulfate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Dextroamphetamine Sulfate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dextromethorphan Hbr", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hbr 15 Mg", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hbr 15 Mg Liquid Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hbr And Guaifenesin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hbr, Guaifenesin", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hydrobromide", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hydrobromide And Guaifenesin", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hydrobromide And Promethazine Hydrochloride", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hydrobromide, Guaifenesin, And Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Hydrobromide, Guaifenesin, Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Polistirex Extended Release", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dextromethorphan Polistirex Extended-Release", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dextrose",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }, { Route: "Parenteral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dextrose And Electrolyte No. 48", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dextrose And Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dextrose In Lactated Ringers", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Dextrose Monohydrate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Parenteral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dexycu", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intraocular" }] }] },
    { Medication: "Deyanling(Cranberry Flavored Collagen Peptide)", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Deyo", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dfndr Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dfs 1.5%/Ms 25%/Menth 6%/Cap 0.025% Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dfs 75Mg Dr/Ms 25%/Menth 6%/Cap 0.025% Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dg 3X Medicated Toothache Adn Gum Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg 4X Medicated Tooth And Gum Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Baby Vitamins A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Body Antibacterial Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Day Time Cold And Flu Formula And Night Time Cold And Flu Formula", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Dg Health",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dg Health 8 Hour Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health 8 Hr Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Acid Reducer Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Adult Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Adult Tussin Multi Symptom Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Advanced Relief Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Dg Health All Day Allergy",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dg Health Aller Ease", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dg Health Allergy",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Dg Health Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dg Health Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Dg Health Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Antacid Berry Bites", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dg Health Antibiotic",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dg Health Arthritis Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Health Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Dg Health Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens Mucus Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens Mucus Relief Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Childrens Pain Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dg Health Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dg Health Cold And Flu Severe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Cold Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Cold N Hot Pain Relieving", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Health Cold Plus Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Cold Zone Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Health Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Cough Drops Assorted Flavors", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dg Health Cough Drops Cherry Flavor", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Health Day Time Severe Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Day Time Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Day Time Severe Cold And Flu Relief Night Time Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dg Health Dry Eye Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dg Health Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Flu And Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Headache Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Heartburn Prevention", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Dg Health Hydrocortisone",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Dg Health Ibuprofen",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dg Health Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Immediate Release Mucus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Maximum Strength Cold N Hot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Health Medicated Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Health Miconazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Dg Health Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dg Health Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Multi Symptom Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Naproxen Sodium Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Dg Health Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Dg Health Nasal Decongestant Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Nasal Four", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Dg Health Nicotine", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dg Health Night Time Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dg Health Night Time Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Omperazole", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dg Health Pain Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dg Health Pain Relief Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dg Health Pain Reliever",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dg Health Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Health Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Sinus Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Sinus Severe", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Dg Health Sleep Aid",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dg Health Sterile Redness Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dg Health Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Dg Health Triacting", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dg Health Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Health Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Honey Lemon Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dg Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dg Maximum Strength Redness Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dg Original Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dg Poly Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dgh Advanced Formula Scar Skin Protectant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dgh Antacid Extra Strength 750 Berry, 96Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dgh Antacid Extra Strength 750 Wintergreen, 96Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dgh Antacid Regular Strength 500 (Fruit Assorted), 150Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dgh Antacid Ultra Strength 1000 Berry Assorted 72Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dgh Antacid Ultra Strength 1000 Fruit Assorted 72Ct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dgp High Performance Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dhc Acne Spot Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dhc Brightening Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dhc Salicylic Acne Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dhea",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dhea Forte", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dhivy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dhs Tar", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dhs Tar Gel", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dhs Zinc", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabase", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabecare Grisi Diabetics", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabecare Grisi Diabetics Foot Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diabek-100",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Buccal" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Diabenex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabenex Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabet Aid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetes Out", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetes Type Ii", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetic Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic Antifungal Professional", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic First Aid Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic First Aid Gel Professional", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic Foot Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic Foot Cream Professional", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic Itch Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic Itch Relief Cream Professional", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic Maximum Strength Siltussin Dm Das-Na", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetic Siltussin Das-Na", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetic Siltussin Dm Das-Na", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetic Tussin Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetic Tussin Dm Maximum Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetic Tussin Expectorant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetic Tussin Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diabetic Wound Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetic Wound Gel Professional", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetics Dry Skin Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetics Foot Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diabetics Intensive Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diacomit",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dial Antibacterial Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete 2In1 Fhw Manuka Honey", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete 2In1 Fhw Mint And Shea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete 2In1 Fhw Pearl Essence", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete 2In1 Fhw Rose Oil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Aloe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Antibacterial Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Antibacterial Foaming Hand Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Antibacterial Hand", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Antibacterial Liquid Hand", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Bw Apple Blossom", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Bw Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Cg Fhw Aloe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Cg Fhw Fragrance Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Cg Fhw Grapefruit", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Fhw Citrus Sunburst", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Fhw Coconut Water", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Fhw Fresh Lavendar", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Fhw Power Berries", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Fhw Silk And Seaberry", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Fhw Soothing White Tea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Fresh Pear Fhw", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Gold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Lhs Clean And Gentle", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Lhs Lavender And Jasmine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Lhs Lemon And Sage", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Lhs Pomegranate And Tangerine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Lhs Sweet Watermelon", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Limited Edition Fhw Fireside Crackle", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Limited Edition Fhw Ice Crystals", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Limited Edition Fhw Midnight Toast", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Spring Water", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete Spring Water Fhw", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dial Complete White Tea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dialyvite", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dialyvite 3000", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dialyvite 5000", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dialyvite Supreme D", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dialyvite With Zinc", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diamond Luminous Uv Defense Light Tinted Fluid Sunscreen Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dianeal Low Calcium With Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intraperitoneal" }] }] },
    { Medication: "Dianeal Pd-2 With Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intraperitoneal" }] }] },
    {
        Medication: "Diaper Cream",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Diaper Rash",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Diaper Rash Cream",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Diaper Rash Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diaperderm Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diaperene Adult", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diaperene Children", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diaperene Maximum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diapia Adl Ever-Cure Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diapia Adl Evercure Ampoule Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diapia Adl Evercurecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diapia Adl Turmeric Pearl Foaming Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diapia Cica Relief Pore Pad", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diapia Collagen Goat Milk Ampoule Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diapia Glow D Cica Wrapping Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diapia Ha Aqua Shining Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diaprex", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diaralia",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Diarrhea",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diarrhea Colic", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diarrhea Dyspepsia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diarrhea Relief", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diarrhea Vomiting", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diascreen 12-Panel Medicated Collection System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Diastat", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Diathesis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diathesis V 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diazepam",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diazepam Intensol", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diazoxide", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dibasic Sodium Phosphate, Monobasic Potassium Phosphate And Monobasic Sodium Phosphate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dibenzyline", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dibromm Cold And Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dibromm Cold And Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dibromm Cold And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dibucaine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dibucaine Topical Anesthetic 1% Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diclegis", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diclocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diclofenac",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Diclofenac Epolamine",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "System", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Diclofenac Potassium",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Diclofenac Sodium",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }, { Route: "Topical" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }, { Route: "Transdermal" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diclofenac Sodium 1.5%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diclofenac Sodium And Misoprostol",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diclofenac Sodium D/R", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diclofenac Sodium Delayed Release", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diclofenac Sodium Misoprostol", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diclofenac Sodium Topical Gel 1%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diclofenac Sodium Topical Gel, 1%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diclofenac Sodium Topical Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diclofenac Sodium/Misoprostol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dicloheal-60", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Diclona Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dicloprep-100", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Diclotrex", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diclovix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Diclovix Cm", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Diclovix M", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dicloxacillin Sodium", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dicyclomine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dicyclomine Hcl", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Dicyclomine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Die Da Wan Hua Pain Relieving", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diencephalinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diet Power", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diethylpropion Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diethylpropion Hydrochloride Er", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dieux Awakening Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Differin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Differin Acne Clearing Body", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Differin Acne Clearing Body Scrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Differin Acne Clearing Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Differin Daily Deep Cleanser With Bpo", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Differin Oil Absorbing Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Difficulty Breathing Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dificid",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Diflorasone Diacetate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Diflorasone Diacetate Oint", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diflucan",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Diflunisal",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Difluprednate", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Difluprednate Ophthalmic", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Digcare Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Digest", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Digestion Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Digestive Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Digestive Enzyme Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Digestive Enzyme Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Digestive Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Digestive Stimulator Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Digestivease", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Digiclean", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Digiclean E", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Digifab", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Digisan",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Digisan E", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Digitalinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Digitalis Pupurea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Digitalis Purpurea",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Digitalis Purpurea Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Digitek", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Digox", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Digoxin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Parenteral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Dihydroergotamine Mesylate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Dihydroergotamine Mesylate Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Dilantin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dilantin Infatabs", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dilantin-125", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dilaudid",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dilotab Ii", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diltiazem Hci",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Diltiazem Hcl Cd", DoseTypes: [{ DoseType: "Capsule, Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diltiazem Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Coated, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diltiazem Hydrochloride Extended Release", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diluent",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dimaphen Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dimenhydrinate",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Dimetapp",
        DoseTypes: [
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dimetapp Cold And Cough And Dimetapp Nighttime Cold And Congestion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dimetapp Cold And Cough And Nighttime Cold And Congestion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Dimethicone",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Dimethyl Fumarate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Dimyoor Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dinosaur", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Diolpure Lidocaine Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diolpure Menthol Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dior Prestige Le Nectar De Teint Exceptional Revitalizing Foundation Satin Radiance With Sunscreen Broad Spectrum Spf20 Tints 010", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dior Prestige Le Nectar De Teint Exceptional Revitalizing Foundation Satin Radiance With Sunscreen Broad Spectrum Spf20 Tints 020", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Ever Wear Extreme Perfection Hold Makeup Base With Sunscreen Spf20 001", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 010", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 020", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 021", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 022", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 023", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 030", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 031", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 032", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 033", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 034", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 035", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 040", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 050", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 060", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 070", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 Tints 014", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 Tints 015", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 Tints 024", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 Tints 025", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 Tints 041", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 Tints 045", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Forever Perfect Makeup Everlasting Wear Pore-Refining Effect With Sunscreen Broad Spectrum Spf 35 Tints 051", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diorskin Nude Air Nude Healthy Glow Ultra-Fluid Serum Foundation With Sunscreen Broad Spectrum Spf25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dioscorea Batata 16 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dioscorea Batata Special Order", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dioscorea Villosa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diovan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diovan Hct", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dipentum", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diphenhist", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diphenhydramine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diphenhydramine Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Diphenhydramine Hcl",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diphenhydramine Hcl 12.5Mg Antihistamine", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diphenhydramine Hcl 25 Mg",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diphenhydramine Hcl 25Mg", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diphenhydramine Hcl And Ibuprofen", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diphenhydramine Hcl And Zinc Acetate",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Diphenhydramine Hcl Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diphenhydramine Hcl, Zinc Acetate", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diphenhydramine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diphenhydramine Hydrochloride 25Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diphenhydramine Hydrochloride 50Mg", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diphenhydramine Hydrochloride And Zinc Acetate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Diphenoxylate Hydrochloride And Atropine Sulfate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diphtheria And Tetanus Toxoids Adsorbed", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Diphtherinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diprivan", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Diprolene", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dipyridamole",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Direct Naturals Fungus Clear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Direct Safety Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Direct Safety Aspirin Free", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Direct Safety Extra Strength Aspirin Free", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Direct Safety Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Disci Viscum Comp.Cum Stanno Dks Special Order", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Discount Drug Mart",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    {
        Medication: "Discount Drug Mart Anti-Itch",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Discount Drug Mart Ear Relief Ear Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Discount Drug Mart Extra Strength Itch Stopping", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Discount Drug Mart Food Fair Earwax Removal Kit", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Discount Drug Mart Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Discount Drug Mart Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Discount Drug Mart Nausea Control", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Discount Drug Mart Pain Relief Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Discount Drug Mart Prebiotic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Discount Drug Mart Regular Strength Itch Stopping", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Discount Drug Mart Value Vapor Steam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Discount Drug Mart Vitamin E Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Discount Drugmart Allergy Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Discount Drugmart Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Discount Drugmart Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Discount Drugmart Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Discount Drugmart Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Discount Drugmart Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Discount Lice Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dishwashing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dishworks", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disinfect And Shield Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disinfect Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Disinfectant",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Disinfectant Wet Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Disinfectant Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Disinfectant Wipes (75% Alcohol)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disinfectant Wipes For Hand Sanitizing", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disinfectant With Pure Silver", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Disinfectantspray",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Disinfecting Wet Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Disinfecting Wet Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Disinfecting Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Extracorporeal" }, { Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Disinfection Wash Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disinfection-Tablets", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disinpro Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Diskets", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Disney 4 Pack", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Bambi Moisturizing Hand Sanitizer Melon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Dalmatians Moisturizing Hand Sanitizer, Vanilla", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Dumbo Moisturizing Hand Sanitizer Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Frozen Ii Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Frozen Ii Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Marie Moisturizing Hand Sanitizer, Strawberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Mickey And Friends Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Mickey Mouse Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Minnie Mouse Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Pixar Lightyear Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Princess Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Princess Blueberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Princess Cherry Blossom Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Princess Cherry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Princess Floral Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Princess Grape Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Princess Watermelon Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Stitch Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Villains Cruella Hand Sanitizer Black Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Villains Evil Queen Hand Sanitizer, Poison Apple", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Villains Maleficent Hand Sanitizer, Dark Fruits", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Disney Villains Ursula Hand Sanitizer, Deep Aqua", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Disopyramide Phosphate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dissociative Disorders Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Distichlis Spicata Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Distress Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Distress-Mood-Sadness",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Disulfiram", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ditropan", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diuretic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Diurex",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Diurex Water Pills", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diuril", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Diva Protect Antibacterial Hand Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Divalproex Sodium",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Divigel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Divine Fragrance Free Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dizzi-V",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dizziness", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dizzy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dm Cough And Congestion Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dm Max", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dmae", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dmr Premium Cleansing", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dmt Suik", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Dna", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dna Insulin Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dna Rna", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dna Rna Spectrum", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dnature Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Do Naturals Spf 32", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doans", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dobutamine",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dobutamine Hydrochloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dobutamine Hydrochloride In Dextrose", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dobutamine In Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Doc Hygiene", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doc Hygiene Extra Strength Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Docetaxel",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Docetaxel Anhydrous", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dock, Yellow Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Dock-Sorrel Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Docosanol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Docs Skincare Natural Sunscreen Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctor Babor Protect Rx Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctor Butler Hemorrhoid And Fissure", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctor Butlers Numb It All Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctor El Black White Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Doctor Guardstick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctor Hoys Natural Arnica Boost", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctor Hoys Natural Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctor Os Diaper Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctor Q Safer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctorcos Snow White Water Glow Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctorpnb Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctors Choice", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doctors Choice Glycerin Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Doctors Kline And Green Board Certified Dermatologist Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctors Kline And Green Board Certified Dermatologist Hand Sanitizer Alcohol Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctors Kline And Green Board Certified Dermatologist Hand Sanitizer Antiseptic", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctors Touch Erase The Pain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctors Touch Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doctrust Doublex Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Doctrust K", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Doctrust One S Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Docu Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusate Calcium", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusate Calcium 240 Mg Sodium Free", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusate Mini Enema", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Docusate Sennosides", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Docusate Sodium",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Docusate Sodium - Stool Softener Laxative", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusate Sodium 100Mg", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusate Sodium 100Mg Two-Tone", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusate Sodium 50 Mg", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusate Sodium Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusate Sodium With Sennosides", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Docusol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Docusol Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Docusol Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Docuzen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dodex Injectable", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Dofetilide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dog Epithelia", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Dog Fennel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Dog Hair", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Dojolvi", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dok",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dolex Children", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dolex Fem", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dolex Flex", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dolex Forte", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dolfrost Cool And Soothing Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dolichos Pruriens",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dolishale", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dollar General",
        DoseTypes: [
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Dollar General Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dollar General Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dollar General Cold-Hot Medicated Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dollar General Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dollar General Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Dollar General Headache Powders", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dollar General Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dollar General Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dollar General Medicated Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dollar General Pain Relieving Patch With Lidocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dollar General Studio Selection Sun Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dollar General Studio Selection Sun Sunscreen Broad Spectrum Spf 85 With Aloe", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dollar General Studio Selection Sun Tanning Dry Oil Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dollar General Vapor Steam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Dolo- Neurobion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dolodent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Doloear", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Dologen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dologen 325", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dologesic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dologesic Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dolomax Cold Cough", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dolophine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dom Organic Lavender Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dom Organic Rosemary Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Domeboro",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Domestic Animals Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dometuss", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dometuss-Da", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dometuss-Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dometuss-Dmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dometuss-G", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Don Antibacterial Blue Floral Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Don Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Don Luxury Foam Antibacterial Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Don Sensi-Derm Plus Antibacterial Ltn So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done 70 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done 70 Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done 70 Isopropyl Alcohol First Aid Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done Hand Antibacterial Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done Hand Antibacterial Fruity Fusion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done Hand Antibacterial Green Apple", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done Hand Antibacterial Sweet Citric", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done Hand Sanitizer Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done Hand Sanitizer Isopropyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done Hand Sanitizer- Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Done Refreshing Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Donepezil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Donepezil Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Donepezil Hyrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dongil Hand Cleaner Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dongwoodang Good Bone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Donna Karan Cashmere Mist Deodorant Anti-Perspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Donnatal",
        DoseTypes: [
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Doo Gro Anti-Dandruff Vitalizer",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Doordash Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dop Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dop Hydrogen Peroxide 3%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dop Isopropyl Rubbing Alcohol 70%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dopamine",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dopamine Hci", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dopamine Hci In 5% Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Dopamine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Dopamine Hydrochloride And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dopamine Hydrochloride In Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dopamine Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dopram", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Doptelet", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doral", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dorama Dry Aid Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dorama-Neo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dorflex Icy Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dormin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doryx", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doryx Mpc", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dorzolamide And Timolol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dorzolamide Hcl", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Dorzolamide Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    {
        Medication: "Dorzolamide Hydrochloride And Timolol Maleate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Dorzolamide Hydrochloride And Timolol Maleate Ophthalmic Solution", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dorzolamide Hydrochloride And Timolol Maleate Preservative Free", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dorzolamide Hydrochloride Ophthalmic Solution", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dorzolamide Hydrochloride Timolol Maleate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dorzolamide Hydrochloride Timolol Maletae", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dorzolamide Hydrochloride-Timolol Maleate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dosoon Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dotarem", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Doterra Sun",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dotremin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dotti", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Double Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Duty Beauty Shape Tape Cloud Coverage Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Fight", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Double Use Compact Spf 15 Gold Deluxe Edition Claire 1-2-3", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Use Compact Spf 15 Gold Deluxe Edition Claire 4", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Use Compact Spf 15 Gold Deluxe Edition Medium 5", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Use Compact Spf 15 Gold Deluxe Edition Medium 6", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Use Compact Spf 15 Gold Deluxe Edition Medium 7", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Wear Cushion Bb All Day Wear Compact Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Wear Maximum Cover Camouflage Makeup For Face And Body Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double Wear Nude Water Fresh Makeup Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Double White", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Double X Concentrated", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Double-Duty Face Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doubleantibioticointment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Doubledex Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Intravenous; Topical" }] }] },
    { Medication: "Douleurin Ice Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Douleurin Topical Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Douxie Alcohol Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dovato", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dove",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dove Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dove Dermaseries",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dove Men Clinical Protection Clean Comfort", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dove Men Plus Care",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Dover Addaprin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dover Aminophen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dover Sudanyl Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dovonex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Down Toothpaste,Gingersnap", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Doxapram Hydrochloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Doxazosin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doxazosin Mesylate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doxepin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doxepin Hcl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Doxepin Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Doxercalciferol",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Doxil", DoseTypes: [{ DoseType: "Injection, Suspension, Liposomal", Routes: [{ Route: "Intravenous" }, { Route: "Intravitreal" }] }] },
    {
        Medication: "Doxorubicin Hydrochloride",
        DoseTypes: [
            { DoseType: "Injectable, Liposomal", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Suspension, Liposomal", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Doxorubicin Hydrochloride Liposome", DoseTypes: [{ DoseType: "Injection, Suspension, Liposomal", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Doxy 100", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Doxy 100(Tm) Doxycycline", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Doxycyclate Hyclate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Doxycycline",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Doxycycline Hyclate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Doxycycline Monohydrate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doxylamine Succinate And Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Doxylamine Succinate And Pyridoxine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dpheal Sul 16 Cool", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dpheal Sul 16 Hot", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Berrys", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dr Bronners",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dr C Tuna Sun Protector Lip Balm Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Canuso Fungal Nail Eraser", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Clean Perfect Gel", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Cocoa", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dr Dennis Gross All-Over Blemish", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Dennis Gross Correct And Perfect", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Dennis Gross Trifix Acne Clearing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr H Stabilizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Hos Pain Aid", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Hos Pain Relief Maximum Strength", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Hydrocell Phytoncide Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dr Joe Lab Arthritis Extra Strength",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dr Joe Lab Pms Menstrual Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Joe Lab Pms Menstrual Pain Relief Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lift Advanced Antibacterial Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dr Lift Antibacterial",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dr Lift Antibacterial Body Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lift Antibacterial Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lift Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lift Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lift Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lift Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lift Hand Sanitizer Tea Tree", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lift Unscented Antibacterial Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid 70% Ethanol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid 70% Ethanol Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid 70% Ethanol Hand Sanitizer Gel With Eucalyptus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid 70% Ethanol Hand Sanitizer Gel With Rose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid 70% Ethanol Hand Sanitizer Liquid With Eucalyptus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid 70% Ethanol Hand Sanitizer Liquid With Rose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid 70% Ipa Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid 91% Ipa Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Lucid Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Mercola Broad Spectrum Spf15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Paines Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Pure Antiseptic Liquid Hand Apple And Cucumber Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Pure Antiseptic Liquid Hand Berries Mix Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Pure Antiseptic Liquid Hand Neutral Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Safe Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Saymans Wonder Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Sheffield Athletes Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Sheffield Muscle Rub Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Sweat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Tans Arthritis Formula 3 Day Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Tans Pain Releiving 3 Day Herabl Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Throwers Oily Skin Facial Astringent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Throwers Oily Skin Facial Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Tichenors", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Tichenors With Extra Whitening And Tartar Control", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr Toc Perfect Clean Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Toc Perfect Clean Pocket Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Toc Perfect Cleanspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Varogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Virur Safe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Virursolution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Weils Mega-Defense Advanced Daily Uv Defender Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr Wises Formula Ii Intestinal Calm", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr Wolfes Cool Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Alpha Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Alpha Hand Cleaner", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Andrew Weil For Origins", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Barrier Intensive Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Blue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Brandt Sun Shield Spf 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Butler Hemorrhoid And Perineal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Butler Hemorrhoid Treatment", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Butler Soothing Incontinence", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C Tuna Sunscreen 30 Spf Broad Spectrum Face And Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Acne Blemish Control Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dr. C. Tuna Acne Clear Complexion Cleanser",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Dr. C. Tuna Acne Pore Purifying Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Antibacterial Liquid Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Antibacterial Waterless Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Lumi Radiance Brightening", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Lumi Radiance Brightening Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Lumi Radiance Brightening Cream 22 Spf", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dr. C. Tuna Resurface Essential Day Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Resurface Essential Day Cream Spf 30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dr. C. Tuna Sun 6 Spf Tan And Care Carrot", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Sun Bronzing Oil Spf 6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Sun Face And Body Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Sun Protector Lip Balm Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Sunscreen 50 Spf Uva Uvb Protection Face And Body Sun", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. C. Tuna Sunscreen Spf30 Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Canuso Foot Repair Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Carrasco Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Cellmo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Cocoa Daytime Cough And Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Cocoa Nighttime Cough And Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Daddys Gumtoothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Daddys Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Daddys Junior Toothpaste(Green Grape)", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Daddys Kids Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Daddys Kids Toothpaste(Raspberry Flavor)", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Daddys Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Dean", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Dennis Gross Skincare - Lightweight Wrinkle Defense Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Dris Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Ds Super 7 Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Du-Mores Viral Defense Nano Gold Anti-Aging Skin Disinfectant Protectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Eslee Physical Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Forest Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Freds Miracle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Guard Pack Solid", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Guard Special Car Edition", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Guard Special Car Edition Refill Stick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Guard Stick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Harveys Herbal Motion Pain Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Healthy Choice Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Hendel Appetite Control", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Hendel Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Hendel Back Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Hendel Cold And Sore Throat Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Hess Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Iaso Ac Booster", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. James Gel (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Joe Lab Acne Control Clarifying Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Juans Santo Remedio Para La Acidez", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dr. Libeaute Hand Cleanser",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Dr. Libeaute Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dr. Lift Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Lift Isopropyl Alcohol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Lightening Ultra-Potent Facial", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Mercola Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Mercola Sunscreen Broad Spectrum Spf-15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Mercola Sunscreen Broad Spectrum Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Mercola Sunscreen Spf-50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Nenninger Cold And Sinus Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Nona", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Numb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Oracle Oh My Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Palm Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Pelo Scalp Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Pelo Scalp Tonic Sheet", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Pelo Scalp-Shampoo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Perrys Day Thyme Spf 20 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Piercing Aftercare", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Protector", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Pure Antiseptic Liquid Hand Soap, Coconut And Honey Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Rainer S Vaginal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Richs Numbing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Safe Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Sayman Salve", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Sayman Wonder Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Scalp Active", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Scalp Spicule Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Scholls Duragel Callus Removers", DoseTypes: [{ DoseType: "Disc", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Scholls Instant Cool Athletes Foot Treatment With Menthol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Scholls Instant Cool Athletes Foot Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Sheffield Anti Itch Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Sheffield Barrier Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Sheffield Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Sheffield Fluoride Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Sheffield Hemorrhoid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Dr. Sheffield Hydrocortisone Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Sheffield Psoriasis Medicated Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Sheffield Sensitive Care", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Sheffield Vapor Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Talbots", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Talbots Cough Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Talbots Daily Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Talbots Gas Colic Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Talbots Infant Daily Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Talbots Mucus Cold Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Talbots Oral Comfort Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Dr. Talbots Pain Fever Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Talbots Pain Inflammation Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Talbots Tummy Ache Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Talbots Vital 12", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr. Throwers Spf 30 Face And Body Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Whitiss (8.3%)", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Wolfes Warm Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Yerma Hand Sanitizerspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Yerma Instant Hand Clean Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Yerma Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Yerma Moisturizing Sanitizer Towelettes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Yerma Sanitizer Wipes Cool Scented With Aloe Vera", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Yerma Sanitizer Wipes Flower Scented With Aloe Vera", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Yerma Sanitizer Wipes Fresh Scented With Aloe Vera", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr. Zenni Childrens Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Ggoggoma Toothpasteraspberry Flavor", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Ggoggoma Toothpastevanilla Flavor", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Ggoma Toothpasteraspberry Flavor", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Ggoma Toothpastevanilla Flavor", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Gum Project Propoli Tooth For Gum Disease", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Junior Toothpaste(Lime Flavor)", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Junior Toothpaste(Mint Flavor)", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Kids Tooth (Baby)", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Natural Orthodontictoothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr. Zenni Natural Whitetoothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.Althea Double Serum Balm Foundation 23", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Better Homes Hand Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Better Homes Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Daddys Junior Toothpaste(Mint Flavor)", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.Day Hand Clean Gel 100Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Day Hand Clean Gel 500Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Day S Hand Clean Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Denese Spf 30 Defense Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Devirus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different 131 Moisturizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Ceq Anti Oxidant Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Cica Metal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Cica Metal Calming Pad", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Scaling Toner For Oily Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Vitaacnal Tx Capsule Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Vitaacnal Tx Night", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Vitalift A", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Vitalift A Eye And Neck", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Different Vitalift A Forte", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.El Clair Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.El Doux Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.El Gum Care Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.El Ortho Care Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.El Tooth White Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.Forhair Folligen", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Forhair Folligen Volume Treatment", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Homes Hand Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Black Label Detox Beauty Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Cicapair Tiger Grass Camo Drops", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Cicapair Tiger Grass Color Correcting Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Dis-A-Pore Beauty Balm 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Dis-A-Pore Beauty Balm 02", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Every Sun Day Mineral Sun Screen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Every Sun Day Sun Fluid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Premium Beauty Balm 01 Light Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Premium Beauty Balm 02 Medium Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Premium Beauty Balm 03 Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Premium Beauty Balm Deep Tan-Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Premium Beauty Balm Fair-Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Premium Beauty Balm Light Medium-Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jart Premium Beauty Balm Medium-Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jecori Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Jmeelab Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Libeaute Multi-Purpose Cleansing Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Libeaute Thermal Spring Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Lord Green Propol Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr.Lord Propol Whitening Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr.Noah Maruftoothpaste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr.Noah Marutoothpaste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr.Noah Solidtoothpaste", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dr.Pauhls Natural Tone Up Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Plinus Fillincell Multi", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Plinus Zero Trouble", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.S Clean Advanced Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.S Clean Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.S Clean Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Serse Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Tengle Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Toc Perfect Clean Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dr.Whitiss 10%", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.Whitiss 15%", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.Whitiss 20%", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr.Whitiss 35%", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dr527", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dragon Pain Numbing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dragon Pain Numbing Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dragon Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Dragon With Arnical Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drainage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drainage-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Drainer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Drainpar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dramaholic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dramamine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dramamine - N", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dramamine For Kids", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dramamine Less Drowsy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dramamine Orange", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dramamine Original Formula", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drawings School Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drax Exametazime", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Draximage Dtpa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous; Respiratory (Inhalation)" }] }] },
    { Medication: "Draximage Maa", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Dream Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drechslera Spicifera", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Driminate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drinites", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drink Ease", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drisdol", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dristan", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Dristan 12 Hr", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Drizalma Sprinkle", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drkids Children Multi Symptom Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drkids Childrens Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drkids Childrens Multi Symptom Cold Cough And Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drkids Childrens Muti Symptom Cold Flu And Sore Throat Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dromelate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Dronabinol", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drop20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Droperidol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Drosera",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Drosera Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drosera Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Drosera Plex",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Drosera Rotundifolia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drosera Rotundifolia Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drospirenone And Ethinyl Estradiol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Drospirenone, Ethinyl Estradiol And Levomefolate Calcium And Levomefolate Calcium", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Drospirenone/Ethinyl Estradiol/Levomefolate Calcium And Levomefolate Calcium", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Droxia", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Droxidopa", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drpurx Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drs. Acne Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drs. Antibiotic Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drs. Pharmacy Hemorrhoidal Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drs. Pharmacy Scar Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drs. Pharmacy Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drs. Pharmacy Triple Antibiotic Plus", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drug Mart Effervescent Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drug Mart Stool Softener Plus Stimulant Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drug Mart Vegetable Laxative Plus Stool Softener", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Druide Biolove For Diaper Rash", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Druide Biolove Kids Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drunk Elephant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Drx Choice", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drx Choice Antacid Calcium", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Drx Choice Stomach Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dry Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dry Cough Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dry Cough Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dry Cough Skin Eruptions", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dry Eczema Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dry Eye Formula Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dry Eye Nighttime", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Dry Eye Relief",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Dry Eye Relief Trial Size 15X", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dry Eye Test", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dry Idea Ad Antiperspirant Roll-On Powder Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Idea Advanceddry Antiperspirant Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Idea Antiperspirant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Idea Stress Shield Inspire Antiperspirant Deodorant Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry It Out Acne Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Mouth", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dry Scalp", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Scalp Care", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Scalp Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Skin Care", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Skin Care Vegan", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Skin Relief Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Skin Salve", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dry Socket", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Dry Sore Throat", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dryness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ds Hancle Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacteria (Chamomile Lemon)", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacterial Hand Coconut Lemongrass", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacterial Hand Crisp Clean", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacterial Hand Fresh Citrus", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacterial Hand Lavender", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacterial Hand Linen Scent", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Antibacterial Hand Orange", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsc Hand Sanitizer With 70 Alcohol", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dsuvia", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Dt Collagen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dt Exfoliating Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duaklir Pressair", DoseTypes: [{ DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Dual Action Cherry Cough Suppressant Oral Anesthetic", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dual Action Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dual Action Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dual Action Honey Lemon Cough Suppressant Oral Anesthetic", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dual Effects Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dual-Shave 2 In 1", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duavee", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Duck Feathers", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Duco", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dudas Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dude Perfect Fluoride Toothpastes And Spinbrush Combo Pack", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Duet Dha", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Duet Dha Balanced", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Duetact", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Duexis", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dugel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dukal Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dukal Sterile Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dukal Sterile Alcohol Swabstick", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dukal Sting Relief Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duke Cannon Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duke Cannon Cooling Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duke Cannon Fresh Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duke Cannon Standard Issue Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duke Cooling Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Dulcamara",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dulcamara Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Chewy Bites Assorted Fruits", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Chewy Fruit Bites Cherry Berry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Dulcolax Pink Stimulant Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Pink Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Simulant Laxative", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Soft Chews Black Cherry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Soft Chews Mixed Berry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Soft Chews Watermelon", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Stimulant Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulcolax Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dulera", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Duloxetine",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Duloxetine Hcl",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Duloxetine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Duobrii", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duocare Clinical Oral Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Duodeninum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Duodote", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Duopa", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Enteral" }] }] },
    { Medication: "Dupixent", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Duraclon", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural" }] }] },
    { Medication: "Duradry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duradry Am", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duradry Am Barca", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duradry Am Clear Sky", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duradry Am End Game", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duradry Am Limitless", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duradry Hands And Feet Antiperspirant Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duradry Pm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Duraflu", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Duragesic", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Duramorph", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Epidural; Intrathecal; Intravenous" }] }] },
    { Medication: "Durapren 0.5%", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Intracaudal; Perineural" }] }] },
    { Medication: "Durapren 0.75%", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Retrobulbar" }] }] },
    { Medication: "Durashield Fragrance Free Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Durashield Original Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Durezol", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Durokleen Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Durysta", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Intracameral" }] }] },
    { Medication: "Dusel Cool Arthritis Dead Sea Salt Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dusel Psoriasis Dead Sea Salt Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dusel Warm Arthritis Dead Sea Salt Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dust Mix Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Dust-Mold-Dander",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Dutasteride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dutasteride And Tamsulosin Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dutoprol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dv Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dx1 Oragenomic Medicated Dna Collection Screen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Dx2 Oragenomic Medicated Dna Collection Screen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Dyanavel Xr",
        DoseTypes: [
            { DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dyclopro", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dye Free Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Childrens Acetaminophen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Childrens Allergy Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Childrens Cetirizine Hydrocholride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Childrens Diphenhydramine Hydrochloride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Dye Free Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Dye Free Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Pain Reliever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Sleep Aid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Wal Dryl Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Wal Fex", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye Free Wal Sleep Z", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye-Free Adult Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye-Free Adult Allergy Liquid Medication", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye-Free Allergy", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye-Free Aspirin 81", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye-Free Childrens Loratadine", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye-Free Ibuprofen", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye-Free Ibuprofen 200", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dye-Free Pain Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dymista", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Dyna-Hex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dyna-Hex 2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dyna-Hex 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamic Brain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dynamic Broad Spectrum Sunscreen Spf 55", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamic Defense Mineral Shield Broad Spectrum Spf30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamic Drying", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamic Eyewash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dynamic Eyewash With Eyecup", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Dynamic Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamic Resurfacing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamic Sanitizing Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamic Sunscreen Broad Spectrum Spf 55", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamic Sunscreen Broad Spectrum Spf 55 Sun Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamiclear", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamics Hand Sanitizing Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynamo Delay", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynarex Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynarex Antifungal Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynarex Green", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynarex Hydrogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynarex Povidone Iodine Prep Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynarex Povidone Iodine Surgical Scrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynarex Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynarub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynashield", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dynashield With Dimethicone", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Dyrax-Q Tab", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dyrenium", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dysbio Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dyscrasite 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dysfunctional Bleeding Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dysmenorrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dysmenorrhea Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dysmenorrhea Fatigue", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dysmenorrhea Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dyspepsia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dyspepsia 3", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dyspepsia Fatigue", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dyspepsia Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Dysport", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Dyural 40 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Intravenous; Soft Tissue; Subcutaneous; Topical" }] }] },
    { Medication: "Dyural 80 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Intravenous; Soft Tissue; Subcutaneous; Topical" }] }] },
    { Medication: "Dyural 80-Lm", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Intravenous; Soft Tissue; Subcutaneous; Topical" }] }] },
    { Medication: "Dyural L Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Infiltration; Intra-Articular; Intralesional; Intramuscular; Soft Tissue; Topical" }] }] },
    { Medication: "Dyural Lm Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Soft Tissue; Topical" }] }] },
    { Medication: "Dzeus Climax Control Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "E Mei Shan Medicated", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "E-2 Food Processing Foam Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "E-Coli", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "E-Ko Skin Care", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "E-Z-Disk", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "E-Z-Gas", DoseTypes: [{ DoseType: "Granule, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "E-Z-Hd", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "E-Z-Paque",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "E-Z-Paste", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "E.E.S",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "E.L.F. Holy Hydration Face Cream Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "E.O.L.",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "E08", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "E2 Antibacterial Foaming Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "E2 Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "E2 Sanitizing Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "E3 Alcohol Free Foaming Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "E3 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ea Eight Hour Skin Protectant", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ea Eight Hr Lip Sheer Tints", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ea Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eaae Disinfectant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eaae Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Each And Every Plant Based Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Eagle",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Eagle Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eagle Green Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eagle Medicated Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eagle Wipes Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eagle Wipes Hand Sanitizing Wipes With Aloe Vera", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eai-Jr286, Inc Vertra Sensitive Spf 30 Sunscreen Face Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eanclean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ear Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ear Care Earache Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Discomfort", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ear Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Drops (Carbamide Peroxide 6.5%)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Drops For Swimmers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Drying Agent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ear Pain Md", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ear Pain Md For Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ear Ring", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ear Ringing",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ear Ringing Remedy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Rx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ear Wax Build-Up", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ear Wax Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Wax Removal Aid Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Wax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ear Wax Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear Wax Rmvl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ear/Eye/Sinus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Earache Drops",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] },
        ],
    },
    {
        Medication: "Earache Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Earache Relief Pm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Earache Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Earache Toothache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Earclearrx", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Earl Clean Solutions Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Early Bird Wintergreen And Peppermint", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Early Mommy Baby Bum Butta", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Earth Baby Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Baby Diaper Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Baby Germ Buster Surface Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Baby Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Best Diaper Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Edition Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Edition Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Mama Baby Face Mineral Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Mama Baby Mineral Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Mama Eczema Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Mama Kids Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Mama Kids Uber-Sensitive Mineral Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Mama Lady Face Mineral Sunscreen - Light/Medium Tint Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Mama Lady Face Mineral Sunscreen - Medium/Dark Tint Broad Spectrum", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth Repair Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth To Skin Antiseptic Hand Sanitizer 2.5 Oz", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth To Skin Blueberry Hand Sanitizer 2.0 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth To Skin Coconut Aloe Hand Sanitizer 2.0 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth To Skin Lemon Basil Hand Sanitizer 2.0 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth To Skin Scent Free Hand Sanitizer 2.0 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earth To Skin Watermelon Hand Sanitizer 2.0 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earths Care Acne Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earths Care Acne Treatment Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Earths Care Anti-Itch",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Earths Care Arthritis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earths Care Eczema", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earths Care Hemorrhoid Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earths Care Muscle And Joint Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earths Care Pain Relieving", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Earwax Removal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    {
        Medication: "Earwax Removal Kit",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Ease Your Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easter Lily", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eastern 10 Tree Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Eastern 6 Tree Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Eastern 7 Tree Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Eastern 8 Tree Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Eastern Cottonwood Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Eastern Oak Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Eastern Sycamore Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Eastern Trees", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Eastern White Pine Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Easy And Safe Hand Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Care Bzk Antiseptic Towelette", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Care First Aid Afterburn", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Care First Aid Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Care First Aid Antiseptic Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Care First Aid Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Care First Aid Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Clean Intensive Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Joint", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Klean 70 Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Klean Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easy Wonderful 75 Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Dw Egf Derma Needle 3000 Shot", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Dw Egf Derma Oneshot Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Eg Sun Ultra Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Ex Fresh Mild Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Ex Mineral Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Ex Renewal Moisture", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Ex Repair Control Active Soothing Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Ex Repair Control Renewal Moisture", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easydew Ex Repair Control Repair Control Egf", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easylax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Easystick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Easystick Dr.K Shield", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eau Thermale Avene Antirougeurs Day Redness-Relief Soothing Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eau Thermale Avene Solaire Uv Mineral Multi-Defense Sunscreen Fluid Spf 50 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eau Thermale Avene Solaire Uv Mineral Multi-Dense Tinted Sunscreen Fluid Spf 50 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eb Epicurus Spf 50 The Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eb301Ap Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eb301Ct Bruise Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eb302 Arthritis Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eb306 Nasal Decongestant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Eb5 Age Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eb5 Ultra Light Face Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ebanga", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ebin Dr. Hand Plus Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ebin New York Hand Plus Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ebin New York Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ebsl-5", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ebss-5", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ebv Stat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ebv/Cfs Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ec Pure Instant Cream Hand Sanitizer Goddess", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ec Pure Instant Cream Hand Sanitizer Joy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ec Pure Instant Cream Hand Sanitizer Love", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ec Pure Instant Cream Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ec Pure Instant Cream Hand Sanitizer Zen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ec-Naprosyn", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ec-Naproxen", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ecco Antiseptic Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Echinacea", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Echinacea Ang Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Echinacea Angustifolia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Echinacea Apis Child Size", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Echinacea Argentum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Echinacea Calendula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Echinacea Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Echinacea E Rad. 3 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Echinacea Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Echinacea Purpurea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Echinacea Purpurea (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Echinacea Quartz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Echinacea Thuja",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Echinacea Thuja Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Echinasyr", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eclean Rubbing Alcohol Denatured Ethyl Alcohol 70 Solution", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eclipse Sheer Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eclipse Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eclipse Spf 50 Perfectint Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Chem Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Chem Isopropyl Alcohol 70", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Chem Skin Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Lips Sport Lip Balm Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Sanitizer Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Sanity Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco Sun Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eco-Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecocare 250", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecocare 270", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecocare 275", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecocare 280", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecocare 350", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecocare 360", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecodent Ultimate Essential Mouthcare", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ecolab",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ecolab Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ecolab Antiseptic",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ecolab Blood Clotting", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecolab Burn", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecolab Burn Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecolab Clean Force", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecolab Clear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecolab Eye Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ecolab Gel Hand Sanitizer Ls", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ecolab Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ecolab Hydrogen Peroxide", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ecolab Inc.",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ecolab Isopropyl Alcohol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecolab Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecolyptus Pain Relieving Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Econ Morning After", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Econavix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Econazole Nitrate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Econtra One-Step", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ecopure Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecoshield Skin Advanced Antimicrobial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecossential Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ecossential Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ecossential Hand Sanitizer 70% Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecossential Personal Ppe Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ecostick Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecotrin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ecotrin Regular Strength", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ecowipes Antiseptic Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecoworld Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ecoza", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczacalm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Eczema",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Eczema - Skin Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Eczema And Psoriasis Free", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Care", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Care Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Care Skin Protectant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Cream Therapeutic Itch Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Face And Body Salve", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Face And Body Wash", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Fast Healing Body Wash", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eczema Itch Relief Wash And Shampo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Eczema Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Eczema Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Relief Cream Body Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Relief Cream Professional", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Remedy", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Rescue", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Skin Eruptions", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eczema Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczema Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eczemaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eczemol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ed A-Hist",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ed A-Hist Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ed Apap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ed Bron Gp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ed Chlorped Jr", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ed-A-Hist Dm", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ed-A-Hist Pse", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ed-Spaz", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Edarbi", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Edarbyclor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Edecrin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Edema", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Edema - Fluid Retention", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Edemaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eden Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Edex", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intracavernous" }] }] },
    { Medication: "Edge", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Edluar", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Edu San",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Edurant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eenk Sanitizer Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eezy Pro", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eezy Pro Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eezy Pro Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eezy Pro Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eezy Pro Spf50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eezysun Aloe Vera After Sun", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eezysun Sunscreen Spf 50 With Aloe Vera, Marine Friendly", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Efavirenz",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Efavirenz, Emtricitabine And Tenofovir Disoproxil Fumarate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Efavirenz, Lamivudine And Tenofovir Disoproxil Fumarate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Effaclar", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Effer-K", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Effervescent Cold Relief - Orange", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Effervescent Hand Sanitizer", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Effervescent Orange Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Effexor",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Effient", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Efudex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Egaten", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Egf Fgf Dna Uv Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Egg Allergen Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Egg White", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Egg Yolk", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Egg, Whole", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    {
        Medication: "Egolan",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Egolan Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Egolan Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Egolan Wipes Hand Sanitizer", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Egrifta Sv", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Eha", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ehsp Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ehsp Hand Sanitizer Family Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ehsp Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eight Hour Lip Spf 15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eight Hour Night", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eight Hour Nourishing Lip Spf 20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eight Hour Pain Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eight Hour Skin Fragrance Free", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eight Saints Chase The Sun Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ekosafe 70%",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "El Piojito Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elaprase", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Elaps Corallinus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elas Gel", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elaskin Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elastic Relief Strip", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elberry Up", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elcys", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Electric Medicated Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Electrolyte Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Electrolyzed Oxidizing Water Disinfection Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Electrolyzed Water", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Electrolyzed-Oxidizing Water Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Electrum Numb Anesthetic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elelyso", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Elemar Patch", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Elementz", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elepsia Xr", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elepsia Xr 1000 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elepsia Xr 1500 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elestrin", DoseTypes: [{ DoseType: "Gel, Metered", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eletriptan", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eletriptan Hydrobromide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elevate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elevate 5% Minoxidil Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate 5% Minoxidil Hair Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate 5% Minoxidil Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate Beard Growth Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate Cbd Heating Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate Cooling Therapy Twist Up", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate Hair Growth Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate Hair Regrowth", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate Heating Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate Heating Therapy Twist Up", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevate Tf Foam Skin Care Advance Antibacterial Foam Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevated Ultimate Sun Defense Broadspectrum Spf 30 (Uva/Uvb) Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elevated Ultimate Tinted Sun Defense Broad Spectrum Spf 30 (Uva/Uvb) Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eleven By Venus Williams Ace The Day Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eleven By Venus Williams Game Set Match Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eleven On The Defense", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eleven Perfect Form Lip Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eleven Perfect Form Tinted Lip Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eleven Unrivaled Sun Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Acne Fighting Foundation Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Acne Fighting Spot Gel With Aloe Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Beige Acne Fighting Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Blemish Control Face Primer Clear", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Broad Spectrum Spf 20 Sunscreen Buff Bb Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Buff Acne Fighting Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Clear Blemish Control Face Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Flawless Finish Foundation Oil Free Spf 15 Sunscreen Light Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Oil Free Flawless Finish Spf 15 Sunscreen Foundation Buff", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Porcelain Acne Fighting Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elf Sand Acne Fighting Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elgoalin Hiseng", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elidel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eligard", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Elimishield Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elimishield Clinical Care Technology Alcohol-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elimishield Clinical Hand Santizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elimishield Home And Outdoor Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elimishield Hunt Core Body Foam For Hunters", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elimishield Sport Alcohol-Free Full Body Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elimishield Sport Body Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elimitone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elinest", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Eliquis",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Elite Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elitek", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Elixicure All Natural Lavender", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elixicure Lavender With Cbd", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elixicure Original With Cbd", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elixophyllin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elizabeth Arden, Great 8, Moisturizer Spf 35", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elizabeth Health Style Chestrub Vaporizing Action", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elizabeth Health Style Ice Cold", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ella", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ellence", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Elliotts B", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Ellipse Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elm Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Elmiron", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eloctate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Elon Dual Defense Anti Fungal Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Els Everybody Loves Antimicrobial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Els Everybody Loves Hand Sanitizer - Eucalyptus Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Els2 Everybody Loves Alcohol Free Hand Sanitizer - Bamboo, Coconut, Sea Salt", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Els2 Everybody Loves Alcohol Free Hand Sanitizer - Holy Basil, Eucalyptus, Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elta Md Skincare Uv Stick Broad Spectrum", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elta Skincare Broad-Spectrum Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elta Skincare Broad-Spectrum Sunscreen Tinted", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Laser Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Active", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Aero", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Clear Spf46", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Clear Tinted Spf46", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Daily Spf40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Daily Tinted Spf40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Elements Spf44", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Facial Spf30 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Lip Balm Spf31", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Luminous", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Physical Spf41", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Pure Spf47", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Replenish Broad Spectrum Spf44", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Restore", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Restore Tinted", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Sheer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Shield Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Spf 30 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eltamd Uv Sport Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eluo Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eluryng", DoseTypes: [{ DoseType: "Ring", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Elymus Repens Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Elyptol Antimicrobial Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elyptol Antimicrobial Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elyptol Antimicrobial Rub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elyptol Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elyptol Light Scent Antimicrobial", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elyptol Light Scent- Antimicrobial Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Elyxyb - Celecoxib", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Elzonris", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Embrace Anti-Itch Skin Protectant With Calamine And Menthol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emco V190", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emcyt", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Emely Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Emend",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Emerald", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emerphed", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Emesyl Nausea Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Emesyl Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Emetrol Cherry", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emetrol Chewables", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emf", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emf Protect And Balance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Emflaza",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Emgality", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Emilia", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emilia Body And Spa Sanitizing Hand Cream", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emilia Safe Harbor Natural Suncare", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eminence Bright Skin Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eminence Bright Skin Moisturizer Spf 40 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eminence Bright Skin Starter Set Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Eminence Lilikoi Daily Defense Moisturizer Spf 40 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eminence Red Currant Protective Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eminence Red Currant Protective Moisturizer Spf 40 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eminence Tropical Vanilla Day Cream Spf 40 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eminore - Daily Mineral Sunscreen Moisturizer Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emmi-Dent Fresh", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Emmi-Dent Kids", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Emmi-Dent Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Emoji Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emoninail Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emoquette", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Emory Johns Creek Amenity", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Emotional Detox", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emotional Eating", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Empaveli", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Empire Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Empire Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Empliciti", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Emsam", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Emtricitabine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Emtricitabine And Tenofovir Disoproxil Fumarate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Emtriva",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Emu Hand Sanitizer Mist Mint", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emu Hand Sanitizer Mist Ocean Air", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emu Hand Sanitizer Mist Ocean Surf", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emu Hand Sanitizer Mist Orange Blossom", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emuaid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emuaidmax", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Emverm", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emvita 11", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emvita 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emvita 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emvita 7", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Emvita 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enaclean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Enalapril Maleate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Enalapril Maleate And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enalapril Maleate Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enalaprilat", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Enamel Defense", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Enamel Health Multi-Protection", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Enamelon", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Enamelon Preventive Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Enbrace Hr", DoseTypes: [{ DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Enbrel",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Enc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enchanted Flower Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enchanted Memories", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enchantment Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Encore Instant Hand Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "End-Zit", DoseTypes: [{ DoseType: "Lotion, Augmented", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endacof Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endit Zinc Oxide Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endless Love For Men", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endocan Relief Roll-On", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endocet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endocrinpath", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endometrin", DoseTypes: [{ DoseType: "Insert", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Endometrium Argentum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endometrium Argentum Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endopan Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endopar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endopar M", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endopath-F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endopath-M", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endoplex F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endopure Testos For Women", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endotoxin Terrain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Endure", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endure 300 Cida Rinse Gel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endure 320 Advanced Care", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endure 400 Scrub-Stat 4", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endure 420 Cida-Stat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endure Male Genital Desensitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Endurotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enema", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Enema Mineral Oil", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Enemeez", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Enemeez Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Energize",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Energy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Energy And Motivation", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Energy Catalyst", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Energy Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Energy Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Energy Regulation Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enfanti Derma Series 100% Mineral Sun Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enfuselle Acne Clarifying", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enfuselle Body Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enfuselle Lip Treatment Spf 15 Broad Spectrum", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Engerix-B", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Englewood Amenity", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Englewood Mesh Amenity", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "English Plantain", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "English Plantain Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "English Walnut",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "English Walnut Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Engystol",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intradermal; Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Enhertu", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Enjaymo", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Enlyte", DoseTypes: [{ DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enmotion Antimicrobial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enmotion Antimicrobial Foam With Moisturizers", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enmotion Antimicrobial Foam With Moisturizers Tranquil Aloe, Green Triclosan-Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enmotion E3 Rated Gel Hand Sanitizer With Moisturizers Fragrance Free, Dye Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enmotion Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enmotion Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enmotion Foam Hand Sanitizer With Moisturizers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enmotion Foam Sanitizer With Moisturizers Fragrance Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enmotion High Frequency Use Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enovatizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Enoxaparin Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] },
        ],
    },
    { Medication: "Enpresse", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Enriched Foam Alcohol-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Enskyce", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Enspryng", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Enstilar", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ent Essentials All Dry Ear Drying Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ent Essentials Ear Wax Remvoer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ent Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Entacapone",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Entadfi", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Entecavir",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Entereg", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enteric Aspirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Enteric Coated Aspirin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Enteric Coated Stimulant Laxative", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Entero Vu 24%", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enterovirus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Entex T", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Entiere Antibacterial Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Entiere Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Entiere Hand Sanitizer, Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Entral Golden Touch Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Entresto", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Entyvio", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Enulose", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral; Rectal" }] }] },
    { Medication: "Enuresis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Envarsus", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Envio 30 Day Daily Essentials Duo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Envio 30 Day Daily Essentials Trio", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Envio 90 Day Daily Essentials Duo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Envio 90 Day Daily Essentials Duo Sub Shipment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Envio 90 Day Daily Essentials Trio", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Envio 90 Day Daily Essentials Trio Sub Shipment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Enviro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enviro Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enviro I", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enviroclenz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Environ Suncare Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Environmental Defense", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Enviropure",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Enviropure Antibacterial Hand Sanitizer Maximum Strength",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Enviropure Antibacterial Hand Sanitizer Maximum Strength (Alcohol-Free)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Envirosolutions Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Envirostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Envirotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Enzoclear Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Eo Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Eo Hand Sanitizer Wipes Lavender", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eon Hand Sanitizing Mist Tm", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eos Shea Bettertm Hydrating Hand Sanitizer Coconut Waters", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eos Shea Bettertm Hydrating Hand Sanitizer Pomegranate Raspberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eos The Fixer Heal Repair Medicated Analgesic Lip", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eos The Guardian 100% Natural Spf 30 Coconut Sun Protect Sunscreen Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eos The Guardian 100% Natural Spf 30 Watermelon Sun Protect Sunscreen Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eovist", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Epaned", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Epclusa",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ephed Asthma", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ephed Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ephed Plus Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ephedrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ephedrine Sulfate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Ephrine Nose", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Ephrine Nose Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Epi-Clenz Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Epi-Clenz Instant Hand Antiseptic",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Epi-Clenz Pure Instant Hand Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epic", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epicoccum", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Epicoccum Nigrum",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Epidermophyton Floccosum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Epididymis Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epidiolex", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epiduo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epiduo Forte", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epifoam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epiforce E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epiforce E-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epiforce P-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epigenetic Support Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epilepsy Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epinastine Hydrochloride", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Epinephrine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intracardiac; Intravenous" }, { Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intramuscular; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Endotracheal; Intracardiac; Intravenous" }, { Route: "Intramuscular" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Epinephrine Convenience Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Epinephrine Professional", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Epinephrine Professional Ems", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Epinephrinesnap-Ems", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Epinephrinesnap-V", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Epionce Daily Shield", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epionce Purifying Spot Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epionce Purifying Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epionce Renewal Calming Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epionce Ultra Shield", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epipen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Epipen Jr", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Epiphaniq",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Epiphaniq Sterile", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epiphaniq Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Epiphysinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Epiphysis 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Epiphysis Plumbum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Epirubicin Hydrochloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Epitol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Epivir",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Epl-Cell Repair", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Eplerenone",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Epoch", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epoch Ava Puhi Moni Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Epogen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Epoprostenol", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Epoprostenol Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Eprontia", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epsolay", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Epsom Salt",
        DoseTypes: [
            { DoseType: "Crystal", Routes: [{ Route: "Oral; Topical" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }, { Route: "Oral; Topical" }] },
            { DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }, { Route: "Oral; Topical" }] },
        ],
    },
    { Medication: "Epsom Salts", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epst Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epst Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epstein Barr Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epstein-Barr Virus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epstein-Barr Virus Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Epstein-Barr Virus Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Eptifibatide",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Epzicom", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equalactin Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline 12 Hour Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equaline Acetaminophen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equaline All Day Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Aller Ease", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equaline Antacid",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equaline Anti Diarrheal Plus Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equaline Anti Itch",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Equaline Anti Nausea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Antifungal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Arthritis Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Chest Congestion Relief Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Childrens Acetaminophen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equaline Childrens Allergy Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equaline Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Childrens Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Childrens Mucus Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Cool Heat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Daytime And Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Equaline Daytime Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Daytime Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Ear Relief Ear Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Equaline Earwax Removal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Flu And Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Fluticasone Propionate", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Equaline Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Heartburn Prevention", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equaline Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    {
        Medication: "Equaline Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equaline Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Kids Continuous Sunscreen Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Lidocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Equaline Loperamide Hydrochloride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Maximum Strength Stomach Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Equaline Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Equaline Migraine Formula", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Multi Symptom Flu And Severe Cold Daytime", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Multi-Action Antiseptic", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equaline Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Naproxen Sodium Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Equaline Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Equaline Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equaline Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Equaline Night Time Sleep Aid",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equaline Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Nighttime Cough Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Nighttime Severe Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equaline Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equaline Omeprazole Delayed Release", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Regular Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Sport Sunscreen Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equaline Stomach Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equaline Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Equaline Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Tussin Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equaline Tussin Mucus And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Equate 2 In 1 Dry Scalp Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate 2% Salicylic Acid Acne Treatment Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate 8Hr Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Acid Reducer Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Acne Wash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Adult Tussin Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate All Day Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate All Night Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Allergy And Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equate Allergy Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equate Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Anti-Wrinkle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Antibacterial Citrus Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Antibacterial Citrus Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Antibacterial Fresh Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Antibiotic Plus Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Antibiotic Plus Pain, Itch, Scar Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Anticavity Oral Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Arnica Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Arthritis Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Equate Athletes Foot",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Equate Baby Advanced Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Barrier Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate Beauty",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Equate Beauty Acne Cleansing Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Beauty Acne Foaming Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Beauty Color Safe Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Beauty Color Safe Dandruff Conditioner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Beauty Daily Moisturizer Spf-30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Beauty Enamel Protection Gentle Whitening For Sensitive Teeth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Equate Beauty Hydro Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Beauty Quick Action Pads Salicylic Acid Acne Treatment", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Beauty Ultra Light Sunscreen Spf 70", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Beauty Ultra Light Sunscreen Spf70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Benz-Dex Cough And Throat Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Black Elderberry Cold And Flu Relief", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Candied Watermelon Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Capsaicin Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Cherry Zinc Cold Relief Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Childrens Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Childrens Multi Symptom Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Childrens Multi Symptom Cold Nighttime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Childrens Vaporizing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Cimetidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Clear Complexion Facial Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Clear Lax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Cold Therapy Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Cold Therapy Pain Relieving Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Comfort Flow Sinus Wash", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Equate Cool And Heat",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Equate Cool And Heat Pain Relieving Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Daily Moisturizing Dimethicone Skin Protectant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Daytime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Daytime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Daytime Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Equate Daytime Severe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Daytime Severe Vaporice Cold And Flu Nighttime Severe Vapor Ice Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Equate Daytime Vapor Ice Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Diarrhea Control", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Dry Scalp 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Dry Scalp 2 In 1 Dandruff Sh And Cond", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Dry Scalp Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Everyday Clean 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Everyday Clean 2 In 1 Dandruff Sh And Cond", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Everyday Clean Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Everyday Clean Gentle Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Extra Strength Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Extra Strength Smooth Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Eye Drops Dry Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Eye Itch Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Eye Lubricant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Famotidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Fiber Therapy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate First Aid Antibiotic Pain Relieving", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate First Aid Antibiotic Plus Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Equate Flu And Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Foaming Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Green Apple 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate Hair Regrowth Treatment",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Equate Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Health Non Drowsy Day Cold And Flu Plus And Nighttime Cold And Flu Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Hemorrhoid Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Equate Hemorrhoidal Medicated Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equate Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Itch Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Jock Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate Lansoprazole",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equate Lansoprazole Delayed Release", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Lice Treatment Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Equate Lidocaine Cool And Heat", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Loperamide Hydrochloride", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Lubricant Eye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Lubricant Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Lubricant Eye Drops High Performance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Lubricant Eye Drops Preservative-Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Lubricant Eye Drops Restorative Performance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Medicated Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Mens Bold Scent 2 In 1 Dandruff Sh And Cond", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Mens Ultra Sport 2 In 1 Dandruff Sh And Cond", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Menthol Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Equate Mucus D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Mucus Er Max", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Mucus Relief Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Muscle Cramp And Spasm Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Equate Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Equate Neti Pot Sinus Wash", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Equate Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equate Nightime Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Nighttime Vapor Ice Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Nitetime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Nitetime Daytime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Equate Non-Drowsy Flu Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Odor-Free Therapy Arthritis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equate Omeprazole And Sodium Bicarbonate", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Omeprazole Delayed Release", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Organic Honey Strawberry Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Pain Relief Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Pain Relief Cream", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Pain Relief Patch Camphor/Menthol/Salicylate", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Equate Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Pain Relieving Cream Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Pain Relieving Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical; Transdermal" }] }] },
    { Medication: "Equate Purifying Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Ready-To-Use Enema", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Equate Redness And Dry Eye Relief Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Redness Reliever", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Repair And Protect", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Equate Repairing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Restore Plus Lubricant Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Restore Plus Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Equate Saline Packets", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Equate Scar", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Equate Severe Cold Multi Symptom", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Severe Toothache And Gum Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Equate Sinus 12 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Soft And Smooth 2 In 1 Dandruff Sh And Cond", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sore Throat Pops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Spf 15 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Spring Rain Liquid Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Sugar Free Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Sunburn Relief With Aloe After Sun", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Baby Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Kids Spf50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Mineral Based Kids Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Mineral Broadspectrum Spf 50 Baby Zinc", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Spf 30 Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Spf 50 Baby", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Spf 50 Kids", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Equate Sunscreen Spf 50 Sport",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Equate Sunscreen Spf 70 Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Spf100 Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Spf70 Ultra", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Sport Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Sunscreen Sport Spf 100", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Suphedrine Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Swimmers Instant Ear Dry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Equate Tanning Sunscreen Dry Oil Uvb Protection Spf10", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Tartar Control Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Tioconazole 1 Day", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Equate Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Tussin Dm Max Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Equate Ultra Light Sunscreen Mist Spf70", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Ultra Stregth Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Vapor Ice Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Vaporizing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Vitamins A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Yeast Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equate Ziinc Sunscreen Ultra Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate Zinc Oxide Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equate-Sunscreen Broad Spectrum Spf 50 Ultra", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equetro", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equi-Mild", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equi-Stat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equilibrium Cbd Pain Relief Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equisetum 1% Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Arvense", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Arvense (M)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Cum Sulfure Tosta 6", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Essence Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equisetum Ex Herba 1% Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Ex Herba 15 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Ex Herba 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Equisetum Formica",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Equisetum Hyemale", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Limosum Rubellite 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Silicea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Stannum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Stannum Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisetum Viscum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Equisteum Iris", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Equus Caballus Skin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Er 911", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Era Organics Mineral Based Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eradikate Acne Mark Fading", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eradikate Acne Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eradikate Clarifying Acne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eradikate Daily Foaming Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eradikate Facial Mask", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eradikate Mattifying Acne", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eradikate Salicylic Acid Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eraxis", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Erbitux", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Erborian - Bb Creme Au Ginseng Caramel Spf20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian - Bb Creme Au Ginseng Clair Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian - Bb Creme Au Ginseng Dore Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian - Bb Creme Nude Spf20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian - Cc Creme High Definition Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian - Cc Creme High Definition Spf25 Clair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian Bb Creme Au Ginseng Chocolate Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian Cc Creme High Definition Spf 25 Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian Cc Dull Correct Spf25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian Cc Red Correct Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erborian Double Sample Cc Creme Clair And Dore Spf 25", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Ergocalciferol",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ergocalciferol (Vitamin D2)", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ergocalciferol Capsules,", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ergoloid Mesylates", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ergomar", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ergotamine Tartrate And Caffeine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ergotamine Tartrate, Caffeine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Erigeron Canadensis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Erins Faces Mineral Spf 32 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erivedge", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Erleada", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Erlotinib",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Erlotinib Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Erogetone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Errin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ertaczo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ertapenem",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Ertapenem Sodium", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Erwinase", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Ery", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ery-Ped", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ery-Tab", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eryfotona Actinica Ultralight Emulsion", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eryfotona Ageless", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erygel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erythrocin Lactobionate", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Erythrocin Stearate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Erythromycin",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Erythromycin And Benzoyl Peroxide",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Topical" }] },
        ],
    },
    {
        Medication: "Erythromycin Ethylsuccinate",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Erythromycin Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Erythromycin Lactobionate", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Esbriet",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Escitalopram",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Escitalopram Oxalate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Escozine Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Esgic",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Esika Its You Antiperspirant Roll-On Deodorant", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Esika Pro Natural Matte Mattifying Foundation Adaptable To Your Skin Tone Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Esika Total Sec Delicate Skin Antiperspirant And Deodorant", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Esika Total Sec Delicate Skin Antiperspirant And Deodorant Womens Roll-On All Day Extra Effective Protection", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Esika Total Sec Neutral Fragrance Antiperspirant And Deodorant All Day Extra Effective Protection", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Esika Total Sec Neutral Fragrance Antiperspirant And Deodorant Unisex Roll-On All Day Extra Effective Protection", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Esika Winner Sport Antiperspirant Roll-On Deodorant", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Esmolol Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Esmolol Hydrochloride In Sodium Chloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Esomeprazole",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Esomeprazole Magneisum D/R", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Esomeprazole Magnesium",
        DoseTypes: [
            { DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule, For Suspension, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Esomeprazole Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Esomeprazole Strontium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Esophagus Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Esperoct", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Essential Daily Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essential Defense Everyday Clear Broad Spectrum Spf 47 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essential Defense Mineral Shield Broad Spectrum Spf 32 Tinted Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essential Defense Mineral Shield Broad Spectrum Spf 35 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essential Everyday Antibacterial", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essential Everyday Antibacterial Moist Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essential Fatty Acid Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Essential Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essential Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essential Weight Loss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Essentials", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essentials By Urban Secrets Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essentials By Urban Secrets Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essentials Face And Body Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essentials Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Essentials Healthy Teeth And Gums", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Essentials Whiten And Strengthen", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Essopi Spf 33 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Estacion Hand Sanitizer Antibacterial", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Estarylla", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Estazolam", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estee Lauder Double Wear Sheer Long-Wear Makeup Broad Spectrum Spf 19", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Estee Lauder Double Wear Soft Glow Matte Cushion Makeup Broad Spectrum Spf 36", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Estee Lauder Multi-Defense Aqua Uv Gel Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Esteem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Esterified Estrogens And Methyltestosterone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estesol Fh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Esthete Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Estomaquil Polvo", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Estrace",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Estradiol",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Film, Extended Release", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Insert", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Estradiol / Norethindrone Acetate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estradiol And Norethindrone Acetate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estradiol And Progesterone", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estradiol Transdermal System", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Estradiol Vaginal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Estradiol Vaginal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Estradiol Vaginal Inserts", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Vaginal" }] }] },
    {
        Medication: "Estradiol Valerate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    { Medication: "Estradiol Valerate And Estradiol Valerate/Dienogest", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Estring", DoseTypes: [{ DoseType: "Ring", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Estriol 5.0 Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Estro-Life", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Estrogel", DoseTypes: [{ DoseType: "Gel, Metered", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Estrogen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estrogen And Progesterone", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estrogen Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estrogens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Estrone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Eszopiclone",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }, { Route: "Oropharyngeal" }] },
        ],
    },
    { Medication: "Eternal Minoxidil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Eternal Spirit Beauty",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Eternatear", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Etesevimab", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Ethacrynate Sodium",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Ethacrynic Acid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ethacrynic Sodium",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Ethambutol Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ethamolin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ethical Zinc Daily Wear Light Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ethical Zinc Natural Clear Zinc Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ethosuximide",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ethy Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ethyl Alcohol",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ethyl Alcohol 70%", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ethyl Alcohol Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ethyl Alcohol Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ethyl Alcohol Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ethyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ethyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ethyl Rubbing Alcohol 70 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ethylicum (Etoh, Aka Alcoholus)", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ethynodiol Diacetate And Ethinyl Estradiol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ethyol", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Etiquette Sterilization Wet Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Etodolac",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Etomidate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Eton Q", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Etonogestrel And Ethinyl Estradiol", DoseTypes: [{ DoseType: "Insert, Extended Release", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Etonogestrel/Ethinyl Estradiol", DoseTypes: [{ DoseType: "Insert, Extended Release", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Etopophos", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Etoposide",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Etravirine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eubioflor", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eucalyptus Comp. Special Order", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucalyptus Cuprum Sulf.", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eucalyptus Ease Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucalyptus Globulus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eucalyptus Globulus Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Eucalyptus Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucalyptus Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Eucamint", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Advance Hydration Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Advanced Hydration Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Advanced Hydration Sunscreen Spf 50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Age Defense Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Baby Sensitive Mineral Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Eucerin Daily Hydration",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Eucerin Daily Hydration Hand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Daily Protection Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Eczema Relief Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Eczema Relief Flare Up Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Eczema Relief Hand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Itch Relief Intensive Calming", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Oil-Control Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Q10 Anti-Wrinkle Sensitive Skin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Redness Relief Daily Perfecting", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Sensitive Mineral Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucerin Skin Calming Itch Relief Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucrisa", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eucutasol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eugenia Jambosa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eugenol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eugenol Toothache Medication", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Eulexin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eunyul Daily Care Fresh Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eupatorium Capillifolium Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Eupatorium Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eupatorium Perf Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Eupatorium Perfoliatum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Eupatorium Purp", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Euphorbium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Euphorbium Officinarum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Euphrasia 3X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Euphrasia Officinalis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Euphrasia Officinalis Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Euqate Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eurofresh Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "European Olive Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Euthyrox", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Euz-Clean", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Euz-Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evamist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Evarrest", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eve Lom Daily Protection Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evekeo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Evekeo Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Evel Knievels Natural Choice Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evelinecharles Pure Purifying Hand Cream Max", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even And Bright Moisturizer Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even And Clear Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Better Dark Spot Correcting Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Better Glow Light Reflecting Makeup Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Better Makeup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Better Skin Tone Correcting Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Defense Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Tone And Radiance", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Tone Mineral Sunscreen Broad Spectrum Spf 36 Medium - Dark", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Tone Mineral Sunscreen Broad Spectrum Spf 40 Light - Medium", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Tone Night Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Even Up Sunscreen Spf 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evening Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evenity", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Evenly Plus Psoriasis Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ever Ready Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everclear", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evereden Botanical Facial Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evereden Healing Eczema Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evereden Multi-Purpose Healing Balm", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evereden Premium Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evereden Spf50 Premium Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everflex Topical Analgesic Pain Relief With Msm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evermastic Mastic Scalp Care", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Everolimus",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Eversmile Alignerfresh Enamel Guard", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Everwipe Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everwipe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Every Day Mineral Sun Screen Spf 45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Every Fresh Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Every Man Jack",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Every Man Jack - Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Every Man Jack 2-In-1 Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Every Man Jack Charcoal Face Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Every Man Jack Face Wash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Every. Single. Face. Watery Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everybody Labo Instant Protective Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Clean", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Clean Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Coverage Tinted Sunscreen - Extra Light Tint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Coverage Tinted Sunscreen - Light Tint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Coverage Tinted Sunscreen - Medium Tint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Diaper Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Eczema Unscented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Essential Travel Salves 4Pc Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Facial Moisturizer And Sunscreen Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Medical Everyday Numb Topical Anesthetic Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Nourishing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Sheer Coverage Light Tint Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Sheer Coverage Medium Tint Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Strength Underarm Antiperspirant Fresh Lavender", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Sunscreen With Sunflower Extract Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everyday Superstars Spf Discovery Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everydaycare Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everydayhumans Rose From Above Spf 35 Sunscreen Base", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Everyman Jack",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Everyone Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Everyone Hand Sanitizer Lavender Aloe",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Everyone Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everytoktok Hair Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Everzero 83 Hand Sanitizer With Vitamin E And Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evicel Fibrin Sealant (Human)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Evict", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evista", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Evitarol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Evithrom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evkeeza", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Evoclin", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evomela", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Evotaz", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Evowell Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evowell Hand Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evoxac", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Evrysdi", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Evtox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Evusheld", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Evviva Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evviva Instant Hand Sanitizer Fruity Floral Aroma", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Evzio(R) Naloxone Hcl", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Ex Cuseme Brightening Suncream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ex-Lax",
        DoseTypes: [
            { DoseType: "Pill", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Exact-Rx Sodium Sulfacetamide And Sulfer 10%/5% Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exact-Rx Sodium Sulfacetamide Wash 10%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exactuss Total Release", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exalthemp Hot And Cold Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Excaugh", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Excedrin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Excedrin Extra Strength", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excedrin Extra Strength And Excedrin Tension Headache", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Excedrin Extra Strength Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excedrin Migraine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excedrin Migraine Acetaminophen, Aspirin (Nsaid) And Caffeine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excedrin Pm Triple Action Caplets", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excedrin Pm Triple Action Caplets And Excedrin Extra Strength Pain Reliever", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Excedrin Pm Triple Action Caplets And Excedrin Tension Headache", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Exceed Total Care Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Excel Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Excelon Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Excelon Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exchange Select", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select All Day Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Aller Ease", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Exchange Select Allergy Relief",
        DoseTypes: [
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Exchange Select Antacid",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Exchange Select Artificial Tears Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Exchange Select Atheletes Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exchange Select Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Cold Sore Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exchange Select Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Eye Drops Advanced Relief Moisturizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Exchange Select First Aid Triple Anitbiotic Pain Relieving", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Exchange Select Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Exchange Select Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exchange Select Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Exchange Select Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Exchange Select Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Sf Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exchange Select Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exchange Select Ultra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excinol Arthritis (Acetaminophen)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excinol Extra", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excinol Extra (Acetaminophen)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excinol Migraine (Acetaminophen)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excinol Night (Acetaminophen)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Excitathyrotem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exel Clarifying Line", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Exelderm",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Exelon", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Exem Foam", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Exemestane",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Exfo Tonic Acne Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exforge", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exforge Hct", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exgerm Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exhaustion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exigence", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exigence One Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exjade", DoseTypes: [{ DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exkivity", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Exmicror", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exoden Cavity", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exoden Cure Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exoden Gum", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exoden Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exoden Sensitive", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exoden White", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exondys 51", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Exoscrt Derma Signal Kit Srlv", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Exoscrt Scalp Care Hrlv", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exoten-C", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exparel", DoseTypes: [{ DoseType: "Injection, Suspension, Liposomal", Routes: [{ Route: "Infiltration" }] }] },
    { Medication: "Expectorant Guaifenesin Extended-Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Expedition Backcountry Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Express Wipes Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exservan", DoseTypes: [{ DoseType: "Film", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ext Hair Regrowth Treatment For Men 2%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ext Hair Regrowth Treatment For Men 5%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ext Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extavia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Extended Care Antimicrobial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extended Care Foaming Antimicrobial Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extended Care Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extended Phenytoin Sodium", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "External Analgesic", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extina", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Action Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Drawing Acne Relief", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Fast Coltalin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Gentle Laxative", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Large Cool And Heat Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Moisturizing Nasal Pump Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Extra Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Pain Relief Acetaminophen, Aspirin (Nsaid) And Caffeine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Repair Moisturizing Balm Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Stength Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Strenght Headache Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strenght Saline Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Extra Strenghth Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Extra Strength",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Extra Strength Acetaminohpen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Extra Strength Acetaminophen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Extra Strength Acetaminophen Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Strength Cold And Hot Menthol Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Strength Cold-Hot Medicated Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Extra Strength Gas Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Extra Strength Gas Relief Simethicone 125 Mg", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Headache", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Extra Strength Headache Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Extra Strength Headache, Circle K", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Heartburn Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Heartburn Relief Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Hua Tuo Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Extra Strength Hua Tuo Pain Relieving", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Extra Strength Mapap", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Extra Strength Natural Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Nite-Time Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Nitetime Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength No-Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength No-Pain Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Pain Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Pain Releif", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Extra Strength Pain Relief",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Extra Strength Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Extra Strength Pain Relief Pm",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Extra Strength Pain Relief Therapy", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Extra Strength Pain Reliever",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Extra Strength Pain Reliever Pm",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Extra Strength Sanitizing Wipes", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Strength Simethicone Antigas", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Sinus Relief Nasal Decongestant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Extra Strength Smooth", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Smooth Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Smooth Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Stopain Cold Pain Relieving Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Strength Sugar Free", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Strength Sugar Free Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Extra Strength Tylenol Cold Plus Flu Multi-Action",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Extra Strength Wal-Dryl Itch Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extra Strength With Natural Menthol Glacier Mint Cough Suppressant", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extra Thick Callus Removers", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extraneal", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intraperitoneal" }] }] },
    { Medication: "Extraprin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Extreme", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extreme Protect Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extreme Protect Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Extreme Tea Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exuviance Age Reverse Day Repair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exuviance Coverblend Skin Caring Foundation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exuviance Daily Acne Peel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exuviance Daily Corrector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exuviance Professional Optilight All-Over Dark Spot Minimizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exuviance Professional Sheer Daily Protector", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Exuviance Skin Caring Bb Fluid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eye Allergy Itch And Redness Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Allergy Itch Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Allergy Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Eye Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Eye Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Eye Drops",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Eye Drops Ac", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Drops Advanced Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Drops Regular", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Floaters", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eye Floaters Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Irritation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eye Itch", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Itch Releif", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Eye Itch Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    {
        Medication: "Eye Lid Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Eye Lubricant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Sarcode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Eye Strain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Strain Relief Pm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eye Stream", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eye Twitching Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Eye Wash",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Irrigation" }, { Route: "Ophthalmic" }, { Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Intraocular" }, { Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Eye Wash Saline", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyeganics Organic Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyelids Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Eyes Alive", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intraocular" }] }] },
    { Medication: "Eyes Alive Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyes Alive Eyecon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intraocular" }] }] },
    { Medication: "Eyes Irritation Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyesaline Concentrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyesaline Emergency Eyewash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyesaline Emergency Eyewash Concentrate", DoseTypes: [{ DoseType: "Concentrate", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyewash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyewash Additive Concentrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eyewash Station Additive Concentrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eylea", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravitreal" }] }] },
    { Medication: "Eys Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Eysuvis", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ez Char", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ez Gel 12", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ez Groom Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ez Nite Sleep",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ez Nite Sleep Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ez-448 Antibacterial Moist Hand", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ez-Detox Super Drainage Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ez-Quit", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Ez-Quit Control", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Ez-Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ez-San Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ez-Slim", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Ez2Go",
        DoseTypes: [
            { DoseType: "Pill", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ezallor Sprinkle", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ezetimibe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ezetimibe And Simvastatin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "F(X)L Skin Care Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "F01", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F02", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F03", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F04", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F05", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F07", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F08", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F09", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F10", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F12", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F14", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F15", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F18", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F19", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F20", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F22", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F25", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F26", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F27", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "F4P Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fa Alcohol Swab", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fa Eol Swab", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fa Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fa Hand Sanitizer Gel 70% (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fa Hand Sanitizer Liquid 70", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fa Sanitizer Swab", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fabior", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fabrazyme", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Face And Body Mineral Spf - Tinted", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face And Body Mineral Spf Non-Tinted", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Balm Spf-15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Plus Scalp Invisible Daily Spf 30 Broad Spectrum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Saver Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Spf 47 Setting", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Acne Medication", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Daily Facial Moisturizing Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Radiant Facial Daily Moisturizer Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Refreshing Cooling Body Mist Sunscreen Spf 45", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Spf 15 Facial Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Ultra Light Body Mist Sunscreen Spf 30", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Ultra Light Dry To The Touch Sunscreen Broad Spectrum Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Ultra Sport Active Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Face Values Ultra Sport Active Spf 30 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Face Values Ultra Sport Active Spf 50 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Face Values Wet N Dry Wet Skin Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Values Wet N Dry Wet Skin Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Face Wash", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Facial Pain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Facilipro", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Facilipro Protecting Fhs", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Facol Cold And Flu Day", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Facol Cold And Flu Day And Night", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Facol Cold And Flu Night", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fagopyrum Esculentum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fagus Grandifolia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Fair And Square",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fair Light Tinted Drops", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fak Care4 Bus And School Yellow Orm D", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fak Large Office First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fallback Solo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Falmina", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "False Ragweed", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "False Ragweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Famciclovir",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Family Care Advanced Formula Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Family Care Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Care Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Care Earwax Removal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Family Care Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Care First Aid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Care Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Care Lubricant Single Use Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Family Care Maximum Strength Medicated Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Care Muscle And Joint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Care Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Care Nasal Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Family Care Original Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Family Care Redness Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Family Care Tolnaftate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Care Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Family Dollar",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Family Dollar Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Dollar Tropic Sun Kids Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family I", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Family Wellness",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Mouthwash", Routes: [{ Route: "Dental" }, { Route: "Oral" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Rectal; Topical" }, { Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Family Wellness Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Family Wellness Acid Reducer",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Family Wellness Acid Reducer Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Advanced Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Family Wellness Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Family Wellness Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Anti Diarrheal Plus Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Anti Diarrheal Plus Multi Symptom Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Antiseptic And Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Childrens Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Cold Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Ear Relief Ear Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Family Wellness Earwax Removal Earwax And Bulb", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Family Wellness Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Effervescent Cold Relief Orange", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Effervescent Cold Relief Original", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Flu And Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Hemorrhoidal", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Family Wellness Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Ibuprofen Ib", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Ice Blue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Infants Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Laxative", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Lice Treatment Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Family Wellness Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Medicated Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Family Wellness Migraine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Multi Symptom Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Nicotine", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Family Wellness Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Family Wellness Original", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Family Wellness Pain Scar Itch", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Plus Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Family Wellness Sleep Aid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Famology Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Famotidine",
        DoseTypes: [
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Fanapt",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fancy Clean Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fancyclean Antiseptic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fapp Mixture", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Fareston", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Farewell Collection",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fargelin Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal; Topical" }] }] },
    { Medication: "Farmacy Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Farmasi Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Farxiga", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Farydak", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fasenra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Faslodex", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Fast", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fast Acting Heartburn Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fast Acting Mucus Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fast Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fast Dissolving Daytime - Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fast Freeze",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fast Freeze Lidocaine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fast Freeze Lidocaine Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fast Freeze Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fast Freeze Pain Relieving Continuous", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fast Maximum Night Time Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fast Mucus Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fast Mucus Relief Cold Flu And Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fast Pain Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fastaction Germastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fastenal Clean Choice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fat Loss Activation Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fat Loss Activator", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral; Sublingual" }] }] },
    { Medication: "Father Johns Cough Medicine Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Father Johns Medicine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fatigue", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fatigue Fighter",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fatigue Nosode Eb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fatigue Stat Cm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Favorite 50 Homeopathic First Aid Kit", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fayosim", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fcs Cp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fcs Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Febriplex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Febuxostat",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fedrin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Feel Clean Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feel Good Labs Natural Pain", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feel Good Labs Sport Recovery", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feel Good You Plus Relief, Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feelgood Lab Natural Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feeling-Te", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feiba", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Felbamate",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Felbatol",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Feld Apotheke Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feldene", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Felodipine",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fem Choice Morning After", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fem Flora Defense", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fem Ph", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Fem Pro Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fem Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fem Repair Non-Preg", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fem Response", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Femadrine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Female Balance",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Female Balance Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female Endocrine Axis Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female Femroids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female Intimate Hygiene Antibacterial Gels", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Female Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female Stimulant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Female Vitality", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Femara", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Femiclear",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Topical; Vaginal" }, { Route: "Vaginal" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Femiclear Bacterial Vaginosis Symptoms", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Femiclear Genital Herpes Symptoms", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Femiclear Yeast Infection", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical; Vaginal" }] }] },
    { Medication: "Feminine Anti-Itch Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feminine Comfort", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Feminine Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Femmesil",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Femring", DoseTypes: [{ DoseType: "Ring", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Femstat C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Femur 9 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Femynor", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fendall 2000 Pure Flow", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Fendall Pure Flow", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Fenofibrate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Fenofibric Acid",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fenofibric Acid Delayed-Release", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fenoglide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fenoprofen Calcium",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fenovar", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fensolvi", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Fentanyl",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Fentanyl Buccal", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Buccal; Sublingual" }] }] },
    {
        Medication: "Fentanyl Citrate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral; Transmucosal" }, { Route: "Transmucosal" }] },
        ],
    },
    { Medication: "Fentanyl System", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Fentanyl Transdermal", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Fentanyl Transdermal System", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Fentora", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Buccal; Sublingual" }] }] },
    { Medication: "Fenty Skin Hydra Vizor", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Feraheme", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Fermierpapa Pure Moringa Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fermitif Neck Renewal Cream Broad Spectrum Spf 15 Sunscreen Us", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ferralet 90", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferring Pharmaceuticals Inc.", DoseTypes: [{ DoseType: "Ring", Routes: [{ Route: "Vaginal" }] }] },
    {
        Medication: "Ferriprox",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ferrlecit", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ferrodonna", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrous Sulfate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum 21", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum 21X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ferrum 6", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ferrum 8 Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ferrum Acidum Cholalicum 4 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Arsenicosum", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Arsenicosum 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Arsenicosum 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Carbonicum", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Iodatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ferrum Metallicum",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ferrum Muriaticum", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ferrum Phos",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ferrum Phos 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Phos Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Phos. 6X", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Phosphate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ferrum Phosphoricum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Ferrum Phosphoricum 6", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Phosphoricum 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Ferrum Phosphoricum 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Picricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ferrum Quartz",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ferrum Quartz Pellets", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Rosatum Graphites", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Sidereum 20", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Sidereum 21 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Sidereum 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ferrum Sidereum 6",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ferrum Sidereum 8X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Sidereum Pancreas 10/4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferrum Sidereum Sulfur", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ferrum Silicicum Urtica",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ferrum Sulphuricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fertility Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ferumoxytol", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Fesoterodine Fumarate",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Festival Favorites", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fetroja", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Fetzima",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Fever - Infection",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fever And Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fever Flu Symptom Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fever Inflammations", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fever Reducer", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fever Reducing", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Fever Vomiting", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fever-Infection",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Feverall", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Fex-Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fexinidazole", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fexmid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fexofenadine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fexofenadine Hcl",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fexofenadine Hcl And Pseudoephedrine Hci", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fexofenadine Hcl And Pseudoephedrine Hcl", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fexofenadine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Fexofenadine Hydrochloride And Pseudoephedrine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fhf Elevated Shade 100% Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fiasp", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Fiber Caplets",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fiber Caps", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fiber Lax", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fiber Laxative",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fiber Tabs", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fiber Therapy",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fibercon", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fibricor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fibromyalgia Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fibrous Breast Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fibryga", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Fields Of Flowers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fifth Chakra (Throat)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Filbert Hazelnut Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Filix Mas", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Finacea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finacea Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Finafta",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Finasteride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Find Bichon Cleansing Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Find Your Happy Place", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fine And Clear Advanced Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fine Wash Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fineapple Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finecare 75% Alcohol Disinfecting Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finefra Aid Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finefra Black Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finefra Clinic Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finefra Kids Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finefra Silver", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Finefra Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finipil 4 Men Only", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finipil Lait 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Finipil Pro/Elec", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fintepla", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fioricet", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fioricet With Codeine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fiorinal", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Firazyr", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Firdapse", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fire", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fire Agate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fire Ant",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Fire Opal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fire Out", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fire Retardant Chemical Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Firebush Kochia", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Firefly Anticavity Fluoride", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Firefly Bubble Blast", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Firefly Pediatric", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Firefly Strawberry Smoothie", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Firmagon", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Firmarine Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "First Aid",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Salve", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "First Aid 4Pc Mini Stick Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "First Aid And Burn", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Antibiotic And Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Antibiotic Bacitracin With Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Antifungal Medicated", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "First Aid Antiseptic",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "First Aid Antiseptic Alcohol Pads Isopropyl Alcohol 70 Rite Aid", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "First Aid Antiseptic Pain Relieving",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "First Aid Antiseptic Water Soluble", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Antiseptic Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Bacitraycin Plus Original", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Beauty Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Beauty Anti-Dandruff Scalp Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Beauty Fab Broad Spectrum Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Beauty Fab Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Beauty Fab Pharma Bha Acne Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Beauty Fab Pharma White Clay Acne Treatment", DoseTypes: [{ DoseType: "Disc", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Beauty Fab Ultra Repair Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Beauty Fab Ultra Repair Skin Protectant Pink Grapefruit", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "First Aid Burn",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "First Aid Burn Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Direct Anti-Diarrheal", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Direct Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Direct Backache And Muscle Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Direct Buffered Eyewash Sterile Isotonic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "First Aid Direct Chewable Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Direct Industrial Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "First Aid Direct Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Direct Peptum Tabs", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Only Alcohol Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Alcohol Antiseptic Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Alcohol Antiseptic Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Ammonia Inhalants", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "First Aid Only Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Only Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Only Burn", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Burn Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Bzk Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Bzk Antiseptic Towelette", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Bzk Antiseptic Towelettes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Extra-Strength Non-Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Only First Aid/ Burn", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only First Aid/Burn", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Aid Only Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Neomycin Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "First Aid Only Povidone-Iodine Antiseptic",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "First Aid Only Povidone-Iodine Prep Pad", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Sting Relief", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "First Aid Only Sting Relief Pad",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "First Aid Only Sunx30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Only Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Pain And Trauma Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Triple Antibiotic Pain And Scar", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Aid Vegan", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Chakra (Root)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "First Class Kit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "First Degree", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Degree Maximum Strength Burn", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Mark", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Mark Ultra Foaming Antibacterial", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Mark Ultra Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "First Safety",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "First Safety Instant Sanitizer Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Shield", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Shield First Aid Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Street Dishwasing Concentrate And Antimicrobial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "First Street Hand Sanitizer Fresh Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Firstcare Allergy Relief Diphenhydramine Hci, 25 Mg Antihistamine", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Firstcare Childrens Allergy Relief", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Firstcare Dextromethorphan Hydrobromide, Doxylamine Succinate", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Firstcare Pain Relief Gel Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Firstep Firstflush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Firvanq", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fish Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Fisher-Price Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fisherbrand Alcohol Prep Pad Sterile", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fissure Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fitglow Vita Shield Hyaluronic Primer Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fixmyskin Healing Balm Fragrance-Free", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fixmyskin Healing Balm Unflavored", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fixmyskin Healing Balm Vanilla Flavor", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fixodent", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flac Otic Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Flagyl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flanax", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flanax Back Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flanax Liniment", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flanax Liniment Unit Dose Display", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flanax Menstrual Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flanax Pain And Fever Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flanax Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Flanax Pain Reliever/Fever Reducer",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Flanders Buttocks", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flanders Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flarex", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Flash Radiant Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flatulence", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flavobac Health Guard Complete", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flavon Charcoal Mint Tooth", DoseTypes: [{ DoseType: "Powder, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flavon White Mint Tooth", DoseTypes: [{ DoseType: "Powder, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flavored Colestid", DoseTypes: [{ DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Flavoxate Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Flawless Brightening Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flawless Brightening Sunscreen Non-Tinted", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flawless By Sonya Aloe Bb Creme Cocoa Spf 10 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flawless By Sonya Aloe Bb Creme Nude Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flawless Effect Liquid Foundation Spf 15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flawless Future Spf 30", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flawless Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Flaxseed",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Fle Antibacterial Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Flebogamma", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Flebogamma Dif", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Flecainide Acetate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Flector",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "System", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Fleet",
        DoseTypes: [
            { DoseType: "Enema", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Fleqsuvy", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flex Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flex Fx Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flex-Prin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flex24 Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexall Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexall Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Flexbumin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Flexible Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexible Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexible Pain Relief Gel Extra Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexible Pain Relief Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexible Pain Relief Roll-On Extra Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexitol Eczema And Dermatitis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexitol Face And Eyelid Eczema", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexitol Foot And Knee Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexitol Happy Little Bodies Eczema Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flexmore", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flexmore Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flexsport Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flight Dr Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flo Wellness Advanced Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flo-Pred", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Floater", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flod Hand Sanitizer Gel, Aloha-Green Apple Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flolan", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Flolipid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flomax", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Flonase",
        DoseTypes: [
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Flonase Sensimist", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Flor-Opal Sustained-Release Fluoride", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Flora Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flora Shine Alcohol Anti-Bacterial", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flora Shine Alcohol Free Anti-Bacterial", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Floral Rose Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Floral Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florasone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florasone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "Flores Formica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flores Formica Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Florexa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Grown", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Laboratories - Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Salt Scrub", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Salt Scrubs", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Squeeze Spf 15", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Squeeze Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Squeeze Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Squeeze Spf 8", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Florida Suncare", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Floriva",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Flounder",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Flouride Mouthwash Strong Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Flovent",
        DoseTypes: [
            { DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    { Medication: "Flowers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Floxuridine", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intra-Arterial" }] }] },
    {
        Medication: "Flu And Severe Cold",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Flu And Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu And Sore Throat Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Flu Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Flu Combination 2021-2022", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Ease", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Fix", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Hbp", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Immune", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Immune Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Nosode 2021-2022", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Relief Therapy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Relief Therapy Severe Cough And Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Symptom Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Terminator Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu, Cough And Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu-Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flu-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluad Quadrivalent", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Fluarix Quadrivalent", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Flublok Quadrivalent Northern Hemisphere", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Flucelvax Quadrivalent", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Fluconazole",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Fluconazole In Sodium Chloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Flucytosine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fludarabine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Fludarabine Phosphate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Fludeoxyglucose",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Fludeoxyglucose F 18",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Fludeoxyglucose F-18",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Fludeoxyglucose F18",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Fludrocortisone Acetate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluffy Marshmallow Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flulaval Quadrivalent", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Flumazenil",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Flumethovix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flunisolide", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Fluocinol Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Fluocinolone Acetonide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Auricular (Otic)" }, { Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] },
        ],
    },
    { Medication: "Fluocinolone Acetonide Oil, 0.01% (Ear Drops)", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Fluocinolone Acetonide Topical", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fluocinolone Acetonide Topical Solution Usp, 0.01%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fluocinonide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fluocinonide Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fluocinonide Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fluopar", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fluorescein", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Fluorescein Sodium And Benoxinate Hydrochloride", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Fluorescein Sodium And Proparacaine Hydrochloride", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Fluorescite", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Fluoride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Dental" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Dental" }, { Route: "Oral" }] },
        ],
    },
    { Medication: "Fluoride Dental Treatment Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluoride Mouth Rinse Anticavity", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluoride Mouthwash Soft Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluoride Soft Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluoride Strong Mint", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fluoridex",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Fluorimax 5000", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fluorimax 5000 Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fluorite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluorodopa", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Fluorometholone", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Fluoroplex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fluorouracil",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fluorouracil Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fluovix Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fluoxetine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fluoxetine Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fluoxetine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }, { Route: "Oral; Oral" }] },
        ],
    },
    { Medication: "Fluoxiv", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fluphenazine Decanoate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Fluphenazine Hydrochloride",
        DoseTypes: [
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Flurandrenolide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Flurazepam Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flurbiprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Flurbiprofen Sodium", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Flush Maximum Strength Antifungal Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flutamide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluticare", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Fluticasone", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Fluticasone Furoate And Vilanterol", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Fluticasone Propionate",
        DoseTypes: [
            { DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    {
        Medication: "Fluticasone Propionate And Salmeterol",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    { Medication: "Fluticasone Propionate Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Flutone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fluvastatin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fluvastatin Sodium",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Fluvoxamine Maleate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fluzone High-Dose Quadrivalent Northern Hemisphere", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Fluzone Quadrivalent", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Fluzone Quadrivalent Southern Hemisphere", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Flygiene Alcohol Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Flygiene Nourishing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fm-24 Foaming", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fml",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Fml Forte", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Fng", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Foam - X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foam 103", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foam Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Foam Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Foam Magic Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foam San Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foam-Bac Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamahol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamfresh Alco-Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamfresh Alco-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamfresh Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamfresh Dye Free Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamfresh E-2 Sanitizer Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamfresh E-2 Sanitizer Cleanser With Pcmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamfresh Healthcare Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Acne Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Advanced Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Foaming Alcohol Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Foaming Alcohol Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Antibacterial Green", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Antibacterial Hand Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Foaming Antibacterial Hand Wash",
        DoseTypes: [
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Foaming Antibacterial Handsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Chlorhexidine Gluconate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Foaming Hand",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Foaming Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Foaming Hand Sanitizer Refill Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Hand Sanitizer Refill Moisturizing Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Foaming Hand Wash",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Foaming Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Foaming Instant Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Mousse Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Mousse Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Non Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foaming Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamyiq E2 Sanitizing Handwash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamyiq Eucalyptus Mint Sanitizing Handwash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamyiq Healthcare Personnel Handwash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foamyiq Lemon Blossom Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foasu Jin Moisturizing Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foasu Jin Powderwash", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foasu Pink Y S Collagen Moisturizing Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foasu Pink Y S Moisturizing Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foasu Pink Y S Recoverycream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Focalin",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Focus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Focus Medical Oxygen", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Focus Spectrum Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Folditam", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Folic Acid",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Foliflex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Folitin-Z", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Folixapure", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Folks Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Folks Alcohol Free Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Folliculinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Follistim Aq", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Follow The Rainbow Antibacterial Hand Sanitizer Coconut Milk Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Follow The Rainbow Antibacterial Hand Sanitizer Sweet Berries Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Follow The Rainbow Antibacterial Hand Sanitizer Watermelon Ice Pop Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Follow The Rainbow Antibacterial Hand Wash Watermelon Ice Pop Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Folotyn", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Foltamin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Foltrate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Foltrexyl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Foma Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foma Antimicrobial Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fomepizole", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Fomica 7 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fondaparinux Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Fonow", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Food - Animal Products And Poultry Products, Beef Bovine Spp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Animal Products And Poultry Products, Chicken Meat Gallus Sp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Animal Products And Poultry Products, Egg, White Gallus Sp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Animal Products And Poultry Products, Egg, Yolk Gallus Sp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Animal Products And Poultry Products, Pork Sus Sp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Dairy Products, Casein, Cow Milk", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Dairy Products, Milk, Whole Cow", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Fish And Shellfish, Clam", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Fish And Shellfish, Codfish Gadus Callarias", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Fish And Shellfish, Crab Xiphosurus Sowerbyi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Fish And Shellfish, Lobster Homarus Americanus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Fish And Shellfish, Salmon Salmo Salar", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Fish And Shellfish, Shrimp Crago Sp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Fish And Shellfish, Tuna Thunnus Sp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Almond Prunus Amygdalus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Apple Malus Sp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Banana Musa Sapientum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Brazil Nut Bertholletia Excelsa", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Carrot Daucus Carota", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Cashew Nut Anacardium Occidentalie", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Celery Apium Graveolens", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Corn Zea Mays", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Hazelnut Filbert Corylus Spp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Melon, Cantaloupe Cucumis Melo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Orange Citrus Sinensis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Pea, Green Or English Pisum Sativum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Peach Prunus Persica", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Peanut Arachis Hypogaea", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Pecan Carya Illinoensis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Potato, White Solanum Tuberosum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Rice, Whole Grain", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Rye Grain", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Soybean Glycine Soja", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Strawberry Fragaria Chiloensis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, String Bean Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Tomato Nicotiana Spp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Walnut, Black Juglans Nigra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Yeast, Baker Saccharomyces Cerevisiae", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Food - Plant Source, Yeast, Brewer Saccharomyces Cerevisiae", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Food Additives",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Food Poisoning Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Foods", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Foodservice Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foot Care", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foot Care Clotrimazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foot Hangover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foot Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foot Saver", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foot Soak", DoseTypes: [{ DoseType: "Crystal", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Footworks", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "For Good Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forane", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Fordagel Kit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever 24H Wear High Perfection Skin Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Comfort And Care", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever 24H Wear High Perfection Skin-Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Comfort And Care 1,5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever 24H Wear High Perfection Skin-Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Comfort And Care 2,5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever 24H Wear High Perfection Skin-Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Comfort And Care 6,5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever 24H Wear High Perfection Skin-Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Comfort And Care 7W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 0.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 00", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 0Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 0N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 0W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 1.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 1.5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 1Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 1N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 1W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 2.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 2.5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 2Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 2N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 2W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 2Wo", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 2Wp", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 3.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 3C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 3Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 3N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 3W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 3Wo", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 3Wp", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 4.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 4.5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 4C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 4N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 4W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 4Wo", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 4Wp", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 6.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 6.5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 6N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 6W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 7.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 7N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 7W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 8N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Foundation Perfection And Hydration Concentrated Floral Skincare With Suncreen Broad Spectrum Spf 15 9N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Perfection Skin Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Hydration", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Perfection Skin-Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Hydration 1,5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Perfection Skin-Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Hydration 2,5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Perfection Skin-Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Hydration 6,5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Glow 24H Wear Radiant Perfection Skin-Caring Foundation With Sunscreen Broad Spectrum Spf 35 Lasting Hydration 7W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Skin Veil Extreme Wear And Moisturizing Primer Correction, Protection, Illumination With Sunscreen Floral Extract-Enriched Broad Spectrum Spf20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 0.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 00", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 0Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 3Wo", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 3Wp", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 4.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 4.5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 4C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 4N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 4W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 4Wo", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 4Wp", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 6.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 6.5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 6N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 6W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 7.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 7N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 7W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 8N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof - 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 9N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 0N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 0W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 1.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 1.5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 1C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 1Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 1N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 1W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 2.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 2.5W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 2Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 2N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 2W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 2Wo", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 2Wp", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 3.5N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 3C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 3Cr", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 3N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forever Transfer-Proof 24H Foundation High Perfection Concentrated Floral Skincare With Sunscreen Broad Spectrum Spf 15 3W", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forfivo", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formalbee Daily Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Formaldehyde", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formalinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica 10X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica 20X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica 2X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica 60 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Formica Apis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Formica Arnica",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Formica Ex Animale 3/15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formica Ex Animale 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Formica Oxalis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Formica Rufa",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Formica Stibium Comp. Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formicum Acidum", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formiplus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formoterol Fumarate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Formula 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formula 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formula 2 Skin Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Formula 3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Formula 3 Cough Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formula 303", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formula 7 - The Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Formula 7 Thegel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Formula Em", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Formula45 Arthritis Blend", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forpro Professional Collection Alcohol-Free Foaming Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fors Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fortaderm Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fortamet", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Forteo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Fortera360 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fortesta", DoseTypes: [{ DoseType: "Gel, Metered", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forticept Antifungal Foot Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forticept Antimicrobial Hydrogel Wound Dressing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forticept Hp Antiseptic Wound Cleanser", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forticept Wounds And Burns Hydrogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fortify", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fortinia",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fortinia Id", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fortinia Of", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forto", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Forto Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fortress", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fosamax", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fosamax Plus D", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fosamprenavir Calcium",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fosaprepitant", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Fosaprepitant Dimeglumine", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Foscarnet", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Foscarnet Sodium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Foscavir", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Fosfomycin Tromethamine",
        DoseTypes: [
            { DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fosinopril Sodium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fosinopril Sodium And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fosphenytoin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Fosphenytoin Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    {
        Medication: "Fosrenol",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fossaoprin Spg2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fossaoprin Spg4", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fote Cabana Beach Club With Blood Orange And Essential Oils Spf 8 Dry Tanning Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fote Cabana Beach Club With Manuka Honey And Essential Oils Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fote Cabana Beach Club With Manuka Honey And Essential Oils Spf 8 Dry Tanning Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fote Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fote Spacology Skin Protectant", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fote Sport Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fote Unscented Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fotivda", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Foundation", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foundation Broad Spectrum Spf 35 Cooly Beige", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foundation Primer Plus Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foundation Sunscreen Face Broad Spectrum Spf 38", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foundation Sunscreen Face Broad Spectrum Spf 38 Kona Gold", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Foundcealer Multi-Tasking Foundation Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fourpainrx", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fourth Chakra (Heart)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Foxy Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fq Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fragaria Vesca", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fragaria Vitis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fragmin", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Fragrance Free Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fragrance Free Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Frankie Avalons Zero Pain Roll On", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frankincense And Myrrh", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frankincense And Myrrh Arthritis Pain Relief", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frankincense And Myrrh Arthritis Pain Therapy", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frankincense And Myrrh Carpal Tunnel", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frankincense And Myrrh Neck Shoulder And Knee", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frankincense And Myrrh Neuropathy", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frankincense And Myrrh Sciatica", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Franklynumb 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Franklynumb 2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Franklynumb 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fraxinus Americana", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fraxinus Americana Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Fraxinus Velutina Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Fre", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fre Protect Body Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freamine Iii", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Freds", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Freds Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freds Effervescent Antacid Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Freds Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freds Saline Twin Pack", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Free And Clear", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Free Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Free Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Free Planet Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Free Radical", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Free Radistat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Free To Breathe", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freeman Packaging Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freeze Cooling Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freezin Cold Toast", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freezone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fremont Cottonwood Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "French Lavender Home", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "French Secret Spf - 15 Day Broad-Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frenna Ac", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Oral; Topical" }] }] },
    { Medication: "Frescosal Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresenius Propoven 2%", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Fresh Basics Antibacterial Hand Sanitizing", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Basics Disinfectant Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Basics Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Breeze", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fresh Citrus Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fresh Clean Mask", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Hand Antiseptic Cleaner", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Hand Foaming Hand Sanitizer Alcohol Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Hand Foaming Hand Sanitizer Non-Alcohol Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Hands", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Lemons Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Linen Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Mint Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh N Pure Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh N Soft Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Peach Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Start", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Sugar Honey Tinted Lip Treatment Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Sugar Lip Treatment Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Sugar Petal Tinted Lip Treatment Spf 15", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Sugar Roll-On Deodorant", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Sugar Rose Tinted Lip Treatment Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Water", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Water Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Water Aromar Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fresh Water Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freshands Instant Foaming Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freshands Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freshenup Fluoride Cool Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Freshenup Fluoride Double Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Freshenup Fluoride Fresh Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Freshenup Fluoride Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Freshkote Preservative Free Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Freshmax Fluoride Minty Flavor", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Freshmax Fluoride Regular Flavor", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Freshmint Anticavity Fluoride",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Freshmint Premium Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Freshmint Premium Anticavity Gel", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Freshmint Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Freshorize Hand Sanitizer Moisturizing With Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Freshquitas Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Freshscent Anti-Perspirant Deodorant",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Frida", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frida Mom", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frida Mom Labor And Delivery Plus Postpartum Recovery", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Frida Mom Perineal Cooling Pad Liners", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frida Mom Perineal Healing", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frida Mom Postpartum Recovery Essentials", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fridababy Fartfreeing Gas Drops 6Ct 0.13Mlea", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Frisky Cinnamon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frisson By Olive", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "From Earth Penetrating Menthol Pain Relief Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "From Earth Therapeutic Menthol Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frontman Fade", DoseTypes: [{ DoseType: "Cream, Augmented", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frost Lidocaine With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frosted Berries Scented Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frosted Cranberry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frosted Mint", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frosty Green Antibacterial Foam Hand", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frova", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Frovatriptan", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Frovatriptan Succinate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Frozen Fresh Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frozen Ii Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frozen Ii Winterberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frozen Lavender Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frozen Orange Blossom Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frozen Vanilla Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Frst1 Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fruit Of The Earth",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fruit Of The Earth Aloe Vera Cool Blue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Block Up", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cabana Beach Club Lip Balm With Blood Orange And Essential Oils Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cabana Beach Club Lip Balm With Manuka Honey And Essential Oils Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cabana Beach Club Lip Balm With Mentha And Essential Oils Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cabana Beach Club With Blood Orange And Essential Oils Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cabana Beach Club With Manuka Honey And Essential Oils Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cabana Beach Club With Mentha And Essential Oils Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cabana Beach Clubwith Blood Orange And Essential Oils Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cool Blue Aloe Mist Continuous", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Cool Blue Aloe Vera Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Grapefruit Scented Antibacterial Hand With Canadian Willowherb", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Honeysuckle Scented Antibacterial Hand With Canadian Willowherb", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Lip Balm Spf 30 Sunscreen Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Meyer Lemon Scented Antibacterial Hand With Canadian Willowherb", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Spacology Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Sun Town City Aloe Vera After Sun With Lidocaine Hcl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fruit Of The Earth Sun Town City Baby Spf 50 Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fruit Of The Earth Sun Town City Dark Tanning Dry Oil Spf 8 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Sun Town City Dark Tanning Spf 8 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Sun Town City Kids Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fruit Of The Earth Sun Town City Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fruit Of The Earth Sun Town City Spf 30 Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Fruit Of The Earth Sun Town City Spf 50 Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fruit Of The Earth Sun Town City Spf 50 Zinc Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fruit Of The Earth, Inc.",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fsk-5", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fubu Antiseptic Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fucidin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fucus Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Fucus Vesiculosus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ful-Glo", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Full Circle Ppe Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Full Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Full Spectrum Matte Ambition Skin Primer Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fulphila", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Fulvestrant",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    { Medication: "Fumaria Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fungablend 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fungi Nail Anti-Fungal Pen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fungi Nail Toe And Foot",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fungi Nail Toe And Foot Anti-Fungal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungi Nail Toe And Foot Pen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fungicure",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fungicure Anti-Fungal Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungicure Anti-Fungal Liquid 25%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Fungicure Liquid Gel",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Fungicure Manicure Pedicure Anti Fungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungicure Manicure Pedicure Liquid Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungifoam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Funginix Af", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Funginix Anti-Fungal Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Funginix Jock Itch Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungisode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fungoid Tincture", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fungus Fighter",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fungus Free", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungus Master Fungal Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fungus Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fungustat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fungway Fungus Clearing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Funiculus Umbilicalis 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Funiculus Umbilicalis 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Fuosemide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Furadantin", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Furosemide",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fusarium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Fusarium Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Fusarium Moniliforme", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Fusarium Oxysporum Vasinfectum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Fusarium Solani", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Fusilev",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Fusion Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fusion Antiseptic Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fustin Alcohol Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Future Time", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Futurist Hydra Rescue Moisturizing Makeup Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fuzeon", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Fuzion Ab Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fuzion Antimicrobial Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fvjia Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fyarro", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Fyavolv", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Fycompa",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Fyenacel Calming Shooter Cellandir Hair Loss-Shampoo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fylnetra", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Fyremadel", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Fyzical Sports Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fyzical Sports Pain Relief 1-Cool", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Fyzical Sports Pain Relief 2 Mild", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "G And H Protect Plus Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "G And Y Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "G Tussin Ac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "G-1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-10", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-11", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "G-12",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "G-13", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-14", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-15", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-16", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-17", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-18", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-19", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-20", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-21", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-22", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-23", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-24", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-25", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-26", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-27", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-28", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-29", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-29 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "G-3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-31", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-32", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-33", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-34", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-35", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-36", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-37", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-38", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-39", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-4", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-40", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-41", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-42", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-43", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-44", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-45", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-46", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-47", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-48", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-49", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-5", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-50", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-51", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-52", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-53", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-54", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-55", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-56", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-57", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-58", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-59", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-6", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-60", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-61", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-62", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-7", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-8", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-9", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G-Dologen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "G-Inflamm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "G-Supress Dx", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "G-Tron", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "G-Tron Pediatric Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "G-Tusicof", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "G-Zyncof", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "G.M. Collin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "G02 Therapy", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "G03", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G05", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G06", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G09", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G10", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G11", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G12", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G14", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G16", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "G7 Flex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ga-68-Dotatoc", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Gaba",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] },
        ],
    },
    { Medication: "Gaba 300-Ezs", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Gaba Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gabapentin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gabgerm Antisec Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gabgerm Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gabitril", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gablofen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Gadavist", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Gadoterate Meglumine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Gaia Herbs Antiseptic Hand Rub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gaia Herbs Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gala Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Galafold", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Galantamine",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Galantamine Hydrobromide",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Galanthus Nivalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Galena Larix", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Galena Larix Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Galentic Triple Antibiotic Plus Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Galium Aparine", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gallbladder 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gallbladder Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gallbladder Liver Meridian Opener", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gallbladderpar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gallcill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gallic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gallicum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gallium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Gallium Citrate Ga-67", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Gallium Ga-68 Psma-11", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Galphimia Glauca", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Galzin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gamastan", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Gambels Oak Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Game Over Acne Drying Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gamer-X", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gamifant", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Gamma -12", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gammagard", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Gammagard Liquid", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Gammaked", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Gammaplex", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Gamunex-C", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    {
        Medication: "Ganciclovir",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }, { Route: "Intraventricular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Ganirelix Acetate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Ganmaoling", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Garb2Art Cosmetics Fairy Dust Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garb2Art Cosmetics Glitter-Rita Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gardasil", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Gardasil 9", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Gargle Dam", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Garlic",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Garnier Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Clean Blackhead Eliminating Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Green Labs Brightening Serum Pineac Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Green Labs Pore Perfecting Cannab Serum Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Green Labs Replumping Hyalumelon Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Moisture Rescue Lightweight Uv", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Ombrelle Spf 30 Face Ultra Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skin Renew Daily Moisture Anti Sun Damage", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skin Renew Dark Spot Hand Treatment Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skin Renew Miracle Skin Perfector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skin Renew Miracle Skin Perfector Antiaging Bb Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skin Renew Miracle Skin Perfector Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skin Renew Miracle Skin Perfector Combination To Oily Skin Bb Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skinactive Clearly Brighter Antisun Damage Daily Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skinactive Clearly Brighter Brightening And Smoothing Daily Moisturizer Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skinactive Moisture Bomb Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skinactive Moisture Rescue Actively Hydrating Daily Fragrance Free Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skinactive Moisture Rescue Broad Spectrum Spf 15 Sunscreen Oil Free All Skin Types Actively Hydrating Daily", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Skinactive Ultra Lift Antiwrinkle Firming Daily Moisturizer Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Ultra Lift Anti Wrinkle Firming Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Ultra Lift Deep Wrinkle Intensive Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garnier Ultralift Transformer Antiage Skin Corrector Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garrison Home", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garrison Home Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Garrison Home Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Gas Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gas Relief Drops Infants", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gas Relief Extra Strength", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gas Relief Extra Strength Cherry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gas Relief Simethicone 250 Mg Anti-Gas", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gas Relief Ultra Strength", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gas-Aid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gas-Aid Drops For Infants", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gas-Indigestion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gas-X",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Gasalia",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gasco Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gaspari Ageless Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gastric Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gastrobiz", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gastrocrom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gastrografin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral; Rectal" }] }] },
    { Medication: "Gastropanpar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gastropar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gastrotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gatifloxacin", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gator Chemical Company Wonder Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Gattex",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Gavilax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gavilyte - C", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gavilyte - N", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gavilyte G", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gavilyte-H And Bisacodyl", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gaviscon",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gavreto", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gazyva", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Gb-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gblv Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gc Hand Sanitizer Gel Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gc Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gc Holiday Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gc Jeremiah Brent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gc Kids Hand Sanitizer Gel Juicy Pear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gds-Calm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gea Cosmetics Labs Hand And Body Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gel Antibacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gel Antiseptic Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gel Cooling Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gel For Life Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gel Hand Sanitizer - Methylcellulose Formula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gel Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gel Relajante Cbd", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Gel-Kam Fruit And Berry", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gel-Kam Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gelato Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gelato Homecare", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gelato Neutral Ph", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gelato Perio Maintenance Rinse", DoseTypes: [{ DoseType: "Concentrate", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Gelato Topical Anesthetic",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Dental" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Gelfos-M", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gelmicin Hydrocortisone With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gelnique", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Gelrite", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gelsanitizerlav", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gelsemium", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gelsemium Bryonia",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gelsemium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gelsemium Semp", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gelsemium Sempervirens",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Gelsemium Sempervirens 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Gelsemium Sempervirens 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Gelusil", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gelynaquin Advanced Nf", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gemcitabine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Gemcitabine Hydrochloride", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Gemfibrozil",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gemmily", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Gems", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gemtesa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gen 7T", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gen7T Plus", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gencare Headache Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gencontuss", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gene 16 Whitening Factor", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Topical" }] }] },
    { Medication: "General Protection", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Generess Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Generic Drug",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Generlac", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral; Rectal" }] }] },
    { Medication: "Geneva Naturals Advanced Anti-Aging With Sunscreen Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Geneva Naturals Advanced Anti-Aging With Sunscreen Spf 20 Unscented", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genexa Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Acetaminophen Pm", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Allergy Care", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Arnica Pain", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Childrens Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Jet Lag", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Kids Allergy Care", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Kids Calm Keeper", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Kids Cold Crush", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Kids Senna Laxative", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Kids Sleepology", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Leg Cramps", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Motion Sickness Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Senna Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genexa Stress", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gengicure", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Gengraf",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Genital Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genlabs Strike Bac Antibacterial Handsoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genorthix Pain Relieving Roll On", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genosyl", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Genotropin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Genozol", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Genrx Anti-Bacterial Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genrx Daily Defense Skin Repairing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genrx Hand Sanitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genstat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gentak", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gentamicin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Gentamicin Sulfate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Gentamicin Sulfate In Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Gentamicin Sulfate Opth Solution", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Genteal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Genteal Tears", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Genteal Tears (Mild)", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Genteal Tears (Moderate)", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Genteal Tears Gel Drops", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Genteal Tears Moderate Preservative Free", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gentian Ginger Bitters", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gentian Violet", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gentian Violet 1%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gentian Violet 2%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Gentiana Absinthium",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gentiana Lutea (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gentle Beast Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gentle Beast Antiseptic Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gentle Lax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gentle Laxative",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gentle Overnight Laxative", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gentle Relief Stool Softner", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gentle Steps Vitamins A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gentle Steps Vitamins A And D Diaper Rash And Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gentle Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gentlecare Hand And Skin Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid Alcohol Cleansing Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid Antiseptic Hand Sanitizer Ethyl Alcohol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid Antiseptic Towelette", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid First Aid Antibiotic Bacitracin Zinc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid First Aid Antiseptic Pain Relieving Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid Hand Sanitizer Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid Hand Sanitizer With Cap Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid Hand Sanitizer With Pump Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine First Aid Insect Sting Relief Pad 1 Ct", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine Joe Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genuine Joe Cleaning Supplies Antibacterial Foam So Ap Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Genvoya", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Geodon",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    { Medication: "Geopathic Stress Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geotrichum Candidum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Geranium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geranium Maculatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geranium Robertianum (M)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gerbil Epithelia", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Geri Dryl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Dryl Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Kot", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Lanta Antacid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Lanta Antacid Antigas", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Lanta Maximum Strength", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Lanta Supreme Cherry", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Mox Antacid Antigas", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Pectate Peppermint Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Pectate Vanilla Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Geri-Tussin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gericare Docusate Sodium Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gericare Liquid Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gericare Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gericare Senna Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gericare Sorbitol Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Gerigard", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gerigentle-Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gerigentle-Good Morning", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gerigentle-Vitamins A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Geritrex Aspirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Germ A Stat", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Attack Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Germ Attack Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Germ Away", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Bloc Disinfecting Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Germ Bloc Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Germ Bloc Hand Sanitizer Foam Peppermint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Bloc Hand Sanitizer Spray/Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Buster Surface Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Clean", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Free 24 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Free Hand Sanitizer Travel Pack", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Free Topical Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Out Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Shield Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Stuff", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ War Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ War Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Wipes Alcohol Wipes Fresh Citrus", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Wipes Antibacterial Alcohol Wipes Fragrance Free", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ Wipes Antibacterial Wipes Fresh Lemon", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ-Away", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ-Free 24 Sanitizing", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ-X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ-X Advanced", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ-X Everywhere", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germ-X Soft Wipes Alcohol Free Antibacterial", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germa Manteca Ubre Plus (Red)", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germa Manteca Ubre Plus (Tin)", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germa Manteca Ubre Plus (Yellow)", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germa Snake Oil Bee Venom", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "German Cockroach", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Germaphobe Black Tea Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germaphobe Crisp Pine Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Germasafe",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Germasave", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germasave Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germfree 24 Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germfree Hand Sanitizer Babypowder Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germfree Hand Sanitizer Gel Babypowder Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germfree Hand Sanitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germfree24 Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germfree24 Sanitizing Body-Spray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germiderm Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germifree Antimicrobial Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germisept 70 Isopropyl Rubbing Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germisept Antibacterial Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germisept Antibacterial Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germisept Antibacterial Hand Sanitizing Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Germisept Antibacterial Hand Sanitizing Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Germisept Bran Antibacterial Moist", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germproof", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germs Are Stupid", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germs Be Gone Antibacterial", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germs Be Gone Antiseptic Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Germs Be Gone Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Germs Be Gone Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germstar Original Unscented", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germstar Premium Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germstarcitrus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germstargel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germstarluxe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germstarone", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germstarone Unscented", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Germstaroriginal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gesteira", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Get Clarity Foaming Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Get Mr Skincare Daily Protection Spf30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Get Mr. Butter Body Spf-30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Get Mr. The Active Mineral Body Spf-30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gett Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gett Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gettik Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Geum Gentian 1 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ggulba Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gh", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gh Flex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ghostmedica Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gi Clear P", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gi Distress", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gi Drainage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gi Flu Symptom Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giant Eagle", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Aloe Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Coconut Water Foaming", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Golden Amber", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Hand Sanitizer With Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Pecan Pumpkin Pie Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Red Berry Foaming", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Refreshing Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle Vanilla Bean Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle White Tea Foaming", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Eagle White Tea Foaming Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giant Ragweed", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Giant Ragweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Giant Wild Ryegrass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Gianvi", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Giapreza", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Giar Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giardia Lamblia Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gibberella Pulicaris", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Gibson Healthcare Buffered Hemostatic Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gilenya", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gillette Clear Advanced 5In1 Cool Wave", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Ocean Blast", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Pacific Tide", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Arctic Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Clear Shield", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Cool Wave", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Pacific Tide", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Power Rush", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Scent Xtend", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Skin Guard", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Sport Active", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Dri-Tech Wild Rain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clear Plus Scent Boost Cool Wave", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Clinical", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gillette Endurance Power Beads Cool Wave Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gilotrif", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gilphex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gilphex Tr", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Giltuss Allergy Plus",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Giltuss Bucalsep", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Giltuss Childrens Allergy Plus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Childrens Cough And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Childrens Cough And Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Childrens Multisymptom Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Giltuss Cough And Cold",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Giltuss D Allergy And Congestion", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Diabetic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Ex Expectorant", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Hbp Cough And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Honey Cough And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Honey Dm Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Multisymptom Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Severe Sinus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Giltuss Sinus And Chest Congestion", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Total Release", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Giltuss Tr", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gilway Antiseptic Hand Saniizer 3.8L", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gilway Antiseptic Hand Saniizer 8 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gim", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gimoti", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Ginger", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Ginger Armor Mineral Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ginger Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gingerbread House Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gingersbread House Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gingi-Aid Max Z-Twist 00", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Oral; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Aid Max Z-Twist 1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Oral; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Aid Max Z-Twist 2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Oral; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Aid Max Z-Twist 3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Oral; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Aid Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Oral; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Cotton Coil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Max 2-Ply", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Max Soft-Twist No 1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Max Soft-Twist No 2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Max Soft-Twist No 3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Max Z-Twist No 00", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Max Z-Twist No 1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Max Z-Twist No 2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Max Z-Twist No 3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingi-Pak Pellets", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "Gingicaine Fusion", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }, { Route: "Topical" }] }] },
    { Medication: "Gingicaine Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingicaine Gel Strawberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingicaine Gel Variety Pak", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingicaine Gel, Cherry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingicaine Gel, Chocolate Mint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingicaine Gel, Cotton Candy Flavor", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingicaine Gel, Mint Julep", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingicaine Gel, Pina Colada", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingicaine Liquid, Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental; Oral; Periodontal" }] }] },
    { Medication: "Gingimed", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ginkgo (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ginkgo Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ginsenggo", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Cutaneous; Topical; Transdermal" }] }] },
    {
        Medication: "Gio Solution Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Giorgio Armani Armaniprima Color Control Glow Moisturizer Broad Spectrum Spf 35 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Crema Nera Extrema Essential Defense Fluid Broad Spectrum Spf 14 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Designer Lift Smoothing Firming Foundation Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Designer Shaping Foundation Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Face Fabric Second Skin Nude Makeup Broad Spectrum Spf 12 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Lasting Silk Uv Foundation Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Luminessence Cc Color Control Bright Moisturizer Broad Spectrum Spf 35 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Maestro Fusion Makeup Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Maestro Glow Nourishing Fusion Makeup Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Maestro Summer Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani My Armani To Go Essence In Foundation Cushion Spf 23", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Power Fabric Long Wear High Cover Foundation Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Power Fabric Plus Weightless Matte Foundation Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giorgio Armani Regenessence High Lift Muti Firming Rejuvenating Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Giovanni - Antibacterial Hand Sanitizer - Fragrance Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gir The Guardian Gel Sanitize", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gir The Guardian Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Girls Rule The World Antibacterial Hand Sanitizer Berry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Girls Rule The World Antibacterial Hand Sanitizer Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Girls Rule The World Antibacterial Hand Sanitizer Watermelon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Girls Rule The World Antibacterial Hand Wash Berry Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Blurring Foundation Balm Bare Skin Perfector - Broad Spectrum Spf 15 Nude Amber", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Blurring Foundation Balm Bare Skin Perfector - Broad Spectrum Spf 15 Nude Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Blurring Foundation Balm Bare Skin Perfector - Broad Spectrum Spf 15 Nude Ginger", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Blurring Foundation Balm Bare Skin Perfector - Broad Spectrum Spf 15 Nude Gold", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Blurring Foundation Balm Bare Skin Perfector - Broad Spectrum Spf 15 Nude Honey", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Blurring Foundation Balm Bare Skin Perfector - Broad Spectrum Spf 15 Nude Porcelain", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Blurring Foundation Balm Bare Skin Perfector - Broad Spectrum Spf 15 Nude Sand", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Blurring Foundation Balm Bare Skin Perfector - Broad Spectrum Spf 15 Nude Shell", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Photo Perfexion Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Shade 130 Perfect Rose", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Amber", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Beige", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Brown", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Ginger", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Gold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Honey", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Porcelain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Sand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Shell", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Sienna", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givenchy Teint Couture Long Wearing Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Elegant Vanilla", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Givlaari", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Gj Chemical Antiseptic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Gl. Suprarenales Gl. Thyreoidea Hypophysis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gl. Suprarenales Gl. Thyreoidea Hypophysis 6X", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glacier Blue Antibacterial Foaming Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glad Skin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gladskin Eczema", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glamour Camper Spf-50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glancure Tooth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Glandula Thymus 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandula Thymus 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandula Thyreoidea 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandula Thyreoidea 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandula Thyreoidea 60 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandulae Supra. Lien Solutio Ferri Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandulae Suprarenales 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandulae Suprarenales 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandulae Suprarenales 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandulae Suprarenales 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandulae Suprarenales 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glandulae Suprarenales 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Glandulae Suprarenales Lien",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Glandulae Suprarenales Lien Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glanhealth Antimicrobial Wound And Skin Therapy", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glanhealth Hand Sanitizer Advanced", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glanhealth Hand Sanitizer Professional", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glanhealth Sanitizing Advanced", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glanhealth Sanitizing Bath Wipe Advanced", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Glanhealth Sanitizing Body Advanced",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Glanhealth Sanitizing Hand Wipe Advanced", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glanhealth Sanitizing Hand Wipes Advanced", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glanhealth Sanitizing Professional", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glassia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Glatiramer Acetate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Glatopa", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Glauco", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glaucocerinite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glaucoma", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glaucoma Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glautarakt", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glazed Pear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gleamist Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gleem", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gleevec", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gleolan", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gleostine", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gliadel", DoseTypes: [{ DoseType: "Wafer", Routes: [{ Route: "Intracavitary" }] }] },
    { Medication: "Glimepiride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gliocladium Viride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Glipizide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Glipizide And Metformin Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glipizide And Metformin Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glipizide Er", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glister Multi-Action Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glo Skin Beauty C-Shield Anti-Pollution Moisture Tint Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care 15Oz Gold Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care 15Oz Original Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care 2 Pack Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care 4Oz Vapor Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care 7.5Oz Antibacterial Hand Cucumber Melon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care 7.5Oz Antibacterial Hand Sweetpea Orchid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care 7.5Oz Original Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care 8Oz Hand Sanitizer -Original", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Alcohol Ethyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Alcohol Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Antibacterial Hand Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Antibacterial Hand Cherry Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Antibacterial Hand Cucumber Melon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Antibacterial Hand Georgia Peach", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Antibacterial Hand Lavender Chamomile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Antibacterial Hand Original", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Antibacterial Hand Sweet Pea Orchid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Antibacterial Hand Vanilla Bean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Gold Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Hand Sanitizer, Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Ice Cold Analgesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Instant 8Oz Hand Sanitizer - Original", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Instant Hand Sanitizer 1.8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Instant Hand Sanitizer 16Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Instant Hand Sanitizer 2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Instant Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Lemon Zest Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Ocean Mist Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Care Travel Ethyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Cosmetics Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Easy Prep Alcohol Prep Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Home Care 19Oz Antibacterial Dish Detergent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Home Care 19Oz Lemon Antibacterial Dish Detergent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Industrial", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Industrial Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Global Vigen Recare Calcium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glofil-125", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Glonoinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Glonoinum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gloperba", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glossier Solution Exfoliating Skin Perfector", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glossier Sun Glaze Gleaming Sunscreen Oil Broad Spectrum Spf 30 Water Resistant 40 Min", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glostrips", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Glovers Anti-Dandruff", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glovers Dandruff Control Med., Floral", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glovers Dandruff Control Medicine, Regular", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glovers Medicated Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glovers Medicated Anti-Dandruff Scalp", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gloves In A Bottle With Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Body Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Go Lip Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Oil Broad Spectrum Sunscreen Spf 50", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Radiant Moisturizer Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Screen Body Broad Spectrum Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Screen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Stick Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Town Hand Sanitizer 59Ml Gtl0001", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glow Town Hand Sanitizer236Ml Gtl0002", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glowing White", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gls-570 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glucag0N", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    {
        Medication: "Glucagen",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Glucagen Hypokit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Glucagon",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Glucoreg", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glucorekt", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glucosamine Cream Extra Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glucose Control", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glucose Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glucoseforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glucotrol", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glumetza", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glutathione", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gluten Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gluten Sensitivity Formula", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glutenstat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gly-Oxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gly-Sal 10-2 Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gly-Sal 5-2 Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glyburide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Glyburide And Metformin Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Glycerin", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Glycerin Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glycerin Liquid Laxative Enema(Kaisalilu)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Glycerin, Lidocaine, Petrolatum, And Phenylephrine Hydrochloride", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glycerol-Saline Control", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Glycine",
        DoseTypes: [
            { DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] },
            { DoseType: "Solution", Routes: [{ Route: "Irrigation" }] },
        ],
    },
    {
        Medication: "Glycolic Acid",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Glycophos", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Glycopyrrolate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Glycyrrhiza Glabra", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glydo", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glynase", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glyoxal Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glyreg", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Glyrx-Pf", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intramuscular; Intravitreal" }] }] },
    { Medication: "Glytone Acne 3P Treatment Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Acne Back And Chest Treatment", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Acne Bpo Clearing Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Acne Bpo Treatment Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Acne Clearing Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Glytone Acne Clearing Toner",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Glytone Acne Self-Foaming Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Acne Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Glytone Acne Treatment",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Glytone Acne Treatment Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Age Defense Uv Mineral Sunscreen Serum Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Daily Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Mineral Sunscreen Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glytone Sunscreen Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Glyxambi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gm Collin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gm Collin Puracne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gmc Medical Acne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gmw Gold (950G)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gmw-Gold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnaphalium Polycephalum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gnaphalium Stannum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gnc Antimicrobial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnc Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Anti Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Calamine Topical Suspension", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Caldyphen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gnp Ethyl Rubbing Alcohol 70 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Eye Drops Dry Eye Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gnp Eye Drops Long Lasting", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gnp Eye Drops Redness And Dry Eye Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gnp Eye Drops Seasonal Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gnp Eyedrops Maximum Redness Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gnp Fiber Therapy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gnp Gentian Violet 1%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Hydrogen Peroxide 3 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Idoinde Tincture 2% Mild", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Isopropyl Alcohol 50 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Isopropyl Alcohol 50 Percent With Wintergreen Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Isopropyl Alcohol 70 Percent With Wintergreen Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Isopropyl Alcohol 91 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Isopropyl Rubbing Alcohol 70 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gnp Lubricating Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gnp Mucus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gnp Pink Bismuth",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gnp Regular Strength Gas Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gnp Witch Hazel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Go Heal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Go Psp Hydrogen Peroxide 3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Go Smile", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Go Smile Luxury", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Goat Epithelia",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Goats Milk", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    {
        Medication: "Goby Peppermint",
        DoseTypes: [
            { DoseType: "Paste", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Gocovri", DoseTypes: [{ DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gojo Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Antibacterial Plum Foam Handwash With Biobased Content", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Antimicrobial Foam Handwash With Pcmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Antimicrobial Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo E2 Foam Handwash With Bak", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo E2 Foam Handwash With Pcmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo E2 Foam Sanitizing So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Gold And Klean Antimicrobial Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Luxury Foam Antibacterial Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Pcmx E-2 Sanitizing Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Pink Antimicrobial Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Premium Foam Antibacterial Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Rich Pink Antibacterial Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gojo Ultra Mild Antimicrobial Ltn Sp With Chloroxylenol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold And Sudsy", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Antiseptic", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Baby Cornstarch Plus", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Extra Strength", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Foot", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Hydrating Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Intensive Healing Anti Itch Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Intensive Relief Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Medicated Advanced Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Medicated Cracked Foot Skin Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Medicated Cracked Skin Fill And Protect", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Medicated Eczema Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Medicated Extra Strength Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Medicated Maximum Strength Foot", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Medicated Original Strength Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Medicated Pain And Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Original Medicated", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Ultimate 5-In-1 Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Ultimate Age Defense Hand Cream With Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Ultimate Cracked Skin Fill And Protect", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Ultimate Diabetics", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Ultimate Eczema Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Ultimate Eczema Relief Hand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Ultimate Neck And Chest Age Defense Cream With Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Bond Ultimate Psoriasis Relief Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Dynamics Skin Boosting Moisturizer Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Gold Eagle Motor Force Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Gold Ice Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Mountain Beauty Collagen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Mountain Beauty Drying", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Mountain Beauty Fungal Nail Eliminator", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Mountain Beauty Intensive Repair Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gold Topaz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Golden Cup Balm 1950", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Golden Ray Sunscreen (Deep Tinted)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Golden Rays Sunscreen (Light Tinted)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Golden Sunshine Far Infrared Cool Herbal", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Golden Sunshine Far Infrared Herbal", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Golden Sunshine Far Infrared Hot Herbal", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Golden Sunshine Herbal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goldenrod", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Goldessence Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goldessence Disinfecting Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goldies Pain Off", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goldshield 24", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Golytely", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gonadal Homeopathic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gonak Hypromellose", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gonal-F", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Gonal-F Rff", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Gonal-F Rff Redi-Ject", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Goniotaire", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Gonitro", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Good And Smart Honey Strawberry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Care Alcohol Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Care Isopropyl Alcohol", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Care Povidone Iodine", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Care Povidone Iodine Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Care Povidone Iodine Swabsticks", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Care Sterile Scrub Brush With Pcmx", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Care Surgical Brush With Nail Cleaner", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Clean Suds Antibacterial Foam Hand Green Tea Scented", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Clean Suds Antibacterial Foam Hand Lavender Scented", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Clean Suds Antibacterial Foam Hand Ocean Breeze Scented", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Clean Suds Antibacterial Foam Hand Rose Scented", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Day Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Guy Mens Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Hygiene Therapeutic Mouth Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Molecules Acne Foaming Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Allergy Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Neighbor Pharmacy",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy 24 Hour Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Good Neighbor Pharmacy Acid Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Adult Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy All Day Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Neighbor Pharmacy All Day Allergy D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Good Neighbor Pharmacy Allergy",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Antacid And Anti Gas Maximum Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Antacid Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Antibiotic And Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Arthritis Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Arthritis Pain",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Artifical Tears Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Intraocular" }, { Route: "Ophthalmic" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Athletes Foot Relief Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Childrens Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Childrens Fluticasone Propionate", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Childrens Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Cough Dm Er", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Day Time",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Dual Action Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Earwax Removal", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Neighbor Pharmacy Earwax Removal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Good Neighbor Pharmacy Eye Drops Original Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Good Neighbor Pharmacy Fast Acting Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Good Neighbor Pharmacy Fiber-Caps Fiber Laxative", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Fluticasone Propionate", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Good Neighbor Pharmacy Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Good Neighbor Pharmacy Heartburn Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Itch Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Jock Itch Relief Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Kids Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Lice", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Lice Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Loperamide Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Loratadine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Loratadine D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Lubricating Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Good Neighbor Pharmacy Maximum Strength Athletes Foot Relief Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Medicated Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Neighbor Pharmacy Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Good Neighbor Pharmacy Miconazole Nitrate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Neighbor Pharmacy Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Mucus Er", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Mucus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Mucus Relief Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Mucus Relief Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Nasal Decongestant",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Nasal Decongestant Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Nasal Four", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Good Neighbor Pharmacy Nausea Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Nicotine Mini", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Night Time",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Nose", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Pain Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Redness Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Good Neighbor Pharmacy Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Sleep Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Sport Sunscreen Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Tab Tussin Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Tabtussin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Neighbor Pharmacy Tussin",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Neighbor Pharmacy Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Tussin Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Neighbor Pharmacy Vagicaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Pharmacy Vitamin A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Neighbor Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Night Sleep", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense 24 Hour Allergy Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Good Sense Acid Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense All Day Allergy",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Good Sense All Day Allergy D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Aller Ease", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Good Sense Antacid",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Antacid Soft Chew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Anti Itch",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Good Sense Antidiarrheal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Arthritis Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Artificial Tears", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Good Sense Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Good Sense Athletes Foot",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Good Sense Burn Relief Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Cherry Benzocaine Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Cherry Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Childrens Allergy Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Childrens Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Childrens Mucus Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Childrens Mucus Relief Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Clear Lax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Cold", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Cold Max", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Complete Lice Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Sense Complete Lice Treatment Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Sense Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Day Time Night Time Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Good Sense Daytime",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Dual Action Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Endure", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Fiber Laxative", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Flu And Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Hair Regrowth Treatment",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Good Sense Headache Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Heartburn Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Good Sense Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Hot And Cold Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Hot And Cold Medicated Patch Small", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Good Sense Ibuprofen",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Good Sense Lansoprazole",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Levocetirizine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Lice Killing Creme Rinse", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Lubricant Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Good Sense Lubricant Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Good Sense Lubricating Plus", DoseTypes: [{ DoseType: "Solution, Gel Forming / Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Good Sense Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Sense Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Sense Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Good Sense Migraine Formula", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Mucus Er",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Good Sense Mucus Relief",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Mucus Relief Cold Flu And Sore Throat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Multi Symptom Flu And Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Good Sense Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Good Sense Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Nasal Decongestant Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Nasal Four", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Good Sense Nausea Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Good Sense Night Time",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Nighttime Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Nighttime Daytime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Sense Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Omeprazole And Sodium Bicarbonate", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Omeprazole Delayed Release", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Pain Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Good Sense Pain Relief Pm",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Ready To Use", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Good Sense Sever Day Time Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Severe Cold", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Severe Daytime", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Severe Daytime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Severe Daytime Cold And Flu Severe Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Good Sense Severe Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Severe Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Severe Tussin Cf Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Sf Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Sf Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Simethicone", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Good Sense Sleep Aid",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Good Sense Sleep Time",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Good Sense Stay Awake", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Suphedrine", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Good Sense Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Tussin Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Good Sense Ultra Strength Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Sense Vagicaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good To Go Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good To Go Grab And Go Sanitizing Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good To Go Sanitizing Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Good Vibes Only Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodearth", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodhands Hand Sanitizer Cool Rain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodoh Energycream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Alcohol Swabs", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Antacid Fruit Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Artifical Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Goodsense Artificial Tears", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Goodsense Athletes Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Athletes Foot Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Burn Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Cherry Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Extra Strength Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Extra Strength Cold And Hot Medicated", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Eye Drops Advanced Relief Moisturizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Goodsense Eye Drops Irritation Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Goodsense Eye Drops Original Formula", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Goodsense Fiber", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Maximum Strength Medicated", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Oral Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Powder Jock Itch Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Redness Relief Plus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Goodsense Regular Strength Antacid Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Spf 30 Sport Cspray Comfort Can", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Spf 50 Cspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Spf 50 Sport Comfort Can", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Sport Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Sport Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Sunscreen Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goodsense Ultra Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Goodsense Ultra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Ultra Strength Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodsense Ultra Strength Antacid Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodwill Labs Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Goodys",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Goodys Back And Body Pain", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodys Extra Strength", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodys Headache Relief Shot", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goodys Pm", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Goose Feathers", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Goose Grease", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goose Grease Recovery And Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gopatch Hangoverrelief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gopatch Motion Sickness And Dizziness Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gopatch Tummyrelief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Goprelto", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Goryeodyo Goyak", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gossypium Herbaceum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gothan Grape Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gout", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gout Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Governing Vessel Conception Vessel Meridian Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Governing Vessel Conception Vessel Meridian Opener", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gp Enmotion Antimicrobial Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gp Pbu Antimicrobial Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gps Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gr Arthritis Pain Relief Roll-On 1500 Mg", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gr Bb Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grafco", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grain And Seed Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grain And Soy Allergen Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grain Mix System Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grain Smut Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Grain Timeless Gold Placenta", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Grain Timeless Perfection",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Grain Timeless Perfection Essence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grain Vita Fermented", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Gralise",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Grama Grass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Granatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grand Touch Antimicrobial Ltion So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grandma Els", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grandpas Thylox Acne Treatment With Sulfur", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grandway Hand Sanitizer 1.8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grandway Hand Sanitizer 16Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grandway Hand Sanitizer 2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grandway Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Granisetron", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Granisetron Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Granite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Granix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Granulotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grape Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grape Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grape Soda Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Grapefruit",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Grapefruit Hint Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Graphite Belladonna", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Graphites",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Graphites Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Graphites Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Graphitis", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grass Mix Number 4-S", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Grass Smut Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Grastek", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Graston Revive Post Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gratiola Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gravol",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gray (White) Birch Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Gray Malin Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gray Salt", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gray Salt Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Graydon Natural-Hydrating", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grease Magic Hand Saniitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Greasewood Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Great Calcium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Antibacterial Dish And Handsoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Apple Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Apple Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Green Ash Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Green Bamboo",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Green Beauty Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Care Safe Sanitizer Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Clean Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Clean Hand 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Clean Hand Water 70", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Compass Reduce Roll-On Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Compass Reduce Topical Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Defense Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Defense Daily Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Ekii Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green English Pea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Green F5 Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Fresh", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Fresh Plus A", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Goo Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Advanced Sinus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Allergy 2 Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Antideptic Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Antimicrobial Towelettes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Green Guard Antiseptic Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Green Guard Burn", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Cough And Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Cough Drop Relief", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Derma-Caine Burn", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard First Aid Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard First Aid Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Green Guard Honey Lemon Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Hydrocortisone 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Hydrogen Peroxide", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Ibupro Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Ibuprofen Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Isopropyl Alcohol 70%", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Green Guard Maximum Non-Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Maximum Strength Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Maximum Strength Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Pain And Ache Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Pms Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Green Guard Sterile Alcohol Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Super Stat Blood Clotting First Aid", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Topical Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Guard Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Island", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Island Sanitiser", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Key", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Koala Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Olive", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Green Pea", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Green Pepper", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Green Pump", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Remedy Cooling Roll On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Screen D Spf 35 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Screen Spf 31 Bronze Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Screen Spf 31 Neutral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Screen Spf 31 Nude Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Screen Spf 31 Peach Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Screen Spf 32 Original Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Supply Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Tea Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Tetterine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Green Tussin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Green World Hand Skin Sanitiser",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Greenberry", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Greencaine Blast", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Greenfrog Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Greenglo", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Greeniche Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Greensaltrain Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Grief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grief And Loss", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grime Stoppers Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grindelia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gripe Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grippe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grippe Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Griseofulvin",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Griseofulvin Microsize", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grisofulvin", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Grofully Mens Extra Strength Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grofully Womens Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Groomwell Weightless With Mineral Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Groovi Beauty Bubbly Bellini Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Groovi Beauty Fresh Baked Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Groovi Beauty Rainbow Sprinkles Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Groovi Beauty Spring Rain Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Groovi Beauty Toasted Vanilla Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grove Blood Orange", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grove Blood Orange Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Grow Girl", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Growth Hormone", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Growth Hormone Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gs Stomach Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gsd Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel, Metered", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gsd Anti Bacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gsd Defender Good Hygiene Defense Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gsd Good Hygiene Defense Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gsd Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gsd Santizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gsp Care Hand And Nature 71 Ethyl Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gtl0003 Hand Sanitizer 500Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guaiasorb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaiatussin Ac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Guaifenesin",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Guaifenesin 1200 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin 1200 Mg And Pseudoephedrine Hcl 120 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin 200Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin 400Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin 600 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin And Codeine Phosphate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin And Dextromethorphan Hbr", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Guaifenesin And Dextromethorphan Hydrobromide",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Guaifenesin And Dextromethorphan Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin And Pseudoephedrine Hcl", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin And Pseudoephedrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin And Pseudoephedrine Hydrochloride Extended Release", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Guaifenesin Dm",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Guaifenesin Extended Release 600 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin Extended-Release 1200 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin Extended-Release 600 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin Extended-Release Tablets, 600Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifenesin Syrup And Dextromethorphan", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guaifensin Extended-Release Tablets, 1200 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Guanfacine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Guanfacine Extended-Release", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guanfacine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guard", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guard Alcohol-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guard Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guard Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guardania Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guardex Sanitizing Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Guardh Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Guardh Instant Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guardian Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guardian Pink Bismuth", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guardian Regular Strength Alkums", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guardme Hand Sannitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guards Up", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Guerison All In One Tocx", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Guinea Pig Epithelia",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Gujin Bio Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gulf Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gum And Enamel Repair", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gum Arabic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Gum Cavity Prevention Fluoride Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gum Crayola Anticavity Fluoride Tropical Twist", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gum Detoxify", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gum Purify", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Gum Therapy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gumball Madness Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gumdrop Glitz Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gumnumb Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Gumsol",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Gun Powder", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Allergy", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Arthro Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Bowel Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Deep Cleanse", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Defense", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Digestion Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Female Support", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Flam Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Hay Fever Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Healthy Brain", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Kidney Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Liver Plus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Lympho Detox", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Male Support", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Matrix Detox", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Mood Support", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Osteo", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Pain Management", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Sinus Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Guna Sleep Support", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Stomach Plus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna Throat And Gums", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Allergy T", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Allergy-Prev", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Anti Il 1", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Arthro", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Awareness", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Bdnf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Bowel", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Cell", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Cough", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Dermo", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Digest", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Diur", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Dizzy", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Egf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Fem", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Fgf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Flam", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Flu", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Gcsf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Geriatrics", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Hemorrhoids", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Hypertension", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Igf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Il 10", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Il 11", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Il 12", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Il 2", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Il 3", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Il 4", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Il 5", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Inf Gamma", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Kidney", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Liver", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Lympho", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Male", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Matrix", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Mood", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Oral", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Pms", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Prostate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-React", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Reflux", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Rerio", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Rhino Nose", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Sinus Nose", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Guna-Sleep", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Spasm", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Stomach", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Tgf Beta 1", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Tonsils", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Guna-Virus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gunpowder", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gurunanda Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gustar Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gustar Clean Wet Tissue", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gustar Hand Sanitizer (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Gut Brain Spectrum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Gutong Tiegao Pain Relieving",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Gvoke Hypopen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Gvoke Kit", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Gvoke Pfs", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Gy-Na-Tren", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Gynazole 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Gyne-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gynestat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gynetox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gyno Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gynobalance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Gyun E Zero Sterilization Tissue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H Biotics", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H E B", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "H E B Max Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "H E B Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "H Plus Holdings",
        DoseTypes: [
            { DoseType: "Rinse", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "H- Eczema Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Acne Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Age Spots Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Arthritis Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Athletes Foot Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Balm Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Bi Hemorrhoids Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Clenz Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "H-Cold Sores Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-E-B Medicated Corn Starch Foot Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Fissures Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Gout Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Headaches Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Hemorrhoids Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Insomnia Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Jock Itch Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Joint And Muscle Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Moles Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Molluscum Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Nail Fungus Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Paxin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "H-Psoriasis Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Rosacea Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Scars Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Shingles Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Skin Tags Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Varicose Veins Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H-Warts Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H. Pylori", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "H. Pylori Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "H. Pylori Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "H. Pylori Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "H.E.B",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "H.E.B Baby", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H.E.B Banana", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H.E.B Mango", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H.E.B Solutions Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H.E.B Ultra", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H.E.B.", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H.E.B. Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H.E.B. Oil Free Daily Acne Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H2Ocean Nothing Tattoo Glide And Soothing Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H2One", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "H3N2 Influenza Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "H7 Complex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ha Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ha Physical Tint Spf 44", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ha2Cg Evolution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Habana Brisa Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Habana Brisa Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Habana Brisa Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Habana Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Habana Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Habana Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Habifac", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Habit",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Habitrol",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Habitrol Lozenge", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Haccp Q E2 Sanitizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Haccp Qe2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hackberry Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Had", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hae Yan Tooth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Haea Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Haegarda", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Haemonetics 0.9 % Sodium Chloride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Haemonetics Additive Solution Formula 3 (As-3)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Haemonetics Anticoagulant Citrate Dextrose Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Haemonetics Anticoagulant Citrate Phosphate Double Dextrose Solution (Cp2D)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Haemonetics Anticoagulant Sodium Citrate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Hailey 1.5/30", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hailey 24 Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hailey Fe 1.5/30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hailey Fe 1/20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hair Af", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hair And Nail Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hair Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hair Regrowth", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hair Regrowth Treatment",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hair Sanity", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hair Stim", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hair-Scalp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hair-X Hair Loss Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Haircare Physicians Recommended", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hairfx Hair Growth Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hairklyn Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hairna Care", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hairna Care Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hairna Care Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hal Hen Earwax Removal Aid Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Hal-Hen Earwax Removal System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Halaven", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Halcinonide", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Halcion", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Haldol", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Haldol Decanoate", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Hale Cosmeceuticals Complexion Perfection Sunscreen Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hale Cosmeceuticals Complexion Protection Sunscreen Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Halibut", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Hallo Iceland Angelica Herb Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hallo Iceland Kelp Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hallo Iceland Moss Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hallo Sapa Volcanic Ash Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Halls", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Halls Kids", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Halobetasol Propionate",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Haloderm Advanced", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Haloderm Regular", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Halodine Nasal Antiseptic",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Halog",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Haloperidol",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Haloperidol Decanoate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    { Medication: "Haloperidol Lactate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Halsted Health Sterile Prep Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hamamelis Aesculus",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hamamelis Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hamamelis Virginiana",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Hamamelis Virginiana 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hamamelis Virginiana 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hamamelis Virginiana Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hammer Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hampton Spf 15", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hampton Spf 8 Bronze", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hampton Sun 0.5% Lidocaine Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hampton Sun Spf 15 Silk", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hampton Sun Spf 30 Silk", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hampton Sun Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hampton Sun Spf 70", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hamster Epithelia",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Han Gel Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Han I Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Han-I-Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Han-I-Size", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Han-San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Han-San Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Han-San Gel With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Han-San Topical", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Han-San Topical 80", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hanbang Cataplasma", DoseTypes: [{ DoseType: "Poultice", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Hanbee Hand Sanitizer 70% 100Ml", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hanbee Hand Sanitizer 70% 500Ml", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hanbee Hand Sanitizer Moisturizing Serum 62% 100Ml", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand 2 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand 2 Mind Alcohol-Free Sanitizing Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand 2 Mind Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand And Care Clear Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand And Skin Shield", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand And Surface Anti Bacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand And Wrist Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Antiseptic Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Armor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Cleaner", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Cleaning Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Hand Cleanse", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Cleanse - Ocean Fresh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Cleanse - Strawberry Pomegranate", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Cleanser",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Essentials Instant Hand Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Goo", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Guard Foaming Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Guard High Foaming Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Hero Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand In Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Prep Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Protector", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Rx Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Rx Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Safe Hand Sanitizer Advanced Aloe Vera Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand San Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sani Gel U", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanit1Zing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitiser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitize Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Cutaneous; Topical" }, { Route: "Extracorporeal" }, { Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Cutaneous" }, { Route: "Extracorporeal" }, { Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer - Citrus Cucumber", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer - Italian Lemon And Lime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer - Lavenderrosemary", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer - Orange Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer - Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 1.8 Oz Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 1.8 Oz Lemon Ice", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 1.8 Oz Strawberry Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 1.8 Oz Watermelon Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 16Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 236Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 24Ct Pouch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 29Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 2Pk Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 2Pk Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 2Pk Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 5.0 60% Flowers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 59Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 60% Lv", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 63% - Lltt", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 70 Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 70 Alcohol Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 70 Alcohol Antiseptic Fresh Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 70% Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 70% Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 8 Oz. Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer 80 Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer 8Oz",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer Advanced Formula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Alcohol 80%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Alcohol Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Anti-Bacterial Hand Gel Aloe Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Anti-Bacterial Hand Gel Jasmine Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Anti-Bacterial Hand Gel Lavender Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Anti-Bacterial Hand Gel Rose Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Antibacterial Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Antibacterial With Moisturizers And Aloe 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Antibacterial With Moisturizers And Vitamin E 1.8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Antimicrobial Hand Rub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Antiseptic 75 Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Apple Tart Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Blue Berries Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Cactus Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Cherie Cherry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Cherry Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Cherry Frosting Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Cinnamon Apple Cider", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Citrus Blend", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Coconut And Lime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Crisp Autumn Leaves", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Cucumber And Melon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Easy Klean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer Foam",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer Fragrance Free 70 Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Fragrant Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Fresh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Fresh Kiwi Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Frosted Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Frosted Cranberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer Gel",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer Gel - 80% Ethyl Alcohol - Coconut Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Gel - Sparkling Citrus Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Gel Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Gel W/Vit E/Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Global Care (Ethyl)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Global Care (Isopropyl)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Hand Rub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Island Escape", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Isopropyl Alcohol Antiseptic 75", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Juicy Orange Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Juicy Pine Apple Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Kids", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Kiwi Avo Smoothie Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Lemon Lime Fizzer Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer Lemon Scent",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Liquid Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Liquid With Immunity", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Liquid With Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Liquid With Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer Lol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer Mango Papaya Popcicle Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Mint Cream Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Mist Lemongrass", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Mist Sea Salt", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Mist Watermelon", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Moisturizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Non Sterile", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Ocean Air", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Ocean Breeze", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Orange Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Original", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Peach Cream Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pen Frosted Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pen Sweet Pomegranate", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pen Whipped Vanilla", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Peppermint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pink Plumeria", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pomegranate And Vanilla", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pouch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pp106", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pumpkin Spice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pure Experience Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Pure Experience Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Rasp Berry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Refill 15 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Responsible", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Rinse Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sachet Packet", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Scented Gel With Aloe And Vit E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sling Honey Pear Cider", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sling Iced Peppermint Pine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sling Winter Berry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sling With Essential Oil Fresh Rosewater", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Solution 101", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Soothing Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sparkling Berry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sparkling Melon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sparkling Rose", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Spary", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Spraying", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Straw Berry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sugar Cookies", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Sweet Peach Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer Sweet Pomegranate",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer Toasted Vanilla", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Trimax Dino Liquid Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer U", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Upside Down", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Vanilla Ice Cream Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Vanilla Sugar Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Vitamin E And Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer W Travel Clip 1.8 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer W/ Carabiner (Lemon Scented)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer W/ Carabiner (Strawberry Scented)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer W/ Carabiner (Watermelon Scented)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Water Melon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Watermelon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Whipped Vanillas", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer White Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Wild Rasp Berry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer Wipe 10 Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Cutaneous; Topical" }, { Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer Wipes Pack With Plastic Dispensing Lid", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizer With Aloe",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Aloe Vera Sweet Berry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Aloe Vera Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Clip", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Glycerin And Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Moisturizers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Moisturizers And Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Moisturizing Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Ve", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer With Vitamin E And Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Coco Rico", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Cross The Lime", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Dolce Vita", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Flower Power", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Glitter Fever", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Hello Sunshine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Into The Wild", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Mystic Fruits", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Namaste", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizer, Unicorn Edition", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizing Gel Frota", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizing Spring Rain", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Sanitizing Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand Sanitizing Wipes 80Ct", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitizing With Lavender Oil", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitzer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Sanitzer Gel - Aloe Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Savior Radiance Boosting Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Screen Broad Spectrum Sunscreen Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Solutions Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Surface Spraysanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand Therape Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand Wash",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Extracorporeal" }, { Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Hand Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand-Aid", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hand-D Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hand-E Xl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand-E-Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand-E-San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand-I-San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand-Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hand2Mind Sanitizing Wet Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handclasp Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handclasp Isopropyl Alcohol Antiseptic 75%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handcream Anti-Bacterial", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handi Sani", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handi-Kleen Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handout Protection Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handpower Cleaner (Tm) Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handrub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hands First Tm",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Hands Free Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hands Fresh Antiseptic Hand Rub With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands On Hydrating Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands On Hydrating Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands Out Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands Puri L Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands Puri Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands Puri Plus Hand Sanitizing Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands Puri Q Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands Puri Q Hand Sanitizing Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hands Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handsoap", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handson Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handstand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handstand Fas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handstand Fs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handtastic Foamy Mango Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handvana Hydroclean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handworks E2 Foam Hand Cleaner", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Handy Andys",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Handy Antibac Antiseptic Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy Antibac Antiseptic Hand Sanitizng Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy Antibac Antiseptic Instant Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy Doctor Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy Pocket Sanitizer Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Handy San Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy Solutions 24 Hour Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Anti-Diarrheal", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Colgate Cavity Protection", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Handy Solutions Cool Mint Listerine Antiseptic Mouthwash", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Crest Cavity Protection Fluoride Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Crest Complete Multi-Benefit Whitening Plus Scope", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handy Solutions Instant Hand Sanitizer With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy Solutions Instant Hand Sanitizer With Vitamin E 70% Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy Solutions Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Handy Solutions Sinus Congestion And Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Handyman Freeze Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hangover Good To Go Moisturizer Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hangover Help", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hangover Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Hanna Labs Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hanpoong Beat Cold", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hanpoong Gal Cheon Xin Extract Granules", DoseTypes: [{ DoseType: "Bead", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hansan Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hansan Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hansor Antibacterial Hand Sanitizer With Aloe And Vitamine E 59Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hantai 8030 Ca-D3 Gold", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hantai 8030 Ca-D3 Premium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Happy Cappy", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Happy Elf Cbd Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Happy Elf Hemp Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Happy Farm Botanicals Protective Day Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Happy Hands Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Happy Happy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Happy Heart", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Happy Little Camper Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harba Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harbor Mist Alco-Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harbor Mist E2 Sanitizing Foam With Bzk", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hard Maple Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Harmon Core Values Epsom Salt", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Harmon Core Values Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harmon Daily Moisturzier", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harmon Face Values Blemishes And Blackheads Apricot Scrub", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Break-Out Free Spf 55 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Cherry Blossom Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Cucumber Melon Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Fresh Waters Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Minoxidil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Oil-Free Body Wash Salicylic Acid Acne Treatment Pink Grapefruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Pomegranate Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Ultra Light Dry To The Touch Spf 100 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Ultra Light Dry To The Touch Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Ultra Light Dry To The Touch Suncreen Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Ultra Light Dry To The Touch Sunscreen Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Ultra Light Dry To The Touch Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmon Face Values Warm Vanilla Sugar Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Flex 10.4% Menthol Pain Relief Spreay 1000Mg", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Flexible 4% Menthol Pain Relief Roll-On 1000Mg", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Flexible 10.4% Menthol Pain Relief 1000Mg", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Flexible 4% Menthol Pain Relief Cream-Gel 1000Mg", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Flexible Pain Relief Gel 1000", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Flexible Pain Relief Gel 500", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Flexible Pain Relief Lotion500", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Flexible Pain Relief Roll-On 1000", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Flexible Pain Relief Roll-On 500", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Flexible Pain Relief Spray500", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Neurocomfort 4% Lidocaine Pain Relief 1000Mg", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Neurocomfort 4% Lidocaine Pain Relief Roll-On 500Mg", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Neurocomfort Pain Relief Gel 1000", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Neurocomfort Pain Relief Gel 500", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Neurocomfort Pain Relief Lotion500", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Neurocomfort Pain Relief Roll-On 1000", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Neurocomfort Pain Relief Roll-On 500", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Hemp Neurocomfort Pain Relief Spray500", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony High Foaming Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Neur Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Neuro Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harmony Neurocomfort 4% Lidocaine Pain Relief Cream-Gel 1000Mg", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harpagophytum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Antibacterial Wipes Antiseptic Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Harris Teeter",
        DoseTypes: [
            { DoseType: "Oil", Routes: [{ Route: "Oral" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Harris Teeter Acetaminophen Pm", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Harris Teeter Acid Reducer",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Harris Teeter Acid Reducer Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter All Day Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter All Day Allergy D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Allergy And Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Harris Teeter Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Harris Teeter Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Harris Teeter Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Harris Teeter Anti Diarrheal",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Harris Teeter Arthritis Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Calamine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harris Teeter Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Childrens Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Daytime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Harris Teeter Earwax Removal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harris Teeter Hemorrhoidal", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Harris Teeter Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Harris Teeter Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Infants Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Iodine Tincture Mild", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harris Teeter Loperamide Hydrochloride", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Harris Teeter Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Harris Teeter Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Mucus Relief D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Harris Teeter Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Sinus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Sinus 12 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Sinus Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Harris Teeter Sleep Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Spring Fresh Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harris Teeter Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teeter Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Harris Teetter Calagesic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harrys Antiperspirant Fig", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harrys Antiperspirant Redwood", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harrys Antiperspirant Shiso", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harrys Antiperspirant Stone", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harrys Extra Strength Antiperspirant Fig", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harrys Extra Strength Antiperspirant Redwood", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harrys Extra Strength Antiperspirant Shiso", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Harrys Extra Strength Antiperspirant Stone", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Haruto Hangover Defencepatch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Harvoni",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hashtagsun Spf 50 Sunscreen Dabber", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hatch Mama Sunny Mama Body Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hatch Mama Sunny Mama Face Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Haute Protection High Protection Tinted Compact Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Haute Protection High Protection Tinted Compact Broad Spectrum Spf 50 Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Haute Protection High Protection Tinted Compact Broad Spectrum Spf 50 Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Havana Sun Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Havasu Hemp Soothing Pain Relief Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Havrix", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Hawaiian Airline Ec With Colgate Toothbrush And Sun", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hawaiian Natural Zinc Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Shaved Ice Lip Balm Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Sol Natural Sunscreen Spf-30", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hawaiian Tropic",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hawaiian Tropic Antioxidant Sunscreen Spf 30 Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Antioxidant Sunscreen Spf 50 Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Dry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Everyday Active High Performance Sport Sunscreen Spf30", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Everyday Active Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Everyday Active Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Everyday Active Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Everyday Active Sport Sunscreen Spf50", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Ilk Hydration Weightless", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Island Sport Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Island Sport Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Island Sport Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Island Tanning Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Island Tanning Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Island Tanning Clear Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Island Tanning Sunscreen Cocoa Butter Spf 4", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Lip Balm Sunscreen Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Matte Effect Mineral Enriched Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Matte Effect Mineral Enriched Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Mineral Milk Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Mineral Skin Nourishing Milk Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Mineral Tinted Facial Milk Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Mineral Translucent Sunscreen Powder Spf 30", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Sheer Touch Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Sheer Touch Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Sheer Touch Sunscreen Ultra Radiance Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Sheer Touch Ultra Radiance Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Silk Hydration Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Silk Hydration Clear Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Silk Hydration Clear Sunscreen Broad Spectrum Spf50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Silk Hydration Weightless", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Silk Hydration Weightless Clear Sunscreen Spf 15", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Silk Hydration Weightless Clear Sunscreen Spf30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiian Tropic Sport Sunscreen Everyday Active", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiin Tropic Island Tanning Spf 6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hawaiin Tropic Silk Hydration Dry Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hay Fever",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hay Fever 1", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hayfever Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hazel Alder Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Hazelnut Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Hbp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hc Max Anti Fungal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hc Max Anti-Fungal", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hc Max For Athletes Foot", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hc Max For Jock Itch", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hc Max Hongo Cura",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hcb-Res", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hcb-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hcg 30/15/8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hclear For Him", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hclo Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hcs Roll-On Antiperspirant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hdx", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hdx Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Head And Cold", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Head And Shoulder", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Head And Shoulders",
        DoseTypes: [
            { DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Head And Shoulders 2 In 1 Instant Moisturization", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders 2 In Renewing Cleanse", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders 2 In Soft And Manageable", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders 2In1", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders 2In1 Men Advanced Series Charcoal", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders 3 In 1 Men", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Aloe Vera 2In1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Aloe Vera Daily", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Apple Cider Vinegar 2In1", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Apple Fresh", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Calming Lavender", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Classic Clean Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Cleansing Cucumber", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Clinical Dandruff Defense Dry Scalp Rescue", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Coconut", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Coconut Daily", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Complete Scalp Care 2In1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Deep Moisture", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Deep Moisture Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Dry Scalp Care", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Hair Thickening System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Men Advanced Series Sage And Mint 2In1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Men Advanced Series Sandalwood", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Men Advanced Series Sandalwood 2In1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Moisturizing Avocado Oil", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Molding Clay", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Nourishing Hair And Scalp Care", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Nourishing Hair And Scalp Care 2In1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Nourishing Hair And Scalp Care Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Old Spice", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Refreshing Relief", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Renewing Cleanse", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Repair And Protect Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Royal Oils Daily Moisture Scalp", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Royal Oils Moisture Boost", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Royal Oils Seal And Prep Moisture Milk", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Scalp X Minoxidil Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Scalp X Minoxidil Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Styling", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Clarify And Volumize Scalp And Hair Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Color Protect", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Color Protect Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Detox And Hydrate", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Detox And Hydrate Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Detoxifying Pre-Wash Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Nourish And Smooth", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Nourish And Smooth Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Nourishing Scalp And Hair Mask", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Scalp Moisturizer With Hemp Seed", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Soothe And Strengthen", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Soothe And Strengthen Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Supreme Soothing Leave On Scalp", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Tea Tree Oil 2In1", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Tea Tree Oil Daily", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders Texturizing Putty", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shoulders White Charcoal Daily Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head And Shouldersvolume Boost Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head Cold", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Head Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Head Congestion And Flu Severe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Head Congestion And Mucus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Head Congestion Plus Flu Severe Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Head To Toe Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Head-To-Toe Favorites Mini Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Headache",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Headache Anxiety", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Congested", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Constipation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Drowsiness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Dry Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Ease", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Fatigue", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Flatulence", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Formula", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Insomnia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Man", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Nervousness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Pain And Sinus Pressure Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Headache Relief",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Headache Relief Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Restlessness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Rheumatism", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Sore Throat", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Spasms", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Stiff Neck", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache Vomiting", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headache-Migraine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headches Painful Menstruation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Headek Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Heal Grief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healer Lab Clean Up Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healer Lab Every Care S Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Healer Lab Safe Good Hand Gel",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Healexir", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing All-Purpose Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing Eczema Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing For Babies", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing Head To Toe", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing Pools Fresh Scent Antibacterial", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing Pools Moisturizing Aloe Antibacterial Wet Wipe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing Pools Moisturizing Aloe Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing Pools Moisturizing Aloe Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing Solutions", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healing Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healqu Warm Therapy Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healquick Tattoo Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Accents Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Care Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Care Personnel Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health E Alcohol-Free Antiseptic", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart 24 Hour Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Health Mart Adult Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Adult Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Alcohol Isopropyl Rubbing 70 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Allergy And Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Health Mart Allergy Complete D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Health Mart Allergy Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Allergy Relief Nasal 24 Hr", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Health Mart Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Health Mart Anti Diarrheal",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Anti Nausea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Arthritis Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Health Mart Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Childrens Allergy Plus Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Health Mart Childrens Allergy Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Childrens Cold And Flu", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Childrens Ibuprofen Ib", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Childrens Mucus Relief Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Cold And Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Health Mart Cough Dm",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Day Time Cold And Flu Night Time Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Health Mart Daytime",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Dual Action Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Famotidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Fexofenadine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Fiber Caplets", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Health Mart Hydrogen Peroxide 3 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Health Mart Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Ibuprofen Ib", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Iodides", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Iodine Tincture Mild", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Isopropyl Alcohol 91 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Lice Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Lidocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Health Mart Loperamide Hydrochloride", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Loratadine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Lubricating Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Health Mart Lubricating Tears", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Health Mart Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Mucus Relief D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Health Mart Nasal Decongestant Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Health Mart Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Health Mart Nicotine Polacrilex",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Night Time", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Night Time Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Nose", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Health Mart Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Health Mart Pain Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Health Mart Pharmacy", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Pharmacy Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Pharmacy Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Redness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Health Mart Regular Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Regular Strength Enteric Coated Aspirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Severe Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Severe Cold Cough And Flu", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Mart Witch Hazel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Mart Z Sleep", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Sense Ecpirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Sense Tactinal Regular Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Health Smart Aloe Vera Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Antibacterial Hand Cucumber Melon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Antibacterial Hand Lavender Chamomile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Antibacterial Hand Original", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Antibacterial Hand Spring Rain H S", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Antibacterial Hand Sweet Pea Orchid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Antibacterial Wet Wipes For Hands And Face", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Baby", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Baby Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Blue Ice Topical Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Cocoa Butter Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Creamy Cocoa Butter Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Creamy Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Hand Sanitizer Cucumber Melon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Hand Sanitizer Original", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Hand Sanitizer Peach", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Hand Sanitizer Sweet Pea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Lavender Baby Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Medicated Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Medicated Foot", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Original Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Smart Vaporizing Chest Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health Supply 770", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Health-Tec Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Healtha Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthmart Calcium Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healthmart Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healthmart Chest Congestion Relief Dmdm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healthmart Ear Wax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthmart Earwax Removal Kit Ear Wax Drops And Bulb", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthmart Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Healthmart Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healthmart Mineral Oil Single", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Healthmart Regular Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healthmart Saline Single", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Healthmart Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healthmart Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Healthwise Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthwise Lidocaine And Menthol Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthwise Lidocaine Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Healthy Accents Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthy Glow Sunless Tan Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthy Hair", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthy Hands Aloe Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Healthy Hands Aloe Instant Hand Antibacterial",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Healthy Hands Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthy Skin Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthy Skin Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthy Spirit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Healthy Start Weight And Appetite Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hearing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heart Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heart Small Intestine Meridian Opener", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heart Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heart/Fatigue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heart/Lung Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Heartburn",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Heartburn And Gas Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heartburn And Gas Fruit Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heartburn Fix", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heartburn Plus Gas Relief Chewable Multi Symptom", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Heartburn Prevention",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Heartburn Relief",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Heartex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heather", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heavy Duty Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Heavy Metal Detox",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Heavy Metal-Amalga Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Heb",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Heb 70 Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Antacid Calcium", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Antacid Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Anti Gas", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Anti Nausea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Broad Spectrum Spf 30 Banana Scented Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Broad Spectrum Spf 30 Coconut Scented Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Broad Spectrum Spf 30 Mango Scented Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Broad Spectrum Spf 30 Pomegranate Scented Sunscreen Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Calaclear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Calagesic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Camphor Phenol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Cherry Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Diarrhea Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Enamel Protection", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Heb Extra Stregth Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Heb Extra Strength",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Heb Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Extra Strength Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Extra Strength Smooth Antacid Assorted Fruits", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Extra Strength Smooth Antacid Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Heb Fiber Therapy", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Gas Relief Extra Strength Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Heb Gleaming White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Heb Hemorrhoidal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Heb Hemorrhoidal Cooling Gel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Honey Lemon Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Lime And Berry Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Heb Lubricant Eye Drops Dry Eye Therapy", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Heb Maximum Strength Laxative Pills", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Maximum Strength Pain Relief Hemorrhoidal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Medicated Cornstarch Body Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Mouth Sore Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Heb Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Ready To Use", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Heb Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Sensitive 50 Mineral Sensitive Skin Sunscreen For Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Sf Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Solutions", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heb Sore Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Heb Stomach Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Heb Stomach Relief Cherry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Water Drying Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Heb Wild Berry Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heb Wild Berry Antacid Chews 60Ct", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hectorol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Hedera Comp.", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heeltastic", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hei Gui Pain Relieving", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heilen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hekla Lava",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Helico 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helicobacter Pylori Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heliotrope", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Heliox", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Helium", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Intracoronary; Respiratory (Inhalation)" }, { Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Helium Oxygen Mixture", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Helium Oxygen Nitrogen Mixture", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Helium, Oxygen", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Helium/Oxygen Mix", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Helium/Oxygen Mixture 70/30", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Helium/Oxygen Mixture 80/20", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Helix", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Helix Cbd Clinical Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Helix Tosta", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helix Tri-Active Therapy Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Helleborus Berberis Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus Comp. A", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus Comp. B", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus Comp. C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus Comp. D", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus Comp. E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus E Pl. Tota 12", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus E Pl. Tota 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus E Pl. Tota 3 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus E Pl. Tota 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Helleborus Niger",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Helleborus Niger 12", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus Niger 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus Niger 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helleborus Niger 6X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hello",
        DoseTypes: [
            { DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hello Bello", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hello Bello Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hello Charcoal", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hello Naturally Fresh Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hello Unicorn", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hello Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hello, Sunshine Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hellotis Mouthwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helmin", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helminthosporium Sativum", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Helminthosporium Solani",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Helmm Antiperspirant Deodorant Coastline", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Helmm Antiperspirant Deodorant Hudson", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Helmm Antiperspirant Deodorant Night Market", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Helmm Antiperspirant Deodorant Trailblazer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heloderma", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Helonias Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Helonias Dioica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Helpmate Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hem Rid", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Hemabate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Hemacord", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Hemady", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemangeol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hematite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hematogen", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hematogen Fa", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hematogen Forte", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemaway", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hemcalm",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hemicare Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemlibra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Hemmorex-Hc", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Hemmorhoids", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemmorrhoids", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemoban", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hemodialysis Grade Sodium Bicarbonate Solution", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Hemodialysis" }] }] },
    { Medication: "Hemofil M", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hemoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemopropin Extra Strength Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal; Topical" }] }] },
    { Medication: "Hemopropin Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorex", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoid And Fissure", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoid Anesthetic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoid Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoid Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemorrhoid Master Hemorrhoid And Fissure", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hemorrhoid Relief",
        DoseTypes: [
            { DoseType: "Salve", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hemorrhoid Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoid-X Treatment Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral; Topical" }] }] },
    {
        Medication: "Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }, { Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Hemorrhoidal Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoidal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoidal Cream With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoidal Hygiene Pads", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Hemorrhoidal Relief",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Hemorrhoidal Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoidal With Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoidal-Ointment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemorrhoidalointment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hemorrhoidex",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hemorrhoids",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hemorrhoids Bruises", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemorrhoids Proctalgia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemorrhoids Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hemorroidal Cooling Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemostat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hemotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hemotreat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemox A", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hemp Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemp Pain", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemp Pain Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hemp Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hemp Pain Relief Roller", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemp Powered", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemp The Point", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemp With Flouride, Spearmint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hempcin Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemporrhoid", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemptuary Hawaii Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemptuary Hawaii Hand Sanitizer 16 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hempvana Cold As Ice",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hempvana Cold As Ice Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Endtag", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Gold", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Knee Bird Ultra Strength Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Maximum Strength Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Maximum Strength Pain Relief - Platinum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Ultra Strength Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Ultra Strength Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Ultra Strength Pain Relief Cream - Deep Freeze", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Ultra Strength Pain Relief Cream - Deep Heat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Ultra Strength Pain Relief Cream - Night", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hempvana Ultra Strength Pain Relief Gel - Arthritis", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hemrid Anorectal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hendel Fibromyalgia Symptom Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Homeopathic Calm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Indigestion And Bloating", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Insomnia Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Leg Cramp", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Liver Support", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Nerve Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Pain And Injury Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Periodontal And Tooth Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Psoriasis, Eczema, Seborrhea", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Rhus Tox", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Schuessler Cell Salts", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Sinus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hendel Stress, Burnout And Exhaustion Symptoms", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Henry Schein Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Henry Schein Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Henry Schein Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Henry Schein Mouth", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Henry Schein Povidone-Iodine Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Henry Schein Povidone-Iodine Swabsticks", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hepad S5", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hepagam B", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Hepapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Mag. Pulsatilla Tormentilla 6/12/30 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Magnesium 10X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Magnesium 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Magnesium 6", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Magnesium 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Magnesium Taraxicum 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hepar Stannum 17/20",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hepar Stannum 6/10",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hepar Sulf", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Sulfuris 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Sulfuris Sinus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Sulph Calc", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hepar Sulph Calc Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepar Sulph. Calc.", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hepar Sulphuris Calcareum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Hepar Sulphuris Calcareum 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Subgingival" }] }] },
    { Medication: "Hepar Sulphuris Calcareum 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Subgingival" }] }] },
    {
        Medication: "Heparin Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }, { Route: "Intravenous; Subcutaneous" }] },
        ],
    },
    { Medication: "Heparin Sodium And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Heparin Sodium In Dextrose", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Heparin Sodium In Sodium Chloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Hepastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepata-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepataforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepatamine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Hepataplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepatatox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepatic-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepatica Triloba", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepaticol Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepatinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepatinum 4Ch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepatitis C Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hepatitis Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hephzibah Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heplisav-B", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Hepsera", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Her Lh2473 Vanilla Scented Hand Sanitizer Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Her Lh2475 Vanilla Scented Hand Sanitizer Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Her Lh2476 Citrus Scented Hand Sanitizer Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Her Lh2484 Strawberry Scented Hand Sanitizer Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Her Lt279 Vanilla Limited Too Hand Sanitizer Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Her Lt288 Citrus Limited Too Hand Sanitizer Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Her Lt289 Vanilla Limited Too Hand Sanitizer Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Her Lt297 Strawberry Limited Too Sanitizer Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Heratburn Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herb Street Anti-Itch Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herb Street Arthritis Relief", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herb Street Backache And Sore Muscle Relief", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herb Street Sore Muscle Relief", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herba Purgo Hands Sanitizer Gel(Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbal Aloe Face And Body Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbal Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbal Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbal Dahuin Beauty", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbal Diuretic Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbal Freeze", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbal Healers Menthol Warming Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbal Menthol Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Herbal Power Alternative",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Herbal Sage Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbal Scent Aromar Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbal Utaplas External Analgesic Plaster", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbalife Herbal Aloe Face And Body Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbalife Skin Protective Moisturizer Broad Spectrum Spf 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbalife Skin Protective Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herbciti Archibone", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbciti Cell Detox", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbciti Immunies", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbciti Insulizen", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbciti Nephricare", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbciti Prostazen 180Caps", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbciti Prostazen 60Caps", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herbojoint-Us", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herceptin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Herceptin Hylecta", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Herclean Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herclean Ipa Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herkimer Diamond", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herklin", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herklin Lice Treatment", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 00 Amande", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 10 Coquillage", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 20 Sable", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 30 Ficelle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 40 Chanvre", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 50 Palomino", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 60 Sienne", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 70 Santal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 80 Barenia", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air Natural Enhancing Complexion Balm Spf 30 Sunscreen 90 Alezan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hermes Plein Air-Baume De Teint Perfecteur Naturel-Enhancing Complexion Balm 85 Tamarin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hero Cosmetics Rescue Mission Holiday Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hero Force Shield Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heroes Alcohol Antiseptic 80% Topical Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Heroes Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herpecin L", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herpecin Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herpes Simplex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herpes Simplex Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herpes Simplex Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herpes Sores", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herpes Virus No 6 Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herpes Zoster Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herpes Zoster Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herpeset", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herpetic Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herplex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hers Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hers Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hers Miconazole 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Hers Mineral Sunscreen Multi-Screen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hers Minoxidil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hers Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Herstat",
        DoseTypes: [
            { DoseType: "Lipstick", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Herstat Plus Cold Sore Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Herx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herz Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Herz Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Herzuma",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Hespan", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Hestia Clinic Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hetastarch In Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Hetlioz",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hevert Cold And Flu Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Contrallergia", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Cough Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Detox", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hevert Detox Intestinum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Detox Kidney", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Detox Liver", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Detox Lymph", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Sinus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Stress Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hevert Trauma Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hexadermal Antiseptic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hexashield Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hexatrione", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular" }] }] },
    { Medication: "Hextend", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Hey Humans Whitening Clean Mint Rush", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hey Humans Whitening Wintermint Chill", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Heys Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hfe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hfm Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hgh",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hgh Complex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hgh Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hgh Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hgh Vibrancy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hgh Vitality",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hgh Young Again", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hgh-Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hhr", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hhv-6 Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hi Vetic",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hi-Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Hi-Iq Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hi-Tech", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hi-Valu Antimicrobial Foaming Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hiberix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hibiclens", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hibiclens For Georgia Pacific", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hibiscus Rose Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hicell", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hickory Pecan Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Hickory Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Hickory-Pecan Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Hickory/ Pecan Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Hicon", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hidex 6-Day", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hielo Ardiente", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hielo Mineral", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Blue Flowers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel Fresh Lemon Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel Fresh Maracuja Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel Lemon Verbena Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Blue Flowers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Blue Flowers With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Damask Rose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Damask Rose With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads For Kids And Adults Bibo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads For Kids And Adults Bitty And Mitty", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads For Kids And Adults Dooh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads For Kids And Adults Filo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads For Kids And Adults Gito", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads For Kids And Adults Kitty", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads For Kids And Adults Mido", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads For Kids And Adults Nino", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Fragrance Free With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Gold", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Gold With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Green Tea And Cucumber", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Green Tea And Cucumber With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Jasmine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Jasmine With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Kiwi", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Kiwi With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Lemon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Lemon With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Marine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Marine With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Oud", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Oud With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Papaya", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Papaya With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Passion Flower", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Passion Flower With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Red Fruits", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Red Fruits With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Together In Love", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Together In Love With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Wild Berry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hand Sanitizer Gel With Vitamin Beads Wild Berry With Silicon Holder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hands And Things Sanitizer Blue Flowers", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hands And Things Sanitizer Fragrance Free", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hands And Things Sanitizer Lemon", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hands And Things Sanitizer Maracuja", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higeen Antiseptic Hands And Things Sanitizer Verbena", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "High Active Formula 75% Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "High Energy Echinacea Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "High Energy Ginseng Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "High Five Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "High Foaming Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "High Potency Heal Grief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "High Vitality", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "High Vitality Tab", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Higher Control Male Climax Control Lubricating", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higher Education Make Out Ready Moisturizing Lip Balm Spf 15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higher Education Rush Clear Complexion Wipes With 2% Salicylic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Higher Education Spring Break Oil-Free Broad-Spectrum Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Highpothecary Therapeutics Cbd Zing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hill Country Essentials", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hill Country Essentials Apricot Scrub Blemish And Blackhead Control", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hill Country Essentials Oil Free Daily Acne Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hill Country Fare Hand Sanitizer With Aloe Fragrance", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard Alcohol Free Foaming Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard Amb So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard Citrus Fresh Antimicrobial Foaming", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard Clean Assist Antibacterial So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard D.G.A. Plus Antimicrobial So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard D.G.A. Plus Foam Antimicrobial Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard E2 Food Industry Foaming", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard Foaming Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hillyard Nt Peach Antimicrobial Foam Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Himangchantoothpaste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hims Delay Wipes", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hims Hair Regrowth Treatment",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hims Mineral Sunscreen Multi-Screen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hims Minoxidil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hippozaeninum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hiprex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histamine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histamine Dhcl 0.10 Percent", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Histamine Dhcl 0.10 Percent And Menthol 2 Percent", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Histamine Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Histamine Positive",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Histaminum 30C Allergy Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Histaminum Hydrochloricum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Histaminum Hydrochloricum 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Histaminum Hydrocloricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histapoll Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histastat Food", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histastat Pollen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histenol Forte Ii", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histex", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histex Ac", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histex Pd Drops", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histex-Dm", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Histiacil Cough", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hives-Rashes",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hizentra", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Hk Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hki", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hlip", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hlu Pneumo Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hm-B (Edible Vinegar)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hn Essentials Hand Sanitizer Gel With Aloe Vera", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hoarse Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hobby Antibacterial Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hobby Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hoboco And Co French Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hoboco And Co Fresh Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hoboco And Co Lavender Sage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hoboco And Co Tea Tree", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hocleans Ipa Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hog Epithelia",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Holcus Lanatus Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Holiday Dust Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Holiday World", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Holy Water Mercy In A Bottle", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Homarus Gam", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homatropine Hydrobromide Ophthalmic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Home Care Fluoride", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Home Cher Sanitizer - Shield", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Farmacy", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Home Formation Antibacteria -Berry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Formation Antibacterial Coconut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Formation Antibacterial Coconut And Hibiscus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Formation Antibacterial Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Formation Antibacterial Ocean Breeze", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Formation Antibacterial Pomegranate And Mango", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Formation Antibacterial Shea Butter", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Formation Antibacterial Spring Shower", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Health Everclean Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Health Psoriasis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Home Health Psoriasis Medicated Scalp And Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Homehealth Antifungal", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Homeopathic Arnica Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeopathic Calm Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeopathic Lip Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Homeopathic Medicine Yeast Relief Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeopathic Moon Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeopathic Pick Up Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeopathic Remedy Kit 200C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeopathic Remedy Kit 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeopathic Stress Mints", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeoplex A", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeoplex B", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeoplex D", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeoplex F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeoplex G", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeoplex L", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homeoplex P", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Homewear Antibacterial Hand", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Homworks Alcohol Wipes Multipurpose", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Homworks Antibacterial Hand Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Honest Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Honest Eczema Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Honest Eczema Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Honest-Paste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Honest-Paste Kids", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Honey Bee Hymenoptera Venom", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Honey Bee Hymenoptera Venom Multidose", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Honey Bee Hymenoptera Venom Venomil Diagnostic", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intradermal; Percutaneous" }] }] },
    { Medication: "Honey Bee Hymenoptera Venom Venomil Maintenance", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Honey Bee Venom", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Honey Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Honey Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Honey Lemon Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Honey Lemon Cough Drops",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pastille", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Honey Lemon Sore Throat Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Honey Lemon Thoracol", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Honey Pot", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Honey Works", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Honeydew", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    {
        Medication: "Hongo Killer",
        DoseTypes: [
            { DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Hongocura",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hongocura Pinceladas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Honour Hand Care", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Honour Hand Care Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hoo Gargle (Jasmine Flavor)(Mouthwash)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hoo Gargle (Mouthwash)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hoo Gargle (Rooibos Tea Flavor)(Mouthwash)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Hope In A Jar", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hops Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hops Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Horizant", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hormone Balance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hormone Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hormone Harmony", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hormone Mix System Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hormoslim", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Horse Epithelia",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Hosp Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hot And Cold Lidocaine With Menthol Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hot And Cold Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hot Cold Lidocaine With Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hot Flash Rescue",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hot Flashes", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hot Pap Cataplasma", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    {
        Medication: "Hot Wheels Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hot/Cold Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hot/Cold Menthol 16% Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hotel G Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hourglass Equilibrium Day Fluid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "House Dust", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "House Dust Mite, Dermatophagoides Farinae", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "House Dust Mite, Dermatophagoides Pteronyssinus", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "House Fly", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Household Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Household Bleach Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Household Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Househould Cleaning Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Houston Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hp Axis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hpnp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hptp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hpyl Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hpyl Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hpz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hqla", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc100", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc62", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc62-Lm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc62-Lv", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc62-Rm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc70-Lm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc70-Lv", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsc70-Rm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsfb Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsus Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hsus Antiseptic Hand Sanitizer Ipa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hthy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Htyp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hua Tuo Xian Gao Antifungal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hug Perfume Sanitizer- Floral", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hug Perfume Sanitizer- Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Humalog",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Human Albumin Grifols", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Human Guard Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Human Papilloma Virus (Hpv)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Human Papilloma Virus Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Human Papillomavirus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Human Parvovirus Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Human Science Antifungal Foot Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Human Science Muscle Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Human Science Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humane Acne Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Humane Acne Wash",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Humate-P", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Humatin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humatrope", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Humble Brush Bamboo With Fresh Mint Flouride", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Humco 1 Percent Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Aromatic Ammonia Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Calaclear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Calagesic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Camphor Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Castor Oil (Regular)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Charcoal Activated", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Dewees Carminative", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Ear Drops For Swimmers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Humco Epson Salt", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Gentian Violet 1%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Gentian Violet 2%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Iodides Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Isopropyl Rubbing Alcohol 70%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Isopropyl Rubbing Alcohol 70% Professional Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Merthiolate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Mineral Oil Heavy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Mineral Oil Light", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humco Sodium Bicarbonate", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Strong Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Humco Witch Hazel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humetec Bergamot Hand Sanitizer Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humetec Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humetec Hand Sanitizer Gel - Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humetec Lavender Hand Sanitizer Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humetec Ocean Hand Sanitizer Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humetec Rosemary Thyme Hand Sanitizer Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humira", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Humphreys Alcoholado Maravilla", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humphreys Bendito Alcoholado", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Humphreys Maravilla", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Humulin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Parenteral" }, { Route: "Subcutaneous" }] },
            { DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Hunger Control", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hunter Beach 4%",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Huo Luo Pain Relieving", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hura Brasiliensis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hurley",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Hurricaine",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Dental; Oral; Periodontal" }, { Route: "Dental; Periodontal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Dental; Oral; Periodontal" }] },
            { DoseType: "Spray", Routes: [{ Route: "Dental; Oral; Periodontal" }, { Route: "Dental; Periodontal" }] },
        ],
    },
    {
        Medication: "Hush Anesthetic",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hush Hand Sanitizer With Aloe Vera Extract", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hustle Bubbles Deluxe", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hustle Helper Comfort Enhancing Anesthetic Antimicrobial Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hutch And Waldo Hand Sanitizer-Lavender, Clary Sage, Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Huureka Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hv 6 Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hwt Spf 30 Unscented Face Sunscreen 2Fl Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hy-G-Clenz Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hy-Iq Hand And Surface Disinfectant And Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hy-Iq Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hy-Iq Open Wound Care", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hy-Iq Sanitizer And Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hy-Iq Surface Cleaner", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyalolex Antiseptic Hand Rub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hyalolex Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hyalolex Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyalolex Hand Sanitizer With Lavender And Zinc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyalolex Hand Sanitizer With Lemon And Zinc", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Acid Collagen Essence Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf 30 100N Neutral Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 100C Cool Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 100W Warm Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 200C Cool Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 200N Neutral Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 200W Warm Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 300C Cool Medium Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 300N Neutral Medium Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 300W Warm Medium Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 400C Cool Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 400N Neutral Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 400W Warm Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 500C Cool Medium Dark", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 500N Neutral Medium Dark", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronic Hydra-Foundation Spf30 500W Warm Medium Dark", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyaluronidase", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Hyatt Hand Sanitizer 70% Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hycamtin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Hycodan",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hydra Balancing Rose Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydra Beaute Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydra Finish Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydra Pearl Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydra Pearl Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydra Pearl Wipe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydraboost Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydraboost Oil-Free Sunscreen Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydragen Pro Barrier Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydraguard-D", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydralazine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydralazine Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hydralazine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hydrangea Arborescens", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydraphex", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrastis", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Hydrastis Canadensis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Hydrastis Canadensis 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hydrastis Candadensis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydrate 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydrating Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Antioxidant Moiturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Duo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Face Wash", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Glow Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Healing Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Lip Balm Sunscreen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Night Salve", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Sheer Lip Balm Spf 30 - Nude", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Sheer Lip Balm Spf 30 - Pink", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Sheer Lip Balm Spf 30 - Red", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrating Sheer Lip Balm Spf 30 - Trio", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hydration Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydration Invisiblur Perfecting Shield Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrator Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrea", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydrel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydro Alcohol Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydro Multi-Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydro-Q", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hydroclean Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hydroclean Hand Sanitizer (Max)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocodone /Apap", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydrocodone Apap", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hydrocodone Bitartrate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hydrocodone Bitartrate And Acetaminophen",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hydrocodone Bitartrate And Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hydrocodone Bitartrate And Homatropine Methylbromide",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hydrocodone Bitartrate And Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hydrocodone Polistirex And Chlorpheniramine Polistirex", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hydrocortisone",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Enema", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hydrocortisone 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hydrocortisone 1%",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hydrocortisone 1% With Healing Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hydrocortisone Acetate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Hydrocortisone Acetate Pramoxine Hcl", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hydrocortisone Acetate Pramoxine Hydrochloride",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Hydrocortisone Acetate, Iodoquinol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone And Acetic Acid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Hydrocortisone Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone Anti-Itch Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hydrocortisone Butyrate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hydrocortisone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone Cream 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone Cream 1% With Aloe Vera", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone Cream Intensive Healing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone Iodoquinol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hydrocortisone Maximum Strength",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hydrocortisone Maximum Strength With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone Rectal Suspension (Retention)", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Hydrocortisone Valerate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hydrocortisone Valerate Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone With Aloe Maximum Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocortisone, Iodoquinol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrocotyle Asiatica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydrocyanicum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hydrofluoricum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hydrogen Peroxide",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Dental; Oral; Topical" }, { Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral; Topical" }, { Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hydrogen Peroxide 3 Topical", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrogen Peroxide 3%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrogen Peroxide Topical Solution U.S.P", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrolatum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydromet", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hydromorphone Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hydropeptide S.P.F. Sun Protection Friends", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hydropeptide Solar Defense Hand Guard", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydropeptide Solar Defense Mineral", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydrophor", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydroquinone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydroquinone 4%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydroquinone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydroquinone Skin Bleaching Agent Normal Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydroquinone Skin Bleaching Agent Oily Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydroseptine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hydroxocobalamin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Hydroxychloroquine Sulfate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hydroxyprogesterone Caproate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    { Medication: "Hydroxyurea", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hydroxyzine",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hydroxyzine Hcl",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hydroxyzine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hydroxyzine Pamoate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hyftor", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygeia Effect Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygenix Af Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygenix Af Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hygenize Original Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hygiee Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Balsam Fir Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Balsam Fir Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Blueberry Lemonade Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Blueberry Lemonade Hand Sanitizer Car Safe Alcohol-Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Bubble Gum Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Clean Cotton Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Eucalyptus Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Lavender Blossom Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Lavender Blossom Hand Sanitizer Bottle Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Leather Oak Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Leather Oak Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Lilac Garden Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Lilac Garden Hand Sanitizer Bottle Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Luxury Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Luxury Clean Hand Sanitizer On The Go And Car Safe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Mahogany Teakwood Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Mahogany Teakwood Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Pumpkin Spice Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Pumpkin Spice Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Spring Bloom Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Spring Bloom Hand Sanitizer Bottle Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Unscented Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Unscented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Vanilla Sugar Hand Sanitizer On The Go And Car Safe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Clean Vanilla Woods Hand Sanitizer Car Safe Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Hero", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Of Sweden", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Wipes (Alcohol)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygiene Wipes (Non-Alcohol)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygienepoint Advance Antibacterial F Oam Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Fluid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Fluid Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Fluid Healthcare Grade", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Fluid Healthcare Grade Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Fluid Healthcare Grade Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Fluid Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Gel Fresh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygieni-Klean Hand Sanitizer Gel Healthcare Grade", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hygieni-Klean Hand Sanitizer Gel Healthcare Grade Fresh",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Hygieni-Klean Hand Sanitizer Gel Healthcare Grade Lavender",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hygieni-Klean Hand Sanitizer Gel Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygienic Ear Care Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygienic Instant Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Hygienigel",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution, Gel Forming / Drops", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Hygienium Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygienium Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hygipart Antiseptc", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hylafem", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Hylanson Alcohol Wipes 13Cmx19Cm", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hylanson Alcohol Wipes 5.9Inx7.9In", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hylanson Antiseptic Wipes 75 Alcohol 60Mmx80Mm", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hylatears", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Hylenex Recombinant", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Hynaut Alcohol Prep Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hyoscyamine",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hyoscyamine Sulfate",
        DoseTypes: [
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Oral; Sublingual" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hyoscyamine Sulfate Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hyoscyamine Sulfate Extended-Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hyoscyamine Sulfate Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hyoscyamine Sulfate Sl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hyoscyamine Sulfate Tab", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hyoscyamus 2 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hyoscyamus Niger",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hyosyne",
        DoseTypes: [
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Hyperactivity-Mental Focus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hyperbaric Chamber Mix", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Hypercalm-Mental Focus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hyperhep B", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Hyperhidrosis Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypericum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypericum 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypericum 6 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hypericum Bryophyllum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hypericum Ex Herba 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypericum Ex Herba 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypericum Kali Phos.", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypericum Perf", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypericum Perf Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hypericum Perforatum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Hypericum Perforatum 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Hypericum Perforatum 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Hyperrab",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Intramuscular" }] },
        ],
    },
    { Medication: "Hyperrho", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Hypert", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypert Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypertension Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypertet", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Hyperthyroid Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypex Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hypochlorous Acid Disinfecting", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hypochlorous Acid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hypogastric Nerve Plexus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypoglycemx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hypophysinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hypophysis 7 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypophysis Comp.", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypophysis Mel Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypophysis Stannum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypopituitaryplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Hypothalamus",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Hypothalamus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypothalamus/Adrenal/Pituitary Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypothalmapath", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypothalmu Stim", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypothalmupar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypothaltem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hypotonia Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hyqvia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Hyrdrocodone Apap", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hyronan", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Topical" }] }] },
    { Medication: "Hysan Analgesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hysan Hua Tuo Huo Lu", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hysan Hua Tuo Medicated", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hysan Huo Lu Medicated", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hysan Pain Reliever", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hysingla Er", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hysteria Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hyvee Blemish Controlling Apricot Scrub", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyvee Care Classic Clean 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyvee Care Classic Clean Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Hyzaar", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hz Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hz Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hz Neurapain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hz Stat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Hz-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "I Conceal Flawless Foundation Broad Spectrum Spf 30 Sunscreen Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Conceal Flawless Foundation Broad Spectrum Spf 30 Sunscreen Deep Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Conceal Flawless Foundation Broad Spectrum Spf 30 Sunscreen Mahogany", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Conceal Flawless Foundation Broad Spectrum Spf 30 Sunscreen Mocha", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Conceal Flawless Foundation Broad Spectrum Spf 30 Sunscreen Porcelain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Conceal Flawless Foundation Broad Spectrum Spf 30 Sunscreen Toffee", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Know Very Necessary Broad Spectrum Spf-40 Moisturizing Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Love Xmas Penguin Moisturizing Hand Sanitizer Winter Berry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Love Xmas Polar Bear Moisturizing Hand Sanitizer Marshmallow", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I Love Xmas Reindeer Moisturizing Hand Sanitizer, Clementine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I-70 Foaming", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I-Defense", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "I-Drop Mgd", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "I-Drops Artificial Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "I-Drops Decongestant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "I-Drops Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "I-Oint Lubricant Eye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "I-Sleep",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "I-Softto Alcohol Disinfectant", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I-Softto Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I-Waker", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "I.C. Antibacterial Hand Cleaner", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I.C. Antibacterial Hand Cleaner With Pumice", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I.C. Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I.C. Ivy Block", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I.C. Sanitizer And Re-Moisturizer With Benzalkonium Chloride", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I.D Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "I.V. Prep Antiseptic Wipe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iallergy Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ibandronate Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Iberis Amara", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ibrance",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ibrea Micro Silver Anti Bacterial With Pure Silver", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ibrea Micro Silver Hand With Pure Silver", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ibrite Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ibsrela", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibu", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibu Tabs", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibupak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ibuprofen",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ibuprofen 200",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ibuprofen 200 Mg",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ibuprofen 200Mg",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ibuprofen And Diphenhydramine Citrate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ibuprofen And Diphenhydramine Hcl", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ibuprofen And Famotidine",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ibuprofen And Pseudoephedrine Hcl", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ibuprofen And Pseudoephedrine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ibuprofen Ca", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibuprofen Ib", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibuprofen Lysine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ibuprofen Migraine", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibuprofen Mini", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibuprofen Minis", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibuprofen Oral", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibuprofen Pain Reliever/Fever Reducer", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ibuprofen Pm",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ibuprofen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibuprofen Thompson", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibuprofen, Diphenhydramine Hcl", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibutab", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ibutilide Fumarate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ic Ivy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ic-Green", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Icatibant",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Ice Cold Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ice Cold Analgesic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ice Cold Topical Analgesic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ice Cream Cone Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iced Pear Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ichthammol", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ichthammol 20%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ichthyolum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ici Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ici Non-Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ici Non-Alcohol Hand Sanitizer Dye And Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iclevia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Iclofenac Cp", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Iclusig", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Icosapent", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Icosapent Ethyl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ics3 Blue Healthcare Hand Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ics3 Pink Antiseptic Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Icy Freeze",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Icy Hot",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Icy Hot Advanced Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Icy Hot Arm Neck Leg And Small Areas", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Back And Large Areas", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Dry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Lidocaine Dry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Lidocaine No-Mess", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Medicated No Mess Applicator", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Original Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Power", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Pro Dry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Pro No-Mess", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Pro Pain Relief Cream W/ Microbeads", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Vanishing Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Icy Hot With Lidocaine",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Icy Hot With Lidocaine No-Mess", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icy Hot Xl Back And Large Areas", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Icyhot Medicated", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Idamycin Pfs", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Idarubicin Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Idelvion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Idhifa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Idlife Sanitize Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ido Disinfection Ethanol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ido Disinfection Tissue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Idodent Flouride Mint", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ifex", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ifly Smart", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ifly Smart Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ifly Smart Antiseptic Hand", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iflysmart Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iflysmart Fly Happy And Clean Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Iflysmart Germ Free", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Iflysmart Germ-Free Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Ifosfamide",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Ignatia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ignatia Amara",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Ignatia Amara 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Ignatia Amara 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Ignatia Amara Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ignatia Bryophyllum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Igualtuss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ihanuus Hands", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Il-10", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ilaris", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Ileocecal Valve Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ilevro", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St0.5 Skye)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St1 Rendezvous)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St10 Porto Ferro)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St11 Matira)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St11.5 Morgat)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St12 Kokkini)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St12.5 Ramla Bay)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St12.75 Papakolea)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St13 Kamari)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St13.5 Rialto)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St14 Dominica)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St14.5 Honopu)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St15 Porto Covo)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St16 Pavones)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St16.5 Jardin)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St17 Miho)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St17.5 Perissa)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St18 Mali)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St19 Lovina)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St2 Tulum)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St2.5 Sombrio)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St3 Balos)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St4 Formosa)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St5 Bom Bom)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St6 Ora)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St6.5 Kai)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St7 Diaz)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St8 Shela)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St9 Paloma)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ilia Super Serum Skin Tint (St9.5 Baikal)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illuccix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Illuminating Mineral Beauty Balm With Spf 9", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Golden Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Light Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Nude", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Shell", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Vanilla", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Hyaluronic Skin Tint Oil Free Sunscreen Broad Spectrum Warm Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Golden", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Golden Tan", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Honey", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Ivory", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Light Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Nude", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Sand", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Shell", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Vanilla", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Illusion Tinted Moisturizer Broad Spectrum Warm Ivory", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iloje Clean And Safe Hand Clean Sanitizer Tissue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Iloje Clean And Safe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] }] },
    {
        Medication: "Iloperidone",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ilumya", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Iluvien", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Intravitreal" }] }] },
    { Medication: "Image Md Restoring Lip Enhancer Spf15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Image Prevention Plus Professional Ultimate Protection Moisturizer Spf50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Image Prevention Refiner Primer Spf50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Image Skincare Clear Cell Clarifying Acne Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Image Skincare Prevention Plus Daily Defense Lip Enhancer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Image Skincare Prevention Plus Daily Hydrating Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Image Skincare Prevention Plus Daily Ultimate Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Imari", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Imatinib", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Imatinib Mesylate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Imbruvica",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Imcivree", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Imdevimab", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Imfinzi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Imipenem And Cilastatin", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Imipramine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Imipramine Pamoate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Imiquimod", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Imitrex",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Imlygic", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intralesional" }] }] },
    { Medication: "Immune Booster", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immune Enhancement Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immune Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Immune Forte", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immune Plus Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Immune Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immune System Stimulator Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immunerx", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Immunexx", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immunexx W", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immunitea Herbal Relief Tea", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immuno Fortifier", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immuno-Reg Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Immunobooster", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immunogesic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immunopar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Immunotec Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Imodium",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Imodium A-D", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Imogam Rabies-Ht", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Imovax Rabies", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Impatiens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Impavido", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Impeccable Skin - Bronze", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Impeccable Skin - Buff", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Impeccable Skin - Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Impeccable Skin - Nude", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Impeccable Skin - Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Impeccable Skin - Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Impeccable Skin Moisturizing Face Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Impeklo", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Impoyz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Improvue", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Imuflex Wb-Rp Blood Bag System With Integral Whole Blood Leukocyte Reduction Filter (Removing Platelets) With Diversion Blood Sampling Arm Anticoagulant Citrate Phosphate Dextrose (Cpd) And Optisol (As-5) Red Cell Preservative", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Imuflex Wb-Sp Blood Bag System With Integral Whole Blood Leukocyte Reduction Filter (Saving Platelets) With Diversion Blood Sampling Arm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Imuflex Wb-Sp Blood Bag System With Integral Whole Blood Leukocyte Reduction Filter (Saving Platelets) With Diversion Blood Sampling Arm Anticoagulant Citrate Phosphate Dextrose (Cpd) And Optisol (As-5) Red Cell Preservative", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Imuran", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Imvexxy", DoseTypes: [{ DoseType: "Insert", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "In The Clear Acne Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "In Transit Skin Defence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "In2Itiv Hand Sanitizer 70 Alcohol Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "In2Itiv Hand Sanitizer 75 Isopropyl Alcohol Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "In2Itiv Spf30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inbrija", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Incare All In One Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incarvexx Essential", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Incassia", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Incellderm Active", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Active Creamex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Cocktail Aqua Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Daily Aqua Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Dermatology Booster", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Dermatology Booster Ex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Dermatology First Package", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Incellderm Dermatology Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Dermatology Serum Ex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Vallatto Oilmist", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incellderm Vieton Oilmist", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Incontinence",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Incontinence Care Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incontinence Vomiting", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Incontrol Alcohol", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incord Disposable Feminine Cleansing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Incredible Saintete Dishclothsoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incredibly Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Incredibly Clear Acne Spot", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Increlex", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Incruse Ellipta", DoseTypes: [{ DoseType: "Aerosol, Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Indapamide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inderal", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inderal Xl", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Indigestion", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Indigestion Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Indigestion With Nausea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Indigestion-Gas",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Indigo Carmine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Indium Dtpa In 111", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Indium In 111 Chloride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Indium In 111 Oxyquinoline",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Individual Antibacteriallotion Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Individual Free And Clear Foaming Antibacterial Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Indocin",
        DoseTypes: [
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Indocyanine Green", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Indole", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Indole Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Indomethacin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Indoor Outdoor Allergies", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Indoor Outdoor Allergy Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Indulgence/Propower", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Industrial Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Industrial Itch And Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Industrial Strength Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Industrial Strength Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Industrial Strength Waterless Hand Cleaner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Industriox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Industristat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Industrotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infanrix", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Infant 4.2% Sodium Bicarbonate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Infant Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Infant Gas Relief",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Infants", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Advil", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Dye Free Ibuprofen", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Dye Free Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Fever Reducer And Pain Reliever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Gas And Colic Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Infants Gas Relief",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Infants Gas Relief Drops",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Infants Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Infants Motrin", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Nanomol Dye-Free Cherry", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Non-Staining Gas Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Infants Pain And Fever",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Infants Pain Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Pain Relief Oral Suspension Cherry Flavor", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Pain Reliever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Silapap", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infants Tylenol", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infasurf", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Endotracheal" }] }] },
    { Medication: "Infect", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infectistat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infectone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infed", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Infinitek", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Infinitek Paris", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Infinity Shields Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Infirst Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infl Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infla", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inflamma-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inflammacin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Inflammation",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Inflammation Ii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inflammation Miasm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inflammation-Fever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inflammations", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Inflamyar",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Inflatherm", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Inflectra", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Infliximab", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Influaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Influaforce Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Influenseg", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Influenza", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Influenza Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Influenzinum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Influenzinum 200C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Infugem", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Infumorph 200", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Intrathecal" }] }] },
    { Medication: "Infumorph 500", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Intrathecal" }] }] },
    { Medication: "Infuvite Adult Multiple Vitamins", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Ingrezza",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Inhibit Ii High Foaming Antiseptic Hand Cleaner", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Injectafer", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Injuries", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Injury", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Injury Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Injury Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Injury Topical",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Injury-Rescue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inked Aftercare Tattoo Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inked Science Lidocaine 4 Percent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inkeeze Original B Numb", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inkeeze Original B Numb Cylinder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inlyta", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inmazeb", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Inmunopro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inner Ear Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Innerest Mediwiper", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Innisfree Daily Uv Defense Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Innisfree Gentle Care For Sensitive Skin Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Innisfree Matte Priming Uv Shield Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Innoble Onechewtoothpaste", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Innopran Xl", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Innovate Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Innovative Display", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Innovative Display Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inoculex Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inoderm Style Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inofoam Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inomax", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Inon", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Inon Ace",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Inqovi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Inrebic", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insadol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insanitize", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Insect Bite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insect Bites", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insect Repellent Insect Block", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Insect Sting Relief",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Insecticides/Pest/Food Additives Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insects Whole Body Cockroach Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Insects Whole Body Cockroach, American Periplaneta Americana", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Insects Whole Body Cockroach, German Blatella Germanica", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Insects Whole Body, Ant, Fire Solenopsis Invicta", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Insects Whole Body, Fire Ant Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Insomnia",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Insomnia Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insomnia Nervousness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insomnia Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Inspiration Academy Menthol Cooling Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inspiration Academy Menthol Cooling Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inspra", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insta Clean Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Insta Relief Dm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Insta San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Insta-Char Aqueous", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insta-Char Aqueous Cherry", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insta-Char Sorbitol", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insta-Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Insta-Clear Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instaclean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instaflex Extra Strength Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instaflex Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instaflex Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instaflex Pain Relief Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Antiseptic Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Foam Non-Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Foam Non-Alcohol Pure Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizeing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Extracorporeal" }, { Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Extracorporeal" }, { Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Instant Hand Sanitizer - Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer - Cocoa Butter", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer - Ice Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer - Original", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer 16Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer 2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer Convenience Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Instant Hand Sanitizer Ke-53San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer Sterile 70 Ethyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizer With Aloe And Vitamine E 29Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitizing Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Hand Sanitzer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Sanitizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instant Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instantfoam Complete Pure", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instantgel Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instantgel Pure", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instaready Face Primer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instasoothe Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Insti-Foam Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instukem Hand Sanitizer Max Moisturizing Gel And Vitamin E Grape Fruit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instukem Hand Sanitizer Moisturizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instukem Pro Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Instukem Pro Hand Sanitizer Topical", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Insulin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Insulin Aspart", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Insulin Aspart Protamine And Insulin Aspart", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Insulin Diluting Medium For Novolog", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Insulin Glargine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Insulin Glargine Solostar", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Insulin Lispro", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Insulin Lispro Protamine And Insulin Lispro Injectable Suspension", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Intco Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intco Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intelence", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intelligent Beauty Labs Age Defying Day Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intellishade Clear Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intellishade Matte Spf 45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intellishade Original Spf 45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intellishade Truphysical Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Multi Ampoule Balm Collagen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Multi Ampoule Balm Vita C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Therapy Lip Balm Asian Pear", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Therapy Lip Balm Basil Lemon", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Therapy Lip Balm Black Cherry", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Therapy Lip Balm Cucumber Lime", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Therapy Lip Balm Mango And Mandarin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Therapy Lip Balm Pineapple Mint", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Therapy Lip Balm Spf25, Shea Butter And Vitamin E", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intense Therapy Lip Balm Winter Melon", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intensite Creme Lustre Day Spf 30 Us", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intensive Daily Repair Body Butter", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intensive Healing Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intensive Healing Eczema", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intensive Skin Repair", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intensive Skin Serum Foundation Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intercellular Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intercom Foaming Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intercom Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intermezzo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Intersol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Intertam Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intestaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intestia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intestinal Distress", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intestinal Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intestine Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intestine Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Intimale Maximum Strength Delay", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intimate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intime Cesens", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Into Repair Face Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intra-Cell", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Intral Sensitive Skin Environmental Lightweight Shield Broad Spectrum Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Intralipid", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Intrarosa", DoseTypes: [{ DoseType: "Insert", Routes: [{ Route: "Vaginal" }] }] },
    {
        Medication: "Intron A",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intralesional; Intramuscular; Subcutaneous" }, { Route: "Intramuscular; Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Introstem Stem Cell Active Defense", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Introvale", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Intuniv",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Invanz", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Invega", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Invega Hafyera", DoseTypes: [{ DoseType: "Injection, Suspension, Extended Release", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Invega Sustenna", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Invega Trinza", DoseTypes: [{ DoseType: "Injection, Suspension, Extended Release", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Inveltys", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inverness Ear Piercing", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Invigo Flex Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invirase", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Invisassure Hydrating Hand Sanitiz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invisassure Hydrating Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invisi Shield Anti Bacterial", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invisible Hero Spf30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invisible Physical Defense Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invisible Shield Full Physical Spf 49 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invisible Shield Full Physical Tinted Spf 52 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invisible Solid Anti-Perspirant And Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Invokamet", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Invokamet Xr", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Invokana", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Invoke Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Inward Agas Acue Cleansing Foam", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iodent Oral Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iodent Sensitive", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Iodent Tooth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iodine Bush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Iodine Bush Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Iodine Tincture",
        DoseTypes: [
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tincture", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Iodine Tincture (Iodo)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iodine Tincture Mild", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Iodium",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Iodium Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Iodixanol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravascular" }] }] },
    { Medication: "Iodo Albo Iodide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iodo Blanco (Iodine Tincture Decolorized)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iodoquinol, Hydrocortisone Acetate, Aloe Polysaccharides", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ioflupane I 123", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ioline", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Iolite", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Ionic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ionite Apf",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Oral; Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Dental; Oral" }, { Route: "Dental; Oral; Topical" }] },
        ],
    },
    {
        Medication: "Ionite H",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Ionite R", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Ionosol Mb And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Iope Air Cushion Cover 13 Ivory", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iope Air Cushion Cover 21 Vanilla", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iope Air Cushion Cover 23 Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iope Perfect Cover Cushion 13", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iope Perfect Cover Cushion 21", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iope Perfect Cover Cushion 23", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iope Uv Defense Sun Protector Ex", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iope Uv Men Defense Sun Protector Ex", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iopidine", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Iosat", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ipa Cleaning Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ipa Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ipana 20% Benzocaine Topical", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ipana Bucky Beaver Bubble Gum Childrens Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ipana Wintergreen Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Ipecacuanha",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ipecacuanha Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ipkn Moist And Firm Bb 02 Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ipkn Moist And Firm Bb 03 Light Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ipkn Moist And Firm Bb 04 Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ipkn Pore Apple Sun Block", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ipol", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Ipratropium Bromide",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Ipratropium Bromide And Albuterol Sulfate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Iq Dental Mepivacaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Irbesartan",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Irbesartan And Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ireliev Roll On Cool Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iressa", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Irinotecan Hydrochloide", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Irinotecan Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Iris", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iris 60 Second Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Iris Antimicrobial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iris Germanica 2 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Iris Germanica Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Iris Tenax", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Iris Versicolor",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Irish Spring Antibacterial Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iron Company Heavy Duty Sanitizing Alcohol Free Wipes Fresh Lemon Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iron Dextran", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Iron Mix System Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Irospan 24/6", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Irregular Pulse Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Irritable Bladder Fever", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Irritable Bowel Syndrome Therapy", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Irritated Cracked Skin", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Irritation Relief Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Irving Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Is Clinical Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isa Knox 365 Bb Sunscreen Broad Spectrum Spf 40, Pa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isa Knox 365 Sunscreen Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isa Knox Anew Solaire Active Face And Body Protection Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isa Knox Anew Solaire Everyday Face Protection Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isa Knox Lxnew Platinum Sculpting Day Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isa Sunguard Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isagel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Isentress",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Isibloom", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Islamic Relief Hand Sanitizer 16Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Islamic Relief Hand Sanitizer 2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Islamic Relief Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Island Pure Hand Sanitizer Soothing Infused With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Island Remedy All-Day Revitalizing Moisturizer With Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ismile Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Isner Mile Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Iso-Quin",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Iso-Quin Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isoflurane", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Isolyte P In Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Isolyte S", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Isolyte S Ph 7.4", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Isomers Isopropyl Alcohol Antiseptic 75%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Isoniazid",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Oral; Oral" }] },
        ],
    },
    { Medication: "Isopathic Phenolic Rings", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Isoplate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Isopro First Aid", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropanol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropy Alcohol Antiseptic 75% Topical Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Isopropyl",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Isopropyl Alcohol",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Isopropyl Alcohol 50%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol 70 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol 70 Percent With Wintergreen Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol 70%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol 70% V/V Prep Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol 91 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol 91%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol 99 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol 99%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol Antiseptic 75", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol Antiseptic 75%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Isopropyl Alcohol Antiseptic 75% Topical Solution",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Isopropyl Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol Prep Pad Sterile", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol Swab", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Extracorporeal" }, { Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol With Wintergreen 50%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Alcohol With Wintergreen 70%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Isopropyl Rubbing Alcohol",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Isopropyl Rubbing Alcohol 50%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Rubbing Alcohol 50% With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Isopropyl Rubbing Alcohol 70",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Isopropyl Rubbing Alcohol 70 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Rubbing Alcohol 70%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Rubbing Alcohol 70% With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Rubbing Alcohol 99%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Rubbing Alcohol 99% With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isopropyl Rubbing Alcohol Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isoproterenol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intracardiac; Intramuscular; Intravenous; Subcutaneous" }] }] },
    {
        Medication: "Isoproterenol Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intracardiac; Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intracardiac; Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Isopto Atropine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Isopto Carpine", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Isopto Tears", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Isordil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Isosorbide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Isosorbide Dinitrate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Isosorbide Dinitrate And Hydralazine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Isosorbide Mononitrate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Isosorbide Mononitrate Er", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Isosulfan Blue", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Isotretinoin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Isovue", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravascular" }, { Route: "Intravenous" }] }] },
    { Medication: "Isovue-M", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Isoxsuprine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Isradipine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Issactizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isseey - Mist Hand Sanitizer - Living The Dream", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isseey - Mist Hand Sanitizer - Love In The Air", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isseey - Mist Hand Sanitizer - Over The Moon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isseey - Moisturizing Hand Sanitizer - Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isseey - Moisturizing Hand Sanitizer - Lemon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Isseey Antibacterial Alcohol Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Issengard 24 Hand Sanitizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Issengard 24 Hand Sanitizer 3 Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ist Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Istalol", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Istick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Istodax", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Isturisa", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Isuprel", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intracardiac; Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "It Cosmetics Bye Bye Foundation Full Coverage Moisturizer Broad Spectrum 50 Plus Sunscreen All Shades", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "It Cosmetics Bye Bye Foundation Full Coverage Moisturizer Oil Free Matte Broad Spectrum 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "It Cosmetics Your Skin But Better Cc Plus Color Correcting Broad Spectrum Spf 50 Plus Sunscreen Full Coverage Deep Fairlight Neutral Tan Rich Honey Lightmedium And Neutral Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "It Cosmetics Your Skin But Better Cc Plus Illumination Color Correcting Illuminating Broad Spectrum Spf 50 Plus Sunscreen Full Coverage Fairlight Neutraltan Richhoney Lightmedium And Neutralmedium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "It Cosmetics Your Skin But Better Cc Plus Nude Glow Color Correcting Medium Coverage Skin Tint Brightening Glow Serum Broad Spectrum Spf 40 Sunscreen All Shades", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "It Cosmetics Your Skin But Better Cc Plus Oil Free Matte Poreless Finish Full Coverage Broad Spectrum Spf 40 Sunscreen All Shades", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "It Works Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Italian Ryegrass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Fair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Light Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Medium Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Neutral Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Neutral Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Fair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Light Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Medium Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Neutral Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Neutral Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Rich", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Rich Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Oil-Free Matte Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Rich", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Rich Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Bye Bye Foundation Full Coverage Moisturizer Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Fair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Light Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Medium Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Neutral Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Neutral Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Rich", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Rich Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Full Coverage Cream Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Fair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Light Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Medium Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Neutral Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Neutral Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Rich", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Rich Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itc Cc Illumination Full Coverage Cream Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itch And Bite Relief First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Itch And Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itch Fixer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itch Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Itch Relief",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Itch Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itch Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itch Relief Max Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itch Stopping", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itch X", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itch-X", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itching Skin", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Itchy Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Itchy Scalp", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Itchy Skin Erruptions", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Itchy Skin Eruptions", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Itchy Skin Rash", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Itchy Skin Runny Nose", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ithurts", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Itires",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Cutaneous" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Itk Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Itraconazole",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Itraconazole Oral", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Its Nanoed Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Its You Antiperspirant Roll-On Deodorant", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iv Solution Stabilizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Iva Annua Var Annua Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Iva Xanthifolia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Ivarest", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ivermectin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ivizia Dry Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ivizia Lubricant Eye For Severe Night Dry Eyes", DoseTypes: [{ DoseType: "Solution, Gel Forming / Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ivogel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivory Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivory Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivory Perfecting Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivresse Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivresse Sanitizer Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivresse Sanitizer Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivresse Sanitizer Disinfecting Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivresse Sanitizer Wet Wipes Antibacterial Travel Pack", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivy Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ivy-Dry Super", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Iwk-5", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ixempra", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Intravenous" }] }] },
    { Medication: "Ixiaro", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Ixinity", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ixoba M", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Iypoly", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "J0830014 Jason Sun Family Sunscreen Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "J0832014 Jason Sun Sport Sunscreen Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "J0832212 Jason Kids Sunscreen Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "J0832706 Jason Sun Facial Sunscreen Spf20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "J1002901Jason Dandruff Relief 2In1Treatment", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "J1002902 Jason Dandruff Relief 2In1 Treatment", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "J1003005 Jason Dandruff Relief", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "J2 Medical Supply Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jabon Avena", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jabon Cremoso", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jabon Fresh Energy", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black All Jacked Up", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Balancing Foam Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Clearing Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Grab N Go Traveler", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Ink Boost Tattoo Care", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Intense Lip Therapy Spf25, Black Tea And Blackberry", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Intense Lip Therapy Spf25, Natural Mint And Shea Butter", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Intense Thearpy Lip Balm Spf25, Lemon And Shea Butter", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Intense Therapy Lip Balm Passion Fruit", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Intense Therapy Lip Balm Spf25, Grapefruit And Ginger", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Intense Therapy Lip Balm Vanilla Bourbon", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Intensive Therapy Lip Balm Mango Chili Lime", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Jacks Most Wanted", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Lavender Lip Balm 0.25Oz", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Overnight Repair Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Performance Remedy Dragon Ice", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Shave Essentials", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black Sun Guard Sunscreen Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black The Closer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black The Supreme Shave", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack Black The Triple Play", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack N Jill Bunny Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack N Jill Dino Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack N Jill Koala Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack N Jill Monkey Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jack N Jill Unicorn Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jacket Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jacket Spf-50 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jade Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Jadenu",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Jafra Daily Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Daily Antiperspirant Deodorant Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Daily Hair Minimizing Antiperspirant Deodorant Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Daily Sensitive Skin Antiperspirant Deodorant Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Daily Skin Brightening Antiperspirant Deodorant Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Bare L6", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Bronze D2", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Caramel D12", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Earth D10", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Fair L2", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Honey M6", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Natural M2", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Nude L4", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Sable D6", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Spice D4", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Long Wear Makeup Broad Spectrum Spf 20 Tawny M4", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Defy Sample Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Defy Travel Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Bare L6", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Bronze D2", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Cappuccino D8", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Caramel D12", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Earth D10", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Fair L2", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Honey M6", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Natural M2", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Sable D6", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Spice D4", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Broad Spectrum Spf 20 Tawny M4", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Jelly Radiance Spectrum Spf 20 Nude L4", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Royal Luna Bright Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jafra Sun Body Protector Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Sun Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jafra Sun Tinted Face Protector Sunscreen Broad Spectrum 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jaimiess", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jakafi", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jakemans Throat And Chest Anise", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jakemans Throat And Chest Cherry", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jakemans Throat And Chest Honey And Lemon", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jakemans Throat And Chest Peppermint", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jalapa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jalyn", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jane Seymour Natural Advantage Am Spf 15 All Day Moisture With Ahas", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Janssen Covid-19 Vaccine", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Jantoven", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Janumet",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Januvia", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jardiance", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jasmiel", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jasmine Plum Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jasmine Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jasmine Scented Antiseptic Hand Sanitizer 80 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jasna Pearl Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jason Healthy Mouth Anticavity Tartar Control Tea Tree Cinnamon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jason Mineral Sunscreen Spf30 Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jason Powersmile Anticavity Whitening Peppermint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jason Sea Fresh Anticavity Deep Sea Spearmint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jason Total Protection Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jasper 17 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jatenzo", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jeanatope", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Jegamsan Extract", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jejuon Dailycare Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jejuon Pure Clean Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jell", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jell Pharmaceuticals Vitamin A D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jelly Candy Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jelmyto", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jemperli", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Jencycla", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jensil Alcohol Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jentadueto", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jentadueto Xr", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jergens Natural Glow Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jermee Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jet Alert Double Strength", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jet Alert Regular Strength", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jet Kleen Hsg-E70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Jet Lag",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Jet Lag Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jet-Asleep Double Strength Night Time Sleep Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jet-Avert Motion Sickness Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jetblue Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jetclean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jeuveau", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Jevtana", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jiajia Gaseous Air Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jianerkang Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jianerkang Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jikimi Germ Killer-Spray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jikimi Phytoncide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jilgyungyi", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Jin Gu Wang", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jingchuang Chonggao Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jinteli", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jivi", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jlo Beauty That Big Screen Broad Spectrum Spf 30 Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Jmax Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Jnk Yrd Advanced Hand Sanitizer Lavender Scent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jnk Yrd Advanced Hand Sanitizer Lemon Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jnk Yrd Antibacterial Wipes Lemon Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jnn-Ii Clean Tok Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jnn-Ii Clean-L Safe Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jo For Him Prolonger With Lidocaine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jo Prolonger With Benzocaine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joby Instant Hand Sanitrizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Jock Itch",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Jock Itch Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jock-Eze", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joesoef Skincare Anti Acne", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joette Calabrese Survivalist Kit", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "John Wayne Cancer Foundation Mineral Sunscreen Facestick Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Johnson And Johnson All Purpose First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Johnson And Johnson Safe Travels First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Johnson Grass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Johnson Grass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Johnsons Baby Care Essentials Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Johnsons Baby First Touch Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Johnsons Bath Discovery Baby Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Johnsons First Touch Baby Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Johnsons Medicated", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Johnsons Tiny Traveler Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Johnsons Welcome Baby Gift Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Joint And Muscle Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joint And Tendon Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Joint Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Joint Formula 88 Max Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joint Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Joint Pain", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joint Pain And Stiffness Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joint Plus Tendon", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Joint Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joint Tone Ai", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Joint Tonic R", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jointflex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jointforce A-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jointforce R", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jointforce R-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jointox Ai", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jointox Bb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Jointstat Bb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Joiya Ease", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jojo Blueberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jojo Donut Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jojo Grape Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jojo Siwa Fresh Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Jojo Siwa Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Jojo Strawberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jolessa", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jolo Active", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jornay Pm", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Josephs Lion Pain Relieving", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Josie Maran - Get Even Sun Milk Broad Spectrum Spf 33 Mineral Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Josie Maran Moisture Repair Facial Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Journee Bio-Restorative Day Balm Broad-Spectrum Suncreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Journee Firm Broad Spectrum Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Journee Riche Bio-Restorative Day Balm Broad-Spectrum Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jouvalle Deep Nourishing Luminosity Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joy Skin And Body Premium Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joy Spring Kids Mineral Sunscreen Spf-30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joylux Anti Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Joyspring Kids Mineral Sunscreen Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jtd Advanced Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jublia", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juc Rinsefree Anti Microbial Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juc Sterilization Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juglans Nigra Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Juglans Regia",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Juglans Regia E Testa 14 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Juglans Regia E Testa 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Juglans Regia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }] }] },
    { Medication: "Juglans Stannum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Juglans Stannum Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Juice Box Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juicy Couture Non Scented Hand Sanitizer 70 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juleber", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Julep Antibacterial Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Julep Antibacterial Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juluca", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Juncturinum Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Junel",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Junel Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Junel Fe 1.5/30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Junel Fe 1/20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Jungle Screen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Skin Nuder Cushion Fair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Skin Nuder Cushion Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Skin Nuder Cushion Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Skin Nuder Cushion Medium Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Skin Nuder Longwear Cushion Fair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Skin Nuder Longwear Cushion Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Skin Nuder Longwear Cushion Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Skin Nuder Longwear Cushion Medium Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Star Cealer Foundation Fair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Star Cealer Foundation Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Star Cealer Foundation Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Essential Star Cealer Foundation Medium Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Masterclass Radiant Cushion N0 Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Masterclass Radiant Cushion N1 Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Masterclass Radiant Cushion N2 Vanila", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Masterclass Radiant Cushion Y4 Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Masterclass Radiant Cushion Y5 Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Skin Nuder Cover Layer Cushion Fair Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Skin Nuder Cover Layer Cushion Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Skin Nuder Cover Layer Cushion Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Skin Nuder Cover Layer Cushion N-Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungsaemmool Skin Setting Tone-Up Sun Base", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jungwon Pine Antibiosis Wet Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Junior Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Junior Strength Advil",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Juniper Breeze Foaming Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juniper Breeze Liquid Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juniper Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juniper Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Juniperus Ashei Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Juniperus Californica Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Juniperus Communis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Juniperus Eucalyptus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Juniperus Virginiana", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Juniperus Virginiana Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Jurassic World Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jusofon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Just 1",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Just Add Heat Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Just Add The Heat Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Just Be Happy Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Just Chill Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Just For Kids", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Just For Men Control Gx Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Just For Men Minoxidil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Just In Case", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Just Right 5000", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Just4U Oil Free Acne Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justatp - Non Alcoholic - Homeopathic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justhuman 24 Hour Protection Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice 194011 Heart It Antibac Blackberry And Vanilla Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice 198824 You Rock Antibacterial Cotton Candy Blueberry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Amazing Melon Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Aztec Apple Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Be Happy Citrus Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Be Strong Bright Citrus Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Beautiful Berry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Berry Blast Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Berry Twist Scent Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Bff Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Bff Cotton Candy Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Bff Go Together Like Unicorns Glitter Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Boho Berry Scent Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Bright Citrus Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Bubble Gum Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Bubbling Blueberry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Cactus Vanilla Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Confetti Cupcake Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Cotton Candy Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Cotton Candy Scented Anti Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Cotton Candy Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Cranberry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Create Inspire Explore Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Cucumber And Melon Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Dance Berry Blast Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Dance Orange Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Dazzling Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Diamond Vanilla Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Electric Lime Bright Citrus Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Elephant Mango Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Flamingo Berry Scented Anti Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Frosted Vanilla Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Fruit Punch Scent Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Fruit Punch Scented Anti Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Glitter Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Grape Scented Anti Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Hamburger Green Apple Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Latte Cotton Candy Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Lavender Dream Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter B Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter C Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter D Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter G Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter H Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter I Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter J Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter K Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter L Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter M Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter N Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter P Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter R Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter S Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter T Blueberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Letter T Frosted Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Love Hugs Me And You Cotton Candy Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Magical Melon Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Meow Mint Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Mermaid Mix Berry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Mixed Berry Scent Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Moonstone Melon Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Outta This World Vanilla Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Paris Punch Scent Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Peace Love Lemonade Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Peppermint Twist Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Pinapple Pizazz Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Pineapple Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Plum Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Pop Corn Vanilla Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Puppy Love Cotton Candy Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Rabbit Strawberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Rainbow Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Rainbow Peach Mango Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Rainbow Raspberry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Rainbow Raspberry Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Rhinestone Raspberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Shimmer Strawberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Sparkling Berry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Sparkling Strawberry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Sparkling Vanilla Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Strawberry Macaroon Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Strawberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Strawberry Stardust Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Strawberry Swirl Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Sugar Swirl Scent Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Sugared Donut Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Sweet Cinnamon Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Sweet Pineapple Scented Anti Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Tortoise Green Apple Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Tribal Tangerine Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Tucan Strawberry Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Twist Peppermint Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Vanilla Latte Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Vanilla Scent Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Vanilla Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Vanilla Sprinkles Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Vanilla Vibes Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Very Berry Grape Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Watermelon And Strawberry Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Watermelon Scent Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Watermelon Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Justice Wild Melon Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Jute", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Juulissa Pharmapak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Juve-Cal", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Juveheal A Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juveheal B Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juveheal W Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Juxtapid", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Jynarque",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Jynneos", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "K Phos",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "K-100",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "K-Mom Baby Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "K-Mom Kids Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "K-O-R-T And Sweet Vernal Standardized Grass Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "K-O-R-T Standardized Grass Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "K-O-T Standardized Grass Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "K-Phos", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "K-Pure", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "K-Tab", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "K.Boeun Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "K.Doctor Clean Ethanol For Disinfection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "K.T.S.O. Fabric And Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "K.Vi Zero Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "K2 Cold Therapy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kabiven", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kadcyla", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kadian", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kaewoon",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kaewoon For Men", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kaewoon For Women", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kaiser Permanente", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kaitlib Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kalaya Breath Refresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kalaya Breathe Easy Vaporizing Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kalaya Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kalaya Pain Relief 6X With Arnica", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kalbitor", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Kaletra",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kali Arsenicosum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kali Bichromicum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Kali Bichromicum 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Kali Bichromicum 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Kali Bichromicum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kali Bromatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kali Carb",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Kali Carbonicum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Kali Carbonicum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kali Chloricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kali Iodatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kali Iodatum 1X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kali Mur",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kali Mur 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kali Muriaticum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kali Muriaticum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kali Phos", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kali Phos 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kali Phos. Aurum", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kali Phosphoricum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kali Phosphoricum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kali Silicatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kali Sulph",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kali Sulph 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kali Sulphuricum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kalium Aceticum Comp 6", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kalium Aceticum Comp. 6",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kalium Carbonicum 12 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kalium Carbonicum Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Kalium Muriaticum 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Kalium Phosphoricum 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kalium Phosphoricum 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Kalium Sulfuratum Bath Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kalium Sulfuricum 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Kalliga", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kalmia Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kalmia Latifolia",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Kalydeco",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kandesn Cc Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kangmanbaby Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kanjinti", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kanjo Homeopathic Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kanjo Natural Pain Relief Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kanjo Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kank-A", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Kanka", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kanuma", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kao Tin", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kaopectate",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kaopectate Cherry Flavor Anti Diarrheal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kaopectate Extra Strength Peppermint Flavor Anti Diarrheal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kaopectate Max Peppermint Flavor Anti Diarrheal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kaopectate Peppermint Flavor Anti Diarrheal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kaopectate Regular Strength Cherry Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kaopectate Regular Strength Peppermint Flavor Anti Diarrheal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kaopectate Regular Strength Vanilla Flavor Anti Diarrheal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kaopectate Vanilla Flavor Anti Diarrheal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kapok",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Kapsin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kapspargo", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kapvay", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Karaya Gum",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Karbinal", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kari Gran Essential Spf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kariva", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Karl And Hans Botanic Double Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Karl And Hans Botanic Jasmine", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Karma Rub Elemental Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kate Aspen Spread Love Not Germs Hand Sanitizer 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kate Aspen Spread Love Not Germs Hand Sanitizer Burgundy Blush Floral 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kate Aspen Spread Love Not Germs Hand Sanitizer Woodland 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kate Summerville Eradikate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Katerzia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kathari Sun Shield Drops Natural Spf 50", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Katinko Pain And Itch Relieving", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kay", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kay Actigel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kay Alcohol Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kay Chemical Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kay Mcd", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kay Medic Menthol Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kay Qsr", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kaydia Pain Relieving Patches Menthol 5 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kaylaan", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kazano", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kazu Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kcentra", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kedbumin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kedrab", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Keen Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Clean Surface Cleaning Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Em Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Going Alcohol Prep Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Going Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Going Non Sting Cleaning Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Going Sting Relief Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep It Kleen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep The Peace Acne-Calming Cream Cleanser", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Your Hands Clean Antibacterial Hand Sanitizer Berry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Your Hands Clean Antibacterial Hand Sanitizer Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Your Hands Clean Antibacterial Hand Sanitizer Strawberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Your Hands Clean Antibacterial Hand Sanitizer Watermelon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keep Your Hands Clean Antibacterial Hand Wash Strawberry Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keepan A", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keeper Medal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Keeps Minoxidil Topical",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kekangbei Alcohol Disinfectant", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kekangbei Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keke Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keke Hand Sanitizer 750Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keke Hand Sanitizer Original 500Ml Lt31042", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kelan", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Kelnor 1/35", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kelnor 1/50", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kelsan Antimicrobial Ltion Skin Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kelsen - Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kelvin Pro Pain Relief Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kenaf Wet Wipe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kenaf Wet Wipe Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kenalog", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kenalog-10", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intralesional" }] }] },
    { Medication: "Kenalog-40", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intramuscular" }] }] },
    { Medication: "Kenalog-80", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intramuscular" }] }] },
    { Medication: "Kenda Care Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kendra Skin Protection And Pain Relief For Hair Removal", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kengreal", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kenkoderm Psoriasis", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kenkoderm Psoriasis Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kentex Hand Sanitizing Wet Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kentex Instant Hand Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Kentucky Bluegrass (June) Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Kepivance", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Keppra",
        DoseTypes: [
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kera Hc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keracare", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Keracare Dry And Itchy Scalp Anti-Dandruff",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Keralyt",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Keranique 2% Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keranique For Women Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keranique Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kerasal Athletes Foot Powder", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kerasal Athletes Foot Rapid Symptom Relief", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kerasal For Athletes Foot Silky Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kerendia", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Keri Clinical Care Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keri Clinical Care Hand Sanitizing Gel - Healthcare", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kericure Fabric And Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kericure Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kerma Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kern Sani Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kern Sani Inc Hand Sanitizer - Unscented", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kern Sani, Inc Hand Sanitizer - Lemon Zest", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kern Sani, Inc Hand Sanitizer - Lemon Zest Gel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kern Sani, Inc Hand Sanitizer - Ocean Breeze", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kern Sani, Inc Hand Sanitizer - Ocean Breeze Gel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kern Sani, Inc Hand Sanitizer - Unscented Gel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kerr 100 Triple Dye Dispos-A", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kerstin Florian Multi-Vitamin Day Creme Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kerydin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kesimpta", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Ketaconazole", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ketalar", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Ketamine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    {
        Medication: "Ketoconazole",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ketoconazole Cream, 2%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ketodan",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    {
        Medication: "Ketoprofen",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ketorocaine L Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Infiltration; Intramuscular; Intravenous; Topical" }] }] },
    { Medication: "Ketorocaine Lm Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intramuscular; Intravenous; Topical" }] }] },
    {
        Medication: "Ketorolac Tromethamine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ketotifen Fumarate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Keveyis", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kevzara", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Kew Hand Sanitiser Bergamot And Ginger", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Bluebell And Jasmine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Coconut", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Elderflower And Pomelo", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Fig And Grape", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Grapefruit And Lily", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Iris", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Jasmine Peach", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Lavender And Rosemary", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Lemongrass And Lime", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Magnolia And Pear", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Mango", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Narcissus Lime", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Osmanthus Rose", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Pineapple And Pink Lotus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Sandalwood And Pink Pepper", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kew Hand Sanitiser Summer Rose", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Key Lime Pie Shake Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keys Soulcare Daily Moisturizer Broad Spectrum Spf30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keystone", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keystone Antibacterial", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keystone Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Keytruda", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kh42", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Khapzory", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kidcreme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kidgets Infants", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidgets Infants Pain Reliever Plus Fever Reducer", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidnex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney Bean", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Kidney Drainage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney Stone Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney Tonic I", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney-Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney/Bladder Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney/Ovarian/Adrenal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidney/Prostate/Adrenal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidneyforce-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Allergy Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Kids Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Anticavity", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Anticavity Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kids Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kids Choice", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Cold And Mucus Relief Plus Echinacea", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Cough And Chest Congestion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Cough And Cold Relief Plus Echinacea, Nighttime", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Cough And Mucus Day And Night Grape", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kids Cough And Mucus Nighttime Grape Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Cough Relief Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kids Crest",
        DoseTypes: [
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kids Crest Cavity Protection", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kids Earache Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Kids Flouride Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Healthy Teeth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Kids Kare",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Dental; Oral" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental; Oral" }] },
        ],
    },
    { Medication: "Kids Mineral Sunscreen Roll-On", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kids Nasal Allergy Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Kids Pain And Fever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Pink Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Kids Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kids Silly Strawberry Tp", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Kids Spf 50 Plus Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kids Spf 50Plus Sunscreen Continuous", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kids Spf30 Super Sensitive Plus C111 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kids Spf50 Plus Mineral Sunscreen Continuous Totally Tropical", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kids Spf50Plus Mineral Sunscreen Rollerball Totally Tropical", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kids Strawberry Ftp", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kids Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kids Tummy Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kidstick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kidzgoo Natural Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kidzstuff Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Activated Sun Protector Broad Spectrum Spf 30 Sunscreen For Body", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Activated Sun Protector Broad Spectrum Spf 30 Sunscreen Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Activated Sun Protector Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Activated Sun Protector Broad Spectrum Spf 50 Sunscreen Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Activated Sun Protector For Body Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Activated Sun Protector For Face And Body Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Activated Sun Protector For Face Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Blue Herbal Acne Cleanser Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Blue Herbal Acne Cleanser Treatment With Frankincense And Ginger Root", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Blue Herbal Acne Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Blue Herbal Gel Cleanser Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Body Fuel Antiperspirant And Deodorant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Broad Spectrum Spf 30 Sunscreen Powerful Wrinkle Reducing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Butterstick Lip Treatment Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Clean Strength Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Crossterrain Antiperspirant Deodorant 24 Hour Strong Dry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Crossterrain Uv Skin Protector Broad Spectrum Spf 50 Sunscreen Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Dermatologist Solutions Acne Blemish Control Daily Skin Clearing Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Dermatologist Solutions Breakout Control Acne Treatment Facial", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Dermatologist Solutions Breakout Control Targeted Acne Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Dermatologist Solutions Clearly Corrective Dark Circle Perfector Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Dermatologist Solutions Skin Tone Correcting And Beautifying Bb Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Dermatologist Solutions Superfluid Uv Mineral Defense Broad Spectrum Spf 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Facial Fuel Daily Energizing Moisture Treatment For Men Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Scalp Purifying Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Super Multi Corrective Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Superbly Efficient Antiperspirant And Deodorant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Ultra Facial Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Since 1851 Ultra Facial Moisturizer Broad Spectrum Spf 30 Sunscreen For All Skin Types", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiehls Super Fluid Daily Uv Defense Spf 50 Plus Anti-Pollution Lightweight Formula For All Skin Types", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kill Firetoothpaste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Killfire Mouth Freshener", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Killfire Mouthwash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kills Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kimmtrak", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kimyrsa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kindest Kare Advanced Antimicrobial Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kindest Kare Advanced Antimicrobial Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kindest Kare Air-Infused Foam Antiseptic Handrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kindest Kare Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kindfill", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kineret", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Kinesys",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kinesys Broad Spectrum Spf 30 Alcohol-Free Performance Sunscreen - Fragrance Free", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinesys Broad Spectrum Spf 50 Alcohol Free Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinetic Ice Muscle And Joint Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinevac", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kinfield Cloud Cover Mineral Body Sunscreen Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinfield Daily Dew Sea Kelp Moisturizer Sunscreen Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinfield Relief Balm Anti-Itch Remedy", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinfield Sunglow Luminizing Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "King-Tuss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kingskin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinkan", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinkan Cool", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinlo Always Golden Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinlo Golden Rays Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinlo Golden Rays Sunscreen (Medium Tinted)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinlo Sunscreen Stick Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kinrix", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Kioni Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiranex 70% Ethanol Hand Sanitizer Gel With Eucalyptus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kirkland Signature Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Acid Controller", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Aller Cort", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Kirkland Signature Aller Fex", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Aller Flo", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Kirkland Signature Aller Tec", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kirkland Signature Aller Tec D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kirkland Signature Allerclear", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Allerclear D 12 Hr", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Allerclear D 24 Hr", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Childrens Aller Tec", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Diclofenac Sodium", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kirkland Signature Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Hydrocortisone Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kirkland Signature Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Ibuprofen Ib", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Lansoprazole Delayed Release", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Laxaclear", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kirkland Signature Minoxidil",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kirkland Signature Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kirkland Signature Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kirkland Signature Quit 2",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Kirkland Signature Quit 4",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kisqali", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kisqali Femara Co-Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kiss Alcohol Prep Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face - Face Factor Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face - Oat Protein Complex Spf 18", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face - Oat Protein Complex Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Bare Naked 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Cool Sport 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Hand Sanitizer Cleansing Alcohol Scent Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Hand Sanitizer Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Hand Sanitizer With Aloe Bacterial Defense Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Kids 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Kids Fluoride - Berry Smart", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kiss My Face Mineral Sunscreen Obsessively Natural Kids Defense Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Mineral Sunscreen Sun Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Organics Face And Body Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Organics Kids Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Kiss My Face Organics Spf-30 Mineral Sun",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kiss My Face Sensitive Fluoride-Free Whitening - Citrus Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kiss My Face Spf-15 Dry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Spf-50 Baby Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Spf-50 Daily Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Spf-50 Face Factor", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Spf-50 Face Factor Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Sunscreen Cool Sport Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Sunscreen Face Factor Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Sunscreen Sun Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss My Face Sunscreen Tattoo Shade Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss Therapy Medicated Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss Therapy Spf 30 Grapefruit", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss Therapy Spf 30 Strawberry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiss Therapy Spf 30 Superfruit", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kit 2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kit For The Preparation Of Lymphoseek (Technetium Tc 99M Tilmanocept)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kit For The Preparation Of Technetium Tc 99M Mebrofenin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kit For The Preparation Of Technetium Tc 99M Medronate", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kit For The Preparation Of Technetium Tc99M Mertiatide", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kit For The Preparation Of Technetium Tc99M Pyrophosphate", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Kit For The Preparation Of Technetium Tc99M Sestamibi",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }, { Route: "Parenteral" }] },
        ],
    },
    { Medication: "Kit For The Prepartion Of Technetium Tc99M Sulfur Colloid", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kit1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kitabis Pak", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Kivoria Acne Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kivoria Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kiwi Burst Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klarex Cleanse", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klaron", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klavuu Uv Protection Secret Tone Up Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klavuu White Pearlsation Ideal Actress Backstage Cream Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean 4 In 1 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean 4 In 1 Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean 4-In-1 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean 5 In 1 Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Freak", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Hand Sanitizer Fluid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Hand Sanitizer Fluid Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Hand Sanitizer Fluid Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Hand Sanitizer Gel Fresh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Hand Sanitizer Gel Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Sanitizing Wipes Fresh", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Sanitizing Wipes Lavender", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean Genix Sanitizing Wipes Unscented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Klean-75",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Klean-Hands", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klean-Them Hand Scrubbing Towels", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleanmann - Hand Sanitizer And Surface Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleann Go Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleanops", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleanya Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleanya Hand Sanitizer Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleen Freak Disinfecting Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleen Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleen Hands", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleen Start Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleenex Antimicrobial Foam Skin Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleenex Antiseptic Skin Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleenex Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleenex Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleenex Reveal Moisturizing Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleenline Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleer-Plex Penetrating Acne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kleer-Plex Targeted Acne Pen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenlii Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Klenlii Hand Sanii Alcohol Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Klenlii Sanii Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Hand Sanitizing Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Kids Showeron Sunscreen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Showeron Sunscreen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Showeron Sunscreen Spa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Spf Lip Balm Broad Spectrum", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Sunscreen Face And Body Broad Spectrum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klenskin Wash On Sunscreen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klerax", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klifem", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Klisyri", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Klonopin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Klor-Con",
        DoseTypes: [
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Klor-Con M", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Klor-Con M20", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Klor-Con Sprinkle", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Klor-Con/Ef", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kloxxado", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Klx Antibaterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Klx Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kmf Hand Sanitizer Cleansing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kndr Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Knee Pain Relief Patchs", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Knock Out 75% Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ko-C100", DoseTypes: [{ DoseType: "Tablet, For Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Koa Protect Our Ocean Life Oxybenzone Free Broad Spectrum Spf 30 Uva Uvb Protection Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Koala Kubs Diaper Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Koala Pals", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Koate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Intravenous" }] }] },
    { Medication: "Kochia Scoparia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Koelers Grass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Kofal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kofal Fuerte", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kofal Original", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kofix Cherry And Blackberry", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kofix Ginger", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kogenate Fs", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kollourion Eyesalve Eyewash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Kolorz Neutral Fluoride Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kolorz Sixty Second Fluoride", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kolorz Sixty Second Fluoride Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kolorz Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kombiglyze", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kondremul", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kong Handipod", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Konix Hand Antiseptic", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Konix Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Konix Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Konsyl Naturally Sweetened Psyllium Fiber", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Konsyl Orange Daily Fiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Konsyl Orange Flavor Psyllium Fiber - Smooth Texture", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Konsyl Original Daily Fiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Konsyl Original Formula Psyllium Fiber", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Konsyl Sugar Free Orange Daily Fiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Konsyl Sugar Free Orange With Stevia", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kontakt Ii Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Koong Yick Hak Kwai Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Koong Yick Hung Fa", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kope Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Korea Calcium King", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Korlym", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Korsuva", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Koru Performance Sunscreen Spf40",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Koselugo", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kosette Care Hand Sanitizer Alcohol-Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kosette Salt Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kosher Meds", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kovaltry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kreosotum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Krintafel", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kristalose", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kroger",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Kroger Allergy Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Anti Nausea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Antibacterial Green Apple Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Kroger Baby Spf 50 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kroger Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Clarify Care Acne Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Company", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kroger Cool Mint Boost Mouth", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Digestive Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Dry Oil Tanning Sunscreen Broad Spectrum Spf 15 Reef Friendly", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Dry Touch Ultra Sheer Spf 100 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Dry Touch Ultra Sheer Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Dual Effects Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Enamel Protection Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Kroger Extra Strength",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kroger Extra Strength Smooth Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger High Performance Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Kroger Hot And Cold Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Kids Anticavity Fluoride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Kroger Kids Spf 50 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Kroger Maximum Strength Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Medicated Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Mucus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Nitetime Cold And Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Odor Free Arthricream Rub Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Regular Strength", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Senna Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Sheer Dry-Touch Sfp 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Sport Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Sport Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Tanning Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kroger Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Kroger Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Krt Athletic Muscle Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Krt Heat Muscle Endurance Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Krud Kutter Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Krystexxa", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Kt Recovery Plus Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Fresh Water Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure And Safe Pocket Perfume Hand Sanitizer Mist Assortment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure And Safe Pocket Perfume Hand Sanitizer Mist Baby Powder", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure And Safe Pocket Perfume Hand Sanitizer Mist Cherry Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure And Safe Pocket Perfume Hand Sanitizer Mist Herb Mint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure And Safe Pocket Perfume Hand Sanitizer Mist Lemon Verbena", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure And Safe Pocket Perfume Hand Sanitizer Mist White Musk", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure And Safe Sanitizing Wipe (Ethanol)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure Refreshing Whitening Tooth (Spearmint)", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kundal Pure Safe Pocket Perfume Hand Sanitizer Mist Baby Powder", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure Safe Pocket Perfume Hand Sanitizer Mist Cherry Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure Safe Pocket Perfume Hand Sanitizer Mist Herb Mint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure Safe Pocket Perfume Hand Sanitizer Mist Lemon Verbena", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Pure Safe Pocket Perfume Hand Sanitizer Mist White Musk", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kundal Sensitive Dental Care Tooth (Apple Chamomile Mint)", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Kung Fu Panda Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kunigel Moisturizing Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kurvelo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Kutkit", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Kuvan",
        DoseTypes: [
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Kvg Group, Inc.", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kwan Loong", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Kwik-Moving",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ky", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ky Duration For Him", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ky Duration For Men", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kybella", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Kyleena", DoseTypes: [{ DoseType: "Intrauterine Device", Routes: [{ Route: "Intrauterine" }] }] },
    { Medication: "Kylieskin By Kylie Jenner Hand Sanitizer Non-Sterile Solution Alcohol Antiseptic 80% Topical Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kymriah", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Kynmobi",
        DoseTypes: [
            { DoseType: "Film, Soluble", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Kit", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Kypris Pot Of Shade Heliotropic Broad Spectrum Spf 30 Sunscreen And Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Kyprolis", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "L Bri Pure N Natural Deep Clean Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L Dopa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L Occitane En Provence Lavender Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L'Eclat Blanc Toothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L'Eclat Imperial Toothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L'Eclat Rose Toothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Arginine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Arginine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Cysteine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Cysteine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Dopa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Dopa Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Glutamine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Glutamine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Glycine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Glycine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Histidine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Histidine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Isoleucine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Isoleucine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Leucine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Leucine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Lysine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Lysine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Methionine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Methionine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Oral Allergex Hay Fever And Allergy Relief Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Oral Cold And Flu Relief Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Oral Cold And Flu Relief-Ds Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Oral Cough Relief Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Oral Gas Relief Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Oral Paracetamol Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Oral Stomach Relief Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Phenylalanine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Phenylalanine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Taurine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Taurine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Threonine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Threonine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Topical 50% Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Topical Benzoyl Peroxide 10%", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Topical Benzoyl Peroxide 10% Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Topical Benzoyl Peroxide 5%", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Topical Benzoyl Peroxide 5% Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Topical Calamine 8% Pramoxine Hcl 1%", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Topical Calamine 8% Zinc Oxide 8%", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Topical Povidone Iodine, 7.5% Scrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Topical White Petrolatum 30%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "L-Trans", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Tryptophan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Tryptophan High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Tyrosine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Tyrosine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Valine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L-Valine High", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "L.O.L Surprise Fresh Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Bodies Eclipse Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Bodies Eclipse Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Bodies Purita Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Defense Broad Spectrum Spf 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Flecha Japonesa", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Fresh Antiperspirant Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Grace Aqua Perfect Hand Cleaner", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Grace Aqua Perfect Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Mer The Broad Spectrum Spf 50 Uv Protecting Fluid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Mer The Luminous Lifting Cushion Foundation Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Mer The Radiant Skintint Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Mer The Soft Moisture Foundation Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 - Almond Beige", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 - Honey Beige", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Creme Peche", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Golden Beige", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Mocha", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Petale", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Pure Ivory", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Satin Nude", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Soft Ivory", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Sunset Beige", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Warm Beige", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Concealer-Foundation Sunscreen Spf 15 Warm Linen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "La Prairie Skin Caviar Essence In Foundation Spf 25 Golden Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Prairie Skin Caviar Essence In Foundation Spf 25 Honey Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Prairie Skin Caviar Essence In Foundation Spf 25 Pure Ivory", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Prairie Skin Caviar Essence-In-Foundation Spf 25 Creme Peche", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "La Prairie Skin Caviar Essence-In-Foundation Spf 25 Peche", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Prairie Skin Caviar Essence-In-Foundation Spf 25 Porcelaine Blush", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "La Prairie Skin Caviar Essence-In-Foundation Spf 25 Tender Ivory", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Anthelios Sunscreen Spf 60 Ultra Light", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Face Body Anthelios Spf 60 Melt In Milk Fast Absorbing, Velvety Finish For All Skin Types With Cell-Oc Shield Xl", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 30 Cooling Water Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 45 Ultra Light Sunscreen Fluid Broad Spectrum Spf 45 Sunscreen Water Resistant 40 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 45 Ultra Light Sunscreen Fluid Water Resistant 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 50 Daily Antiaging Primer With Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 50 Mineral Body Broad Spectrum Spf 50 Ultra Light Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 50 Mineral Daily Tone Correcting Primer Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 50 Tinted Mineral Face Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 50 Tinted Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 60 Breakout Free Oil Free Face Dry Touch Broad Spectrum Spf 60 Sunscreen Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 60 Face And Body Cooling Water Broad Spectrum Spf 60 Sunscreen Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 60 Face Light Fluid Sunscreen Broad Spectrum Spf 60 Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 60 Face Ultra Light Fluid Sunscreen Broad Spectrum Spf 60", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 60 Melt In Sunscreen Milk Broad Spectrum Spf 60 Face And Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 60 Melt In Sunscreen Milk Face And Body Broad Spectrum Spf 60 Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios 60 Ultra Light Sunscreen Broad Spectrum Spf 60 Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios Aox Daily Antioxidant Serum Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios Broad Spectrum Spf 100 Body And Face Melt In Milk Sunscreen Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios Clear Skin Face Oil Free Dry Touch Broad Spectrum Spf 60 Sunscreen Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios Dermo Kids Gentle Face And Body Broad Spectrum Spf 60 Sunscreen Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios Ha Mineral Broad Spectrum Spf 30 Daily Moisturizing Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios Sx Daily Moisturizing Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Anthelios Uv Correct Broad Spectrum Spf 70 Daily Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Cicaplast Balm B5 Soothing Therapeutic Multipurpose", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Effaclar Clarifying", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Effaclar Duo Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Effaclar Pore Refining Acne Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Hydraphase Uv Intense Moisturizer Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Lipikar Eczema Soothing Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Pigmentclar Daily Dark Spot Correcting Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Rosaliac Daily Complete Tone Correcting Broad Spectrum Spf 30 Sunscreen Cc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Sport Anthelios Activewear Broad Spectrum Spf 60 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratoire Dermatologique Toleriane Double Repair Moisturizer Uv Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Laboratorie Dermatologique Effaclar Medicated Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay New 50 Body And Face Spf 50 Mineral Anthelios Gentle 100 Percent Mineral Uv Filters Soft Finish With Cell Ox Shield", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche Posay Redermic C Uv Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Roche-Posay Effaclar Duo Dual Action Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Vaquita", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "La Vaquita New Regular", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lab 52 Essential Oil Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Lab Amaros Camellia Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lab Series Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lab Series Day Rescue Defense Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lab Series Skincare For Men", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lab52 Anti-Cavity Oral Care", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Lab52 Anti-Sensitivity Oral Care", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Lab52 Kids Oral Protection", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Labccin Fresh Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Labccin V3 Fresh Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Labetalol Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Labetalol Hcl In Dextrose", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Labetalol Hcl In Sodium Chloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Labetalol Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lac Caninum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lac Caninum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lac Defloratum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lac Felinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lacellate Solution - 1000", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lacellate Solution - 250", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lacellate Solution - 500", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lachesis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lachesis Belladonna",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lachesis Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lachesis Mutus",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Lachesis Mutus 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Lachesis Mutus Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lachnanthes Tinctoria", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lacosamide",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lacosamide Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lacovit Deodorant Antiperspirant Original", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lacrisert", DoseTypes: [{ DoseType: "Insert", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lacsian New Perfect All Care Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lactated Ringers",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] },
        ],
    },
    { Medication: "Lactated Ringers And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lactated Ringers Irrigation", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Irrigation" }] }] },
    { Medication: "Lactation Calm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lactation Flow Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lacticplus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lacticum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lactigo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactistat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lacto Pack D29 Cream Care", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Deodorant Antiperspirant Invisible", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Deodorant Antiperspirant Lactourea", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Deodorant Antiperspirant Men", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Deodorant Antiperspirant Men Invisible", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Deodorant Antiperspirant Original", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Deodorant Antiperspirant Sensitive", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Invisible Antistain Deodorant And Antiperspirant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Men Deodorant And Antiperspirant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Men Invisible Antistain Deodorant And Antiperspirant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Original Deodorant And Antiperspirant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactovit Sensitive Skins Deodorant And Antiperspirant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lactuca Virosa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lactulose", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }, { Route: "Oral; Rectal" }] }] },
    { Medication: "Lactulose Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }, { Route: "Oral; Rectal" }] }] },
    { Medication: "Lacura Everyday Clean 2In1Dandruff Plus Conditioner", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lacura Everyday Clean Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lacura Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lady Face Mineral Sunscreen, Spf 40 - Medium/Dark", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lady Speed Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lady Speed Stick Double Defense Active Fresh - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lady Speed Stick Double Defense Floral Fresh - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lady Speed Stick Invisible Dry Power Powder Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lady Speed Stick Invisible Dry Power Spring Blossom - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lady Speed Stick Invisible Dry Shower Fresh Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lady Speed Stick Power Wild Freesia", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lafco Hand Sanitizer Blue Mercury Grapefruit Lavender Cucumber", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lafco New York Hand Sanitizer Chamomile Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lafco New York Hand Sanitizer Champagne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laflare Santix Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lagarde", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lagevrio", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lagicam",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Lagrace Aqua Perfect Hand Sanitizerspray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lagrace Aqua Perfect Sanitizerspray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lake Trout", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Laline", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lamb",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Lambs Quarter", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Lambs Quarters Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Lamictal",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Lamisil",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lamisil At", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lamisil At Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lamivudine",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Lamivudine And Zidovudine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Lamotrigine",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lamotrigine Extended Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lampit", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lanacane", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lanacane First Aid", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lanashield", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancasco Professional Hygiene Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lanco 70 Isopropyl Alcohol Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lanco Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Absolue Revitalizing Brightening Velvet Spf 15 With Grand Rose Extracts", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Bx Makeup Broad Spectrum Spf 18 Sunscreen Absolute Replenishing Radiant Makeup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Bx Makeup Broad Spectrum Spf 18 Sunscreen Absolute Replenishing Radiant Makeup Normal To Dry Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Hand Premium Bx Broad Spectrum Spf 15 Sunscreen Absolute Anti Age Spot Unifying And Replenishing Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Makeup Broad Spectrum Spf 20 Sunscreen Absolute Replenishing Makeup For Dry And Very Dry Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Precious Cells Broad Spectrum Spf 15 Sunscreen Intense Revitalizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Precious Cells Sunscreen Repairing And Recovering Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Premium Bx Broad Spectrum Spf 15 Sunscreen Absolute Replenishing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Premium Bx Replenishing And Rejuvenating Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Absolue Revitalizing Brightening Velvet Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Bb Bienfait Teinte Beauty Balm Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Bienfait Multivital Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Bienfait Multivital Broad Spectrum Spf 30 Sunscreen 24 Hour Moisturization Antioxidant And Vitamin Enriched", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Bienfait Multivital Eye Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Bienfait Uv 50 Plus Broad Spectrum Spf 50 Plus Water Resistant 80 Minutes Super Fluid Facial Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris City Miracle Broad Spectrum Spf 50 Sunscreen Cc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris High Resolution Refill 3X Triple Action Renewal Anti Wrinkle Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Imanance Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris La Base Pro Redness Corrector Broad Spectrum Spf 10 Sunscreen Neutralizing Makeup Base", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Nude Miracle Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Renergie Lift Makeup Broad Spectrum Spf 26 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Renergie Lift Makeup Broad Spectrum Spf 27 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Renergie Lift Multi Action Broad Spectrum Spf 15 Sunscreen Lifting And Firming For Dry Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Renergie Lift Multiaction Lifting And Firming For All Skin Types Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Renergie Lift Multiaction Sunscreen Broad Spectrum Spf 15 Lift And Firming Face And Neck All Skin Types", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Renergie Lift Multiaction Ultra Firming And Dark Spot Correcting Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Renergie Lift Multiaction Ultra Lifting Firming Dark Spot Correcting Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Skin Feels Good Broad Spectrum Spf 23 Sunscreen Hydrating Skin Tint Healthy Glow", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Teint Idole Fresh Wear Enduring Shine Free Makeup Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Teint Idole Ultra 24H Makeup Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Teint Idole Ultra Care And Glow Skincare Foundation Broad Spectrum Spf 27 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Teint Idole Ultra Cushion Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Teint Idole Ultra Makeup Broad Spectrum Spf 21", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Teint Miracle Lit From Within Makeup Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Teint Visionnaire Skin Correcting Makeup Duo Foundation Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Uv Expert Aquagel Defense 50 Plus Sunscreen Broad Spectrum Spf 50 Plus Primer And Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Paris Visionnaire Broad Spectrum Spf 20 Sunscreen Advanced Multi Correcting All Skin Types", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Uv Expert Aquagel Defense 50 Broad Spectrum Spf 50 With Antioxidant Vitamin E", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lancome Uv Expert Mineral Cc Cream With Titanium Dioxide Spf 50 Color Correcting", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Land Shark Pups Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Land Shark Spf 15",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Land Shark Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Land Shark Spf 30",
        DoseTypes: [
            { DoseType: "Lipstick", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Land Shark Spf 30 Clear Zinc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Land Shark Spf 30 Tinted Zinc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Land Shark Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Land Shark Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Land Shark Spf 50",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Land Shark Spf 50 Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Land Shark Spf 8",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Land Shark Zinky Spf 36 Azure Blue", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Land Shark Zinky Spf 36 Pacific Purple", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Land Shark Zinky Spf 36 Pink Beach", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lander Amber Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Landshark", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laneige Hydro Uv Defense", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lani And Kai Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lanolin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lanolin, Petrolatum", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lanoxin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lanreotide Acetate", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Lansinoh Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lansoprazole",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lansoprazole, Amoxicillin And Clarithromycin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lansoprazole, Amoxicillin, Clarithromycin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lanthanum Carbonate", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lantus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Lantus Solostar", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Laonmedi Premium Rose Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lapatinib", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lapis Albus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lapis Lazuli", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Large Ansi First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Ophthalmic" }] }] },
    { Medication: "Large Intestine Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Large Intestine Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Large Joint Pain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Large Medicated Pain Relief Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Large Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Large Ring Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Larin 1.5/30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Larin 1/20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Larin 24 Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Larin Fe 1.5/30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Larin Fe 1/20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Larissia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Larkly Spf30 Mineral Powder Face Sunscreen", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Larynx Bryonia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Larynx Bryonia Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Larynx Levisticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lasix", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laspa Broad Spectrum Mineral Sunscreen Spf 50 Ultra Sun", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laspa Daily Sun Protection Broad Spectrum Mineral Sunscreen Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laspa Moisturizing Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laspa Tinted Matte Sunscreen Spf 20 Bronze", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laspa Tinted Matte Sunscreen Spf 20 Deep Umber", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laspa Tinted Matte Sunscreen Spf 20 Golden", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laspa Tinted Matte Sunscreen Spf 20 Light", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laspa Tinted Matte Sunscreen Spf 20 Rich Brown", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lastacaft", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lasting Foundation Spf 12", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Latalia Alcohol Antiseptic 80 Sanitizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Latalia Hand Sanitizer 80 Alcohol Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Latalia Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Latalia Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Latanoprost",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Lath Nonistoothpaste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lather - Hand Sanitizer With Moisturizing Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lather - Lavender Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lather Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lather- Daily Defense Mineral Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lather- Hand Sanitizer With Moisturizing Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lathyrus Sativus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Latisse", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Latrodectus Mactans", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Latuda", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laughing Linen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laundry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Ashley Antibacterial Hand Sanitizer Aloe Vera Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Ashley Antibacterial Hand Sanitizer Cotton Blossom Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Ashley Antibacterial Hand Sanitizer Pink Peony Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Geller Antibacterial Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Geller Better Than Bare Tinted Moisturizer Broad Spectrum Sunscreen Spf 50 Plus Beige 200", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Geller Better Than Bare Tinted Moisturizer Broad Spectrum Sunscreen Spf 50 Plus Deep 430", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Geller Better Than Bare Tinted Moisturizer Broad Spectrum Sunscreen Spf 50 Plus Fair 130", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Geller Better Than Bare Tinted Moisturizer Broad Spectrum Sunscreen Spf 50 Plus Medium 230", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Geller Better Than Bare Tinted Moisturizer Broad Spectrum Sunscreen Spf 50 Plus Sand 400", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Flawless Skin Daily Face Shield Spf 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Flawless Skin Repair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Flawless Skin Repair Day Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Pure Canvas Primer Protecting Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen Lightweight Flawless Coverage Cameo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen Lightweight Flawless Coverage Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen Lightweight Flawless Coverage Ochre", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 0N1 Petal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 0W1 Pearl", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 1C0 Cameo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 1W1 Porcelain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 2N1 Nude", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 2W1 Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 3N1 Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 3W1 Bisque", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 4C1 Almond", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 4W1 Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 5N1 Walnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 5W1 Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 6C1 Cacao", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Light Revealer Natural Skin Illuminator Broad Spectrum Spf 25 Sunscreen 6N1 Mocha", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Natural Skin Perfector Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Oil Free Natural Skin Perfector Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Almond", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Bisque", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Blush", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Fawn", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Mocha", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Nude", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Porcelain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laura Mercier Tinted Moisturizer Spf 20 Walnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Laurocerasus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lava Lava Lip Balm Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavaderm After Sun", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavelier Divine Day Defense Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavendar Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Aloe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Frost Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Frost Scented Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lavender Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lavender Hand Sanitizer Girl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Hand Sanitizer Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Life Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Quartz Ear Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Sanimoist Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavender Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lavender Scented Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lavender Verbena Aromar Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lavinna First Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lawn And Garden Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lax-Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lax-Time", DoseTypes: [{ DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laxa-Basic 100", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laxacin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Laxative",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Laxative And Stool Softener", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laxative Docusate Sodium With Senna", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laxative For Women", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laxative Pills", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laxative Senna", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laxative Stool Softener", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Laxative Tabelts", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Layolis Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lazanda", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 110-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 130-F", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 140-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 150-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 160-N", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 200-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 210-F", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 240-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 250-F", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 260-N", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 270-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 280-F", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 290-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 300-N", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 320-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Clarite Makeup Foundation For A Clearer Appearance Spf 30 330-C", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Concentre Total Base Serum Ultra-Fluid Foundation Spf 25", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Defense Total Broad Spectrum Spf 50Plus Sunscreen For Face And Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Dermo Clarite Brightening And Moisturizing Facial Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Divine Double Use Compact Spf 15 Gold Deluxe Edition Obscure 8-9", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Effet Parfait Signs Of Age Appearance Minimizer Foundation Spf 20 Fm", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Effet Parfait Signs Of Age Appearance Minimizer Foundation Spf 20 Fm Claire 2", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Effet Parfait Signs Of Age Appearance Minimizer Foundation Spf 20 Fm Claire 3", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Effet Parfait Signs Of Age Appearance Minimizer Foundation Spf 20 Fm Claire 4", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Effet Parfait Signs Of Age Appearance Minimizer Foundation Spf 20 Fm Medium 5", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Effet Parfait Signs Of Age Appearance Minimizer Foundation Spf 20 Fm Medium 6", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Effet Parfait Signs Of Age Appearance Minimizer Foundation Spf 20 Fm Medium 7", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Lederm 35 Plus Jour Facial Against First Signs Of Aging", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Lederm 45 Plus Jour Facial Against Noticeable Signs Of Aging", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Lederm 55 Plus Jour Facial Against Advanced Signs Of Aging", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Libertage Cc Matte Perfectioning-Effect Moisturizing Face Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Claire 1", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Claire 2", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Claire 3", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Claire 4", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Medium 5", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Medium 6", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Medium 7", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Obscure 8", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Mousse Matiffiant Matte Finish Mousse Foundation Spf 15 Obscure 9", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Nude Skin Ultra Light Matte Foundation With Vitamins Spf 20 Almendra 230-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Nude Skin Ultra Light Matte Foundation With Vitamins Spf 20 Beige 220-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Nude Skin Ultra Light Matte Foundation With Vitamins Spf 20 Capuccino 310-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Nude Skin Ultra Light Matte Foundation With Vitamins Spf 20 Champagne 170-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Nude Skin Ultra Light Matte Foundation With Vitamins Spf 20 Latte 180-F", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbel Nude Skin Ultra Light Matte Foundation With Vitamins Spf 20 Mokaccino 390-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbri Pure N Natural Diaper", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbri Pure N Natural Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbri Pure Nnatural Oil Free Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lbri Super Aloe Stop The Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ld Force", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ld Force Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ldr Vitamans A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Le Lou Collagen Day Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Le Mieux Just Glow Bb Cream Spf 50", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Le Vital Japanese Cherry Blossom", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Le Vital Moon To Light", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Le Vital Sweet Pea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Le Vital Vanilla Heaven", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lea Black", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Leader",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leader 12 Hour Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader 2% Mild Iodine Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader 8 Hr Muscle Aches And Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader 8Hr Arthritis Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Acid Reducer Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Advanced Relief Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Alcohol 70 Isopropyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader All Day Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader All Day Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader All Day Sinus And Cold D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Allergy Relief D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Allergy Relief D-24", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Allergy Relief D12", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Leader Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Leader Anti Diarrheal",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leader Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Artificial Tears 15 Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Leader Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leader Athletes Foot Clotrimazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Baby Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Calamine Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Caldyphen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Caldyphen Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Camphor Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Capsaicin Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Castor", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Castor Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Cherry Sore Throat Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Childrens Allergy Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Childrens Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Childrens Mucus And Cough Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Childrens Mucus Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Cold Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Cough", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Dandruff Everyday Clean", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Daytime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Daytime Severe Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Decolorized Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Dry Eye Relief Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Ear Drops For Swimmers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Leader Earwax Removal Kit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Leader Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Emergency Contraceptive Levonorgestrel", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Extra Strength Acetaminophen", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Extra Strength Effervescent Antacid And Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Extra Strength Sugar Free Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Eye Drops Redness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Eye Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Leader Fiber",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Leader Gas Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leader Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Leader Glycerin", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Leader Hemorrhoidal", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Leader Honey Lemon Benzocaine Throat Lozenge", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Hydrocortisone With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Leader Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leader Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Ice Blue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Infants Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Infants Ibuprofen", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Kids", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Lice Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Lidocaine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Liquid Bandage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Loperamide Hydrochloride", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Lubricant Eye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Lubricant Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Lubricant Eye Drops 30Ct", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Lubricating Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Maximum Strength Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical; Vaginal" }] }] },
    { Medication: "Leader Maximum Strength Mucus Relief Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Maximum Strength Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Maximum Strength Stomach Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Medicated Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Medicated Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Mild Iodine", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Mineral Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Mixed Berry Benz-Dex Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Mucus Relief Cold Flu And Sore Throat", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Mucus Relief D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Multi Symptom Flu And Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Leader Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Leader Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Nausea Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Nighttime Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Omeprazole", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Original Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Pain Relief Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Poison Ivy Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Poly Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Povidone - Iodine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Povidone-Iodine 10% Topical Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Preservative-Free Ultra Lubricating Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Ready To Use Mineral Oil", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Leader Ready To Use Saline", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Leader Regular Strength Chocolate Laxative Sennosides", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Sinus Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Leader Sinus Wash Saline Refill Packets", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Leader Sleep Aid",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Leader Stomach Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leader Therapeutic Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Ticonazole", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Leader Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Triple Antibiotic And Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Tussin Dm Cough Plus Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Ultra Lubricating Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Leader Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Ultra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Ultra Strength Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leader Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Vapor Steam Camphor (Synthetic)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Leader Womens Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leader Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leahue Madhuca", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leahue Madhuca Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leaky Gut Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Learning Disability Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lebody Bu W Renewal Up", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Face Renewal", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Fit", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Fit Body Massager", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Fit Cool Body Massager", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Fit Hot Body Massager", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Lab Renewal Dual Effect 24K Gold Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Lab Renewal Dual Effect Btx Collagen Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Lab Renewal Dual Effect Hyaluronic Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Lab Renewal Dual Effect Idebenone Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lebody Lab Renewal Dual Effect Peptide Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lechat", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lederm Day Sunscreen Broad Spectrum Spf 15 Day Facial Spf 15 Against Noticeable Signs Of Aging All Skin Types", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ledipasvir And Sofosbuvir", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ledum Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ledum Palustre",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Ledum Palustre 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Ledum Palustre 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ledum Palustre 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Ledum Palustre Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leena", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Leep Redikit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Leflunomide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Leg And Back Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leg Cramp Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leg Cramp Rx", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Leg Cramps",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leg Cramps Pm", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leg Cramps Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Leg Cramps-Swelling", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leg Pain Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Legatrin Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Legend Age Whitening Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Legere Bamboo Foot Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Legere Eucalyptus Foot Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Legere Lavendar Foot Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Legionella Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lemaitre Sulfur", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemna Minor", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lemon",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
            { DoseType: "Swab", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lemon Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Drop Gentle Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Fresh Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Glycerin", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Oral" }, { Route: "Topical" }] }] },
    { Medication: "Lemon Glycerine", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Lime Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Lime Spritz", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Love Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Myrtle Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Quartz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Quince", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lemon Scented Antiseptic Hand Sanitizer 80 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Scrubs Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Lemon Twist Hand Sanitiser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Twist Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon Verbena Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lemon Verbena Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lemon Verbena Hand Sanitizer Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon X Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon, Verbena And Cedar", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemon, Verbena And Cedar Hand Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemonade Hand Sanitizer Boy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemonade Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemonbella Lavender Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemongrass Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemtrada", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lemuel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemyn Organics Medical Grade Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lemyn Organics Medical-Grade Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lenalidomide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lens Scale Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Lenscale", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Lenscale Quailbrush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Lenvima",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Lenza Pro", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lenzapro Flex", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lenzapro Flex Path", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Leonurus Cardiaca", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lepidolite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leptandra Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leptandra Virginica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leqvio", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Les Beiges", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lescol", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Less Drowsy Formula Motion Sickness Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Less Drowsy Motion Sickness Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lessentiel Natural Glow Foundation 16H Wear With Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lessentiel Natural Glow Foundation 16H Wear With Sunscreen Broad Spectrum Spf 20 01N Tres Clair/Very Light", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lessina", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Letairis", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Letrozole",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lets Go Clean, Llc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lettuce",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Leucoflex Cgp", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Leucovorin Calcium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Leukeran", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Leukine", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Leukotrap - As-3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Leukotrap - As-3 Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Leukotrap - Cp2D", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Leukotrap - Cp2D Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Leukotrap Wb System",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Leukotrap Wb System - As3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Leukotrap Wb System - Cp2D", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Leuprolide Acetate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Levalbuterol",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    { Medication: "Levalbuterol Hydrochloride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Levalbuterol Inhalation", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Levalbuterol Inhalation Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Levalbuterol Tartrate Hfa Inhalation", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Levamlodipine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Levbid", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Level 1 Health Hand Sanitizer Liquid Non-Sterile Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Level1 Health Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Levemir", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Levertiracetam", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Levetiracetam",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Levetiracetam Extended Release", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Levetiracetam In Sodium Chloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Levico Prunus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Levigosp", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Levisticum E Rad. 3", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Levisticum Quercus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Levo-T", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Levobunolol Hydrochloride", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Levocarnitine",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Levocetirizine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Levocetirizine Dihydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Levofloxacin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }, { Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Levoleucovorin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Levoleucovorin Calcium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Levomilnacipran", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Levonest", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Levonorgestrel", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Levonorgestrel And Ethinyl Estradiol",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Levonorgestrel And Ethinyl Estradiol And Ethinyl Estradiol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Levophed", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Levophed(R) Norepinephrine Bitartrate", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Levora", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Levorphanol Tartrate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Levothroxine Sodium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Levothyroxine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Levothyroxine Sodium",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Levoxyl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Levsin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Levulan Kerastick", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lexapro", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lexette", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lexiscan", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lexiscan(R) (Regadenoson)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Lexiva",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lfg", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lfg-Ind-San Single Use Sanitizer Packet", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lfg-Ind-Wipes Single Use Alcohol Wipe Packets", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lgb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lhs108", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lialda", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liauid Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liberdol Analgesic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liberdol Topical Analgesic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Libido",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Libido Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Libidopro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Librax", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Libtayo", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Licart", DoseTypes: [{ DoseType: "System", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lice", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lice Complete", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lice Complete Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lice Solution Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lice Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lice Treatment Complete", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lice-Nil Lice And Eggs Eliminator Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liceall", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Licefreee",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Licefreee Everyday", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Licefreee Ultimate Family Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liceout", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidaflex", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidenzal 1%", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }] }] },
    { Medication: "Lidenzal 2%", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }] }] },
    { Medication: "Lidl", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidl Daytime Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lidl Dry Tanning Oil Sunscreen Spf 15", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidl Nightyime Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lidl Orange Smooth Fiber Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lidl Orange Smooth Fiber Powder Sugar Free", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lidl Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidl Ultra Strength Antacid Calcium Carbonate", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lido Bdk", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lido King", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lido Rx", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lidocaine",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }, { Route: "Intravenous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Cutaneous" }, { Route: "Percutaneous; Topical; Transdermal" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Lidocaine 4 Percent And Menthol 1 Percent Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine 4 Percent Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine 4%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine 4% Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine 4% Menthol 4%", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine 4% Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine 4% Topical Anesthetic Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lidocaine 5%",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Rectal; Topical" }, { Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lidocaine 5% Topical Anorectal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Rectal; Topical" }] }] },
    {
        Medication: "Lidocaine And Menthol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Oral" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lidocaine And Prilocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine And Prilocaine Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine And Tetracaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Anesthetic Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Anorectal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Lidocaine Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Dry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Dry Patch Plus Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lidocaine Hci",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal" }, { Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Infiltration; Perineural" }, { Route: "Intravenous" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lidocaine Hci And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lidocaine Hci And Epinephrine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }] }] },
    { Medication: "Lidocaine Hci And Epinephrine With Epinephrine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] }] },
    {
        Medication: "Lidocaine Hcl",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal" }, { Route: "Epidural; Infiltration; Intracaudal; Perineural" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Lidocaine Hcl - Hydrocortisone Acetate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Rectal" }, { Route: "Rectal; Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Lidocaine Hcl 4 Percent And Menthol 4 Percent", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lidocaine Hcl 4%",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lidocaine Hcl 4% Menthol 1%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lidocaine Hcl And Epinephrine",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }] },
        ],
    },
    {
        Medication: "Lidocaine Hydrochloride",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection", Routes: [{ Route: "Infiltration" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration" }, { Route: "Epidural; Infiltration; Intracaudal" }, { Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Infiltration" }, { Route: "Infiltration; Intravenous" }, { Route: "Infiltration; Perineural" }, { Route: "Intravenous" }, { Route: "Retrobulbar; Topical; Transtracheal" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }, { Route: "Oral" }, { Route: "Oral; Topical" }, { Route: "Oropharyngeal" }, { Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lidocaine Hydrochloride And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lidocaine Hydrochloride And Epinephrine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural" }, { Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Infiltration" }, { Route: "Infiltration; Perineural" }] }] },
    { Medication: "Lidocaine Hydrochloride And Epinephrine Bitartrate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Lidocaine Hydrochloride And Hydrocortisone Acetate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Rectal" }, { Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Lidocaine Numbing Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Pain Relief Gel Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Pain Relief Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Pain Relief With Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Patchs", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine Viscous", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }, { Route: "Oral; Topical" }] }] },
    { Medication: "Lidocaine With Menthol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocaine With Menthol Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocanna", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocare", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocare Arm, Neck, Leg", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidocort", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Rectal; Topical" }] }] },
    { Medication: "Lidocream Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidoderm", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Lidodose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidoer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidofore 4% Flexipatch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidoforte Hemorrhoidal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidoheal-90", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidolog Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intramuscular; Topical" }] }] },
    { Medication: "Lidomark 1/5", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] }] },
    { Medication: "Lidomark 2/5", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intracaudal" }] }] },
    { Medication: "Lidomet-10 Kit", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidopatch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidoplus Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidopro", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidopro Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidopure Patch", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lidoreal-30 4% Topical Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidorex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidosport Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidostat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lidothol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lidotor", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidovix L", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lidoxryl", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Lidozen",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Liebers Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lien Cichorium",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lien Plumbum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lien Plumbum Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Life Detoxer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Life Extension Shade Factor Spf30",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Life Is Healed", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Life Is Simple Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Life Q", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Life Quality Hand Sanitzer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Life Quality Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Life Quality Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lifebasic Zero Care Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lifebasic Zero Care Sanitizerspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lifebuoy Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lifebuoy Hand Sanitizer Professional", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lifecare Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lifecare Antibacterial Sanitizing Wet Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lifecell",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lifeguard Disinfecting Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lifemedz Rinse-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lift Eyecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liftintense Sculpting With Mineral Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ligamentinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ligapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ligatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Light Day Sunscreen Spf 37", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Hearted Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Caramel M5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Classic Tan M3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Ivory L2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Mocha D3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Natural Beige M1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Nude L3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Nutmeg D2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Porcelain L1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Sable D1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Soft Sand M2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Tawny M4", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All Day Wear Broad Spectrum Spf18 Sunscreen Warm Beige L4", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Illusion Luminous Makeup Nude-Skin Feel All-Day Wear Broad Spectrum Spf18 Sunscreen 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Medium Tinted Drops", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Moisturizing Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Skin Defense Concealer Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Tint Clear Zinc Solar Protection Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Light Wonder Your Light, Dream Skin Foundation Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lightning Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lights Antiseptic Towelette", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lights Isopropyl Alcohol Prep Pads", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lights Povidone Iodine Prep Pad", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lights Povidone Iodine Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lights Povindone Iodine Scrub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lights Povindone Iodine Swabstick", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lights Swabstick", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lightweight Miracle Moisturiser Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lightweight Sheer Daily Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lignospan Forte", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Lignospan Standard", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Ligustrum Vulgare Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Likewise Facial Moisturizer - Sun Protectant Spf 50 Normal To Dry Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Likewise Facial Moisturizer - Sun Protectant Spf 50 Normal To Oily Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Likewise Facial Moisturizer - Sun Protectant Spf 50 Oily Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Likewise Hand Moisturizer - Sun Protectant Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lil Chubs Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lil Drug Store Allergy Sinus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Anti-Diarrheal", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Aspirin 325", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Claritin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lil Drug Store Headache Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lil Drug Store Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lil Drug Store Infants Pain Reliever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Multi-Symptom Sinus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Multi-Symptom Sinus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Nyquil Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lil Drug Store Products Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Tylenol Cold Plus Flu Severe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Tylenol Pm Extra Strength", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Drug Store Tylenol Sinus Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lil Goats Petite Chevre Fragrance Free", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lil Goats Petite Chevre Original", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lilas Feminine Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liletta", DoseTypes: [{ DoseType: "Intrauterine Device", Routes: [{ Route: "Intrauterine" }] }] },
    { Medication: "Lilikoi Light Defense Face Primer Spf 23", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lilikoi Mineral Defense Moisturizer Spf 33", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lilikoi Mineral Defense Sport Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lilium Tigrinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lilkoi Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lilkoi Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lillow", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lily Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lima Bean",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Limbex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lime Hint Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lime Safe Sanitizer Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lime Safe Sanitizer Plusspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lime Scented Antiseptic Hand Sanitizer 80 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Limencin",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Limetcam", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Limpia Pecho", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Limpiador De Ojos Weye", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Limpiel70Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lincocin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subconjunctival" }] }] },
    { Medication: "Lincomycin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intramuscular; Intravenous; Subconjunctival" }] }] },
    { Medication: "Lindane", DoseTypes: [{ DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Linden Flower Tinc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Linden Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Linezolid",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ling Nam Hak Kwai Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ling Nam Hung Far", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ling New York - Hand Shield", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 00N Porcelain", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 01N Very Light", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 02C Light Cool", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 02N Light", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 03N Natural", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 03W Natural Warm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 04N Medium", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 05W Deep Warm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Aqua Nude Water-Infused Perfecting Fluid Intense Hydration Long-Wear Foundation With Sunscreen Broad Spectrum Spf 20 06W Very Deep Warm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Bb Invisible Skin-Fusion Multi-Perfecting Makeup With Sunscreen Broad Spectrum Spf 30 Bb 2 Light", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Bb Invisible Skin-Fusion Multi-Perfecting Makeup With Sunscreen Broad Spectrum Spf 30 Bb 3 Natural", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Bb Invisible Skin-Fusion Multi-Perfecting Makeup With Sunscreen Broad Spectrum Spf 30 Bb 4 Medium", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 00N Porcelaine Porcelain", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 02C Clair Rose Light Cool", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 02N Clair Light", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 02W Clair Dore Light Warm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 03C Naturel Rose Natural Cool", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 03N Naturel Natural", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 03W Naturel Dore Natural Warm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 04C Moyen Rose Medium Cool", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 04N Moyen Medium", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 04W Moyen Dore Medium Warm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 05C Fonce Rose Deep Cool", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 05N Fonce Deep", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lingerie De Peau Natural Perfection Foundation Skin-Fusion Texture With Sunscreen Broad Spectrum Spf 20 06W Tres Fonce Dore Very Deep Warm", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Linhart", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Linstol Refreshing And Cleaning Towelette", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Linstol Refreshing And Cleansing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Linstol Refreshing And Cleansing Towelette", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Linzess", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lioresal (Baclofen)", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Liothyronine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Liothyronine Sodium",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Lip Balm",
        DoseTypes: [
            { DoseType: "Lipstick", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lip Balm 4Pc Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lip Balm Vanilla Flavored", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lip Conditioner Spf 15", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lip Healer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lip Recovery", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lip Screen Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lip Screen Sheer Spf 30", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lip Shield", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lip Shine Spf35 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lipiodol", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intra-Arterial; Intralymphatic; Intrauterine" }] }] },
    { Medication: "Lipitor", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liplux", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lipo-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lipocaine 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lipofen", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lipostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lipotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liprotect Spf 35", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liprotek", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liquid Acetaminophen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liquid B", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liquid Hand Cleanse", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Liquid Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Liquid Polibar Plus", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral; Rectal" }] }] },
    { Medication: "Liquid Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liquid Serotonin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liquid Wart Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liquidambar Styraciflua Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Liquimat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lisinopril", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lisinopril And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lisinopril With Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lisinopril/Hctz", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lisinoril", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Cool Mint Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Essential Care", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Freshburst Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Gum Therapy Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Healthy White Restoring Anticavity Fluoride Clean Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Healthywhite Vibrant Clean Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Naturals Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Naturals Enamel Repair Anticavity Fluoride, Herbal Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Nightly Reset Anticavity Fluoride Twilight Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Smart Rinse Anticavity Fluoride Rinse - Berry Splash", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Smart Rinse Anticavity Fluoride Rinse - Bubble Blast", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Smart Rinse Anticavity Fluoride Rinse - Mint Shield", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Smart Rinse Anticavity Fluoride Rinse - Pink Lemonade Flavor", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Smart Rinse Anticavity Rinse - Frozen", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Smart Rinse Anticavity Rinse Bubble Blast", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Smart Rinse Berry Splash", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Smartrinse Anticavity Fluoride Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Strong Teeth Anticavity Fluoride Mint Shield", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Total Care Anticavity", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Total Care Fresh Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Total Care Stain Remover Anticavity Fluoride Fresh Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Total Care Zero Alcohol Anticavity", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Ultraclean Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Ultraclean Arctic Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Listerine Ultraclean Fresh Citrus", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lite Nfoamy E2 Sanitizing Handwash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lite Nfoamy E3 Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lite Nfoamy Eucalyptus Mint Sanitizing Handwash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lite Nfoamy Healthcare Personnel Handwash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lite Nfoamy Lemon Blossom Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lithium",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lithium Bromatum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lithium Carb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lithium Carbonate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lithium Carbonicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lithium Muriaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lithobid", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lithostat", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Little Bubs Diaper", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Little Noses Decongestant Nose Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Little Remedies",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Little Remedies Childrens Fever Pain Reliever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Little Remedies Decongestant Nasal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Little Remedies Infant Essentials", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Little Remedies Infant Fever/Pain Reliever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Little Remedies New Baby Essentials", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Liu He Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liva Clean", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Livalo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Livcalm", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Live Clean",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Live Clean Baby",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Live Love Travel Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Live Tinted Hueguard 3-In1 Mineral Sunscreen Moisturizer Primer Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Live Ultimate Facial Sunscreen Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Liver", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Comp. Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Detox", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Drainage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Gallbladder", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Plus Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Liver Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liver/Gallbladder Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liverex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Liverex I", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Livergen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Livesafe No Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Livesafe Orange Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Livestock Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Living Concepts Hand Sanitizer Advanced Aloe Vera", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Living Master Safe Good Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Livmarli", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Livrelief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Livtencity", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Livwell", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lixtraxen 1%", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }] }] },
    { Medication: "Lmd In Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lmd In Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lmnoop", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lmr Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lmx4", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lmx5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lo Loestrin Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lo Simpesse", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lo-Zumandimine", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Loa New-Freedom S", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lobelia Inflata",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lobelia Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lobelia Quercus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Loblolly Pine Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Lobster", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Locametz", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Loccitane En Provence Light Comforting Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Locke Teddy",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Locke Teddy Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Locoid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Locoid Lipocream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lodosyn", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loesch Hair Regrowth Formula", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Loestrin",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lofena", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Logically Skin Acne Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Logically Skin Liftderm Handcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Logically Skin Multi-Liftingcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Logically Skin Wrinkle Target Eyecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lohist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lohist Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lojaimiess", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lokelma", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lol Surprise Cotton Candy Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lol Surprise Cotton Candy Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lola Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lola Vaginal Itch Relief Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Loles",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Loles Naturals Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lolium Perenne Ssp Multiflorum Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Lollicaine Bubble Gum", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Lollicaine Cherry", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Lollicaine Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Lollicaine Pina Colada", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Lollipop Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lomaira", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lombard Poplar Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Lombardys Poplar Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Lomotil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "London Labs Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "London Labs Premium Hand Sanitizer With Antibacterial Tea Tree Oil", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loneliness And Sadness", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Long Acting Cough Softgels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Long Lasting Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Longleaf Pine Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Lonhala Magnair", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Lonol Forte Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lonsurf", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Look At Me Expert Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Look At Me Invisible Acne", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Looney Tunes Moisturizing Hand Cleanser With Alcohol Aqua", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Looney Tunes Moisturizing Hand Cleanser With Alcohol Pearl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Looney Tunes Moisturizing Hand Cleanser With Alcohol Rose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Looney Tunes Moisturizing Hand Cleanser With Alcohol Sweet Pea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lop Homeopathic Hair Conditioning For Eliminating Lice And Nits", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loperamide Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loperamide Hcl And Simethicone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Loperamide Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Loperamide Hydrochloride And Simethicone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loperamide Hydrochloride, Simethicone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lopid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lopinavir And Ritonavir", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lopinavir-Ritonavir", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lopreeza", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lopressor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Loprox",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lorac Foundation Fair", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lorac Foundation Golden Light", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lorac Foundation Golden Tan", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lorac Foundation Light", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lorac Foundation Light Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lorac Foundation Medium", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lorac Foundation Medium Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lorac Foundation Medium Tan", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lorac Foundation Tan", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Loratadine",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Loratadine Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Loratadine And Pseudoephedrine",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Loratadine And Pseudoephedrine Sulfate", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loratadine D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loratadine Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lorazepam",
        DoseTypes: [
            { DoseType: "Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lorbrena", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loreal Go 360 Clean Antibreakout Facial Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Luxe Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Advanced Suncare Invisible Protect Dry 50 Plus Broad Spectrum Spf 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Advanced Suncare Silky Sheer 30 Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Advanced Suncare Silky Sheer 50 Plus Broad Spectrum Spf 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Advanced Suncare Silky Sheer Bb Face 50 Plus Broad Spectrum Spf 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Advanced Suncare Silky Sheer Face 30 Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Advanced Suncare Silky Sheer Face 50 Plus Broad Spectrum Spf 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Age Perfect Cell Renewal Day Moisturizer Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Age Perfect Glow Renewal Day Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Age Perfect Hydra Nutrition Instant Glow Daily Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Age Perfect Radiant Serum Foundation Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Age Perfect Rosy Tone Spf 30 Renew And Revive Healthy Tone Visibly Younger-Looking Skin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Antisagging Plus Even Tone Collagen Expert Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Antispot Revitalift Triple Power Intensive Antiaging Day With Proxylane Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Day Age Perfect For Mature Skin Antisagging Even Skin Tone Hydrating Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Ever Pure Dandruff Scalp Care And Detox", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Hair Expert Ever Fresh Sulfate Free Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Ideal Moisture Dry Skin Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Ideal Moisture Even Skin Tone Moisturizer Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Ideal Moisture Normal Skin Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Ideal Moisture Sensitive Skin Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Infallible Longwear Shaping Stick Foundation Spf 27", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Infallible Proglow Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Infallible Up To 24 H Fresh Wear Foundation Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Magic Nude Bare Skin Perfecting Makeup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Men Expert Comfort Max Hydra Energetic After Shave Balm Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Men Expert Vita Lift Daily Moisturizer Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Revitalift Anti Wrinkle Firming Moisturizer Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Revitalift Antiwrinkle Plus Firming Spf 18 Sunscreen Day Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Revitalift Miracle Blur Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Revitalift Miracle Blur Instant Skin Smoother Original Opiblur Technology Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Revitalift Triple Power Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Skin Expert Revitalift Bright Reveal Brightening Day Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Skin Expertise Age Perfect Broad Spectrum Spf 20 Sunscreen Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Skin Expertise Day Moisturizer Futur E Daily Dose Of Pure Vitamin E Normal To Dry Skin Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Skin Paradise Water Infused Tinted Moisturizer Broad Spectrum Spf 19 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Sublime Bronze Summer Express Wash Off Face Bronzer Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris True Match Lumi Healthy Luminous Makeup Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris True Match Naturale Gentle Mineral Makeup Broad Spectrum Spf 19", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris True Match Super Blendable Foundation Broad Spectrum Spf 17 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris True Match Super Blendable Makeup Broad Spectrum Spf 17", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Visible Lift Blur Foundation Broad Spectrum Spf 18 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Visible Lift Cc Broad Spectrum Spf 20 Light Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Visible Lift Line Minimizing And Tone Enhancing Makeup Broad Spectrum Spf 17", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Visible Lift Medium Deep Cc Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Visible Lift Serum Absolute Advanced Age Reversing Makeup Broad Spectrum Spf 17", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Wrinkle Expert Age Defense 55 Plus Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Youth Code Broad Spectrum Spf 15 Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreal Paris Youth Code Dark Spot Corrector Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loreev Xr", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loris 10% Povidone-Iodine And 70% Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loris 7.5% Povidone-Iodine And 74% Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loris Bzk", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loris Povidone-Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lortab", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lortuss Lq", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loryna", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lorzone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Los Poblanos Lavender Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Losartan Pot/Hctz", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Losartan Potassium",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Losartan Potassium And Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Losartan Potassium Hctz", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Losartan Potassium Tablets, 100 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Losartan Potassium Tablets, 25 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Losartan Potassium Tablets, 50 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loseasonique", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Losortan Potassium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lotemax",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Lotemax Sm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lotensin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lotensin Hct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Loteprednol Etabonate",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Lotrel", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lotrimin",
        DoseTypes: [
            { DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lotrimin Af", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lotrimin Daily Prevention", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lotrimin Daily Prevention Deo", DoseTypes: [{ DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lotrimin Daily Sweat And Odor Control", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lotrimin Ultra", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lotrimin Ultra Jock Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lotronex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loud Lock Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lovali Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lovastatin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lovaza", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Love Beauty And Planet",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Love Juicy Juicy Couture Hand Sanitizer Non Scented 70 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Love Juicy Juicy Couture Non-Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Love Sun Body Spf 30 Fragrance Free", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Love Sun Body Spf 30 Lightly Scented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Love Sun Body Spf 50 Fragrance Free", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Love Sun Body Spf 50 Lightly Scented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Love You Berry Much", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loveit-Janitex Disinfecting Wipes 75% Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lovenox", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Low Back Pain", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Back Pain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Bacterial Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Bowel Pathogen Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Low Dose Aspirin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Low Dose Aspirin Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Dose Chewable Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Dose Miniprin", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Hepatitis Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Mycosis Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Periodontal Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Strength Chewable Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Vaccinosis Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low Virus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Low-Ogestrel", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Loxapine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Loyal Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Loyal Hand Sanitizer Liquid", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ls29 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ls30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ls31", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ls32", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ls33", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ls9", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lubelife Climax Control Delay", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lubiprostone",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lubracane", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lubricaine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lubricaine 5", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lubricant / Redness Reliever", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubricant Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Lubricant Eye",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubricant Eye Drops High Performance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubricant Eye Drops Preservative Free", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubricant Eye Drops Restorative Performance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubricant Eye Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubricant Pm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubricant Stye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubricating Eye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Lubricating Eye Drops",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Lubricating Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lubriderm Daily Moisture With", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucas Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucemyra", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lucentis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravitreal" }] }] },
    {
        Medication: "Lucky",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lucky 13S All Natural Tattoo Sunscreen Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucky Antiperspirant Deodorant Extreme Energy", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucky Antiperspirant Deodorant Fresh Comfort", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucky Antiperspirant Deodorant Spring Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucky Blue Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucky Epsom Salt", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lucky Hand Sanitizer Instant Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucky Ice Cold Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucky Instant Hand Sanitizer With Vitamin E And Moisturizers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lucky Super Soft",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lucky Super Soft Antifungal", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucky Super Soft Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lucky Supersoft",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lucky Supersoft Medicated Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lucy Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludaxine", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ludens", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Assorted Flavors", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Berry Assortment Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Green Apple Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Honey Lemon", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Honey Licorice Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Strawberry Banana Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Sugar Free Wild Cherry Throat", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Watermelon Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Wild Cherry Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ludens Wild Honey Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Luesinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Luffa", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Luffa Operculata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Luliconazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lululemon Selfcare No Nasties Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lumakras", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lumason",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Intravenous; Intravesical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Lumbagoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lumbar 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lumbar Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lumene Valo Day Moisturizer Spf 15 Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lumene Valo Nordic-C Brightening Day Fluid Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lumicain", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lumiere De Vie Daily Brightening Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lumify Redness Reliever Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lumigan", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Lumin Broad Spectrum Moisturizer With Spf 30 Pa Uva/Uvb Protection Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lumin Uv Defense Moisturizing Balm Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luminess Spf 30 Sunscreen Setting Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luminess Spf30 Suncreen Setting Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luminous White With Activated Charcoal Cwlt", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lumior",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lumior Empowered Day Moisturizer Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lumizyme", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lumoxiti", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Lunesta", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lung And Sinus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lung Choy Shung Pain Relief Liquid", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lung Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lung Distress", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lung Drainage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lung Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] }] },
    { Medication: "Lung Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lung Large Intestine Meridian Opener", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lung Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lung-Resp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lungex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lupaneta Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lupium Moisture Tone Up Sun Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lupium Moisture Water Sun Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lupkynis", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lupron Depot", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lupron Depot-Ped", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Lupus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lurasidone Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lushman Blossom Refresh Great Passion Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lushman Blossom Refresh Sweet Pleasure Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lushman Blossom Refresh Viva Love Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Luster Premium White",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Paste", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Lusti Profesional Vaporizing Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lusti Skin Softening Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lusti Therapeutic Ice Analgesic Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lutathera", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Luteinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lutera", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Luvone Antibacterial Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luvone Body Wash Daily Moisturizing Oatmeal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luvone Hand Antibacterial Berries", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luvone Hand Antibacterial Citric", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luvone Hand Antibacterial Roses", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luvone Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luvone Hand Sanitizer With Glycerin", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Luxe And Willow Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Luxe Art Beaute Sas (69879-108-35) Cover-Expert Sunscreen Broad Spectrum Spf 15 No.8 Intense Beige, 35Ml", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luxe Traditions", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luxiq", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luxturna", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Luxury",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Luxury Feet", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luxury Foam Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luxury Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luxury Sun Ritual", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luzen Whitening Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Luzu", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lv-Fx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lxr Healthcare",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Lybalvi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lycogel Breathable Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lycopersicum Esculentum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lycopodium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lycopodium 5% Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lycopodium Berberis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Lycopodium Clavatum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Lycopodium Clavatum 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Lycopodium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lycopodium E Pl Tota. 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lycopodium E Pl. Tota 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lycopodium Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lycopus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lycopus Virginicus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lydexa 4.12%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lyleq", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyllana", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Lym Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lym D", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyme", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyme And Co-Infection Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyme Disease Support 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyme Disease Support 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyme Nosode Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyme Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymepak", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymestat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph 1", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Detox Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Drainage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Iii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Spleen Combo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph-Tone Ii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph-Tone Iii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph/Spleen Combo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymph/Spleen/Mammary Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphastat B", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphastat C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphastat F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphastat V", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Lymphatic Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lymphatic Drainer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphdrainex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphocil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphocytes 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphocytes 6X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphocytes 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphoma Virus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphomyosot", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphomyosot X", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intradermal; Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Lymphonest", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphoplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lymphoplus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lynparza", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lynx Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lynx Foamy Pcmx Antiseptic Hand And Body Wash 6876", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lynx Lx0864 Peachy-Foam Antiseptic Handsoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lynx Lx0869 Alcohol Free Hand San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lynx San A Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lyrall", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lyrall Citrus Burst Antibacterial Liquid Handsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lyran Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Lyrica",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Lyrica Cr", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lysimachia Solanum 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lysodren", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lysol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Lyssin", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lysteda", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyumjev", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Lyvispah", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Lyza", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "M And U Make You Up Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M Kelly Derma O Barrier Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M Marvel Sani Care Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M Marvel Sani Care Multi Function Disinfectant Liquid No Rinse", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M Skin Care Blemish Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M Skin Care Gel Hand Sanitizer 120", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M Skin Care Gel Hand Sanitizer Anti-Bacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M Skin Care Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M Skin Care Microneedle Blemish Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "M-Clear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "M-Dryl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "M-End", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "M-M-R Ii", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "M-Natal Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "M-Pap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "M12", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "M3 Naturals Eczema Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ma Ying Long Musk Hemorrhoids", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mac Lightful Cplus Coral Grass Tinted With Radiance Booster Broad Spectrum Spf030", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mac Prep And Prime Face Protect Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mac Studio Waterweight Spf 30 Broad Spectrum Foundation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Macdee", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Macerrx Web", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maci", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Intra-Articular" }] }] },
    { Medication: "Mackerel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Macoma Senior Premium Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Macrilen", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Macrobid", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Macrodantin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Macrotinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Macula", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mad Beauty Best Friend Hand Sanitizer Poppy Temptation", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Hand Sanitizer Blackcurrant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Hand Sanitizer Candy Cane", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Hand Sanitizer Coconut", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Hand Sanitizer Cranberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Hand Sanitizer Frosted Berries", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Hand Sanitizer Marshmallow", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mad Beauty Hand Sanitizer Spiced Apple",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mad Beauty Hand Sanitizer Strawberry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Hand Sanitizer Sweet Marshmallow", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mad Beauty Hand Sanitizer Vanilla",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mad Beauty Hand Sanitizer Winter Pear", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Llama Queen Hand Sanitizer, Cherry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Llama Queen Hand Sanitizer, Strawberry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Llama Queen Hand Sanitizer, Vanilla", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Pom Pom Moisturising Hand Sanitizer, Fresh Apples", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Pom Pom Moisturising Hand Sanitizer, Sweet Watermelon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mad Beauty Pom Pom Moisturising Hand Sanitizer, Wild Berries", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mada", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mada 70% Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Made For Men", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Made Of Broad Spectrum Spf 30 Non-Nano Zinc Oxide Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Made Of Diaper Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Madecassol", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Madeincali Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Madina Acne", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maekmundong Tang Extract", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maestro Uv Skin Defense Primer Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "Mafenide Acetate", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mag 5 Pt Cooling Formula Recovery", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mag 5 Pt Warming Formula Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mag Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mag Phos",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mag Phos 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mag-Al Liquid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mag-Al Plus", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mag-Al Plus Xs", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magesium Citrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magic Balm", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magic Pouch", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Magic Stamina Climax Control", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magic Xtreme Xtra Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magic Xtreme Xtra Soft Alcohol Wipe 1", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magic Xtreme Xtra Soft Alcohol Wipes 40", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magic Xtreme Xtra Soft Alcohol Wipes 80", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magical Mist Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magical Muscle Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magicshine Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magis Lene Collagen Choc Extra Gold Bb Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magnacal", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Magnesia Carbonica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Magnesia Muriatica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Magnesia Phos",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Magnesia Phos Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Magnesia Phosphorica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Magnesia Phosphorica 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Magnesia Sulphurica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magnesite Viscum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magnesium Carbonicum 6", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magnesium Chloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Magnesium Citrate",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Magnesium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magnesium Hydroxide/Aluminum Hydroxice/Simethicone", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magnesium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magnesium Oxide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magnesium Oxide 400Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magnesium Phosphoricum 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Magnesium Sulf. Bryonia",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Magnesium Sulfate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
        ],
    },
    { Medication: "Magnesium Sulfate In 5% Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Magnesium Sulfate In Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Magnesium Sulfate In Water", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Magnet Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magnevist", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Magnificent Menthol Cooling Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magnificent Menthol Warming Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magnilife Knee Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magnolia Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magsil Antacid (Lemon Mint)", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Magsoothium Body Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magsoothium Cbd Body", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magsoothium Cbd Cooling Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magsoothium Cbd Cooling Roll On Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magsoothium Pure Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Magsoothium Warming Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maias Care Antiseptic Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maine Lobster", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Maintenance Warehouse", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maison Blanche", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Majestic Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Major", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Major Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Major Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Major Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Major Bismuth Subsalicylate", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Major Clotrimazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Major Heartburn Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Major Liquitears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Major Lubricating Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Major Lubrifresh Pm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Major Medi-Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Major Night Time Multi Symptom", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Major Oral Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Major Povidone Iodine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Major Prep Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Major Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Major Thera-Gel Therapeutic", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Major Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Major Ultra Strength Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Makena", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Malachite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Malarone", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Malathion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Male Balance",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Male Balance Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Male Endocrine Axis Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Male Genital Desensitizer", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Male Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Male Stimulant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Male Vitality", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Male-Pros", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Malic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Malie Organics Mango Nectar Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Malie Organics Reef Safe Sunscreen Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Malie Organics Reef Safe Sunscreen Spf-50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Malin And Goetz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Malt", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Malvin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mamisan Pain Relieving", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mamma (Dextra) 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mamma (Sinistra) 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mammary Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mammaryplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Manamed Lidocaine 4% Pain Relieving Gel Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mancinella", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mancore Muscle Mend Roll-On Pain Reliever", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mandarin Basil Aromar Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mandarin Orange Scented Antiseptic Hand Sanitizer 80 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mandragora Arnica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mandragora Arnica Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mandragora E Rad. 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mandragora Rheum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Manganese", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Manganese Sulfate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Manganum Aceticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Manganum Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mango Bay 0.5% Lidocaine Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mango Bay Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mango Bay Spf 30",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mango Bay Spf 30 Gallon", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mango Bay Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mango Bay Spf 50 Kids", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mango Bay Spf 50 Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mango Blossom", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Mango Select High Foaming Antiseptic Hand And Body Wash 6876", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mania Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Manime Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mannan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mannan Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Manncave Hand Sanitizer", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mannitol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mano Hand Sanitizer Moisturizing Grape Fruit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mano Hand Sanitizer Moisturizing Lemon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mano Hand Sanitizer Moisturizing Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Manteca Maslac", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Manzanilla", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Manzatin Diaper Rash Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Manzatin-E Diaper Rash Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mapap",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mapap Arthritis Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maple Box Elder Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Maple Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Maple-Box Elder Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Maple/Box Elder", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Mara Algae Zinc Sea Kale Sunscreen Serum Spf 30", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maraviroc", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Marbeta 25 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Topical" }] }] },
    { Medication: "Marbeta L Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intralesional; Intramuscular; Topical" }] }] },
    {
        Medication: "Marc Glassman",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Marcaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural" }, { Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Epidural; Intracaudal; Perineural" }, { Route: "Epidural; Retrobulbar" }, { Route: "Infiltration; Perineural" }, { Route: "Perineural" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Marcaine Spinal", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subarachnoid" }] }] },
    { Medication: "Marcaine With Epinephrine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Epidural; Intracaudal; Perineural" }, { Route: "Infiltration; Perineural" }, { Route: "Perineural" }] }] },
    { Medication: "Marcelle Cc Cream Complete Correction Sunscreen Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marcs", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marcs 2 In 1 Everyday Care Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marcs Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Marcs Dry Scalp Care Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marcs Everyday Care Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marcs Mucus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Marcs Twin Pack", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Mardex 25 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intramuscular; Intravenous; Topical" }] }] },
    { Medication: "Margaritaville", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Margarite Zinc Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Margenza", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Marimer Hypertonic Seawater Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marinol", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Marjoram Melissa Adult Size", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    {
        Medication: "Marjoram Quercus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mark 3", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Market America Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Market Basket Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Market Basket Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Market Basket Kids Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Market Basket Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Marlido 25 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Topical" }] }] },
    { Medication: "Marlido Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Topical" }] }] },
    { Medication: "Marlissa", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Marplan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Marquis Gel Formula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mars Wellness Magnesium", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mars Wellness Mg Plus Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marshall Toothache Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Marshmallow Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marvel Avengers Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Marvel Avengers Hand Sanitizer With Case",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Marvel Spider-Man Hand Sanitizer With Case", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marvel Spiderman Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marvel Spiderman Hand Sanitizer With Case", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Marvona Suik", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration" }] }] },
    { Medication: "Mary Kay Alcohol Antiseptic 80% Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Cc Cream Sunscreen Broad Spectrum Spf 15 Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Cc Cream Sunscreen Broad Spectrum Spf 15 Light To Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Cc Cream Sunscreen Broad Spectrum Spf 15 Medium To Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Cc Cream Sunscreen Broad Spectrum Spf 15 Very Deep", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Cc Cream Sunscreen Broad Spectrum Spf 15 Very Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Foundation Primer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Satin Hands Shea Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Suncare Lip Protector Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Suncare Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Timewise Age Minimize 3D Day Cream Spf 30 Broad Spectrum Sunscreen (Combo To Oily Skin)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Timewise Age Minimize 3D Day Cream Spf 30 Broad Spectrum Sunscreen (Normal To Dry Skin)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Timewise Miracle Set 3D Sampler (Combo To Oily Skin)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mary Kay Timewise Miracle Set 3D Sampler (Normal To Dry Skin)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mary Kay Timewise Miracle Set 3D The Go Set (Combo To Oily Skin)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mary Kay Timewise Miracle Set 3D The Go Set (Normal To Dry Skin)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mary Kay Timewise Repair Volu-Firm Day Cream Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mary Kay Timewise Repair Volu-Firm Sampler Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mary Kay Timewise Repair Volu-Firm Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mary Kay Timewise Repair Volu-Firm The Go Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mascura La Vaca Plus", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Massage Men Gel Max Delay", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Massage Men Gel Max Power", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Massage Men Gel Max Size", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Massage Men Gel Max Time", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mastikids Ii", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mastikis Fresh Gargle", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mastikis Gold", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mastikis Gold Ii", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mastikis Mastic 24K", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mastikis Mastic 24K Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mastikis Mastic Gumcare", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mastikis Qingjie Shine Mastic", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mastikis Qingxin Care Mastic", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mastitis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mata Piojos Lice", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Match Perfection Foundation Spf 20 - Shade 101", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Match Perfection Foundation Spf 20 - Shade 201", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet - Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Mat Amber 08", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Mat Beige 04", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Mat Ginger 07", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Mat Gold 06", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Mat Honey 05", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Mat Porcelain 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Mat Sand 03", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matissime Velvet Radiant Mat Fluid Foundation With Sunscreen Broad Spectrum Spf 20 Mat Shell 02", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matrix Biolage Raw Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matrix Biolage Scalpsync Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matrix Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Matte Moisturizer Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matte Pro Defense Base Mate Con Multiproteccion De La Piel Fps 20 Matte Foundation With Skin Multiprotection Spf 20 Almendra 230-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matte Pro Defense Base Mate Con Multiproteccion De La Piel Fps 20 Matte Foundation With Skin Multiprotection Spf 20 Beige 220-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matte Pro Defense Base Mate Con Multiproteccion De La Piel Fps 20 Matte Foundation With Skin Multiprotection Spf 20 Capuccino 310-C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matte Pro Defense Base Mate Con Multiproteccion De La Piel Fps 20 Matte Foundation With Skin Multiprotection Spf 20 Champagne 170-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matte Pro Defense Base Mate Con Multiproteccion De La Piel Fps 20 Matte Foundation With Skin Multiprotection Spf 20 Latte 180-F", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matte Pro Defense Base Mate Con Multiproteccion De La Piel Fps 20 Matte Foundation With Skin Multiprotection Spf 20 Mokaccino 390-N", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mattifying Duo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mattifying Primer Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matulane", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Matys Baby Diaper Rash Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matys Baby Eczema Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matys Organic Baby Diaper Rash Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matys Organic Baby Eczema Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Matzim La", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maui Island Secret Browning Creme Broad Spectrum Spf 5 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maui Vera Reef Friendly Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mavenclad", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mavyret",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Max", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Max Cold, Flu, And Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Max Lax", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Max Non Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Max Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Max Revive Plaster", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Max-Relief With Boswellia And Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxalt", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maxalt-Mlt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maxenergy Health Care Alcohol Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxi Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maxidex", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Maxill Kwiky Et Antiseptic Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim 50% Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim 50% Isopropyl Rubbing Alcohol With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim 70% Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim 70% Isopropyl Rubbing Alcohol With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim Assure", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim Fdf", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim Ice Cold Analgesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxim Instant Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Redness Relief Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Maximum Security", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Maximum Strenght Day Cold And Flu Formula", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strenght Lidocaine Plus Menthol Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Maximum Strength",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Adult Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Advanced Antacid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Maximum Strength Antacid",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Maximum Strength Antacid And Antigas",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Antacid And Antigas Cherry", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Antacid And Gas Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Antacid And Gasrelief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Antacid Antigas", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Maximum Strength Antacid Cherry",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Antacid Cherry Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Antacid Mint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Antacid/ Antigas", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Anti Fungal Tolnaftate 1%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Blue Emu Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Chest Congestion And Cough", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Cold Flu And Sore Throat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Cold Sore Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Cold, Flu And Sore Throat", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Congestion And Headache", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Cortisone", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Cortisone Cream With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Cough Plus Chest Congestion Dm", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Day-Night Cold And Flu Formula", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Daytime - Nighttime Cold And Flu Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Daytime Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Daytime Nighttime Sinus Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Dm Max", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Maximum Strength Gas Relief",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Hemorrhoidal Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Hydrocortisone", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Hydrocortisone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Hydrocortisone Cream With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Hydrocortisone Plus Moisturizing Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Maximum Strength Laxative",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Laxative Pills", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Lidocaine Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Lidocaine Plus Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Medicated Foot Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Maximum Strength Mucinex Fast-Max Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Mucinex Fast-Max Cold And Flu And Mucinex Nightshift Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Maximum Strength Mucinex Fast-Max Day Cold And Flu And Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Maximum Strength Mucinex Sinus-Max Pressure, Pain And Cough", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Maximum Strength Mucinex Sinus-Max Severe Congestion And Pain",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Mucinex Sinus-Max Severe Congestion And Pain And Mucinex Nightshift Sinus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Maximum Strength Mucinex Sinus-Max Severe Congestion And Pain Clear And Cool", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Mucus Dm Extended Release", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Mucus Relief Cold,Flu And Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Maximum Strength Mucus Relief Dm",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Mucus Relief Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Mucus-Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Maximum Strength Nasal Decongestant",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Night Time Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Maximum Strength Nighttime Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Maximum Strength Nighttime Cold/Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Non Drowsy Day And Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Maximum Strength One Step Wart Remover Strips", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Pain Relief Lidocaine Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Severe Congestion Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Sleep Aid", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Stomach Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Tussnex Fm Dm Max", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strength Wart Remover Strips", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength Wart Remover With Salicylic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maximum Strength-Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximum Strenth Night Cold And Flu Formula", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maximun Strength Lidocaine Patch Plus Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Maxitrol",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Maxocaine Lidocaine Hemp Oil Topical Analgesic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxocaine Pain Relieving Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxocaine Topical Anesthetic Anorectal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxsani Hand Sanitizing And Cleaning Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maxumim Strength Gas Relief Simethicone Softgel 250 Mg", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maxzide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Maxzide-25", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "May New York Secret Muse Himiznohana Cover Pact No 21", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "May New York Secret Muse Uv Vita Sun Cushion", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "May New York Super Clear Pure Sun Ultra", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybe You Touched Your Genitals Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline Dream Pure Bb Beauty Balm", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline Dream Urban Cover Full Coverage Protective Make-Up Spf 50 Antioxidant-Enriched Pollution Protection", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Baby Lips Dr Rescue Medicated Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Baby Lips Peppermint Moisturizing Lip Balm Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Baby Lips Quenched Moisturizing Lip Balm Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Dream Fresh Bb Beauty Balm Skin Perfector Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Dream Nude Airfoam Air Infused Makeup Sunscreen Broad Spectrum Spf 16", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Dream Wonder Fluid Touch Foundation Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Fit Me Foundation Dewy Plus Smooth Spf 18 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Instant Age Rewind Eraser Treatment Makeup Broad Spectrum Spf 18 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Instant Age Rewind Radiant Firming Makeup Broad Spectrum Spf 18 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Master Prime By Face Studio Primer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maybelline New York Superstay Better Skin Skin Transforming Foundation Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mayer /Kang Du Qing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Mayinglong Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Maylodeur Safe Good Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mayzent", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mbm 10 Stomach", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mbm 11 Thyroid Adrenal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mbm 13 Female", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mbm 5 Large Intestine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mbm 7 Lung", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mbm 8 Small Intestine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mbm 9 Spleen/Pancreas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mccord Professional Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mcd", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mcd Foaming Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Acetaminophen 325", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mckesson Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Antacid Calcium Carbonate", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mckesson Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mckesson Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Benzalkonium Chloride Towelette", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Bismuth", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mckesson Cough Supressant", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mckesson Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mckesson Hand Cleanse And Soothe With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Ibuprofen 200 Mg", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mckesson Loratadine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mckesson Obstetrical Antiseptic Towelette", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Orasol Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Pvp Prep", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Unna Boot 3", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mckesson Unna Boot 4", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Md Acne Customized Hydrating Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Md Acne Customized Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Md Acne Medicated Dark Spot Remover", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Md Acne Oil Free Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Md Gastroview", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral; Rectal" }] }] },
    { Medication: "Md Post Care", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Md-Acne Body Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Md11 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Md80 Clinical Strength Topical Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mdacne Customized Cream (Benzoyl Peroxide)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mdacne Customized Cream (Sulfur)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mdf-Hs80 Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mdfarma Cold And Flu Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Mdhair Extra Strength Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mdhair Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mdsolarsciences", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Me Naphos Mb Hyo 1", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Meadow Fescue Grass, Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Meaningful Beauty Cindy Crawford", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meaningful Beauty Cindy Crawford Anti-Aging Day Creme With Environmental Protection Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meastem First Aid And Antibiotic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mecamylamine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Meclizine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Meclizine 25", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Meclizine Hcl",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Meclizine Hcl 12.5 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Meclizine Hcl 25 Mg",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Meclizine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Meclizine Hydrocloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meclofenamate Sodium", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Med Aid Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Med Nap Benzalkonium Chloride Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Med Nap Cleansing Towelette", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Med Nap Obstetrical Towelette", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Med X Frontline Advanced Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Med-Nap Sterile Alcohol Prep Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medactive Oral Relief",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medactive Patient Friendly", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medactive Patient Friendly Stannous Fluoride Rinse", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medama Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medart Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medcosa Adult Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medcosa Daily Defense Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medcosa Daily Defense Skin Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mederma", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mederma Advanced Scar", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mederma For Kids", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mederma Pm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mederma Scar Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mederma Stretch Marks Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi Defense Mpulse Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi Fect", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi First Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Cramp", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Cramp", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Non-Aspirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Non-Aspirin Extra Strength", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Pain Zapper", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Plus Sinus Pain And Pressure", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi First Sinus Pain And Pressure", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi Guard Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi I Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi W Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-Clean", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First 1% Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medi-First Alcohol Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Medi-First Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medi-First Antiseptic Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Medi-First Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Medi-First Burn",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Medi-First Burn With Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First First Aid Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First First Aid Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Medi-First Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Hydrocortisone 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Hydrogen Peroxide", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medi-First Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medi-First Isopropyl Alcohol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Isopropyl Alcohol 70%", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Ivy-Rid First Aid", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Medi-First Non-Aspirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Non-Aspirin Extra Strength", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Pep-T-Med", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Medi-First Plus Alcohol Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Medi-First Plus Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Medi-First Plus Antiseptic Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Medi-First Plus Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medi-First Plus Burn Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Plus Burn With Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Plus Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Plus First Aid Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Medi-First Plus Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Plus Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medi-First Plus Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medi-First Plus Sinus Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Plus Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Protective Coating Bandage", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Pvp Iodine Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Sinus Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medi-First Sting Relief", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Topical Analgesic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-First Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-Hands", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-Sulting Topical Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medi-Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediblue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicago Sativa Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Medicaine Sting And Bite", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicaine Swabs", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medical Air", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Medical Oxygen Compressed", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Medical Super", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicare Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicare Hand Sanitizer (1 Gallon)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicare Hand Sanitizer (100Ml)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicare Hand Sanitizer (30Ml)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicare Hand Sanitizer (500Ml)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicare Hand Sanitizer (50Ml)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicascrub High Foaming Antiseptic Hand Cleaner", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicasp", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Apricot Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Balm Arthritis Pain Relieving Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Body Extra Strength", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Body Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Buccal Dna Collection Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medicated Buccal Dna Collection Kit 2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Medicated Chest", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medicated Chest Rub",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Medicated Compression Sock", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Cornstarch Baby Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Foot Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Gel Cleanser -2% Salicylic Acid", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Hemorrhoidal Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medicated Lip Balm",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Medicated Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Pain Relief Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Pain Relief Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medicated Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Alcohol Prep Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Alcohol Swabstick", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Antiseptic Isopropyl Alcohol Prep Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Bzk Antiseptic", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Cleansing", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Medichoice Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Ob Cleansing", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Of Hydrogen Peroxide 3 10 Volume", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Povidone-Iodine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Povidone-Iodine Prep Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Premium Anticavity Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Medichoice Pvp", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Pvp Swabsticks", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medichoice Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediclean Sanitizing", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediclean Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medics Choice Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Medif Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medif White Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medifect Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medifense Clean Hand Mist Greenery Bouquet", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medifense Clean Hand Mist Tropical Fruit", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medifense Clean Hand Serum Greenery Bouquet", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medifense Clean Hand Serum Tropical Fruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medifirst Burn", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medifirst Hydrogen Peroxide", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medifresh Hand Skin Antiseptic Disinfectant Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediheal Insant Hand Sanitizer 70% 500Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediheal Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medik8 Physical Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medikills Hand Sanitizer And Cleaning Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medikoii Bio Active Vital Shot Ampoule", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medikoii Bio Active Vital Shot Ampoule Starter Kit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medikoii Bio Calming Relief Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medikoii Bio Repair Cica", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medikoii Bio Safe Hand Refreshing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medimom Lava Pink Cleansing Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medimom Lava Yellow Baby Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medinumb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medioro Signature Essence Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Medioro W", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Medipap Cool", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediplast", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediqe Medi-Mucus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Alcalak", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Apap", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Apap Extra Strength", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique At Home Apap", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique At Home Apap Extra Strength", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique At Home Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique At Home Diphen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique At Home Iprin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique At Home Mediproxen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Back Pain Off", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Ban-Acid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Ccp Caffeine Free", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Cetiramed", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Chlorphen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Cold Eeze", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Decorel Forte Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Diamode", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Medique Diotame",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medique Diphen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Guaicon Dms", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Medique Iprin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medique Loradamed", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Medi Seltzer", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Medi-Lax Enteric Coated", DoseTypes: [{ DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Medi-Meclizine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Medi-Phenyl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Medicidin D", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Medikoff Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Mediproxen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medique Pain Off", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Medique Products Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medique Sugar Free Medikoff Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medirelief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medirelief Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medis Sodium Bicarbonate", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medishield Pro Original Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediside-Hand Antiseptic Tissue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medispray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medistik Dual", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medistik Dual Hot And Cold", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medistik Ice", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medistik Professional Dual Action",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Medistik Professional Ice Therapy",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Meditowel", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medium Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medium Skin Defense Concealer Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mediwiper", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medlife A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medline",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medline Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medline Alcohol Prep", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medline Alcohol Swabstick", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medline Nasal Antiseptic Swabs", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medline Peroxi Fresh", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Medline Remedy Phytoplex Calazime", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medline Remedy Phytoplex Skin Repair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medorrhinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Medpride",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Medpride 99% Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medpride Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medpride Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medpride Cold Ice Analgesic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medpride Medicated Body Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medpura Benzoyl Peroxide",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Medpura Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medpura Hand Sanitizer Topical Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medpura Vitamin A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medpura Zinc Oxide 20%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medrol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medroloan Ii Suik", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular; Soft Tissue" }] }] },
    { Medication: "Medroloan Suik", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intralesional; Intramuscular; Soft Tissue" }] }] },
    { Medication: "Medroxyprogesterone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Medroxyprogesterone Acetate",
        DoseTypes: [
            { DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Suspension, Extended Release", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medshield Products", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medt Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Medulla Arnica",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Medulla Ossis Suis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medulla Ossium 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medullosseinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medullosseinum Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Medusa", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Medyskin Anti-Microbial Cleanser With Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medyskin Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Medyskin Sanitizer Multi Use", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mefenamic Acid", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mefloquine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mega Menses", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Megatope", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Megestrol Acetate",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Meijer",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Meijer Acne Foaming Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Advanced Redness And Irritation Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Analgesic Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Analgesic With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Anti Nausea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Antifungal Tolnaftate Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Artificial Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Athletes Foot Cream Af", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Baby Tear Free Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Baby Tear Free Spf 50 Cs", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Berry Antacid Hbg", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Blackhead Clearing Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Burn Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Childrens", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Childrens Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Clarifying Body Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Meijer Cold And Hot Pain Relieving",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Meijer Cold And Hot Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Cold Therapy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Cold Therapy Pain Relief 360", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Dry Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Ear Relief Ear Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Meijer Earwax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Meijer Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Enamel Guard", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Meijer Enema", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Meijer Extra Strength",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Meijer Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Extra Strength Cold And Hot Therapypain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Extra Strength Smooth Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Fiber Laxative", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Meijer Fiber Therapy",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Meijer First Aid Antiseptic", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Foaming Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Meijer Infants Gas Relief Drops", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Invisible Acne", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Jock Itch Relief Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Kids Broad Spectrum Spf 50 Mineral Based Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Meijer Kids Tear Free Spf 50",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Meijer Large Cold-Hot Medicated Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Lidocaine Pain Relief Back And Large Areas", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Lidocaine Pain Relief Patch Assortment Large", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Lidocaine Pain Relief Patch Assortment Medium", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Lidocaine Pain Relief Patch Assortment Small", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Lime/Berry Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Lubricant Single Vial", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Max. Strength Anti-Itch Cream Intensive Healing Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Redness Relief And Lubricant", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Maximum Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Anthletes Foot Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Anti-Itch", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Anti-Itch Cream Ultra Moisturizing Formula 1Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Anti-Itch Cream Wiith Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Anti-Itch Cream With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Athletes Foot Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Foaming Acne Face Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Jock Itch Relief Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Nighttime Cough Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Maximum Strength Pain Relieving", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Maximum Strength Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Maximum Strength Sore Throat Fast Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Medicated Cold And Hot Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Medicated Selenium Sulfide", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Mucus Relief Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Natural Daily Fiber Sugar Free", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Natural Fiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Oatmeal Bath", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Odor Free Analgesic Pain Relieving", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Oil Free Acne Wash Facial Cleanser Pink Grapefruit", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Oil Free Faces Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Oil Free Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Meijer Pain Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Meijer Pain Relief Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Pain Relieving Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Pain Relieving Max Strength With Lavender Essential Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Redness Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Saline", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Meijer Scar Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Seasonal Itching And Redness Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Senna Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Sheer Moisturing Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Sheer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Small Cold-Hot Medicated Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Sore Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Spf 8", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Sport Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Sport Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Meijer Stomach Relief",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Meijer Sunscreen Broad Spectrum Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer T Plus Gel Coal Tar Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Ultra Lubricant Eye Drops High Performance", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Meijer Ultra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Ultra Strength Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Ultra Strength Pain Relieving Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meijer Whole Care With Fluoride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Meijer Wild Berry Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meijer Zinc Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mekinist", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mektovi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melaleuca", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Melaleuca Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Melaleuca Quinquenervia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Melancholy Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melancholy Drops Extrovert", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melancholy Drops Introvert", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melange Combination", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melatonin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melatonin Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melatonin Forte", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melatonin Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melatonin Pro", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melatonin Sheet Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mele",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Meli Hands Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Melilotus Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melilotus Officinalis (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melissa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Melissa Chamomilla",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Melissa Marjoram 20/20 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melissa Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Melissa Officinalis (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mellow 300Ml Hand Sanitizer Original", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow 500Ml Hand Sanitizer Original", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow 59Ml Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer (Aloe And Vitamin E)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer (Original)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer 236Ml Ml312", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer 400Ml Ey001", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer 500Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer 59Ml Ml307", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer 59Ml Ml315", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer Original 59Ml Ml307", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer With Aloe And Vitamin E 59Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Hand Sanitizer236Ml Ml310", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Instant Hand Sanitizer 2 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Instant Hand Sanitizer 8 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mellow Instant Hand Sanitizer With Aloe And Vitamin E 236Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Melodetta 24 Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Melon Dollar Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Melon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Melon Mango Melt Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Melox", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Meloxicam",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Melphalan",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Melphalan Hydrochloride", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Intravenous" }] }] },
    { Medication: "Melt Pain Away", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Memantine",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Memantine And Donepezil Hydrochlorides Extended-Release", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Memantine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Members Mark",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Members Mark Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Members Mark Acid Pep", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Members Mark Aller Zyr", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Members Mark Aller-Itin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Members Mark Arthritis Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Members Mark Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Members Mark Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Members Mark Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Members Mark Hair Regrowth Treatment",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Members Mark Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Members Mark Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Members Mark Mini Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Members Mark Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Members Mark Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Members Mark Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Members Mark Value Size Sport Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Members Select Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Members Selection Aloe Vera Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Membraneblue", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intraocular; Ophthalmic" }] }] },
    { Medication: "Memorial Sloan Kettering Cancer Center Amenity Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Memory Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Men Delay Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menactra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Menadione", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menadione Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menastil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mencaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mencaps", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menest", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Menopause",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Menopause Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menopause Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menopause Plus Bladder", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Menopause Plus Energy", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menopause Plus Moisture", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Menopause Plus Mood",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Menopause Plus Sleep", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Menopause Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Menopower", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menopur", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Menostar", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Menotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menquadfi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Mens Intelligent Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Minoxidil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Pura Dor Hair Loss Md", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Rogaine Extra Strength Unscented", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Rogaine Minoxidil", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Spectral Uhp Extra Strength Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Irish Spring Charcoal - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Irish Spring Pure Fresh - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Overtime Odor Control With Charcoal - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Stick Fresh Rush - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Stick Irish Spring Celtic Ice - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Stick Irish Spring Original - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Stick Overtime - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Stick Overtime Odor Control With Charcoal - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mens Speed Stick Stainguard Clean - Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menstridol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menstrual - Pms", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Menstrual Complete",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Menstrual Complete Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menstrual Cramps", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menstrual Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menstrual Muscle Cramps", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menstrual Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Menstrual Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mental Stimulator Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mentax", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentho Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthogel Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Menthol",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Menthol 10 Percent Plus Camphor 4 Percent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol 4.5% Capsaicin 0.0225%", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol 5%", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol 5% Hydrogel", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol 6.5%", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol And Methyl Salicylate", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol And Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol Camphor Cough Suppressant Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol Camphor Knee", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol Camphor Micro", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol Camphor Vapor Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Menthol Cough Drops",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pastille", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Menthol Pain Relief Gel-Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol Therastat", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menthol Vapor Cough Drops", DoseTypes: [{ DoseType: "Pastille", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menthol, Camphor", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthol, Lidocaine, Camphor", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Cherry Vaporizing Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Childrens Nighttime Vaporizing Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Deep Heating Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Greaseless Vaporizing Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Lavender Vaporizing Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Lidocaine Heat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Lidocaine Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Medicated Lip Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mentholatum Natural Ice",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mentholatum Nighttime Vaporizing Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum No Mess Vaporizing Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentholatum Pain Relieving", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthopatch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthoreal-10 Kit", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthozen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Menthozen Hydrogel", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mentox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Menveo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Menyanthes Trifoliata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Meperidine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mephitis Mephitica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mephyton", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mepivacaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mepivacaine Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Meprobamate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mepron", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mepsevii", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mer Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Merc Sol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercaptopurine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Merci Handy Hand Sanitizer Lavender And Eucalyptus Relaxing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Hand Sanitizer Orange And Peppermint Energizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Hand Sanitizer Sage And Tea Tree Purifying", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Hand Sanitizer Self-Love", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Rainbowtiful Hand Sanitizer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Aquarius", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Aries", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Cancer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Capricorn", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Gemini", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Leo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Libra", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Pisces", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Sagittarius", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Scorpio", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Taurus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Virgo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merci Handy Sanctuary Hand Sanitizer Zodiac Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mercurialis 3X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurialis Calendula", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mercurialis Calendula Dks Special Order", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mercurius 10X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius 21X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Auratus 15 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Auratus Pulmo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Auratus Pulmo Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Corr Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mercurius Corrosivus",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mercurius Cyanatus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Cyanatus 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Cyanatus 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mercurius Dulcis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mercurius Iodatus Ruber", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mercurius Solubilis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Mercurius Solubilis 12 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Solubilis 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Mercurius Solubilius", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Sulphuratus Ruber", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mercurius Vivus",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Mercurius Vivus 10/20/30 Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Mercurius Vivus 12 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Vivus 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Vivus 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurius Vivus Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mercurochrome", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merfin Mates Hand Sanitizing Towels", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meringue", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mermaid", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mermaid Hand Sanitizer Watermelon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mermaid Squad Antibacterial Hand Sanitizer Strawberry Cake Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mermaid Squad Antibacterial Hand Wash Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mermaid Squad Hand Sanitizer Berry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mermaid Vibes Antibacterial Hand Sanitizer Blueberry Shake Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mermaid Vibes Antibacterial Hand Sanitizer Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mermaid Vibes Antibacterial Hand Sanitizer Mint Chocolate Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Meropenem",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Meropenem And Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Merrem", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Merry Berry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merthiolate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Merthiolate Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mertodol Solution",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Merzee", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Mesalamine",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Enema", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mesalamine Rectal", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Mesenchyme Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mesenchyme Quercus",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Mesna",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Mesnex",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mesotox Clean Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mesquite Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Mestinon",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Met Tox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Met-Tox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metab", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metabatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metabolic Brightening Bb Cushion", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metabolic Brightening Tone Up", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metabolic Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metabolic Intensive Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metabolic Reset", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metabolic Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metabolism", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metabolism Manager", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metabopath", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metaderm Eczema And Baby Eczema Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metaderm Eczema Body And Scalp And Baby Body And Scalp", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metaderm Hyper-Moisturizing Daily Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metaderm Psoriasis Body And Scalp", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metaderm Psoriasis Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metaderm Scalp And Body", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metal-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metalock", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metalogin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Metals",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Metals Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metalstat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metamucil", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metaproterenol Sulfate", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metaxalone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metdetox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Meteoric Iron Phosphorus Quartz",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Meteoric Iron Prunus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Meteorite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Meteorites Baby Glow Broad Spectrum Spf 25 2 Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meteorites Baby Glow Broad Spectrum Spf 25 3 Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meteorites Baby Glow Broad Spectrum Spf 25 4 Golden", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Metformin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Metformin Er 500 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metformin Er 750 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Metformin Hcl",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Metformin Hydrochloride",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Metformin Hydrochloride Extended Release", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metformin Hydrochloride Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metformin Hydrochoride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Methadone Hydrochloride",
        DoseTypes: [
            { DoseType: "Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Methadone Hydrocloride", DoseTypes: [{ DoseType: "Concentrate", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methadose", DoseTypes: [{ DoseType: "Concentrate", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methadose Dispersible", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methamphetamine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methazolamide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methenamine Hippurate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Methenamine Mandelate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Methergine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methimazole", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methitest", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methocarb 500-Ezs", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Methocarbamol",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Methocarbamol And Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methocarbamol Tablets, Usp, 500 Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methocarbamol Tablets, Usp, 750 Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Method Men Face With Zinc Oxide Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Methotrexate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intra-Arterial; Intramuscular; Intrathecal; Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intra-Arterial; Intramuscular; Intrathecal; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intra-Arterial; Intramuscular; Intrathecal; Intravenous" }, { Route: "Intra-Arterial; Intramuscular; Intravenous" }, { Route: "Intramuscular; Intrathecal; Intravenous; Subcutaneous" }, { Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intra-Arterial; Intramuscular; Intrathecal; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Methotrexate Sodium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methoxsalen", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methscopolamine Bromide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Methyl Salicylate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Methyldopa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methylene Blue", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Methylergonovine Maleate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Methylin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Methylphenidate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Methylphenidate Hcl", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Methylphenidate Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Methylphenidate Hydrochloride (La)", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methylphenidate Hydrochloride Cd", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methylphenidate Hydrochloride Extended Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methylpred Dp", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methylprednisolone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Methylprednisolone Acetate", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Arterial; Intralesional; Intramuscular; Soft Tissue" }, { Route: "Intra-Articular; Intralesional; Intramuscular; Intrasynovial; Soft Tissue" }, { Route: "Intra-Articular; Intralesional; Intramuscular; Soft Tissue" }, { Route: "Intralesional; Intramuscular; Intrasynovial; Soft Tissue" }] }] },
    {
        Medication: "Methylprednisolone Sodium Succinate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Methyltestosterone", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metler Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Metoclopramide",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Metoclopramide Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Metolazone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metopirone", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Metoprolol",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Metoprolol Succinate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Metoprolol Succinate Er", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Metoprolol Tartrate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Metoprolol Tartrate And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metrocream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metrogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metrolotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Metronidazole",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Metronidazole Topical Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metronidazole Topical Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metronidazole Vaginal", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Metronidazole250 Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metronidazole500 Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Metropolish Cherry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Metyrosine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mexiletine Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mexsana", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Meyer Lemon And Honey-Cough Relief Lozenges (Oral Anesthetic)", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mezereum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mezereum Comp.", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mfl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mg Dermaclean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg Dermafoam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg Dermagel 62", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg Dermasan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg Dermasan 72", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg Dermawash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg Foamsan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg Foamsan 70", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg217 Baby Eczema", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg217 Eczema Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg217 Eczema Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg217 First Aid Drawing Salve", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mg217 Psoriasis",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mg217 Psoriasis Therapeutic Conditioner", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mg217Coolingburnspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mhs Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mi", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mi Acid",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mi Deurium Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mi-Acid Gas Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mia Del Mar Spf 30 Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miacalcin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Miaderm-L", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Miami Beach",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Miami Beach Body Spf-30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miami Beach Body White Gold Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miami Beach Burn Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miami Beach White Gold Spf-30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miami Ice 80%", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micafungin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Micafungin Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Micardis", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Micardis Hct", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Micatin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mickey And Friends Moisturising Hand Cleanser With Alcohol Amber And Ginseng", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mickey And Friends Moisturising Hand Cleanser With Alcohol Aqua", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mickey And Friends Moisturising Hand Cleanser With Alcohol Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mickey And Friends Moisturising Hand Cleanser With Alcohol Soft Rose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mickey Mouse Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miclara Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miclara Lq", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miconatate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Miconazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] }] },
    { Medication: "Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Miconazole 1 Combination Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Miconazole 3",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Topical; Vaginal" }, { Route: "Vaginal" }] },
        ],
    },
    { Medication: "Miconazole 3 Combination Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Vaginal" }] }] },
    { Medication: "Miconazole 3 Day", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Miconazole 7",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] },
        ],
    },
    {
        Medication: "Miconazole Nitrate",
        DoseTypes: [
            { DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] },
        ],
    },
    { Medication: "Miconazole Nitrate 2%", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miconazole Nitrate 2% Cream Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miconazole Nitrate Antifungal Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miconazole Nitrate Cream 2%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miconazole Nitrate Cream Usp, 2%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micotrin Ac", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micotrin Al", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micotrin Ap", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micox", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Micrell Antibacterial Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micrell Antibacterial Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micrhogam Ultra-Filtered Plus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Micro Day Rejuvenating Broad-Spectrum Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micro Day Riche", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Micro Defence Antiseptic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Micro Defence Antiseptic Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micro Exfoliating Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micro-Aseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micro-Coated Aspirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Micro-Coated Aspirin 325 Mg", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Micro-Guard", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Microbe Bloc Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Microbe Carbon Clear", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Microcaine Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Microflora Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Microgestin 1.5/30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Microgestin 1/20",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Microgestin 24 Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Microgestin Fe 1.5/30", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Microgestin Fe 1/20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Microgold Anti-Bacterial Waterless Hand (4 Oz.)", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Microklenz Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Micronized Benzoyl Peroxide Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Microsporum Canis", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Microsure Hand And Skin Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Microsure Sanitizer",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Microsure Wound Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Midazolam",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    {
        Medication: "Midazolam Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Middle Ear Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Midodrine Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Midodrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Midol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Midol Complete",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Midol Long Lasting Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Midol Pm", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Midol Teen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Midway Select Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Midwest Mix Of Standardized Grass", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Midwest Molds", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mielim Y Mas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mielle Illuminating Face", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mifepristone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Migergot", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Miggy Foundation 70% Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mighty Micropoint For Blemishes,Salicylic Acid 0.5 Acne Treatment", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miglitol", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miglustat", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Migraide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }, { Route: "Sublingual; Sublingual" }] }] },
    { Medication: "Migraine", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Migraine Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Migraine Ease", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Migraine Formula", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Migraine Headache Therapy", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Migraine Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Migranal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Migranow", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Milan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Milani Prime Protect Spf 30 Face Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Milani Spf Bff 30 Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mild Care Suncream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mili", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Milk Of Magnesia",
        DoseTypes: [
            { DoseType: "Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Milk Of Magnesia Cherry",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Milk Of Magnesia Concentrate", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Milk Of Magnesia Mint",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Milk Of Magnesia Original",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Millefolium",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Millipred", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Milnacipran Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Milrinone Lactate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Milrinone Lactate In Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mimi Luzon - Face Defence Age Defying Cream Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mimi Luzon - Face Defence Broad Spectrum Spf 30 Sunscreen Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mimi Luzon - Face Defence Broad Spectrum Spf 30 Sunscreen Serum (Tinted)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mimibo Premium Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mimo Anti-Bacterial Aloe Vera", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mimo Anti-Bacterial Oats-Avena", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mimvey", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Minastrin 24 Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mind And Memory", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mind Body Matrix Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineffect Acid Indigestion And Heartburn Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mineffect Cold Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mineffect Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Mineffect Facial Skin Protectant And Acne Treatment Solution", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineffect First Aid Antiseptic And Antibiotic Solution", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineffect Hangover Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mineffect Nasal Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Mineffect Skin Protectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Based Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Beauty Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Corrector Palette Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Defense Sport Antioxidant Face Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Defense Sport Ceramide", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Foundation Spf 30-3W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Foundation Spf 30-5W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Ice Quick Dry Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Mattescreen Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Mattescreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Moisture Defense", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mineral Oil",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral; Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Oral" }, { Route: "Oral; Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mineral Oil Heavy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mineral Prismatic Luminizing Shield Spf 35", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Radiance Energizing Day Bs Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sheer Screen Broad Spectrum Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Spf 30 Brush-On Sun Defense", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mineral Sunscreen",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Balm Spf 15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Beach Bonfire", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Candy Skies", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Creamsicle", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Pink Paradise", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Sangria Kiss", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Skinny Dip", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Summer Fling", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Tahiti Breeze", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Tropical Punch", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Lip Gloss - Wet Sand", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Sunscreen Moisturizer Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mineral Sunscreen Spf 30",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mineral Sunscreen Spf 30 For Kids And Babies", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mineral Sunscreen Spf 50",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mineral Sunscreen Stick Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Suscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Tinted Face Sunscreen Spf 30 - Light/ Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Tinted Face Sunscreen Spf 30 - Medium/ Dark", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Tinted Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Wear Airbrushing Pressed Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Wear Loose Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mineral Wear Talc Free Mineral Face Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mini Care Hand Sanitizer Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mini Duo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mini Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Minica-S", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Minidrops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Minions Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minions Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minipress", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miniso Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minivelle", DoseTypes: [{ DoseType: "Film, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Minnerva Ii", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minnie Mouse Cherry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minnie Mouse Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Minocin",
        DoseTypes: [
            { DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Minocycline",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Minocycline Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Minolira Extended Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Minor Arthritic Pain", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Minoxidil",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Minoxidil Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minoxidil For Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minoxidil Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minoxidil Topical", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mint Alcohol Free", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mint Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mint Broad Spectrum Spf 15 Lip Moisturizer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mint Dentistry", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mint Flavored Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mint Foaming Hand Sanitizer 62%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mint Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mint/Eucalyptus/Lemon Hand Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mintblast", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mintblast Cool Mint Flavor Anti Cavity Fluoride Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Minted Leaf Cold Therapy Foot Relief With Cbd And Menthol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Minted Leaf Cold Therapy Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Minted Leaf Cold Therapy Pain Relief Roll-On", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Minted Leaf Cold Therapy Relief With Cbd And Menthol",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Minted Leaf Cold Therapy Relief With Cbd And Menthol Roll On", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minted Leaf Heat Therapy Relief Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minted Leaf Heat Therapy Relief Roll-On", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minted Leaf Heat Therapy Roll-On With Capsaicin And Cbd", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minted Leaf Odorless Histamine Dhcl Pain Relief And Hemp Extract", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Minted Leaf Odorless Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mintox", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mintox Maximum Strength", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mintox Plus Tabs", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miochol E", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Miosaic Antibacterial Wipes Lemon Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miostat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Mioszzi Sanitary Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mipaste One", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste One Kids Blue Raspberry", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste One Kids Bubble Gum", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste One Kids Cotton Candy", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste One Perio", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste Plus Melon", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste Plus Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste Plus Strawberry", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste Plus Tutti Frutti", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipaste Plus Vanilla", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Mipasteone Kids Blue Raspberry", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Miracle Brands Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Brands Sense Dispense Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Brands Sense Dispense Hand Sanitizer Refill Bottle", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Footcream Lavender", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Footcream Lemon", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Lite Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Of The Mose Hair Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Origin Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Origin Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Power Hand Sanitaizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Saniquick Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Special Hand Sanitaizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Wipes For Hand Sanitizing", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miracle Worker Anti-Aging Foundation Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miraculous", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miraculous Hangover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miralac", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miralax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miranda Castros Healing Cell Salts For Bones, Hair, Nails And Teeth", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miranda Castros Healing Gel For Bites And Stings", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mirapex", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mircera", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mircette", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mirena", DoseTypes: [{ DoseType: "Intrauterine Device", Routes: [{ Route: "Intrauterine" }] }] },
    {
        Medication: "Mirtazapine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mirvaso", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Misoprostol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Miss Spa Banish Microneedle Spot Treatment", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miss Spa Clear Blemish Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Miss Spa Gel Hand Sanitizer Citrus Ginger", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Missha M Perfect Cover Bb Cream Spf42 (No. 25)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb No 13", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb No 21", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb No 23", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb No 27", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb No 31", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb Spf42 No21", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb Spf42 No23", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Missha M Perfect Cover Bb Spf42 No29", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Advance Control", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Advance Control Powder Fresh", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Advance Control Pure Fresh", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Advance Control Sport", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Advanced Control Solid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Clear Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Clinical Soft Solid", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitchum Smart Solid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mitigare", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mitigo", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Epidural; Intrathecal" }] }] },
    { Medication: "Mitomycin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mitosol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Mitoxantrone",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Mitreapel Hunter Antiseptic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mix Of 5 Standardized Grass Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mix Of 6 Standardized Grass Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mix Of 7 Standardized Grass Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mix-In Laxative Packets", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mixed Aspergillus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Berry Antacid And Anti-Gas Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mixed Berry Sore Throat And Cough Lozenge", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mixed Berry Sore Throat And Cough Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mixed Berry Sore Throat Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mixed Berry Throat And Cough Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mixed Cockroach", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Mixed Feathers",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Mixed Fish", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Mixed Molds", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Penicillium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Ragweed", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Salts Of A Single Entity Amphetamine Product", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mixed Sanitizing Towlettes", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mixed Shellfish", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Vespid Hymenoptera Venom", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Vespid Hymenoptera Venom Multidose", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Vespid Hymenoptera Venom Venomil Maintenance", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Vespid Venom", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mixed Weeds", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mizani Scalp Care Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mizani Scalp Care Conditioner Antidandruff Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mjalli Hand Sanitizer 2.0", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Alcohol Prep Pads Large", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Alcohol Prep Pads Medium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Alcohol Swabstick Sterile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Bzk Antiseptic Towelette Non-Sterile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Bzk Antiseptic Towelette Sterile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Bzk Swab Non-Sterile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Maokang Medical Povidone Iodine Swabsticks", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Povidone Iodine Swabstick Sterile", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Povidone-Iodine Scrub Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Povidone-Iodine Swabstick Sterile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mk Sting Relief Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mkmen Advanced Facial Hydrator Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mkrs", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mlk F1 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intramuscular" }] }] },
    { Medication: "Mlk F3 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intramuscular; Topical" }] }] },
    { Medication: "Mlk F4 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intramuscular; Topical" }] }] },
    { Medication: "Mlk Kit F2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intra-Articular; Intramuscular; Topical" }] }] },
    { Medication: "Mmm - Cough Formula Guaifenesin Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mmm Diaper Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mmm Guaifenesin Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mmm Ice Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mmm Topical Analgesic Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mmr (Measles, Mumps, Rubella)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mnfr Safe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mobic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mobility", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mobisyl", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mocha Latte Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Modafinil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moderate Lubricating Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Modere", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Modere Sports Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Modern Essentials", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Modern Expressions Hand Sanitizer Set", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moderna Covid-19 Vaccine", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Moderna Covid-19 Vaccine, Bivalent", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Modesa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Modesa Antibacterial Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Modesa Hand Sanitizer 2 Fl.Oz.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moduga Camellia Hair Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moduga Woody Hair Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moexipril Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mogut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Moist Moisture",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Moist Moisture Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moist Moisture Cc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moist Moisture Essence Face Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moist Moisture Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moist Moisture Skin Toner", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisture Barrier", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisture Eyes Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Moisture Melt", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisture Restore Day Protective Mattefying Broad Spectrum Spf15 Combination To Oily", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisture Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisture Surge Sheertint Hydrator Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturel", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturising Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Moisturizer",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Moisturizer Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturizer Maple Day Spf15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturizing Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturizing Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Moisturizing Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Moisturizing Hand Sanitizer Lavender And Mint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturizing Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturizing Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturizing Renewal Cream Broad Spectrum Spf 30 Us", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturizing Skin Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moisturizing Sunscreen Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Molchem Hand Purifier", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mold Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mold Mix 1",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Mold Mix 2",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Mold Mix 3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Mold Mix A", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mold Mix Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mold Mix B", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mold Mix C", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mold Mix Number 1", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mold Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mold Relief Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Molds - Alternaria/Hormodendrum Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds - Mold Mix 10", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds - Mold Mix 4", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds - Trichophyton Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Penicillium Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Alternaria Tenuis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Aspergillus Fumigatus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Aspergillus Niger", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Botrytis Cinerea", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Candida Albicans", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Cephalosporium Acremonium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Curvularia Spicifera", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Epicoccum Nigrum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Epidermophyton Floccosum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Fusarium Vasinfectum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Helminthosporium Interseminatum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Hormodendrum Cladosporioides", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Mucor Racemosus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Penicillium Notatum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Phoma Herbarum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Pullularia Pullulans", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Rhizopus Nigricans", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molds, Rusts And Smuts, Stemphylium Botryosum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Molecular Af Molecular Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Molecure", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Molindone Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Molton Brown Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mom Ae Sog For Zero", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mom Ae Sog For Zero 70", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mometasone Furoate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Mommys Bliss Gas Relief Simethicone Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Momordica Balsamina", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Monagra", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monarch Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monat Sun Veil Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mongdies Blueberry Kids Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mongdies Excellent Sun", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mongdies Excellent Sun Cushion", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mongdies Raspberry Baby Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mongdies Spearmint Adult Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monilia", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Monilia Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Monistat 1 Combination Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Monistat 3",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Monistat 3 Combination Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Monistat 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Monistat 7 Combination Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Monistat Care Instant Itch Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monistat Complete Care Chafing Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monistat Complete Care Instant Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monistat Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monjuvi", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mono-Linyah", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Monobasic Sodium Phosphate And Dibasic Sodium Phosphate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Monoferric", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Monogram Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monoket", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mononessa", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mons", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Monsels Solution", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Montelukast",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Montelukast Sodium",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Monurol", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mood And Patience", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mood Disorders Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mood Swings", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moodbrite", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moodcalm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moon Platinum Advanced Whitening Anticavity", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moonlite Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moonstone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moor Spa Hand Sanitizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moore Medical Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Bismuth", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Cherry Cough Suppressant/Anesthetic Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Extra Strength Non Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Menthol Eucalyptus Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Moorebrand Severe Cold Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Non Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Sinus Pain And Pressure Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moore Medical Sugar Free Menthol Cough Suppressant/Anesthetic Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moorebrand Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moorebrand Assorted Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moorebrand Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moorebrand Phenylephrine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Morbillinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Morcin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "More Focus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Morebrand Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Morella Cerifera Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mormaii Grip Action Protector Solar", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Morning After", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Morning Fresh", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Morning Fresh Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Morning Sickness", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Morning Sickness-Nausea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Morphine Sulfate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Intrathecal; Intravenous" }, { Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Morus Alba Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Morus Rubra Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Mosaic Advanced Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mosaic Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mosaic Bath And Spa Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mosaic Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mosaic Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mosaic Lavender Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mosaic Lemon Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mosco",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Plaster", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Mosquito",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Mosquito Bug Off", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mossy Oak Eclipse", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mostal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mostal Lashes And Brows Enhancing Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Motegrity", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mother Dirt Daily", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Motherwort Herb Feminine Antibacterial Gels", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Motion Medicine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Motion Sickness",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Motion Sickness 1", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Motion Sickness Ii", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Motion Sickness Less Drowsy Formula", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Motion Sickness Relief",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Motion-Time", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Motioncalm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Motivation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Motives Liquid Powder Mineral Foundation Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Motofen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Motrin",
        DoseTypes: [
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Motrin Arthritis Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Motrin Ib",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Motrin Ib Migraine", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mounjaro", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mountain Air Scent Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mountain Cedar Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Mountain Herb Sugar Free With Stevia Cough Suppressant Oral Anesthetic", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mountain Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mountain Ice Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mountain Ice Eczema", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mountain Ice Sports", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mouse Epithelia",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Mouth Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mouth Sore Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mouthwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mouthwash For Gum Health", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mouthwash For Total Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mova Al-Kogel Hand Sanitizer 75 Alcohol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mova First Aid Antiseptic Denatured Alcohol 94", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mova Liquid Hand Antibacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Movantik", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moviprep", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Moxa Roll", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moxe Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moxe Eucalyptus Mint Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moxeza", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Moxicaine Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moxidectin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moxie", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Moxiecare Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Moxifloxacin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Moxifloxacin Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Moxifloxacin Hydrochloride Tablets, 400 Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Moxifloxacin Ophthalmic Solution", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Mozi-Q",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mozi-Qfor Kids And Infa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mozobil", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Mp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mpk Alcohol Sanitizing Wipes 1", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mr. Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mrn Marine Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mrs. Meyers Clean Day Hand Sanitizer Basil Scent", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mrs. Meyers Clean Day Hand Sanitizer Lavender Scent", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mrsa Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ms Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ms Contin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ms Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ms. Germsafe Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ms. Germsafe Antibacterial Hand Sanitizer - Clean Fresh", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ms. Germsafe Antibacterial Hand Sanitizer - Unscented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ms. Germsafe Antibacterial Hand Sanitizer -Tropical Coconut", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ms. Germsafe Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ms.Germsafe Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Msg Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Msm2000 Premium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mtx Topical Pain", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muca Clear", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucan", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucilin", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucinex Childrens",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucinex Childrens Cough And Congestion", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Childrens Day Time Multi-Symptom Cold And Mucinex Childrens Night Time Multi-Symptom Cold Triple Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Childrens Freefrom Cough And Mucus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Childrens Freefrom Multi-Symptom Cold And Flu Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Childrens Freefrom Multi-Symptom Cold And Stuffy Nose", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Childrens Freefrom Multi-Symptom Cold, Flu And Sore Throat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Childrens Freefrom Multi-Symptom Cold, Flu And Sore Throat And Mucinex Childrens Freefrom Multi-Symptom Cold And Flu Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Childrens Multi-Symptom Cold", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Childrens Stuffy Nose", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Mucinex D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Fast Max", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Mucinex Fast-Max",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucinex Fast-Max Chest Congestion Honey And Berry Flavor", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Fast-Max Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Fast-Max Cold And Flu Arctic Burst", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Fast-Max Cold, Flu And Sore Throat", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Fast-Max Congestion And Headache", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Fast-Max Day Cold And Flu And Mucinex Nightshift Night Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Fast-Max Day Cold And Flu And Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Fast-Max Day Severe Congestion And Cough And Mucinex Nightshift Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Fast-Max Day Time Severe Cold And Mucinex Fast-Max Night Time Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Fast-Max Day Time Severe Congestion And Cough And Mucinex Fast-Max Night Time Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Fast-Max Dm Max Honey And Berry Flavor", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Fast-Max Severe Cold", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Fast-Max Severe Congestion And Cough Arctic Burst", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Freefrom Cold And Flu Daytime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Freefrom Cold And Flu Daytime And Mucinex Freefrom Cold And Flu Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Freefrom Cold And Flu Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Freefrom Cold, Flu And Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Freefrom Severe Congestion And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucinex Instasoothe",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucinex Junior", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Nightshift", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Nightshift Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucinex Nightshift Severe Cold And Flu",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucinex Nightshift Severe Cold And Flu Arctic Burst", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Nightshift Sinus", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Sinus - Max", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Mucinex Sinus-Max",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucinex Sinus-Max Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Sinus-Max Day Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Sinus-Max Day Pressure, Pain And Cough And Mucinex Nightshift Night Sinus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Sinus-Max Pressure, Pain And Cough", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucinex Sinus-Max Pressure, Pain And Cough And Mucinex Sinus-Max Severe Congestion Relief Clear And Cool", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucinex Sinus-Max Severe Congestion And Pain", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muco Coccinum", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Mucolytic Drainage Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucor", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Mucor Circinelloides F. Circinelloides", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Mucor Circinelloides F. Lusitanicus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Mucor Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Mucor Plumbeus",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Mucosa", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucosa Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucosa Guifenesin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucous Membrane Core Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus And Sinus Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Congestion And Cough Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus D",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus D Er", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Dm Extended Release", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Extended Release", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Multi Symptom Cold Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Releif", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus Relief 600 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief All In One", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief All In One Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief All In One Daytime Nighttime Cold Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucus Relief Chest Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus Relief Cold And Flu",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus Relief Cold And Flu All In One", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus Relief Cold And Sinus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Mucus Relief Cold Flu And Sore Throat",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus Relief Cold Flu And Sore Throat Max", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus Relief Cold Flu Sore Throat",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Mucus Relief Cold, Flu And Sore Throat",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus Relief Cold, Flu, Sore Throat", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Congestion And Headache", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Congestion Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Cough And Congestion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Cough And Congestion Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus Relief D",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus Relief D Sinus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Daytime Severe Cold Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Mucus Relief Dm",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus Relief Dm And Overnight Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucus Relief Dm Cough", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Dm Expectorant And Cough Suppressant", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Dm Expectorant And Cough Suppressant Maximum Strength", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Dm Extended Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Dm Extended Release Caplets", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Dm Honey And Berry Flavor", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Dm Immediate Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus Relief Dm Max",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus Relief Dm Maximum Strength", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Dmimmediate Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Er", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Extended Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Extended-Release", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Max", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Maximum Strength 1200 Mg", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Nighttime Cold Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Nighttime Congestion Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Pe Sinus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Plus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mucus Relief Severe Cold",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mucus Relief Severe Congestion And Cold Max", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Severe Congestion And Cough Multi Symptom Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Severe Congestion And Sinus", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Severe Congestion Cough Night Time Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucus Relief Severe Sinus Congestion", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Sinus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Sinus Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucus Relief Sinus Pressure And Pain", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Sinus Pressure Pain And Cough", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Sinus Pressure, Pain And Cough", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Sinus Severe Congestion And Pain", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Sinus Severe Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief Xpect", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Relief, Cold, Flu And Sore Throat", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus Sinus Relief Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Mucus-Clear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus-Clear Flu Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mucus-Clear Sinus Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mueller First Aid Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mugwort Sage Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Mulpleta", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multaq", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi Action", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multi Flower", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi Function Sleeping Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multi Purpose Safety First Antiseptic Cleaning Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multi Symptom", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi Symptom Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Multi Symptom Cold",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Multi Symptom Cold And Multi Symptom Nighttime Cold", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Multi Symptom Cold Relief Cf", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi Symptom Nitetime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi Symptom Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi Symptom Severe Cold And Nighttime Severe Cold And Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Multi Symptom Severe Cold Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi Symptom Wal Flu Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi Vitamin Acne Clearing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multi- Action Relief Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Multi-Action Antiseptic", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Action Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Multi-Active Jour Spf 20 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multi-Cell Salt", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Function Smart Bb Spf30 Pa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multi-Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Potency Growth Hormone Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Multi-Purpose Alcohol Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Multi-Purpose Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multi-Purpose Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multi-Specialty Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Infiltration; Intra-Articular; Intralesional; Intramuscular; Soft Tissue; Topical" }] }] },
    { Medication: "Multi-Symptom Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold And Fever", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold And Flu Relief Daytime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold And Flu Relief Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Multi-Symptom Cold And Flu Relief Nighttime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold And Sinus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold Daytime", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold Flu And Sore Throat", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Cold Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Multi-Symptom Daytime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Daytime Cold Rapid Release", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Multi-Symptom Mapap Cold Formula",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Multi-Symptom Nighttime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Nitetime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Severe Cold", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Symptom Sinus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-System", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Vitamin Drops With Fluoride And Iron", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multi-Vitamin With Fluoride", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multihance", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Multihealth Fiber Therapy", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multiple Miasm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multitam", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multitrace -4 Pediatric", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Multitrace-4 Neonatal", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Multitrace-5",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Multivit Sun Protector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Multivitamin With Fluoride", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Multrys", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mundipur", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mundus Hypochlorous Acid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mupirocin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Murad - Acne Control - Oil And Pore Control Mattifier", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Murad - Age Reform - Perfecting Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Murad - Anti-Aging Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Murad - Environmental Shield - City Skin Age Defense Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Murad - Environmental Shield - Essential-C Day Moisture Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Murad - Environmental Shield - Essential-C Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Murad - Resurgence - Age-Balancing Moisture", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Murad Correct And Protect Serum", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Murchison Hume Dental Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Murex Purpurea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muriaticum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Murine Ear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Murine Plus For Dry Eyes Redness Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Murine Tears For Dry Eyes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Muro 128",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Murumuru Butter Lip Cream", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mus Musculus Skin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Muscle", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle And Joint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle And Joint Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle And Joint Pain Relief Gr Isolate 150 Mg", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle And Joint Pain Relief Gr Isolate 350 Mg", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle And Joint Pain Relief Gr Isolate 750 Mg", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Cramp Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle Cramps", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle Drops (Non-Striated)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle Drops (Striated)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Muscle Ease",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Muscle Freeze Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Pain", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle Relaxant Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Muscle Rub",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Muscle Rub Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Rub Pain Reliever Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Rub Ultra", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Soreness Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle Spasms", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle Therapy With Arnica", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle Therapy With Arnica Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscle-Tendon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle/Ligament/Cartilage Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscle/Tendon Pain And Stress Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscles", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscles And Joints", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Muscleshok Back Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscleshok Sport", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Musco Mxt", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muscular Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muscular Pain Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Musculus Deltoides Comp. 12 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Musculus Deltoideus 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Musculus Deltoideus Comp. 8 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Muse", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Urethral" }] }] },
    { Medication: "Muse Apothecary Hemp Ritual Roll On", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muse Health Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Muse Health Hand Sanitizer - Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mushroom",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Muskel", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mustard",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Mustard Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Mustard Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Mustela Diaper Rash 1 2 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mustela Diaper Rash Sprayable", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mustela Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mustela Mineral Sunscreen Broad Spectrum Spf 50",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mustela Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mutamycin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mvasi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "My Beauty Spot Antibacterial Hand Sanitizer French Lavnder Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Antibacterial Hand Wash Cherry Blossom Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Antibacterial Hand Wash Coconut Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Antibacterial Hand Wash Lavender Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Antibacterial Hand Wash Lemongrass Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Antibacterial Hand Wash Peppermint Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Aromatherapy Antibacterial Hand Wash With Vitamin E And Aloe Vera (Cherry Blossom Scented)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Aromatherapy Antibacterial Hand Wash With Vitamin E And Aloe Vera (Coconut Scented)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Aromatherapy Antibacterial Hand Wash With Vitamin E And Aloe Vera (Lavender Scented))", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Aromatherapy Antibacterial Hand Wash With Vitamin E And Aloe Vera (Lemongrass Scented))", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Hand Sanitizer Cool Fall Breeze Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Hand Sanitizer Lavender Frost Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Beauty Spot Hand Sanitizer Wild Berries Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Choice Tm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "My Cycles Bff", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "My Day Screen Medium Dark Tinted Drops Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Earthday Alpha Care Plus", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "My Fair Baby", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Hemp Md", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Hemp Md Cbd Muscle And Deep Tissue Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Hemp Md Cbd Muscle And Joint Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Little Pony Blueberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Little Pony Cotton Candy Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Little Pony Grape Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Little Pony Strawberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Medic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Medic Hand Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Medic Super Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My One-Day Mask", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My Way", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "My-Shield Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My-Shield Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My-Shield Sanitizing", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My-Shield Sanitizing Body", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My-Shield Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "My-Shield Topical Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myabetic Alcohol Prep Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myalept", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Myambutol", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycamine", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Mycapssa", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myccombination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycellcare", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Replenishing Solar Defense Body Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Replenishing Solar Defense Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Sun Shield Clear Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Sun Shield Spf 28 Coconut", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Sun Shield Spf 28 Unscented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Sun Shield Spf 50 Dark", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Sun Shield Spf 50 Light", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Sun Shield Spf 50 Light/Medium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Dermaceuticals Sun Shield Spf 50 Medium/Dark", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Mychelle Dermaceuticals Sun Shield Spf 50 Non-Tinted",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Mychelle Dermaceuticals Sun Shield Spf 50 Tinted", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Sun Shield Liquid Spf 50 Light/Medium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mychelle Sun Shield Liquid Spf 50 Medium/Dark", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myco Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myco Nail A Antifungal Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mycobacter/Mycoplasma Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycobutin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycocan 100X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycocan 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycocan 30X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycocan 500X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycocan 60X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycocan Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycocan-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycocombo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycogone Perniciosa", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Mycological Immune Stimulator", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Mycophenolate Mofetil",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Mycophenolic Acid", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycoplasma Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycosis Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycotoxin Inhibitor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mycozyl Ac", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mycozyl Al", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mycozyl Ap", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mydayis", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myderm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myderm Bite Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myderm Cooling Menthol Pain Relief Roll-On With 50 Percent More Menthol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Myderm Cooling Menthol Pain Relief With 50 Percent More Menthol",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Myderm Hand Sanitizer Antibacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mydriacyl", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Myelin Sheath Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myfembree", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myfortic", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mygale", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mygale Agaricus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mygrin Nighttime", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mygrin Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myhair Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myhealth Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myhealth Alcohol Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mykro Max Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mykro Max Multi Purpose Disinfecting Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mylanta Coat And Cool", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylanta Gas Minis Assorted Fruit Flavor", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylanta Gas Minis Cherry Flavor", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylanta Gas Minis Mint Flavor", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylanta Maximum Strength Classic Flavor", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylanta Maximum Strength Classic Flavor Antacid And Anti Gas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylanta Maximum Strength Vanilla Caramel Flavor", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylanta New Tonight Soothing Honey Chamomile", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylanta One Antacid/Anti-Gas", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myleran", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylicon Infants Gas Relief Dye Free", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylicon Infants Gas Relief Original", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mylotarg", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Myobloc", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Myocardial Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myorisan", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myorx", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myosotis Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myosport Relieve Kinesiology", DoseTypes: [{ DoseType: "Tape", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myoview", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Myrbetriq",
        DoseTypes: [
            { DoseType: "Granule, For Suspension, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Myristica Argentum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Myristica Argentum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myristica Sebifera", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mysoline", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mystic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mystic Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Myterra Hand Sanitizer Foam Na", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Mytesi", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Mytox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Myxredlin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "N-1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-10", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-11", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-12", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-13", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-14", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-15", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-16", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-17", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-18", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-19", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-20", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-21", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-22", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-23", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-24", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-25", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-26", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-27", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-4", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-5", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-6", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-7", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-8", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-9", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N-Drops Nasal Decongestant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "N-Labs Solar Max Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "N/A",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "N01", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N02", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N03", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N04", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N05", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N09", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N10", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "N12", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Na",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Retrobulbar" }] },
        ],
    },
    {
        Medication: "Nabi-Hb",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    {
        Medication: "Nabumetone",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nabumetone 1000 Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nabumetone 500 Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nabumetone 750 Mg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nacellate Solution 0.9% - 1000Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nacellate Solution 0.9% - 100Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nacellate Solution 0.9% - 250Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nacellate Solution 0.9% - 500Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nadh Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nadolol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naeli Day Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naeli Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nafasolina", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Nafcillin",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Intramuscular; Intramuscular; Intravenous" }, { Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Nafcillin Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Naftifine Hydrochloride",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Naftin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naglazyme", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nail Fungus Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nail Fungus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nail Renew", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naisture Hand Cleaner Gel(Hand Sanitizer)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naisture Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naja E Veneno 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naja Forte X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naja Tripudians", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naked Sundays Golden Glow Body Sunscreen Spf50 100Ml", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naked X Restore Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nalbuphine Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Nalbuphine Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    {
        Medication: "Nalfon",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nalmefene Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Nalocet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naloxone Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    {
        Medication: "Naloxone Hydrochloride",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Parenteral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Naloxone Hydrochloride Injection, Usp, Auto-Injector", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Naltrexone Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Namenda",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Namman Muay Pain Relieving", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Namzaric",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nano - Q1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Acqua Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Caramel Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Carnaval Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini I Do Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini I Oud Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Lavander Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Lemonade Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Lollipop Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Lucky Me Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Raspberry Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nano Mini Vanilla Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nanoxen Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Naphazoline Hci And Pheniramine Maleate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Naphcon A", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Naphthalinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Napoleon Perdis Advanced Mineral Makeup Broad Spectrum Spf 15 Look 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Advanced Mineral Makeup Broad Spectrum Spf 15 Look 2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Advanced Mineral Makeup Broad Spectrum Spf 15 Look 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Advanced Mineral Makeup Broad Spectrum Spf 15 Look 4", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Advanced Mineral Makeup Broad Spectrum Spf 15 Look 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Auto Pilot Bbb Broad Spectrum Spf 30 Light-Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Auto Pilot Bbb Broad Spectrum Spf 30 Medium-Dark", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis China Doll Foundation Broad Spectrum Spf 15 Look 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis China Doll Foundation Broad Spectrum Spf 15 Look 2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis China Doll Foundation Broad Spectrum Spf 15 Look 2B", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis China Doll Foundation Broad Spectrum Spf 15 Look 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis China Doll Foundation Broad Spectrum Spf 15 Look 4", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Foundation Broad Spectrum Spf 15 Look 1", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Foundation Broad Spectrum Spf 15 Look 2", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Foundation Broad Spectrum Spf 15 Look 2B", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Foundation Broad Spectrum Spf 15 Look 3", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Foundation Broad Spectrum Spf 15 Look 3B", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Foundation Broad Spectrum Spf 15 Look 4", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Foundation Broad Spectrum Spf 15 Look 5B", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Napoleon Perdis Sheer Genius Liquid Foundation Broad Spectrum Spf 20 Look 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naprelan", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Naprosyn",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Naproxen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Naproxen And Esomeprazole Magnesium",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Naproxen Back And Muscle Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Caplets", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Headache Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Oral Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Naproxen Sodium",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Naproxen Sodium (Nsaid)", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Naproxen Sodium 220Mg",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Naproxen Sodium And Diphenhydramine Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Sodium And Pseudoephedrine Hydrochloride Sinus And Cold", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Sodium And Pseudoephedrine Hydrochloride Sinus And Headache", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Sodium Back And Muscle Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Sodium Diphenhydramine Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naproxen Sodium Headache Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Naproxen Sodium Pm",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Naratriptan",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Narcan", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Narcan Naloxone Hci", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nardil", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Naro Como", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naro Quinn", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naro Tiamo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naropin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural" }, { Route: "Epidural; Infiltration" }, { Route: "Epidural; Infiltration; Perineural" }, { Route: "Epidural; Perineural" }] }] },
    { Medication: "Nars Afterglow Lip Balm Clean Cut", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars All Day Luminous Foundation", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Laguna Body Tint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Alaska", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Auckland", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Cuzco", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Finland", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Gotland", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Granada", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Groenland", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Guernsey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Havana", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Marrakesh", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Mykonos", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Norwich", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Santiago", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer St. Moritz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Sydney", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Radiant Tinted Moisturizer Terre-Neuve", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Pure Sheer Spf Lip Treatment", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Radiance Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Smooth And Protect Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nars Velvet Matte Skin Tint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naryn Care Stick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nas Max Strength Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nas Pore Refining Scrub", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nas Purifying Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nas Targeted Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nas Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nasacort Allergy 24Hr", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Nasal",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nasal Allergy Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nasal And Sinus Decongestant", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nasal Catarrh", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nasal Decongestant",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nasal Decongestant Extra Moisturizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Nasal Decongestant Pe",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nasal Decongestant Pe And Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nasal Decongestant Pe And Pain Relief Plus Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nasal Four", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nasal Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nasalcrom", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nascobal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nasel Congestion", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nasoclenz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nasopen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nasopro 24", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nasova Ear Drying Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Naspen", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nat Mur", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nat Mur 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nat Phos",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nat Phos 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nat Sulph", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nat Sulph 6X", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nat. Phos.", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natachew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natacyn", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Natazia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Natean", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nateglinide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Natesto", DoseTypes: [{ DoseType: "Gel, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "National Guard Ppe Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "National Postal Mailhandlers Moisturizing Advanced Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "National Weed Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Native", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Native Facial Care Brightening Facial Moisturizer Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Native Mineral Face Broad Spectrum Spf 30 Coconut And Pineapple", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Native Mineral Face Broad Spectrum Spf 30 Unscented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Native Mineral Sunscreen Broad Spectrum Spf 30 Coconut And Pineapple", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Native Mineral Sunscreen Broad Spectrum Spf 30 Rose", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Native Mineral Sunscreen Broad Spectrum Spf 30 Unscented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nativeremedies Acid Free-Flux", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nativeremedies Backache Ease", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natpara (Parathyroid Hormone)", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Natralia", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natralia Eczema And Psoriasis", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natrium Muriaticum 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Natrium Phosphoricum 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Natrium Sulfuricum 6X", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Natroba", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Natrum Carbonicum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Natrum Muriaticum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Natrum Muriaticum 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Natrum Muriaticum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natrum Phos Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Natrum Phosphoricum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Natrum Sulphuricum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Natrum Sulphuricum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natubase Pine Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natura S Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natura-Lax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Adult Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Natural Aloe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Blend Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Cherry Honey Herb Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Concepts", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Concepts Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Concepts Alcohol-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Concepts Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Concepts Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Concepts Non-Alcohol Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Concepts Vitamin E Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Extensions Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Finish Pressed Foundation Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Fit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Fluoride Fresh Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Natural Fusion Anti-Fungal Medi-Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Herbal Cough Drops", DoseTypes: [{ DoseType: "Pastille", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Honey Herb Cough Suppressant Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Honey Lemon With Echinacea Cough Suppressant Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural House Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Hydrating Shield Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Laxative", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Lemon Mint Herb Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Mineral Body Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Mineral Body Sunscreen Unscented", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Mineral Sunscreen Sport, Spf 30 (Unscented)", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Moisturizing Face Sunscreen And Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Orange Flavor", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Pain Relief Foot Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Prebiotic Peppermint Ppbt4", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Natural Prebiotic Spearmint Spbt4", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Natural Progesterone", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Protection Face And Eyes Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Relief 1222", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Relief Patch For Menstrual Cramps", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Relief Patch For Motion Sickness", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Seal Itch Relief For Kids", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Senna Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Natural Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Sunscreen For Kids/Baby", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Tanning And Body Oil Spf15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Tanning And Body Oil Spf6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natural Testosterone", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Natural Vegetable Laxative",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Natural White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Naturally Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturally Beaming", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Naturaltech Purifying Anti-Dandruff",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Naturasil",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Naturasil Molluscum Treatment Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nature Clean", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Clean Water", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Cleaner", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Touch (Ethanol) 70% 40Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Touch Antiseptic Ethanol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Touch Cherry (Ethanol) 70% 40Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Touch Green Tea (Ethanol) 70% 40Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Touch Moringa (Ethanol) 70% 40Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Touch Sanitizer Gel (Ethanol)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Touch Sanitizer Gel (Ethanol) 70% 500Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hand Touch Sanitizer Liquid (Ethanol) 70%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Hnb Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Mint Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Nature Origin Collagen Bb 01 Light Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Origin Collagen Bb 02 Natural Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nature Origin Collagen Bb Original", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Natureplex",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Enema", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Natureplex Antibiotic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Antibiotic Plus Pain", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Antibiotic Plus Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Antibiotic Plus Pain, Itch, Scar Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Extra Strength Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Lidocaine 4%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Psoriasis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natureplex Tolnaftate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Baby", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Choice Vitamins A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Craft Lidocaine Male Genital Desensitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Fusions Dragons Breath Hand Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Oil Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Oil Unscented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Promise", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Natures Realm Vaporizing Chest Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Solution Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Willow", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Willow Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Willow Balm Pain Relieving Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Willow Bug Bite Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Willow Bug Bite Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Natures Willow Itch Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturewell Advanced Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturia Fresh Blue Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturia Gel Moisturizer Plus Handsanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturia Green Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturia Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturopathica", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturopathica Calendula And Butterfly Ginger", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturopathica Chill Chocolate Vine Detox Tonic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturopathica Chlorophyll And Salicylic Acid Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Naturopathica Colloidal Silver And Salicylic Acid Acne Clearing Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Naus-Ease",
        DoseTypes: [
            { DoseType: "Film, Soluble", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nausea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nausea Constipation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nausea Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nausea Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nausea Vomiting", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nausea Vomiting Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nausea Vomitting", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nausea/Morning Sickness Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nauseacalm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nausia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nautica Non Scented Hand Sanitizer 70 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nauzene", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nauzene Grape", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nauzene Homeopathic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Navi Hair Solutions Mens Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Navi Hair Solutions Womens Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Navy Bean", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Nayzilam", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nazal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Nba Hand Sanitizer Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nbe Anbesol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ne Mexico Aging Long Term Services Department Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neat Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nebivolol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nebivolol Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nebupent", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Neck And Lumbar Pain Relieving Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neck Back Sciatic Remedy", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Necon", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Need For You Limpio Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neem Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nefazodone Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neilmed Anti-Itch", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neilmed Clear Canal Earwax Removal Kit", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Neilmed Hot And Cold Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelarabine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nellies Baby Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nellies Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nellies Hand Sanitizer Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Bio Medical Bioacne Anti-Redness 50G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Bio Medical Bioacne Anti-Redness Serum 30 Ml", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Biosolar Colorlift 1 Tinted Sunscreen 30 Ml", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Biosolar Colorlift 2 Tinted Sunscreen 30 Ml", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Cleansing Bioacne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Mask Bioacne", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Micellar Foam Bioacne", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Organic Sunscreen Natural Protection Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Organic Transparent Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Ph Toner Bioacne", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Purifying Bioacne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Salicylic Acid Complex Bioacne", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nelly Devuyst Serum Bioacne", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nematodes Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nembutal Sodium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Nena Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nena Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nenningers Naturals", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neo Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neo Polycin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Neo Polycin Hc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Neo-Synalar",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Neo-Synephrine Maximum", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Neo-Synephrine Mild", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Neo-Synephrine Regular", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Neobenz Micro Plus Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Neogen Dermalogy Day-Light Protection Airy Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neogen Dermalogy Day-Light Protection Sun Screen Spf50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neol One", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neolinn", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neomycin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neomycin And Polymyxin B Sulfates", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Irrigation" }] }] },
    { Medication: "Neomycin And Polymyxin B Sulfates And Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Neomycin And Polymyxin B Sulfates And Dexamethasone",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Neomycin And Polymyxin B Sulfates And Gramicidin", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Neomycin And Polymyxin B Sulfates And Hydrocortisone",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Auricular (Otic)" }, { Route: "Ophthalmic" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Auricular (Otic)" }] },
        ],
    },
    { Medication: "Neomycin And Polymyxin B Sulfates And Hydrocortisone Otic Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Neomycin And Polymyxin B Sulfates, And Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Neomycin And Polymyxin B Sulfates, Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Neomycin And Polymyxin B Sulfates, Bacitracin Zinc And Hydrocortisone", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Neomycin And Polymyxin B Sulfates, Bacitracin Zinc, And Hydrocortisone", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Neomycin Polymyxin B Sulfates And Dexamethasone", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }, { Route: "Topical" }] }] },
    { Medication: "Neomycin Sulfate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neonatal Complete", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neonatal Dha", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neonatal Fe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neonatal19", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neoprofen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Neora",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Neoral",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Neorelief For Menstrual Pain And Cramps", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neorelief For Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neorelief Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neosporin Eczema Essentials", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neosporin Eczema Essentials Daily Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neosporin First Aid Antiseptic Foaming For Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neosporin Kids Plus Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neosporin Lip Health", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neosporin Original", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neosporin Pain Itch Scar", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neosporin Plus Burn Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neosporin Plus Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Neosporin Plus Pain Relief Neo To Go First Aid Antiseptic Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neostigmine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Neostigmine Methylsulfate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Neostrata Enlighten Skin Brightener", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neostrata Sheer Hydration", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neostrata Sheer Physical Protection", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neostrata Skin Active Matrix Support", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neotica", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neova Day Therapy", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neova Dna Damage Control - Active", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neova Dna Damage Control - Everyday", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neova Dna Damage Control - Silc Sheer", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nephplex Rx", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nephron Fa", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nephroplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nephroscan", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nephrovesic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neptune 362 Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neptune 367 Foaming Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neptune 375 Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neptune 377 Foaming Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neptune Ice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neptune Ice Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neptune Wellness Solutions Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nerlynx", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nerve Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nerve Pain",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nerve Reverse Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nerve Tonic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nerve Tonic Stress Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nerve Z", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nervinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nervinum Vagum 4Ch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nervive Pain Relieving",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nervous Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nervous Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nervousness - Anxiety",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nervousness Excitement", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nervus Stat. 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nervus Trigeminus 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nervus Vagus 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nesacaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Infiltration; Perineural" }] }] },
    { Medication: "Nesina", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nestabs", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nestabs Dha", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nestabs One", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nesura Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Netimist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Netspot", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Nettle",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Nettle Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Neu-Regen", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuac", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuac Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neulasta",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Neulumex", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neupogen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    {
        Medication: "Neupro",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Neuraceq", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Neuracin Topical Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuralgia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuralgia Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuralgia Numbness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuralgo Rheum", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intradermal; Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Neuriterx", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neuro",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Neuro 3", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuro Links", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuro Relax Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuro-Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuro-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuro-Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neurobalance Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neurocomfort Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neurocomfort Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neurocomfort Pain Relief Gel Extra Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neurocomfort Pain Relief Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neurocomfort Pain Relief Roll-On Extra Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neurodexx", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuroflex Topical Pain Relieving Liquid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuroforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neurogena Healthy Defense Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuroinflammacil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neurolatore Power Formula Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neurolite", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Neuromaquel Neuroma/Anti-Inflammatory System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Neuromed", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuromed 7 Topical Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuromed Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuromed Topical Anesthetic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neurontin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Neuropar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuropathy Support Foot Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neurospora Intermedia",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Neurostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neurotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neurotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neurotox Hand Neuro", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neuroveen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neuroxa Kit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutra Maxx 5000", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neutracure Restore", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutral Sodium Fluoride", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Neutral-Eyes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Neutralizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutralyze Clearing Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutramaxx 5000", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neutramaxx 5000 Plus Turbo", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neutramaxx 5000 Tcp", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Neutrevo Liquid Hand Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutro Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neutrogena",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Neutrogena Acne Control", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Acne Proofing Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Acne Proofing Daily Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Age Shield Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Age Shield Face Oil Free Sunscreen Broad Spectrum Spf 110", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Age Shield Face Oil Free Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ageless Essentials Continuous Hydration Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ageless Intensive Anti-Wrinkle", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ageless Intensives Anti Wrinkle Deep Wrinkle Daily Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ageless Restoratives Anti-Oxidant Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena All In 1 Acne Control Daily Scrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neutrogena Beach Defense Water Plus Sun Protection",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Neutrogena Beach Defense Water Plus Sun Protection Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Beach Defense Water Plus Sun Protection Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Beach Defense Water Plus Sun Protection Sunscreen Broad Spectrum Spf 60 Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neutrogena Beach Defense Water Plus Sun Protection Sunscreen Broad Spectrum Spf 70",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Neutrogena Beach Defense Water Plus Sun Protection Sunscreen Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Beach Defense Water Plus Sun Protection Sunscreen Broad Spectrum Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Beach Defense Water Plus Sun Protection Sunscreen Stick Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Blackhead Eliminating Daily Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Body Clear Body Scrub", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Body Clear Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Body Clear Body Wash Pink Grapefruit", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Bright Boost Moisturizer Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Clear Body Breakout Free Oil Free Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Clear Face Breakout Free Oil Free Broad Spectrum Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Clear Face Breakout Free Oil Free Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Clear Face Breakout Free Oil Free Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Clear Pore Cleanser Mask", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Clear Pore Oil-Eliminating Astringent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Clinical Lifting Wrinkle Treatment System", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Complete Acne Therapy Solution", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Neutrogena Cool Dry Sport Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Cooldry Sport", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Cooldry Sport Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neutrogena Cooldry Sport Sunscreen Broad Spectrum Spf 50",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Neutrogena Cooldry Sport Sunscreen Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Cooldry Sport Sunscreen Broad Spectrum Spf100", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Daily Control Tgel 2 In 1 Dandruff Plus Conditioner", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Day Activating", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Deep Clean Purifying Cooling Gel Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Deep Moisture Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Defense Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Defense Daily Moisturizer Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Defense Daily Moisturizer With Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Anti Wrinkle With Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Anti-Aging Perfector Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Brightening Eye Perfector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Brightening Eye Perfector Spf 20 - Buff 09", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Brightening Eye Perfector Spf 20 - Fair 05", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Brightening Eye Perfector Spf 20 - Light 10", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Brightening Eye Perfector Spf 20 - Medium 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Compact Makeup With Sunscreen Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Enhancer With Sunscreen Broad Spectrum Spf 20 - Fair To Light 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Enhancer With Sunscreen Broad Spectrum Spf 20 - Ivory To Fair 10", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Enhancer With Sunscreen Broad Spectrum Spf 20 - Light To Neutral 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Enhancer With Sunscreen Broad Spectrum Spf 20 - Neutral To Tan 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Enhancer With Sunscreen Broad Spectrum Spf 20 - Tan To Medium 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Firming", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Glow Sheers Illuminating Tinted Moisturizer - Fair To Light 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Glow Sheers Illuminating Tinted Moisturizer - Ivory To Fair 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Glow Sheers Illuminating Tinted Moisturizer - Light To Medium 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Makeup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Primer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Radiant Tinted Moisturizer Sunscreen Broad Spectrum Spf 30, Sheer Fair 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Radiant Tinted Moisturizer Sunscreen Broad Spectrum Spf 30, Sheer Ivory 10", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Healthy Skin Radiant Tinted Moisturizer Sunscreen Broad Spectrum Spf 30, Sheer Tan 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Hydro Boost City Shield Water Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Hydro Boost Hyaluronic Acid Moisturizer With Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Hydro Boost Water Gel Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Hydroboost Water Gel Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Hydroboost Water Gel Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Invisible Daily Defense Body Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Invisible Daily Defense Body Sunscreen Broad Spectrum Spf 60 Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Invisible Daily Defense Face Mist Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Invisible Daily Defense Face Serum Sunscreen Broad Spectrum Spf 60 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Invisible Daily Defense Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Invisible Daily Defense Sunscreen Broad Spectrum Spf 60 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Men Age Fighter Face", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Men Age Fighter Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Men Skin Clearing Acne Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Men Triple Protect Face With Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Mineral Ultra Sheer Dry-Touch Face And Body Sunscreen Broad Spectrum Uva-Uvb Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Moistureshine Lip Soother", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Moistureshine Spf20", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Norwegian Formula Lip Moisturizer Sunscreen Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil Free Acne Moisturizer Pink Grapefruit", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil Free Acne Stress Control Power Clear Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil Free Acne Stress Control Triple Action Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neutrogena Oil Free Acne Wash",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Neutrogena Oil Free Acne Wash Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil Free Acne Wash Pink Grapefruit Facial Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil Free Acne Wash Pink Grapefruit Foaming Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neutrogena Oil Free Acne Wash Redness Soothing Facial Cleanser",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Neutrogena Oil Free Moisture Broad Spectrum Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil-Free Acne Stress Control Power-Cream Wash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil-Free Acne Wash Daily Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil-Free Acne Wash Pink Grapefruit Foaming Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Oil-Free Moisture", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena On-The-Spot Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Protect Plus Tint Fair To Light 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Protect Plus Tint Ivory To Fair 10", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Protect Plus Tint Light To Tan 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Pure And Free Baby", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Pure And Free Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Clear", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Clear 2 In 1 Fight And Fade Toner", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Clear Acne Eliminating Spot", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Clear Foaming Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Clear Stubborn Acne Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Clear Stubborn Acne Spot", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Tone Repair Moisturizer Sunscreen Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Tone Repair Retinol Plus Vitamin C Moisturizer Sunscreen Broad Spectrum Spf 30 Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Wrinkle Repair Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Rapid Wrinkle Repair Retinol Moisturizer Sunscreen Broad Spectrum Spf 30 Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Revitalizing Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Scalp Therapy Anti-Dandruff Daily Control", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Scalp Therapy Anti-Dandruff Extra Strength", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Scalp Therapy Anti-Dandruff Itchy Scalp", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Scalp Therapy Anti-Dandruff Scalp Build-Up Control", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Sensitive Skin Face Liquid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Sensitive Skin Face Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Sensitive Skin Mineral Sunscreen Broad Spectrum Spf 60 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Sheer Zinc Dry-Touch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Sheer Zinc Face Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Neutrogena Sheer Zinc Kids Mineral Sunscreen Broad Spectrum Spf 50 Plus",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Neutrogena Sheer Zinc Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Sheer Zinc Mineral Sunscreen Broad Spectrum Spf 50 Plus", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skin Polishing Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Mineral - Buff", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Mineral - Classic Ivory", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Mineral - Honey Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Mineral - Natural Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Mineral - Natural Ivory", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Mineral - Nude", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Mineral - Soft Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Mineral Powder - Chestnut 135", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Buff 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Caramel 105", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Chestnut 135", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Classic Ivory 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Cocoa 115", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Fresh Beige 70", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Honey 85", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Medium Beige 80", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Natural Beige 60", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Natural Ivory 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Natural Tan 100", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Nude 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Soft Beige 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Skinclearing Oil Free Makeup - Warm Beige 90", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Spectrum Plus Advanced", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Spectrum Plus Face Advanced Oil Free", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Sport Face Oil Free Sunscreen Broad Spectrum Spf 70 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Stubborn Acne Am Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Stubborn Blackheads Daily Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Stubborn Texture Daily Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Sun Rescue After Sun Medicated Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena T/Gel Therapeutic Extra Strength", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena T/Gel Therapeutic Original Formula", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena T/Sal", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena T/Sal Therapeutic", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Tgel Therapeutic", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Triple Age Repair Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Triple Age Repair Moisturizer With Sunscreen Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Body Mist", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Body Mist Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Body Mist Sunscreen Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Body Mist Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Dry Touch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Dry Touch Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Dry Touch Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Dry Touch Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Dry Touch Sunscreen Broad Spectrum Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Dry Touch Sunscreen Broad Spectrum Spf55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Face And Body", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Face Mist Sunscreen Broad Spectrum Spf 55", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Face Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Ultra Sheer Moisturizing Face Serum Sunscreen Broad Spectrum Spf 60 Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Visibly Even Daily Moisturizer With Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Wet Skin Kids", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Wet Skin Kids Beach And Pool Sunblock", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Wet Skin Kids Beach And Pool Sunscreen Broad Spectrum Spf 70 Plus", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Neutrogena Wet Skin Swim Humidity Sweat", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nevada Natural Products Cream Sunscreen Spf 90", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nevanac", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Nevirapine",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "New Clairfore", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Cleanflex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Day", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "New Life 3% Hydrogen Peroxide First Aid Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Life Advanced Formula Topical Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Life Antibacterial Handsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Life Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Life Rubbing Isopropyl Alcohol 70% First Aid And Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Life Sanitizing Handlotion With Vitamin E And B5- Aloe Vera", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Nbf Gingival Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "New Sanicol Advanced Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "New Skin Bandage",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "New Stock Fungi Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "New Terocin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Wave Cleaning Solutions Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Wave Cleaning Solutions Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New Wave Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "New-Freedom S", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Newgel Plus Spf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Newrain Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Newtralize", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Newtralize Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Newyork-Presbyterian Amenity", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nexafed", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nexavar", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nexesta Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nexgen Sunscreen Broad Spectrum Spf 36", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nexiclon Xr", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nexium",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Nexium 24Hr",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nexium 24Hr Clearminis", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nexletol", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nexlizet", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nexplanon", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Next 1 Anti-Bacterial Deodorant", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Next Cell Medical",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Next Choice One Dose", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Next Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Next Home Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Next Night Severe Cold, Flu And Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Next1 Anti-Bacterial Deodorant Sport", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nexterone", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nexterone (Amiodarone Hci)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nextstellis", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nexviazyme", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ngf 574H Hair", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ngf 574H Hair Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ngf37 Hucb Boost", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nhs Constipation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Dyspepsia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Flatulance Slow Digestion", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Flu Fever Sudden Onset", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Flu Nausea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Headache Flu", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Hip Pain", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Hoarse Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Insomnia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Insomnia 1", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Insomnia 2", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Insomnia 4", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Muscle Cramps 1", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Nausea Heartburn", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Rheumatism", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Rheumatism Eczema", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Skin Irritation Cuts", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nhs Spasms Weakness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nia 24 Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nia24 Sun Damage Defense Broad Spectrum 100 Percent Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Niacin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Niacor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Niagara S", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Niaspan", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nicardipine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Niccolum Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nice 'N Clean Wipes Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nice Assorted Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nice Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nice Citrus Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nice Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nice Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nice N Clean Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nice N Clean Wipes Sani-Hands Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nice N Clean Wipes Sani-Hands Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nice Sore Throat Cherry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nice Sore Throat Honey Lemon", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nice Sore Throat Spearmint", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nichols Luxury Antimicrobial Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nickelodeon Slime Bubble Gum Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nickelodeon Slime Red Raspberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nickelodeon Slime Watermelon Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nicoderm", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Nicole Miller Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nicole Miller New York Antibacterial Hand Wipes Coconut Vanilla Warm Sugar Honey Almond 3 Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Nicorette",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nicorette Fruit Chill Nicotine Polacrilex", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicorette White Mint Nicotine Polacrilex", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotac Nicotine Polacrilex", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }] }] },
    {
        Medication: "Nicotiana Carbo",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nicotiana E Fol. 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotiana Pulsatilla", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotiana Pulsatilla Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }, { Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Nicotine Coated Fruit Flavor", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotine Coated Ice Mint", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotine Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotine Gum", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotine Lozenge", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotine Mini", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nicotine Polacrilex",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }, { Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nicotine Polacrilex Gum", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Nicotine Polacrilex Lozenge Mint", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nicotine Polacrilex, Original", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Nicotine Transdermal Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nicotine Transdermal System",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Nicotinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nicotrol",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Nictoine Gum", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nifedipine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Niferex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nifola Hand Sanitizer With Aloe Vera And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Night Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Cold Flu Cough And Allergy", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Night Time",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Night Time Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Night Time Cold And Flu Formula", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Cold And Flu Severe", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Cold And Flue Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Severe Cold And Cough", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Severe Cold And Cough Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Severe Cold And Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Sinus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night Time Sinus Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Night Time Sleep Aid",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Night Time Sleep-Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Night-Time", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nightime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nightime Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nightime Cough And Bronchial", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nightime Sleep Aid", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nightshade Antigen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nightshade Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nightshade Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nighttime",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Childrens Multi Symptoms", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nighttime Cold And Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Cold And Cough Sugar-Free", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nighttime Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Cold And Flu Max Softgels Maximum Strength", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nighttime Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Cold/Flu Relief Softgels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Control", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nighttime Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Cough Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Cough Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nighttime Cough Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Daytime Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nighttime Daytime Severe Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nighttime Hbp", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Hbp Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Maximum Strength Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Multi Symptom Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Pain Reliever Plus Sleep Aid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Pain Reliever Pm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Severe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nighttime Severe Cold And Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Nighttime Severe Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Nighttime Severe Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Severe Cold Cough And Flu Relief", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Severe Cough And Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Sinus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Sinus And Congestion Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Sinus Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nighttime Sleep Aid",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Sleep Aid Maximum Strength", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nighttime Sleep-Aid",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nighttime Sleep-Aid Softgel", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nighttime Wal Flu Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Niguo Disinfectant Wipes-80 Sheets", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nikki", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nikzon", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nilandron", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nilutamide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nimbex", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nimbus", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nimbus9", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nimbus9 Advanced Alcohol Wipes Pouch 80Pcs", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nimbus9 Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nimbus9 Hand Sanitizer 225Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nimbus9 Hand Sanitizer 3.78L", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nimbus9 Hand Sanitizer 500Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nimodipine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ninebio Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ninebio Sanitizer Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ninetails Advanced Line Lift Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ninetails Double Drop Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ninetails Neck Decolte Smoother", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ninetails Radiance Lift Sheet Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ninetails Rebalancing Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ninjacof", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ninjacof Cotton Candy Flavor- 16 Oz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ninjacof-D", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ninlaro", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nioxin Hair Regrowth Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Topical" }] }] },
    { Medication: "Nioxin Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nioxin Hair Regrowth Treatment Extra Strength For Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nioxin Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nioxin Scalp Optimizing Cleanser", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nioxin Scalp Optimizing Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nioxin Scalp Recovery",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nipent", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nipride Rtu", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Niravi 24 Hours Lidocaine 4 % Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Mega", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Mega Disinfection", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Mega Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Pure 50%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Pure 50% With Wintergreen And Glycerin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Pure 70%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Pure 90%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Pure Disinfection", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisc Pure Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nisoldipine", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitazoxanide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nite Time", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nite Time Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nite Time Day Time", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nite Time Severe Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nite-Time", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nite-Timecold/Flu Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitecin", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nitetime",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Nitetime Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nitetime Cold/Flu, Multi-Symptom Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitetime Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitetime Cough Daytime Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nitetime Daytime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nitetime Sinus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitetime Sinus Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nithiodote", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nitisinone", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitouch Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nitricum Acidum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nitro-Bid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nitro-Dur", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Nitro-Thio Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitro-Time", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitrofurantion", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitrofurantion Macrocrystals", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nitrofurantoin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nitrofurantoin (Monohydrate/Macrocrystals)", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitrofurantoin Macrocrystals", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitrofurantoin Mono", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitrofurantoin Monohydrate Macrocrystalline", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitrofurantoin Monohydrate/ Macrocrystalline", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitrofurantoin Monohydrate/Macrocrystals", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nitrogen",
        DoseTypes: [
            { DoseType: "Gas", Routes: [{ Route: "Cutaneous; Respiratory (Inhalation)" }, { Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    { Medication: "Nitrogen Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitrogen Oxygen Mix", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Nitrogen, Refrigerated", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Cutaneous" }] }] },
    {
        Medication: "Nitroglycerin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Nitroglycerin In Dextrose", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nitroglycerin Lingual", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitroglycerin Transdermal Infusion System", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    {
        Medication: "Nitroglycerin Transdermal System",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Nitrolingual", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nitromist", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Nitrostat", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Nitrous Oxide", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Nitrous Oxide Sedara", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Nitroval Blue", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nityr", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nivagen Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nivanex Dmx", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nivea Men Protective", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nivea Men Sensitive Protective", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nivea Recovery Medicated Lip Care", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nivea Smoothness Lip Care", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nivestym", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Nix",
        DoseTypes: [
            { DoseType: "Rinse", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nix Complete Lice Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nix Complete Maximum Strength Lice Elimination", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nix Family Pack Lice Killing Creme Rinse Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nix Lice Killing Creme Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nizatidine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nizoral", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nizoral Psoriasis", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nl Hand Sani Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nli Gbb Hand Sanitizer Fragrance", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nmn", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nmn.5S", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nnp Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Bs Moisturizer Spf20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Bs Spf20 Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Drip", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "No Drip Anefrin Nasal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "No Drip Nasal Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "No Drip Nasal Mist Nasal Decongestant Severe Congestion", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "No Drip Severe Nasal Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "No Itching", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Bronzer Spf15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Concealer (Deep) Spf 20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Concealer (Fair) Spf 20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Concealer (Light) Spf 20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Concealer (Medium) Spf 20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Concealer (Tan) Spf 20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Concealer Fair Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Concealer Medium Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation (Buff) Spf20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation (Golden) Spf20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation (Nude) Spf20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation (Porcelain) Spf20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation (Rich) Spf20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation (Tan) Spf20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation Fair Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation Fair-Light Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation Light Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation Light-Medium Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Foundation Tan Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Lipstick (Berry) Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Lipstick (Cognac) Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Lipstick (Original Pink) Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Lipstick (Red) Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Lipstick (Rose) Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Makeup Lipstick (Wine) Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No More Nosebleed", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No Sun Face Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No-Scar Oint.", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Calico", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Chestnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Cool Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Cool Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Cool Vanilla", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Deeply Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Latte", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Warm Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Warm Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Beautifully Matte Foundation Sunscreen Broad Spectrum Spf 15 Wheat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 City Light Tinted Moisturiser Sunscreen Broad Spectrum Spf 15 Fair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 City Light Tinted Moisturiser Sunscreen Broad Spectrum Spf 15 Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 City Light Tinted Moisturiser Sunscreen Broad Spectrum Spf 15 Medium Dark", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Dual Action Tinted Moisturiser Fair", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Dual Action Tinted Moisturiser Medium", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Early Defence Day Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Early Defence Glow Activating Day Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Laboratories Acne Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Day Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Fragrance Free Day Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Calico", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Cool Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Cool Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Cool Vanilla", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Deep Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Deep Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Hazelnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Latte", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Sandalwood", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Sepia", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Shell", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Toffee", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Warm Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Warm Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Warm Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Wheat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Lift And Luminate Triple Action Serum Foundation Sunscreen Broad Spectrum Spf 15 Willow", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Menopause Skincare Protect And Hydrate Day Cream Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Calico", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Chestnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Cool Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Cool Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Cool Vanilla", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Porcelain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Toffee", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Warm Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Warm Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Advanced All In One Foundation Spf 50 Wheat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Amber", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Bamboo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Calico", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Chestnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Cool Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Cool Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Cool Vanilla", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Deeply Bronze", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Deeply Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Dune", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Hazelnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Latte", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Sand", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Sepia", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Toffee", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Warm Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Warm Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Wheat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Perfect And Perfect Intense Advanced All In One Foundation Spf 50 Willow", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Protect And Perfect Intense Advanced Day Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Protect And Perfect Intense Advanced Day Cream Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Protect And Perfect Intense Advanced Fragrance Free Day Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Face And Neck Multi Action Day Cream Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Amber", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Bamboo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Calico", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Cedar", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Chestnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Cool Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Cool Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Cool Vanilla", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Dune", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Hazelnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Honey", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Latte", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Porcelain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Tawny", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Umber", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Warm Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Warm Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Restore And Renew Multi Action Serum Foundation Sunscreen Broad Spectrum Spf 20 Wheat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Stay Perfect Foundation Sunscreen Broad Spectrum Spf 15 Chestnut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7 Stay Perfect Foundation Sunscreen Broad Spectrum Spf 15 Deeply Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "No7Restore And Renew Face And Neck Multi Action Day Cream Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Noad Sport Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Noble 1 Plus", DoseTypes: [{ DoseType: "Powder, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Noble Art Numbing Cream With Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Noble G Plus", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Noble S Plus", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Nobleaid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nobleaid Capsaid", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nobleaid Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nocdurna", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Nocold-F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nocto Sleep Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nocturnal Enuresis Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nodi Lymphatici 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nodoz Alertness Aid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Noella 24K Aged Defense Spf 30 Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Noevir Raysela Uv Shield Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Nohist Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nohist Lq", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nolix", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Non Drowsy Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non Drowsy Day Cold And Flu Plus And Nighttime Cold And Flu Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Non Drowsy Daytime And Nighttime Sinus Congestion And Cough Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non Drowsy Daytime Cold And Flu Softgel", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non-Alcohol Foaming Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Non-Aspirin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Non-Aspirin Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non-Drowsy Daytime Cold And Flu Max Softgels Maximum Strength", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non-Drowsy Daytime Multi Symptom Cold And Flu Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non-Drowsy Daytime Severe Cold Multi-Symptom", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non-Drowsy Daytime Severe Sinus Congestion And Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non-Drowsy Sinus Daytime", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Non-Nano Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Non-Negotiables Fast-Acting Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Non-Sterile", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nongbiae Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Noon For Kids", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nopiate Topical Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nora Be", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nordic Wellness Vitamin C Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Norditropin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Norel Ad", DoseTypes: [{ DoseType: "Tablet, Multilayer", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norelgestromin And Ethinly Estradiol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Norepinephrine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Norepinephrine Bitartrate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Norepinephrine Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norethindrone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norethindrone Acetate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Norethindrone Acetate And Ethinyl Estradiol",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Norethindrone Acetate And Ethinyl Estradiol And Ferrous Fumarate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Norethindrone Acetate And Ethinyl Estradiol, And Ferrous Fumarate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Norethindrone And Ethinyl Estradiol And Ferrous Fumarate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Norgesic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norgesic Forte", DoseTypes: [{ DoseType: "Tablet, Multilayer", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norgestimate And Ethinyl Estradiol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Noritate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Norliqva", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norlyda", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Normal Intestinal Flora Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Normal Salt", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norml Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Normosol-M And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Normosol-R", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Normosol-R And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Norpace", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norpace Cr", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Norpramin", DoseTypes: [{ DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "North American Coral Snake Antivenin (Equine)", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "North Burn", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "North First Aid Antiseptic Water Soluble", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "North Pvp Iodine Swabs", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "North Sinus Decongestant", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "North Woods D-Germ", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "North Woods Derma Foam E-2", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "North Woods Derma Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "North Woods Derma Gel Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "North Woods Supreme Antiseptic Hand Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Northeast Regional", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Northera", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Northside Hospital Amenity Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital Amenity Kit--Gi Dept", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital Bereavement", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital C-Section Mothers Bag", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital C-Section Mothers Tote", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital H.E.A.R.T. Strings Bag", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital H.E.A.R.T. Strings Tote", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital Mothers Bag", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital Mothers Vaginal Tote", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northside Hospital Natural Mothers Bag", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Northwest Regional", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nortrel",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nortrel 7/7/7", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nortriptyline Hci", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nortriptyline Hcl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nortriptyline Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Norvasc", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Norvir",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Norwalk Virus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nose", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Noshinku Bergamot Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Noshinku Eucalyptus Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Noshinku Lavender Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nosnooze", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nostrilla", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Not Applicable", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Not Pot Cbd Icy Cooling Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Not Pot Oat Puff Cbd Eczema", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Notaflu Lemon Oil", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }, { Route: "Oral" }] }] },
    { Medication: "Notaflu Lemon Oil Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nothing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nourianz", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nourishing Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nourishing Hand Sanitizing", DoseTypes: [{ DoseType: "Insert, Extended Release", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nova Bright Skin Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nova Complex Hq", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nova Green Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novagel", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novagel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novagel Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novagreen Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novalent Shield Advanced Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novalent Shield Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novalgina", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Novapur Creamy Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novarel", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Novarnica", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novarnica Foot Pain Reliever", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novarnica Sport", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novavax Covid-19 Vaccine, Adjuvanted", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Novelty On The Go Hand Sanitizers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novita 50% Isopropyl Rubbing Alcohol W/ Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novita 70% Isopropyl Rubbing Alcohol Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novita Baby Petrolatum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novita Isopropyl Alcohol 50%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novita Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novita Petrolatum Jelly Pink", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novita Petrolatum With Cocoa Butter", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novita Pure Petrolatum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novo Proseries Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novo Proseries Foaming Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novo Proseries Foaming Sanitizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novo Pure Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novo Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novo Wipe 30 Pcs", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novoeight", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Novogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Novolin",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Novolog", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Novolog Mix 70/30", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Novoseven", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Novospray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novuly Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Novum", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Now St", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nox-A-Sting", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Noxafil",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Noxivent 101", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Noxivent 102", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Noxzema",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nozin Body Bath (Skin Cleansing Antiseptic Wipes)", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nozin Nasal Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nozin Nasal Sanitizer (Antiseptic)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Np Thyroid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Np Thyroid 120", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Np Thyroid 15", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Np Thyroid 30", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Np Thyroid 60", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Np Thyroid 90", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nplate", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Nrg Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Nrg Hemo-Stat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Nrg Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Nrv-Systemic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ns Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nsi Sanitizing Wipes", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nsure Kleenwipe Sanitizing Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nsure Purewipe Alcohol Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Calmar", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Almond", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Chestnut", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Espresso", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Ivory", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Java", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Light Golden", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Linen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Medium Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Medium Ochre", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Mocha", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Natural Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Ochre", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Shell Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Colour Bioadaptive Bb Plus Foundation - Tan", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nu Skin",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nu Skin 180 Degree Uv Defense Hydrator Spf 18", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Me Day S1 Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Me Day S2 Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Me Day S3 Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Me Day S9 Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Me Day T1 Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Me Day T2 Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Me Day T3 Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Me Day T9 Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Ageloc Radiant Day Broad Spectrum Spf 22", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nu Skin Clear Action",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nu Skin Clear Action Acne Medication Night Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Almond", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Delicate Ivory", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Honey Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Medium Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Medium Ochre", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Mocha", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Neutral Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Ochre", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Pink", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Pink Ochre", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Soft Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Sunny Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Sunset Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Finish Broad Spectrum Spf 15 - Tawny", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Fair", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Honey", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Medium Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Medium Ochre", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Natural Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Ochre", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Pink", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Pink Ochre", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Nu Colour Advanced Tinted Moisturizer Broad Spectrum Spf 15 - Sand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Sunright 35 Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Sunright 50 Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu Skin Sunright Lip Balm Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu-Derm Blender", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu-Derm Clear", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu-Derm Healthy Skin Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu-Derm Physical Uv", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu-Derm Sunfader", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nu-Derm System Normal-Dry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nu-Derm System Normal-Oily", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nu-Derm Travel Set Normal/Dry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nuance Medical", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuance Salma Hayek Renewed Radiance Brightening Bb Cream Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuance Salma Hayek Renewed Radiance Moisturizing Day Cream Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nubeqa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nuby Soothing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nucala",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Nucaraclinpak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nucararxpak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nucynta",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nudermrxpak 120", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nudermrxpak 60", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nudestix Daily Mineral Veil Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nudestix Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nudiclo Solupak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nudiclo Tabpak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nudroxicin", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nudroxicin Pain Relief Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nudroxipak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nudroxipak Dsdr-50", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nudroxipak Dsdr-75", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nudroxipak E-400", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nudroxipak I-800", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nudroxipak M-15", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nudroxipak N-500", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nuedexta", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nufabrx Ankle Sleeve", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Arm Sleeve", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Calf Sleeve", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Foot/Ankle Sleeve", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Hand/Wrist Sleeve", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Knee Sleeve", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Lower Leg Sleeve", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Lower Leg Sleeve M/L", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Lower Leg Sleeve Xl/Xxl", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Lower Leg Sleeve Xs/S", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Neck Wrap", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nufabrx Wrist Sleeve", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuhydrashield 30 Spf-30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nulev", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nulibry", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nulido", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nulido Lidocaine Patch Plus Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nulojix", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nulytely", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nulytely With Flavor Packs", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Numb 100", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numb 520", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numb Gel Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numb Master", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numb Plus Maximum Strength Anorectal", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numb Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Numb520",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Numbing Max Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numbness Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Numbrino", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Numfast Tetracaine Green", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numian Heratage Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numotizine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numpot Topical Anesthetic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numquick Analgesic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numquick Topical Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Numstick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuori Mineral Defence Sunscreen Face And Body Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuori Mineral Defence Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuori Mineral Defence Sunscreen Water Resistant Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuori X Boost The Light Salon Mineral Defence Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nuplazid",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nupro 60 Second Fluoride Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Nupro Chlorhexidine Gluconate", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nupro Fluorides",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Dental" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Nupro Fluorides Naf Oral Solution", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Nuria Defend Matte Finish Daily Moisturizer Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nurinse",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nursing Cream", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nurtec Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nurture Antiseptic Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nurtureflo Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nurtureflo Gel Hand Sanitizer Ocean Breeze", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nurtureflo Non-Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nut Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nut Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nutesse Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nutmeg", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Nutralox", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nutrastate Soothing Relief Roll-On Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nutrastate Soothing Relief Topical Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nutriarx Creampak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nutricentials Day Dream Protective",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nutricentials Here You Glow Exfoliating Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nutrilipid I.V. Fat Emulsion", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nutropin Aq Nuspin 10", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Nutropin Aq Nuspin 20", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Nutropin Aq Nuspin 5", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Nuturna Arthritis Intensive Concentrate For Pain Relief And Recovery", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu 100 Pure Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Epsom Salt", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nuvalu Hand Sanitizer Aloha Pina Colada 1Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Hand Sanitizer Guava Burst 1Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Hand Sanitizer Sweet Pea 1Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Hand Sanitizer Sweet Pea Blue 1Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Hand Sanitizer Sweet Pea Pink 1Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Hand Sanitizer Violet Mist 1Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Ice Therapy Menthol Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Instant Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Instant Hand Sanitizer Aloe Vera 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Instant Hand Sanitizer Lavender 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Instant Hand Sanitizer Original", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Instant Hand Sanitizer Original 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Petroleum Baby Fresh Scent", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Petroleum Cocoa Butter", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvalu Vaporizing Chest Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvaring", DoseTypes: [{ DoseType: "Insert, Extended Release", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Nuvessa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvigil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nuvik Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nuvik Large Roll In Bucket Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Nuvomed Antibacterial Wet Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nuwiq", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Nux Moschata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nux Vom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nux Vomica",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Nux Vomica 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Nux Vomica Chamomilla",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nux Vomica Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nux Vomica E Sem 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nux Vomica E Sem. 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nux Vomica Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nuzyra",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nv-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nvcare", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nwk A-Fv", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nxn Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nxn Nurture By Nature Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nyakio Hydrating Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nyamyc", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nyc Health Hospitals", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nychh Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nylia 1/35", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nylia 7/7/7", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Nyloxin",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Nymalize", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nymyo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Nyquil Severe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nyquil Severe Plus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nyquil Severe Plus Vapocool Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nyquil Severe Plus Vicks Vapocool", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nyquil Severe Plus Vicks Vapocool Cold And Flu", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Nystatin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Oral; Topical" }, { Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Nystatin And Triamcinolone Acetonide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Nystatin Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nystatin Oral Suspension", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nystatin Topical Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nystop", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Nyt Time Sleep Caps", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nytol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Nyvepria", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Nyx Professional Makeup Bare With Me Daily Moisturizing Primer With Cannabis Sativa Seed Oil Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "O Hui Sun Science Ideal Cover Sunscreen Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "O Plus Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "O Skin Acne", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "O Skin Acne Benzoyl Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "O2 Bubble Pack Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "O2 Patch", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "O24 Pain Neutralizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "O2Safe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oak", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oak Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Oars And Alps Antibacterial Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oasis Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Oasis Tears Pf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Oasis Tears Pf Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Oasis Tears Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Oat Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Oat Grain", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Oatmeal", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oatmeal Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oatmeal Daily Moisturizing 8Oz.", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oatmeal Skin Relief Calming .", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oattbe Syn Bio Beta Glucans Brightening Calming", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oattbe Syn Bio Beta Glucans Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ob Metab", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Obagi", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi 360", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi 360 Hydrafactor Moisturizing Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi C Rx System C Clarifying Serum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi Medical - Nu-Derm System - Normal To Dry - Skin Transformation Trial Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi Medical Back To Basics (Including Obagi Medical Professional-C Serum 15)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi Medical Back To Basics (Including Obagi Medical Professional-C Serum 20)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi Medical Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi Medical Physical Defense Broad Spectrum Mineral Sunscreen Spf 40 By Suzanobagimd", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi Medical Physical Defense Broad Spectrum Mineral Sunscreen Spf 50 By Suzanobagimd", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi Nu-Derm Fx System Normal - Dry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi Nu-Derm Fx System Normal - Oily", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi Skintrinsiq Antioxidant Protocol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi Skintrinsiq Brightening Protocol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi Skintrinsiq Clarifiying Protocol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi Skintrinsiq Firming Protocol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi-C Rx System C-Clarifying Serum Normal To Oily", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi-C Rx System C-Sunguard", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi-C Rx System C-Therapy Night", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obagi-C Rx System Normal-Dry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obagi-C Rx System Normal-Oily", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obizur", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Obredon", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Obrilo Hand Sanitizer Formulated With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Obsidian", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Obstetrical Towelette", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocaliva", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Occuloplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Occuloplex G", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Occulus",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ocean Blue Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocean Breeze Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocean Breeze Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocean Breeze Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocean Citron Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocean Fresh Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocean Friendly Sunscreen Spf30 Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocean Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ocean Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ocean Hand Sanitizer Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocean Perch", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Ocella", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ocimum Canum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ocrevus", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Octagam Immune Globulin (Human)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Octaplas", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Octinoxate, Octisalate, Avobenzone", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Octinoxate, Zinc Oxide", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Octinoxate,Octisalate,Avobenzone,Octocrylene,Ensulizole,Titanium Dioxide", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Octiq", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Octiq Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Octisalate,Octocrylene,Octinoxate,Zinc Oxide,Titanium Dioxide,Ensulizole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Octoclenz", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Octopamine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Octreoscan", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Octreotide", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Octreotide Acetate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Ocuflox", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ocufresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Oculinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ocumend", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ocusan Dry Eyes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Odacite", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Odacite Sun Guardian Oceanic Glacial Water Day Creme Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Odactra", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Odefsey", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Odeur Premium Body Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Odomzo", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Odorono", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oenanthe Crocata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ofev", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Off Bite And Itch Relief First Aid Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Offects Te-Pads Acne Pore Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ofirmev", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ofirmev (Acetaminophen)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Ofloxacin",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }, { Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }, { Route: "Ophthalmic" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ofloxacin Ophthalmic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ofloxacin Otic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Ogenashield Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ogivri",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Oh My Bod Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oh My Bod Spf50 Antioxidant Infused Dry Touch Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oh My Clean Premium Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oh My Clean Premium Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oh My Clean Premium Sanitizer Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oh My Clean The Pure Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oh My Clean The Pure Sanitizer Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohhy Secure", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Allergy Animal Mix", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Allergy Grass, Weed, Tree Mix", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Allergy Mold Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Calendula Balancing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Cholesterol Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Drainage Lymphatic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Fatigue Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Fever Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Hepatic Terrain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Immune Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Menopause", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Mouthwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Mucosa Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Ohm Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Muscle And Joint Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Muscle And Joint Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Osteoarticular Terrain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Pancreas Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Parasites", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Pituitarum Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Sinus Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Ohm Skin Growths", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Skin Healing Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Skin Healing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Sleep Well", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Sore Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Ohm Sting Relief And Insect Repellent", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm Tonic Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm Venous Aid", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohm-Anx", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Cir", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Dam", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Detk", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Detl", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Dren Lft", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Flu", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Hep", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Inma", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Ins", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Ost", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Pan", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Ren", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohm-Resb", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohmyclean Intensive Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohmyclean Premium Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohso Antibacterial Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohtas Isan Antacid", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ohtrust Mouth And Nasal Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohtrust Nano Ion Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohui Ultimate Cover Cc Cushion Compact 01 Light Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ohui Ultimate Cover Cc Cushion Compact 02 Natural Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oi Lin Deep Moisture Sunscreen Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oi Lin Deep Moisture Sunscreen Spf 25 Tinted", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oi Lin Deep Moisture Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oi Lin Natural Emulsion Spf 30", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oi Lin Sunmilk Sunscreen Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oi Lin Sunmilk Sunscreen Spf 30", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oido Sana", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oil Control Mattifier Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil Free Acne", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil Free Acne Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil Free Deep Cleansing Duo Cleansing Brush Oil Free Acne Cleanser", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Oil Free Matte Sunscreen Spf 30, - Zinc Oxide, Octinoxate, Octisalate, Oxybenzone", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil Free Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil-Free Astringent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil-Free Daily Hydration Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil-Free Foaming Acne Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil-Free Foaming Acne Wash Facial Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oil-Free Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okay Blemish Control Apricot Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okay Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okay Mens Aluminium Free Deodorant Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okay Mens Antiperspirant Rollon Deodorant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okay Nourishing Peppermint Scrub And Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okay Womens Aluminium Free Deodorant Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okay Womens Antiperspirant Roll On Deodorant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okcheonwon", DoseTypes: [{ DoseType: "Pill", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Okeeffes Eczema Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okeeffes Healthy Feet", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okeeffes Healthy Feet Pain Relief Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Okeeffes Lip Repair",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Okeeffes Medicated Lip Repair Seal And Heal", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okeeffes Working Hands Eczema Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okgo Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Okoubaka", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Olanzapine",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Olanzapine And Fluoxetine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olay Age Defying Anti-Wrinkle Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Complete Normal Uv365 Daily Moisture With Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Complete Uv 365 Daily Moisturizer Combination Oily", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Complete Uv 365 Daily Moisturizer Normal", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Complete Uv365 Daily Moisturizer Sensitive", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Regenerist Collagen Peptide 24 Hydrating Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Regenerist Micro-Sculpting Anti-Aging Moisturize", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Regenerist Regenerating Advanced Anti-Aging Moisturize", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Regenerist Retinol 24 Plus Peptide Hydrating Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Regenerist Vitamin C Plus Peptide 24 Hydrating Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Regenerist Whip Active Moisturizer Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Regenerist Whip Active Moisturizer Broad Spectrum Spf 40 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Total Effects Cc Tone Correcting", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Total Effects Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Total Effects Moisturizer With Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Total Effects Whip Active Moisturizer Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olay Total Effects Whip Active Moisturizer Broad Spectrum Spf 40 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olbas Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olbas Pastilles", DoseTypes: [{ DoseType: "Pastille", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Old Spice", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Bald Care System Scalp Moisturizer Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Clinical Sweat Defense Pure Sport Plus Soft", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Game Day", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Old Spice High Endurance",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Old Spice Krakengard", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Odor Blocker Stronger Swagger", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Playmaker", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Pure Sport High Endurance Dtv", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Red Collection Captain", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Red Collection Captain Scent Of Command", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Red Collection Swagger", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Red Zone Collection Swagger", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Sea Antiperspirant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Seaspray", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Sweat Defense Extra Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Sweat Defense Lasting Legend", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Sweat Defense Pure Sport Plus", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Sweat Defense Steel Courage", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Sweat Defense Stronger Swagger", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Volcano With Charcoal", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Wild Collection Dragonblast Invisible", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Wild Collection Yetifrost Invisible", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Old Spice Wolfthorn", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olea Europaea Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Oleander", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oleander 4X", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Oligo Bio Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olika Hand Sanitizer Charcoal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hand Sanitizer Cucumber Basil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hand Sanitizer Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hand Sanitizer Mint Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hand Sanitizer Orange Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer Blue Tansy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer Cotton Flower", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer Frosted Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer, Ice Crystal", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer, Noble Fir", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer, Sugar Plum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer- Cucumber Basil", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer- Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer- Mint Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Hydrating Hand Sanitizer- Orange Blossom", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Ultra Hydrating Hand Sanitizer Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Ultra Hydrating Hand Sanitizer, Bold", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Ultra Hydrating Hand Sanitizer, Brave", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Ultra Hydrating Hand Sanitizer, Unstoppable", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olika Ultra Hydrating Hand Sanitizer,Strong", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olinvyk", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Olive", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Olive Blossom Scented Antiseptic Hand Sanitizer 80 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olive Leaf Acne Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olive Leaf Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Olive Leaf Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olive Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Olive Vaginal Formula", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Olive-Viate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Olivenite 6 Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Olivenite Stannum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olivenite Stannum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olivia Quido Blemish Eraser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olivia Quido Firm And Fade I", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olivia Quido Firm And Fade Ii", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olivia Quido Skincare Blemish Eraser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olje Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ollie Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olloflu", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Olmesartan Medoxomil",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Olmesartan Medoxomil / Amlodipine Besylate / Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Olmesartan Medoxomil And Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Olmesartan Medoxomil, Amlodipine And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olmesartan Medoxomil, Amlodipine And Hydrochlorothiazide 20/5/12.5 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olmesartan Medoxomil, Amlodipine And Hydrochlorothiazide 40/10/12.5 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olmesartan Medoxomil, Amlodipine And Hydrochlorothiazide 40/10/25 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olmesartan Medoxomil, Amlodipine And Hydrochlorothiazide 40/5/12.5 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olmesartan Medoxomil, Amlodipine And Hydrochlorothiazide 40/5/25 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olmesartan Medoxomil/Hctz", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olopatadine", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Olopatadine Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    {
        Medication: "Olopatadine Hydrochloride Ophthalmic Solution",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Olp Diaper Rash Cream A And D", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olumiant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Olux", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Olux-E", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Omeclamox-Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Omedi Anti-Bacterial Hand Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Omedi Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Omega-3-Acid Ethyl Ester", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Omega-3-Acid Ethyl Esters",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Omegaven", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Omeosport", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Omeprazole",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Omeprazole And Sodium Bicarbonate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Omeprazole And Sodium Bicrabonate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Omeprazole Dr", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Omeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Omeprazole Sodium Bicarbonate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Omeprazole, Sodium Bicarbonate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Omeprazole/Bicarbonate", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Omeprazole/Sodium Bicarbonate", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Omeza Lavage", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Omeza Licodaine Pain And Itch Relief Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Omeza Lidocaine Lavage", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Omeza Skin Protectant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Omidria", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intraocular" }] }] },
    { Medication: "Omnaris", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Omni", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Omni Fresh By Facela Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Omnipaque",
        DoseTypes: [
            {
                DoseType: "Injection, Solution",
                Routes: [
                    { Route: "Intra-Articular; Intrathecal; Intravascular; Intravenous; Oral; Rectal" },
                    { Route: "Intra-Articular; Intravascular; Intravenous; Oral" },
                    { Route: "Intrathecal; Intravascular; Intravenous; Oral" },
                    { Route: "Intrathecal; Intravascular; Intravenous; Oral; Rectal" },
                    { Route: "Intrathecal; Oral; Rectal" },
                    { Route: "Intravascular; Intravenous" },
                    { Route: "Intravascular; Intravenous; Oral" },
                    { Route: "Intravascular; Intravenous; Oral; Rectal" },
                    { Route: "Intravenous" },
                ],
            },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Omniscan", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Omnitrenidol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Omnitrope",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Omv Anti-Itch Bikini", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "On Guard Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "On The Go Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "On The Go Hand Sanitizer Blushing Rose",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "On The Go Hand Sanitizer Coconut Breeze",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "On The Go Hand Sanitizer Eucalyptus Mint",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "On The Go Hand Sanitizer Lavender Fields",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "On The Go Hand Sanitizer Vanilla Vibes",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "On The Go Hand Sanitizer White Jasmine",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "On The Spot Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "On-The-Go Underarm Antiperspirant Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "On-The-Spot Hand Sanitizer Original", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "On-The-Spot Scented Hand Sanitizer Citrus",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "On-The-Spot Scented Hand Sanitizer Lavender",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "On-The-Spot Scented Hand Sanitizer White Tea",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Oncaspar", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Once Daily Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Oncoderm Itchrx", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oncoderm Rx Mineral Sunscreen Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ondansetron",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ondansetron Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ondansetron Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "One And Done", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "One Complete Microbarrier", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "One Dose Pharm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "One Drop Care Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "One For All Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "One Step Antiseptic Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "One Step Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "One Touch Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "One-Step Wart Remover Clear", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oneka Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Onelax", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Oneseed Juniper Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Onetooth Clinic Tooth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Onetoothclinic Tooth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Onexton", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Onfi",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ongentys", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Onglyza", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ongo - Fin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Onhand Antibacterial Hand Sanitizing", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Onid Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Onion",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Onivyde", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Onko Health", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Only For - Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Only For Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Only For Body Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Only For Fever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Only For First Aid Medicine", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Only For Headache", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Onlycal", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Onopordon Aurum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Onopordon Aurum Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Onopordon Comp.",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Onosmodium Virginianum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Onpattro", DoseTypes: [{ DoseType: "Injection, Lipid Complex", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Onsaemiro Damda", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ontruzant",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Onureg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Onycho-Med", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Onyx 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Onyx Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Onzetra Xsail", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Oomph Antiseptic Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Oophorinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Op 6 Menthol Cooling Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Op 6 Menthol Warming Roll On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Op First Aid Series Alcohol Prep Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Op First Aid Series Antiseptic Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Op First Aid Series Insect Sting Relief Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Op First Aid Series Povidone Iodine Prep Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Op-P", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Op6 Menthol Cooling Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Op6 Menthol Warming Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Opalescence Sensitivity Relief Whitening", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Opalescence Whitening", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Opaline Antibacterial And Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Opaline Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Opcicon One-Step", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Opcon-A", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Opdivo", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Opdualag", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ope Elite Brands Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Open Formula Spf 33 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Open Nature Children Spf 50 Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Open Nature Herbal Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Open Nature Spf 50 Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Open Nature Zinc Oxide Sunscreen Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Opium Tincture Deodorized",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tincture", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ops 70 Percent Alcohol Foaming Skin Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ops Ab Antibacterial Foaming", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ops Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ops Ranch Hand Antibacterial Foaming", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Opsin I", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Opsin Ii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Opsonat", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Opsumit", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Optase",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Spray", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Opti-Nail 2-In-1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Optic Splash", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Opticview", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Optimal Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Optimel Manuka Dry Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Optimel Manuka Forte Eye Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Optimize Thick Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Optimv Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Option 2", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Optique 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Optiray", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intra-Arterial; Intravenous" }] }] },
    { Medication: "Optisafe Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Optison", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Opzelura", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orabloc", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Submucosal" }] }] },
    { Medication: "Oracea", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oracit", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Orajel",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Strip", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Orajel 3X Medicated For All Mouth Sores", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel 3X Medicated For Denture Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel 3X Medicated For Toothache And Gum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel 4X Medicated For Toothache And Gum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel 4X Medicated For Toothache And Gum, Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel 4X Medicated Pm For Toothache And Gum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel Antiseptic Alcohol-Free Mouth Sore Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orajel Antiseptic Rinse For All Mouth Sores", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orajel Cold Sore Moisturelock", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel Cold Sore Touch Free - Single Dose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel Fluoride", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Orajel For Cold Sores", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel For Cold Sores Double Medicated, Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel Gum Pain 3X Medicated", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel Instant Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel Jojo Siwa", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Orajel Kidstoothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Orajel Medicated For Toothache And Gum, Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orajel Paw Patrol", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Orajel Toothache Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orak-100", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Oral Antivavity", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral B Cavity Protection", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral B Gum Detoxify", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral B Minute-Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral B Mouth Sore", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral B Neutra Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral B Neutracare", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral B Plaque Defense", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral Care Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral Care Travel Kit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Oral Care-Gingivitis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral Fusion Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oral Health", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral Ivy Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral; Oral" }] }] },
    { Medication: "Oral Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }, { Route: "Topical" }] }] },
    { Medication: "Oral Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oral Saline Laxative Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral Saline Laxative Lemon Ginger", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral Sore Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oral7 Dry Mouth Moisturizing", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Oralabs Chapice Cherry Petrolatum", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oralabs Chapice Original Petrolatum", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Oralair",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Oraline-Secure", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oralline", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oralline Kids", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oralline Secure", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oralone", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Oralprocare Medicated Lip Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Orange",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Orange Antacid Soft Chew", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orange Antibacterial Dish And Handsoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orange Blossom Lilac And Jasmine Hand Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orange Blossom Lilac And Jasmine Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orange Blossom Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orange Creamsicle Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orange Flavor Geri-Mucil", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orange Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orange Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Orange Smooth Fiber Therapy", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orange Soda Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orange Twist Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orapred", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oraqix", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Periodontal" }] }] },
    { Medication: "Orasep", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oratox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oraverse", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Submucosal" }] }] },
    { Medication: "Oravig", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Orbactiv", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Orbel Antiseptic Hand Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Orbit Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Orbitol Alpine Fresh Fluoride Whitening Toothpaste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Orbitol Fluoride Toothpaste With Baking Soda", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Orbitol Fluoride Whitening Toothpaste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Orbitol Mint Flavored Fluoride Toothpaste", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Orchard De Flore Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Orchard Grass, Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Orchard Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orchidee Double Defense Skin Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orchidee Imperiale Brightening Exceptional Complete Care The Brightening And Perfecting Uv Protector With Sunscreen Broad Spectrum Spf 50 Pollution", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Orchitinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Oread Blue Anti-Dandruff", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oread Gold Anti-Itch Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oread Green Anti-Dandruff", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oregon Ash Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Orencia",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Orenitram", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Orfadin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Organdrainex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Organic Acid Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Organic Antiseptic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Organic Antiseptic Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Organic Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Organic Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Organic Peppermint Herb Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Organic Sulfur Suspension", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Organic Wear 100% Natural Origin Tinted Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Organic Zinclear Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Organophosphate Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Organoplex",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Orgaplus Super Care Balm For Combination And Oi Ly Skin Type", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orgaplus Ultra Pure Sun Ex", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orgovyx", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oriahnn", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Oriental Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oriental Medicine Detox", DoseTypes: [{ DoseType: "Pill", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Origanum Marjorana", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Original Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Original Extra Strong All Natural Fishermans Friend Menthol Cough Suppressant", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Original Hand Sanitizer Cedar Forest", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Fig Rhubarb", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Gel Cedar Forest", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Gel Fig Rhubarb", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Gel Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Gel Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Golden Amber", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Lavender Fields", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Mystic Oud", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Royal Oud", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Sport Pro", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Tobacco Leather", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Healing", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Original Herb Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Original Nasal Pump Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Original Night-Time", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Original Pain-A-Trate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Origins Ginzing Energy-Boosting Tinted Moisturizer Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orilissa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oriox Cool Breath Oral Rinse Mild", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Orkambi",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Orladeyo", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orlistat", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ornithogalum Umbellatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orostat 8%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    {
        Medication: "Oroxid Forte Oral Hygiene",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Oroxid Sensitiv",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Orphenadrine Citrate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Orphenadrine Citrate Aspirin Caffeine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orphenadrine Citrate, Aspirin And Caffeine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orphengesic Forte", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Orris", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Orris Root",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Orsythia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ortho Guard", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ortho Tri Cyclen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ortho-Nesic With Capsaicin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Orthocare", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Ortikos", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Os Silky Sun Cushion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oscar", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Osceola Skin Care Sani-Gel Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Osceola Supply Inc 6176", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oscillococcinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oscimin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Osds Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Osds Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oseltamavir Phosphate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oseltamivir", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oseltamivir Phosphate",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Oseltamivir Phosphate For Oral Suspension", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oseni", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oseque Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Osmitrol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Osmoflex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Osmolex Er",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Osmoprep", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osphena", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oss-Regen", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteo Bi-Flex Joint Health Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Osteobios", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteodoron Am", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteodoron Am Special Formula", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteodoron Pm", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteodoron Pm Special Formula", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteopar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteoplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteoreg", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteosis", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Osteotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Otezla",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Otiprio", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Auricular (Otic); Intratympanic" }, { Route: "Intratympanic" }] }] },
    { Medication: "Otis Clapp Mygrex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Otis Clapp Valihist", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Otitis Media Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Otix Ear Wax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Otix Ear Wax Removal System Ear Wax And Bulb", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Otoflam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Otoflam Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ototone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Otovel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Otrexup", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Ouch", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ounce By Ounce Broad Spectrum Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ourself Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Outback Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Outdoor Adventure Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Outdoor Essentials", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Outdoor Explorer Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Outer Reef Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Outer Reef Reef Safe (Wr) Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Outgro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Outpain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Outrageous Orange Anticavity - Oft51 Mrt", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Ovace",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Ovace Plus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ovaceplus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ovaplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ovaria Apis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ovaria Apis Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ovarian Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ovary Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Overnight Clearing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Overnight Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Overnight Relief Gel-Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Overnight Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Overtime", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Overtime-Hmp", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Overwhelmed", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ovide", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ovidrel", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Own Beauty - Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Oxacillin",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Oxalicum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxaliplatin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Oxalis 10", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxalis 10% Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxalis 20", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxalis 20%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxalis 3 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxalis 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxalis Belladonna", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxalis Belladonna Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxalis E Pl. Tota 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxaltem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxandrolone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxaprozin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Oxaydo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxazepam",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Oxbryta",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Oxcarbazepine",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Oxervate",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Oxicleanse", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxiconazole Nitrate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Oxistat",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Oxistom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Oxlumo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Oxtellar Xr", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxy",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Oxy 10", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy 10 Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy 3 In 1 Acne Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy 5", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Bee Pollen Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxy Cleansing Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Deep Pore Cleansing Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Face Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy On-The-Go Acne Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Overnight Acne Reducing Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Rapid Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Rub Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Sensitive Face Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Volcanic Ash Acne Face Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Volcanic Ash Detox Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxy Volcanic Clay Acne Wash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxybutynin", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxybutynin Chloride",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Oxycodone", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxycodone And Acetaminophen",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Oxycodone Apap", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxycodone Hcl",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Oxycodone Hydchloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxycodone Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Oxycodone Hydrochloride And Acetaminophen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxycodone Hydrochloride Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxycodone/Apap", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxycontin", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Oxygen",
        DoseTypes: [
            { DoseType: "Gas", Routes: [{ Route: "Cutaneous; Respiratory (Inhalation)" }, { Route: "Nasal" }, { Route: "Nasal; Respiratory (Inhalation)" }, { Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    { Medication: "Oxygen Compressed", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Compressed Gas", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Compressed Un 1072 Refrigerated Un 1073", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Helium Mixture 20/80", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Helium Mixture 30/70", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Nitrogen Mixture", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Refrigerated Liquid", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size 400", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size B", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size C", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size D", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size E", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size F", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size H", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size M", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen Size T", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen, Compressed U.S.P.", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen, Refrigerated", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen/Helium 20/80", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen/Helium 25/75", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen/Helium 30/70", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen/Helium 40/60", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxygen/Helium 50/50", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Oxyhives", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oxymetazoline Hcl", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Oxymetazoline Hydrochloride",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Oxymethazoline Hcl", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Oxymorphone Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Oxyrub Max Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxyrub Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Oxyrub Pro Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Oxytocin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Oxytrol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Oxytrol For Women", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    {
        Medication: "Oyster",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Oyster Shell Calcium With Vitamin D", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Oz Naturals Antiseptic Gel Handsanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ozempic", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Oznaturals Sunscreen", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ozobax", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ozurdex", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Intravitreal" }] }] },
    { Medication: "P B Pure Antibacterial Hand Sanitizer Cucumber Melon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P B Pure Antibacterial Hand Sanitizer Ocean Breeze Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P B Pure Antibacterial Hand Sanitizer Sweet Pea Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P D All", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "P D All M", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "P D M", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "P.A.W.S. Hand Sanitizer Bottle", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V Itch Relief Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. 2 In 1 Dry Scalp Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Aloe Vera Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Baby Moisturizing Fragrance Free", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Blue Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "P.O.V. Classic 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Classic Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Dry Scalp Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Everyday Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Green Apple 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Light Moisturizing Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Spring Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "P.O.V. Springtime Renewal Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Sugarfree", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "P.O.V. Timeless Spice 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P.O.V. Vitamin E Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P25 Progesterone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "P3 Ai", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P3 Oa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P3 Rg", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "P50 Progesterone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "P75 Lav Progesterone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "P75 Maxx With Retinol All Natural Progesterone 75 Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Pacerone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pacific Blue Basic Gel Hand Sanitizer With Moisturizers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacific Blue Select Foam Hand Sanitizer, Fragrance Free, Dye Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacific Blue Select Gel Hand Sanitizer Fragrance And Dye Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacific Blue Ultra", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacific Blue Ultra Antimicrobial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacific Blue Ultra Foaming", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacific Garden Antimicrobial Foam Fragrance Free, Dye Free, Triclosan-Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacific Garden Antimicrobial Foam Pacific Citrus, Mandarin, Triclosan-Free", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacific Garden E3 Rated Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacifica Acne Warrior Body Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacifica Acne Warrior Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacifica Cc Broad Spectrum Spf 17 Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacifica Cc Broad Spectrum Spf 17 Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pacifica Ultra Cc Radiant Foundation Broad Spectrum Spf 17", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Paclitaxel",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Paclitaxel Protein-Bound Particles For Injectable Suspension (Albumin-Bound)", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Pacreaplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Padcev", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Paecilomyces Variotii", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Paeonia Officinalis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Paeonia Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Pahaba Control", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pahaba Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pain Aid Esf", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Aid Pmf", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain And Fatigue Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain And Fever",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pain And Fever Relief",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain And Inflammation Relief Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain And Itch Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pain And Sinus Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Away Plus Sleep Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Away Solution", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Bust R Ii", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Crush", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Ease", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Formula Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Formula I Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Formula Ii Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Formula Iii Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Formula Iv Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Free", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Free Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain No More Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Plus Sleep", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain Release",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Pain Relief",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Capsule, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Salve", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Relief - Ibuprofen 200 Mg", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Relief 4% Lidocaine Dry Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief 8 Hr", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Relief Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain Relief Acetaminophen Pm",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Relief Arnica And Hemp", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain Relief Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Relief Back And Body", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain Relief Balm",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pain Relief Body", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain Relief Caffeine And Hemp",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Relief Caffeine And Snake Venom", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Relief Cold Therapy Gel 3 Fl Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain Relief Extra Strength",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Relief Fever Reducer", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Gel-Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Hot Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain Relief Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Relief Lidocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Liquid Roller", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Max Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Medicated", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Menthol And Hemp", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Menthol And Snake Venom", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain Relief Patches",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pain Relief Patchs", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Plus Multi Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Relief Plus Sleep Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain Relief Pm",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Relief Rapid Release Gelcaps", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Relief Regular Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain Relief Roll On",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Pain Relief Roll-On",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Pain Relief Snake Venom And Hemp",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Relief Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relief Vegan", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain Reliever",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Reliever / Fever Reducer", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Reliever 2", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Reliever And Fever Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Reliever And Sleep Aid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain Reliever Fever Reducer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    {
        Medication: "Pain Reliever Plus",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pain Reliever Pm",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pain Reliever Rapid Release", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain Relieving",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liniment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Salve", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pain Relieving Burn Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relieving Cleansing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relieving Cold Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relieving Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relieving Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relieving Gel-Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relieving Lidocaine Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relieving Liniment", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Relieving Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pain Relieving Roll-On",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pain Relieving Warming Balm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain Stoppers", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Stoppers Ex", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Suppress For Men", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Suppress For Women", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Terminator", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pain Terminator 2 Severe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pain Terminator Analgesic",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pain Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pain-X", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painazol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Painbloc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painbloc24", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painbloc24 Flexi-Stretch Pain Tape", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painbloc24 Prowomen Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painbloc24 Prowomen Womens Discomforts Menthol Pain And Sleep Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painfix Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painfix Restore", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painful Cough Colic", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Painful Menstruation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Painful Urination", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Paingone Freeze 5", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painkoolers", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painonil", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painpatrol Cbd Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painpatrol Cbd Pain Relief With Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painpatrol Cbd Professional Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painpatrol Professional Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painshield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painzaway Pain Relieving Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Painzone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Palagrip", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Palatos Expectorante", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Palforzia",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Paliperidone",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pall Sterile Cord Blood Collection Unit", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Palladium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Palm", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Palm Shield Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Palm-Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Palma Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Palmero Healthcare Discide Xra Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Palmero Healthcare Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Palmers Amaranth", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Palmers Skin Success", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Palo Verde Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Palonosetron",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Palonosetron Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Palynziq", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Pamana Jack", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pamela Metamorphosis Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pamelor", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pamidronate Disodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Pamprin Max Menstrual Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pamprin Multi-Symptom", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pan-Zyme-S", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Panadol",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Panama Jack",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pancreaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pancreapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pancreas Argentum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pancreas Db Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pancreas Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pancreas Equisetum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pancreas Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Pancreas Meteoric Iron", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pancreas Meteoric Iron Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pancreas/Stomach Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pancreatinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pancreaze", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pancuronium Bromide", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Pandanus Calc. Carb", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pandanus Calc. Carb.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pandel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panhematin", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Panic Button",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Panic Ease", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Panoxyl", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panoxyl Oil Control Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panretin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Alcohol Free Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Alcohol-Free Hand Sanitizer With Refreshing Aloe Dual Action Moisturizers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Antibacterial Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Antibacterial Hand Green Apple", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Antibacterial Hand Green Milk And Honey", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Antibacterial Hand Rose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Fresh Clean Pyrithione Zinc Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Instant Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Instant Hand Sanitizer Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Panrosa Instant Hand Sanitizer, Aloe And Moisturizers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pantin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pantoprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pantoprazole Sodium",
        DoseTypes: [
            { DoseType: "Granule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pantoprazole Sodium D/R", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Panzyga", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Papaverine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }, { Route: "Parenteral" }] },
        ],
    },
    { Medication: "Paper Mulberry Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Papi Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Papilion Hygienic Alcohol Wipes Antibacterial", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Papilion Hygienic Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Papilion Hygienic Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Papilion Hygienic Disinfectant Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Papis Creams Psoriasis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Para Solve", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Para-Bn", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Para-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Paraben Isode Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parachute Hand Protect Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paragard T 380A", DoseTypes: [{ DoseType: "Intrauterine Device", Routes: [{ Route: "Intrauterine" }] }] },
    { Medication: "Paragon Hand Sanitizer 75%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paragon Sanitizing Hand Gel 75%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Parakeet Feathers", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Paraplatin", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Parasite Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parasite Infection", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parasite Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Parasites",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Parasites/Amoeba/Protozoan Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parasitexx", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Parasitol",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Parasol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Parasol Scent-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Parastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parathyreoidea Aurum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parathyroid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parathyroid Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parathyroid Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parathyroidinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parathyropar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parathyroplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parche De Leon", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Parche Leon", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pareira Brava", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Paremyd", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Parents Choice Advanced Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Parents Choice Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Parents Choice Infants Ibuprofen", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parents Choice Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Paricalcitol",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Paris Quadrifolia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parkers Sani Spritz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Parkers Sani Spritz Ii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Parlodel",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Parma Anus Skin Premium Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Parnate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Parodontax",
        DoseTypes: [
            { DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] },
            { DoseType: "Paste", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Paroex", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Paromomycin Sulfate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Paroxetine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Paroxetine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Parsabiv", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Party Detox", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Party Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Paser", DoseTypes: [{ DoseType: "Granule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pasion Alcohol Wet", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pasion Alcohol Wet Wipes",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pasion Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pasion Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Paspalum Notatum Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Passiflora Crataegus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Passiflora Ex Herba 1 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Passiflora Incarnata",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Passiflora Incarnata (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Passiflora Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Passion And Beyond Deep Moisture", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Passion And Beyond Verbena", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pataday Once Daily Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Pataday Twice A Day Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Patanase", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Patchology Breakout Box 3-In-1 Acne Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Patchology The Blemish Buster Breakout Box 3-In-1 Acne Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Patterson Antimicrobial Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Patterson Dental Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Paulas Choice Calm Redness Relief Spf 30 Mineral (Normal To Oily)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Calm Redness Relief Spf 30 Mineral Moisturizer (Normal To Dry)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Clear Anti Redness Exfoliating Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Clear Body Acne", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Clear Daily Skin Clearing Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Clear Ultra-Light Daily Hydrating Fluid Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Defense Essential Glow Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Extra Care Non-Greasy Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Hydralight Shine Free Daily Mineral Complex", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Hydralight Shine-Free Daily Mineral Complex Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Lipscreen Spf 50", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Moisture Boost Daily Restoring Complex", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice On-The-Go Shielding Powder Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Pc4Men Daytime Protect", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Resist Skin Restoring Moisturizer Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Paulas Choice Resist Smoothing Primer Serum Spf 30", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Resist Super-Light Daily Wrinkle Defense Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Resist Youth Extending Daily Hydrating Fluid Spf 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Skin Recovery Daily Moisturizing Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paulas Choice Ultra Sheer Daily Defense Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paume Moisturizing Antibacterial Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paw Patrol Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paw Patrol Blue Raspberry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Paw Patrol Fresh Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Paw Patrol Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Paws", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Paxil",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Paxlovid", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pazeo", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Pc Hansan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pca Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pca Skin Acne",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pca Skin Body Hydrator", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pca Skin Bpo Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pca Skin Intensive Clarity Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pcxx Anticaries No Flavor Ne G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Apf Rns Bubblegum", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Apf Rns Grape", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Apf Rns Mint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Apf Rns Strawberry", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Banana Berry Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Bubblegum Apf F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pcxx Bubblegum Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Bubblegum Neutral F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Bugglegum Neutral Gl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pcxx Cappuccino Apf F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Cherry Apf F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Cherry Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Cool Peppermint Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Creme De Menthe Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Creme De Menthe Neutral Gl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Grape Apf F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pcxx Grape Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Lime Apf F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Marshmallow Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Mocha Cappuccino Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Mocha Cappuccino Neutral Gl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Neutral Rms Bubblegum", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pcxx Neutral Rns Grape", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pcxx Neutral Rns Peppermint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Neutral Rns Watermelon", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pcxx No Flavor Neutral F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Peppermint Apf F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Peppermint Neutral F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Peppermint Neutral Gl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Pina Colada Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Raspberry Apf F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Raspberry Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Raspberry Neutral Gl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Rootbeer Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Snf Rns Bubblegum", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Snf Rns Grape", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Snf Rns Mint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Snf Rns Strawberry", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Strawberry Apf F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Strawberry Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Strawberry Neutral F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Vanilla Orange Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Vanilla Orange Neutral F", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Vanilla Orange Neutral Gl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Vanilla Orange One Minute Fm", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pcxx Watermelon Apf G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pcxx Watermelon Neutral Gl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Pdi Alcohol Prep Pad Sterile", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pdi Duo-Swab Povidone-Iodine", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pdi Sani Hands", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pdi Sani-Hands", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pe Sinus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peace Out Acne", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peace Out Acne Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peacefuli", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Peach",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Peach And Lily Peaches Slices Acne Clarifying Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peach And Lily Peaches Slices Acne Exfoliating Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peach And Lily Peaches Slices Acne Oil Acne Free Moisturizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peach Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peach Tanning Oil Spf6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peak Sunscreen 100 Natural Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peannuts Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Peanut",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Peanuts Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pear",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Pearl White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Pearlescent", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pearls Antiseptic Wet Wipes Lavender", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pearls Antiseptic Wet Wipes Lemon", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pearls Antiseptic Wet Wipes Pine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pearls Antiseptic Wet Wipes Rose", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pearson Antimicrobial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pearsys",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pecan Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pecan Nut", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    {
        Medication: "Pecan Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Pecgen Dmx", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pecgen Pse Cough Suppressant Expectorant Nasal Decongestant Grape Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pedatosol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pedatosol Oattbe Syn Bio Beta Glucans Derma Blemish Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pedi-Derm Bum", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pedi-Quick One Step Callus Removers", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pedi-Quick Onestep Corn Removers", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pedia-Lax",
        DoseTypes: [
            { DoseType: "Enema", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pediacare Childrens Cough And Congestion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pediacare Childrens Fever Reducer Pain Reliever Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pediacare Multi Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pediacare Simethicone Antigas", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pediacare-Cough-Runnynose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pediadermics Eczema 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pediapred", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pediarix", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Pediatric", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Pediatric 8.4% Sodium Bicarbonate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Pediatric Cough And Cold Medicine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pediatric Earache Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    {
        Medication: "Pediatric Infuvite Multiple Vitamins",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Pediatric Infuvite Multiple Vitamins For Infusion", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Pediquick Onestep Wart Removers", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pedizolpak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pedoxan Extract", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pedvaxhib", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Peeps Cotton Candy Marshmallow Screme Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peeps Vanilla Marshmallow Screme Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peg-3350 And Electrolytes", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peg-3350, Electrolytes, And Ascorbate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Peg-3350, Sodium Chloride, Sodium Bicarbonate And Potassium Chloride", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peg-3350, Sodium Sulfate, Sodium Chloride, Potassium Chloride, Sodium Ascorbate And Ascorbic Acid", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Peganone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pegasys", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Pei Fan Instant Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pelo Baum Brow Brow", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pelo Baum Hair Revitalizing",
        DoseTypes: [
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pelo Baum Hair Revitalizing Conditioner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pelo Baum Lash Lash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pemazyre", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pemetrexed",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Pemetrexed Disodium", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Pemf Optimizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pemfexy", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Penacom Hypochlorous Acid Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Penetrating Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Penetrex(R) Arthritis Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Penicillamine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Penicillin G Potassium",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Penicillin G Procaine", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Penicillin G Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Penicillin V Potasium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Oropharyngeal" }] }] },
    {
        Medication: "Penicillin V Potassium",
        DoseTypes: [
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Penicillium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Penicillium Chrysogenum (Notatum)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Penicillium Chrysogenum Var Chrysogenum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Penicillium Digitatum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Penicillium Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Penicillium Notatum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Pennsaicin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pennsaid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Penorra Prep Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Penorra Sanitary Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pentacel", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pentam 300", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Pentamidine Isethionate",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Pentasa", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pentazocine And Naloxone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pentazocine Hydrochloride And Naloxone Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pentetate Calcium Trisodium", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous; Respiratory (Inhalation)" }] }] },
    { Medication: "Pentetate Zinc Trisodium", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous; Respiratory (Inhalation)" }] }] },
    {
        Medication: "Pentobarbital Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Pentoxifylline", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pentrexcilina", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pentrexcilina Night Time", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "People4Ocean Spf30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "People4Ocean Spf50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "People4Ocean Spf50 Sunscreen Vegan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pep Tum", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pep-Star Daily Uv Protector Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pepaxto", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Pepcid",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pepcid Ac", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pepcid Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peppa Pig Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pepper Tree Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Pepperdine Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pepperment Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peppermint Charcoal Anticavity Pcft6", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Peppermint Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peppermint Cream Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peppermint Fruit Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peppermint Hand Sanitizer Boy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peppermint Hand Sanitizer Girl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peppermint Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peppermint Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peppermint Spf 30 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Peppermint Twist", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peppermint Twist Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pepplus Lifting Essence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pepplus Special Care", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pepplus Special Skin Care Lifting Program", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pepplus Wrinkle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pepplus Wrinkle Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pepsodent Complete Care", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Pept Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Peptic Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Peptic Relief Cherry", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pepto",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pepto Bismol",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pepto Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pepto-Bismol",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Peptostrep Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peraflu D", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perbelle Cc Spf43 Uva Uvb Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Percara Kids Bubble Gum", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Percara Oral Health Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Percocet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Percogesic", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Percogesic Extra Strength", DoseTypes: [{ DoseType: "Capsule, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Percogesic Original Strength", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Percy Medicine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Perdiem", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Perfect Body - Weightless Moisturizer Broad Spectrum Spf 25 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Body - Youth Boost Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Busty Booster", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Butt Booster", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Care", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Care Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Clean Intensive Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Clean Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Hand Cleaner", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Legs Skin Protector Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Purity Hotice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Purity Medicated Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Purity Oil Free Acne Wash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Purity Vaporizing Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Shield Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Sleep", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Perfect Sunscreen Spf 50 Sheer Mineral", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfect Sunscreen Tinted Mineral Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfectheal Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfectif Even Skin Tone Cream Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Alabaster", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Buff", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Deep Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Deep Bronze", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Earthy Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Espresso", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Fair", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Golden Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Golden Bronze", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Honey Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Neutral Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Porcelain", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Rosy Beige", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Soft Blush", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfecting Liquid Foundation Toffee Bronze", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfectint Powder Spf 40", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfectionist Pro Multi-Defense Uv Fluid Broad Sprectrum Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfectly Posh Block It Like Its Hot Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perform", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perform Atomic Heat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perform Cool And Soothing Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perform Cool And Soothing Pain Relieving Foot", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perform Cool And Soothing Pain Relieving Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Performance Brands Steel Releaf", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Performance Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perforomist", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Perfume On Hand Sanitizer Gel Babypowder", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfume On Hand Sanitizer Gel Pepermint Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perfumox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pericardium Triple Warmer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pericardium/Triple Warmer Meridian Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peridex", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peridot", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Periguard", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perikabiven", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Perindopril Erbumine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Perio Daily Defense", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Periochip", DoseTypes: [{ DoseType: "Insert, Extended Release", Routes: [{ Route: "Periodontal" }] }] },
    { Medication: "Periodontal Pathogen Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Periodontium Hypophysis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Periodontium Quartz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Periodontium Quartz Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Perioe Microbiome Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Perioe Microbiome Total Care", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Perioe Microbiome Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Perioe Whitening Essentials", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Periogard (Chlorhexidine Gluconate)", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Periogard Alcohol Free", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Periogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Periomed", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Periorestore", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Periosciences Hydrating Ao Pro", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Periosciences Sensitive Ao Pro", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Periosciences White Care Ao Pro", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Periotherapy Healthy Gums", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Periplaneta Americana", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Perisol Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perjeta", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Perk Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perk Hand Sanitizer Moisturizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Permethrin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pernod Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perox-A-Mint Solution", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Peroxide Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peroxie Care Tartar Control", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Peroxy Shield Mouth Sore", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Peroxyl Mouth Sore Mild Mint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Buccal" }] }] },
    {
        Medication: "Perphenazine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Perphenazine And Amitriptyline Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Perricone Acne Relief Calming And Soothing Clay Mask", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Acne Relief Gentle Exfoliating Toner", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Md No Make Up Foundation Serum Spf 20 - Beige", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Md No Make Up Foundation Serum Spf 20 - Buff", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Md No Make Up Foundation Serum Spf 20 - Golden", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Md No Make Up Foundation Serum Spf 20 - Ivory", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Md No Make Up Foundation Serum Spf 20 - Nude", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Md No Make Up Foundation Serum Spf 20 - Porcelain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Md No Make Up Foundation Serum Spf 20 - Rich", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perricone Md No Make Up Foundation Serum Spf 20 - Tan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Perrigo Allergy D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Persani Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Persani Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Perseris", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Personal Care",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Personal Care Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personal Care Pyrithione Zinc Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personal Care Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personal Cleansing Cloths With Dimethicone", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personal Cleansing With Dimethicone", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personal Defense Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Personal Lubricant",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Salve", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Personal Performance Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personal Protection Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Personal Skincare Barrier Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personality Disorders Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Personalized Day Moisturizer With Spf", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personalized Day Moisturizer With Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Personalized Day Moisturizer With Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pert Plus 2 In 1", DoseTypes: [{ DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pertussinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pertussinum (Nosode)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pertussis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pertzye", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pest And Dustmite Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Petasites E Rad. 1 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Petasites Plantago", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Petasites Quercus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Petasites Veronica", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pete And Pedro", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pete And Pedro Sanitize", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petit Cochon Tone Up Pre Shower", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petra Hand Sanitizer 75 Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petra Health E Wipes Alcohol Free Antiseptic", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petrochemical Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Petrocort Antipruritic Lip 21", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Petrolatum",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }, { Route: "Topical; Topical" }] },
        ],
    },
    { Medication: "Petrolatum Jelly With Cocoa Butter", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Petroleum",
        DoseTypes: [
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Petroleum Jelly",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Petroleum Jelly Baby Fragrance", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petroleum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Petroleum Original", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petroleum Skin Protectant", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petroleum With Cocoa", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petroleum-Free Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Petroselinum Sativum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pets", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Petsilver Wound Gel With Hemp Oil", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pexeva", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pfa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfa Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pfa For Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pfa Stronghold Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfa Stronghold Sanitizing Handlotion Mint", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfa Stronghold Sanitizing Handlotion Rose", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfa Stronghold Sanitizing Handlotion Shea Butter", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfizer-Biontech Covid-19 Vaccine", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Pfizerpen", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Intramuscular; Intrapleural; Intrathecal; Intravenous" }, { Route: "Intravenous" }] }] },
    { Medication: "Pfp Polyclean Hand Sanitizer - Gel 62", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfp Polyclean Hand Sanitizer - Gel 65", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfp Polyclean Hand Sanitizer - Gel 68", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfp Polyclean Hand Sanitizer - Gel 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfp Polyclean Hand Sanitizer - Gel 70 Ipa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfp Polyclean Hand Sanitizer - Gel 72", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfp Polyclean Hand Sanitizer - Gel 80", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfp Polyclean Hand Sanitizer - Liq 80", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pfp Polycleane Hand Sanitizer - Gel 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ph.Hubby 1G Sun Cream Intensive Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ph.Hubby Beige Bb Tone Up Sun", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ph.Hubby Pink Bb Tone Up Sun", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phace Bioactive Clarifying Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phace Bioactive Soothing Day Plus Primer Broad-Spectrum Spf 46", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharbechlor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pharbedryl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pharbest Aspirin 325Mg", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pharbest Regular Strength Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pharbetol",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pharbinex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pharbinex-Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pharbinex-Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pharma C Wipes 70 Isopropyl Alcohol",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pharma C Wipes 70 Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma C Wipes Hemorrhoidal", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma C Wipes Medicated Foam With Witch Hazel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma Care Ipa Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma Cleanse Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma Clear Acne Treatment", DoseTypes: [{ DoseType: "Concentrate", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma Clear Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma Clear Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma Clear Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma-C-Wipes 3 Hydrogen Peroxide Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma-C-Wipes Toallitas De Alcohol Isopropilico 70", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharma-Care Skin Preparation Cloths", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaca Natural Mineral Sunscreen Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaca Natural Mineral Sunscreen Spf-50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacist Choice Alcohol Prep Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacist Choice Alcohol Sterile Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacy Prescription 1.8Oz Hand Sanitizer - Cucumber Melon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacy Prescription 1.8Oz Hand Sanitizer - Original Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacy Prescription 1.8Oz Hand Sanitizer - Sweet Pea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacy Prescription 1.8Oz Hand Sanitizer - Sweet Peach", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacy Prescription 70% Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacy Prescription 70% Isopropyl Alcohol With Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 1.8Oz Hand Sanitizer Cucumber Melon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 1.8Oz Hand Sanitizer Original Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 1.8Oz Hand Sanitizer Sweet Pea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 1.8Oz Hand Sanitizer Sweet Peach", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 2Pk 2Oz Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 4.5 Oz Cocoa Butter Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 4.5 Oz Creamy Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 8 Oz Acne Control", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription 8Oz Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Analgesic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Creamy Petroleum Cocoa Butter", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Creamy Petroleum Unscented", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pharmacys Prescription Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pharmacys Prescription Hand Sanitizer 2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Hand Sanitizer Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Ice Cold Analgesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Petroleum Baby Fresh Scent", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Petroleum Jelly", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Petroleum Skin Protectant", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmacys Prescription Vapor Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmapurerx Lidocaine Hcl 4.12%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmapurerx Menthotral", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Acnecare Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Acnecare Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Acnecare Dermacontrol Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Acnecare Exfoliator", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Acnecare Toner", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Brightening Dermarelief Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Multi-Gly Skin Relief Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Solar-C Moisturizer Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Solar-C Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharmaskincare Solar-C Moisturizer Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pharynx 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phase 6 Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phazyme",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phazyme Ultimate", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phelityl Day Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phellandrium Aquaticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenadoz", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Phenagil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenagil Ch", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenazopyridine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenazopyridine Hcl 100Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenazopyridine Hcl 200Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phenazopyridine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phendimetrazine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phendimetrazine Tartrate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Phenelzine Sulfate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phenergan", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Phenobarbital",
        DoseTypes: [
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phenobarbital Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenobarbital Sodium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Phenobarbital With Belladonna Alkaloids", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenobarbital With Belladonna Alkaloids - Grape", DoseTypes: [{ DoseType: "Elixir", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenobarbital With Belladonna Alkaloids - Mint", DoseTypes: [{ DoseType: "Elixir", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenobarbital, Hyoscyamine Sulfate, Atropine Sulfate And Scopolamine Hydrobromide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenobarbital, Hyoscyamine Sulfate, Atropine Sulfate, Scopolamine Hydrobromide", DoseTypes: [{ DoseType: "Elixir", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phenohytro",
        DoseTypes: [
            { DoseType: "Elixir", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phenoxybenzamine Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phentermine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phentermine Hcl",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phentermine Hcl C-Iv", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phentermine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Phentolamine Mesylate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Phenylalanine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenylalanine Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenylephrine Hci", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Phenylephrine Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phenylephrine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phenylisothiocyanate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenylisothiocyanate Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenylpropanolamine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phenytek", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phenytoin",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phenytoin Infatabs", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phenytoin Sodium",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Phesgo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Phexxi", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Philith", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phillips Fresh Mint Milk Of Magnesia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phillips Milk Of Magnesia Concentrated", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phillips Milk Of Magnesia Wild Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phillips Original Milk Of Magnesia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phillips Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Philosophy Clear Days Ahead Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phisoderm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phisoderm Anti Blemish Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phisoderm Anti-Blemish Gel Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phisoderm Clear Confidence Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phisoderm Clear Confidence Daily Gel Face Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phisoderm Clear Confidence Face Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phisoderm Clear Confidence Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phloridzin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phloridzin Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phmb Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phoemay", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phoenix Kineticream Anti-Inflammatory And Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phoma", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Phoma Betae", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Phoma Exigua Var Exigua", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Phoslo", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phoslyra", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phosphasal", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phospholine Iodide", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Ophthalmic" }] }] },
    {
        Medication: "Phosphoricum Acidum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phosphoricum Acidum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phosphorus",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Phosphorus 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phosphorus 30 Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phosphorus 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phosphorus 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Phosphorus 8",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phosphorus Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phosphorus Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phosphorus Tartarus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Photo Regul Cream Unifying Protective Face Care Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Photo Regul Face Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Photo-Age Sunscreen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Photoblock",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Photoblock Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Photocil", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Photofrin", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Photrexa", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Photrexa Cross-Linking Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Photrexa Viscous", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Phoxillum", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Phuel",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Phycomycetes Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Phyenlimcide Toothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physical Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Physician Ez Use Joint Tunnel And Trigger Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Physician Ez Use Joint Tunnel And Trigger Kit Ii", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Physician Force Maximum Strength Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Physicians Care Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Physicians Care Cold Cough", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physicians Care Cough And Sore Throat", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physicians Care Extra Strength Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Physicians Care Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Physicians Ez Use B-12 Compliance", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Subcutaneous; Topical" }] }] },
    { Medication: "Physicians Ez Use M-Pred", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intramuscular; Intrasynovial; Soft Tissue; Topical" }] }] },
    {
        Medication: "Physicians Group Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Physicians Group Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Physicianscare Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physicianscare Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physicianscare Dimenhydrinate", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physicianscare Extra Stregth Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physicianscare Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Physicianscare Eyewash Station Additive Concentrate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Physicianscare Motion Sickness", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physicianscare Non Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physicianscare Ophthalmic Solution Eyewash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Physicianscare Sinus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physiolyte", DoseTypes: [{ DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] }] },
    { Medication: "Physiosol", DoseTypes: [{ DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] }] },
    { Medication: "Physostigma Venenosum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Physostigmine Salicylate", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Phytogel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Phytolacca Decandra",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Phytolacca Decandra 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Phytolacca E Rad 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Phytolacca Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Phytonadione",
        DoseTypes: [
            { DoseType: "Injection, Emulsion", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Parenteral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Phytoncide Essential", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phytoncide Su", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phytoplex Clear Aid", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Phytozine Ringworm Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pi Yen Chin", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Pibubab Gold Grain Pack", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pibubab Grain Total Wrinkle Brake", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pibubab Prestige Grain Ampule", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pic N Shovel Antiseptic Hand And Body Wash 6875", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Picnic Allergy Symptom Reliever Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Picnic Antihistamine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Picnic Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Picnic Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Picnic Nasal Decongestant And Antihistamine", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pico Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Picricum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Picture Perfect Day Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pifeltro", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pigweed Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pilinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pilocarpine Hydrchloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pilocarpine Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pilocarpus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pilot Alcohol Antiseptic 80% Topical Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pimecrolimus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pimozide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pimplepatch By Glam Up", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pimtrea", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pina Colada Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pinaway", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pinceladas Para Hongos Navarro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pinchot Juniper Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pindolol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pine And Honey Scent Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pine Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pine Forest Scented Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pine Phytoncide Antiseptic Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pine Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pineal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pineal Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pineal/Pituitary/Hypothalamus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pineapple",
        DoseTypes: [
            { DoseType: "Aerosol", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Pineapple Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pineapple Hand Sanitizer 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pineapple Mineral Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pineapple Tanning Oil Spf15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pineapple Tanning Oil Spf6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pinene", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pink Bismuth", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pink Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Pink Eye Nighttime", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Pink Eye Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Pink Eye Remedy", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Pink Grapefruit Acne Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pink Satin Lace", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pink-Bismuth", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pinkeye Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pinpoint Lymphatics Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravascular; Intravenous" }] }] },
    { Medication: "Pinrid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pinus Echinata Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Pinus Strobus Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Pioglitazole And Metformin Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pioglitazone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pioglitazone And Glimepiride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pioglitazone And Metformin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pioglitazone Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pioglitazone Hydrochloride And Glimepiride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pioglitazone Hydrochloride And Metformin Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pioglitazonehydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pioneer Eclipse Touche Foaming Antibacterial Green", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pioneer Eclipse Touche Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pioneer Eclipse Touche Foaming Non-Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pip", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Piper Methysticum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Piper Nigrum E Fruct. 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Piperacillin And Tazobactam",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }, { Route: "Intravenous; Parenteral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }, { Route: "Intravenous; Parenteral" }] },
        ],
    },
    { Medication: "Piperacillin Sodium And Tazobactam Sodium", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Piperacillin, Tazobactam", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Piperine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Piperwaihandsanitizerlemongrass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Piperwaihandsanitizerminty", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pipette Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pipette Eczema", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pipette Mineral Sunscreen Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pipette Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Piqray",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pirfenidone",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pirmella 1/35", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pirmella 7/7/7", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pirnuo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Piroxicam", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pistachio Nut", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Pit Boss Antiperspirant And Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pitocin", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Pituistat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pituitarum Posterium", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pituitary 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pituitary Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pituitary Forte", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pituitary Gland", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pituitary Homeopathic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pituitary Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pituitary/Pancreas/Kidney Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Piyanping Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pizensy", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pk 99 Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Placenta (Bovis) 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plak Smacker", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Plan B One-Step", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plane It Safe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Planeaire Hands", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Planeaire Hands Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Planeairehands Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Planet Luxe Hand Sanitizer Cedarwood, Ylang Ylang And Clary Sage", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Planet Luxe Hand Sanitizer Lavender, Bergamot And Geranium", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plant 2 Product Allevatio", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plant And Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plant Confldence Comfortable Cleansing Bubble Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plant Herb Based Bacteriostatic Feminine Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plantago E Fol. 4 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plantago Lanceolata Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Plantago Major", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plantago Primula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plantago Primula Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Plantago Pyrite",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Plantain Beeswax Cough Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plantain Beeswax Mild", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plantain Sorrel Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Plantain Spruce Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plantar Wart Removers", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Planticin Advanced Topical Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plantogen Protective Moisturizer Broad Spectrum Spf", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plantscription Spf 25 Power Anti Aging Broad Spectrum 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plantscription Spf 25 Power Anti-Aging Oil Free Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plaque Hd Anticavity Berry Bubble Gum Flavor", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Plaque Hd Anticavity Fresh Mint Flavor", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Plaque Refresh Remineralizing Rinse Fresh Mint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plaquenil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plasbumin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Plasma-Lyte 148", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Plasma-Lyte A", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Plasmanate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Plastics/Bpa Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Platanus Occidentalis Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Platinum Cichorium",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Platinum Hand Care Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Platinum Hand Care Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Platinum Hand Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Platinum Metallicum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Plavix", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Play Antioxidant Body Mist Spf 50 With Vitamin C", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Antioxidant Body Mist With Vitamin C Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Body Mousse With Blue Sea Kale Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Everyday Sunscreen Spf 50 With Sunflower Extract", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Lip Balm Broad Spectrum Spf 30 With Mint", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Lip Balm With Acai Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Mineral Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Mineral Sunstick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Spf 30 Lip Shield Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Play Spf 30 Lip Shield Strawberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Playmakar Roll On Cool Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pleasure Balm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plegisol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intra-Arterial" }] }] },
    {
        Medication: "Plegridy",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }, { Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Plenamine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Plenvu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Plessentiel Natural Glow Foundation 16H Wear With Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pleurisy Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plevent", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plexaderm Dark Spot Fade Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Plexion",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Plexion Ns", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plexus Coeliacus 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pliaglis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plius Daily Spf Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Plum Smooth Plumb Numb Gelee", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plumbum 12", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plumbum 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum 8X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum Mel.", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum Mellitum 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum Mellitum 14", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum Mellitum 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum Mellitum 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum Met", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Plumbum Met. 20 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumbum Met. 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Plumbum Metallicum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Plumbum Silicicum 20 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plumeria Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Plumscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Plus Pharma",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Plus Pharma Senna", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plus Plus White Smokers Whitening", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Plus Rinse Emergency", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Plus Rinse Emergency Eyewash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Plus White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plus White Coffee", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plus White Coffee Drinkers Whitening", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Plus White Extra Whitening",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Plus White Smokers Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Plus White With Peroxide", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Plus White Xtra", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Plus White Xtra Whitening", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Pluscbd Pain Cream (Arthritis) 2Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pluscbd Pain Cream (Muscle) 4Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pluscbd Pain Cream (Penetrating) 2Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pluscbd Sport Recovery Stick 1Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pluspharma", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pluspharma Extra Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pluspharma Extra Strength Pain Reliever,Fever Reducer 500 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pluvicto", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Pms",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pms Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pms Irritability", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pms Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pms Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pnd Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pneumo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumo Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumonia Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumonia/Meningitis Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumopar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumotone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pneumovax 23", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Pnv-Dha", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pnv-Omega", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pnv-Select", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Po Sum On Healing Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Po Sum On Medicated", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poa Annua Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Pocket Farmacy", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pocket Size Alcohol Formula Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pocket.Watch Ryans World Fresh Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Podocon 25", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Podofilox", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Podophyllum", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Podophyllum Pelt Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Podophyllum Peltatum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Point Of Care Kit Km", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Infiltration; Soft Tissue; Topical" }] }] },
    { Medication: "Point Of Care L2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Infiltration; Soft Tissue; Topical" }] }] },
    { Medication: "Point Of Care L5", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Infiltration; Soft Tissue; Topical" }] }] },
    { Medication: "Point Of Care Lm 2.2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] }] },
    { Medication: "Point Of Care Lm 2.5", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] }] },
    { Medication: "Point Of Care Lm Dep 2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intracaudal" }] }] },
    {
        Medication: "Point Relief Cold Spot Pain Relieving",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Point Relief Cold Spot Pain Relieving Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Point Relief Lidospot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Point Relief Lidospot With Menthol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Poison Ivy",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Poison Ivy And Poison Oak Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poison Ivy Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poison Ivy Care", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poison Ivy Dermatosis Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poison Ivy Pills", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poison Ivy Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poison Ivy/Oak", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poison Oak And Ivy Relief", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poison Oak Ivy", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polaris Mint Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polen Natural After Care", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poli-A", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Polident",
        DoseTypes: [
            { DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] },
            { DoseType: "Paste", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Polivy", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Pollen Distress", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pollen Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pollen-Weeds",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pollens - Grasses, Bahia Grass Paspalum Notatum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Grasses, Brome, Smooth Bromus Inermis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Grasses, Corn, Cultivated Zea Mays", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Grasses, Grass Mix 8", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Grasses, Johnson Grass Sorghum Halepense", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Grasses, Oats, Common, Cultivated Avena Sativa", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Grasses, Southern Grass Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Acacia Acacia Longifolia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Alder, Red Alnus Rubra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Ash, White Fraxinus Americana", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Beech, American Fagus Grandifolia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Birch Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Bottle Brush Callistemon Spp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Bottlebrush, Callistemon Spp.", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Pollens - Trees, Boxelder/Maple Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Cedar, Mountain Juniperus Ashei", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Cedar, Red Juniperus Virginiana", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Cottonwood, Common Populus Deltoides", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Cypress, Arizona Cupressus Arizonica", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Cypress, Bald Taxodium Distichum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Elm, American Ulmus Americana", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Elm, Chinese Ulmus Parvifolia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Eucalyptus, Eucalyptus Globulus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Gum, Sweet Liquidambar Styraciflua", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Hackberry Celtis Occidentalis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Hickory, Shagbark Carya Ovata", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Linden Basswood Tilia Americana", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Maple, Hard Acer Saccharum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Melaleuca Punk Tree Melaleuca Quinquenervia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Mesquite, Prosopis Juliflora", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Mulberry Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Oak Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Oak, Red Quercus Rubra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Olive Olea Europaea", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Olive, Russian Elaeagnus Angustifolia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Pollens - Trees, Palm, Queen Cocos Plumosa", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Palo Verde Cercidium Floridum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Pecan Carya Carya Illinoensis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Pepper Tree, California Schinus Molle", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Pine Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Privet Ligustrum Vulgare", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Russian Olive Elaeagnus Angustifolia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Sycamore, American Eastern Platanus Occidentallis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Tree Mix 11", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Tree Mix 5", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Tree Mix 6", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Tree Of Heaven Ailanthus Altissima", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Walnut, Black Juglans Nigra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Trees, Willow, Black Salix Nigra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Cocklebur Xanthium Strumarium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Dog Fennel Eupatorium Capillifolium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Dog Fennel, Eastern Eupatorium Capillifolium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Goldenrod Solidago Canadensis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Lambs Quarters Chenopodium Album", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Nettle Urtica Dioica", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Pigweed, Rough Redroot Amaranthus Retroflexus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Plantain, English Plantago Lanceolata", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Ragweed, Giant Ambrosia Trifida", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Ragweed, Mixed Ambrosia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Ragweed. Western Ambrosia Psilostachya", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Russian Thistle Salsola Kali", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Sagebrush, Mugwort Artemisia Vulgaris", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Scale, Wing Shad Atriplex Canescens", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Scotch Broom Cytisus Scoparius", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Short Ragweed, Ambrosia Artemisiifolia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds And Garden Plants, Sorrel, Sheep Rumex Acetosella", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds, Careless Weed Amaranthus Palmeri", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds, Careless/Pigweed Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds, Dock/Sorrel Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds, Giant, Short, Western Ragweed Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds, Kochia Scoparia", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds, Marshelder/Poverty Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens - Weeds, Weed Mix 2630", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pollens Early", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pollinosis Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polmon", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polocaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Polocaine (Mepivacaine Hcl)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration" }] }] },
    { Medication: "Polocaine(R) -Mpf (Mepivacaine Hci)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration" }] }] },
    { Medication: "Polocaine-Mpf", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Alfajor", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Capuccino", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Cocada", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Cocoa", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Crema", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Flan", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Manjar", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Mocha", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos Compactos De Alta Cobertura Con Fps 20 Waffle", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polvos De Sulpha", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poly Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poly Hist Forte", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poly Hist Pd", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poly Interferent Mouthwas", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poly-Hist Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poly-Tussin Ac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poly-Vent Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poly-Vent Ir", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polycin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Polyethylene Glycol", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol (3350)", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350 And Electrolytes - Unflavored", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350 And Electrolytes With Lemon Flavor", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350 And Electrolyteswith Orange Flavor", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350 Nf", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350, Nf Powder For Solution, Laxative", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350, Sodium Chloride, Sodium Bicarbonate And Potassium Chloride - Unflavored", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350, Sodium Chloride, Sodium Bicarbonate And Potassium Chloride With Lemon Flavor", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol 3350, Sodium Chloride, Sodium Bicarbonate And Potassium Chloride With Orange Flavor", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polyethylene Glycol-3350 And Electrolytes", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polygonatum Cepa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polygonum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Polygonum Punctatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Polymyxin B",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intrathecal; Intravenous; Ophthalmic" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intrathecal; Intravenous" }, { Route: "Intramuscular; Intrathecal; Intravenous; Ophthalmic" }] },
        ],
    },
    {
        Medication: "Polymyxin B Sulfate And Trimethoprim",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Polymyxin B Sulfate And Trimethoprim Sulfate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Polysporin", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polysporin First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Polytrim", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Polytussin Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pomada De Sulfa", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pomada Dragon Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pomada Sulfa First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pomalyst", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pomegranate And Vanilla Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pomegranate Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pomegranate Hand Sanitizer Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pomegranate Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pomegranate Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pomegranate Spf 15 Lip", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pomiks Antiseptic Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ponderosa Pine Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Ponds", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ponos Natural Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ponvory",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Poo Pourri Antibacterial Hand Sanitizer - Coconut Plus Lavender", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poof 100% Mineral Part Powder Spf 35", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pop Citrus Ginger Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pop Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pop Green Tea Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pop Ocean Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pop Watermelon Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Popcare", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Populus Alba Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Populus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Populus Deltoides Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Populus Deltoides Ssp Monilifera Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Populus Fremontii Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Populus Tremuloides Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Porcelain Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Porcelana Day Skin Lightening", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pork",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Port And Co", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Portia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Portrazza", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Posaconazole",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Posimir", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Infiltration" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 10N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 12C", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 14W", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 16C", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 20W", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 22W", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 24N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 26W", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 28C", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 30N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 32N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 34W", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 36C", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 38N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 40N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 42N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 44N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 46C", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 48W", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 50N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 52W", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 54N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 56N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Light Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen 58N", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Positive Skin Test Control - Histamine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Post - Vaccination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Post Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Post Rays Uv Protect Sun Block", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Post Treatment Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Post-Dental Visit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Post-Vaccination", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Postday One-Step", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Postpartum Blues", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Postpituapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Potassium Acetate", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Potassium Chloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Coated, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }, { Route: "Parenteral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Potassium Chloride Extended Release", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Potassium Chloride Extended-Release",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Potassium Chloride For Oral Solution", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Potassium Chloride In Dextrose",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Potassium Chloride In Dextrose And Sodium Chloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Potassium Chloride In Lactated Ringers And Dextrose", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Potassium Chloride In Sodium Chloride",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Potassium Citrate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Potassium Citrate And Citric Acid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Potassium Citrate, Sodium Citrate, And Citric Acid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Potassium Iodide", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Potassium Phosphates",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Potassium-Cit", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Poteligeo", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Poultry First Aid", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poverty Weed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Povi-One", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidine Iodine", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Povidone Iodine",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Povidone Iodine Impregnated", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Povidone Iodine Prep",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Povidone Iodine Prep Pad",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Povidone Iodine Prep Solution Sterile", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Povidone Iodine Scrub",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Povidone Iodine Solution First Aid Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone Iodine Surgical Scrub", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone Iodine Swabsticks 4 Triple", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone Iodine Topical Solution Paint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Povidone-Iodine",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Povidone-Iodine Cleaning Scrub Swabstick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Gel Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Gel Swabstick", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Gel Swabstick Non-Sterile", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Pad- Povidone-Iodine Swab", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Paint Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Prep", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Povidone-Iodine Prep Pad",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Percutaneous" }, { Route: "Topical" }] },
        ],
    },
    {
        Medication: "Povidone-Iodine Prep Sterile",
        DoseTypes: [
            { DoseType: "Sponge", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Povidone-Iodine Prep Winged Sterile Large", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Prep Winged Sterile Small", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Iodine Scrub Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Povidone-Iodine Scrub Sterile",
        DoseTypes: [
            { DoseType: "Sponge", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Povidone-Iodine Solution Sterile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Povidone-Lodine Swabsticks Triple", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Poviofix Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Power Pain Relief Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Power Stick For Her Powder Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Power Stick For Her Shower Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Power Stick Xj Coolblast Xtreme", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Power Stick Xj Intensity", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Power Up", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Power Up For Women", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Powerect Max Power Male Delay", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Powerfulx Ginseng Recoverycream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Powerspot Cleanse", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ppe Advantage Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ppe Advantage Wet Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ppe Company Care Anti-Bacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ppe Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ppe361 Alcohol Wipe 1", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pqg Advanced Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pr", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pradaxa",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prairie Mugwort Darkleaved Sagebrush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Praluent", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Pramipexole", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pramipexole Dihydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pramosone",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pramosone Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pramoxine Hydrochloride", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pranarom Aromaforce Alcohol Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pranarom Good Samaritan Alcohol Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prandin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pranicura 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pranicura Ii", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prasugrel", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pravastatin Sodium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prax", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Praxbind", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Praziquantel", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prazosin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prazosin Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pre Fense", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pre Fense Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pre Post Surgery", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pre-Dental Visit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pre-Labor Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pre-Moistened Towelette", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pre-Pen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intradermal; Subcutaneous" }] }] },
    { Medication: "Pre-Scrub Ii", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pre/Post Surgetone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prebel", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preboost", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Precedex",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Precious Gems", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Precision Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pred Forte", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Pred Mild", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Pred-G",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Prednicarbate", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Prednisolone",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prednisolone Acetate", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Prednisolone Sodium Phosphate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prednisolone Sodium Phosphate Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Prednisone",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prednisone Intensol", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preface Earth Baby Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preferred Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preferred Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preferred Plus Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preferred Plus Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preferred Plus Pharmacy Artificial Tears", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Preferred Plus Pharmacy Redness Relief Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Preferred Plus Senna", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preferred Plus Value Vapor Steam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Preferred Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prefest", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Pregabalin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pregabalin Capsules, Cv", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pregabalin Extended Release", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pregnenolone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pregnyl", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Prehevbrio", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Prelidopine Pre-Operative System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Premarin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Premasol - Sulfite-Free (Amino Acid)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Premier", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Solutions Antifungal Tolnaftate Liquid", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Solutions Antifungal Tolnaftate Powder", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Solutions Liquid Bandage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Solutions Liquid Corn And Callus Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Premier Value",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Oil", Routes: [{ Route: "Oral" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Premier Value Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Arthritis 8 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Arthritis Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Calaclear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Chewable Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Chewable Antacid Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Chewable Antacid Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Diarrhea Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Ear Relief Ear Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Premier Value Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Extra Strength Chewable Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Famotidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Fiber", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Fiber Laxative", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Premier Value Fiber Therapy",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Premier Value Infants Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Instant Antiseptic Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Iodine Tincture Mild", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Lice Treatment Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Maximum Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Medicated Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Medicated Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Merthioalate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Multi Symptom Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Nausea Control", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Nighttime Sleep-Aid Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Regular Strength Chewable Antacid Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Smooth Antacid Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premier Value Tussin Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premier Value Zinc Cold Therapy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premiere Value Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premiere Value Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premiere Value Ear Drops For Swimmers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Premieres Pain", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Age Protector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Age Protector Ultra", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Foam Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Hand Sanitizer Fresh Lavender", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Hand Sanitizer Fresh Lavender Gel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Hand Sanitizer Gel Tobacco Leather", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Hand Sanitizer Golden Amber", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Hemp Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Moisturizing Sunscreen Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Nature Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Nature Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Nature Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Protection Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Premium Whitening", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Premphase", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Prempro", DoseTypes: [{ DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Premsyn Pms Premenstrual Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenaissance", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenaissance Plus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenatal Plus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenatal Plus Vitamins", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenatal Vitamins Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenatal Vitamins Plus Low Iron", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenate Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenate Dha", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenate Elite", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenate Enhance", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenate Essential", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenate Mini", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenate Pixie", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenate Restore", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenatrix", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prenatryl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preop", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prep And Prime Spf Mini Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prep Non Sterile", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prep Pad Sterile", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prep Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Preparation H",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Preparation H For Women", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preparation H Medicated Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Preparation H Rapid Relief",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Preparation H Soothing Relief Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preparation H Soothing Relief Cleansing And Cooling Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preparation H Totables Irritation Relief Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prepared Instant Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prepidil", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Prepiel K365 Clean Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prepiel K365 Clean Tissue", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prequimm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preserve And Protect Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Presgen", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Presgen B", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Presgen Pediatric", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Presidential Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pressed Mineral", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pressure And Pain", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain And Cold", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain Pe Plus Cold", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain Pe Plus Cough", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain Pe Plus Mucus", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain Plus Cold Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain Plus Cough Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain Plus Mucus Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure And Pain Sinus Relief Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Pain And Cold Suphedrine Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Pain Cold", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Pain Cold Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Pain Mucus", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Pain Mucus Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Pain Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Plus Pain Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Plus Pain Pe Plus Cold", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Plus Pain Pe Plus Cough", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Plus Pain Pe Plus Mucus", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pressure Plus Pain Plus Mucus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prestalia", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prestige", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pretomanid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pretrate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pretty In Bloom Spf 20 Flower Infused Long Wear Foundation Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pretty Pump Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pretty Punch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Prevacid",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prevacid 24 Hr", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prevacid Solutab", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prevage Anti Aging Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevage Anti-Aging Eye Cream Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevage Anti-Aging Moisture Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevage City Smart", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevail Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevalite", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prevantics Maxi Swabstick", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevantics Swab", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevantics Swabstick", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Prevent",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Prevent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevent Restoration", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preventage Firming Defense Creme Normal/ Oily Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preventage Firming Defense Creme Normal/Dry", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preventage Firming Defense Creme Normal/Dry Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preventage Firming Defense Normal/Oily Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevention Mouth Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prevention Oncology Mouth Rinse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prevention Wound And Burn", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Preventive Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Preventmd Extraclean Hydrating Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Previcare", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Previddent 5000 Booster Plus Spearmint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Prevident",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Prevident 5000 Booster Plus Fruitastic", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Prevident 5000 Enamel Protect", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Prevident Alcohol Free", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Prevident Fresh Mint", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Prevident Orthodefense", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Prevident Very Berry", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Previfem", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Prevnar 13", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Prevnar 20", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Prevymis",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prezcobix", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Prezista",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prfc Hemp Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prialt", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Pricill 70% Etoh Hand Mist Sweet Pleasure", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prickly Pear And Salicylic Acid Acne Clearing Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prid", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prid Salve", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Priftin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prilo Patch", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Priloheal Plus 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prilosec", DoseTypes: [{ DoseType: "Granule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prilosec Otc", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prilovix Lite", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prilovix Lite Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prilovix Ultralite", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prilovix Ultralite Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prilovixil", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prima Clean Hands Moisturizing Hand Sanitizerspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prima Fleur Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Prima Fleur Hand Sanitizer Bergamoti Neroli", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Prima Fleur Hand Sanitizer Lemon Thyme", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Primagel Plus Rinse-Free Antiseptic Hand Sanitizer 70 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primal Elements Antibacterial", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primal Elements Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primal Elements Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primal Elements Isopropyl Alcohol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Primaquine Phosphate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Primatene", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Primatene Mist", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Primaxin", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Prime",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prime Of Life", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prime Source Antibacterial Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Antibacterial Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Antibacterial Ltion So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Antimicrobial Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Antimicrobial Foaming Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Antimicrobial Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source E2 Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Select Plum Antibacterial Dye Free Foam Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Select Plum Antibacterial Foam Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Ultra Energy Plum Antibacterial Dye Free Foam Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime Source Ultra Energy Plum Antibacterial Foam Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prime-Cide", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primegel Antiseptic Hand Sanitizer Gel 80 Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primer Plus Radiance Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Primest Ethyl Alcohol Antiseptic 70",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Primest Hand Sanitizer Isopropyl Antiseptic Non-Sterile Topical Solution 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primest Hand Sanitizer Isopropyl Antiseptic Non-Sterile Topical Solution 75", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primest Hand Sanitizer Non Sterile Alcohol 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primest Hand Sanitizer Non Sterile Alcohol 75", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primest Hand Sanitizer Non-Sterile Alcohol Antiseptic Topical Solution 70", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primest Hand Sanitizer Nonsterile Isopropy Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primidone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Primo Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Primo Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Primory Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primory Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primory Clean Skin Antibacterial Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Primula Auro Culta 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Primula Onopordon", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Primula Onopordon Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Primula Scilla", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Primula Scilla Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prince And Spring Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Princess Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Principal Secret Advanced With Hydrospheres Continuous Moisture Spf8", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Principal Secret Reclaim Botanical (With Sp) Radiant Recovery Day Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Priorix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Prism Health Lab Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prismasol", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Prismatic Protectant Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prisme Primer Color Correcting Primer Spf 20 Pa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pristine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pristiq", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Private Label Antifungal Barrier Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Private Label Moisturizing Oil Free Paraben Free Spf43", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Private Label Poison Ivy Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Private Label Spf45 Water Resistant Paraben Free Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Private Label Super Light Oil-Free Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Privet Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Privigen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Pro Advantage Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pro Advantage Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pro Clean Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro Clear Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro Clear Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro Formance Plum Perfection Antibacterial Foam S Oap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro Guard", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-C-Dure 5 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intra-Articular; Intramuscular" }] }] },
    { Medication: "Pro-C-Dure 6 Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intra-Articular; Intramuscular" }] }] },
    { Medication: "Pro-Care", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Collagen Day And Night Duo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Pro-Collagen Marine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Collagen Tsa Traveller Collection", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Pro-Den Rx",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Oral" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pro-Enema Single Saline", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Pro-Ex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Link Antibacterial Plum Perfection Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Link Foaming Antimicrobial So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Link Free Hands Antibacterial Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Link Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Link Optimum Foam Hand Sanitizer Without Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Link Optimum Foaming Antimicrobial So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Link Optimum Foaming Medicated Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Link Prestige Antibacterial Gold Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro-Sys", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Pro-Tect Sports Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Pro-Tect Sports Sunscreen Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Pro-Tect Sunscreen For Professionals", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Pro1Tek Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro1Tek Hand Sanitizer Gallon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pro1Tek Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Acne Body Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Acne Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Acne Body Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Acne Clearing Body", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Advanced Daily Oil Control", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Blackhead Dissolving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Clarifying Night", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Cleansing Body Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Clear Skin Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Clear Zone Body Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Deep Cleansing Body Wash", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Deep Cleansing Wash", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Emergency Blemish Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Extra Strength Formula Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Extra Strength Formula Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Gentle Formula Clarifying Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Gentle Formula Clarifying Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Md Adapalene", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Md Daily Oil Control Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Plus Pore Targeting Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Plus Retexturizing Toner", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Refining Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Renewing Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Repairing Concealer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Repairing Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Repairing Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Skin Clearing Water", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Soothing Shave Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactiv Spf 15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactive Solution Cleaning Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proactivplus Skin Smoothing Exfoliator", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proair", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Proair Digihaler",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    { Medication: "Proair Respiclick", DoseTypes: [{ DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Proaller", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Probenecid", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Probenecid And Colchicine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Probiotic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Probiotic Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Problend Alcohol Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Problend Antibacterial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Problend Antibacterial Foaming Silk", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Problend E3 Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procainamide Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Procainamide Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Procalamine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Procardia", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Procbd Pain Cream (Arthritis) 2Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procbd Pain Cream (Muscle) 4Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procbd Pain Cream (Penetrating) 2Oz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procentra", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Prochlorperazine",
        DoseTypes: [
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Prochlorperazine Edisylate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    {
        Medication: "Prochlorperazine Maleate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Proclean", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proclean Foaming Antibacterial Nexa", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proclearz", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proclearz Maximum Strength Fungal Shield Brush-On Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procort", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procrit", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Procto-Med Hc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Proctocort",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Proctofoam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Rectal" }, { Route: "Topical" }] }] },
    { Medication: "Proctosan Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proctosol-Hc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proctozone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proctozone-Hc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procure", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procure Hand Sanitizing", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procure Hydrocortisone Cream Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procure Products Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procure Vitamin A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procurefirst Aid Antibio", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procuretriple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procurevitamin A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procuro Alcohol Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procuro Sanitizing Wet Wipes - Lemon Fragrance", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procuro Sanitizing Wet Wipes - Pine Fragrance", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Procuro Sanitizing Wet Wipes - Rose Fragrance", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Procysbi",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Profen Ib",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Profend Nasal Decolonization", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Professional", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Professional Choice Gold Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Professional Solutions - Botanical Day Cream Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Professional Therapy Musclecare",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Cutaneous; Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Cutaneous; Topical" }] },
        ],
    },
    { Medication: "Professional Therapy Musclecare Pain Relieving", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Cutaneous; Topical" }] }] },
    { Medication: "Professional Therapy Musclecare Pain Relieving Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous; Topical" }] }] },
    { Medication: "Professional Weight Maintenance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Professional Weight Support",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Professional-C Suncare Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Professor Amos Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Profilnine", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Profinac", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Profoot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Profoot Pain Relief Patches", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Profuse Sweating", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Progel Max Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Progena", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Progenesis", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Progenite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Progesterone",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Progesterone Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Progesto-Life", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proglycem", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Prograf",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Proguard Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prohance", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Prohemp Soothing Menthol Rub 4%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project C.U.R.E Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Reef Mineral Sunscreen Spf-30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Reef Mineral Sunscreen Spf-50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Reef Spf-30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Reef Spf-50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Sunscreen Baby Mineral Sunscreen Roll-On", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Sunscreen Festival Mineral Sunscreen Roll-On", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Sunscreen For All Mineral Sunscreen Roll-On", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Sunscreen Kids Mineral Sunscreen Roll-On", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Project Sunscreen Spfu Mineral Sunscreen Roll-On", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prolastin-C", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Prolastin-C Liquid", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Prolate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Prolayed",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Prolayed Lidocaine Male Genital Desensitizer", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prolensa", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Proleukin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Prolia", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Prologel Neurotherapy", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prologel Pain Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prologel Urgent Care", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Promacta",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Promescent", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Promescent Delay Wipes", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prometa", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promethazine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promethazine Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promethazine Hci", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Promethazine Hcl, Phenylephrine Hcl, Codeine Phosphate", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Promethazine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Promethazine Hydrochloride And Codeine Phosphate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Promethazine Hydrochloride And Dextromethophan Hydrobromide", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Promethazine Hydrochloride And Dextromethorphan Hydrobromide",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Promethazine Hydrochloride And Dextromethorphan Hydrobromide Oral", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promethazine Hydrochloride And Dextromethorphan Hydrobromide Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Promethazine Hydrochloride And Phenylephrine Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Promethazine Hydrochloride, Phenylephrine Hydrochloride And Codeine Phosphate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promethazine Vc", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promethazine Vc With Codeine Oral Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promethazine With Codeine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promethegan", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Prometrium", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Promolaxin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pronature Earth Conscious Hand Sanitizer With Aloe Vera Extract", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Propafenone Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Propafenone Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Propantheline Bromide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Proparacaine Hydrochloride", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Propecia", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Proplex Bacitracin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proplex Greaseless Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proplex Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Propofol", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Propofol Injectable Emulsion", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Propofol Lipuro", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Propower Originals Antimicrobial Foaming So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Propranolol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Propranolol Hcl Er", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Propranolol Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Propranolol Hydrochloride Er", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Propylthiouracil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Proquad", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Prorino Delay",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Prosacea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Prosana Skin And Body",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Prosanca",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Proscar", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Proscenat", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prosecco Hand Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prosecco Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prosera Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prosera Soothing Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prosera Soothing Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proshield Plus", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prosol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Prosopis Juliflora Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Prosoria Anti-Itch Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prosoria Medicated", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Prosoria Psoriasis Treatment",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Prosoria Scalp", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prostate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prostate Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prostate Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prostatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prostex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prostin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravascular; Intravenous" }] }] },
    { Medication: "Prostoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Protamine Sulfate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Protec Hand Sani Gel Antibacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect And Prime Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect U Guard 63% Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect U Guard 63% Foaming Hand Sanitizer Cotton", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect U Guard 63% Foaming Hand Sanitizer Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect U Guard 63% Foaming Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect U Guard 63% Foaming Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect U Guard 63% Foaming Hand Sanitizer Watermelon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protect U Guard Antibacterial Foaming", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protecteav Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protectif Deep Tint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protecting Fhs", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protection", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protection U.V. Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Protective Day",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Protective Foundation Sunscreen Spf 15", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protective Industrial Products Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protector Brands Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protector Gripper Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protectorplus Alcohol Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proteinuria Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Protetor Solar Grip Action Fps 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proteus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Proteus Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Protex Balance Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protex Complete Antibacterial Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protex Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Protherix", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intramuscular" }] }] },
    {
        Medication: "Protonix",
        DoseTypes: [
            { DoseType: "Granule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Protopam Chloride", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Protopic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Protriptyline Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Provayblue", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Prove Alcohol Prep Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Proven Personalized Day Moisturizer With Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provence Air Skin Fit Pact 01 Light Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provence Air Skin Fit Pact 02 Natural Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provenge", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Proventil Hfa", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Provera", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Providone-Iodine Prep Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provigil", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Provocholine", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Provocholine Inhalation Solution", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Provon Antimicrobial Foam Handwash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provon Antimicrobial Ltn Sp With 0.3% Pcmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provon Foaming Antimicrobial Handwash With Moisturizers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provon Foaming Antimicrobial Handwash With Pcmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provon Perineal Skin Prot", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Provtect Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prozac", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prozero", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prudoxin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Prunus Iron", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prunus Iron Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Prunus Spinosa",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Prunus Spinosa E Flor. Et Summ. 2 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prunus Spinosa E Flor. Et Summ. 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prunus Spinosa E Flor. Et Summ. 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prunus Spinosa E Summ 10%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Prunus Spinosa E Summ 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pryidostigmine Bromide", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ps1 Aciurgy Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ps2 Aciurgy Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pseudo Ephed Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pseudo Time Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pseudoephedrine Hcl",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pseudoephedrine Hcl And Guaifenesin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pseudoephedrine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Psodex Eczema Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psodex Eczema Therapy Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psodex Psoriasis Cream Intensive Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psodex Psoriasis Cream Standard Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psodex Psoriasis Therapy Intensive Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psodex Psoriasis Therapy Standard Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psora Miasm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psorcon", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psoriaflora", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psoriaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psoriasin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psoriasin Deep Moisturizing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psoriasin Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psoriasin In-Shower", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Psoriasis",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Psoriasis Control",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Psorinum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Psorinum Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psorinum Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psorinum Miasm Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psorizide Forte", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psorizide Ultra", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psp 70 Isopropyl Rubbing Alcohol First Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psp Go", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psp Go Isopropryl Rubbing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psp Hydrogen Peroxide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psp Hydrogen Peroxide 3 First Aid Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psp Isopropyl Rubbing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psr Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pssi E-San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Psy-Stabil", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psychotria Ipecacuanha Mt", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Psychypar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ptelea Trifoliata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pts/Stress", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pts/Stress Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ptsd/Stress Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pube Chlorine Dioxide Disinfection", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Public Goods", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Publix",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Publix Alcohol", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Publix Calamine Medicated", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Publix Clear Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Publix Extra Moisturizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Publix Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Publix Fast Relief Effervescent", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Publix Fiber Therapy", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Publix High Performance Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Publix Infants Dye Free", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Publix Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Publix Moisturizing Relief Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Publix Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Publix Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Publix Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Publix Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Publix Women", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pulezza Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pullio Hand Sanitizing Wipes Aloe Vera", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pullio Hand Sanitizing Wipes Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pullio Hand Sanitizing Wipes Lavender", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pullio Hand Sanitizing Wipes Peppermint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pullio Wipes For Faith", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pullio Wipes For Jacob", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pullularia", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Pulmicort", DoseTypes: [{ DoseType: "Aerosol, Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Pulmicort Respules", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Pulminum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pulmo 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pulmo Bryonia", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pulmo Calcio", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pulmo Echinacea",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pulmo Ferrum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pulmo Mercurius", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pulmo Mercurius Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pulmo Tartarus 6/8",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pulmopar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pulmotech Maa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Intraperitoneal; Intravenous" }] }] },
    { Medication: "Pulmozyme", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Pulsatilla",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Pulsatilla Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pulsatilla Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pulsatilla Nig", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] }] },
    {
        Medication: "Pulsatilla Nuttalliana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pulsatilla Tormentilla",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pulso Absolute Antiperspirant Roll-On Deodorant", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pulso Antiperspirant Roll-On Deodorant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pumice Cleansing Scrub- 2.5% Benzoyl Peroxide", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pumpkin Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pumpkin Juice Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pumpkin Spice Scented Clip On Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pumpkin Spice Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Punchgunk", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puncture Wounds Bites", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Puppicorn Cotton Candy Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puppicorn Strawberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pur 4-In-1 Correcting Primer", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pur Confort Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pur-Defense", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pur-Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Pura 75 Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pura Vida Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puracy Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puracy Natural Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purador", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purago Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purax", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purcol Anti-Bacterial Wipes Lemon Scent Heavy Duty", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purcol Antibacterial Wipes Lemon Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purcol Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purdoux", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure And Gentle", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Pure And Gentle Laxative", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Pure And Protected Hand Sanitizing Cloth", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure And Safe Hand Sanitizer Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Biology Eczema With Colloidal Oatmeal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Care Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Pure Carnation",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pure Cornstarch Baby", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Dash Antiseptic Hand Almond Scented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Dew", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Dew Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Dew Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Essential Minerals Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Eyes Sterile Eyes", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Pure Force", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Grass Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Green Hand Sanitizer Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Haven Body Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Haven Essentials", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Haven Face Sunscreen Spf-30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Honest Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Inflamedix Natural Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Lavender", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Life Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure O2 Pack-Solid", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure O2 S", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure O2-S F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure O2Stick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Passion Hand Sanitizer Juicy Watermelon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Passion Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Petrolatum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Skin Naked All Natural Sunscreen Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Source Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Vitac Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure Wipes 70 Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure X Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pure-Aid", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }, { Route: "Topical" }] }] },
    { Medication: "Purease Antiseptic Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purease Hand Sanitizerspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purecarerx Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purecle", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purefoam Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pureforce", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puregel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purehocl", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purelax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Purelife Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Purelife Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Purelight", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced E3 Rated Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced E3 Rated Instant Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Purell Advanced Green Certified Instant Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Purell Advanced Hand Sanitizer Be Blissful", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Be Peaceful", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Be Vibrant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Biobased Content", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Calming Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Energizing Mint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Fragrance Free Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Moisturizing 2In1 Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Naturals Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Naturals Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Ocean Kiss", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Prime Defense", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Spring Bloom Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Hand Sanitizer Ultra Nourishing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Instant Hand Sanitizer Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Moisturizing 2In1", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Refreshing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Skin Nourishing Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Advanced Soothing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Cottony Soft Hand Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Education Advanced Hand Sanitizer Gentle And Free Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Education Hand Sanitizer Sf607 Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Food Processing Advanced Hand Sanitizer E3 Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Food Processing Healthy Soa P Bak E2 Antimicrobial Foam Soa P", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Food Processing Healthy Sp 0.5Pct Pcmx Antimicrobial E2 Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Foodservice Advanced Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Foodservice Advanced Hand Sanitizer Vf481 Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Foodservice Healthy Sp 0.5Pct Bak Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Green Certified Instant Hand Sanitizer Foam (Ethyl Alcohol)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Hand Sanitizer Purifying Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Hand Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Hand Sanitizing Wipes Alcohol Formula", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Hand Sanitizing Wipes Clean Refreshing Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthcare Advanced Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthcare Advanced Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthcare Advanced Hand Sanitizer Gentle And Free Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthcare Advanced Hand Sanitizer Ultra Nourishing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthcare Healthy Sp 0.5 Bak Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthcare Healthy Sp 0.5Pct Pcmx Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthy Sp 0.5Pct Bak Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthy Sp Antibacterial Ltn Sp With Pcmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthy Sp Bak E2 Antimicrobial Foam Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Healthy Sp Pcmx E2 Antimicrobial Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Instant Hand Sanitizer Ocean Mist", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Professional Advanced Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Professional Advanced Hand Sanitizer Fragrance Free Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Professional Advanced Hand Sanitizer Fragrance Free Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Professional Advanced Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Professional Healthy Sp 0.5Pct Bak Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Sf607 Instant Hand Sanitizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Vf Plus Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Vf481 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purell Waterless Surgical Scrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purely Physical Spf 47", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purely Soft Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purenique Hygiene Solutions Alcohol Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purenique Hygiene Solutions Antiseptic Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purenique Hygiene Solutions Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puresan Antibacterial Foaming Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puresan Antibacterial Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puresan Hand Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puretize", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purevit Dualfe Plus", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Purexx Ipa Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pureza Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purfree Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purge Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Purgo Af", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purgo Af Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purgo Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puri Clean Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puri-San Plus Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puriderm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purif Alcohol Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purif Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purif Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purif Instant Hand Sanitizer Innstyle", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purifi Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purified Cortrophin Gel", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Puriguard Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purilab", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purinethol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Puristic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puriton Perfect Brightening Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purity", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purity Made Simple", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purity Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purixan", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Puriya Antifungal Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purizee Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purklenz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purlif Tek", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purlisse Blue Lotus Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purlisse Perfect Glow Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Blush Medium)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Deep)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Deeper)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Golden Dark)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Golden Medium)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Light Tan)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Light)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Medium Dark)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 14-Hour Wear Foundation Broad Spectrum Spf 15 (Tan)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 Mineral Tinted Moisturizer Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 Mineral Tinted Moisturizer Broad Spectrum Spf 20 (Dark)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 Mineral Tinted Moisturizer Broad Spectrum Spf 20 (Light)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 Mineral Tinted Moisturizer Broad Spectrum Spf 20 (Medium)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purminerals 4-In-1 Mineral Tinted Moisturizer Broad Spectrum Spf 20 (Tan)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purmist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puro 70 Percent Alcohol Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puro Antibacterial Wash Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puro Azul Awapuhi Antibac", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puro Non-Alcohol Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Puroma",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Puroma Alcohol 75% Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Alcohol Hand Sanitizer Wipes Fragrance Free", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Antibacterial Foaming Hand Wash Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Antibacterial Foaming Hand Wash With Fragrance Alcohol Free Citrus Floral", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Antibacterial Hand Wash Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Antibacterial Hand Wash With Fragrance Lavender Rose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Foaming Hand Alcohol Free Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Foaming Hand Sanitizer Alcohol Free With Fragrance Floral", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Hand Sanitizer Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Hand Sanitizer Wipe Alcohol Free", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Hand Sanitizer Wipe Alcohol Free With Fragrance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Instant Foaming Hand Sanitizer With Fragrance Citrus Floral", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Instant Foaming Hand Sanitizer Without Fragrance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puroma Ipa Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purple Coneflower", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Purple Frog Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purple Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purple Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purpleu Baobab Face", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purpleu Baobab Firming", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purpose", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purprep", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purrfect Cherry Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pursan", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purupuru Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Purx Eco Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Push Clean", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pussy Willow Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Puttisu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Puyen Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pv Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pv Extra Strength Smooth", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pv Ultra Zinc Cold Therapy", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pvp Iodine", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pvp Iodine Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pvp Iodine Prep Swabstick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pvp Iodine Swabs", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pvp Iodine Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pvp Scrub Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pvp-I Pouch Foil-Foil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pvp-I Prep Pads Foil-Foil", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pvp-Iodine Swabstick Threes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pycnogenol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pylarify", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Pylera", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pyrantel Pamoate",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pyrargyrite 6 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyratine Xr Creme With Sunscreen Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pyrazinamide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyrethrum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Pyrethrum Cinerariifolium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Pyridium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyridostigmine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pyridostigmine Bromide",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pyridoxine Hci", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Pyridoxine Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Pyrimethamine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyrite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pyrite Salvia",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Pyrithione Zinc",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Pyrocaine Burn", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pyrogenium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyrogenium Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyrogenium Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyroxy Gel-70 Base", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pyroxy Gel-70 Base Poly Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pyroxy Liquid-80B", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Pyrrole", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Pyrukynd",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Pytest", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Pyurx", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Q Health Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Q Shield Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Q Smart Spa Germinator Anti-Bacterial", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Q Tussin Dm", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qb Skin Lab Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qbrelis", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qbrexza", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qc 2Oz Instant Hand Sanitizer (Original)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qc 8Oz Instant Hand Sanitizer (Original)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qc Antacid Calcium", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qc Anti Diarrheal", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qc Burn Relief Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qc Cool And Heat Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qc Fiber Therapy", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qc Gas Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qc Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qc Medicated Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qc Natural Daily Fiber Sugar Free", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Qc Pink Bismuth",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Qc Quality Choice Pink Bismuth Stomach Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Qc Stomach Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Qcare Oral Cleansing And Suctioning System, Q4", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Qdolo", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qelbree", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qian Li Zhui Feng Pain Relieving", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qinlock", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qkit Procedure Tray", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Qnasl", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Qr Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qs Plus Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qs Plus Plus Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsc Antimicrobial Wash Cm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsc Assist Antimicrobial Hand Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsc Foam Fresh Antimicrobial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsc Tf Ambre Antibacterial Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsr", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsr Foaming Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsr Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsyh Hypochlorite Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qsymia", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qtern", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quackgrass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Quadracel", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Quadrydern", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quala Topical Anesthetic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Qualaquin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quali Cold", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quali Herbal", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quali Patch (Cold)", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quali Patch Herbal Soultice", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quali Patch Warm", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quali Warm", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Quality Choice",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Quality Choice Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Anti-Itch Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Anti-Itch Medicated", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Aromatic Ammonia Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Artificial Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Quality Choice Assorted Berries", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Camphor Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Cetirizine Hydrochloride", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Chewable Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Ear Relief Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Quality Choice Earwax Removal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Earwax Removal Kit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Effervescent Cold Relief - Orange", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Effervescent Cold Relief Plus", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Extra Strength Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Eye Drops Irritation Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Quality Choice Eye Drops Moisturizing Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Quality Choice Eye Wash", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Quality Choice Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Hydrogen Peroxide Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Infants Dye Free Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Instant Ear Dry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Quality Choice Iodides Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Iodine Tincture Mild", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Itch Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Lice Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Quality Choice Maximum Strength Oral Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Medicated Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Medicated Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Medicated Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Merthiolate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Mucus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Mucus Relief Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Quality Choice Original Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Quality Choice Pain Relief Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Pain Relief Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Severe Cold And Cough Nighttime", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Single Saline", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Quality Choice Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Ultra Strength Pain Relieving Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quality Choice Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Urinary Pain Relief Maximum Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quality Choice Vapor Steam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Quantumtoothcare Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Quaranclean Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quartette", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Quartz 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quartz 60 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quartz 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quartz Oxalis Nicotiana", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quash Multi Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quash Multi Sanitizing Tissue", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quatriderm Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quazepam", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qudexy", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quebracho", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Queen Palm Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Queen79 Noble Gold Aquacream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Queen79 Noble Gold Eyecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Queen79 Noble Gold Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Queen79 Noble Goldcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quelicin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Quercetin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quercetin Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quercus 5", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quercus Agrifolia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Quercus Alba Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Quercus Borago", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quercus Borago Adult Size", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Quercus Cinis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Quercus Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quercus Rubra Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Quercus Virginiana Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Questran", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Quetiapine",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Quetiapine Fumarate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Quflora Fe Pediatric Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quflora Pediatric", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quflora Pediatric Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Quick Action",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Quick Dry Body", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quick Numb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quick Protect", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quick Protect Sanitizing Alcohol", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quick San Ll Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quickmend", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quid Labs Alcohol Antiseptic 70 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quiet Calm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quietude", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quik-Care", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quiksilver Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quillichew Er", DoseTypes: [{ DoseType: "Tablet, Chewable, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quillivant", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Quinapril",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Quinapril And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinapril And Hydrochlorothiazide 10/12.5", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinapril And Hydrochlorothiazide 20/12.5", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinapril And Hydrochlorothiazide 20/25", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinapril Hcl And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinapril Hydrochloride And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinapril Hydrochloride/Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinidine Gluconate", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinidine Sulfate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinine Sulfate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quinixil", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Quinsana Anti-Fungal Powder 2 Miconazole Nitrate", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quinsana Athletes Foot Anti Fungal", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quintessence Q-Sunshade Leave In Hair Conditioner And Scalp Protectant Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quip", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Quip Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Quip Anticavity Fluoride", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Quita Callos Corn And Callus Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quita Colicos Gas Relief Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quita Piojos Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Quitters Relief Headache", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quitters Relief Irritability", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qulipta", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Qur Eucalyptus Essential Oil Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qur Lavender Essential Oil Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qur Vanilla Essential Oil Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qutenza", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Quviviq", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Quzyttir", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Qvar Redihaler", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Qve - S", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Qwo", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intralesional" }] }] },
    { Medication: "R And Co Palm Reader Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "R And R", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "R-Gene", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "R-Zero Ethyl Alcohol Antiseptic Hand Sanitizing Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "R-Zero Ethyl Alcohol Antiseptic Hand Sanitizing Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "R01", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R02", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R03", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R04", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R06", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R07", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R08", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R09", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R11", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R13", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R16", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R18", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R20", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R23", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R27", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "R32", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Rabavert", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Rabbit", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Rabbit Bush", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Rabbit Epithelia", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Rabbitbush Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Rabeprazole Sodium", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Raberprazole Sodium D/R", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rabofen Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Racepinephrine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Raddefense 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Raddefense1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Radiablock", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiaguard Advanced Radiation Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radialgin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Radiance Antibacterial Citrus Scent Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Antibacterial Green Apple Scent Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20 - Bare", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20 - Bronze", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20 - Cappuccino", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20 - Fair", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20 - Honey", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20 - Tawny", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20 -Caramel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20 -Spice", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20- Natural", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Foundation Broad Spectrum Spf 20-Nude", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Superbe Day Cream Neutral Bronze", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Superbe Day Cream Neutral Caramel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Superbe Day Cream Neutral Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Superbe Day Cream Neutral Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Superbe Day Cream Neutral Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Superbe Day Cream Nude", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiance Superbe Day Cream Translucent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Almond", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Caramel", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Espresso", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Golden", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Honey", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Sand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Shell", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radiant Defense Perfecting Liquid Broad Spectrum Spf 30 - Truffle", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Radiation",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Radiation Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Radiaura Skin Recovery", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radically Rejuvenating Day Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Radicava", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Radicava Ors", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Radinex", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Radiogardase", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Radiogenix System", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous; Intravesical; Ophthalmic" }] }] },
    { Medication: "Radium Bromatum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Radx", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rael Clean Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raf Five - After Hours Salicylic Acid Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raf Five - Clear The Way Salicylic Acid Acne Treatment Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raf Five - Kick Off Hydrating Broad Spectrum Spf30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raf Five - Spot On Salicylic Acid Roll-On Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raf Five - Wash Away Salicylic Acid Gel Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ragwitek", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Rain Renewal Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rainbow Berry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rainbow Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rainbow Hand Sanitizer Blue",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Rainbow Hand Sanitizer Clear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rainbow Hand Sanitizer Green",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Rainbow Hand Sanitizer Orange", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rainbow Hand Sanitizer Pink", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rainbow Hand Sanitizer Purple",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Rainbow Hand Sanitizer Red",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Rainbow Hand Sanitizer Yellow",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Rainbow Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rainforest Natural Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rainforest Natural Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rainforest Natural Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rainforest Of The Sea Water Foundation Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rainwater", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Raloxifene Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Raloy Pharma Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ralph Lauren", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ralph Lauren Ralphs Club Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ramedica", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ramelteon",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ramipril",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rancho Bernardo Inn Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Randex Hemp Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ranexa", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ranger Ready Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ranitidine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ranitidine 150", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ranitidine 75",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ranitidine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    {
        Medication: "Ranolazine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ranunculus Bulbosus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Raon Bio Safe Sanitizer Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raon Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raon Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rapaflo", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rapamune",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Raphanus Sativus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rapid Release Acetaminophen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rapid Release Acetaminophen Pm", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rapid Release Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rapid Wrinkle Repair Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rapidcare Flavored Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rapidcare Sterile Eyewash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rapidol Arnica", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rapidol Arnica Homeopathic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rapidol Aspirin Display 2X25", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rapidol Naproxen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rapivab", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rareessence Gel Well Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rareessence Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rasagiline", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rasagiline Mesylate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rash Relief", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rashes-Hives",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Raspberry Cooler Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raspberry Lime Margarita Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raspberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raspberry Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rast A Fri Stank Off Odor Eliminator Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rast A Fri Stank Off Odor Eliminator Rose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rast A Fri Stank Off Odor Eliminator Tea Tree Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rasuvo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Rat Epithelia", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Ratanhia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rauwolfia Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rauwolfia Serpentina", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ravicti", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Raw Antiseptic Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Baby Kids Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Baby Kids Broad Spectrum Spf30 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Daily Moisturizer Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Eco 30 Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Eco Formula 30 Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Eco Formula Spf30 Sample Packs", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Facial Moisturizer Tint Formula 30 Spf 30 Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Lip Rescue 30 Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Elements Tint 30 Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Sugar Hand Sanitizer Coconut And Lemon Verbena", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Sugar Hand Sanitizer Eucalyptus And Tea Tree", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Sugar Hand Sanitizer Peppermint And Sea Salt", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Sugar Hand Sanitizer Watermelon And Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Sugar Hand Sanitizer- Coconut And Lemon Verbena", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raw Sugar Hand Sanitizer- Peppermint And Sea Salt.", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Raxibacumab", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ray Defense", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rayaldee", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rayon Bamboo Heavy Duty Antiseptic Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rayos", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Razadyne", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Razzmatazz Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rbx For Men Antibacterial Hand Sanitizer Aloe Vera And Tea Tree Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rbx For Men Antibacterial Hand Sanitizer Cedarwood Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rbx For Men Antibacterial Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rbx For Men Antibacterial Hand Wash Aloe Vera And Tea Tree Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rbx For Men Antibacterial Hand Wash Cedarwood Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rbx For Men Antibacterial Hand Wash Eucalyptus Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rbx Hand Sanitizer Lemon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rbx Hand Sanitizer Ocean Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rbx Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rcp Alcohol-Based Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rd Food Service Dba Restaurant Depot", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Re-Fresh Scalp Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Re-Leve", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Re-Lieved Lidocaine Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Re-Nutriv Ultra Radiance Makeup Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Re9 Advanced", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Re9 Advanced For Men", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Re9 Advanced For Men Anti-Aging Skincare Line", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Re9 Advanced Prepwork", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Re9 Advanced Prepwork Soft Focus Veil Broad Spectrum Spf30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Re9 Advanced Skincare Sample Pack For Face", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Reach Anti Plaque", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Reactive Plus Anti-Oxidant Serum Plus Broad-Spectrum Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Readi-Cat 2", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Readi-Cat 2 Banana Smoothie", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Readi-Cat 2 Berry Smoothie", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Readi-Cat 2 Creamy Vanilla Smoothie", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Readi-Cat 2 Mochaccino Smoothie", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ready-To-Use", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Readybath Luxe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Readybath Select", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Readyflush Protect Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Readyprep Chg", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Real Eyewash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Real Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Real Time Hand Sanitizer 70% Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Daily Relief", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Foot Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Hemp Oil Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Maxx", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Select Plus Hemp Oil", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Sports Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Therapeutic Hand Cream", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Real Time Pain Relief Vegan Select", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Realheal-I", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Realtree", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rebetol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rebif",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    {
        Medication: "Rebif Rebidose",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Rebinyn", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Reblozyl", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Reboost",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }, { Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Reboost Immune", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Recalibr8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Recarbrio", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Recelltis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recens", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recens Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recens Foam Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recens Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recens Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recessa Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recharge Protect Blur", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recharge Regimen For Balancing, Boosting And Defending Healthy-Looking Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recharge Sanitizing Towlette", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recipe Infinite Uv Shield Sunscreen Broad Spectrum Spf100", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reclaim Revolutionary Anti-Aging Day Spf15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reclast", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Reclipsen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Recombinate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Recombivax Hb", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Reconstituted Air", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Recorlev", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Recothrom", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Recovatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Recover Cbd Pro", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recover Hemp Powered", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Recovery",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Recovery Antiseptic Skin Prep", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Recovery Numb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rectaease Hemorrhoid Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rectal Care Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rectasmoothe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rectibiom Hemorrhoid Relief", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Recticare",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Recticare Advanced", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rectiv", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Rectum 6X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rectum Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Red Alder Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Red Cedar Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Red Clover", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Red Cross Canker Sore", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Red Cross Oral Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Red Cross Toothache", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Red Devil Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Red Gecko Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Red Hot", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Red Hot Cinnamon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Red Jasper", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Red Label Ephed Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Red Label Ephedrine Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Red Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Red Maple Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Red Mulberry Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "Red Oak Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Red Quartz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Red Raspberry", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Red Rose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reddot Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reddy Lab Advanced Alcohol Gel Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reddyport Antiplaque Solution", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reddyport Antiseptic Oral Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Reddyport Niv Maintenance Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Buccal" }] }] },
    {
        Medication: "Redefine Triple Defense",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Redeye Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Redi Wipes", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Redicare Cherry Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Redicare Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Redicleanse Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reditrex", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Rediwipes Antiseptic Cleaning Towelettes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Redken 5Th Avenue Nyc Scalp Relief Dandruff Control", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Redness And Itchy Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Redness Reliever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Redness Reliever And Lubricant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Redness Solutions Daily Protective Base Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Redness Solutions Makeup Broad Spectrum Spf 15 With Probiotic Technology", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Redroot Pigweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Redtop Grass, Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Redu Mineral", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reed Canarygrass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Reef Babies Spf 50 Oxybenzone Free",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Reef Kids Spf 30 Oxybenzone Free",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Reef Repair Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reef Repair Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reese Headache Powders", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reeses", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reeses Tabtussin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reface Tox Ampule", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refenesen Mucus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Refill 2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Refill 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refill 4", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refill 6", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Refill Bundle 7 Large Office", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Refined Gou Pi Pain Relieving Plaster", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refl Hs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reflief Saline Laxative Pediatric Ready To Use", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Reflux", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Refresh Antibac Foam", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refresh Celluvisc", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Classic", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Digital", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Digital Pf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Lacri-Lube", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Liquigel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Optive", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Optive Advanced", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Optive Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Optive Mega-3", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh P.M.", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Purebac Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refresh Relieva", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Relieva Pf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Relieva Preservative Free", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Sanitizing Towlette", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refresh Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Tears Lubricant", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Refresh Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refreshed Traveler Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Refreshed Traveler Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Regadenoson", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Regen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Regen-Cov",
        DoseTypes: [
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Regenecare Ha", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Regenereyes", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Regenpure Precision", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Regio Substantiae Nigrae 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Regiocit", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Reglan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Regonol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Parenteral" }] }] },
    {
        Medication: "Regoxidine",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Regranex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Regrowz Homeopathic Scalp Stimulant", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Regrowz Homothetic Restoration Serum", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Regular Strength",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Regular Strength Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Regular Strength Antacid",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Regular Strength Antacid And Gas Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Regular Strength Antacid Antigas",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Regular Strength Antacid Mint",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Regular Strength Antacid/Antigas", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Regular Strength Aspirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Regular Strength Aspirin Ec",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Regular Strength Enteric Coated Aspirin", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Regular Strength Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Regular Strength Pain Relief",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Regular Strength Pain Reliever", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rehydrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rehydrate Jr.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rehydration", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rehydration Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rejuvaflex Natural Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rejuve Sunscreen 100", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rejuven8Tion Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rejuvenate Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rejuvenation Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rejuvenation Secret Serum", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rejuvesol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Rejuvity Moisturizing Day Cream Broad Spectrum Spf 15 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relafen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relafen Ds", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relax Am-Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relax Sanitizing Towlette", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relax-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relaxatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relaxing Leg Cream Homeopathic Pm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relaxing Leg Homeopathic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relaxing Legs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relaxoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relenza", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Releuko", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Relexxii", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reliable E2 Luxury Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reliable Hand So Ap Antibacterial Plum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reliable Luxury Foam Antimicrobial Handwash Citrus Floral Scent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reliable Luxury Foam Antimicrobial Handwash Fresh Fruit Scent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reliaderm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Reliance Hand Saniziter", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Relief",
        DoseTypes: [
            { DoseType: "Enema", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Relief 3-G12", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relief And Cooling", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief C Anti-Bacterial Formula Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief C Hand Sanitizer Unscented For Professionals", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief C Hand Sanitizer Unscented For Professionals (1 Gal)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief C Hand Sanitizer Unscented For Professionals (16 Oz)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief C Hand Sanitizer Unscented For Professionals (2 Oz)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief Chemistry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief Hand Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief Pain Relieving Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief Plus G12", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Relief Soothing Bath Treatment", DoseTypes: [{ DoseType: "Granule, Effervescent", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relief-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reliefor", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relieve And Relax Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relieve Well Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relieve Your Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relieveit Footspray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relieveit Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relieveit Gel Max", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relieveit Regenerating Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relieveit Spraingo Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relispray Antifungal Liquid", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Relispray Antifungal Powder", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Relistor",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Relpax", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reltone 200 Mg", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reltone 400 Mg", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rembrandt", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Rembrandt Deeply White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rembrandt Intense Stain", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Remedi Health Solutions",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }, { Route: "Oral; Topical" }] },
        ],
    },
    {
        Medication: "Remedy Antifungal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Remedy Antimicrobial Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Barrier Cream Cloths", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Calazime Skin Protectant", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Cleansing Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Clear-Aid Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Dimethicone Moisture Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Essentials", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Remedy Nutrashield",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Remedy Pain Relief Cooling Menthol Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Skin Protectant", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Skin Repair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remedy With Phytoplex", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remeron", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Remeronsoltab", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Remeverse Instant Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Remicade", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Remifentanil Hydrochloride", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Remodulin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Ren Clean Favourites", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ren Satin Perfection Bb Sunscreen Broad Spectrum Spf 15 Light-Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rena-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renacidin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Irrigation" }] }] },
    { Medication: "Renaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renagel", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renapar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renapath", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renelix", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rener Lift Multi Action Ultra Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renergie Lift Makeup Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renergie Lift Multi-Action Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renes 5X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renes Argentum Nitricum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renes Betula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renes Betula Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renes Carbo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renes Carbo Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Renes Cuprum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Renes Cuprum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Renes Mesenchyme",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Renes Pulmo 8/8/12 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Renes Veratrum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Renes Veratrum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renew Apothecary Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renew Apothecary Lavender Sage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renew Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renew Periprotect", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renewal Firming Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renewal Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renewal Hgh Advanced", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renewed Hope In A Jar Full Cover Tint Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renewed Hope In A Jar Skin Tint Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renewed Hope In A Jar Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renflexis", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Reninum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reninum 4Ch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renova", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renown Antibacterial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renown Antibacterial Foam Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renown Antibacterial Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renown Antibacterial Plum Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renown Antimicrobial So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renstat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Renu Laboratories Acne Sulphur L", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renu Laboratories Drying", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renu Laboratories Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Renu Laboratories Isopropyl Alcohol 75% Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Renvela",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Repaglinide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Repair", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Repairwear", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Repairwear Laser Focus All Smooth Makeup Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Repairwear Laser Focus Line Smoothing Broad Spectrum Spf 15 -Very Dry To Dry Combination", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Repairwear Uplifting Firming", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Repatha",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Repellent", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Replenix Acne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Replenix Acne Solution Benzoyl Peroxide Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Replenix Acne Solutions Gly-Sal 2-2 Foaming Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Replenix Acne Solutions Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Repuera Dermatozyme 5% Gauze Dressing Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Repuera Dermatozyme 5% Hydro Essential Ampule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Repuera Dermatozyme 5% Ultra Moisture Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Res Balancer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescon", DoseTypes: [{ DoseType: "Tablet, Multilayer", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescon Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescon Gg", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rescue",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rescue 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescue Breather Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rescue Energy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescue Pastille Black Currant", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescue Pastille Lemon", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescue Pastilles Cranberry", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescue Pearls", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescue Remedy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rescue Remedy Kids", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rescue Sleep",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rescue Sleep Kids", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reset U", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Resfridol Antitussive", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Resilience Multi-Effect Tri-Peptide Face And Neck Creme Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Resilience Multi-Effect Tri-Peptide Face And Neck Creme Broad Spectrum Spf 15 Dry Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Resina Laricis Bath Milk", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Resinol", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Resist Wrinkle Defense Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Resp Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respiforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respiforce A", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respiforce A-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respiforce I Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respiratory Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respiratory Syncytial Virus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respiratory Upper Terrain", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respiro-Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respitory Tonic F", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respitrol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Respo-K", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Responsible", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Responsible Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Responsible Ppe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Rest", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rest Simply",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Restasis", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Restasis Multidose", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Restful Legs", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Restful Legs Pm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Resting Beach Face Spf30 Anti-Pollution Sunscreen Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Restless Leg",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Restless Leg Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Resto L", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Restoral", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Restoration Treatment", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Restorative Day Moisture Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Restorative Formula Lubricant Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Restore",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    {
        Medication: "Restore Antibacterial Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Restore Lidocaine Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Restore Plus Single Vial", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Restoril", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Restoring Anticavity", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Restorsea Rejuvenating Day Spf 30 Broad Spectrum Uva Uvb With Vibransea Complex", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Restulex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Resurfix Elemental Silver Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Resurfix Plus", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ret Bladder", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Gallbladder", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Heart", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Kidney", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Large Intestine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Liver", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Lung", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Pituitary/Sex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Small Intestine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Spirit/Metaphysical", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Spleen/Pancreas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Stomach", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ret Thyroid/Adrenal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Retacrit", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Retail Foaming Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Retail Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Retaine Hpmc", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "Retaine Mgd", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Retaine Pm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Retavase", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Retevmo", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rethymic", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Reticuloendothelial System 8 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Retin-A",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Retin-A Micro", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Retisert", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Intravitreal" }] }] },
    {
        Medication: "Retreat",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    {
        Medication: "Retrovir",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Revalife Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Revatio",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Revcovi", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Reverse Brightening Defense Fluid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revitaderm Psoriasis", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revitaderm Wound Care", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revitalizing Supreme Plus Global Anti-Aging Cell Power Creme Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revive 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revive 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revive Fermitif Neck Renewal Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revive Science Eczema", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revive Science Natural Hemp Pain Relief Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlimid", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Revlon Colorstay Light Cover Foundation", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlon Colorstay Liquid Makeup For Normal To Dry Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlon Colorstay Liquid Makeup For Oily To Combination Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlon Colorstay Preo And Protect Primer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlon Kiss Balm Spf 20", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlon Mitchum Clinical Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlon Photoready Bb Cream Skin Perfector", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlon Photoready Insta-Fix Makeup", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revlon Youth Fx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Revonto", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rexall", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Alcohol Prep", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rexall All Day Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Antacid Advanced", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Antacid Flavor Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rexall Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rexall Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Bismuth", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Daytime Nighttime Cold Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Rexall Dm Cough And Congestion Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Earwax Removal", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Rexall Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Rexall Hemorrhoidal Cooling Gel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rexall Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rexall Ice Cold Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rexall Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rexall Medicated Wipeshemorrhoidal Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rexall Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Rexall Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rexall Night Time Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Ready To Use 4Pk", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Rexall Restore And Defend Sensitive Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Rexall Sensitive Extra Whitening", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Rexall Sensitive Freshmint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Rexall Sinus Wash System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Rexall Sleep Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rexall Sterile Eye Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rexallear Relief Ear Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Rexulti", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Reyataz",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Reynoutria Lyme", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Reyvow", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rezamid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rezipres", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rezurock", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rezvoglar", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Rfl Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rgo Daily Essence Sun", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rgo Hydration Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rgo Nutriment Rich Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rgo Tension Up Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rgo Tension Upcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rheum Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheum Officinale", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheuma", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rheuma Dks Special Order", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rheuma Pain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheuma Pain Ii Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumagesic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumatic Pains", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumatic Pains 1", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumatic Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rheumatic-Joint Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Rheumatism",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rheumatism Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumatism Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumatism Laryngitis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumatism, Nausea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rheumatsm", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rhinallergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rhinallergy Kids",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rhinase D Gel Moisturizing Nasal Decongestant", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Rhino Long Power",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Rhinocort Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Rhizopus", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Rhizopus Arrhizus Var Arrhizus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Rhizopus Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Rhizopus Oryzae", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Rhizopus Stolonifer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Rhodochrosite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rhododendron Chrysanthum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rhododendron Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rhodonite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rhodotorula Mucilaginosa",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Rhodotorula Rubra", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Rhofade", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rhogam Ultra-Filtered Plus", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Rhophylac", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Rhopressa", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "Rhuli Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rhumatol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rhus Aconitum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Rhus Tox",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Rhus Tox 4X / 8X / 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rhus Tox Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rhus Tox Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rhus Tox. 10%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rhus Tox. E Fol. 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rhus Tox. E Fol. 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rhus Toxicodendron",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Rhus Toxicodendron 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Rhus Toxicodendron Mt", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rhus Venenata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Riabni", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Riastap", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rib Cage Pain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ribavirin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ribeskin Epiderm Plus Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ribotin-E", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rice", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Rice Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Ricetsotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rich Girl", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Richenna Pharm Hand Sani Fluid Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ricinus Communis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rico Hand Sanitizer", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rico Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ricola Max Nasal Care Cool Menthol", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ricola Max Throat Care Cool Peppermint", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ricola Max Throat Care Honey Lemon", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ricola Max Throat Care Swiss Cherry", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ricura", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rid Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rid Lice Treatment Complete Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ridaura", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rifabutin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rifadin Iv", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rifadin Iv Novaplus", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Rifampin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Right Guard Antiperspirant",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Right Remedies Adult Long Lasting Cough Relief Softgel", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Right Remedies Allergy Relief", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Right Remedies Aspirin Low Dose 81 Mg", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rightcare Alcohol Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rilakkuma Aloe Vera Scented",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Rilutek", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Riluzole",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rimantadine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rimmel Bb Cream Beauty Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rimmel Bb Cream Beauty Balm Matte 9-In-1 Skin Perfecting Super Makeup Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rimmel Lasting Breathable Foundation Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rimmel London Lasting Finish 25 Hr Foundation Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rimso-50", DoseTypes: [{ DoseType: "Irrigant", Routes: [{ Route: "Intravesical" }] }] },
    { Medication: "Ring Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ring Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ring Relief Pm Nighttime Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    {
        Medication: "Ringers",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] },
        ],
    },
    { Medication: "Ringers Irrigation", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Irrigation" }] }] },
    { Medication: "Ringing In Ears", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ringl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ringworm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ringworm Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rinosan Mint Nasal Decongestant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Rinosan Propo Nasal Decongestant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Rinse", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rinse-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rinvoq", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Riomet", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ripe Raspberry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rise Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Risedronate Sodium",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Risperdal",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Risperdal Consta", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Risperdal M-Tab", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Risperidone",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ritalin",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Rite Aid",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rite Aid Advanced Scar Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Advanced Sterile Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rite Aid Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Athletes Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Capsicum Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Cherry Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Cold And Hot Medicated", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Cool Therapy Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Cooling Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Decolorized Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Digestive Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Fast Relief Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Rite Aid Fiber Caplets", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rite Aid Fiber Therapy",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rite Aid Herbal Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Hot And Cold", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Hot And Cold Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Laxative Saline", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Rite Aid Lubricant Drops",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Rite Aid Maximum Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Mixed Berry Sore Throat And Cough Lozenge", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Multihealthfiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Natural Fiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Original Sterile Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rite Aid Original Strength Vagicaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Pain Relief Cream With Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Pharmacy Congestion Relief Vaporizing Steam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Rite Aid Pharmacy Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Pharmacy Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Pro Defense", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Rite Aid Radiant White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Rite Aid Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Rite Aid Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Tooth And Gum Pain Relief", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Ultra Zinc Cold Therapy", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rite Aid Wild Berry Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rite Aid Zinc Cold Therapy",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rite Aid Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite Aid Zinc Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rite-Aid", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Riteaid Black Elderberry Cold And Flu Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Riteaid Nighttime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ritonavir",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ritual Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ritual Tattoo",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ritual Tattoo Reveal Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rituxan", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rituxan Hycela", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Rivastigmine",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Rivastigmine Tartrate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rivastigmine Transdermal System", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Rivelsa", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "River Birch Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "River/Red Birch Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Riversol Comprehensive Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Riversol Spf 40 Weightless Body Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rixubis", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Rizatriptan", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rizatriptan Benzoate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rizatriptan Benzoate Odt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rize Hair Regrowth Treatment For Men 5%", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rna Beauty Lab Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Road To Wellness", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Robafen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robafen Cough", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robafen Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robanda Anti-Aging Hand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Robaxin", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Robinia Nicotiana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Robinia Pseudoacacia",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Robinia Pseudoacacia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Robinul",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Robinul Forte", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin 12 Hour Cough Relief", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Cough Plus Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Elderberry Maximum Strength Cough Plus Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Honey Maximum Strength Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Honey Maximum Strength Nighttime Cough Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Honey Severe Cough, Flu Plus Sore Throat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Honey Severe Cough, Flu Plus Sore Throat Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Long-Acting Coughgels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Maximum Strength 12 Hour Cough And Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Robitussin Maximum Strength Cough Plus Chest Congestion Dm",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Robitussin Maximum Strength Nighttime Cough Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Maximum Strength Severe Cough Plus Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Maximum Strength Severe Multi-Symptom 7 In 1 Relief Nighttime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Peak Cold Multi-Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Severe Multi-Symptom Cough Cold Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Severe Multi-Symptom Cough Cold Flu Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robitussin Sugar-Free Dye-Free Cough Plus Chest Congestion Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robo Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robohbr Cough Suppressant", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Robotablets", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Roc Multi Correxion 5 In 1 Chest, Neck And Face Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roc Multi Correxion 5-In-1 Daily Moisturizer With Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roc Multi Correxion Hydrate And Plump Daily Moisturiser Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roc Multi Correxion Lift Anti Gravity Day Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roc Retinol Correxion Deep Wrinkle Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roc Retinol Correxion Wrinkle Correct Daily Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rocainol Topical Analgesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rocaltrol",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rock Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rockhomo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rocklatan", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "Rocksauce Fiery Hot Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rocksauce Icy Cold Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rocky Mountain Juniper Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Rocky Mountain Spotted Fever Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rocky Mountain Spotted Fever Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rocky Mtn. Juniper Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Rocuronium", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Rocuronium Bromide",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Rodan - Fields Essentials Body Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rodan And Fields Enhancements", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rodan And Fields Essentials Lip Shield Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rodan And Fields Redefine Am", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rodan And Fields Reverse 4 Broad Spectrum Spf 50 Plus Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rodan Fields Soothe 4 Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roderm Protecting Spf 28 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rodial Broad Spectrum Spf 50 Drops Anti-Blue Light And Pollution", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rohto", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rohto Beauty Secret", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rohto Digi-Eye", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rohto Dry-Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rohto Jolt", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rohto Optic Glow", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rok Fulvic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rolaids Advanced Antacid Anti-Gas Mixed Berry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rolaids Advanced Softchews Mixed Berry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rolaids Extra Strength Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rolaids Extra Strength Mint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rolaids Regular Strength Mint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rolaids Ultra Strength Antacid Strawberry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rolaids Ultra Strength Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rolaids Ultra Strength Mint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Roll On Anti Perspirant", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roll On Anti-Perspirant (Alcohol Free)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roll On Anti-Perspirant Deodorant", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roll-On Antiperspirant Deodorant - Scented", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rolling Farmacy", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Roman Broad Spectrum Spf 28", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roman Swipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Romidepsin",
        DoseTypes: [
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Rompe Pecho", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rompe Pecho Max Multi Symptoms", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ron 2", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Rongbang Bzk Cleansing Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rongbang Bzk Cleansing Swabstick", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roosin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roosin Burn", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roosin Neomycin Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roosin Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rootly Extra Strength Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rootly Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roots Aromatherapy", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roots Hand Sanitizer Gel, Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roots Hand Sanitizer Gel, Fragranced", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Roots Professional - Topical Solution Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rootsallo Advanced Scalp Care Hair", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rootsallo Toothpaste", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ropidex", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Epidural; Infiltration; Intramuscular; Intravenous; Perineural; Topical" }] }] },
    {
        Medication: "Ropinirole",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ropinirole Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Ropivacaine Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Epidural" }, { Route: "Epidural; Infiltration" }, { Route: "Epidural; Infiltration; Perineural" }, { Route: "Epidural; Perineural" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural" }, { Route: "Epidural; Infiltration" }, { Route: "Epidural; Infiltration; Perineural" }, { Route: "Epidural; Perineural" }] },
        ],
    },
    { Medication: "Rorobell Wawa Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rory Broad Spectrum Spf 28", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rosacea",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Rosacea Free", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rosadan",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Rose All Day Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rose Copper", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rose Copper Viscum Dks", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rose Iron Graphite",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Rose Quartz",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Rose Rush Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rose Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rose Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosease By Anna Lotan Gentle Daytime Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosehip And Lemongrass Lip Spf 15", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosemary And Salicylic Acid Acne Clearing Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosemary Mint Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosewill Hygiene Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosilliance Medium/Dark", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosmarinus Argentum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rosmarinus E Fol. 1% Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rosmarinus Ex Herba 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rosmarinus Lavandula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosun Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosun Hand Sanitizer Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosun Hand Sanitizer Floral", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rosuvastatin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rosuvastatin And Ezetimibe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rosuvastatin Calcium",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Roszet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rotarix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Rotateq", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rotop - Dmsa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rough And Smooth Heavy Duty Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rough Marshelder Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Rough Redroot Rough Redroot", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Roundaround Grrentea Cica Sun Zinc Oxide", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rowasa", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Roweepra", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Roxalia", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Roxicodone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Roxybond", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Royal 70-Percent Alcohol Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Alcohol Cleansing", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal And Ancient Suncreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Antibacterial Foam Hand Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Boost Cover Me Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Creamy Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Creamy Petroleum Cocoa Scent", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Creamy Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Creamy Petroleum Jelly Cocoa Butter", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Defy Multi-Performance Age Protector Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Epsom Salt", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Royal Ginseng Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Ginseng Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Ginsengcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Ginsenglotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Jelly Berry Powerful Lip Care", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Jelly Body Hand And Cuticle Complex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Jelly Hand Complex Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Jelly Scar Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal King Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Men 3-In-1 Face Moisturizer Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Men Sample Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Non-Alcohol Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Oils By Head And Shoulders Moisture Boost 2In1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Oils By Head And Shoulders Moisture Boost Scalp Care", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Oils By Head And Shoulders Moisture Renewal Scalp Balancing Conditioner", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Oils By Head And Shoulders Moisturizing Hair And Scalp Co-Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Oils By Head And Shoulders Sulfate Free Scalp Care", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royal Whitening Pre-Brush", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Royalab Germ Away Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Royalcare Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rozerem", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rozlytrek", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rpt-Sys", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rrub Body Sanitizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rrub Rinse Free Hand Wash-Alcohol Version", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rrub Rinse Free Hand Wash-Non Alcohol Version", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ru-21 Hangover Relief", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ru-Hist D", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rubber Ducky Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubber Gloves/Latex Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rubbing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubbing Alcohol 50%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubbing Alcohol 70%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubbing Alcohol 70% Isopropyl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubbing Dots Natural Cool Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubelli Beauty Face Premium", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubelli Black Pearl Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubelli Toe Socks Foot Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubelli Water Glow Essence", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rubraca", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rubus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ruby-Fill", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ruconest", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rue21 Blush Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rue21 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rue21 Icon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rue21 Pink Ice Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Rufinamide",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Rugby",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Rugby Acetaminophen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rugby Acne Medication Benzoyl Peroxide", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Adult Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby All Day Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Almacone", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Antifungal Clotrimazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Arthritis Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Rugby Benzoyl Peroxide",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Rugby Benzoyl Peroxide Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Cal-Gest Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Calcium Carbonate 10 Gr", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Caldyphen Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Capsaicin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Capsaicin Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Cetirizine Hydrochloride And Pseudoephedrine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Chest Congestion Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Childrens Acetaminophen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Clotrimazole Antifungal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Cough Syrup", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Eye Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rugby Famotidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Fluticasone Propionate Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Rugby Gas Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Rugby Hemorrhoidal", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Rugby Hydrocortisone With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Ice Blue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Lubricating Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rugby Lubricating Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rugby Meclizine Hcl", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Menthol Hot Cold Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Mucus And Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Mucus Relief D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Mucus Relief Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Muscle Rub Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Nicotine Polacrilex Gum, Coated Fruit Flavor", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Rugby Nicotine Polacrilex Gum, Coated Mint Flavor", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Rugby Nicotine Polacrilex Gum, Mint Flavor", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Rugby Nicotine Polacrilex Gum, Original Flavor", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Rugby Pain Relief Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Peg 3350", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Petrolatum Hydrating", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Regular Strength Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Tetrahydrozoline Ophthalmic Solution", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Rugby Tolnaftate 1% Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Travel Sickness", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rugby Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugby Zinc Oxide 20%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugid Itch And Pain Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugid Outdoors Hand Sanitizer Benzalkonium Chloride", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rugid Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rukobia", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rumex Acetosella Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Rumex Crispus",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Rumex Crispus Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rumex Crispus Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Runny Nose", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Runny Nose Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Runny Nose Warts", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rush Salicylc Acid Complexion Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rushing Fahs", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Russian Olive Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Russian Thistle", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Russian Thistle Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Rust, Wheat", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Rut Hair Ageless Clinic Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rut Hair Ageless Clinic Plus", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rut Hair Ageless Clinic Treatment Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ruta Grav", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ruta Graveolens",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Ruta Graveolens 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Rutin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ruxience", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rvr90 Brighten", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rvr90 Clear", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rvr90 Hydrating", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Rvr90 Retexturize", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Rx Act Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rx300 Topical Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ryaltris", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Ryanodex", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rybelsus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rybrevant", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ryclora", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rycontuss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rydapt", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rye Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Rye Grain", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Rye Grass, Perennial Standardized", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Rylaze", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Rymed", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rynex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rynex Pe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rynex Pse", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ryplazim", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Rytary", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Rythmol", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ryvent", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "S Gvd Phytoncide",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "S Panax All In One Liquidsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S Panax All In One Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S Panax Liquidsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S Panax Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S Panax Mist For Refill", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Cream Clotrimazole Cream 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Cream Hydrocortisone 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Cream Miconazole Nitrate 2%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Cream Tolnaftate 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Cream Zinc Oxide 13%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Oint Antiseptic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Oint Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Oint Diaper Rash And Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Oint Hydrocortisone 1%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Oint Proderm Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S-Oint Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S.O.S Repair Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "S.T.37", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "S01", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "S02", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "S12", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "S2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "S9 Soseang-Su", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sabadilla",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sabadilla Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sabal", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sabal Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sabal Populus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sabal Serrualata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sabal Serrulata",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sabal Serrulatum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sabina",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sabina Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sabril",
        DoseTypes: [
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Saccharinum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Saccharomyces Cerevisiae",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Saccharum Lactis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Saccharum Officinale",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sacral Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Saerasu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safco Sensicaine Ultra Topical Anesthetic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Safe And Soft Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe And Soft Antibacterial Hand Wipes 15 Count", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Choice Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Cleaner Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Cleaner K", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Safe Cleaner Plus",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Safe Good Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Hand Clean Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Hand Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Hand Sanitizer Aloe And Moisturizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Harbor Hand Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Harbor Natural Suncare", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Housekeeping Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Housekeeping Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Housekeeping Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Opa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Sea Spf 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Sea Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Sea Spf 50 Kids", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Sense Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Warrior Instant Hand Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safe Warrior Instant Hand Sanitizer With Moistrurizers And Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Safe Warrior Instant Hand Sanitizer With Moisturizers And Aloe Vera",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Safeguard",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Safeguard Antibacterial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeguard Hand Sanitizer Fresh Clean Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeguard Moisturizing Hand Sanitizer Notes Of Citrus Bloom", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeguard Moisturizing Hand Sanitizer Notes Of Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeguard Moisturizing Hand Sanitizer Ocean Breeze", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Safehands Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Safehands Hand Sanitizer Clean Linen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safehands Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safehaven Personal Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safelite Autoglass Touch Point Sanitization Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safesha", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeshield Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safetussin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Safety 1St Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safety 1St Oh Sh T Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Safety 1St Social Safety Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Safety Coated Aspirin 81 Mg Low Dose", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Safety First 70% Ethanol Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safety First Hand Sanitizer 70% Etoh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safety Matters Hand Sanitizer Soothing Gel Infused With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safetynadol", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Safever Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeway Acid Reducer", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Safeway Instant Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeway Signature Care Dry Touch Sunscreen Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeway Signature Care Kids Continuous Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeway Signature Care Sport Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safeway Signature Sport Continuous Sunscreen Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Safrel Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Safyral", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sage And Zinc Facial Hydrating Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sage Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Sahi Cosmetics", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer Eight", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer Five", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer Four", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer Nine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer One", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer Seven", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer Six", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer Three", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Slip Cover Broad Spectrum Spf 35 Sunscreen Tinted Moisturizer Two", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saie Sunvisor Broad Spectrum Spf 35 Sheer Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saiveroy Alcohol Disinfectant Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saiveroy Alcohol Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saizen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Saizenprep", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Sajazir", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Sal De Uvas Picot", DoseTypes: [{ DoseType: "Granule, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Salagen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Salex",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Salico", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylacne-1% Clearing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylacne-2% Clearing Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Salicylic Acid",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Plaster", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Salicylic Acid 6 Percent", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylic Acid Corn Remover", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylic Acid Cotton Sheet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylic Acid Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylic Acid Wart Remover", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylic Face And Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylic Iodine (Iodo Salicilico)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylic Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salicylicum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Saline", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Saline Enema", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Saline Laxative",
        DoseTypes: [
            { DoseType: "Enema", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Saline Laxative Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Saline Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Saline Nasal Moisturizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Saline Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salinocaine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saliskin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salix Nigra", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Salix Nigra Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Sally Hansen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sally Hansen Microwavable Eybrow, Face And Lip Wax Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sally Hansen Ouch-Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sally Hansen Ouch-Relief Numbing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sally Hansen Ouch-Relief Stripless Hard Wax Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sally Hansen Ouch-Relief Wax Strip Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sally Hansen Zero Bump Bikini", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Salmon",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Salmonella", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Salon Technologies International",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Saloncentric Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saloncentric Take Care Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Salonpas",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Salonpas Arthritis Pain", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Salonpas Deep Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Salonpas Lidocaine Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    {
        Medication: "Salonpas Lidocaine Plus",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] },
        ],
    },
    { Medication: "Salonpas Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Salonpas Pain Relieving Jet", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Salonpas Pain Relieving Lidocaine 4% Flex", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Salonpas-Hot Capsicum", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saloon Antibacterial Hand Wash Citrus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saloon Antibacterial Hand Wash Eucalyptus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saloon Antibacterial Hand Wash Ocean", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Salsalate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Salsola Kali Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Salsolinol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Salt And Stone Natural Mineral Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salt And Stone Natural Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salt And Stone Spf 30 Sunscreen Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salt And Stone Stussy Spf 50 Tinted Sunscreen Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salt And Stone Sunscreen Lip Balm Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salt And Stone Tinted Sunscreen Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salt Cedar Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Salt Cedar Tamarisk Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Salt Grass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Salted Pecan Caramels Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saltrain Clean Breath", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Saltrain Tooth",
        DoseTypes: [
            { DoseType: "Paste", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Saluja Md Essential Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salvax", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Salvia Officinalis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Salvia Officinalis (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sambrosa", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sambucol Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sambucus Flucare",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sambucus Larix",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sambucus Nigra",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sambucus Nigra (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Same Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sams West", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Samsca", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "San E 2 Hand Wash 6265", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "San Skinlab Cloth Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "San X E2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "San X E3", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "San-A-Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "San-Ez Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "San-It-E Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "San-X E2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "San-X E3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanacare", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanaflu Xtra", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanamax Menthol Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanativa Dry Skin Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanatos Boost", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Children Cough And Congestion Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Childrens Mucus Relief Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Day And Night Multi Symptom", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Mucus Relief Cold Toddlers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Multi Symptom", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Multi Symptom Daytime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Nighttime Multi Symptoms", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Severe Cold And Cough Day Time", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Severe Cold And Cough Nighttime", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos Turbo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanatos X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sancochito Compuesto", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sancuso", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Sand Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sandbur Ragweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Sandimmune",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sandisizer 120", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sandostatin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Sandostatin Lar Depot", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sandstone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sandy Beige Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sanell Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sanetto", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanguinaria Can", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sanguinaria Can Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sanguinaria Canadensis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    {
        Medication: "Sanguinarinum Nitricum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sani", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Choice Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Claw Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Clean Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sani Eze",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sani Foam Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Foam Mango Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sani Gel",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sani Luxe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Sani Luxe Foaming Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Sani Luxe Hand Sanitizer With Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Sani Mango Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Pro Sani-Hands Antibacterial Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Professional Brand Sani-Hands", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Professional Brand Sani-Hands Basics Bzkhand Wipes Hand Wipes Hand Wipes Hand Wipes Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Professional Brand Sani-Hands Basicshand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Professional Brand Sani-Hands For Kidshand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Professional Hands Instant Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Spire Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Spritz Ii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Stix", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani T Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Wash Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Wash Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani Wipes Instant Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Cardz", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Care", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Com", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Sani-Foam Foaming Non-Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Health Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Kure Extra Strength Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Lotion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Professional Sani-Hands", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Spray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-Spritz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-X Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sani-X Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanibeads", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanicol Advanced Aniseptic Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saniderm Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sanidry",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sanidry Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanidry Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sanifoam Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sanigel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanigen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanigen Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saniguard-Sf", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanikit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saniluxe Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Saninta 10% Povidone-Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saninta Antiseptic Wipe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saninta Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sanipro Disinfectant Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sanipure Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saniquick", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saniquick Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanirus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanispire Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanistation Hand Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanisuds Antibacterial Foam", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanisuds Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanit Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanit Hand Sanitizer Advanced Formula", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitalk - Sanitizer(Covid-19)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitalk Green - Fruit ,Vegetable", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitaxe 27 Oz Instant Hand Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitaxe Antibacterial", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitaxe Antibacterial Amber Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitaxe Antibacterial Amber Hand 27 Fl Oz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitaxe Instant Hand Sanitizer With Aloe Vera 27 Fl Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitect Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitect Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Advanced Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Advanced Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Advanced Hand Sanitizer With Macadamia Oil", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Hand Sanitizer Aroma Mango", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Hand Sanitizer Aroma Of Coffee", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Moisturizing Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitelle Moisturizing Hand Sanitizer With Macadamia Oil", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitex 60", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitex Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitfresh Antiseptic Hand Sanitizer 1 Gallon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitfresh Antiseptic Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitising Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitize Those Hands 40 Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitize With Purpose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sanitizer Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Hand", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Hand And Skin Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Solution, Formulation One", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Station Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Station Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Wipe In Sachet", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizer Wipes 70% Ethyl Alcohol", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizerspray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizerx Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizetek", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizetek Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Alcohol Free Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Alcohol Free Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Body", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Hand Sweet Peach Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Hand Wipe Mitts", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sanitizing Wet Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sanitizing Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sanitizing Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sanitizing Wipes With Aloe Grapefruit Grove", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitizing Wipes With Aloe Lavender Field", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanitol Antiseptic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanityze", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanivir Gel Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanivir Hands, Body, And Surfaces Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saniwash Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saniwash Antimicrobial Foaming Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanix Alcohol Hand Rub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanizen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanizer Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanizer Kids Multi-Purpose Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sankaijo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sankind Hand Sanitizer 75 % V/V Isopropyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanmed Healthcare Hand Sanitizing Gel Handrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanmed Healthcare Hand Sanitizing Handrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sannytize Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sannytize Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sano Ti Amo Relief And Repair Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanotis Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sanotis Sanitizer Hand",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sansostory Shc2000", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Santi-Gel Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Santigel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Santizall Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Santo Remedios Piel Eterna Radiance Sunscreen Primer Spf-42", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Santoninum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Santorini Hand Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Santorini Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Santotizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Santotizer E80- Fragrance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanugel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanxiderm Anti-Bacterial Hand Gel 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sanziderm Antiseptic Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saphnelo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Saphris", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Saponaria Officinalis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sapphire", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sapphire 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sapropterin Dihydrochloride",
        DoseTypes: [
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sarasota Memorial Amenity", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sarclisa", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sarcolacticum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sarina", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sarna", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sarna Whipped Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sarocladium Strictum", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Sarothamnus Aurum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sarothamnus Aurum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sarracenia Purpurea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sars Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sarsaparilla",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sarsaparilla Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Satin Foam High Foaming Antiseptic Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satin Foundation Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satinique Anti Dandruff", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satinique Anti-Dandruff For Dry, Irritated Scalp", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sato Clear", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Satogesic", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satogesic Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Satohap",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Satohap Cool And Hot Pads", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satohap Lidocaine 4% Menthol 1% Pain Relieving Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satohap Lidocaine 4% Pain Relieving Pad", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satohap Lidocaine Hydrochloride 4 Percent Pain Relieving", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satos Corn And Callus Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satsuma Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Satya Organic Fragrance-Free Eczema Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sauber Kare 1 Gallon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sauber Kare 500Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Savaysa", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Save Global", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Save Me Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Savella",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Savie Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saviour Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saviour Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Savon De Provence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Savon De Provence Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Savvy Wellness", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Savvycare", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sawyer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Saxenda", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Sazerac Company Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sbs 40 Medicated Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sbs 41 Medicated Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sc Health Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sc Health Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sc Herbal Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scalar Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scale Atriplex Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Scallops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Scalp", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scalp Revival", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scalpanumb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scalphair Mistsunscreen Spf30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scalpicin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scalpmed For Men - Vitadil-5A Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scalpmed For Women - Vitadil-2A Hair Regrowth Treatment 2%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scandonest 2% L", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Scandonest 3% Plain", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Scantily Pad", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scar Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scar Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Scar Gel",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Scar Reducer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scars-Adhesions", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scarstat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Scarzen",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Scemblix", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scenesse", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Scent For Good Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scent Killer Gold", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scent Theory - Keep It Clean Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scent-A-Way Odor Control Max", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scented Anti-Bacterial Hand Sanitizers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scented Hand Sanitizer - Cherry Blossom Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scented Hand Sanitizer - Citrus Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scented Hand Sanitizer - Strawberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scented Hand Sanitizer - Vanilla Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scentful Good Vibes Only Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scentful Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scentful Love Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scentful Self Love Club Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scentfull Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scentfull Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scentia Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scentia Pro Care And Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Schein Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Schein Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Schein Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Schinus Molle Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Schizophrenia Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Schnucks Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Scholls Wellness Company Llc",
        DoseTypes: [
            { DoseType: "Disc", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "School Health", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "School Health Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "School Health Sterile Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Schusslers Immune", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sciatic Aide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Sciatic Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sciatica", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sciatica Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sciatica Relief",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sciatica Rheumatism", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sciatica Therapy", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sciatica-Back Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sciatica-Nerve Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sciaticaide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual; Sublingual" }] }] },
    { Medication: "Scienceware Eyewash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Scilla Crataegus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Scilla Maritima",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sclera Care Topical Anesthetic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scleracare Lidocaine Hcl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scolopendra", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scope", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scope All Day Cpc Antigingivitis/Antiplaque Mouthwash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Scopolamine",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "System", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Scopolamine Trandermal System", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Scorodite 6X Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Scorodite Prunus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Scorodite Saccharum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scot-Tussin Diabetes Cough Formula With Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scot-Tussin Dm Maximum Strength Cough Suppressant And Cold Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scot-Tussin Expectorant Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scot-Tussin Senior Maximum Strength Cough Suppressant And Expectorant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scott Antimicrobial Foam", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scott Antimicrobial Foam Skin Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scott Antiseptic Foam Skin Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scott Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scott Gel Hand Sanitizer With Moisturizers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scott Moisturizing Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scott Moisturizing Foam Hand Sanitizer (Ultra)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scout Spf-50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Screamin Hot Toast", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Screamin Menthol Toast", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scrofularoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Scrophularia Nodosa",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Scrophularitone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Scrophulous", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Scrub Care",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Scrub Care Povidone Iodine Cleansing Scrub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scrub So Clean Acne-Fighting Super Scrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scrub, Scrub-Stat, Foam Safe, Micro-Guard", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scrub-A-Dub", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scrub-In Surgical Scrub Brush/Sponge", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scrub-Stat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Scrubs Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Scrubs Hand Sanitizer Towelette", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Scrubs Hand Sanitizer Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Scrubs Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] }] },
    { Medication: "Scrubs Sunscren", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Scullys Hand Sanity Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Scutellaria Lateriflora",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sd Kleen Rx Antiseptic Hand Rub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sd Kleen Rx Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sd Naturals Hand Sanitizer Fragrance Free",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Sd Naturals Hand Sanitizer Fresh Citrus",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Sd Naturals Hand Sanitizer Lemon Lavender",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Sd Naturals Hand Sanitizer Mint Twist",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sdara Skincare Mineral Sunscreen Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sdg High Level Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Se Sport Endurance Pain Freeze Recovery", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Se-Natal 19", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Se-Tan Plus", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sea And Ski Advance Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Advanced Hand Sanitizer 8Oz", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Coolest Burn Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Kids Spf 70 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Spf 15 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Spf 30 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sea And Ski Spf 50",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sea And Ski Spf 50 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Spf 50 Sport", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Spf 50 Sport C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Spf 70 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Spf 70 Kids", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Tanning Lotionspf 4", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea And Ski Tanning Oil Spf 4", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea Salt Anticavity Salt47", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sea Set And Protect Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea Star Sparkle Spf 50 Glitter Sunscreen Coco Lime", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea Star Sparkle Spf 50 Glitter Sunscreen Cotton Candy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea Star Sparkle Spf-50 Mango-Tango", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea Star Sparkle Spf-50 Party Cake", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sea Star Sparkle Spf-50 Very Berry", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Seacret",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Seacret Soothing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seal-D All Day Protection Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seamoms Tamanu Oil Mist", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seasonal Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Seasonal Itchy And Red Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Seasonique", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Seavenger Ocean Kiss Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seavenger Ocean Kiss Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seaweed Bath Co Cooling Mist Spf-60 Sport", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seaweed Bath Co Everyday Mineral Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seaweed Bath Co Spf 40 Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seaweed Bath Co. Spf 50 Sport", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seaweed Bath Co. Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seaweed Oil-Control Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sebastian Purist Antidandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sebex", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seborasershampoo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secale Argentum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Secale Argentum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Secale Betula",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Secale Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Secale Cornutum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Secale Cornutum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Secale Nicotiana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Secale Nicotiana Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Secale Quartz",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Second 20 Y-Zone Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Second Chakra (Sacral)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Secret", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Antiperspirant", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Antiperspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Active Soft Sport Fresh", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Clear Clean Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Clear Stress Response", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Invisible", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Smooth Mean Stinks Fearlessly Fresh", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Soft Clean Lavender", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Soft Light And Fresh", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Soft Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Soft Sport Fresh", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Soft Stress Response", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Clinical Strength Soft Waterproof", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Cocoa Butter Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Cucumber Water Invisible Solid", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Derma Plus Antiperspirant Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Derma Plus Antiperspirant Even Tone", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Derma Plus Antiperspirant Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Derma Plus Antiperspirant Nourishing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Fresh For You Luxe Lavender", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Freshies On-The-Go Invisible Lavender", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Freshies On-The-Go Invisible Rose", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Green Tea Invisible", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Invisible", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Invisible Shower Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Invisible Spring Breeze", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Invisible Tropical Orchid", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Invisible Unscented", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Lavender Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Muse Vita Blue Sun", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Nectarine Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Outlast Xtend Technology Invisible", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Plus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Pomegranate Invisible Solid", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Roll-On", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secret Vanilla Clear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secrete Plus Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secretin Cycle I Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Secretin Cycle Ii Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Secuado", DoseTypes: [{ DoseType: "Film, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Secura Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secura Dimethicone Protectant", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secura Epc Skin Care Starter Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Secura Extra Protective", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secura Extra Protective Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secura Moisturizing Cleanser", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secura Personal Cleanser", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secura Personal Skin Care Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Secura Protective",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Secura Starter Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Secura Total Body Foam Cleanser", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secura Two Step Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secure Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secure Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Secure Personal Care Products Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Securitas Hand Sanitizer 15Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sed-Max", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sedalmex", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sedalmex Xl Flu", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sedum Acre", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "See Clean-One Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Seedalmerk", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Seedlings Diaper Rash Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seeme Beauty Moisturizer With Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seglentis", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Segluromet", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sei Bella Age-Defying Liquid Foundation", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sei Bella Cc Brightening Creme", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sei Bella Flawless Liquid Foundation", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sei Bella Tinted Bb Moisturizing Creme", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seizalam", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Sekkisei Treatment Bb 00", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sekkisei Treatment Bb 01", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sel Gemme", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Selahatin", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Selan Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selan Plus Zinc Oxide", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Select Brand Anti-Nausea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Select Brand Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Select Brand Povidone Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Select-Ob Plus Dha", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Selegiline Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Selenious Acid", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Selenite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Selenium Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Selenium Metallicum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Selenium Sulfide",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Selex Dna", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Self Defense Mineral Sunscreen Fluid Spf 34", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selff-Aid Cocoa Butter Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selsun Blue 2-In-1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selsun Blue Active 3-In-1", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selsun Blue Full And Thick", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selsun Blue Itchy Dry Scalp", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selsun Blue Medicated", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selsun Blue Moisturizing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selsun Blue Naturals Itchy, Dry Scalp", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Selvan85", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Selzentry",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Semglee", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Sempervivum Tectorum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Senecio Aureus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Senega Officinalis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senegence Facial Moisturizer For Men", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Senegence Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Senegence Senesun Hydrating Facial Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Senexon", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Senexon-S",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senility Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Senna",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senna Lax", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Senna Laxative",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senna Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Senna Natural Vegetable", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Senna Plus",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Senna S",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senna S Dual Action", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Senna S Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Senna S Orange", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Senna Syrup",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senna Tabs", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Senna Time", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Senna Time S", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Senna-Lax",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senna-Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Senna-S",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senna-Tabs", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Senna/Docusate Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sennosides",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sennosides And Docusate Sodium",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sennosides Natural Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Senokot",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Senokot-S", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Senophile Dermal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sense Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sensi Care Protective Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sensi-Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sensible Spf 32 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sensipar", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sensitif Eye Us Spf 30 Broad Spectrum Uva Uvb Sunscreen Pa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sensitive Mint Tooth-Paste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sensitive Rapid Relief", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sensitive Teeth And Gums", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sensitive Whitening Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sensitive Whitening Tooth-Paste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sensodyne", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }, { Route: "Oral" }] }] },
    { Medication: "Sensodyne Complete Protection", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sensodyne Pronamel", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }, { Route: "Oral" }] }] },
    { Medication: "Sensorcaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Epidural; Intracaudal; Perineural" }, { Route: "Epidural; Retrobulbar" }, { Route: "Infiltration; Perineural" }, { Route: "Perineural" }] }] },
    { Medication: "Sensorcaine Mpf", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Intracaudal; Perineural" }] }] },
    { Medication: "Sensorcaine(R) (Bupivacaine Hci And Epinephrine)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }] }] },
    { Medication: "Sente Daily Repair Complex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seojeon Antibacterial Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sephora 8Hr Mattifying Moisturizer Sunscreen Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sepia",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Sepia 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Sepia 6 Ampule Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sepia 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Sepia Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sepia Comp Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sepia Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sepia Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sepia Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Septaforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Septastat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Septihol Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Septocaine And Epinephrine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Septona Antiseptic Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Septona Antiseptic Hand Sanitizer Wipes Fragrance Free", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Septonsil", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sera Hand-Sanitizing Wipe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seren Guard", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seren Guard Sanitizing Wipes", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Serenade Alto Antimicrobial Hand", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Serene Clean And Pure Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Serene Sleep", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Serevent", DoseTypes: [{ DoseType: "Powder, Metered", Routes: [{ Route: "Oral; Respiratory (Inhalation)" }] }] },
    { Medication: "Serious Skincare Continuously Clear Acne Medication For Problematic Skin Dry Lo Pro All- Over Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Serious Skincare Serious C3 Plasma Bio Technology C Vitamin C Ester Protective Daytime Broad Spectrum Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Serious Skincare Serious-C3 Plasma Tm Bio Technology C Vitamin C Ester Protective Daytime Broad Spectrum Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seriously Soothing Face Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sernivo", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seromycin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Seroquel",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Serostim", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Serotonin",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] },
        ],
    },
    { Medication: "Serotonin (Human)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Serotonin Dopamine Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Serotonin Phenolic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Serotonin/L-Tryptophan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sertraline", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sertraline Hcl", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sertraline Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Serum Anguillae", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Serum Crema", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Serum X", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servclean Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servclean Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servclean Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servo Stat Bac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servo Stat-Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servo-Stat", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servo-Stat P", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servo-Stat P Antiseptic Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Servo-Stat P Foam Antiseptic Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sesame Seed",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Sesame Street Baby Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Session Savers Sunscreen Broad Spectrum Spf 30 Water Resistant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Setlakin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Sevelamer Carbonate",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sevelamer Carbonate For Oral Suspension", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sevelamer Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }, { Route: "Parenteral" }] },
        ],
    },
    { Medication: "Seven", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seven Minerals Skin Friendly Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seven Minerals Skin Friendly Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Seven Three Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sevenfact", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Seventh Chakra (Crown)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sever Daytime Cold And Flu And Sever Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Severe Allergy And Sinus Headache", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Severe Allergy Plus Sinus Headache",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Allergy Relief Plus Sinus Headache", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Anefrin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Severe Cold",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Severe Cold And Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Cold And Cough Daytime", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Cold And Cough Nighttime", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Cold And Cough Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Severe Cold And Flu",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Severe Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Cold And Sinus Relief Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Cold Cough And Flu Relief", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Cold Head Congestion", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Cold Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Cold Plus Flu Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Severe Cold, Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Severe Cold, Flu And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Congestion", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Congestion And Cough", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Congestion And Cough Max", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Severe Congestion And Cough Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Congestion And Cough, Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Severe Congestion And Pain", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Congestion And Sinus Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Congestion Nasal Anti-Drip Nasal Decongestant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Severe Congestion Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Congestion, Cough, Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Severe Cough And Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Severe Cough And Congestion",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Cough And Congestion And Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Severe Cough And Congestion Maximum Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Daytime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Daytime Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Daytime Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Nighttime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Nighttime Severe Daytime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Severe Sinus",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Sinus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Sinus Congestion Allergy And Cough Formula", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Sinus Congestion And Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Severe Sinus Congestion And Pain",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Sinus Congestion And Pain Daytime", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Severe Sinus Congestion Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Severe Sinus Pain And Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Sinus Relief Mist", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Severe Triple Medicated Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Vapor Ice Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Severe Wal Tussin Cf Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sevoflurane", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Sexual Disorders Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sexy Tasha", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seyip", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Seysara", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sf 5000 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sf Cherry Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sf Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sf Honey Lemon Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sf Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sf Menthol Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sferangs Collagen Recharging", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sferangs Collagen Recharging Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sferangs Collagen Recharging Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sferangs Collagen Rechargingcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sferangs Collagen Synergy Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sferangs Vita C Capsulebooster", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sfl Sunscreen - Sol Defense", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sfrowasa", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Sft One 70 Ethyl Alcohol Antiseptic Hand Sanitizer Lavender Scented",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray, Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sft1 Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shaboink",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shad Scale Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Shagbark Hickory", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Shagbark Hickory Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Shagbark, Hickory", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Shah Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shaklee Essentials Desert Wind Roll-On Antiperspirant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shaklee Youth Age Defense Mineral Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shamrock Sun", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shamrock Sun Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shang Shi Zhi Tong Pain Relieving Plaster", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shanon Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shao Lin Pain Relief Plaster", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Shapiro Md 5% Minoxidil Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shapriero 2% Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shark Cartilage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Shark Cartilage Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sharobel", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Shea Butter Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shea Moisture Spf 35 Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheep Red Sorrel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Sheep Sorrel Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Sheer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Broad Spectrum Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Confidence Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Cover Nourishing Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Daily Wear Face Protection Mineral Based Sunscreen Spf 16", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Defense Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Defense Tinted Moisturizer Broad Spectrum Spf 15 M50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Defense Tinted Moisturizer Broad Spectrum Spf 15 Md 70", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Defense Tinted Moisturizer Spf 15 L10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Defense Tinted Moisturizer Spf 15 Ml 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Defense Tinted Moisturizer Spf 15 Ml 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Defense Tinted Moisturizer Spf15 L20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Defense Tinted Spf 46", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Fluid Sun Shield", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Glow", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Matte Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Tint", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Touch Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheer Zinc Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheerzinc Spf 30 Tinted - Nude", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheerzinc Spf 30 Tinted - Sunkissed", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheerzinc Spf 30 Tinted - Tan", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheet Spf 30 Mineral", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheet Tint Eye", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheffield Advanced Scar Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheffield Arthritis And Muscle Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheffield Baby Teething Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sheffield Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheffield Lidocaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheffield Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sheffield Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheffield Triple Antibiotic Plus Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sheffield Zinc Oxide", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shellbark Hickory Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Shellfish Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Shengguxiang Foot Care Bath Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shexiang Zhuanggugao", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shield", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shield And Heal Lip Balm", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shield And Heal Medicated Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shield And Protect Barrier Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shieldplex",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shieldplex Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shieldplex Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shieldtek Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmershade Broad Spectrum Spf 30 First Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmershade Broad Spectrum Sunscreen Spf 30 Day Dream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmershade Broad Spectrum Sunscreen Spf 30 Golden Hour", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmershade Broad Spectrum Sunscreen Spf 30 Sunset", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmery Sun", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmy Meyer Lemon Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmy Neutral Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmy Original Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shimmy Rose Hibiscus Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shine Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shine On Living 30A Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shineblock", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shingbase Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shingles",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Shingles Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Shingles Pain Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shingles Pain Ulceration Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shingles Relief", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shingles Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shingrix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Shiseido Benefiance Nutriperfect Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Benefiance Wrinkle Smoothing Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shiseido Benefiance Wrinkleresist24 Day",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shiseido Benefiance Wrinkleresist24 Protective Hand Revitalizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Clear Stick Uv Protector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Clear Sunscreen Spf 50 Plus", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Essential Energy Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Essential Energy Hydrating Day", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shiseido Extra Smooth Sun Protection N",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shiseido Future Lx Total Radiance Foundation E", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Future Solution Lx Infinite Treatment Primer", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Future Solution Lx Total Protective", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Future Solution Lx Total Protective E", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Future Solution Lx Universal Defense E", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Glow Enhancing Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Ibuki Protective Moisturizer", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Radiant Lifting Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Refining Makeup Primer", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Sports Bb", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Sports Bb Dark", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Sports Hydrobb Compact", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Sun Protection Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Sun Protection Lip Treatment", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Glow Luminizing Fluid Foundation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Lasting Foundation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Lasting Liquid Foundation Golden 5", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Lasting Liquid Foundation Neutral 5", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Lasting Liquid Foundation Rose 5", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Radiant Lifting Foundation", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 110 Alabaster", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 120 Ivory", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 130 Opal", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 140 Porcelain", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 150 Lace", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 160 Shell", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 210 Birch", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 220 Linen", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 230 Alder", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 240 Quartz", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 250 Sand", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 260 Cashmere", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 310 Silk", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 320 Pine", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 330 Bamboo", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 340 Oak", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 350 Maple", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 360 Citrine", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 410 Sunstone", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 420 Bronze", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 430 Cedar", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 440 Amber", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 450 Copper", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 460 Topaz", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 510 Suede", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 520 Rosewood", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 530 Henna", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 540 Mahogany", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 550 Jasper", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Foundation 560 Obsidian", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Synchro Skin Self-Refreshing Tint", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido The Makeup Foundation", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido The Makeup Perfect Smoothing Compact Foundation (Refill)", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shiseido Ultimate Sun Protection",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Shiseido Ultimate Sun Protector",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shiseido Urban Environment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Urban Environment Fresh-Moisture Sunscreen Spf 42", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Urban Environment Oil-Free Mineral Sunscreen Spf 42", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Urban Environment Oil-Free Sunscreen Spf 42", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Urban Environment Oil-Free Uv Protector", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Urban Environment Tinted Uv Protector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Urban Environment Uv Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Uv Protective Compact (Refill)", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shiseido Uv Protective Foundation",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shiseido Vital Perfection Uplifting And Firming Day", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido Waso Koshirice Acne Calming Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido White Lucent All Day Brightener N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido White Lucent Day", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shiseido White Lucent Onmakeup Spot Correcting Serum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shit In The Woods Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shock Doctor Hand Sanitizer- Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shock Grief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Shop Rite Day Severe Cold Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Shopkins 3D Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopkins 3D Hand Sanitizer Dlish Donut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopkins 3D Hand Sanitizer Lippy Lips", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopkins Brush Buddies", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Shopkins Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shopkins Mouth Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Shopko Alcohol Free Multi Action", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Shopko Beauty Classic Spice 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopko Beauty Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopko Beauty Everyday Clean Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopko Beauty Green Apple 2 In 1 Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopko Beauty Hand Sanitizer With Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopko Beauty Maximum Strength Medicated Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopko Beauty Moisturizing Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopko Blue Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Shopko Ice Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Shopko Spring Mint", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Shopping Annuity Brand Premium Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shopping Annuity Premium Anti-Bacterial Handsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shoprite Antibacterial Moist Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shoprite Antibacterial Moist Wipesantibacterial Moi", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shoprite Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Short Ragweed",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Short Ragweed Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Shortleaf Pine Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Shot Down",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shred Flex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Shrimp",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Shsnh6-3600", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shsnh6-3601", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shu Jin Huo Luo Pain Relieving", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shu Jin Lu", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Shuddi Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Shunga",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Shur Clens Skin Wound Cleanser 24 Count Single Dose Ampules", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sibarc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Siberian Elm Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Sick Stopper", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Siena Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sierra Soft Alcohol Foam Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sierra Soft Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sierra Soft Antibacterial Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sierra Soft Non-Alcohol Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sierra Soft Non-Alcohol Foam Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sierra Soft Non-Alcohol Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sight", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signatry Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signatry Antimicrobial Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signatry Ultra Antimicrobial Ltn Sp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Athletes Foot Antifungal", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Black Bottle Tea Tree Antifungal Foot And Body Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Signature Care",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Signature Care 12 Hour Cough Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Acid Controller", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Acid Reducer", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Acid Reducer Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Acne Medication", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Acne Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Advanced Hand Sanitizer With Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Alcohol Prep Pad", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Allergy And Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Signature Care Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Signature Care Allergy Relief D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Signature Care Aloe Vera Gel With Lidocaine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Antacid Plus Gas Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Anti Diarrheal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Anti Nausea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Arthritis Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Aspririn", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Signature Care Athletes Foot",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Signature Care Athletes Foot Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Blemish And Blackhead Control", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Childrens 12 Hour Cough Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Signature Care Childrens Allergy Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Signature Care Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Signature Care Childrens Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Signature Care Childrens Multi Symptom Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Childrens Pain Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Cold And Hot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Cold And Hot Medicated", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Cool Heat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Cough Relief", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Daytime Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Daytime Nighttime Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Signature Care Daytime Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Daytime Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Enamel Guard Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Signature Care Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Extra Strength Smooth Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Extra Strength Smooth Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Eye Drops Advanced Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Signature Care Eye Drops High Performance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Signature Care Eye Drops Redness And Dry Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Signature Care First Aid Antibiotic",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Signature Care Hair Regrowth Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Hair Regrowth Treatment For Women", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Hand Sanitizer Chamomile Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Hand Sanitizer Wipes With Aloe Vera", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Signature Care Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }, { Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Signature Care Hemorrhoidal Cream (With Applicator)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Signature Care Hydrocortisone",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Signature Care Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Infants Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Infants Ibuprofen", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Infants Pain Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Lice Treatment", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Signature Care Maximum Strength Cold Sore Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Medicated Cooling", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Medicated Wipes Hemorrhoidal Wipes With Witch Hazel", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Signature Care Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Signature Care Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Signature Care Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Naproxen Sodium Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Signature Care Nasal Decongestant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Signature Care Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Signature Care Nighttime Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Non Drowsy Daytime Severe Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Oil Free Acne Wash Pink Grapefruit Foaming Scrub", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Signature Care Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Signature Care Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Signature Care Severe Cold Cough And Flu", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Sheer Face And Body Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Sport Spf 15 Clear", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Signature Care Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Care Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Upset Stomach Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Signature Care Vagicaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Collection Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Spf 50 Clear Face", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signature Spf 50 Tinted Face", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Signifor", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Signifor Lar", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Intramuscular" }] }] },
    { Medication: "Siklos", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sila Iii", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Silace",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Siladryl Allergy Medicine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silafed", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silagen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sildenafil",
        DoseTypes: [
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sildenafil Citrate",
        DoseTypes: [
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sildenafil For Oral Suspension", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silenor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silica", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silica 20 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Silica 30 Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Silica 6 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silica Comp.", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silica Marina", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Silicea",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Silicea 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Silicea 6X",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Silicea Belladonna",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Silicea Belladonna Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silicea Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silicone Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Siliq", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Silk Line Silk Day Cream Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silk Spf Facial Sunscreen Fluid Hybrid Broad Spectrum Spf 30 Uva/Uvb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silk Worm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Silka",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Silka Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silka Antifungal Foot", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silken Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silken Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silken Pore Perfecting Sunscreen Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silky Touch Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silly Strawberry Anticavity - Bft51 Mrt", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Silodosin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Silphen", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silphen Dm Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Siltussin Das", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Siltussin Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Siltussin Dm Das Cough Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Siltussin Sa", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silvadene", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silvaplex Wound Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silver", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Silver Ion Antibacterial Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silver Ion Feminine Antibacterial Gels", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silver Leather", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silver Maple Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Silver Nitrate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silver Nitrate Applicators", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silver Nitrate Potassium Nitrate", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silver Ragweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Silver Soft Maple Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Silver Standard Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Silver Sulfadiazine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simbrinza", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Simethicone",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Gel", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Simethicone 125 Mg", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simethicone 80 Mg", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simi-Tree Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Similasan Earache Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Simliya", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Simoniz Foaming Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simoniz Hand Sanitizing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simoniz Saniclean", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simpesse", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Simpex Bisacodyl Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simpex Guaifenesin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simple", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Chlorinated Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simple Grace Blueberry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Grace Fresh Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Grace Green Apple Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Grace Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Green", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Hygiene Alcohol Free Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Hygiene Antibacterial Foam Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Hygiene Antibacterial Hand", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Hygiene Foaming Hand Sanitizer 62", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Hygiene Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Truth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Simple Truth Liquid Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Truth Mineral Based Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Truth Mineral Based Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Truth Organic Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simple Truth Silver Gel Wound Dressing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simplex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simpli Day All In One Smartcream Spf 42", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply 70 Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply 70 Hand Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Beige Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Beige Perfecting Makeup Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Bliss Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Clean Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Dreams", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simply Harmony", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simply Lavender Scent Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Neosporin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Right", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simply Saline", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Shift", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simply Sleep", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Simply Vital Acne Face Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply Vital Hemorrhoid And Anorectal Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simply White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Simponi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Simponi Aria", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Simpurity Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Simulect", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Simvastatin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sinapis Nigra",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sine Off", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sine-Eez", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinemet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinex", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinex Severe Plus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Single 0.9G Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Single Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Single Antibiotic With Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Single Antibiotic With Neomycin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Singulair",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Singular Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Singular Wipes Fragrance Free", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Singular Wipes Lavender Fragrance", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinofresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinold", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinolm Cold And Flu", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinolm Fast Allergy And Sinus Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinolm Fast Headache Pain Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinsin Mulpas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Cutaneous; Topical; Transdermal" }, { Route: "Topical" }] }] },
    { Medication: "Sinsinpap Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinsinpas Arex Instant Pain Relief (Not For Sale)", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinsinpas Arex Instant Pain Relief Small", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Cutaneous; Topical; Transdermal" }] }] },
    { Medication: "Sinsinpas Arexgel", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinsinpas Arexlotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinsinpas Cold", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinsinpas Hot Large", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinsinpas Lidocaine Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Cutaneous; Topical; Transdermal" }] }] },
    { Medication: "Sinsinpas Pain Relieving Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sinu Hc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinu Inhaler", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinu-Phen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinu-Phen Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinu-Rite", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinu-S", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinuchron", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinuchron Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinucleanse", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinucleanse Micro-Filtered Nasal Wash System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sinucleanse Nasal Drip", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinucleanse Neti-Pot", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinucleanse Squeeze", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinucleanse Sterile Saline Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinucleanse Sterile Saline Mist Daily Care", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinuforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinufrin", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinufrin Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Sinus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus 12 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus And Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus And Allergy Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus And Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus And Allergy Relief Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus And Cold D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus And Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus And Headache",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus And Headache Day Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sinus Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus Congestion And Pain",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sinus Congestion And Pain Relief",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sinus Congestion And Pain Severe",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Congestion Headache", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus Congestion Pe",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Decongestant", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Decongestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Headache", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Headache And Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Headache Pe Non- Drowsy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Headache Pemaximum Strength Non-Drowsy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Max Relief Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Sinus Pain",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sinus Pain And Congestion",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sinus Pe",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Pe And Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pe Congestion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sinus Pe Head Congestion And Flu Severe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pe Plus Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pe Pressure Plus Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pe Pressure, Pain And Cold", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pe Pressure, Pain And Mucus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Plus Allergy Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus Plus Headache",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Plus Headache Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sinus Plus Headache Daytime And Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Sinus Pressure And Congestion Relief",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Pressure And Congestion Relief Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus Pressure And Pain",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Pressure And Pain And Cough", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus Pressure And Pain Pe",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Pressure Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pressure Pain And Cough", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pressure Plus Pain", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pressure Plus Pain Pe", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Pressure, Pain And Cough", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Relief Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Relief Day And Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sinus Relief Day Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sinus Relief Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Relief Pressure And Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Relief Pressure, Pain And Cough", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus Relief Severe Congestion",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Relief Severe Congestion And Pain", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Rx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinus Severe",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinus Sevre", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinus Wash Packet", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinus Wash Pre Mixed Ready To Use", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Sinus Wash Saline Packets",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Sinus Wash Saline Refills", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sinus-Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sinusalia",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sinuscalm",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sinusforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinusforce-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinusitis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinusitis Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinusitisx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinusitx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinutone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sinuva", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Intrasinal" }] }] },
    { Medication: "Sipa Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sipa Foaming Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sirolimus",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sirturo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sisal", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Sisleya Global Anti-Age Hand Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sit Clean", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sitavig", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Buccal" }] }] },
    { Medication: "Siterol", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sivan G Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sivextro",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sixth Chakra (Third Eye)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sj3", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sk Advanced Relief Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sk Dry Eye Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sk Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sk Maximum Redness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sk Original Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Skaka Phytoncide", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skelaxin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin A Cool Hydrocortisone Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Affections", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Balance Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Balancing Ultra Sheer Daily Defence Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Barista 2In1 White Body Perfection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Barista Rice Bran Cleansing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Block Extra Strength Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Care Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Skin Caviar Concealer-Foundation Sunscreen Spf 15 Peche", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Skin Caviar Concealer-Foundation Sunscreen Spf 15 Porcelaine Blush", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Skin Caviar Concealer-Foundation Sunscreen Spf 15 Tender Ivory", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Skin Caviar Essence In Foundation Broad Spectrum Spf 25 Sunscreen Mocha", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Cleansing Wipe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Clearify Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Clearify Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Clearify Moisturizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Clearify Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Cottage 70 Percent Ethyl Alcohol Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Eruptions", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Eruptions 7", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Eruptions Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Eruptions Runny Nose", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Foundation Cushion Compact Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Infections", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Irritant And Healing Support", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Irritation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Klear Acne Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Skin Lightener", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Long-Wear Fluid Powder Foundation Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Long-Wear Weightless Foundation Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Md Natural", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Medica Purifying Foaming Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Pharmacy Advanced Acne Therapy", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Pharmacy Advanced Acne Therapy Clarifying Daily Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Pharmacy Advanced Acne Therapy Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Pharmacy Advanced Acne Therapy Overnight Salicylic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Pharmacy Advanced Acne Therapy Pore Refining Facial Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Pharmacy Advanced Sun Therapy Broad Spectrum Spf 30 Sun", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Project Ya Cooling Celluven Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Skin Protectant",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Skin Protectant Cream 43% Petrolatum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Protectant Petrolatum", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Protectant With Lanolin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Protector With Breathable Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Rash Acne", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Rashes Eczema", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skin Rejuvenating Daily Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Relief Oatmeal Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Renewing Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Repair", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Repair Complex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Rescue Set", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Skin Restorative", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Soothing Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Soothing Cream - Nut Free", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Supplies For Men", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Skin Tag Relief",
        DoseTypes: [
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Salve", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Skin Tags", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Therapy Calm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid- Medics Antibacterial Wipes Fragrance Free", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Apple Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Berry Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Citrus Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Coconut Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Cucumber Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Fresh Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Grapefruit Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Kit Fresh, Citrus, Apple, Cucumber", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Kit Fresh, Citrus, Berry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Kit Fresh, Grapefruit, Berry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Kit Fresh, Grapefruit, Berry, Coconut", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Kit Watermelon, Cucumber, Coconut", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Kit Watermelon, Cucumber, Coconut, Lemon", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Lemon Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Aid-Medics Antibacterial Wipes Watermelon Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skin-Lube", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinbutak Chomchom Magicshampoo", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinbutak Go Young Magic Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinbutak Go Young Magiccream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinbutak Go Young Magiclotion", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinbutak Gold Magic Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Advanced Scar Control Skin Protectant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Blemish Plus Age Defense Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Clarifying Exfoliating Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Daily Brightening Uv Defense Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Light Moisture Uv Defense Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Physical Eye Uv Defense Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Physical Fusion Uv Defense Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Physical Matte Uv Defense Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Physical Uv Defense Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Sheer Physical Uv Defense Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Silymarin Cf Acne Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinceuticals Sport Uv Defense Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skineez", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinfix Hand Repair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinfuse Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Skinguard24",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Skinheal Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinlycious Clear Skin Essentials Starter Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Skinlycious Glow Exfoliant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinlycious Multi Protection Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinnies Spf30 Sungel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinperfect Primer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Acne Therapy", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Acne Therapy Active Clearing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Acne Therapy Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Acne Therapy Overnight Salicylic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Acne Therapy Pore Refining Facial Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Acne Therapy Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Anti-Aging Therapy Glycolic Day Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Anti-Aging Therapy Retinol Day Moisturizer Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinpharmacy Advanced Sun Therapy Broad Spectrum Spf 50 Face", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinprotect Spf 50 Sheer Roller Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skins Delay Maximum Strength Lidocaine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinsei", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinsuit Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skinsuit Lip", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skintek Skin Tag Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Skintillate Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sklice", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skm 70% Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skm 99.9% Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skopko Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Skullduggery Pain Relief And Skin Protection For Tattoos", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sky Oral Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sky Organics Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sky Peg3350", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sky Wellness", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skyaderm-Lp", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skyla", DoseTypes: [{ DoseType: "Intrauterine Device", Routes: [{ Route: "Intrauterine" }] }] },
    { Medication: "Skyline Professional Instant Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skymo Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Skyn Endurance Delay For Men", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Skyrizi",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Skytrofa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Slap Happy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slash", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sleep",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sleep Aid",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sleep Aid Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sleep Aid Nighttime", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep Assist", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep Disorder Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep Ii", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep Tabs", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep Tight", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sleep Well",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Sleep-Aid",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sleep-Aid Chewables", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep-Aid Diphenhydramine Hcl", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep-Aid, Circle K", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep-Aid, Lil Drug Store", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleep-Ez Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sleepcalm",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sleepcalm Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleepease", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleepinal", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleepology", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleepology For Children", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sleeptime", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Slender Ragweed", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Slender Ragweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Slenderiix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Slight Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slip Tint Broad Spectrum Spf 35 Tinted Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Acne Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Am Benzoyl Peroxide Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Dual Defender Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Moisturizer With Spf", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Salicylic Acid Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Silver Sulfur Acne Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Uv Bounce Shade 001", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Uv Bounce, Shade 002", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slmd Uv Bounce, Shade 003", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Slow Corporation", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Slow Digestion Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Slynd", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sm Swabalpha", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Small Intestine Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Small Joint Pain Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Smart And Clean", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Antibacterial Handsoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Baby Diaper Rash And Skin Protectant", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Generic Emoji Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Hand Sanitizer 16.9 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Hand Sanitizer 2 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Hand Sanitizer 33 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Hand Sanitizer 8 Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Marvel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Care Personal Safety Kit", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Choice Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Choice Incontinence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Clean Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Defender", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Foaming Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart San Alcohol Free Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart San Alcohol-Free Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Smart San Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Smart San Hand Sanitizer Fragrance-Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Sanitizer - Liquid Hand Sanitizer - 70 Ethyl Alcohol - Lavender And Herbs Fragrance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Sense Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Smart Sense Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Smart Sense Hand Sanitizer Cotton Breeze", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Sense Hand Sanitizer Lychee Berry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smart Sense Hydrogen Peroxide Solution", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Smartmouth",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Smartmouth Advance Clinical Dds Formula Mint", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Smartmouth Mouthwash", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Smartmouth Premium Action", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Smartsense Maximum Strength Athletes Foot Relief Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smartshield", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Nasal; Topical" }] }] },
    { Medication: "Smashbox Halo Healthy Glow All-In-One Tinted Moisturizer Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smd Sanitizer Solutions Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smileplus Alcohol Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smiles Prid Salve", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smith Amish Arthritis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smith Amish Eczema/Psoriasis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smith Amish Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smith-Amish Arthritis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smoflipid", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Smoke Free", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }, { Route: "Sublingual; Sublingual" }] }] },
    { Medication: "Smoke Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Smooth Af", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smooth Brome", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Smooth Brome Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Smooth Gator 60-Second Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smooth Gator Foot And Leg Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smooth Gator Fungus Eliminator", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smooth Groom Society American Edition Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smooth Lax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Smply 70 Citrus Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply 70 Gel Unscented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply 70 Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply Aloe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply Black Velvet Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply Eucalyptus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply Isopropyl 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply Renew Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply Sanitizer Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smply Sanitizer Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Smut, Corn", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Smut, Johnson Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Smut, Wheat", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Snap Crack Natural Pain Relieving Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snappyscreen Spf 15", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snappyscreen Spf 40", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sniff-N-San Topical 80", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sniffles",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sniffles N Sneezes 4 Kids", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Snore Defense", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Snore Stop Extinguisher Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Snore-Soothe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Snorestop 60 Fasttabs", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Snorestop Nasospray 60", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Snugell", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Beaded Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Hand Sanitizing Spry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Spf 15 Beeswax Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Spf 15 Flavored Beeswax Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Spf 30 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Spf 30 Soy Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Spf 50 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Snugz Spf 50 Sunscreen Spry", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "So Totally Clean Deep Pore Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soaanz", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Soapbox 70 Hand Sanitizer Light Citrus Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soapbox Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soapbox Antibacterial Hand Wipes Unscented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soapbox Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soapbox Soaps Single Use 70 Hand Sanitizer Packs", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Socheongryong Tang Extract", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Social Grace Alcohol Free Hand Sanitizer Eucalyptus Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodalite", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sodium Acetate", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Sodium Bicarbonate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sodium Bicarbonate 10 Gr",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sodium Bicarbonate 5 Gr",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sodium Bicarbonate Antacid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sodium Chloride",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intravascular" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sodium Chloride For Irrigation", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Irrigation" }] }] },
    {
        Medication: "Sodium Chloride Hypertonicity",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Sodium Chloride Hypertonicity Ophthalmic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sodium Chlorode Hypertonicity Ophthalmic Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sodium Citrate 4% W/V Anticoagulant", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodium Citrate And Citric Acid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sodium Citrate Blood", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodium Citrate Blood-Pack Units, (Pl 146 Plastic)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodium Citrate W/V Anticoagulant", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodium Diuril", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodium Edecrin", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodium Ferric Gluconate Complex In Sucrose", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodium Flouride", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Sodium Fluoride",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Oral" }] },
            { DoseType: "Gel", Routes: [{ Route: "Dental" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sodium Fluoride 1.1%", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Fluoride 1.1% Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Fluoride 5000 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Sodium Fluoride 5000 Ppm",
        DoseTypes: [
            { DoseType: "Paste", Routes: [{ Route: "Dental" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Sodium Fluoride 5000 Ppm Dry Mouth", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Fluoride 5000 Ppm Enamel Protect", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Fluoride 5000 Ppm Fruit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Fluoride 5000 Ppm Sensitive", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Fluoride 5000 Ppm Spearmint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Fluoride And Potassium Nitrate", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Fluoride Chewable", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sodium Fluoride F 18",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Sodium Fluoride Gel", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sodium Iodide I 123", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sodium Iodide I 131 Diagnostic", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sodium Iodide I-131", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sodium Nitrite", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Sodium Nitroprusside",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Sodium Phenylacetate And Sodium Benzoate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Sodium Phenylbutyrate",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sodium Phosphates", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Sodium Polystyrene Sulfonate",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral; Rectal" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }, { Route: "Oral; Rectal" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral; Rectal" }] },
        ],
    },
    {
        Medication: "Sodium Sulfacetamide",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sodium Sulfacetamide - Sulfur Cleansing Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide - Sulfur Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 10 Sulfur 2 Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 10 Sulfur 2 Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 10 Sulfur 5 Cleanser", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 10% And Sulfur 2% Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sodium Sulfacetamide 10% And Sulfur 5% Cleanser",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sodium Sulfacetamide 10% And Sulfur 5% Emollient Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 10% And Sulfur 5% Emollient Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 10%, Sulfur 2%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 8% And Sulfur 4%", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 8% And Sulfur 4% Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 9 Sulfur 4.5 Wash", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 9% - Sulfur 4%", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 9% - Sulfur 4.5% Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 9% And Sulfur 4% Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 9.8% And Sulfur 4.8%", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 9.8% And Sulfur 4.8% Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 9.8% And Sulfur 4.8% Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sodium Sulfacetamide 9.8%, Sulfur 4.8%", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sodium Sulfacetamide And Sulfur",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sodium Sulfacetamide And Sulfur Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sodium Sulfacetamide, Sulfur",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Rinse", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sodium Sulfate, Potassium Sulfate, Magnesium Sulfate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sodium Tetradecyl Sulfate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodium Thiosulfate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sodum Acetate", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Soeder Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sof Skin Vaporizing Chest Rub And Cough Suppressant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sofosbuvir And Velpatasvir", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Soft And Dri Soft Scent", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Bisque Expert Finish Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Bisque Perfecting Makeup Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Defend Antibacterial Handwash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Defend Foam Antibacterial Handwash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Foam Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Hand Sanitizing Wipe Alcohol Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Hand Sanitizing Wipes Af", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Impact Alcohol Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Impact Foam Alcohol Foam Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Impact Mini Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Instant Hand Sanitizer Af", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Care Sentry", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft N Sure Antiseptic Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft N Sure Foamed Antiseptic Handrub", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Services", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Services Clearing Clay Multi-Use Breakout Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Services Clearing Mist Breakout Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Tip Neti Pot", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Soft Tip Neti Pot Easy To Use Sinus Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Soft Tip Neti Pot Sinus Wash", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Soft Tip Neti Pot Soothing Sinus Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Soft Tip Squeeze Bottle Sinus Wash", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Soft Wet Wipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Whisper Cucumber Green Tea", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Whisper Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soft Wipes Alcohol Free Antibacterial", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softcide", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soften Sure Foam Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softensure Foam Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Aloe Vera And Pineapple", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Bright And Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Cherry Blossom", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Coconut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Cucumber And Melon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Fresh Breeze", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Lavender And Chamomile", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Mango And Pomegranate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Milk And Honey", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Shea And Cocoa Butter", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Soft Rose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Antibacterial Handsoap Strawberry And Kiwi", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Kitchen Handsoap Antibacterial Lemon Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softie Soft And Smooth Handsoap Antibacterial White Tea", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Softlips",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Softlips Cherry Cordial, Maple Butter, Frosted Berry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Cherry, Raspberry, Vanilla", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Oasis Peach Mango", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Oasis Pineapple Coconut", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Oasis Strawberry Kiwi", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Oasis Watermelon Blackberry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Pearl", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Peppermint", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Salted Caramel, Candy Apple, Marshmallow Ghost", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Vanilla", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softlips Watermelon, Tropical Coconut, Peach Passion", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsheencarson Magic Bump Rescue Spot Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial Clean And Protect Cool Splash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial Foaming Hand Crisp Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial Foaming Hand Kitchen Fresh Hands Zesty Lemon", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial Hand Citrus Cheer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial Kitchen Fresh Hands", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial Sparkling Pear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial Wicked Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial With Moisturizers Crisp Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial With Moisturizers Crisp Clean Lhs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial With Moisturizers Fresh Citrus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial With Moisturizers Fresh Citrus Lhs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial With Moisturizers White Tea And Berry Fusion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Softsoap Antibacterial With Moisturizers White Tea And Berry Lhs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soil/Water Contaminants Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sojourn", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Sol-M Alcohol Swabs Large Non-Sterile", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sol-M Alcohol Swabs Large Sterile", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sol-M Alcohol Swabs Medium (Folded)", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sol-M Alcohol Swabs Medium (Folded) Non Sterile", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sol-M Alcohol Swabs Medium Non-Sterile", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sol-M Alcohol Swabs Medium Sterile", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sol-M Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solace Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solanum Nigrum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solanum Tuberosum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solar Defense Body", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solar Defense Non Tinted", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solar Defense Tinted", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solar Goo", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solar Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solar Plexus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solar Sense Spf 50 Daily Facial Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solara Suncare - Clean Freak", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solara Suncare - Glow Getter", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solara Suncare - Pout Protector", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solara Suncare - Time Traveler", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solaravix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solaraze", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solarcaine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solarcaine Cool Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solbar Avo Spf35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solbar Fifty Spf50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solbar Shield Spf40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solbar Thirty Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solbar Zinc Spf38", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soldamist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soldanizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soleil Broad Spectrum Spf 30 Sunscreen Body Creme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soleil Forte Multi", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soleil Sun Creme Solaire Visage Et Corps Sunscreen Face And Body Sunscreen Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soleil Superieur Body Spf 50 Us", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soleil Superieur Broad Spectrum Spf 50 Sunscreen Us", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soleil Tojours", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Soleil Toujours",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Solenopsis Invicta", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }] }] },
    { Medication: "Solenti Bionatural Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soleo Organics Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solep Premier Hi Gro", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solep Premier Hi Gro Ampoule Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solep Premier Hi Gro Tonic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solid Roots Roll-On Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solidago", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solidago (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solidago Canadensis Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Solidago Comp.", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Solidago Virgaurea",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Solifenacin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Solifenacin Succinate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Solifenacine Succinate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Solimo",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Solimo Kids Spf 50 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solimo Renewing Cleanser For Acne Prone Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solimo Repairing Treatment For Acne Prone Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solimo Sport Spf 50 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solimo Ultra Light Sunscreen Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soliqua 100/33", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Soliris", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Solivie Natural Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Solmate",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Solnara Everything Blood Circulation Nattokinase(Control Blood Pressure And Improve Blood Circulation)", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solodyn", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solosec", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solrx Kids Spf 60 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solrx Sport Spf 30 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solrx Sport Spf 50 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solrx Sport Spf 70 C-Spray", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Solscents",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Solstice Capsaicin Patch Hot", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solstice Pain Relieving", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soltamox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solthera Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Solu-Cortef", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Solu-Medrol", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Solu-Medrol(R) Methylprednisolone Sodium Succinat", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Soluble Fiber", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Solucion Intersol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Solum Aesculus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Solvent/Small Ring Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Soma", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Somaderm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Somatuline Depot", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Somavert", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sombra Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Cbd Warm Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Cool Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Hand Sanitzer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Plus Cbd 1000Mg Cool Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Plus Cbd 1000Mg Warm Theapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Plus Cbd 2000Mg Cool Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Plus Cbd 2000Mg Warm Theapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Plus Cbd 500Mg Cool Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sombra Warm Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Somcupin", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Some By Mi Aha Bha Pha 30 Days Miracle Serum Light", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Some By Mi Aha Bha Pha 30 Days Miracle Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Some By Mi Aha Bha Pha Real Cica 92% Cool Calming Soothing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Some By Mi Charcoal Bha Pore Clay Bubble Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Somerset Anti-Bac Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sominex",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sominex Max", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Somineze", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Somnapure", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Somnitabs", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Somxl Homeopathic Wart Remover", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sonacaine Topical Anesthetic With Menthol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sonage Protec Plus Spf 30 Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sonamu-01", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sonssack Plus Hand Cleaner", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sooae Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sooae Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sooae Hand Santizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sool Softener Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Soolantra", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soom Green Mouthwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Soonreborn Repair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soonreborn Repair Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sooth-A-Sting", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Soothe",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Soothe And Cool", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Cool Free Medseptic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Cool Free Moisture Barrier", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Cool Inzo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Cool Inzo Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Cool Moisture Barrier", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Cool Moisture Guard", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Cool Protect", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Cool Treat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Hydrate Starter Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Move Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe And Sleep", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Soothe Comfort I Cough Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Soothe Comfort Ii Cough Syrup", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Soothe Daily Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe Hydration", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Soothe Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Soothe Moisturizing Rescue Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe Night Time", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Soothe Regimen For Sensitive Skin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe Sensitive Skin Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe Xp", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Soothe Xp Preservative Free", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Soothe Your Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothe-A-Sting", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothing Antiseptic Mask With Sulfur", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothing Day Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothing Itch", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothing Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soothing Respiratory Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sopure",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sopuro", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soqu Watery Chamomile Sun Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sorafenib", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sorafenib Tosylate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sorafine Cremogel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soraresal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sorbet Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sorbitol", DoseTypes: [{ DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] }] },
    { Medication: "Sorbitol-Mannitol", DoseTypes: [{ DoseType: "Irrigant", Routes: [{ Route: "Urethral" }] }] },
    { Medication: "Sorbugen", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sorbutuss", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Mouth Cleanser", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore Muscle And Joint Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore Muscle And Joint Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore No More", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore No More Cool Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore No More Plus Cbd 2000Mg Cool Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore No More Plus Cbd 500Mg Cool Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore No More Warm Therapry Plus Cbd 2000Mg", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore No More Warm Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore No More Warm Therapy Plus Cbd 500Mg Natural Pain Relieving Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sore Throat",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sore Throat 911", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sore Throat Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Coughs", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Dry Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Inflammation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Left Side", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Logenzes", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Logenzes Sore Throat-", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat Menthol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sore Throat Relief",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral; Sublingual" }, { Route: "Sublingual" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sore Throat Right Side", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sore Throat With Pallor", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sorghum Halepense Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Sorilux", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sorine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sorrel/Dock Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Sotalol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sotalol Hydrochloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sothys Paris Sunscreen Face And Body Broad Spectrum Spf 30 Uva/Uvb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sotradecol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sotrovimab", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sotylize", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Soulus Rp Rash And Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sound Body Acid Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body All Day Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sound Body Allergy Relief",
        DoseTypes: [
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sound Body Calcium Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Childrens Allergy Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sound Body Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sound Body Daytime Cold And Flu Relief Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sound Body Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Extra Strength Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Herbal Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sound Body Ibuprofen",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sound Body Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Sound Body Nighttime Sleep Aid",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sound Body Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Pain Relief Pm", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Pain Reliever", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Sleep Aid", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Body Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Health Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Health Heartburn And Gas Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Health Sf Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sound Health Throat Relief Pops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Soundbody", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soundbody Advanced Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Soundbody Cold And Hot Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soundbody Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Soundbody Seasonal Itchy And Red Eye Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Soundhealth Sf Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Source Vital Apothecary Sunshade Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "South Seas Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Southeast Regional", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Southern Medical Oxygen, Compressed", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Southern Ragweed", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Southern Soft Luxury Foam Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Southwest Regional", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sovaldi",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sovereign Silver", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sovereign Silver Skin Care", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Soybean", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Soybean Food", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Sp Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sp Hand Sanitizer 99", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sp Pharma", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sp1 Formula", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Cology Skin Clearing Body Wash Salicylic Acid Acne Treatment Pink Grapefruit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Cology Sport Sunscreen Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Cology Ultra Sheer Face And Body Sunscreen Spf 70", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Day Care For Hands Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Dry Heel Foot Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Niacinamide And Vitamin E Hand Cream Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Sense Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Sense Antibacterial Hand Wash Eucalyptus Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Sense Antibacterial Hand Wash Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Sense Antibacterial Hand Wash-Eucalyptus Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Sense Antibacterial Hand Wash-Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa Sense Hand Sanitizer Eucalyptus Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spa/Pool Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sparitual Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sparkle Fresh Anticavity", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sparkle Screen Broad Spectrum 30 Glittering Gold Kiwi-Pear Scented", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sparklefresh", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sparkling Cranberry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sparoom Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Spascupreel",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intradermal; Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Spasmodic Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spasoap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spasoap Liquid Antibacterial", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spasoap Liquid Antibacterial 2X Ultra", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Berry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Cherry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Cherry Blossom", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Cucumber Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Eucalyptus Mint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial French Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Lemon Citrus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Peach", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Pineapple", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Vanilla Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Antibacterial Watermelon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Hand Sanitizer With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spatherapy Sanitizing Hand Wipes Lemon", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spearmint Eucalyptus", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Spearmint Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spearmint Spf 15 Lip", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spearmint Spf 30 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spearmint Whitening With Hemp Oil", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Speci-Chol", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Specter Capital", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spector And Co Antiseptic Hand Sanitizer Gel With Kiwi Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spector And Co Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spector And Co Hand Sanitizer Gel With Coconut Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spector Scientific Lab Hand Sanitizer Soothing Infused With Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spectragenix Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spectric", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spectrum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spectrum 62% Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spectrum Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spectrum Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spectrum Health - Amway Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Speed Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Speed Stick Clean Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Speed Stick Cool Clean", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Speed Stick Fresh Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Speed Stick Gear Overtime Relentless Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Speed Stick Overtime Clear Gel", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Speed Stick Power Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Speedgel Rx", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Sperian Water Additive Concentrate", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Spf 15 Lip And Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 20 Loose Mineral Foundation", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 30 Daily Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 30 Face Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 30 Moisturizing Facial Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 30 Oil-Free Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 30 Scalp And Hair Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Spf 30 Sport Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Spf 30 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Spf 30 Tinted Mineral Moisturizer Sand", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 30 Tinted Mineral Moisturizer Sheer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 32 Daily Suscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 50 Face Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Spf 50 Mineral Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Spf 50 Moisturizing Sunscreen Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 50 Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Spf 50 Plus Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Spf 50 Sheer Mineral Sensitive Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 50 Sport Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Spf 50 Sunscreen",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Spf 50 Sunscreen Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 50 Super Shield Sport Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 50 Tinted Face Mineral Fragrance Free Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 50 Uva/Uvb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 55", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf 8 Lip Moisturizers", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Spf Beauty", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf Best Sellers Mini Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf Bestsellers Starter Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Topical" }] }] },
    { Medication: "Spf Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf Multitaskers Mini Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf Ventures Brush On Block", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf-30 Bb", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf15", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf15 Lip Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf15 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf15 Sunscreen Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf30 Calendula Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Spf30 Everyday/Year-Round Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Spf30 Sheer Touch Facial Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf30 Sheer Touch Tinted Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf30 Summer Blend Sunscreen", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Spf30 Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Spf30 Sunscreen And Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf30 Sunscreen And Spf15", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Spf30 Super Sensitive Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Spf50 Plus Mineral Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spf50 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spherusol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }] }] },
    {
        Medication: "Spigelia Anthelmia",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Spigelia Comp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spikevax", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Spinach",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Spinal Cord Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spinal Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spinalmax", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spine And Nerve Balance Core Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spineforce S-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spinosad", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spinraza", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intrathecal" }] }] },
    { Medication: "Spiny Pigweed", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Spiraea Ulmaria", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spiranthes Autumnalis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spiriva", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral; Respiratory (Inhalation)" }] }] },
    { Medication: "Spiriva Respimat", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Spiro Complex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Spironolactone",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Spironolactone And Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Spirulina/Chlorella Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spit And Polish Mineral Face Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spit And Polish Mineral Lip Balm Broad Spectrum Lip Protectant Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spit And Polish Sun Smart Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Splash Garden French Lavender", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Splash Of Silver Moisturizing Hand Sanitizer Peony Jasmine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Splash Of Silver Moisturizing Hand Sanitizer Tonka Bean And Myrrh", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Splash Of Silver Moisturizing Hand Sanitizer, Soft Rose And Oud", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Splash Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Spleen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spleen Stim Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spleen Thymus Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spleen-Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spleenex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spleenpar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Spleninum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Splizz Pain Reliever - Grapefruit-Flavored", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Splizz Pain Reliever - Orange-Flavored", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spongatos", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Spongebob Bubble Gum Scented Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Spongia Aurum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spongia Ovi", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Spongia Tosta",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Spongia Tosta 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Spongia Tosta Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spongilla Lacustris Mt", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sporanox",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Broad Spectrum Spf50 Water Resistant 80 Minutes Mineral Sunscreen Uva Uvb Protection Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Deodorant Extra Antiperspirant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Mineral Tinted Sun - Light", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Mineral Tinted Sun - Medium", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Natural Mineral Body Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Natural Mineral Tinted Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Sunscreen Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sport Sunstick Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sports Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sports For Trauma", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sports For Trauma Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical; Topical; Topical" }] }] },
    { Medication: "Sports Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sports Pain Relief Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sports Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sports Recovery", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sportscreme", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spot Check", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spot Correction", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spot On Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spot Stopper Acne Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spotex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spotless Acne Clearing Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spotless Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spotless Daily Acne Wash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spotless Regimen For Acne, Blemishes And Breakouts", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spotlight Mouthwash For Whitening Teeth", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spotlight Oral Care For Gum Health", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Spotlight Oral Care For Rebuilding Teeth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Spotlight Oral Care For Sensitive Teeth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Spotlight Oral Care For Total Care", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Spotlight Oral Care For Whitening Teeth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Spotlight Oral Care Kit For Bad Breath", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Spotlight Oral Care Kit For Sensitive Teeth", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Spotlight Pregnancy", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sprains Bruises", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sprains Strains", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sprains, Tendonitis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spravato", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sprayable Diaper Rash Cream", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sprayology Stress Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sprayology Travelease", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spraypain Away", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spread Love Not Germs Rainbow Sprinkles Hand Sanitizer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Spring Birch Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Spring Campus Hydrogel Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Spring Campus Skin Base", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sprinjene Natural", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sprinsol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sprintec", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Spritam", DoseTypes: [{ DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sprix", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sprycel", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Spy Elite Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravascular; Intravenous" }] }] },
    { Medication: "Spy Lymphatics Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravascular; Intravenous" }] }] },
    { Medication: "Spy-Mis Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravascular; Intravenous" }] }] },
    { Medication: "Spy-Phi Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravascular; Intravenous" }] }] },
    { Medication: "Squalane Zinc Sheer Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Squash", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Squatch 64 Natural Hand Sanitizer Crisp Citrus", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Squatch 64 Natural Hand Sanitizer Mountain Mint", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Squeaky Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Squeaky Clean Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Squeeky Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sronyx", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ss70 Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ssacsu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ssangbi Tang", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ssanggi Tang", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ssd Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ssencare", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sski", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sss Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ssssting Stop", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ssssting Stop Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ssug Ssag Zero V3Soap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "St. Ives",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "St. Joseph Chewable Low Dose Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "St. Joseph Low Dose Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "St. Supery Antiseptic Hand Rub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "St. Tropica Tropical Touch Beauty Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stage Fright-Fear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stain Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stalevo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stamaril", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Stamina", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stanback", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stance", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Standard Ansi First Aid", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Topical" }] }] },
    { Medication: "Standardized Bermuda Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Standardized Bermuda Grass Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Standardized Cat Hair",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Standardized Cat Hair Allergenic Extract", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Cat Pelt", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Bermuda Grass", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Bluegrass, Kentucky June", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Fescue, Meadow", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Grass Mix 4", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Orchard Grass", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Redtop", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Ryegrass", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Sweet Vernal Grass", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Grass Pollen, Timothy", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Kentucky (June) Bluegrass Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Kentucky Blue (June) Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Standardized Meadow Fescue Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Standardized Meadow Fescue Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Standardized Mite D. Farinae",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    {
        Medication: "Standardized Mite D. Pteronyssinus",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Standardized Mite Dermatophagoides Farinae", DoseTypes: [{ DoseType: "Concentrate", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Mite Dermatophagoides Pteronyssinus", DoseTypes: [{ DoseType: "Concentrate", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Mite Mix Dermatophagoides Farinae And Dermatophagoides Pteronyssinus", DoseTypes: [{ DoseType: "Concentrate", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Mite Mix, Dermatophagoides Pteronyssinus And Dermatophagoides Farinae, 10000 Au Per Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Standardized Mite Mix, Dermatophagoides Pteronyssinus And Dermatophagoides Farinae, 30000 Au Per Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Standardized Mite, Dermatophagoides Farinae, Bulk, 10000 Au Per Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Standardized Mite, Dermatophagoides Farinae, Scratch Or Bulk, 30000 Au Per Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Mite, Dermatophagoides Pteronyssinus, Bulk, 10000 Au Per Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Standardized Mite, Dermatophagoides Pteronyssinus, Scratch Or Bulk, 30000 Au Per Ml", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Mite, Mixed", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Standardized Orchard Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Standardized Orchard Grass Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Perennial Rye Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Standardized Perennial Rye Grass Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Standardized Perennial Ryegrass Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Redtop Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Standardized Redtop Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Sweet Vernal Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Standardized Sweet Vernal Grass Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Standardized Timothy Grass", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Standardized Timothy Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Stanley Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stannum 0.4", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stannum 12X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stannum 21X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stannum 30X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stannum 5",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Stannum 5% Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stannum 5X", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stannum 6", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stannum 8X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stannum Iodatum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stannum Metallicum",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stannum Succinum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stap Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stap Tab", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Staph/Strep Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Staph/Strep/Respiratory Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Staphylococcinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Staphysagria",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Staphysagria 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Staphysagria Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Star Drops", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Star Of Bethlehem", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Star War Mandalorian Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Star Wars Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Star Wars Mandalorian Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Star Wars The Mandalorian Fresh Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Star-San", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Star-San Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Starmanrx Survival Essentials Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Start Clean Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Start So Fresh", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Starter Set Dry/Normal Skin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Starter Set Oily/Normal Skin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stasis", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Dental; Periodontal; Subgingival" }] }] },
    { Medication: "State-48 Suncare Mineral Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stavudine", DoseTypes: [{ DoseType: "For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stay Ageless Daily Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Ageless Daily Moisturizer Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Ageless Daily Moisturizer Mineral Sunscreen Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Ageless Daily Moisturizer Mineral Sunscreen Broad Spectrum Spf30 Cream", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Ageless Tinted Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Ageless Tinted Mineral Sunscreen Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay All Day 10-In-1 Illuminating Skin Veil Broad-Spectrum Spf 30 Sunscreen Water-Resistant 80 Minutes Kitten", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Stay Awake",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stay Safe Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Safe Hand Sanitizer Alcohol Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Safe On The Go", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Well", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stay Well Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stay Well Hand Sanitizer Travel Kit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Moisturizing Hand Gel Sanitizer 2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Moisturizing Hand Gel Sanitizer 3.8L", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Moisturizing Hand Gel Sanitizer 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Scented Hand Gel Sanitizer Blueberry 2 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Scented Hand Gel Sanitizer Blueberry 8 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Scented Hand Gel Sanitizer Coconut 2 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Scented Hand Gel Sanitizer Coconut 8 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Scented Hand Gel Sanitizer Lemon Citrus 2 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Scented Hand Gel Sanitizer Lemon Citrus 8 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Scented Hand Gel Sanitizer Watermelon 2 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayclean Scented Hand Gel Sanitizer Watermelon 8 Fl.Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stayklear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Steady Freddy", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Steel City Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Steglatro", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Steglujan", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stelara",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Stellalife Vega Oral Care", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Dental; Sublingual" }] }] },
    { Medication: "Stellalife Vega Oral Care Coconut", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Stellalife Vega Oral Care Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental; Topical" }] }] },
    { Medication: "Stellalife Vega Oral Care Recovery", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Dental; Sublingual; Topical" }] }] },
    { Medication: "Stellalife Vega Oral Care Recovery Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Dental; Sublingual; Topical" }] }] },
    { Medication: "Stellalife Vega Oral Care Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Stellaria Media", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stem Cell Hmsc Cell Rejuvenation-Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stem Cellular Cc Cream Beach Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stem Cellular Cc Cream Deep Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stem Cellular Cc Cream Desert Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stem Cellular Cc Cream Natural Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stem Cellular Cc Cream Rosy Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stem Cellular Cc Cream Sun-Kissed Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stem Cellular Cc Cream Warm Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stemphylium", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Stemphylium Solani",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Stendra", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Steral Antiseptic Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterile", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterile Alcohol", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterile Alcohol Prep Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterile Alcohol Wipe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterile Antibacterial Wound Wash", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterile Cord Blood Collection Unit (Msc127D)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sterile Cord Blood Collection Unit (Msc128D)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Sterile Diluent",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Sterile Diluent For Remodulin", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Sterile Gentle Tears", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sterile Lubricant Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sterile Saline Kids Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sterile Saline Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Sterile Water",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intravenous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }, { Route: "Intravenous" }] },
            { DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] },
        ],
    },
    { Medication: "Sterilize Clean Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterillium Comfort", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterillium Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterillium Med", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterillium Rub Fragrance Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Steritalc", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Intrapleural" }] }] },
    { Medication: "Sterite Element", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterite Neutrum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterite Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Steritouch Antiseptic Hand Sanitizer With Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Steritouch Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sterix Safe Good Hand Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sterizar",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sterlomax Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterlomax Hand Sanitizer Professional", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Stern-X Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sterno Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sterno Performance Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Steuarts Pain Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stibium 0.4", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stibium 0.4 Adult Size Special Order", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Stibium 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stibium 20 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stibium 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stibium 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stibium 6 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stibium 6X Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sticta Pulmonaria", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stiff N Sore",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Stimate", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Stimulant Laxative",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stimulant Laxative Enteric Coated", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stimulant Laxative Plus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stimulant Laxative Plus Stool Softener", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sting And Bite", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sting Master", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sting Relief", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sting Relief Medicated Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sting Relief Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sting Relief Prep Pad", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sting Relief Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sting-Kill", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stings, Bites, Swellings", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stingx", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stiolto Respimat", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Stiptik", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stivarga", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stix 3 Day Yeast Infection Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Stix Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stix Restart", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sto Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stoko Gel Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stoko Refresh 4In1 Food Handler Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stoko Refresh 4In1 Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stoko Refresh 4In1 Instant Hand Sanitizer Sp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stoko Refresh Foaming Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stoko Sp. Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stokoderm Sun Protect 30 Pure", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stokoderm Sunscreen Pure", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stomach", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Ache", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Cramps", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Cramps 1", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Disorders", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Distress", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Enzyme Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stomach Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stomach Relief - Maximum Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Relief - Regular Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Relief Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Relief Ultra", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Relief, Maximum Strength", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Relief, Ultra Strength", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Spleen Meridian Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomach Spleen Meridian Opener", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomachforce A", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stomaplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stona",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Stona Cough",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stona For Children", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stona-S", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stonarhini", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stool Softener",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stool Softener And Laxative", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stool Softener And Stimulant Laxative",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stool Softener Docusate Sodium", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stool Softener Docusate Sodium 50 Mg", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stool Softener Laxative",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stool Softener Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stool Softener Plus Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stool Softener Plus Stimulant Laxative",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stool Softener Regular Strength", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stool Softener With Laxative", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stool Softener With Stimulant Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stool Softener-Stimulant Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stool Softening", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stool Softening Senna Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stool Softner", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stop Hair", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stop Rash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stop Smoking", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stop Smoking Aid", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stop Spot", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stop-Aging Peptide", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stop-Aging Peptide Essence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stop-Aging Peptide Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stop-Aging Peptide Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Stopain Clinical",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Stopain Clinical Continuous", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stopain Clinical Migraine And Headache", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stopain Clinical Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stopain Cold Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stopain Extra Strength Continuous", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stopain Extra Strength Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stopain Migraine Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stops The Sting", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stout Daily Spf For Men", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stozyme", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stramonium",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stranded Hand Sanitizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strataclear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stratagraft", DoseTypes: [{ DoseType: "Cellular Sheet", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Straton", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strattera", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stratuscare Adult Glycerin", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Stratuscare Bisacodyl", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Stratuscare Glycerin Laxative Pediatric", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Strawberries Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Strawberry",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Strawberry Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strawberry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strawberry Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strawberry Limoncello Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strawberry Scent Anti Germ", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strawberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strawberry Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stre Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stre Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stream2Sea Broad Spectrum 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stream2Sea Broad Spectrum 20 - Tinted", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stream2Sea Broad Spectrum 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stream2Sea Broad Spectrum 30 - Tinted", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stream2Sea Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strensiq", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Strep Plus/Pandas", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Streptococcinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Streptococcus Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Streptococcus Series Therapy Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Streptomycin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Stress / Fatigue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stress Control", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stress Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Stress Less", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Stress Relief",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Oral" }, { Route: "Oral; Topical" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stress Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Stress-Tension",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Stresscalm",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Stribild", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stride Products Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stridex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stridex Xl Face And Body Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strike Bac Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "String Green Bean", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Stringbean", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Strivectin Advanced Retinol Daily Repair Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strivectin Advanced Retinol Day Moisturizer Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strivectin Advanced Spf Full Screen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strivectin Multi-Action Super-C Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strivectin-Sh Nia-114 Uva Uvb Shields Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Striverdi Respimat", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Stromectol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strong Iodine Solution", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strong Iodine Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Strontium Carbonicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strontium Chloride Sr-89", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Strophanthus", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strophanthus 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strophanthus 4 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Strophanthus Aurum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Strophanthus Aurum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strophanthus E Sem. 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Strophanthus Hispidus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Strophanthus Nicotiana",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Strovite One", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Stryx Daily Moisturizer Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stubble And Stache Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio 35 External Analgesic Medicated Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Blend Cover Foundation Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Finish Concealer Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Fix Fluid Spf 15 Broad Spectrum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Almendra 230-N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Avellana 270-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Avena 170-N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Azucar Blanca 110-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Azucar Moreno 300-N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Bombon 400-N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Brownie 380-F", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Cacao 350-N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Canela 280-F", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Caramelo 210-F", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Chantilly 130-F", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Chocochip 330-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Chocotrufa 430-N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Dulce De Leche 260-N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Espresso 450-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Miel 200-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Nuez 180-F", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Panela 320-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Toffee 240-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Look Base De Alta Cobertura Para El Rostro Con Fps 20 Vainilla 140-C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Studio Sculpt Spf 15 Broad Spectrum Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Stuvz Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Stye",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Stye Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Stye Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Styptstix", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Su Phedrine Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Suave",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Suave 75% V/V Alcohol Wipes", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suave Essentials - Antibacterial Deeply Clean Refreshing Deodorant Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suave Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suave Hand Sanitizer Professional", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suavear Earwax Removal Aid", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Subir", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Subir Eyelash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sublocade", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Suboxone", DoseTypes: [{ DoseType: "Film, Soluble", Routes: [{ Route: "Buccal; Sublingual" }] }] },
    { Medication: "Substance Abuse Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Subsys", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Subvenite",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Subway Alcohol Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Succinylcholine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    {
        Medication: "Succinylcholine Chloride",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Parenteral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] },
        ],
    },
    { Medication: "Sucraid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sucralfate",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sucrets", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sucrets Sore Throat Wild Cherry", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sucrets Vapor Cherry", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed 12 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Pe Congestion", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Pe Day Plus Night Sinus Congestion", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sudafed Pe Head Congestion Plus Flu Severe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Pe Head Congestion Plus Mucus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Pe Head Congestion Plus Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Pe Pressure Plus Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Pe Pressure Plus Pain Plus Cough", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Pe Pressure Plus Pain Plus Mucus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sudafed Pe Sinus Congestion",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sudafed Pe Sinus Pressure Plus Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Sinus 12 Hour Pressure Plus Pain", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Sinus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Sinus Congestion 12 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudafed Sinus Congestion 24 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudden Change Derma Renewal Complex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sudden Nausea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sudogest",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sudogest 12 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudogest Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sudogest Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Suds Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suds High Foaming Antiseptic Hand And Body Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sufentanil Citrate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Epidural; Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Intravenous" }] },
        ],
    },
    { Medication: "Sufiya", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar And Food Additive Allergen Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sugar Beet Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Sugar Bloom Lip Treatment Sunscreen Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Bloom Tinted Treatment Sunscreen Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Brands Rescue Pain Relief And Recovery", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Cookie Cheer Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Cookie Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Cravings", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Black Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Cherry Fishermans Friend Menthol Cough Suppressant", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Cough And Chest Congestion Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Green Tea With Echinacea Cough Suppressant Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Hiney Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Honey Lemon Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Honey-Lemon Fishermans Friend Menthol Cough Suppressant", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Lemon Mint Herb Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Menthol Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Menthol Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Mountain Herb Cough Suppressant Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Original Swiss Herb Cough Suppressant Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Refreshing Mint Fishermans Friend Menthol Cough Suppressant", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Free Swiss Cherry Herb Throat Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Hard Maple Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Sugar Honey Tinted Treatment Sunscreen Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Lip Treatment Sunscreen Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Mix System Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sugar Orchid Tinted Lip Treatment Sunscreen Spf15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Petal Tinted Treatment Sunscreen Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Rose Lip Treatment Sunscreen Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Rose Tinted Lip Treatment Sunscreen Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Rush No Bullzit Acne Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Rush Skin Treat Poreless Tinted Moisturizer Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugar Spice Lip Treatment Sunscreen Spf 15", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sugared Lemon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sular", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sulconazole Nitrate",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sulfacetamide", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Sulfacetamide Sodium",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Sulfacetamide Sodium And Prednisolone Sodium Phosphate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sulfacetamide Sodium And Sulfur", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sulfacetamide Sodium, Sulfur",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sulfacleanse 8/4", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfadiazine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sulfameth/Trimeth-Ds", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sulfamethox-Tmp Ds", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sulfamethoxazole And Trimethoprim",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sulfamylon",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Sulfasalazine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sulfatrim", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sulfo Lo", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sulfur",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] },
        ],
    },
    { Medication: "Sulfur 3 Special Order", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sulfur 30 Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sulfur 3X", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sulfur 8 Anti-Dandruff Braid", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Aqua Blue", DoseTypes: [{ DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Five Star Anti-Dandruff Hair Groom", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Fresh Anti-Dandruff", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Fresh Anti-Dandruff Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Fresh Anti-Dandruff Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Fresh Anti-Dandruff Oil Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Kids Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Light Anti-Dandruff Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Light Moisturizer Anti-Dandruff", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Loc Twist And Braid Scalp", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Oil Moisturizer Anti-Dandruff", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Original Anti-Dandruff Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sulfur 8 Scalp Therapy Medicated Dandruff Control",
        DoseTypes: [
            { DoseType: "Shampoo, Suspension", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sulfur 8 Scalp Therapy Medicated Dandruff Control Leave-In Soothing Treatment", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Scalp Therapy Medicated Dandruff Control Scalp Butter Cream", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur 8 Scalp Therapy Medicated Dandruff Control Scalp Oil Serum", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur Calming Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur Facial Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulfur Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulindac", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sulphur",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Sulphur 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Sulphur Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sulphur Iodatum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sulphur Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sulphur Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sulphuricum Acidum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sulur 8 Kids Anti-Dandruff Hair And Scalp Conditioner", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Perfecting Cushion No. 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Perfecting Cushion No. 21", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Perfecting Cushion No. 23", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Perfecting Cushion No. 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Perfecting Cushion No. 27", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Perfecting Cushion No. 31", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Perfecting Cushion No. 37", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Perpecting Cushion Brightening No.23 Medium Beige", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Snowise Uv Daily Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sulwhasoo Uv Daily Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sumadan", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sumadan Wash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sumansetron", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Sumatriptan",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sumatriptan And Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sumatriptan Succinate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sumatriptan Succinate And Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sumaxin", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sumaxin Cp", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sume Apothecary Alcohol Free Hand Sanitizer Fresh", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sume Apothecary Alcohol Free Hand Sanitizer Lavender And White Sage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sumikkogurashi Aloe Vera Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summer Breeze Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summer Fridays Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summer Fridays Shadedrops Broad Spectrum Spf 30 Mineral Milk Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summer Sangria Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summers Eve", DoseTypes: [{ DoseType: "Douche", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Summers Eve Active Chafe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 362 Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 367 Antibacterial Foam", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 367 Foaming Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 375 Gel Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 375 No Rinse Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 377 Alcohol Free Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 377 No Rinse Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 378 Foam Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit 378 Foaming Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Summit E2", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Basics", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Body Protector Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Clear Zinc Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Daily Mineral Sunscreen Moisturizer Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Daily Sunscreen Face Mist Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Daily Sunscreen Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Face 50 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Face Mist Spf 45", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Face Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Glow 30 Moisturizing Sunscreen Face Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Hand Cream Spf15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Mineral Spf 30 Tinted Sunscreen Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Mineral Spf30 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sun Bum Mineral Sunscreen Broad Spectrum Spf 50",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sun Bum Mineral Sunscreen Face Stick Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Mineral Sunscreen Roll On Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Premium Moisturizing Sunscreen Roll On Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Premium Spf 15 Sunscreen Tanning", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Premium Sunscreen Browning Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Signature Clear Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Signature Spf 30 Sunscreen Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Skin Care Essentials Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Sun Bum Spf 15 Premium Moisturizing Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Sun Bum Spf 30 Premium Moisturizing Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Sun Bum Spf 50 Premium Moisturizing Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Sun Bum Spf 70 Premium Moisturizing Sunscreen",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sun Bum Sunscreen Lip Balm Broad Spectrum Spf 30 Banana", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Sunscreen Lip Balm Broad Spectrum Spf 30 Coconut", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Sunscreen Lip Balm Broad Spectrum Spf 30 Keylime", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Sunscreen Lip Balm Broad Spectrum Spf 30 Mango", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Sunscreen Lip Balm Broad Spectrum Spf 30 Pineapple", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Sunscreen Lip Balm Broad Spectrum Spf 30 Pomegranate", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Sunscreen Lip Balm Broad Spectrum Spf 30 Watermelon", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Sunscreen Oil Spf 30 With Coconut Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Bum Tinted Sunscreen Face Uva/Uvb Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Defense Canyon Ranch", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Defense Canyonranch", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Defense Mineral Oil-Free Sunscreen Spf 30 Baby", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sun Face Protector Sunscreen Broad Spectrum Spf 50",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sun Goo Mineral Sun Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Guard", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Headache Rheumatism", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sun Mark Mucus Relief Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sun Mark Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sun Party Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Project Light Sun Essence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Project Shimmer Sun Essence", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Project Silky Calming Sun Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Project Skin-Relief Suncream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Project Water Sun Cream Spf50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Protect Lip Balm Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Screen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Shades", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Shades Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Shades Mineral Plus Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Shield Broad Spectrum Spf 50 Matte", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sun Shield Clear",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sun Shield Mineral Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Shield Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Sublime Protective Face Care Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Therape Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Therape Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Therape Face And Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Therape Pro Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Therape Pro Sport Face And Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Therape Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Ultimate Face Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun X Multi-Pack Spf 30 Thick", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun X Spf 50 Broad Spectrum Sunscreen Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun Xspf 30 Broad Spectrum Sunscreen Lip Balm", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sun-Kissed Suncream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunbetter Broad Spectrum Spf 56 Sunscreen Compact Sheer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunbetter Broad Spectrum Spf 56 Sunscreen Stick Sheer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunbetter Broad Spectrum Spf 68 Sunscreen Compact Tone Smart", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunbetter Broad Spectrum Spf 70 Sunscreen Sheer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunbetter Broad Spectrum Spf 75 Sunscreen Tone Smart", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunblocz", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunblocz Baby And Kids", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunburn Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunburn Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunburnt Advanced After-Sun", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunburnt Plus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunburnt Plus Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suncare Essentials", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Suncreen Daily Defense Lather", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunday Riley Saturn Sulfur Acne Spot Treatment Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunday Riley Sulfur Acne Treatment Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunday Riley U.F.O Acne Treatment Face Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunday Riley Ultra-Clarifying Acne Treatment Face", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sundrops 67", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sundrops 71", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sundrops 75", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sundrops 77", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sundrops 87", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunflower", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Sunflower Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Sunforgettable Brush-On Sunscreen Spf 30", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Tint Du Soleil Foundation Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Face Protection Face Shield Classic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Body Shield", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Body Shield Bronze", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Brush-On Shield Spf 50", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Color Balm (Berry)", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Color Balm (Blush)", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Color Balm (Bronze)", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Face Shield Bronze", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Face Shield Flex", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Face Shield Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Face Shield Matte", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Face Shield, Bronze", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Sheet Matte Sunscreen Spf 30", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunforgettable Total Protection Sport Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunfree Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunfrog Ultra Spf 30 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunglitter Princess", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunitinib Malate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunleya Age Minimizing Sun Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunlight Daily Facial", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunlindac", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sunmark",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Oil", Routes: [{ Route: "Oral" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sunmark Acid Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Adult Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Adult Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark All Day Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sunmark All Day Allergy D",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sunmark Allergy Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Allergy Relief Nasal 24 Hr", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sunmark Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Antacid Maximum Strength Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sunmark Anti Diarrheal",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sunmark Anti Nausea", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Arthricream Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Arthritis 8 Hour", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Arthritis Pain", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Arthritis Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sunmark Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sunmark Benzoin Compound Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Calcium Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Caldiphen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Caldyphen Clear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Camphor Spirit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Castor Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Chest Congestion Relief Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Chest Congestion Relief Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Childrens Ibuprofen Ib", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Childrens Loratadine Srp Sf Grape", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Clotrimazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Cold And Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Cold And Cough Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Cold And Flu Severe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Cough Dm", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Day Time", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Extra Strength Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Eye Drops Advanced Moisturizer/Lubricant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sunmark Eye Drops Original Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sunmark Fexofenadine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Fiber Laxative", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Heartburn Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Sunmark Hydrocortisone Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Hydrocortisone With Aloe", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Hydrogen Peroxide 3 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sunmark Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Sunmark Ibuprofen Ib",
        DoseTypes: [
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sunmark Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Iodides Tincture", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Iodine", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Isopropyl Alcohol 70 Percent With Wintergreen Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Isopropyl Alcohol 91 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Isopropyl Rubbing Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Isopropyl Rubbing Alcohol 70 Percent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Lice Solution Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sunmark Lice Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Loratadine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Loratadine D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Lubricant Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sunmark Lubricating Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sunmark Lubricating Tears", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sunmark Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sunmark Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Sunmark Miconazole Nitrate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Milk Of Magnesia Mint", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sunmark Milk Of Magnesia Original",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sunmark Mineral Oil", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Sunmark Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sunmark Nasal Decongestant Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sunmark Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sunmark Nite Time", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Nose", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Sunmark Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Sunmark Pain Reliever",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sunmark Phenolated Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Povidone-Iodine Topical", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmark Redness Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Sunmark Regular Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Regular Strength Antacid Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Saline Single", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Sunmark Severe Day Time", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Severe Nite Time Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Sunmark Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Tussin Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Urinary Pain Relief Maximum Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunmark Witch Hazel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmed Topical With Menthol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunmitt Broad Spectrum Spf-50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunna Pure Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunny Q Broad Spectrum Spf-30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunny Tropicolada Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunnydays Broad Spectrum Spf 30 Tinted Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sunnyscreen Spf 50",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sunnyscreen Spf 50 Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunosi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sunplus Sunscreen Laguna", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunplus Sunscreen Yosemite Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunproof 3-In-1 Invisible Priming Sunscreen Broad Spectrum Spf 37 Water Resistant (80 Minutes)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunproof Intensive Lip Balm Broad Spectrum Spf 24 Sunscreen Fresh Mint", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunproof Intensive Lip Balm Broad Spectrum Spf 24 Sunscreen Sweet Tangerine", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sunscreen",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sunscreen And Amphibious", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sunscreen And Insect Repellent",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sunscreen Broad Spectrum Spf 30 Light Tropical Scent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Broad Spectrum Spf 55 Moisturizing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Cream Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Drops", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Face And Body Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Flawless Skin Fluid Tinted", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen For Face Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen For Kids", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen For Kids Spf50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Karina Skin Care Spf 15", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Mist Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Mist Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Plus Powder Broad-Spectrum Spf 45", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Spf 50 Plus Broad Spectrum Uva Uvb Protection", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Sprays", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen Ultra Broad Spectrum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen With Aloe Vera Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunscreen With Uv Pearls Broad Spectrm Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunshield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunshine And Glitter", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunshine On The Go Natural Spf 30Plus Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunshine Skin Tint Broad Spectrum Spf 30 - Refill", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunshine Skin Tint Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunshine Skin Tint Spf 30 Broad Spectrum Spf 30 - Refill", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunsorb Reef Safe Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunsorb Reef Safe Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunsorb Reef Safe Spf50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunstar G.U.M Hydral Dry Mouth Relief", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Sunstation All-Natural Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Suntone",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sunx Spf 30/Bugx Wallmount", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Suny-Tizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunzee", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sunzen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suodan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Super Amazing Revolution For Eye", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Amazing Revolution For Face", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Amazing Revolution For The Youth Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Bb Beauty Balm Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Bb Beauty Balm Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Blue Stuff Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Cc Color-Correction Care All Over Blur Cc Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Cc Plus Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Cell Two", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Super Clean Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Colon Cleanse", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Super Cream 229", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Creme Solaire Corps Body Sun Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Max Barrier Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Mint Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Sensitive Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Sensitive Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Serums Collection", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Sparkle Screen Broad Spectrum 30 Sunkissed Gold Glitter Tangerine Scented", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Sparkle Screen Broad Spectrum 30 Sunkissed Pink Glitter Strawberry Scented", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Spot Remover Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super Suds Anti-Bacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super T", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super White Stuff Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Super-Stop Bloodclotter", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Superba", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Superdefense Broad Spectrum Spf 25 Fatigue Plus 1St Signs Of Age Multi-Correcting", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Superdefense Broad Spectrum Spf 25 Fatigue Plus 1St Signs Of Age Multi-Correcting Very Dry To Dry Combination", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Supergoop Daily Hydra-Ceramide Boost Spf40", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Supergoop Glow Screen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Superguard Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Supernatural Poreless Tint Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Superscreen Daily Moisturizer Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Supersmile", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Supersmooth Acne Clear 5 Min Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Supervalue Equaline Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Suphedrine Pe",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Suphedrine Pe Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Suphedrine Pe Pressure Plus Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Suphedrine Pe Sinus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Suphedrine Pe Sinus Plus Allergy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Suphedrine Pe Sinus Pressure Plus Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Supprelin", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Suppress Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suppress Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Supraderm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suprane", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Suprax",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Supreme", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Supreme Antacid", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Supren", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Suprep Bowel Prep", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Supress A", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Supress Dm", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Supress Dx Pediatric Drops", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Supress-Pe", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sure Antiperspirant Deodorant Regular", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Antiperspirant Deodorant Unscented", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Invisible Solid Fresh And Cool", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Invisible Solid Nourishing Coconut", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Invisible Solid Powder Scent", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Invisible Solid Regular", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Invisible Solid Unscented", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Original Solid Fresh And Cool", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Original Solid Powder", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Original Solid Regular", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Original Solid Unscented", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sure Safe Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sure-Pure Sanitizing Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Surely Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suresafe Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sureway Brand Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surf And Sand Resort Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surf Sani All-Purpose Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Dry Touch Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Sheer Touch Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Sheer Touch Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Sun Dry Touch Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Sun Dry Touch Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Sun Sheer Touch Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Sun Sheer Touch Spf30 Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surface Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surfers Barrier", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surfers Barrier Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surge Industrial Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surgent Antiseptic", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surgery/Trauma", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Surgical And Healthcare Personnel Hand Antiseptic With Moisturizers", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surgical Scrub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suria Bubble Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suria Calming Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suria Premium Jojoba Foam Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suria Renew Intensive Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suria Whitening", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Surreninum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Surreninum 200K", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Surreninum 4Ch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Survanta", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Endotracheal" }] }] },
    { Medication: "Surya Natural Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Susanne Kaufmann Suncare Body Broad Spectrum Protection Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Susanne Kaufmann Suncare Face Broad Spectrum Protection Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sustafix", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sustiva",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Sustol", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Susvimo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravitreal" }] }] },
    { Medication: "Sutab", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sutent", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Suture Syringe Medic", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Suzanne Organics Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Svens Everyday Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Swab", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Swabs",
        DoseTypes: [
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Swagon Hand Sanitizer Advanced Aloe Vera Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sweatblock",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Swedish Dream Sea Aster Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Swedish Dream Sea Salt Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Swedish Dream Seaweed Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sween 24", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweep", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sweet Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Respiratory (Inhalation); Topical" }] }] },
    { Medication: "Sweet Carefor Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Cheek Diaper Cream", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Cheeks", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Cheeks Diaper", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Cinnamon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Coconut Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Comfort", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Corn", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Sweet Grace Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Gum Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Sweet Heart Antibacterial Hand Sanitizer Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Heart Antibacterial Hand Sanitizer Strawberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Heart Antibacterial Hand Sanitizer Tropical Fruit Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Honey Lip Balm", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Ones", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Pea Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Pea Blossom Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Pea Scented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Pea Sheer Shield Spf45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sweet Potato",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Sweet Shimmer Hand Sanitizer Toasted Vanilla", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Skin Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Stardust Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Strawberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sweet Sugar Fantasy Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Sweet Vernal Grass, Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Sweetgum Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Swim-Ear", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Swimmers Ear Complete Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Swimmers Ear Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Swimmers Ear Relief Ear Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Swimmers Friend", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Swimmers Itch Guard", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Swine Flu H1N1 Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Swipe Sterile Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Swisher",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Swisher Tf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Swiss Navy Endurance Rx", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Swiss Navy Endurancerx", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sx1 Medicated Post-Operative System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Syagrus Romanzoffiana Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Sycosis Miasm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sycosis Miasm Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Sycuan Casino Resort Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Syeda", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Sylvant", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Symax Duotab", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symax Sl", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symax Sr", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symbicort", DoseTypes: [{ DoseType: "Aerosol", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Symbyax", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symdeko", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Symfi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symfi Lo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symjepi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Symlinpen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Symmetry Antimicrobial Foaming Hand Wash",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Symmetry Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Symmetry Foaming Hand Sanitizer Fragrance Free With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Symmetry Foaming Hand Sanitizer With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Symmetry Foaming Hand Sanitizer-Fragrance Free With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Symmetry Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Symmetry Hand Sanitizer With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Symmetry Non-Alcohol Foaming Hand Sanitizer With Allantoin And Aloe",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Sympazan", DoseTypes: [{ DoseType: "Film", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symphoricarpus Racemosus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symphytum Hamamelis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symphytum Off", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symphytum Off Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Symphytum Officinale",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Symphytum Officinale 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Symphytum Stannum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symphytum Stannum Bone Support", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symproic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Symtuza", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Synagis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Synalar",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Synalar Ts", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Synaptol", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Synarel", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Syndros", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Synera", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Synercid", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Synergy Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Synjardy",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Synoflex Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Synribo", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Synthroid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Syphilinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Syphilinum Miasm Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Syphilitic Miasm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Syprine", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Syren Intimate Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Syren Intimate Relief Topical Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sysco", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Sysco Reliance", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Systane",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Systane Balance", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Systane Complete", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Systane Complete Pf", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Systane Day And Night Value Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Systane Hydration Pf Preservative Free", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Systane Lubricant", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Systane Nighttime", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Systane Ultra", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Systane Ultra Preservative Free", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Systemic Detox",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Systemic Drainage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Systemistat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Syzygium Jambolanum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "T Relief Arthritis", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "T Relief Arthritis Extra Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "T-1", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-10", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-11", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-12", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-13", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-14", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-15", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-16", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-17", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-18", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-19", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-2", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-20", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-21", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-22", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-23", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-24", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-25", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-26", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-27", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-28", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-29", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-3", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-30", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-31", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-32", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-33", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-34", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-35", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-36", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-37", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-38", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-39", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-4", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-40", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-41", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-42", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-5", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-6", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-7", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-8", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-9", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "T-Chem Anti-Microbial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "T-Chem E2 Rated", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "T-O-S Standardized Grass Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "T-Relief",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "T-Relief Arthritis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "T-Relief Extra Strength",
        DoseTypes: [
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "T-Relief Extra Strength Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "T-Relief Extra Strength Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "T-Relief Pro", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "T-Relief Pro Arthritis", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "T-Relief Pro Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "T-Relief Pro Spasm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "T.R.U.E. Test", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tabacum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tabloid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tabrecta", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tachosil", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Taclonex",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Tacrolimus",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tacrus", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tact", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tact Cool", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tadalafil",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Taeyangsoo Plavital Eye Allcream", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Taeyangsoo Plavital Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tafinlar", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tag Alder Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Tag Away", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tagamet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tagitol V", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tagrisso", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Takahi Hot Medicated Plaster", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Take Action", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Take It Above And Beyond Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Takhzyro",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Subcutaneous" }] },
        ],
    },
    { Medication: "Talc-Free Medicated Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Talicia", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Talk To The Palms Hand Sanitizer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tall G U", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taltz", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Talzenna", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tamiflu",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tamoxifen Citrate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tamsulosin Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tan Towel Spf 30 Bronzing Mist", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tan Towel Spf 30 Clear", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tan-Luxe Super Glow Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tanac Oral Pain Reliever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tanceuticals Spf Drops Broad Spectrum Spf-30", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tania Pure Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tania Purecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tanners Tasty Cha Cha Chocolate", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tanners Tasty Ooh La La Orange", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tanners Tasty Vanilla Bling", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tanning Sunscreen Uvb Protection Dry Oil", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tanri Sunscreen Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tanri Sunscreen Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tap Water Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taperdex 12-Day", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taperdex 6-Day", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taperdex 7-Day", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tapeworm Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taraxacum (M)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taraxacum Comp.", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taraxacum E Pl. Tota 2 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Taraxacum Officinale",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Taraxacum Stanno Culta 3X Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tarceva", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tarentula Cubensis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tarentula Hispana",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Targadox", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Target",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Target Advanced Scar Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Antacid - Anti-Gas Relief Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Target Antacid Fruit Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Target Cough Plus Chest Congestion Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Target Eye Drops Maximum Redness Relief Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Target Maximum Strength Cold Flu And Sore Throat",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Target Overnight Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Target Sensitive Whitening Tp", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Target Strawberrylime Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Target Up And Up 2-In-1 Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Acne Medication", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Acne Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up After Sun Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Broad Spectrum Spf 55 Oil-Free", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Foaming Body Wash Acne Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Kids Mineral Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Kids Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Kids Spf 50 Clear", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Mineral Based Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Mineral Sunscreen Broad Spectrum Spf 50 Reef-Conscious Formula", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Oil Free Acne Wash Pink Grapefruit Foaming Scrub", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Reef Conscious Formula Spf 50 Sport Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Spf55 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Spf55 Sunscreen Reef-Conscious Formula", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Sport Spf 15 Clear", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Sport Spf 30 Clear", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Sunscreen Spf 30 Sport", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Sunscreen Spf 50 Kids", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Up And Up Sunscreen Sport Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Target Wild Berry Antacid Chews 60Ct", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Target Zinc Cold Therapy", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Targeted Body Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Targretin",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tarina 24 Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tarina Fe 1/20", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tarina Fe 1/20 Eq", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tarmac", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tarocco Hand Sanitizer Sicilian Blood Oranges", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tarocco Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tarpeyo", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tarsum Professional", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tartar Control", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tartar Control Plus", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tartarus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tarteguard 20 Tinted Moisturizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tarteguard 30 Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tarteguard Mineral Powder Spf 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tasigna", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tasmar", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tasoprol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tatcha The Silk Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tattoo After Care", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tattoo Care", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tattoo Goo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Taurimmune Fatigue", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Taurine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taurox Immune Support", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Tauvid", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Tavaborole", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tavalisse", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tavneos", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tavos Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tavos Liquid Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Taxodium Distichum Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Taysofy", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Taytulla", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Tazarotene",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tazicef", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] }] },
    {
        Medication: "Tazorac",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Cutaneous" }] },
            { DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] },
        ],
    },
    { Medication: "Taztia", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tazverik", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tazza", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tazza Antiseptic Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tazza Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tazza Hand Sanitizer", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tazza-X Extreme Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tazza-X Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tbs Aloe Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tbs Vitamin E Spf", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tc Enriched Foam Alcohol Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tc Max", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tci Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tcm Brand Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tcs Weightless Blend Everyday Primer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tcx Skincare", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Td Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tdvax", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Te-Fu Recovery Massage", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tea Tree Daily Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tea Tree Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tea Tree Lip Balm, Spf 15", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tear Stimulation Forte", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Tears Again", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intraocular" }] }] },
    { Medication: "Teatrical Skin Lightening", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tecartus", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Tecentriq", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Tecfidera",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Tech Care Earwax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Tech Care Earwax Removal Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Technelite", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Technescan Hdp", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Technescan Mag3", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Technescan Pyp", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Technetium Tc 99M Medronate", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Technetium Tc 99M Sestamibi",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Tecnologia Y Soluciones En Alimentos S.A De C.V", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tecnu Calagel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tecnu Rash Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ted S Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teds Topical Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teds Topical Pain Gel Roll On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teen Toothpast E Eucalyptus Flavor", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teen Toothpast E Peach Leaf Flavor", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teeter Renew Muscles Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teeter Renew Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teeth And Gums", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Teeth-Gums",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Teething",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Teething Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teething Irritability", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teething Pain", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Teething-Colic",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Teething-Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teflaro", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Tego Systems Professional Grade Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tegretol",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tegsedi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Tegumentinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teint Couture City Balm - Radiant Perfecting Skin Tint 24H Wear Moisturizer Urban Shield Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture City Balm Radiant Perfecting Skin Tint 24H Wear Moisturizer Urban Shield Spectrum Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 N203", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 N365", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 N430", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 N470", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 N98", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P100", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P105", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P110", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P115", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P200", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P210", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P300", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P340", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P350", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P395", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P400", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P450", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 P95", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y100", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y105", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y110", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y200", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y205", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y207", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y210", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y215", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y300", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y305", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y310", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y315", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y325", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Couture Everwear 24H Wear Satin Finish Full Coverage And Comfort Broad Spectrum Spf 20 Y400", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Idole Ultra 24H Makeup Octinoxate Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teint Miracle Octinoxate Sunscreen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tekturna", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tekturna Hct", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tellurium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Telmisartan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Telmisartan And Amlodipine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Telmisartan And Hydrochlorothiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Telmisartan And Hydrochlorthiazide", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Telomere - Dna", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Temazepam", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tembexa",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Temixys", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Temodar",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Temovate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Temozolomide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Temper Tamer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Tempo Cool", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Temporary Fibromyalgia Pain And Discomfort Relief", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Temsirolimus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Intravenous" }] }] },
    { Medication: "Tena", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tena Barrier Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tena Proskin Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tena Proskin Fragrance-Free Body", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tena Proskin Protective", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tena Protective Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tenacious",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tencon", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tender Moments Baby Sunscreen Broad Spectrum Spf 50 Water Resistant (40 Minutes)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tender Moments Delicate Baby Bottom Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tender Moments Delicate Baby Diaper Rash Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tenderlaven Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tendersant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tendon Rescue", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tenivac", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Tenofovir Disoproxil Fumarate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tenofovir Disproxil Fumarate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tenoretic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tenormin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tension Headache",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tension Headache Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tenue De Perfection Timeproof Foundation With Sunscreen Ultimate Lasting Perfection Broad Spectrum Spf 20 01 Beige Pale",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Tenue De Perfection Timeproof Foundation With Sunscreen Ultimate Lasting Perfection Broad Spectrum Spf 20 02 Beige Clair",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Tenue De Perfection Timeproof Foundation With Sunscreen Ultimate Lasting Perfection Broad Spectrum Spf 20 03 Beige Naturel",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Tenue De Perfection Timeproof Foundation With Sunscreen Ultimate Lasting Perfection Broad Spectrum Spf 20 04 Beige Moyen",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tenue De Perfection Timeproof Foundation With Sunscreen Ultimate Lasting Perfection Broad Spectrum Spf 20 12 Rose Clair", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tenue De Perfection Timeproof Foundation With Sunscreen Ultimate Lasting Perfection Broad Spectrum Spf 20 13 Rose Naturel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tepadina", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intracavitary; Intravenous; Intravesical" }] }] },
    { Medication: "Tepezza", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Tepmetko", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Terazosin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Terazosin Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Terbinafine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Terbinafine Hydrochloride",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Terbutaline Sulfate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Terconazole",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] },
        ],
    },
    { Medication: "Terebinthina", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Terebinthina Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teriflunomide", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teriparatide", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Terminator 10", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Terocin",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Terpenicol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terra Pure Sanitizing Hand Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terra Pure Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terracotta Bronzing Mist With Sunscreen Spf 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terracotta Joli Teint Beautifying Foundation With Sunscreen Sun-Kissed, Healthy Glow Broad Spectrum Spf 20 Dark", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terracotta Joli Teint Beautifying Foundation With Sunscreen Sun-Kissed, Healthy Glow Broad Spectrum Spf 20 Ebony", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terracotta Joli Teint Beautifying Foundation With Sunscreen Sun-Kissed, Healthy Glow Broad Spectrum Spf 20 Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terracotta Joli Teint Beautifying Foundation With Sunscreen Sun-Kissed, Healthy Glow Broad Spectrum Spf 20 Medium", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terracotta Joli Teint Beautifying Foundation With Sunscreen Sun-Kissed, Healthy Glow Broad Spectrum Spf 20 Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrafreeze Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terragen Hg Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terranea Cares Wellness Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Terrasil Anti-Fungal Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Antibacterial Skin Repair 200G", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Antifungal Cleansing Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Antifungal Cream Maximum Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Arthritis Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Athletes Foot Antifungal Treatment Max", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Back Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Balanitis Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Bed Sore And Pressure Sore Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Burn Care And Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Burn Care And Relief Maximum Strength", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Diabetics Ulcer And Sore Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Eczema And Psoriasis", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Terrasil Fast And Natural Wart Removal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Flex -Pain Relief And Muscle Rub Max", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Flex Pain Relief And Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Infection Protection Wound Care", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Infection Protection Wound Care Maximum Strength", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Ipl Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Itch, Rash And Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Itch, Rash And Pain Relief Maximum Strength", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Jockitch Treatment Maximum Strength", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Joint Pain Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Molluscum Skincare", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Rapid-Relief Vaginal", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Terrasil Ringworm Anti-Fungal Cleansing Bar 75G", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Ringworm Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Serious First Aid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Serious Fungal Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Terrasil Serious Tinea Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Terrasil Skin Repair", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Tinea Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Tinea Treatment Maximum Strength", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terrasil Wart Remover For Kids 14G", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Terravitals Baknek", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Terravitals Lobak", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Terravitals Midbak", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Terrell", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Terrestristat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teruflex Blood Bag System Anticoagulant Citrate Phosphate Dextrose (Cpd) And Optisol Red Cell Preservative", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Teruflex Blood Bag System Anticoagulant Citrate Phosphate Dextrose Adenine (Cpda-1)", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Teruflex Blood Bag System With Blood Sampling Arm Anticoagulant Citrate Phosphate Dextrose (Cpd) And Optisol (As-5) Red Cell Preservative", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Teruflex Blood Bag System With Diversion Blood Sampling Arm Anticoagulant Citrate Phosphate Dextrose (Cpd) And Optisol (As-5) Red Cell Preservative", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Test Flex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Testall", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Testes Apis",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Testes Apis Special Order", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Testim", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Testone Cik", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Testopel", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Testopro", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Testosterone",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }, { Route: "Transdermal" }] },
            { DoseType: "Gel, Metered", Routes: [{ Route: "Topical" }, { Route: "Transdermal" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Testosterone Cypionate",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }, { Route: "Intramuscular; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    { Medication: "Testosterone Enanthate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Testosterone Gel, 1%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Testozole", DoseTypes: [{ DoseType: "Pellet, Implantable", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Tetra Now", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tetrabenazine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tetracaine", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Oral" }, { Route: "Subarachnoid" }] }] },
    { Medication: "Tetracaine Hcl", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subarachnoid" }] }] },
    {
        Medication: "Tetracaine Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Tetracycline 3 Percent A First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tetracycline Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tetracyte Topical",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tetracyte Topical - Tetracycline Hydrochloride", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tetracyte Topical Tetracycline Hydrochloride", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tetraviv Advanced Cold Sore Care", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Teucrium Marum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Teucrium Scorodonia", DoseTypes: [{ DoseType: "Globule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Congestion And Pain Sinus Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Daytime Cough Cold/Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Fast Acting Allergy Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Homeopathic Allergy Releif", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Homeopathic Allergy Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Kids Allergy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Kids Congestion Stuffy Nose", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Kids Cough And Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Night Time Cough Cold/Flu", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texaclear Pain Reliever", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Texacort", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Texas Medical Center Supply", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tezspire", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Th-101", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thai Duong Hand Sanitizer Antiseptic Hand Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thailuv", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thalamus 8 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thalitone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thallium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thallous Chloride Tl 201", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Thalomid", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tham", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Thank You Farmer Safe Sun Fluid Age 0880", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Thann Signature Hand Sanitising",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Thann Signature Hand Sanitising Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thayers Blemish Clearing Balm", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thayers Since 1847 Natural Remedies Blemish Clearing Witch Hazel Aloe Vera Formula Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thayers Since 1847 Natural Remedies Blemish Clearing Witch Hazel Aloe Vera Formula Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Cica Serum Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Clean Club Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Clean Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Bt21 Moisturizing Sanitizerspray Green Tea Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Bt21 Moisturizing Sanitizerspray Lavender Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Bt21 Moisturizing Sanitizerspray Peach Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Bt21 Moisturizing Sanitizerspray Peppermint Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Bt21 Moisturizing Sanitizerspray Strawberry Vanilla Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Bt21 Moisturizing Sanitizerspray White Tea Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Bt21 Moisturizing Sanitizerspray Yuzu Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Hand Sanitizer Green Tea", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Hand Sanitizer Mixed Berries", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Srpay Green Tea Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Srpay Strawberry Scented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Towelettes Eucalyptus Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Towelettes Green Tea Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Towelettes Lemon Drop Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Towelettes Mixed Berries Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Towelettes Peppermint Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Towelettes Rose Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Creme Shop Moisturizing Sanitizer Towelettes Strawberry Scented", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Duo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "The Eczema", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "The Essence Of Eva",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "The Fact Natural Sfn 18 Skin Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Fact-Hfn Cooling Anti-Hair Loss Tonicgel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Fact-Sfn Brightening Anti-Wrinkle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Flower Remedy Get Up And Go", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Flower Remedy Happy Heart", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Flower Remedy Peaceful Feeling", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Flower Remedy Peaceful Night", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Goo", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Good Stuff", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Green Beaver Company Spf 40 Adult Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Green Beaver Company Spf 40 Kids Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Green Scissor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Grid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Healthy Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Home Edit Antibacterial Hand Wash-Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Home Edit Hand Sanitizer-Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Humble Co Anticavity Mouthwash Charcoal", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Humble Co Anticavity Mouthwash Fresh Mint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Humble Co. Anticavity Charcoal", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "The Humble Co. Anticavity Fresh Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "The Humble Co. Anticavity Kids Strawberry Flavor", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "The Humble Co. Anticavity Mouthwash Coconut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Humble Co. Anticavity Mouthwash Strawberry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Humble Co. Anticavity Mouthwash Strawberry Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Hydrator, Ionic Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Indigo Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Inkey List Alcohol Hand Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Inkey List Beta Hydroxy Acid Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Inkey List C 50 Serum", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Inkey List Salicylic Acid Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Inkey List Succinic Acid Acne Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Magic Bullet", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "The Mitt", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Natural Dentist", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "The Ordinary Suncare Mineral Uv Filters With Antioxidants", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Original Mane N Tail Daily Control 2 In 1 Anti Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Original Natural Herb Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "The Peninsula Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Pharma C Company Medicated Foam With Witch Hazel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Pharma-C Company 3 Hydrogen Peroxide Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Pharma-C Company 70 Isopropyl Alcohol Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Pharma-C Company 70 Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Pharma-C Company Medicated Hemorrhoidal Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Pure Lotus Jeju Botanical Suncream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Pure Lotus Jeju Lotus Leaf Brightening Serum", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Real Glow Lifting Mask", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Rose", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Saffron", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Egf Collagen Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Hyaluronic 6000 Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Rose Heaven", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Rose Heaven Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Rose Heavencream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Rose Heavenemulsion", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Snail Mucin 5000 Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Vital Bright Emulsion", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Vital Bright Eyecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Vital Bright Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Vital Brightcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Vital Brightemulsion", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Vital Brightserum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Wrinkle Collagen Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Wrinkle Collagen Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Wrinkle Collagenemulsion", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Wrinkle Snail Systemcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Wrinkle System Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Wrinkle System Eyecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skin House Wrinkle Systemcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Aloe Soothing Vitamin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Black Snail Wrinkle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Green Tea Collagen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Real Snail Wrinkle Free", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Rose Heaven Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Vita Snail First Essense", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse White Tightening Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse White Tightening Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse White Tighteningcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse White Tighteningemulsion", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Wrinkle Collagen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Wrinkle Collagen Free Spot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Wrinkle Eye Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Wrinkle Supreme Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Wrinkle Supreme Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Wrinkle Supremecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Skinhouse Wrinkle Supremeemulsion", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Sof Fluid Long Wear Foundation Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Solution", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "The Starter Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "The Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Thea Sinensis",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thefaceshop Cc Cushion Natural Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thefaceshop Color Control Cushion Apricot Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thefaceshop Cushion Cc Spf 50 Apricot Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thefaceshop Cushion Cc Spf 50 Natural Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thefaceshop Cushion Cc Total Cover Spf 50 Apricot Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thefaceshop Cushion Cc Total Cover Spf 50 Natural Beige", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thefaceshop Natural Sun Long Lasting Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thefaceshop Natural Sun Oil-Free Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theo-24", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Theophylline",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Theorie Anti-Microbial Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theorie Antimicrobial Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theorie Cote Dazur Nourishing Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theorie Embers And Berries Nourishing Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theorie Linen And Teak Nourishing Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theorie Moroccan Fig Nourishing Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theorie Santal Blanc Nourishing Hand Wash", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theory", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Thera", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Antifungal Body", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Calazinc Body Shield", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Care", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Care 24Hrs Lidocaine Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Care Cold Hot Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Care Hot And Cold Medicated Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Care Pain Relief Menthol Patchs", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Care Pain Therapy", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Moisturizing Body Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Plus Anti-Itch With Aloe Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Plus Antifungal Clotrimazole", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Plus Antifungal Tolnaftate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Wise Natural Acne", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thera Wise Natural Hemorrhoidal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Rectal; Topical" }] }] },
    { Medication: "Therabreath For Kids Anti Cavity Oral Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Therabreath Fresh Breath", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Therabreath Healthy Gums Oral Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Therabreath Healthy Smile Oral Rinse", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Therabreath Plus Fresh Breath", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Theracare 4% Lidocaine And 1% Menthol Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare 4% Lidocaine Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Extra Strength Anti-Itch Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare First Aid Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Ice Blue Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Maximum Strength 1% Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Maximum Strength Oral Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Maximum Strength Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Medicated Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Muscle And Join Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Pain Relief Lidocaine", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Pain Relieving Muscle Vanishing Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theracare Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theraflu", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Theraflu Daytime Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Theraflu Expressmax",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Theraflu Expressmax Daytime Severe Cold And Cough And Theraflu Expressmax Nighttime Severe Cold And Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Theraflu Expressmax Severe Cold And Flu Caplets", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Theraflu Expressmax Severe Cold And Flu Syrup", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Theraflu Flu Relief Max Strength", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Theraflu Flu Relief Maximum Strength", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Theraflu Multi-Symptom Severe Cold And Theraflu Nighttime Severe Cold And Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Theraflu Nighttime Multi-Symptom Severe Cold Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Theraflu Nighttime Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Theragesic Creme", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theralast Clinical Strength Md3 Topical Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theralast Maximum Strength Pain Relief Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theralast Topical Analgesic Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Therapeutic",
        DoseTypes: [
            { DoseType: "Rinse", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Therapeutic Anti-Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Therapeutic Dandruff", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Therapeutic Hydrogel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Therapeutic Plus", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Therapeutic T Plus", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theraplex Eczema Therapy", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Theraplus",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Theraplus Antibacterial Wipes Fresh Scent With Aloe And Vitamin E", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theraseal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theraspec", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Therastom", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Theratears", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Theratears Extra", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Theratears Lubricant", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Theraworx Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theraworx Relief Joint Discomfort And Inflamation", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theraworx Relief Joint Discomfort And Inflammation", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Theraworx Relief Muscle Cramp And Spasm Relief",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Theresienol Md Skin Protectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thergy", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Theriac Advanced Healing", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Theridion", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thermalab", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thermasun Zinxation Mineral Zinc Facial Sunscreen - Acne Prone Skin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thermazene", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Theruptor",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Thiamine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Thiamine Hcl", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Thiamine Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Thick Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thick Hand Sanitizer - Ocean Breeze", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thick Head Hair Regrowth Treatment For Men", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thieves Waterless Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thieves Waterless Hand Sanitizer With Peppermint Oil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Think Baby Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Think Every Day Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Think Sport For Kids", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Think Sport Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Thinkbaby",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Thinksport",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Thio Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thiola", DoseTypes: [{ DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thiola Ec", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thioridazine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thiosinaminum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thiosinominum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thiotepa",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intracavitary; Intravenous; Intravesical" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intracavitary; Intravenous; Intravesical" }] },
        ],
    },
    { Medication: "Thiothixene", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Third Chakra (Solar Plexus)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thlaspi Bursa Pastoris", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thomas And Friends", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "Thomas And Friends Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Thompson Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thompson Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thompson Chewable Bismuth Tabs", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thoracic Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thoracic Spinal Cord Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Three Cube Mask", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Threefold Balance",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thritex", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thrive Health Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Throat And Voice", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Throat Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Throat Coat", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Throat Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Throat Formula", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Throat Relief Pops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Throat Spray- Tone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Throatcalm",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Throatspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thrombate Iii", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Thrombin Human", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thrombin-Jmi", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Thrombocytes 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thrombocytes 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thuja",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thuja E Summ. 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thuja E Summ. 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thuja E Summ. 6X", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thuja Occidentalis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Thuja Occidentalis 30C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thuja Occidentalis 6C", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Thuja Occidentalis Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thuja Thymus Comp.",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thumb Thumb No Thumb", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thumb Thumb No Thumb Extra Strength", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thumbs-Up Lime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thy-Plex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thylox", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thymes Frasier Fir Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thymes Lemon Leaf Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Thymoglobulin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Thymoplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thymuline",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
        ],
    },
    { Medication: "Thymupar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thymus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thymus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thymus Mercurius",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thymus Vulgaris (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thymusinum", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thymusinum 4Ch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyquidity", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thyreoidea Ferrum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thyreoidea Ferrum Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thyreoidea Melissa",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thyro", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyro Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyro Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyro T3 Rescue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyro Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyro-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyro-Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyrogen", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Thyroid",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thyroid Care", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroid Formula", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Thyroid Homeopathic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroid Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroid Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroid Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroid Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroid Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thyroid-Adrenal",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thyroid/Thymus/Parathyroid Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Thyroidinum",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Thyroidinum 4Ch", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyropar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyropath", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroplex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroplus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyropro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyrosafe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyrostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyrotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Thyroxine Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tiadylt Er", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tiagabine Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tiazac", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tibetree Pain Relieveing Medicated Plaster", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tibsovo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ticagrelor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tice Bcg", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Intravesical" }] }] },
    { Medication: "Tick Pathogen Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tickling Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ticovac", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Tidalove Smile More Oral Care", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tidl Sport Cryotherapy Joint Muscle", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tidl Sport Plant Powered", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tidy Tiger Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tiege Hanley Am Morning Facial Moisturizer With Spf20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tiege Hanley Moisturizing Lip Balm Spf 30", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tigan",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] },
        ],
    },
    { Medication: "Tigan(R) (Trimethobenzamide Hydrochloride)", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Tigecycline", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }, { Route: "Intravenous; Parenteral" }] }] },
    { Medication: "Tiger Baby Baby Sunscreen Spf-50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tiger Balm",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liniment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tiger Balm Pain Relieving Hydrogel", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tiger Balm Red", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tiger Balm White", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tiger Cats Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tiger Supply Inc Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Tiger Supply Inc Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Tigercats 70%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tigers Eye", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tiglutik", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tikkun Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tikosyn", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tilcare", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Tilia Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tilley Endurables Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Time Dynamics Defy Time Protector Moisturizer Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Time-Gesic", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Alabaster", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Alabaster Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Creamy Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Ivory", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Porcelain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Pure Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Quiet Rose", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Sandy Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Sesame", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Simply Beige", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Illuminating Makeup Spf 30 Soft Bisque", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Prescription - Acne Repairing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timeless Prescription - Renewing Acne Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timing Pain Savior Cool Herbal Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timing Pain Savior Fragrant Ginger Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Timolol Gfs", DoseTypes: [{ DoseType: "Solution, Gel Forming / Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Timolol Maleate",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Timolol Maleate Ophthalmic Gel Forming Solution, 0.25%", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Timolol Maleate Ophthalmic Gel Forming Solution, 0.5%", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Timoptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Timoptic In Ocudose", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Timoptic-Xe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Timothy, Standardized",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Percutaneous" }] },
        ],
    },
    { Medication: "Timothy-Orchard Standardized Grass Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Tinactin",
        DoseTypes: [
            { DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tincture Green", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tincture Merthiolate", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tindamax", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tindazole", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tineacide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tineacide Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tineacide Physician Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinfu Isopropyl Alcohol Prep Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ting",
        DoseTypes: [
            { DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Tinidazole",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tinitx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tinnistop", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tinnitus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tinnitus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tinnitus Relief",
        DoseTypes: [
            { DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tinted Clear Zinc Solar Protection Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Isopropyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Lip Balm Spf30 Sienna Rose", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Lip Balm Spf30 Wild Peony", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Lip Shield", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Liplux", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Mineral Defense Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Mineral Face Sunscreen Stick - Latte", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Mineral Sunscreen Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Mineral Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Mineral Sunscreen Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Mineral Sunscreen, Spf 30 Medium Tint", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Moisturizer Broad-Spectrum Spf 45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Moisturizer Matte Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Moisturizer Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Moisturizer Spf 46 Universal Tint", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Sunscreen Cream Spf 30 4-Deep Amber", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Sunscreen Spf 30 1-Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tinted Sunscreen Spf 30 2-Golden", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tiny Cold", DoseTypes: [{ DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Tion Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tion Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tiopronin", DoseTypes: [{ DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tirofiban Hydrochloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Tirosint",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tis-U-Sol", DoseTypes: [{ DoseType: "Irrigant", Routes: [{ Route: "Irrigation" }] }] },
    {
        Medication: "Tisseel",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tissen Icm 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tissen Nf 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tissueblue", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intraocular; Ophthalmic" }] }] },
    { Medication: "Titanium Dioxide", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Titanium Metallicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tivdak", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Tivicay", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tivicay Pd", DoseTypes: [{ DoseType: "Tablet, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tivorbex", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tizandine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tizanidine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tizanidine Hcl",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tizanidine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tizanidne Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tize-It", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizer Sanitizing Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Eye Renewal Spf 20 (Non-Tinted)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Photoceutical Am Replenish - Tinted", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Photoceutical Am Replenish Non-Tinted", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Photoceutical Environmental Skin Protectant", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Sheer Botanicals Non-Tinted", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Sheerfoam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Sheerfoam - Tinted", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Tinted Lip Protection Spf 45", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Ultra Zinc Spf 40 (Non-Tinted)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo Ultra Zinc Spf 40 (Tinted)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo2", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tizo3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tlando", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tlb-Matrix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tlc", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tlc Plus Anesthetic G", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tlc Plus Anesthetic S", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tlcuo Phytoncide", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tlcuo Pure", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tm Titus Medical Antiseptic Non Sterile", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tm Titus Medical Povidone Iodine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tm-C7 Oral Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tmd Industrial Disinfecting Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tmj Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tn Dickinsons Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tn Dickinsons Witch Hazel Astringent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tn Dickinsons Witch Hazel Hemorrhoidal Pads", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tnkase", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Toa Syrup Adult", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tobacco", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tobacco Leaf", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "Tobacco Withdrawal",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tobacco, Leaf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Tobaccostat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tobaccotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tobi", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }, { Route: "Oral; Respiratory (Inhalation)" }] }] },
    { Medication: "Tobi Podhaler", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral; Respiratory (Inhalation)" }] }] },
    {
        Medication: "Tobradex",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Tobradex St", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Tobramycin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }, { Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }, { Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Tobramycin And Dexamethasone", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Tobramycin Inhalation", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Tobramycin Inhalation Solution", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Tobramycin Ophthalmic Solution", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Tobrex",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Toenail Fungus / Nail Repair Pen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tok And Care Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tolak", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tolcapone",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tolcylen Antifungal Solution", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toleriane Double Repair Moisturizer Uv Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tolnafate",
        DoseTypes: [
            { DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Tolnaftate",
        DoseTypes: [
            { DoseType: "Aerosol, Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Tolnaftate Antifungal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tolnaftate Cream 1%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tolnaftate Cream 1% Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tolsura", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tolterodine Tartarate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tolterodine Tartrate",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tolterodine Tartrate Extended Release", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tolvaptan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tom Ford Face Protect Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tom Ford Shade And Illuminate Foundation Broad Spectrum Spf 45 Soft Radiance Cushion Compact Refill", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tom Ford Shade And Illuminate Soft Radiance Foundation Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tom Ford Soleil Glow Tinted Moisturizer Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tom Ford Soleil Glow Tone Up Foundation Broad Spectrum Spf 45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tom Whole Care Peppermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Tomarmon Onguard Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tomato",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Tommie Copper Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tommie Copper Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tommie Copper Pain Relief Roller", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tommy Hilfiger", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Baking Soda Cavity Protection Peppermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Cavity Protection Spearmint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Childrens", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Childrens Anticavity Mouthwash Silly Strawberry", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Childrens Wild Blueberry Anticavity Blue", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Fresh And Bright", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Kids Grape Save The Animals", DoseTypes: [{ DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Luminous White - Spearmint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Luminous White Fluoride Clean Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Natural Enamel Strength Peppermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Natural Fluoride-Free Strength Sensitive Wintermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Natural Maximum Strength Sensitive Soothing Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Of Maine Coconut Lavender Antiperspirant / Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine Coconut Lavender Antiperspriant / Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine For Men Charcoal Antiperspirant / Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine For Men Mountain Spring Antiperspirant / Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine For Men Mountain Spring Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine For Men North Woods Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine For Men North Woods Antiperspriant Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine For Women Charcoal Antiperspirant / Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine Natural Powder Antiperspirant / Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Of Maine Vanilla Antiperspirant / Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toms Teeth And Gum Health", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Whole Care Cinnamon Clove", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Whole Care Mouthwash Fresh Mint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Whole Care Spearmint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Whole Care Wintermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Whole Care Wintermint - Wwt4", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Wicked Fresh Cool Peppermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toms Wicked Fresh Spearmint Ice", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tong Feng Ding Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Tonsil Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tonsil/Adenoid/Appendix Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tonymoly My Sunny All In One Sun Spf45", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tonymoly My Sunny All Sunspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tonymoly Pandas Dream Brightening Eye Base", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tonys Warm Therapy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Too Faced Hangover Good To Go Spf 25 Broad Spectrum Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Too Im Cell All In One", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toosty Golden Dookkeobi Tooth", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tooth Note Aqua Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Tooth Note Herb Tooth Charcoal Jasmine", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Tooth Note Moisturizing Therapy Tooth Grapefruit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Tooth Note Moisturizing Therapy Tooth Lemon Basil", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Tooth Note Pineapple Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Tooth Whole White", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Toothache And Gum Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toothache And Gum Relief Cushions", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toothache Neuralgia", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Top Care", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Top Care All Day Allergy", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Top Care Castor Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Top Care Decolorized Iodine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Top Care Ear Relief Ear Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Top Of Texas Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Top Quality Mfg Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Top Quality Mfg. Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Top Secret Cc Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Top Secrets Bb Primer Spf 25", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Top Toon Dr.Q Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Top Toon Dr.Q Hand Sanitizerspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Top Value Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Top Value Effervescent Cold Relief - Orange", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Top Value Effervescent Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topamax",
        DoseTypes: [
            { DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Topcare",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare 12 Hour Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare 3 Hydrogen Peroxide Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare 70 Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare 8 Hour Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare 8 Hr Arthritis Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Acid Reducer", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare All Day Allergy",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare All Day Allergy D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare All Day Pain Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare All Night Pain Relief Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Allergy And Congestion Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Amber Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antacid Calcium", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Antacid Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Anti Diarrheal", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antibacterial Berry Blast Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antibacterial Complete Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antibacterial Cucumber Melon Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antibacterial Fresh Pear Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antibacterial Hand Light Moisturizers", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antibacterial Spring Water Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antibacterial White Tea Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antibiotic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Athletes Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Athritis Pain Reliever", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Back And Muscle Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Calaclear", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Calagesic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Calamine", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Cherry Antacid Soft Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Cherry Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Chest Congestion And Cough Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Chest Congestion And Sinus Congestion Relief Pe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Childrens All Day Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Childrens Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Childrens Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Childrens Allergy Relief Nasal 24 Hour", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Topcare Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Childrens Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Childrens Mucus Relief Multi Symptom Cold", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Childrens Night Time Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Childrens Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Citrus Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Cold", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Cold And Hot Medicated", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Cold Sore Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Complete Lice Treatment Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Topcare Cough Dm",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Day Time", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Day Time Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Day Time Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Day Time Nite Time Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Topcare Day Time Nite Time Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Topcare Dual Action Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Earwax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Topcare Earwax Removal Kit", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Topcare Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Enema", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Topcare Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Everyday After Sun Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Everyday Continuous Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Everyday Kids Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Everyday Mineral Sunscreen Zinc Oxide Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Everyday Sport Continuous Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Everyday Sport Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Everyday Ultimate Sheer Sun Broad Spectrum Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Everyday Ultimate Sheer Sun Broad Spectrum Spf 70", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Everyday Ultra Protection Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Extra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Extra Strength Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Extra Strength Gas Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Topcare Fiber Laxative", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Flu And Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Fluticasone Propionate Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Topcare Headache Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Health Advanced Moisturizing Plus Redness Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Topcare Health Artificial Tears", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Topcare Health Cold Remedy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Health Dry Eye Relief Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Topcare Health Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Topcare Health Non Drowsy Daytime Cold And Flu Relief And Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Topcare Health Original Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Topcare Health Ultra Itch Relief Astringent Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Topcare Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    {
        Medication: "Topcare Hydrocortisone",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Topcare Ibuprofen",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Infants Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Infants Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Lansoprazole",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Lice Killing Creme Rinse", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Lime And Berry Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Loperamide Hydrochloride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Maximum Redness Relief Eye Drops", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Topcare Maximum Strength Pain Relieving", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Topcare Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Topcare Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Topcare Migraine Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Milk Of Magnesia", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Mucus D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Mucus Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Mucus Relief",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Multi Symptom Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Topcare Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Topcare Nasal Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Nasal Decongestant Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Nasal Four", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Topcare Natural Cherry Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Natural Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Nicotine Gum", DoseTypes: [{ DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Nicotine Polacrilex", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Nite Time", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Nite Time Cold And Flu",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Nite Time Cold And Flu Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Nite Time Day Time", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Topcare No Drip Extra Moisturizing", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Topcare Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Omeprazole Delayed Release", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Oral Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Pain And Fever", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Pain Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Pain Relief Patches", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Pain Relief Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Pain Relieving 40Ct", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Pain Relieving Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Sensitive", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Topcare Sinus And Cold D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Sinus Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Topcare Sleep Aid",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Sore Throat", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Stay Awake", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Stomach Relief",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topcare Stomach Relief Cherry", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Swimmers Ear Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Topcare Tab Tussin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Topcare Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topcare Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Tussin Cf", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Tussin Cf Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Tussin Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Ultra Strength Antacid Assorted Berry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Ultra Strength Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Ultra Strength Antacid Peppermint", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Ultra Strength Stomach Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Wild Berry Antacid Chews", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topcare Zinc Cold Remedy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topcare Zzz Sleep",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Topco", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topco 4X Medicated Toothache And Gum Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topco Associates Llc", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Topco Everyday Mineral Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topco Fiber Therapy", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topco Miconazole 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Topco Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topco Stomach Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topco Topcare Everyday Mineral Sunscreen Zinc Oxide Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topco Tussin Cf Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Topex",
        DoseTypes: [
            { DoseType: "Gel, Dentifrice", Routes: [{ Route: "Dental" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Periodontal" }] },
        ],
    },
    { Medication: "Topex 60 Second Fluoride Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Topex Neutral Fluoride Foam", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Topex Neutral Ph Fluoride Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Topicaine", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topicaine 5", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Topical Analgesic",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Topical Anti-Fungal Solution", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topical Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topical Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topicool Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Topicort",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Topiramate",
        DoseTypes: [
            { DoseType: "Capsule, Coated Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Toplast Cool Menthol 5Percent", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toplast Pain Relief Lidocaine 4Percent Plus Menthol 1Percent", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toposar", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Topotecan",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Topotecan Hydrochloride", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Toprol", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Topsani", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Topwin Speedy Enema", DoseTypes: [{ DoseType: "Enema", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Toremifene Citrate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Torisel", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tork Foam Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tork Hand Sanitizer Alcohol Free Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tork Hand Sanitizer Alcohol Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tormentilla Cochlearia",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Toronova Ii Suik", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Toronova Suik", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Toroplex Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Torsemide",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tosymra", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Total Advanced Health Fresh Mint", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Total Aluminum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Total Andro Bal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Total Daily Repair", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Total Defense Repair Broad Spectrum Spf 34 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Defense Repair Broad Spectrum Spf 34 Sunscreen (Tinted)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Defense Repair Broad Spectrum Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Eye 3-In-1 Renewal Therapy Spf 35", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Eye 3-In-1 Renewal Therapy Spf 35 (Deep)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Eye 3-In-1 Renewal Therapy Spf 35 (Fair)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Eye 3-In-1 Renewal Therapy Spf 35 (Tan)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Eye Care", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Fem Bal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Total Flu Cld 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Total Mercury", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Total Pro-Jes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Total Sanitizer Alcohol Free Antiseptic Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Sanitizer Alcohol Free Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Sanitizer Alcohol Free Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Sanitizer Alcohol Free Hand Saniting Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Skin Tag Remover", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Solutions Hygien Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Sun Protection", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Total Tri-Estro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Totalarthritis", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Totally Fun After Sun", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Totect", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Touch Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touch Anti-Aging Spf 30 Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touch Clean", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touch Sanitizing Germ Block, Mint Green Tea Aloe, 1Oz (28.35G)", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touch Sanitizing Germ Block, Ocean Mist, 1Oz (28.35G)", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touch Sanitizing Germ Block, Tropical Breeze, 1Oz (28.35G)", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touch Sanitizing Germ Block, Unscented, 1Oz (28.35G)", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touche Eclat Le Teint Radiance Awakening Foundation Octinoxate Sunscreen Broad Spectrum Spf 22", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "Touchland Glow Mist Hand Sanitizer Rosewater", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Aloe You", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Applelicious", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Beach Coco", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Berry Bliss", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Blue Sandalwood", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Citrus Grove", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Frosted Mint", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Lemon Lime Spritz", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Pure Lavender", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Rainwater", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Vanilla Blossom", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Velvet Peach", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchland Power Mist Hand Sanitizer Wild Watermelon", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchless Care Zinc Oxide Protectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Touchpoint Biodegradable Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Touchpoint Hand Sanitizing Wipes Fragrance Free", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Touchpoint Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Touchpoint Wipes Fragrance Free", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Tough Guy 821 Antimicrobial Liquid Ltion So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tough Guy Antibacterial Foam Hand So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tough Guy Antimicrobial Foam So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tough Guy Antimicrobial Ltion So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tough Guy Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tough Guy Instant Hand Sanitizer With Moisturizers And Vitamin E", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tough Town Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Toujeo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Toujeo Max", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Tourmaline 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tovet (Emollient Formulation)", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tovet Foam (Emollient Formulation)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Toviaz", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tox-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxagent Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxex", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxic Fungi-Mold Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxicodendron Pubescens Leaf", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxicology Medicated Collection System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Toxo Homo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxodetox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxoplasma Gondii Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxoplasma Homochord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxoplasmosis Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Toxycology Medicated Collection System", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tpn Electrolytes", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Tpoxx",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Trace Elements 4", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Traceless Perfecting Foundation Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Traceless Touch Foundation Broad Spectrum Spf 45 Refill Cushion Compact", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Traci Lynn Skin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Tracleer",
        DoseTypes: [
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Soluble", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Trade Joe Anticavity Peppermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trader Joe S Mineral Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trader Joes Daily Facial Sunscreen", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trader Joes Enrich Moisturizing Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trader Joes Face And Body Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Trader Joes Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Trader Joes Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trader Joes Zinc Oxide Mineral Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tradition Salt Caretoothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Traditional Chinese Medicine Health Card And Tcm Epidemic Prevention Card", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tradjenta", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tragacanth Gum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Tralement", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Tramadol Hcl",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tramadol Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tramadol Hydrochloride And Acetaminophen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Trandolapril", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trandolapril And Verapamil Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tranexamic Acid",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tranexamic Acid In Sodium Chloride", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Transcend E2 Sanitizing Foam", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Transderm Iq",
        DoseTypes: [
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Transderm Scop", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Transderm-Iq", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Transform 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Translucent Broad Spectrum Foundation Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tranxene", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tranylcypromine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tranylcypromine Sulfate", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Traulevium",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Trauma Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trauma Less", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trauma Plus Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Trauma Relief",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Trauma Remedy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Traumatone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Traumed", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Traumeel",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intra-Articular; Intradermal; Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Traumeel Rx", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Traumex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Travasol", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Travatan Z", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Travel Ease", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Travel Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travel Hand Wipes Dispenser Kit", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travel Hand Wipes Refills", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travel Immunization Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Travel Lite Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travel Lite Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travel Remedy", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Travel Safe Hand Sanitizer", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Travel Savvy Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Travel Savvy First Aid Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Travel Savvy Non-Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Travel Savvy Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travel Sickness Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Travel Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travel-Ease", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Travelers Hand Sanitizers", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Traveling Farmacy", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travelshield Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travelshield Sanitizing Wipes 70", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Traveltime Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Travoprost", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Travoprost Ophthalmic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Travoprost Ophthalmic Solution", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Travoprost Ophthalmic Solution Usp, 0.004%", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Travoprost Ophthalmic Solution, 0.004%", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Trazimera",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Trazodone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trazodone Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Trazodone Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Treadmill Doctor Advanced Gym Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Treadmill Doctor Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Treanda", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Treat So Deep Acne-Fighting Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Treatonic Nail Fungus Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Trecator", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tree Antigen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tree Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tree Of Heaven Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Treeactiv Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Treeactiv Cystic Acne Spot Treatment", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Treeactiv Safflower Oleosomes Daily Natural Moisturizing Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trees 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trees 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trelegy Ellipta", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Trelstar", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tremfya", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Tremorsoothe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trendy John Spf-32 Sheer Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Treprostinil",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous; Subcutaneous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] },
        ],
    },
    { Medication: "Tresiba", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Tretinoin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Tretinoin Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tretten", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Trexall", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Treximet", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trezix", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tri Estrogen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tri Femynor", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Buffered Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tri-Estarylla", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Legest Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Linyah", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Lo- Estarylla", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Lo-Marzia", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Lo-Mili", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Lo-Sprintec", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Luma", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tri-Mili", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Nymyo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Soft Softening", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tri-Sprintec", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Titans Funk Fighter Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Tri-Vite Drops With Fluoride", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tri-Vylibra", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tri-Vylibra Lo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Triacting Day Time Cold And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Triacting Daytime Cold Cough",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Triadime-80", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Trial Antacid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Triamcinolone Acetonide",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intramuscular" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste", Routes: [{ Route: "Dental" }, { Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Intra-Arterial; Intramuscular" }] },
        ],
    },
    { Medication: "Triamcinolone Acetonide Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Triaminic",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Triamterene", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Triamterene And Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Trianex 0.05%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triastem Topical Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triazolam", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tribenzor", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tricalm", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tricare Prenatal", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trichoderma Harzianum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Trichophyton Mentagrophytes",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Percutaneous; Subcutaneous" }, { Route: "Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Trichophyton Rubrum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Trichothecium Roseum", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Tricitrasol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Tricitrates", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Triclara", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triclosan Free Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triclosan Free Antibacterial 7.5 Oz (Fresh Citrus)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triclosan Free Antibacterial 7.5 Oz (Ocean)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triclosan Free Antibacterial 7.5 Oz (Strawberry Pomegranate)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triclosan Free Antibacterial 7.5 Oz Original", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tricon", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tricor", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trident Bioguard Alcohol Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trident Bioguard Antiseptic Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Acne Gel Face Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Acne Spot Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Baby Stubborn Eczema Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Bruise Defense Healing -Diabetics", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Eczema Fast Healing Face And Body", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Essential Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Everyday Bruise Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Intense Fast Healing Multi-Purpose Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Lipsore Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Pressure Sore Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Protect And Heal Non- Greasy Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Psoriasis Control", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Psoriasis Control Body Wash", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Psoriasis Control Face And Body Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Stubborn Diaper Rash Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triderma Ulcer Defense Healing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tridesilon Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Trientine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Triesence", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Triferic",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Hemodialysis" }] },
        ],
    },
    { Medication: "Triferic Avnu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Triflora", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triflora Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical; Topical" }] }] },
    { Medication: "Trifluoperazine Hydrochloride", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trifluridine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Triheal-80", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Trihexyphenidyl Hydrochloride",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Trijardy Xr", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trikafta", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Trileptal",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Trilipix", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trillium", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trillium Pendulum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Triloan Ii Suik", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intramuscular" }] }] },
    { Medication: "Triloan Suik", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intra-Articular; Intramuscular" }] }] },
    { Medication: "Trilociclo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trimethobenzamide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trimethobenzamide Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trimethoprim", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trimethoprim Sulfate And Polymyxin B Sulfate", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Trimipramine Maleate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trimo San", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Trimol", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trimotion Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trintellix", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Triostat", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Trip Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Action Arthritis", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Allergy Defense", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antibiotic And Burn Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antibiotic And Pain Itch Scar", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antibiotic And Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antibiotic First Aid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antibiotic First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antibiotic Plus", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antibiotic Plus Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antibiotic With Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antifungal", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Antiobiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Care Plus Arnica", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Defense Sunscreen Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Flu Defense", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Triple Protection Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple S Gentle Antibacterial Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Shield", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Shield Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triple Shield Sanitizing Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tripleantibioticapainscaritch", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tripleantibioticointmentandpainrelief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triponel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Triprolidine Hcl Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Triprolidine Hydrochloride",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Triptodur", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Trisenox", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Trispec Dmx Cough Suppressant Expectorant Peach Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trispec Pse Cough Suppressant Expectorant Nasal Decongestant Grape Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tristart Dha", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Triticum Aestivum Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }] }] },
    {
        Medication: "Triticum Cerebri",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tritocin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triton Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triumeq", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Triumeq Pd", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Triumph Daily Md", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trivita Qpr", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trivix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Trivix Lite", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Trivora", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Triz Hand Sanitizer (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Triz Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trizivir", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trodelvy", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Trofresh", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trogarzo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Trojan Extended Pleasure", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trokendi Xr", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trolls Bubble Gum Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trolls Cherry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Trolls Grape Scented Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Trolls Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trolls Raspberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trolls Red Raspberry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Trolls Strawberry Scented Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Trophamine", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Tropic Sport Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropic Sun", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tropical Forest Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Mint Broad Spectrum Spf 15 Lip Moisturizer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Sands Spf-30 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Sands Spf-30 Broad Spectrum Sunscreen (Coconut)", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Sands, Spf-50 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Spf 15 Lip", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Sunrise Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Vanilla Body Sunscreen Broad Spectrum Spf 32", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropical Vanilla Sunscreen Broad Spectrum Spf 32", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tropicamide", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Trospium Chloride",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Trospium Chloride Er", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trouvaille Sunscreen Broad Spectrum Spf 30 Uva/Uvb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tru And Gen", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trudhesa", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "True Natural Baby 30 And Family", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "True Natural Caribbean Coconut 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "True Natural Neutral 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "True Pain Relief Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "True Rough Marsh Elder", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "True Wash Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Truelipids Anti-Itch Barrier", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trueplus Diabetics Foot Care", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Truffoire Truffle Brightening", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trulance", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trulicity", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Truly Radiant Bright And Strong", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Truly Radiant Clean And Fresh", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Truly Radiant Rejuvenating", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Trulyclear", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trum Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trumed Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trumed Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trumenba", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Trurelief Rubiton Rub It Where It Hurts", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Truseltiq", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Trusopt", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Truth Alcohol Free Hand Sanitizer - Lavender Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Truth Alcohol Free Hand Sanitizer - Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Truth Antibacterial Foaming Hand Wash - Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Truvada", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Truxima", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Trymax Dino Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Trymax Sol Body", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tryptophan", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tsh Cord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tsong Anti Bacterial Foaming Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tsong Anti Bacterial Hand Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tsong Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tterrasil Athletes Foot Cleansing Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tuberculinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tuberculinum Koch", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tuberculinum Miasm Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tubersol", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }] }] },
    { Medication: "Tucks Medicated Cooling Pads", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tucks Multi-Care Relief Kit (Tucks Hemorrhoidal And Tucks Medicated Cooling 40 Count)", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tudorza Pressair", DoseTypes: [{ DoseType: "Powder, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Tukol A", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol Cough And Cold Honey Flavor", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol Cough And Congestion", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol Honey Daytime Cold And Flu", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tukol Max Action",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Tukol Max Action Cold, Sore Throat And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol Max Action Dm Max", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol Max Action Severe Congestion And Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol Max Action Sinus Caplets", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol Max Action Sinus Liquid", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol Multi Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukol X-Pecto Miel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tukysa", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tula Acne Clearing And Tone Correcting Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tula Breakout Star Oil-Free Acne Moisturizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tula Skincare Breakout Breakthrough Acne Clarifying Toner Pads Refill", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tula Skincare Breakout Breakthrough Acne Toner Pads", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tula Skincare Go Away Acne Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tula Skincare Keep It Clear Acne Foam Cleanser", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tula Skincare Mineral Magic Mineral Sunscreen Fluid Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tula Skincare Protect Glow Daily Sunscreen Gel Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tula Tantrum Tamer", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tulana", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tulip Dew Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tummy Ache 4Kids", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tummy Calm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tummy Upset",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tums",
        DoseTypes: [
            { DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tums Chewy Bites", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tums Chewy Bites With Gas Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tuna",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Turalio", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Turbo Arnica", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Turbo Travel Sickness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Turkey", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Turkey Meat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Turskas Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tusicof",
        DoseTypes: [
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tusnel",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tusnel Diabetic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tusnel Dm", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tusnel Pediatric", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tusnel Pediatric Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tusnel-Dm Pediatric", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussi Pres", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussi Pres B", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussi Pres Pediatric", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tussin",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Tussin Cf",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tussin Cf Multi-Symptom Cold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tussin Cough",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tussin Cough And Chest Congestion Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Cough And Chest Congestion Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Cough Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Cough Gels", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tussin Dm",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tussin Dm Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Dm Cough And Chest", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tussin Dm Cough And Chest Congestion",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tussin Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Dm Max Daytime Nighttime", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tussin Dm Nighttime Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Expectorant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Long Acting Cough", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tussin Mucus And Chest Congestion",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tussin Multi Symptom Cold Cf", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Nighttime Cough Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussin Severe Multi Symptom Cough Cold Plus Flu Cf Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tusslin",
        DoseTypes: [
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tusslin Tr", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussnex Fm Cold And Sinus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussnex Fm Cold, Flu And Sore Throat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussnex Fm Severe Cough And Congestion", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tussy Antiperspirant And Deodorant Invisible Original Fresh Spice", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tussy Antiperspirant And Deodorant Original Fresh Spice", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tuvivex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tuvivex10", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tuxarin", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tuzistra Xr", DoseTypes: [{ DoseType: "Suspension, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Twice Science And Wellness Calming Vanilla Lavender Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Twice Science And Wellness Invigorating Wintergreen And Peppermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Twice Science Wellness Cooling Spearmint Eucalyptus", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Twilight Peppermint With Vanilla And Lavender", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Twinrix", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Twirla", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Twkof Children", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Two Old Goats Arthritis Formula", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Two Pvp-I Scrub Large Winged Sponges", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Two Pvp-I Scrub Sticks", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Twyneo", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ty-D Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tyblume", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tybost", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tyche Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Tydemy", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tygacil", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Tykerb", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tylenol",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tylenol 8 Hr Arthritis Pain", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylenol 8Hr", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylenol Acetaminophen Extra Strength", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylenol Cold Max", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylenol Cold Plus Flu", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylenol Cold Plus Flu Plus Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tylenol Cold Plus Flu Severe",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tylenol Cold Plus Flu Severe And Tylenol Cold Max", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tylenol Cold Plus Flu Severe And Tylenol Cold Max Copack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tylenol Cold Plus Flu Severe Day And Tylenol Cold Plus Flu Plus Cough Night 2-Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tylenol Cold Plus Flu Severe Day And Tylenol Cold Plus Flu Plus Cough Night 3-Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tylenol Cold Plus Flu Severe Day/Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Tylenol Cold Plus Mucus Severe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tylenol Extra Strength",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }, { Route: "Rectal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Rectal" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }, { Route: "Rectal" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }, { Route: "Rectal" }] },
        ],
    },
    { Medication: "Tylenol For Children Plus Adults", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tylenol Pm",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tylenol Sinus Plus Headache", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tylenol Sinus Severe",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Tylenol With Codeine", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylo Arthritis (Acetaminophen)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylo Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylo Extra", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylo Extra (Acetaminophen)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylo Migraine (Acetaminophen)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tylo Night (Acetaminophen)", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tymlos", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Typhim Vi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Typhus/Rickettsia Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tyramine", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Tyrvaya", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Tysabri", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Tyvaso", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Tyvaso Dpi",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "U Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U Cocoa Butter Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U Ice Cold", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U Instant Hand Sanitizer Classic 16Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U Instant Hand Sanitizer Classic 2Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U Instant Hand Sanitizer Classic 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U Instant Hand Sanitizer Lavender 8Oz", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U Nourishing Vitamin E Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U-Cort", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "U.S. Legal Support Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Uber Numb",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Uber Numb Topical Anesthetic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uberliss", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uberzinc", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ublim Alcohol Disinfectant", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Extracorporeal; Topical" }] }] },
    { Medication: "Ubloom Safe Sanitizer Plus Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ubloom Safe Sanitizer Plus Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ubrelvy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Uceris",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Rectal" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Udenyca", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Uec Medical Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uec Medical First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ukoniq", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ulcer Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ulcerease", DoseTypes: [{ DoseType: "Rinse", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uline Acetaminophen Extra Strength", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uline Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Uline Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Uline Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Uline Ibuprofen",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Uline Sinus And Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ulmus Americana Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Ulmus Crassifolia Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Ulmus Pumila Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Uloric", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ulta",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Oil", Routes: [{ Route: "Topical" }] },
            { DoseType: "Powder", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Ulta Beauty",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ulta Face Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ulta Whim", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ulta Whim Cupcake", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ulta Whim Peach", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultane", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Ultimate Miracle Worker Eye Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultimate Miracle Worker Multi-Rejuvenating Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultimate Pro-Collagen Day And Night Duo", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ultimate Protection Spf28", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultimate Solutions Antiseptic Gold", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultimate Solutions Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultimate Topical Pain Relief Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultiva", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ultomiris", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ultra", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Advantage", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Balm For Lips Spf 20 Cerise Brise", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Balm For Lips Spf 20 Coral Petale", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Balm For Lips Spf 20 Nude Caramel", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Balm For Lips Spf 20 Nude Translucide", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Balm For Lips Spf 20 Rose Cotton", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Balm For Lips Spf 20 Rose Paradise", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Lips Spf 20 Cerise Brise", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Lips Spf 20 Coral Petale", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Lips Spf 20 Nude Caramel", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Lips Spf 20 Nude Translucide", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Lips Spf 20 Rose Cotton", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Balm Moisturizing Protective Lips Spf 20 Rose Paradise", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Bismuth", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Blue Antibacterial Foaming Skin Cleanser", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Clean Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Clear Foaming Cleanser", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Clear Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Compact Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Dish", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Dry Anti-Perspirant And Deodorant Cool Blast", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Fine Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Ultra Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Le Teint Velvet", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Light Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Massagecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Nano Clean Alcohol Free Hand Sanitizer 4 Hour Power", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Organic Fiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Pain-A-Trate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Professional Antibacterial Foaming Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Professional Instant Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Replenishing Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Seal Bacitracin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Seal Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Sheer Daily Defense Facial Spf 18", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Slim 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Stomach Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strenght Pink Bismuth", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Antacid Assorted Berries", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Antacid Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Antacid Relief Assorted Berry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Antacid Relief Assorted Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Antacid Tropical Fruit", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Digestive Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Strength Muscle Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Strength Pain Relief Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Strength Pain Relief Gel", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Strength Stopain Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Tuss", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Tuss Dm", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Tuss Safe", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra Umbrella", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Uv Protective Mineral Defence", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra V Aqua Shine Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra V Idebanone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra V Idebenone Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra V Idebenone Nutritional", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra V Idebenone Skinfit Cushion", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra V Idebenone Sunblock", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra V Renewderm Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Vedic", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultra Zinc Cold Therapy", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultra-Technekow", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Ultrabrite", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ultracare", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ultracare Oral Anesthetic Cool Mint", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Ultracet", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultracruz Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultram", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ultramax", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultramax Invisible Solid", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultramax Invisible Solid - Active Sport", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultramax Invisible Solid - Cool Blast", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultramax Invisible Solid - Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultramax Invisible Solid - Powder Fresh", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultramax Invisible Solid - Unscented", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ultramicrosize Griseofulvin",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ultrasal-Er", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultratag", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Ultravate", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ultravist", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intra-Arterial" }, { Route: "Intra-Arterial; Intravenous" }] }] },
    { Medication: "Umbiliclean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Umbrella", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Umbrella Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Umcka Allergy And Sinus", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Cold Flu Berry", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Cold Flu Berry Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Cold Flu Orange", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Cold Flu Sugar Free Orange", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Coldcare", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Umcka Coldcare Alcohol Free Cherry", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Coldcare Alcohol-Free", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Coldcare Cherry", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Coldcare Childrens Cherry", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Coldcare Lemon", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Coldcare Mint Chewable", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Coldcare Starter Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Umcka Coldcare Sugar Free Grape", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Cough", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Elderberry", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Fastactives Berry", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Fastactives Cherry", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Menthol", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umcka Original Coldcare", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Umecta Mousse", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unasyn", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Unblemish Clarifying Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unblemish Dual Intensive Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unblemish Invisible Matte Defense", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unblemish Refining Acne Wash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Unburn",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Uncle Buds Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Uncle Buds Hand Sanitizer With Hemp Oil",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Uncle Buds Hemp Pain Relief", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uncle Buds Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uncle Buds Roll-On Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uncle Jeds Cold Remedy Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uncomplikated Makeup Setting", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unda 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 1000", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 1001", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 1002", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 11", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 12", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 13", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 14", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 15", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 16", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 17", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 18", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 19", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 202", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 203", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 21", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 210", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 212", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 219", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 22", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 220", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 226", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 228", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 23", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 233", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 24", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 240", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 243", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 245", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 248", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 25", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 258", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 26", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 27", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Unda 270",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Unda 273", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 28", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 29", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 295", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 31", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 312", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 32", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 33", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 34", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 35", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 36", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 37", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 38", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 39", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 41", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 42", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 43", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 44", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 45", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 46", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 47", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 48", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 49", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 600", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 7", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 700", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 710", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 74", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 76", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 8", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unda 9", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Undecylenic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Undecylenic Acid Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Underarm Antiperspirant Daily Use Charcoal Linen Scent", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Underarm Antiperspirant Daily Use Fresh Linen Scent", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Underarm Antiperspirant Daily Use Mandarin Scent", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Underarm Antiperspirant Pm Wipes Clean Lavender Scent", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unflavored Organic Natural Fiber", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Unichem",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Unicorn Antibacterial Hand Sanitizer Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unicorn Antibacterial Hand Sanitizer Fruit Punch Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unicorn Antibacterial Hand Sanitizer Mint Chocolate Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unicorn Antibacterial Hand Sanitizer Strawberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unicorn Antibacterial Hand Wash Mint Chocolate Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unicorn Snot 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unicorn Snot Bio 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unipetz 2 In 1 Hand Sanitizer N Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unisex Bb Tint- It S Clear - 6550", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unisex Bb Tint- It S Clear - 6551", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unisex Bb Tint- It S Clear - 6552", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unisex Bb Tint- It S Clear - 6553", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unisex Bb Tint- It S Clear - 6598", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unisex Bb Tint- It S Clear - 6599", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unishield Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Unishield Aspirin",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Unishield Back Pain Off", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unishield Cherry Cough", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unishield Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unishield Cramp", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unishield Extra Strength Non-Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unishield Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unishield Pain Zapper", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unishield Sinus Decongestant", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unisom Pm Pain Nighttime Sleep Aid And Pain Reliever", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unisom Sleepgels Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unisom Sleepmelts Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unisom Sleepminis Nighttime Sleep-Aid", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unisom Sleeptabs", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unisource Antibacterial Foam Handwash Plum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Unit Dose Bisacodyl",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "United Airlines",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "United Airlines Business Class Kit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "United Airlines Clean Plus Clorox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "United Airlines Fly Cleaner", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "United Airlines Polaris Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "United Airlines Polaris Kit With Colgate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "United Airlines Transcon Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "United Airlines Transcon Kit With Colgate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "United Airlines Upp Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "United Airlines Upp Kit With Colgate", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "United Cleanplus", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "United Cleanplus Sanitizing Wipe", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "United Spirit Of America",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "United Spirit Of America (Warrior)", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unithroid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unituxin", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Univ Of Vermont Medical Center Amenity", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Universal Advanced Whitening Anti-Cavity Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Universal Anti-Cavity Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Universal Baby Fresh Scent Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Charcoal With Aloe Vera Anti-Cavity Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Universal Cocoa Butter Scent Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Ice Cold Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Lavender Scent Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Original Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Pure Petroleum Cocoa Butter Scented", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Sanitizing Citrus Garden", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Sanitizing Fresh Linen And Lilac", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Sanitizing Lavender", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Sanitizing Lemon And Mint", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Sanitizing Pomegranate And Cherry", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Sanitizing Waterfalls", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Universal Sensitive Anti-Cavity Fluoride", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Universal Vaporizing Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "University Medical Pharmaceuticals Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "University Of Miami Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Unlimited By Degree", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unnaplast", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unnaplast With Calamine", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unpa Cha Cha Energy Toothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unpa Cha Cha Premium Whitening Toothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unpa Cha Cha Toothpaste", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unpa Cha Cha Toothpaste Grapefruit", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unpa Cha Cha Toothpaste Jasmine Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Unpaste", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Unpaste Tooth Tabs", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Unscented", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unscented Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unscented Aromar Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unscented Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unscented Hand Sanitizer Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unscented Lip Balm", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unscented Sanimoist Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unseen Sunscreen Broad Spectrum Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Unsun", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Untra Dry Anti-Perspirant And Deodorant -Cool Blast", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Up And Up",
        DoseTypes: [
            { DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Up And Up 12 Hour Cough Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up 3 Hydrogen Peroxide Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up 5-Symptom Digestive Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up 70 Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Up And Up Acetaminophen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Acetaminophen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Up And Up Allergy Relief",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Allergy Relief Nasal", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Up And Up Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Antacid Anti Gas", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Up And Up Anti Diarrheal",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Anti Diarrheal Anti Gas", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Up And Up Antibiotic Plus Pain Relief",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Up And Up Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Arthritis Pain Reliever", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Aspirin", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Athletes Foot", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Chewable Aspirin", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Childrens Acetaminophen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Childrens Allergy Melts", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Childrens Allergy Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Childrens Cold And Cough", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Up And Up Childrens Ibuprofen",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Cold And Hot Medicated", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Cold Flu Relief Day Night", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Up And Up Cough Plus Chest Congestion", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Daytime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Daytime Multi Symptom Severe Cold Nighttime Severe Cold And Cough", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Up And Up Daytime Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Up And Up Daytime Severe Cold Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Up And Up Daytime Vapor Ice Cold And Flu Nighttime Vapor Ice Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Up And Up Digestive Relief", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Dye Free Infants Concentrated Ibuprofen", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Esomeprazole Magnesium", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Extra Strength Antacid 750", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Extra Strength Gas Relief Chewables", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Extra Strength Smooth Dissolving Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Famotidine", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Famotidine Complete", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Up And Up Fiber Therapy",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Gentle Laxative", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Up And Up Hair Regrowth Treatment For Men",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Up And Up Hair Regrowth Treatment For Women",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Up And Up Hemorrhoidal",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Rectal" }] },
        ],
    },
    { Medication: "Up And Up Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Hydrocortisone Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Hydrocortisone One Per Cent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Up And Up Ibuprofen",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Ibuprofen Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Infants Acetaminophen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Infants Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Intensive Healing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Jock Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Lansoprazole", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Lidocaine Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Up And Up Lubricant", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Up And Up Lubricant Refreshing", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Up And Up Maximum Strength Fast Mucus Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Maximum Strength Mucus Relief Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Medicated Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Miconazole", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Up And Up Miconazole 1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Up And Up Miconazole 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Up And Up Miconazole 7", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Up And Up Mini Nicotine", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Mini Nicotine Polacrilex", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Mucus Relief Dm", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Multi Action Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Naproxen Sodium", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Naproxen Sodium Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Nasal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Up And Up Nasal Allergy", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Up And Up Nicotine",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Up And Up Nicotine Polacrilex",
        DoseTypes: [
            { DoseType: "Gum, Chewing", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Nighttime Cough Control Dm Max", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Nighttime Severe Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Up And Up Nighttime Sleep Aid",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Nighttime Vapor Ice Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Oil-Free Cleansing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Up And Up Omeprazole",
        DoseTypes: [
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Pain", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Powderlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Up And Up Severe Cold And Flu",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Up And Up Sinus Pe", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Up And Up Soothing Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up And Up Tioconazole 1", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Up And Up Vagicaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Up Toothpaste,Lemon Citron", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Upandup", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Upelva", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uplizna", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Upneeq", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Upper Resp Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Upper Respiratory Staph Strep Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Upper Respiratory Staph/Strep Combination", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Upper Respiratory Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Upset Stomach Relief", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Upset Stomach, Constipation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Upside Down Collector Fanny Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Upside Down Set Vol 1 Handcare", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Upside Down Set Vol 2 Bodycare", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Uptravi",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Uptravi Titration Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Uqora Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uqora Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urban Antioxidant Sunscreen Spf 40", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urban Decay Naked Skin One And Done Hybrid Complexion Perfector Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urban Decay Urban Defense Complexion Primer Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urban Legend Antioxidant Day Cream With Green Tea And Vitamin C Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urban Outfitters", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urban Street Ocean Breeze", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Urea",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Urea 20", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea 40", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea 40 Nail Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea 40 Percent", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea 40 Plus Ha", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea 40 Plus Sa", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea 41%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea 47% Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea Cream 40%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea Cream 41%", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urea Hydrating Topical", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urelle", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uremol", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uremol 20% Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uribel", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uric Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uric Acid Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uricalm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uriflex Fast Acting Analgesic Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urigel 80% Hand Sanitizer Cream Gel", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urigel 80% Hand Sanitizer Gel 3Ml Packet", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urigel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urigel Neutro Antiseptic Hand Sanitize 75%R 75%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urigel Neutro Hand Sanitizer Gel 80%", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Urimar-T", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urinary Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urinary Incontinence Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urinary Nosode Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urinary Tract Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uristat Ultra", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uro-458", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uro-Mp", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urocit-K", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urodel Homeopathic Urinary Tract Infection", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urogesic Blue", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uroxatral", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urso 250", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urso Forte", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ursodiol",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ursodiol 200 Mg", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ursodiol 400 Mg", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urtica Betula Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urtica Conchae", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urtica Conchae Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urtica Dioica (M)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urtica Dioica Ex Herba Essence Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Urtica Ferro 3 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Urtica Urens",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Urtica Urens Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Usana Celavive Daily Mineral Protective Cream Moisturizer With Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Usana Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Usf Inc. Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ustell", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ustilago Maidis", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ustilago Maydis", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Ustilago Tritici", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Usualklenz Disinfectantspray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Utah Juniper Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Uterine Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uterus Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Utiliderm Acne Control", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Utimi Water Based Personal Lubricant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Rectal; Vaginal" }] }] },
    { Medication: "Utira-C", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Utua Hand Sanitizer With Moisturizers", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uv Base Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uv Essentiel", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uv Protect Face Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Uva Ursi", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Uvadex", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "V", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "V Doctor Pure Chlorine Dioxide", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "V Force", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "V Force Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "V Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "V W", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "V Zero Hand Clean Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "V-Bella", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "V-Fem Care", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "V-Shapes Antiseptic Hand Sanitizer Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "V19Pan", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Va Harvest Silver Wound Gel For Pets", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vabomere", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vabysmo", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravitreal" }] }] },
    { Medication: "Vac-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vacation Chardonnay Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vacation Classic Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vacation Lip Trips Desserts",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Vacation Mineral Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vacation Spf 30 Classicspray", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vacation World",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Vacc Hc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vaccines", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vaccinosis Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vaccinotoxinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vaccistat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vagi-Cure Advanced Sensitive Medicated", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vagibiom", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Vagicaine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vagicaine Medicated Maximum Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vagical", DoseTypes: [{ DoseType: "Insert", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Vagifem", DoseTypes: [{ DoseType: "Insert", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Vaginae Synovialis Tendinum 30 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vaginitis", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vagisil Anti-Itch Creme Maximum Strength Sensitive Skin Formula", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vagisil Anti-Itch Creme Regular Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vagisil Anti-Itch Medicated Creme Maximum Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vagisil Anti-Itch Medicated Wipes Maximum Strength", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vagisil Brand Vagistat", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Vagistat", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Vagistat 3", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Valacyclovir",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Valacyclovir Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Valchlor", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Valcyte",
        DoseTypes: [
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Valentino Very Valentino Light Lasting Perfecting Foundation Broad Spectrum Spf 26 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Valeriana Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valeriana Conchae", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valeriana E Rad. 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Valeriana Officinalis",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Valeriana Officinalis (N)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valeriana Officinalis Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valerin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Valganciclovir",
        DoseTypes: [
            { DoseType: "For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Valganciclovir Hydrochloride",
        DoseTypes: [
            { DoseType: "For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Valganciclovir Hydrochloride For Oral", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valladerm-90", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vallegel Prep", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vallement Flex", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Valley Fever Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Valproate Sodium",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Valproic", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Valproic Acid",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Valrubicin Intravesical Solution", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Intravesical" }] }] },
    {
        Medication: "Valsartan",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Valsartan And Hydrochlorothiazide",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Valsartan/Htcz", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valstar", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Intravesical" }] }] },
    { Medication: "Valtoco", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Valtrex", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valtrum Aerobic Sports", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Valtrum Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Valtrum Topical Analgesic Roll On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Valumeds Allergy Relief", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valumeds Aspirin", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valumeds Aspirin Free Headache Relief Pm", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valumeds Extra Strength Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valumeds Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valumeds Maximum Strength Stay Awake", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valumeds Natural Laxative", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valumeds Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Valvula Aortae 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vamousse Lice Defense", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vamousse Lice Treatment", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vamousse Lice Treatment Complete Kit", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanacof", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vanacof Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vanacof Dmx", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vanalice", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanatab Dm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vancocin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vancomycin",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    {
        Medication: "Vancomycin Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vandazole", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Vanibiss Butt And Thighs Acne Treatment Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream Anti-Perspirant/Deodorant", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream Facial Moisturizer Broad Spectrum Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream Hc Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream Lip Protectant/Sunscreen", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream Sunscreen Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream Sunscreen Sport Broad Spectrum Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanicream Z-Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vanilla",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Vanilla Blossom", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Coconut Scent Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Cupcake Frosting Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Frosting Scent Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Ice Cream Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Latte Tinted Moisturizer Broad Spectrum Spf 25 Sunscreen Light", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Lavender Mint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Vanilla Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Mint Broad Spectrum Spf 15 Lip Moisturizer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Mint Lip", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Mint Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Perfecting Makeup Broad Spectrum Spf 25", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Sanimoist Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Silq", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vanilla Silq Hd", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral; Rectal" }] }] },
    { Medication: "Vanilla Silq Md", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral; Rectal" }] }] },
    { Medication: "Vanilla Sky Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Spf 15 Lip", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Spf 30 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Sugar Anti-Bacterial Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Sugar Cookie Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Sugar Cupcake Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Sugar Scented Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanilla Sugar Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaniqa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanitizer I-200", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanos", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanoxide", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vanquish", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vanquish Digital Headache", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vapor Chill Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vapor Ice Daytime And Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vapor Inhaler", DoseTypes: [{ DoseType: "Inhalant", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Vaporal Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaporal Hand Sanitizer Aloe Vera", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaporal Hand Sanitizer Eucalyptus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaporal Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaporal Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaporex", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vaporizing",
        DoseTypes: [
            { DoseType: "Inhalant", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    {
        Medication: "Vaporizing Chest Rub",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Vaporizing Chest Rub 4 Oz.", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaporizing Colds Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaporx", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaprisol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vaprisol Dextrose In Plastic Container", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vaproizing", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Vaqta", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    {
        Medication: "Vardenafil",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Vardenafil Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Varecell Intensive Liposome Booster", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Varecell Intensive Liposome Doublecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Varecell Intensive Liposome Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Varenicline",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Varibar Honey", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Varibar Nectar", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Varibar Pudding", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Varibar Thin Honey", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Varibar Thin Liquid", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Varico", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Varico Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Varicose Vein Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Varicose Veins",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Varigard Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Varithena", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Varivax", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Varix", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Varizig", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Varophen", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Varubi", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vascepa", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vascu-Pro", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vascuflow", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vascular", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vaseline",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Vaseretic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vasocaine", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vasopressin",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }, { Route: "Parenteral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Vasostrict", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vasotec", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vasseur Skincare Day Matte Spf30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vastizer Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaswani Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vax", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vax Reaction", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vaxchora", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vaxelis", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Vaxneuvance", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Vaxol Puri", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vaxr Hc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vazalore", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vazculep", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vcf Contraceptive", DoseTypes: [{ DoseType: "Film, Soluble", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Vcf Contraceptive Pre-Filled Applicators", DoseTypes: [{ DoseType: "Gel, Metered", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Vclear Berry Dissolving Tablets", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vecamyl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Veclat Ou C.Gnature 3 Lighty Blending Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veclat Ou C.Gnature 3 Lighty Blending Face Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veclat Ou C.Gnature 3 Power Radiant Ampoule", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veclat Ou C.Gnature 3 Power Radiant Face Mask", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veclat Ou C.Gnature 3 Power Radiant Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vectibix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vectical", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vecuronium Bromide",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Veeda Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vegan Collagen Every Day", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vegetable Laxative",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vegetable Laxative And Stool Softner", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vegetable Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Veilment Natural Spa Black Rose", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veive Cbd Muscle Relaxer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veive Cbd Pain And Cramps", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Veklury",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Velatrin Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Velcade", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Veletri", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Velivet", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Velnue", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Velphoro", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Veltassa", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Veltin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Velvet Grass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Velvet Mesquite Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Velvet Peach", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Velvetgrass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Vemlidy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Venclexta",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Veneno De Abeja", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Venexa", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Venexa Fe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Venipuncture Px1", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Venlafaxine",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Venlafaxine Hcl", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Venlafaxine Hcl Er", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Venlafaxine Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Venngel One", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Venodol", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Venofer", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Venofer (Iron Sucrose)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Venoforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Venofye Orchard Bee Brilliance Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ventavis", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Ventolin", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Ventolin Hfa", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Ventrixyl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ventrixyl Fe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Venus Mercenaria", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ver", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vera Refresh Hand", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veraciti", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Verapamil Hci",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Verapamil Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Verapamil Hydrochloride",
        DoseTypes: [
            { DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Veratrum Alb", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Veratrum Album",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
        ],
    },
    { Medication: "Veratrum Album Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Veratrum Chamomilla",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Veratrum Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Veratrum Viride", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Verbascum Thapsus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Verbena Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verdant Antibacterial", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verdant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verdant Isopropyl Alcohol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verdeso", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veregen", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verelan", DoseTypes: [{ DoseType: "Capsule, Delayed Release Pellets", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Verelan Pm", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Veribella Protect Daily Drops", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verkazia", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "Vermafuge -Ag", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vermex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vermiclear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vermistat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vermox", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Verquvo", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Verruguin Liquid Wart Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verrustat Medicated Plantar Pads", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verrustat Wart Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Versacloz", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vertebra Thoracica 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vertebrae Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vertiforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vertifree", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vertigo Syncope Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vertigone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vertitone", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vertra Ehukai Beige Face Stick Spf 38", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vertra Hawaiian Shaved Ice Pineapple", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vertra Lava Lava Hibiscus", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vertra Mick Fanning Signature Foundation Sunscreen Face Stick Spf 35 Coolangatta Beige", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vertra Pearl White Face Stick Spf 50", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vertra Premium Suncare Spf 30",
        DoseTypes: [
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Vertra Premium Suncare Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vertra Premium Suncare Spf 50 Unscented", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vertra Reef Safe Lip Balm Spf 15", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vertra S Dorian Kona Gold Signature Face Stick Spf 38", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Very Emollient Body Daily Shade Spf15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Verzenio", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vesica Fellea 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vesica Fellea Ferrum 17/20 Special Order",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vesica Fellea Ferrum 6/8 Special Order", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vesicare",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vespa Crabro", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vespa Crabro 15 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vespa Crabro Dks", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vespa Crabro Ex Animale 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vestura", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vet One", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veteran Shield 24", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Veterans United Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vfend",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vfender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vhp Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vi-Clean", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vi-Trap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viabecline", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viabecline First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viadox", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Viagra", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vial Kit3.98", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viamed Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viatrexx- Sinister 7", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Cellulite", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Enzyme Plus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-F Gus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Gi", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-H Metals", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Hair", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Mesenchyme", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Pigmentation", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Prolo-Neural", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Str Ss", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Tone", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Viatrexx-Virus", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral; Topical" }] }] },
    { Medication: "Vibativ", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Viberzi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vibramycin Calcium", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vibramycin Hyclate", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vibramycin Monohydrate", DoseTypes: [{ DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vibriance", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viburnum Opulus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vica-Cet", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vichy Laboratoires Aqualia Thermal Uv Defense Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Capital Soleil 30 Broad Spectrum Spf 30 Sunscreen Luxurious Protective", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Capital Soleil 50 Daily Antiaging Face Sunscreen Broad Spectrum Spf 50 Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Capital Soleil 60 Daily Antiaging Face And Body Sunscreen Broad Spectrum Spf 60 Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Capital Soleil 60 Mineral Sunscreen Broad Spectrum Spf 60 Water Resistant 80 Minutes Sheer Tint Ultra Lightweight", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Ideal Capital Soleil 45 Sunscreen Broad Spectrum Spf 45 Water Resistant 80 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Ideal Capital Soleil 50 Sunscreen Broad Spectrum Spf 50 Water Resistant 40 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Ideal Capital Soleil 60 Sunscreen Broad Spectrum Spf 60 Water Resistant 40 Minutes", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Normaderm Beautifying Anti Acne Care 24 H Hydrating Acne Treatment", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Normaderm Daily Gentle Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Normaderm Phytoaction Acne Control Daily Moisturizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Normaderm Phytoaction Daily Deep Cleansing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratoires Normaderm Sos Acne Rescue Spot Corrector", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratories Aqualia Thermal Uv Defense Spf 30 Mineralizing Thermal Water Niacinamide Plant Sugar Vitamin E 48 Hr Dynamic Hydration Oil Free Lightweight Texture All Skin Types", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratories Capital Soleil Uva Uvb 60 Titanium Dioxide 100% Mineral 15 Mineral Rich Water Ultra Lightweight", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vichy Laboratories Liftactiv Peptide-C Spf 30 Phyto Peptides Vitamin C", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vicks", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Alcohol Free Nyquil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Childrens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Childrens Nyquil", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vicks Dayquil",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vicks Dayquil And Vicks Nyquil", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Oral" }] }] },
    { Medication: "Vicks Dayquil And Vicks Nyquil Cherry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Dayquil Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Dayquil Nyquil Severe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vicks Dayquil Severe",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vicks Dayquil Severe And Vicks Nyquil Severe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vicks Dayquil Tropical And Vicks Nyquil Cherry", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Flu Therapy", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vicks Formula 44",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vicks Formula 44 Vapocool", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Jarabe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vicks Nyquil",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vicks Nyquil Hbp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Nyquil Kids", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vicks Nyquil Severe",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vicks Severe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Sinex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Vicks Sinex Severe", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Sinex Severe Vapocool", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Vicks Vapo Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vicks Vapocool",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vicks Vapodrops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vicks Vaporub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vicks Vaposteam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    {
        Medication: "Vicks Zzzquil",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Victoria Bay Anti-Bacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Victoria Bay Antibacterial Plum Foam Handwash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Victoria Bay Soft Touch Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Victorias Secret Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Victory 19 Virus Out", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Victoza", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Vida Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vida Mia Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vidaza", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Vie Naturelle Acne Body", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viekira Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vienva Tm", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Vigabatrin",
        DoseTypes: [
            { DoseType: "For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vigabatrin For Oral Solution", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vigabatrin For Oral Solution Usp, 500 Mg", DoseTypes: [{ DoseType: "For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vigadrone", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vigamox", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Vigen Dr. Nattokinase", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vigofem For Women", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vigoril For Men", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral; Sublingual" }] }] },
    { Medication: "Vigoril Plus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Sublingual" }] }] },
    {
        Medication: "Viibryd",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Viiva", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viiva Bioderma Actives", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viiva Bioderma Actives Hgh Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vijoice",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vilazodone", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vilazodone Hydrochloride",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Villa Floriani Aloe Ultra-Hydrating Day Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Villa Floriani Chamomile Calming Day Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Villa Floriani Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Villa Floriani Mint Purifying Day Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Villimed Antifungal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viloe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viltepso", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vimizim", DoseTypes: [{ DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vimovo", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vimpat",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vinblastine Sulfate", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vincasar Pfs", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vincristine Sulfate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vindicator - E", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vindicator Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vine Vera Resveratrol Cabernet Replenishing Moisture", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viniferamine Antifungal Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viniferamine Antiseptic Cleanser", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viniferamine Clean N Moist", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Viniferamine Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Viniferamine Renewal Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viniferamine Skinmineralz", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vinorelbine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Viokace", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viola Comp.", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viola Odorata", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Viola Tricolor",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Viola Tricolor (M)", DoseTypes: [{ DoseType: "Tincture", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vionex", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vionex Towelette", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vionexus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viorele", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Viper Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vipera Berus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vipy Antibacterial Sanitizing Wet Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vir Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viracept", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viraclear Eps 7630 Cherry Flavor", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viraclear Eps 7630 Original", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viracomp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viral", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viral Hp", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viral Immune Stimulator", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Viramune",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Virazole", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Vircin Advanced Wart Treatment", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Viread",
        DoseTypes: [
            { DoseType: "Powder", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Virex", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virginia Live Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Virginia Scrub Pine Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Viril", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virimmune", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viro Fense", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viro Flu Pm Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viroccinum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viroclear", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Virofense", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Virosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virotox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virtussin A/C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virtussin Ac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virtussin Ac W/Alc", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virtussin Dac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viru-Chord", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virurcatcher", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Virus Barrier Plus", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Virus Combination", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virus Defense", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virus Gone", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Virus Kill Sanitizer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Virus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virus Plus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Virus Shield Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Virus Shield Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Virustat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visage", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Visage Envy Facial Moisturizing Spf30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Visage Envy Invisible Face Gel Spf50", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Visage Envy Remodeling Day Cream Spf15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Visceral Polarity", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visco Shield", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visco-Gel Medicated Round Callus Removers", DoseTypes: [{ DoseType: "Disc", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Visconsin Mali 0.1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visconsin Mali 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visconsin Mali 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visconsin Mali 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visconsin Mali 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visconsin Mali 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visconsin Mali 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viscum 10", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viscum 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viscum Abietis", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis 100", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis 12.5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis Series 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis Series 1 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis Series 2 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis Series 3 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Abietis Series 4 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Viscum Album",
        DoseTypes: [
            { DoseType: "Globule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Viscum Album 1 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Viscum Belladonna",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Viscum Comp.", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Crataegi", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Crataegi Series 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Crataegi Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Crataegi Special Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Viscum Crataegus",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Viscum Echinacea",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Viscum Mali", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali 100", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali E Pl. Tota 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali E Pl. Tota 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali E Pl. Tota 5%", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali E Pl. Tota 6", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali Series 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali Special Series 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali Special Series 1 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Mali Special Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini 10", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini 100", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini 20", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini 30", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini 5", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini 50", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini E Pl. Tota 4", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini Series 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini Series 1 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini Series 2 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini Series 3 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini Series 4 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini Special Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Pini Special Series 4 7Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Populi", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Populi Series 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Populi Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Quercus 1%", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Viscum Quercus 6 Special Order", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Quercus Special Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Salicis Series 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Salicis Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Special Series C", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Viscum Stannum",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Viscum Tiliae", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Tiliae Series 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Tiliae Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscum Tiliae Special Series 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viscumforce", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visibly Clean Antibacterial Wet Wipes Blue Sage Lime", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Visibly Clean Antibacterial Wet Wipes Fresh Lemonade", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Visibly Clean Antibacterial Wet Wipes Strawberry Tart", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Visibly Clean Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Visine A.C. Itchy Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visine Allergy Eye Relief Multi-Action", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visine Dry Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visine Original", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visine Red Eye Comfort", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visine Red Eye Hydrating Comfort", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visine Red Eye Hydrating Comfort Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visine Red Eye Total Comfort Multi-Symptom", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Visionblue", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intraocular; Ophthalmic" }] }] },
    { Medication: "Visiopar", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visipaque", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravascular" }] }] },
    { Medication: "Vistacaine Topical Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Vistaril", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vistaseal", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vistogard", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visual Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Visudyne", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vit C Antioxidant Sunscreen Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita C Bright Eyecream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita C Bright Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita C Bright Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita C Brightcream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Plus Saniwipes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Rich", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Snail", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Vie Antibacterial Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Vie Hand Sanitizer Lavender", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Vie Hand Sanitizer Lemon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Vie Hand Sanitizer Tea Tree", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Vie Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita Vie Isopropyl Alcohol", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vita-Rx Diabetic Vitamin", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitabath Baby Eczema Care Intensive Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitabath Baby Eczema Care Moisture", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitabath Baby Eczema Care Moisture Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitabath Baby Soothing Relief Diaper Rash", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitabath Hand Sanitizer Cool Spearmint And Thyme", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitabath Hand Sanitizer Lavender Chamomile", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitacilina Bebe Diaper Rash", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitacilina Neomycin Sulfate First Aid Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitacin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vitafol",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Strip", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vitafol Fe Plus", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitafol Ob", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitafol-Ob Plus Dha", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vital", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Age Defiance", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Brain Energy", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Female Sexual Energy", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Gel Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vital Health Care Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vital Hgh Immune Booster", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Immune Booster", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Male Sexual Energy", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Shield 26 Hand Sanitizer Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vital Skin Hair Nails", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Vapor", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vital Weight Loss Hcg", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital Weight Loss Xl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital-D Rx", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vital-Fl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitale Sensitive Scalp Daily Scalp Healer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitality", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitalsail", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitalumiere Aqua", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vitamin A And D",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Vitamin A D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitamin B Complex", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vitamin C Brightening Facial Oil Stick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitamin C Daily Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitamin C Glow-Protect Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitamin C Suncare Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vitamin D",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vitamin Deficiency Injectable System - B12", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vitamin Deficiency System", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    { Medication: "Vitamin E Moisture-Protect Lip Care Spf 15", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitamin E Moisture-Protect Spf 30 Pa Plus Plus Plus", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitamin K1", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Vitamin K1 - Phytonadione", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Vitamin K1 Phytonadione", DoseTypes: [{ DoseType: "Injection, Emulsion", Routes: [{ Route: "Intramuscular; Intravenous; Subcutaneous" }] }] },
    { Medication: "Vitamins A And D", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitamins A And D Without Lanolin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitaplus Eco Hygiene", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitaplus Fore", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitaplus Ore", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitastem", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitastem Ultra", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitastem-Tetracycline Hydrochloride", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitavie", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitazing Spf 15 Energy Boosting Moisturizer With Mangosteen Broad Spectrum Spf 15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Vite20",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Vitiligo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitiligo Organics", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vitis Spp", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Vitis Stibium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vitrakvi",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oropharyngeal" }] },
        ],
    },
    { Medication: "Vitramyn", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitranol", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitranol Fe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitrase", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Vitrexate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitrexate Fe", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitrexyl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vitrexyl Plus Iron", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Viva", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Viva Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivacaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Vivacious 75 Ethyl Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivacious Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivacity", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vivaiodays Sunscreen With Turmeric", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivalacara Dermalere Advanced Burn And Scar Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivalacara Dermalere Advanced Skin Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivarin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vivelle-Dot", DoseTypes: [{ DoseType: "Patch, Extended Release", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Vivid Foameez Antimicrobial Foaming", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitar Advanced Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitar Antibacterial Hand Sanitizer Lavender Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitar Antibacterial Hand Sanitizer Lemon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitar Antibacterial Hand Sanitizer Ocean Breeze Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitar Antibacterial Hand Sanitizer Peach Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitar Antibacterial Hand Sanitizer Rose Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitar Ethyl Alcohol Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitar Ethyl Alcohol Antiseptic Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vivitrol", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vivlodex", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vivotif", DoseTypes: [{ DoseType: "Capsule, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vizamyl", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vizimpro", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vocabria", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vogelxo",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel, Metered", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Volnea", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Voltaren",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Volu-Sol Moisturizing Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Volumex", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vomiting Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vonjo", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vontee", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vonvendi", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Voquezna Dual Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Voquezna Triple Pak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Voraxaze", DoseTypes: [{ DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Voriconazole",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vortex Moisturizer Nasal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Vortex Moisturizer Oral", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vosevi", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Votivo Red Currant Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Votrient", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Voupre Age Defense", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Voxzogo 0.4Mg", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Voxzogo 0.56Mg", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Voxzogo 1.2Mg", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vpriv", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Vraylar",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vrmx 500", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vtama", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vting", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vtol Lq", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vuity", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Vulpur", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vumerity", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vusion", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vvardis Set Soft Mint", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vvardis Set Strong Mint", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Dental; Oral" }] }] },
    { Medication: "Vyepti", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vyfemla", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vyleesi", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Vylibra", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Vyndamax", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vyndaqel", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Vyondys 53", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vytis Shield Aloe Vera Foam Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vytone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Vytorin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Vyvanse",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Vyvgart", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vyxeos", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Vyzulta", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "W.Skin A.M", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Anti Hair Loss", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Cica Foot Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Cica Foot Peeling", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Cica Plus Repair", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Hand Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Hyaluronic Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Milky Perfect Cleansing Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Natural Fermented Grain Mask Sheet", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Stop-Aging Son Joo Rum Handcream Plus", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Treemon Cleansing Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W.Skin Treemon Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "W.Skin Triple Care Sun",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "W.Skin White Glutathione Tone Up", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "W/Clip Pdq Hand Sanitizer", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "W04", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Wakaya Ruru Topical Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wakix", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal Dryl",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal Dryl Allergy", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Fex 24 Hour Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Fex Allergy", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Flu Severe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Wal Flu Severe Cold", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Flu Severe Cold And Cough", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Four", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Wal Itin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Sleep Z", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Som", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Som Nighttime Sleep Aid", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Tussin", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Tussin Dm", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal Tussin Dm Max",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal Tussin Dm Max Nighttime Wal Tussin Dm Max", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Wal Zyr", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal Zyr D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal- Dryl Itch Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wal-Act", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Dram", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Dram 2 Quick-Dissolving", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal-Dryl",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Wal-Dryl Allergy",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal-Dryl Pe Allergy Plus Congestion", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Fex", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Finate", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Finate D", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal-Itin",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal-Itin D", DoseTypes: [{ DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal-Nadol",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal-Nadol Pm", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal-Phed",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal-Phed Head Congestion And Mucus", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal-Phed Pe",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal-Phed Pe Pressure Plus Pain", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal-Sleep Z",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal-Sleep Z Pain Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Tussin", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Tussin Dm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Zan", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wal-Zan 75", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wal-Zyr",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wal-Zyr D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Waldo Hydration Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walg Sun Moist Spf30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walg Sun Moist Spf50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Walgreen",
        DoseTypes: [
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Walgreen Alcohol", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreen Anti Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreen Anti-Itch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreen Cherry Zinc Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreen Cl Pectin Throat Pops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreen Cold Therapy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreen Cool N Heat Maximum Strength Pain Relieving", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreen Day Time Nighttime Cold And Flu Relief", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Walgreen Daytime Cold And Flu Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreen Fiber Lax", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreen Maximum Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreen Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreen Pain Relieving", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreen Saline", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Walgreen Sport Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreen Sterile Lubricant Drops 30 Count", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreen Sterile Lubricant Drops 70 Count", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreen Zinc Cold Therapy", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Walgreens",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }, { Route: "Vaginal" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }, { Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Walgreens 24-Hour Lidocaine Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens 50 Baby Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens 70% Isopropyl Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Acne Foaming Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Acne Foaming Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Acne Spot Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Adult Glycerin", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Walgreens Adult Nighttime Wal Tussin Dm Max Maximum Strength", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Advanced Hand Sanitizer Citrus Scent", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Advanced Hand Sanitizer With Fresh Scent", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Advanced Scar Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens All Purpose Psoriasis Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Allergy Eye", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Anorectal Lidocaine Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Antacid Pain Relief Extra Strength", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Anti Itch Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Antibacterial Plus Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Antiseptic Wound Wash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Apricot Scrub Acne Treatment", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Arthritis Pain Relief Capsaicin Analgesic", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Arthritis Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Baby Eczema", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Walgreens Black Elderberry Cold And Flu Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Walgreens Blueberry Raspberry Gentle Laxative", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Broad Spectrum Spf 110 Age Protection Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Broad Spectrum Spf 30 Dry Touch Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Broad Spectrum Spf 70 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Broad Spectrum Sport Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Burn", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Burn Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Camphor Phenol Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Chap Aid Cherry Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Chap Aid Lip Balm Strawberry", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Chap Aid Moisturizer", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Chap Aid Regular Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Chest Rub", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Children Glycerin", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    {
        Medication: "Walgreens Childrens",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Walgreens Cold Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Cool N Heat Dry", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Cooling", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Corn Remover", DoseTypes: [{ DoseType: "Strip", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Daily Repair Psoriasis Moisturizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Day And Night Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Walgreens Daytime", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Dry Touch Broad Spectrum Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Ear Wax Remover", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Walgreens Earache Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Walgreens Eczema", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Effervescent Cold Relief Plus", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Effervescent Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Eye Drops Advanced Relief 15Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Eye Drops Maximum Redness Relief 15Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Eye Drops Original 15Ml And Twin Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Eye Drops Seasonal Relief 15Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Fast Acting Heartburn Relief Extra Strength", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Flu Relief", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Foaming Acne Scrub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Foaming Body Wash Acne Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Gentle Laxative", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Walgreens Ice Blue Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Ice Blue Pain Relieving", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Infants Dye Free Gas Relief", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Kids Baby Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Lice Elimination", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Lubricant Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Lubricant Eye Drops Dry Eye 15Ml And Twin Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Lubricant Eye Drops High Performance 15Ml And Twin Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Walgreens Lubricant Eye Drops Preservative Free",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Ophthalmic" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] },
        ],
    },
    { Medication: "Walgreens Lubricant Eye Drops Restorative Performance", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Lubricant Eye Pm Sterile", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Lubricant Eye Preservative Free", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Maximum Strength Jock Itch Antifungal", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Maximum Strength Non Drowsy Day And Night Cold And Flu", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Walgreens Maximum Strength Non-Drowsy Day And Night Sinus And Cold", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Walgreens Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Medicated Antifungal Athletes Foot", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Medicated Foot Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Mixed Berry Benz-Dex Lozenges", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Multi-Antibiotic With Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Multi-Symptom Relief Eye Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Natural Yeast Treatment", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Walgreens Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Original Strength Anti Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Pain Relief", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Pain Relief Powder", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Pain Relieving Plus Turmeric", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Pinworm Medicine", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Pm Lubricant Eye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Redness Relief Eye Drops 15Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Saline Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Walgreens Scar Cream W/Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Senna Laxative", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Sinus Wash Squeeze Bottle Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Walgreens Sodium Chloride Ophthalmic 5 Percent Hypertonicity Eye", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Spf 30 Hydrating Continuous", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Spf 50 Clear Complexion For Face", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Spf 50 Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Spf 55 Dry Touch", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Spf55 Sunscreen", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Spf55 Sunscreen Reef-Conscious Formula", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Sport Broad Spectrum Spf 50 Faces Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Sport Faces Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Sport Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Sport Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Sport Spf 50 Sunscreen", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Sterile Soothing Eye Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Walgreens Stool Softener", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Stye Eye Relief", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    {
        Medication: "Walgreens Sunburn Relief",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Walgreens Throat Relief Pops - Clean Label", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Tolnaftate", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walgreens Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Urinary Pain Relief Maximum Strength", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walgreens Zinc Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walkin Around With Poop In My Hand Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walkingsoap Antibacterial Hand Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wallgreens Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walmart", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walmart Equate Beauty Ultra Light Sunscreen Broad Spectrum Spf 55", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walmart Equate Beauty Ultra Light Sunscreen Spf 100", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Walmart Maximum Strength Urinary Pain Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walnut Mix", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Walphed D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Walprofen Congestion Relief And Pain", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Waltz Free Hand Sanitizer", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waltz Free Hand Sanitizer Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Wancare",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Wander Pack Up And Glow", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wangza Mens Body Massage Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warfarin Sodium", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Warm Vanilla Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warming Epsom Rub", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Moisturizing Hand Sanitizer Apple", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Moisturizing Hand Sanitizer Peach", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Sanitizer Clip And Clean How You Doin Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Sanitizer Clip And Clean Oh My God Peach", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Sanitizer Clip And Clean On A Break Pear", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Sanitizer Clip And Clean We Know Grapefruit", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Sanitizer Clip And Clean, How U Doin Coconut", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Sanitizer Clip And Clean, We Know, Strawberry", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warner Friends Sanitizer Clip And Clean, We Were On A Break, Apple", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warrior", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warrior Antifungal Foot", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warrior Chiggerex Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warrior Pain Relief Roll-On", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warrior Pain Relieving", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wart", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wart Control", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wart Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wart Free",
        DoseTypes: [
            { DoseType: "Salve", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wart Remover", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wart Remover Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wart Remover Liquid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wartcalm", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Warticide", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wartovir Wart Remover", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wartrin Wart Remover", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Warts", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Warts - Moles - Skin Tags",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wartstick", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wartstick Plantar", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wash Free Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wash With Water Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wash-Free Surgical Hand Disinfectant Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wash-Free Surgical Hand Disinfectant Gel Ii", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wasp Hymenoptera Venom", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Wasp Hymenoptera Venom Multidose", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Wasp Hymenoptera Venom Venomil Diagnostic", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intradermal; Percutaneous" }] }] },
    { Medication: "Wasp Hymenoptera Venom Venomil Maintenance", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Wasp Venom Protein", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Water", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Water Guard Slightly Acidic Electrolyzed Hypochlorous Acid Water Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Water Hemp", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Water Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Water-Free Antibacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Water-Jel 3-In-1 Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Water-Jel Neomycin Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Water-Soluble Human Body Lubricant", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Cutaneous; Vaginal" }] }] },
    { Medication: "Waterless Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermans Spf 55", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Watermelon",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Watermelon Gel Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Glow Niacinamide Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Hand Sanitizer Girl", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Mango Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Save The Animals", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Watermelon Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Tanning Oil Spf 6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Tanning Oil Spf15", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Tanning Oil Spf6", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Twist Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watermelon Wonder Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waterrok Fulvic Acid", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watson Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Watson Hand Sanitizer Aloe", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wave Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wax B Gone Ear Wax Removal Drops", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wax Myrtle Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Wax Out Plus", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Waxhead Baby Sunscreen Spf 35", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxhead Skin Nourishing Sunscreen Spf 32", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxhead Skin Nourishing Tinted Plus Sunscreen Spf 31", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxhead Skin Nourishing Tinted Sunscreen Spf 31", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxhead Tinted Zinc Oxide Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxhead Zinc Oxide Sunscreen Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxie Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxie Clear Tone Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxiecare Pink Antibacterial Ltion So Ap", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxman Kleem Freak Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxman Kleen Freak Disinfecting Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxman Kleen Freak Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxman Kleen Freak Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxman Kleen Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Waxrx Ear Wax Removal Aid Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Auricular (Otic)" }] }] },
    { Medication: "Wbm Care Alcohol Cleaning Wet Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wbm Care Wet Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wd Sanitizing Wipes Lemongrass Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wdc Antibacterial Travel Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wdc Antibacterial Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "We Care Sanitizing Wipe", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "We Clean Hand Sanitizer Apple Cider", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "We Clean Hand Sanitizer Candy Corn", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "We Clean Hand Sanitizer Caramel Apple", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "We Clean Hand Sanitizer Fall Chai", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "We Clean Hand Sanitizer Gel - Tea Tree", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "We Clean Hand Sanitizer Roasted Marshmallow", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "We Sell Hope Egf Essence", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weadvanced Lavender Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weadvanced Original Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weakness", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Weber Rinse Free Skin Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wecare Anti-Dandruff", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Antibacterial Hand Sanitizer Eucalyptus Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Antibacterial Hand Sanitizer Tea Tree Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Antibacterial Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean C2 Tlcuo", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Foam Hand Sanitizer Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Gel Hand Sanitizer Eucalyptus", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer - Lemon Scent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer - Orange Apple Scent", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Apple Cider Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Candycorn Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Caramel Apple Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Cinnamon Stick Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Cranberry Spice Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Fall Chai Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Gel - Eucalyptus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Gingerbread Cookie Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Lemon Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Mistletoe Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Orange Apple Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Peppermint Bliss Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Pumpkin Swirl", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Pumpkin Swirl Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Starry Night Citrus Spice Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Sugar Cookie Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Sugar Plum Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Toasted Marshallow Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Winter Berry Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weclean Hand Sanitizer Winter Wonderland Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weed And Grass Antigen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Weed Grass Antigens", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Weed Mix Number 7B", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Weeks And Leo Complete Lice Treatment", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weeks And Leo Extra Strength Itch Stopping", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weeks And Leo Medicated Vaporizing Steam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Wegovy", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Weh-Weh Natural Pain Relief Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weh-Weh Pain Reliever Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weight Control", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Weight Gloss", DoseTypes: [{ DoseType: "Lipstick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weight Management", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Weight Management Formula", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Weight Management Support", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Weightless Protection", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Weis Simply Great Advanced Hand Sanitizer With Vitamins E And B5", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Welby All Day Allergy Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Welby Allergy Relief",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Welby Childrens Ibuprofen", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Welby Clearlax", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Welby Fluticasone", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Welby Ibuprofen", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Welby Mucus Relief", DoseTypes: [{ DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Welby Nighttime Cold And Flu", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Welby Omeprazole", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Welchol",
        DoseTypes: [
            { DoseType: "For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Welireg", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Welkin Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Well At Walgreens",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Kit", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Powder, For Solution", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Well At Walgreens Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well At Walgreens Blemish And Blackhead Control Apricot", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well At Walgreens Castor Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Well At Walgreens Kids Sunscreen Broad Spectrum Spf 50", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well At Walgreens Mist Sheer Broad Spectrum Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well At Walgreens Spot Acne Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well Being Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 10W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 11W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 12W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 13W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 14W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 1C", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 2W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 3N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 4W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 5N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 6W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 7N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 8N", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Bio Tint Spf 30 Sunscreen 9W", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Lip Butter Spf 15 Sunscreen Afterglow", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Lip Butter Spf 15 Sunscreen Natural", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Lip Butter Spf 15 Sunscreen Peach", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Well People Lip Butter Spf 15 Sunscreen Wine", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Wellbutrin",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Wellderma Clean Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wellgate Anti-Bacterial Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wellguard", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wellheal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wellmind Calming", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wellmind Mental Focus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wellmind Vertigo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wellness Earache", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wellness Products J-Flex", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wellness Products Victorx", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wellpatch Cooling Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wellpatch Warming Pain Relief", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wellstar Amenity", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Welly Adventure First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Welly Bravery Balm", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Welly Bravery First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Welly Bravery Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Welly Bravery Triple Antibiotic With Pain Relief", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Welly Calm Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Welly Clean Hands", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Welly Excursion First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Welly First Aid Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Welly Human Repair Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Welly Itch Fix", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Welly Quick Fix Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Welly Superhero Supplies Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Welmate Anti Diarrheal Loperamide Hcl", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Welmate Anti Diarrheal Loperamide Hcl Softgels", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Welmate Lidocaine Pain Relieving Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Percutaneous; Topical; Transdermal" }] }] },
    { Medication: "Wentworth Alcohol Free", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Wera", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    { Medication: "West Nile Virus Nosode", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "West Parasite Detox", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Western 10 Tree Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Western 3 Tree Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Western Cottonwood Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "Western Juniper Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Western Oak Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Western Ragweed", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Western Ragweed Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Western Ragweed Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Western Sycamore Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Western Walnut Pollen Mix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Western Waterhemp Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Western Weed Mix", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Western Wheatgrass", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Western Wheatgrass Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Western White Oak Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Western White Pine Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Westussin Dm", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wet Eczema Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wet Nap", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Nap Antibacterial Hand Wipes Fresh", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Naps Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Ones Antibacterial Hand Wipes Fresh Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Ones Antibacterial Hand Wipes Ocean Breeze", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Ones Antibacterial Hand Wipes Spring Bliss", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Ones Antibacterial Hand Wipes Tropical Splash", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Ones Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Ones Hand Sanitizer Fresh Scent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Ones Hand Sanitizer Mist Lavender Blossom", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Ones Hand Sanitizing Wipes Plus", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Wet Wipe",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Wet Wipe 12 Pack", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet Wipe 36 Pack", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Wet Wipes",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Swab", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Wet Wipes Y-40", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wet-Nap Antibacterial Hand Wipes Citrus", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wewell Anti Bacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wf Face Fitness Btx Collagen Soothing", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whamisa Organic Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whamisa Organic Hand Sanitizerspray", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "What A Complete Shit Show Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "What Virus", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wheat Grain", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] }] },
    { Medication: "Wheat Mix", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whipped Vanilla Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whisk Alcohol Sanitizing Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whisk Care 371", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whisk Care 373", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whisk Care 375", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 357", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 362", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 365", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 367", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 371", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 373", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 375", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 375 Fragrance Free", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 377", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 377 Free", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whiskcare 387", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Alder Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    {
        Medication: "White Ash Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "White Birch Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "White Chestnut", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "White Citrus Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Cream", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Faced Hornet Hymenoptera Venom Multidose", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "White Faced Hornet Hymenoptera Venom Venomil Diagnostic", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intradermal; Percutaneous" }] }] },
    { Medication: "White Faced Hornet Hymenoptera Venom Venomil Maintenance", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "White Faced Hornet Venom Protein", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "White Flower Analgesic Balm", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Flower Analgesic Balm Floral Scented", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Flower Strain Relief", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Glo 2 In 1 With Mouthwash", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "White Glo 2In1 Tooth W Mouthwash", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "White Glo 2In1 With Mouthwash", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "White Glo Bio-Enzyme", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "White Glo Charcoal", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "White Glo Charcoal Deep Stain Remover Tooth", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Dental" }] }] },
    { Medication: "White Glo Charcoal Sensitive", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "White Glo Coffee Tea Drinkers Formula", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "White Glo Instant White", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "White Glo Maximum White Charcoal", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "White Glo Micellar", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "White Glo Mouthwash Protection", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "White Glo Professional Choice",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "White Glo Smokers Formula", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    {
        Medication: "White Hickory Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "White Labs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "White Mertodol",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "White Mulberry Pollen", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "White Mulbery Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    {
        Medication: "White Oak Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "White Petrolatum",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "White Poplar Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    {
        Medication: "White Potato",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "White Rain Cocoa Butter Petroleum Jelly", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Rain Original Petroleum", DoseTypes: [{ DoseType: "Jelly", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Rose Petroleum Jelly", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "White Seedless Grape", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "White Tetterine", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Whitening",
        DoseTypes: [
            { DoseType: "Paste", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] },
        ],
    },
    { Medication: "Whitening Kit", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Whitening Plus", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Who Recommended Hand Rub", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whole Body Detox Liquescence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whole Care", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Whole Foods 365 Herbal Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whole Foods Market Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Whole Foods Market Health Ultra Strength Natural Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whole Foods Market Regular Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whole Grain Barley", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Whole System Eep", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whole Wheat", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Wholistic Thyroid Balance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Acne", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Colds And Cough", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Congested Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Constipation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Diarrhea", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Dry Eyes", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Flatulence", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Fungus", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Hangover", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Hay Fever", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Headache", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Hot Flashes", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Low Back Pain", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Minor Arthritic Pain", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Skin Irritation", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Stings", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Teething", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Whp Be Gone Wheezing", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wick Storm Tea Tree Antifungal Foot Body Wash", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wicked Cool Anticavity - Lmft51 Mrt", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Wicked Cool Fluoride Tween Mild Mint Lmft", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Wilate - Von Willebrand Factor/Coagulation Factor Viii Complex (Human)", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Wild Horse 777 Moisturizing Nasal", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wild Horse 777 Oral", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wild Mint Clear Spf 45", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wild Watermelon", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wild Yam Ginger", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Wildberry Spf 15 Broad Spectrum Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Williams Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Williams Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Willow Fern", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Willow Wart", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wilma Schumann Moisture Protektiv Day Cream Spf-20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wilzin", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Bacitracin Zinc", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Cherry Spf 4 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Cold And Hot Medicated Patch Large", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Foods", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Foods Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Foods Antacid Ultra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Foods Antifungal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Foods Childrens Allergy Relief", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Foods Childrens Multi Symptom Cold", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Foods Effervescent Antacid And Pain Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Foods Effervescent Cold Relief", DoseTypes: [{ DoseType: "Tablet, Effervescent", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Foods Extra Strength", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Foods Gas Relief", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Foods Hydrocortisone Cream Intensive Healing", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Foods Maximum Strength Oral Analgesic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Foods Oil Free Acne Wash With Pink Grapefruit", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Foods Saline Nasal Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Winco Foods Stomach Relief",
        DoseTypes: [
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Winco Foods Ultra Strength", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winco Hemorrhoidal", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Maximum Strength Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Maximum Strength Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Medicated Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Moisture Spf 15 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Original Formula Eye Drops", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Winco Original Spf 4 Lip Balm", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Original Strength Anti-Itch", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Original Strength Triple Antibiotic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winco Stool Softener", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wing Scale Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Wingscale", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Winlevi", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winner Alcohol", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winners Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winnie Flex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winning Hands Foaming Antibacterial", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winova Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winrho Sdf", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intramuscular; Intravenous" }] }] },
    { Medication: "Winter Apple", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winter Berry Scented Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winter Blend", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Winter Bloom Scented Antibacterial Hand Wash", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winter Frost Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Winterfat Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Wintergreen Peppermint", DoseTypes: [{ DoseType: "Paste, Dentifrice", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Wipe", DoseTypes: [{ DoseType: "Dressing", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipe All-Purpose Wipes Fresh Lemon Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipe All-Purpose Wipes Lemon Scent", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipe Out Antibacterial Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipe Out Antibacterial Wipes, Fresh Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipe Out Antibacterial Wipes, Lemon Scent", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipe Out Safe Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Wipe Outz Cleansing Tattoo Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipes Plus", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipes4Health", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wipex", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wise Clean Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wise Shield", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wise Shopper 50% Isopropyl Rubbing Alcohol W/ Wintergreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wise Shopper Isopropyl Alcohol 50%", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wish", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Wish Advanced Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Wish Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wish Hand Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wish Ultra Hand Sanitizer A Coconut Kinda Life", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wish Ultra Hand Sanitizer Dream In Cherry Blossoms", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wish Ultra Hand Sanitizer Easy Peasy Lemon Squeezy", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wish Ultra Hand Sanitizer I Love You Cherry Much", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wish Ultra Hand Sanitizer Lifes A Peach Beach", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wish Ultra Natural Epsom Salt", DoseTypes: [{ DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Wish Ultra Slim Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Witch Hazel",
        DoseTypes: [
            { DoseType: "Cloth", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Witch Hazel Pore Clarifying Toner", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "With Max", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wixela Inhub", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Wl-Aaa-Z-A-525", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaa-Z-A-625", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1001", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1003", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1018", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1019", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1026", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1095", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1117", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1118", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1173", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1190", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1350", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-1351", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-330", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-401", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-471", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-780", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-807", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-850", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-980", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-982", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aab-Z-W-988", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aac-Z-A-1312", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aac-Z-A-1313", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aac-Z-A-1314", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aac-Z-A-567", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aac-Z-A-568", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aac-Z-A-790", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aac-Z-A-792", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aac-Z-A-809", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1020", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1038", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1049", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1050", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1051", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1052", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1053", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1054", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1055", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1056", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1057", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1058", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1059", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1060", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1062", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1063", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1064", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1065", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1189", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1226", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1311", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1347", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1367", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-1386", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-691", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-750", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-751", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-752", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-753", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-754", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-755", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-756", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-757", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-758", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-759", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-760", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-761", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-762", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-763", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-764", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-765", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-766", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-772", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-971", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aad-Z-W-987", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Z-O-1032", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Z-O-1039", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Z-O-1307", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Z-O-1342", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Zt-O-1258", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Zt-O-1337", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Zt-O-391", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Zt-O-400", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Zt-O-413", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Zt-O-414", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Zt-O-416", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aae-Zt-O-492", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aai-Zt-P-522", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aai-Zt-P-523", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aai-Zt-P-524", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-1138", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-508 (Kopari 50Ml)", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-633", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-796", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-818", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-832", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-838", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-870", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-914", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aaj-Z-W-942", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aan-Z-A-822", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aas-Z-A-348", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aca-Z-A-1405", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aca-Z-A-1428", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aca-Z-A-1444", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wl-Aca-Z-A-1473", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wlp40", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wok Lok Pain Relieving Oil", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wolf Daily Moisturizier Spf 15", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Woman Power", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Womans Laxative",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Women To Blame", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Womens Gentle Laxative",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Womens Laxative",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Sugar Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Womens Laxative Enteric Coated", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Womens Minoxidil", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Womens Relief Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Womens Rogaine Unscented",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Solution", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Womens Sports Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonder Glow Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonder Hand Fresh Gel 62% (250Ml)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonder Hand Fresh Gel 83% (Lemon Flavor) (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonder Hand Fresh Gel Lemon Flavor (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonder Hand Gel (Ethanol)", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonder Hand Gel (Ethanol) 72% 100Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonder Hand Gel (Ethanol) 72% 30Ml", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonder Hand Sanitizer Liquid (Ethanol) 70% 40Ml", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wonderbelly Maximum Strength Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wong To Yick", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wood", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Workforce", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Workforce Foaming Hand Sanitizer E3",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Workforce Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Workforce Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Workforce Personal Care Hand Sanitizer Lemon Tea Tree", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Workforce Personal Care Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Cutaneous" }] }] },
    { Medication: "Workmans Friend - Superior Hand Sanitizer With Aloe And Chamomile", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Workmans Friend - Superior Topical Sanitizer And Antiseptic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Workmans Friend Superior Barrier Skin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Workvie", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Workvie Work Pain Relief Therapy Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wormwood Sage Pollen", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] }] },
    { Medication: "Wotnot Naturals Natural Sunscreen Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound And Burn Care Refill Pack", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound Care", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound Cleanser", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound First Aid", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound Gel Professional", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound Healer", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound Wash Antiseptic Saline With Benzalkonium Chloride", DoseTypes: [{ DoseType: "Aerosol, Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wound Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wow Ez Finishes Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wow Quick Kill Bac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wrinkle Solution Concentrating Essence Special Set", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Wsinc Disinfecting Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wu Yang Brand Pain Relieving Medicated", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Ww Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wyandotte Sanifect E3", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wyandotte Sanifect E3-Na", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wyandotte Sanifect Foam-E Ii", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wyethia Helenioides", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Wymzya Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Wynn Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Wynzora", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X Bright Pro Sanitized Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X Folate", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X Out Cleansing Body", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X Out Cleansing Body Bar", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X Out Daily Body Scrub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X Out Shine Control", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X Out Spot Corrector", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X Ray", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "X-Folate", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X-Out Acne Control Body Pads", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X-Out Wash-In Treatment", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "X-Pur Opti-Rinse Plus", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Dental" }] }] },
    { Medication: "X-Ray", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "X-Treme Freeze", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "X3 Clean",
        DoseTypes: [
            { DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "X3 Clean Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xaciato", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Xadago", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xalatan", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Xalix", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xalkori", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Xanax",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Xanitize", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xanthium Strumarium Var Canadense Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intradermal" }, { Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Xanthoxylum Fraxineum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xaracoll", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Parenteral" }] }] },
    {
        Medication: "Xarelto",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Xatmep", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Xcopri",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Xeepur Indoor Safe Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xeglyze", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Xeljanz",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Xeloda", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xelpros", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic; Topical" }] }] },
    { Medication: "Xembify", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Xenical", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Xenleta",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Xenon", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Xenon, Xe-133", DoseTypes: [{ DoseType: "Gas", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Xeomin", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Xepi", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xepi Gel 70E Hand Sanitizing Handrub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xepi Rub Et Hand Sanitizing Handrub", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xerac Ac", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xerava", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Xerbex Eczema Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xerese", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xermelo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xeroburn Burn Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Xerostom",
        DoseTypes: [
            { DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] },
            { DoseType: "Paste", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Xerox Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xgeva", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Xhance", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Xiaflex", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Xifaxan", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xigduo", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xiidra", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ximino", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xipere", DoseTypes: [{ DoseType: "Injection, Suspension", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Xl-3 Day Time", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xl-3 Xtreme", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xlear Cough Drops (Black Cherry)", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xlear Cough Drops (Green Apple)", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xlear Cough Drops (Honey Lemon)", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xlear Nasal Decongestant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Xlentskin", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xofigo", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Xofluza",
        DoseTypes: [
            { DoseType: "Granule, For Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Xolair", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Xolair Pfs", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Xolegel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xolido", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Xopenex",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] },
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Respiratory (Inhalation)" }] },
        ],
    },
    { Medication: "Xopenex Hfa", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xospata", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xoxo Disinfectant", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xoxo Hand Disinfectant Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xpect Acetaminophen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Antacid", DoseTypes: [{ DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Antiseptic Wipes", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xpect Buffered Eyewash Sterile Isotonic", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Xpect Burn Care", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xpect Cherry Menthol Cough Drop", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Cold Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Cough Relief", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Decongestant", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Honey Lemon Cough Drops", DoseTypes: [{ DoseType: "Lozenge", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Hydrocortisone", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xpect Ibuprofen", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Isopropyl Alcohol", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xpect Pain Away", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Sinus Relief", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpect Sting Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xpecto", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpovio", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xpro Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xray Dol", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xrylix", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Xtampza", DoseTypes: [{ DoseType: "Capsule, Extended Release", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Xtandi",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Xtracare 2 In 1 Hair Cleanse And Condition", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Anti-Dandruff Conditioner", DoseTypes: [{ DoseType: "Lotion/Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Anti-Dandruff Hair Cleanse", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Antibacterial Hand Cleanse", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Xtracare Creamy Petroleum Jelly",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Jelly", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Xtracare Cucumber Melon", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Dandruff Hair Wash", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Deep Cleaning Astringent", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Dry Fresh Invisible Solid", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Xtracare Foam Antibacterial Hand Wash",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Xtracare Foaming Facial Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Ice Cold Topical Analgesic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Instand Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Invisible Solid Lady Stick Powder Fresh", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Lavender Chamomile", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Oatmeal Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Skin Relief Oatmeal Daily Moisturizing", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Sweet Pea Blossom", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Vaporizing Chest Rub", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Warm Vanilla", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare Wet Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtracare With Cooling Action Oatmeal Skin Relief Calmin", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtraguard Antibacterial Hand Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtreme Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtreme Kleen Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtreme Kleen Hand Sanitizer 80", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtreme Kleen Moisturizing Advanced Gel Hand Sanitizer Fragrance", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtreme Kleen Moisturizing Advanced Hand Sanitizer Unscented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtreme Personal Care Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xtreme Power Stick Ignition", DoseTypes: [{ DoseType: "Stick", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xulane", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Xultophy 100/3.6", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Xuriden", DoseTypes: [{ DoseType: "Granule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xxl For Men", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xycos Pink Collagen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xycos Pink Collagen Serum", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xycos Pink Collagen Toner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xycos Pink Collagencream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Xylocain (Lidocaine Hcl )", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Infiltration; Perineural" }] }] },
    { Medication: "Xylocaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }, { Route: "Infiltration" }, { Route: "Infiltration; Perineural" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Xylocaine(R) -Mpf (Lidocaine Hci And Epinephrine)", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] }] },
    { Medication: "Xylocaine-Mpf With Epi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Epidural; Infiltration; Intracaudal; Perineural" }] }] },
    { Medication: "Xyntha", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Xyosted", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Xyralid Hemorrhoidal", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Rectal" }] }] },
    { Medication: "Xyrem", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xywav", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Xyzal Allergy 24Hr", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Yaamava Antibacterial", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yaamava Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yaamava Antibacterial Wet Wipe", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yanbal Base Liquida Hidratante Moisturizing Foundation Sunscreen Broad Spectrum Spf 15 Tono 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yanbal Base Liquida Hidratante Moisturizing Foundation Sunscreen Broad Spectrum Spf 15 Tono 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yanbal Base Liquida Hidratante Moisturizing Foundation Sunscreen Broad Spectrum Spf 15 Tono 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yanbal Base Liquida Matificante Mattifying Foundation Sunscreen Broad Spectrum Spf 15 Tono 1", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yanbal Base Liquida Matificante Mattifying Foundation Sunscreen Broad Spectrum Spf 15 Tono 2", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yanbal Base Liquida Matificante Mattifying Foundation Sunscreen Broad Spectrum Spf 15 Tono 3", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yanbal Sentiva Extracto Divino Youth Renewal Moisturizing Spf 20", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yasameen Paradise Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yasmin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Yat Tip Tor", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yat Tip Xiao", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yaya Organics Spf-50 Baby Kids Mineral Sunscreen", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yaya Organics Spf-50 Sports Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yaz", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Yeast Gard Advanced",
        DoseTypes: [
            { DoseType: "Douche", Routes: [{ Route: "Vaginal" }] },
            { DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] },
        ],
    },
    { Medication: "Yeast Gard Homeopathic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Yeastaway", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Yeastcalm", DoseTypes: [{ DoseType: "Suppository", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Yellow Curly Dock", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Yellow Hornet Hymenoptera Venom Multidose", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Yellow Hornet Hymenoptera Venom Venomil Diagnostic", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intradermal; Percutaneous" }] }] },
    { Medication: "Yellow Hornet Hymenoptera Venom Venomil Maintenance", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Yellow Hornet Venom Protein", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Yellow Jacket Hymenoptera Venom", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Yellow Jacket Hymenoptera Venom Multidose", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Percutaneous; Subcutaneous" }] }] },
    { Medication: "Yellow Jacket Hymenoptera Venom Venomil Diagnostic", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intradermal; Percutaneous" }] }] },
    { Medication: "Yellow Jacket Hymenoptera Venom Venomil Maintenance", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Yellow Jacket Venom Protein", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Yellow Pine Pollen",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Cutaneous; Intradermal; Subcutaneous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] },
        ],
    },
    { Medication: "Yellow Summer Squash", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Intradermal; Percutaneous; Subcutaneous" }] }] },
    { Medication: "Yenaya Secret Gung Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yensa Bc Foundation", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yeodi", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yervoy", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Yes Grapefruit Daily Mineral Broad Spectrum Spf-15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Avocado Fragrance-Free Daily Mineral Broad Spectrum Spf-15", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Coconut", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes 3-Step Acne Clearing", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Yes To Tomatoes Acne Fighting Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Acne Fighting Paper Mask", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Detoxifying Charcoal Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Detoxifying Charcoal Mud Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Detoxifying Charcoal Sleeping Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Detoxifying Daily Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Detoxifying Mud Mask", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Detoxifying Overnight Moisturizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Detoxifying Spot Treatment", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Fragrance-Free Clarifying Mineral Broad Spectrum Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Fragrance-Free Daily Clarifying Cleanser", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Micellar Cleansing Water", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yes To Tomatoes Two-Step Nose", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Yescarta", DoseTypes: [{ DoseType: "Suspension", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Yesul Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yf-Vax", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Yiganerjing Suifursoap", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yiganerjing Wash Free Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Extracorporeal" }] }] },
    { Medication: "Yin-Yang Balance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Yinchiao", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ymlabs",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ynot", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ynot Hand Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ynot Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yoma Ampoule Plus Aloe Mask Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yoma Ampoule Plus Collagen Mask Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yoma Ampoule Plus Ginseng Mask Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yoma Ampoule Plus Snail Mask Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yoma Ampoule Plus White Peal Mask Pack", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yondelis", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Yonis Bliss", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yonsa", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Yoology Alcohol-Free Hand Sanitizer", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yorun Germicidal Disposable Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yorun Hand Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yorun Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yosprala", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "You Are A Magical Unicorn Antibacterial Hand Sanitizer Berry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Are A Magical Unicorn Antibacterial Hand Sanitizer Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Are A Magical Unicorn Antibacterial Hand Sanitizer Strawberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Are A Magical Unicorn Antibacterial Hand Sanitizer Watermelon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Are My Sunshine Antibacterial Hand Sanitizer Blueberry Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Are My Sunshine Antibacterial Hand Sanitizer Cotton Candy Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Are My Sunshine Antibacterial Hand Sanitizer Watermelon Scented", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Are My Sunshine Antibacterial Hand Wash Watermelon Scented", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Are The Best Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Got This Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Plus Relief Pain Relieving", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Relief Muscle And Joint Rub", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You Think Im Fuckin Around Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "You-V Spf-30 Broad Spectrum Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yougreen F", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Young Adult Serene", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Young Again", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Young And Clear - Mineral Moisturizer Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Young And Clear Mineral Moisturizer Broad Spectrum Spf30 Sunscreen", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Younggaksan", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Your Name Cosmetics Brightening Cc Cream Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Your Name Cosmetics Mineral Sheet Tint Broad Spectrum Spf 20", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Your Skin But Better", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Your Skin But Better Cc Color Correcting Full Coverage Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Your Skin But Better Cc Illumination", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Yourgoodskin",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Yourgoodskin Spf 30 Anti-Oxidant Day Sunscreen Broad Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth 1 Activating Bb Cream Spf 30 Light", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth 2 Activating Bb Cream Spf 30 Light Medium", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth 3 Activating Bb Cream Spf 30 Medium", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth 4 Activating Bb Cream Spf 30 Dark", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth Activating Bb Spf30 Shade 3", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth Age Defense Mineral Moisturizer Spf 30", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth Corridor Soothe And Defend Spf-30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth Dew Roll-On Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youth Protection Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Youthful Skin", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ysnore", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Yu Long You Pain Relieving", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yun Xiang Jing", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Percutaneous; Transdermal" }] }] },
    { Medication: "Yuni Beauty Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yuniku Antiseptic Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yupelri", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Respiratory (Inhalation)" }] }] },
    { Medication: "Yutiq", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Intraocular" }] }] },
    { Medication: "Yuvafem", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Vaginal" }] }] },
    { Medication: "Yves Saint Laurent All Hours Foundation Broad Spectrum Spf 20 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent All Hours Primer Broad Spectrum Spf 18 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Forever Light Creator Cc Primer Color Correcting Primer Broad Spectrum Spf 35 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Forever Youth Liberator Broad Spectrum Spf 15 Sunscreen Fluid", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Forever Youth Liberator Sunscreen Broad Spectrum Spf 15 Antiwrinkle Lift Plump Radiance", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Le Teint Encre De Peau Fusion Ink Foundation Broad Spectrum Spf 18 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Le Teint Touche Eclat Illuminating Foundation Broad Spectrum Spf 19 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Top Secrets All In One Bb", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Top Secrets Cc Primer Color Correcting Primer Broad Spectrum Spf 35 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Touche Eclat All In One Glow Instant Complexion Enhancer Broad Spectrum Spf 23 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Touche Eclat Le Teint Radiance Awakening Foundation Broad Spectrum Spf 22 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Yves Saint Laurent Youth Liberator Serum Foundation", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Z-Bum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Z-Guard", DoseTypes: [{ DoseType: "Paste", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Z-Sleep", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zaditor", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Zafemy", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Transdermal" }] }] },
    {
        Medication: "Zafirlukast",
        DoseTypes: [
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zaldyon", DoseTypes: [{ DoseType: "Tablet, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zaleplon", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zaltrap", DoseTypes: [{ DoseType: "Solution, Concentrate", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Zanaflex",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zang Hong Hua Pain Relieving", DoseTypes: [{ DoseType: "Oil", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zanosar", DoseTypes: [{ DoseType: "Powder, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Zantac 150",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zantac 360", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zantac 75", DoseTypes: [{ DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zantanol Tab.", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zany Antiseptic Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zap Apf", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Zap Lice Killing", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zap Neutral", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Zapotol Bacitracin", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zapzyt Acne", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zapzyt Acne Treatment Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zapzyt Acne Wash Cleanser", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zarah", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Zarontin",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zarxio", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous; Subcutaneous" }] }] },
    { Medication: "Zatural Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zavesca", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zaxs Aches And Pain", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zaz Antiseptic Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zcaine Fast Acting Anesthetic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zcort",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zea Mays Pollen", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Percutaneous" }, { Route: "Subcutaneous" }] }] },
    { Medication: "Zeasorb", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zebutal", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zedoary Oils Feminine Antibacterial Gels", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee Antiseptic Wipes", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee Aspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zee Blood Clotting Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee Burn Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee First Aid Antiseptic", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee First Aid Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee Hydrocream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee Hydrogen Peroxide", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee Ibutab", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zee Insect Sting Relief Wipes", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee Medical Clean Wipes", DoseTypes: [{ DoseType: "Sponge", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zee Pain Aid", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zee Painaid Back Relief Formula", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zee Unaspirin", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zeel",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intra-Articular; Intradermal; Intramuscular; Intravenous; Subcutaneous" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zegalogue", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Zegerid",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zegerid Otc", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zehn-X Antiseptic Sanitizing Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zejula", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zelapar", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zelboraf", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zellulisan", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Cutaneous" }, { Route: "Topical" }] }] },
    { Medication: "Zemaira", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Zembrace Symtouch", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Zemdri (Plazomicin)", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Zemplar",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Zen Global Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zen Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zen Hemp Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zen Sports Balm", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zenatane", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zenchent Fe", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zenol Cool",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Poultice", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    {
        Medication: "Zenol Mild Hot",
        DoseTypes: [
            { DoseType: "Patch", Routes: [{ Route: "Transdermal" }] },
            { DoseType: "Poultice", Routes: [{ Route: "Transdermal" }] },
        ],
    },
    { Medication: "Zenotri Follicle Shampoo.", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zenotri Scalp Essence", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zenotri Scalp Shampoo.", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zenpep", DoseTypes: [{ DoseType: "Capsule, Delayed Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zensa", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zensezone Arthmed Temporary Pain Relief Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zentrip", DoseTypes: [{ DoseType: "Film, Soluble", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zentrip Motion Sickness", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zenzedi", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zep Acclaim Ab", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Alcohol Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Antibacterial Hs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Applaud Ab", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Blue Sky Ab", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep E-2 Foaming Sanitizing Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep E-2 Hand Sanitizing Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Foam San", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Fs Antimicrobial Foaming Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Fs Antimicrobial Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Fuzion Antimicrobial Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Fuzion Fs Antimicrobial Foaming Hand Cleaner", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Fuzion Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Handstand Antimicrobial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Handstand Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Instant Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Instant Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Mango Ab", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Mild Antibacterial", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Non Alcohol Foam Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Pear Ab Hs", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Professional Mild Ab", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Round One Ab", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zep Tranq Mdw Ab Foam", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zepatier", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zephrex D", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zepol Topical Analgesic", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zeposia",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Kit", Routes: [{ Route: "" }] },
        ],
    },
    { Medication: "Zepzelca", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Zerbaxa", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Zermat Antiperspirant Deodorant Roll-On For Men", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zermat Antiperspirant Deodorant Roll-On For Women", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zermat Care Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zermat Kiwi Antiperspirant Deodorant Roll-On Sensitive Skin", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zermat Kiwi With Fragrance", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zermat Skin Platinum Series Cellular Beauty Caviar And Collagen Day Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zermat Skin Spf 50 Broad Spectrum", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zermat Xtreme Roll-On Antiperspirant Deodorant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zernel Scalp Rejuver Essence", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zero Sweat", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zerosweat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zerosweat With Aloe", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zerviate", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Zest Aqua Antibacterial Hand", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zestoretic", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zestril", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zestymint Antiseptic", DoseTypes: [{ DoseType: "Mouthwash", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zeta Clear Antifungal", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zetia", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zetonna", DoseTypes: [{ DoseType: "Aerosol, Metered", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Zetox Antibacterial Wet Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zevalin", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Zeyco Antiseptic Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zeyco Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zh Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zhangguang 101 Hair Follicle Tonic", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zhangguang 101 Hair Max 101B", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zheng Gu Shui", DoseTypes: [{ DoseType: "Liniment", Routes: [{ Route: "Percutaneous; Transdermal" }] }] },
    { Medication: "Zhong Hua Feng Shi Die Da Gao External Analgesic Plaster", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zhuang Gu Feng Shi Pain Relieving Plaster", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zhuanggu Gao Pain Relieving", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Zhuifeng Gao Pain Relieving", DoseTypes: [{ DoseType: "Plaster", Routes: [{ Route: "Transdermal" }] }] },
    { Medication: "Zhushi 75% Alcohol Disinfectant", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zhushi Disposable Alcohol Disinfectant Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zhushi Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ziac", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Ziagen",
        DoseTypes: [
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ziana", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zicam",
        DoseTypes: [
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zicam Allergy Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    {
        Medication: "Zicam Cold Remedy",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Lozenge", Routes: [{ Route: "Oral" }] },
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
        ],
    },
    { Medication: "Zicam Cold Remedy Medicated Fruit Drops", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zicam Cold Remedy Medicated Fruit Drops - Assorted Fruit", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zicam Cold Remedy Medicated Fruit Drops - Elderberry", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zicam Cold Remedy Medicated Fruit Drops - Manuka Honey", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zicam Cold Remedy Medicated Fruit Drops - Manuka Honey Lemon", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zicam Cold Remedy Medicated Fruit Drops - Ultimate Orange", DoseTypes: [{ DoseType: "Bar, Chewable", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zicam Cold Remedy Oral Mist", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zicam Cold Remedy Rapidmelts Elderberry Citrus", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zicam Extreme Congestion Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Zicam Intense Sinus Relief", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Nasal" }] }] },
    { Medication: "Zicam Ultra Cold Remedy Rapidmelts", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ziclopro", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Zidovudine",
        DoseTypes: [
            { DoseType: "Capsule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Syrup", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ziextenzo", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Ziks Arthritis Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zilacaine Patch", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Zilactin", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zilactin-B", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zileuton",
        DoseTypes: [
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Multilayer, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Ziloval 5%", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zilretta", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Intra-Articular" }] }] },
    { Medication: "Zilxi", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zimhi", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intramuscular; Subcutaneous" }] }] },
    {
        Medication: "Zims Max Freeze",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Patch", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Zims Max Heat", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zinc",
        DoseTypes: [
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zinc Chloride", DoseTypes: [{ DoseType: "Injection", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Zinc Cold Remedy", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zinc Cold Therapy",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zinc Five-O", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinc Five-O Zinxation Baby Mineral Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinc Five-O Zinxation Kids Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinc Met", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zinc Oxide",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Paste", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray, Suspension", Routes: [{ Route: "Topical" }] },
            { DoseType: "Stick", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Zinc Oxide 20%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinc Oxide 40%", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinc Oxide Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zinc Pyrithione",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Shampoo", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Zinc Screen Broad Spectrum Sunscreen Spf 40", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinc Sulfate", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    {
        Medication: "Zinc Therapy",
        DoseTypes: [
            { DoseType: "Liquid", Routes: [{ Route: "Topical" }] },
            { DoseType: "Soap", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Zinc-Oxyde Plus",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Zincum Metallicum",
        DoseTypes: [
            { DoseType: "Granule", Routes: [{ Route: "Oral" }] },
            { DoseType: "Pellet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zincum Metallicum Kit Refill", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zincum Sulphuricum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zincum Valerianicum", DoseTypes: [{ DoseType: "Pellet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zingo", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Intradermal" }] }] },
    { Medication: "Zinka Clear Zinc Oxide Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinka Spf 30 Clear Sunscreen", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinplava", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Zintrexyl-C", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zinxation Spf 47", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zinxation Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zioptan", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Ziprasidone", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ziprasidone Hydrochloride", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Ziprasidone Mesylate", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intramuscular" }] }] },
    { Medication: "Zipsor", DoseTypes: [{ DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zirabev", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Zirgan", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Zitfree", DoseTypes: [{ DoseType: "Ointment", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zithromax",
        DoseTypes: [
            { DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Powder, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zitsticka Megashade Sunscreen Serum (Spf-50)", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ziva Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ziva Antibacterial Multipurpose Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ziva Baby Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Ziva Hand Sanitizer",
        DoseTypes: [
            { DoseType: "Gel", Routes: [{ Route: "Topical" }] },
            { DoseType: "Spray", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Ziva Perfumed Wipes Bakhoor", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ziva Perfumed Wipes Gardenia", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ziva Water Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Acne Control Benzoyl Peroxide", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Astringent Solution", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Broad-Spectrum Sunscreen Spf 50", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Oil Control Pads Acne Treatment", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zo Skin Health Pigment Control Creme Hydroquinone",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    {
        Medication: "Zo Skin Health Pigment Control Plus Blending Creme Hydroquinone",
        DoseTypes: [
            { DoseType: "Emulsion", Routes: [{ Route: "Topical" }] },
            { DoseType: "Lotion", Routes: [{ Route: "Topical" }] },
        ],
    },
    { Medication: "Zo Skin Health Pigment Control Plus Brightening Creme Hydroquinone Plus Vitamin C", DoseTypes: [{ DoseType: "Emulsion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Pigment Control Program Plus Hydroquinone", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Zo Skin Health Smart Tone Broad-Spectrum Sunscreen Spf 50", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Sulfur Masque", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Sunscreen Plus Powder Broad-Spectrum Spf 45", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Sunscreen Plus Powder Broad-Spectrum Spf 45 (Light)", DoseTypes: [{ DoseType: "Powder", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zo Skin Health Sunscreen Plus Primer Broad-Spectrum Spf 30", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zocor", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zoey Naturals Diaper Cream", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoey Naturals English Garden Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoey Naturals Island Hibiscus Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoey Naturals Lemon Squeeze Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoey Naturals Unscented Broad Spectrum Spf 30 Sunscreen", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zogics Alcohol Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zogics Instant Hand Sanitizer Hydrating Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zokinvy", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zoladex", DoseTypes: [{ DoseType: "Implant", Routes: [{ Route: "Subcutaneous" }] }] },
    {
        Medication: "Zoledronic Acid",
        DoseTypes: [
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution, Concentrate", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Solution", Routes: [{ Route: "Intravenous" }] },
        ],
    },
    { Medication: "Zolgensma", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Zolidyne", DoseTypes: [{ DoseType: "Shampoo", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zolinza", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zolmiptriptan", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zolmitriptan",
        DoseTypes: [
            { DoseType: "Spray", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zolmitriptan Od", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zoloft",
        DoseTypes: [
            { DoseType: "Solution, Concentrate", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zolpak", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zolpidem", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zolpidem Tartrate",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }, { Route: "Sublingual" }] },
            { DoseType: "Tablet, Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Extended Release", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zolpidem Tartrate Sublingual", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Zolpimist", DoseTypes: [{ DoseType: "Spray, Metered", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zomacton", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }, { Route: "Subcutaneous" }] }] },
    {
        Medication: "Zomig",
        DoseTypes: [
            { DoseType: "Spray, Metered", Routes: [{ Route: "Nasal" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zomig Zmt", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zonalon", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zone 1", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zone 2", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zone Defense Foaming Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zone Defense Hand And Surface Sanitizing Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zone Defense Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zonegran", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zonisamide", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zonsen Alcohol Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zonsen Bzk Antiseptic", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zonsen Povidone-Iodine Prep Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zonsen Sting Relief Pad", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zontivity", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zooby", DoseTypes: [{ DoseType: "Aerosol, Foam", Routes: [{ Route: "Dental" }] }] },
    { Medication: "Zoom Wipes", DoseTypes: [{ DoseType: "Swab", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoono Alcohol Free Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoono Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoono Ultra Hand Sanitizer", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoono Wound Cleanser", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoosh Antiseptic Hand Sanitizer Gel, 80% Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoosh Kakadu Plum Scented Antiseptic Hand Sanitizer", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zoosh Lemon Scented Antiseptic Hand Sanitizer Gel, 80% Ethyl Alcohol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zorari Waterless Disinfection", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zorbtive", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Zorcaine", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Zortress", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zorvolex", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zostavax", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Suspension", Routes: [{ Route: "Subcutaneous" }] }] },
    { Medication: "Zostrix High Potency Foot Pain Relief", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zostrix Hp", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zostrix Original Strength", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zosyn", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Zovacil 5", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zovia 1/35", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    {
        Medication: "Zovirax",
        DoseTypes: [
            { DoseType: "Cream", Routes: [{ Route: "Topical" }] },
            { DoseType: "Ointment", Routes: [{ Route: "Topical" }] },
            { DoseType: "Suspension", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zoya Hand Sanitizer", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Ztlido", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zubsolv", DoseTypes: [{ DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Sublingual" }] }] },
    { Medication: "Zudaifu Antibacterial Cream", DoseTypes: [{ DoseType: "Salve", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zudz Hand Sanitizer", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zuklean Advance 62 Ethyl Alcohol Hand Sanitizer Gel", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zulresso", DoseTypes: [{ DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Zumandimine", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "" }] }] },
    { Medication: "Zuplenz", DoseTypes: [{ DoseType: "Film", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zurflex", DoseTypes: [{ DoseType: "Spray", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zyclara", DoseTypes: [{ DoseType: "Cream", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zydelig", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zyflo", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zykadia", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zylast Antiseptic", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zylast Antiseptic K", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zylast Antiseptic S", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zylastxp Antiseptic", DoseTypes: [{ DoseType: "Lotion", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zylastxp Antiseptic Foaming", DoseTypes: [{ DoseType: "Soap", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zylet", DoseTypes: [{ DoseType: "Suspension/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Zyloprim", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zylotrol", DoseTypes: [{ DoseType: "Gel", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zylotrol Maximum Pain Patch", DoseTypes: [{ DoseType: "Patch", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zylotrol Plus", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zylotrol-L", DoseTypes: [{ DoseType: "Kit", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zymaderm", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zymaderm For Cold Sores", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zymaderm For Shingles", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zymaderm For Warts", DoseTypes: [{ DoseType: "Liquid", Routes: [{ Route: "Topical" }] }] },
    { Medication: "Zymaxid", DoseTypes: [{ DoseType: "Solution/ Drops", Routes: [{ Route: "Ophthalmic" }] }] },
    { Medication: "Zyncof", DoseTypes: [{ DoseType: "Syrup", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zynlonta", DoseTypes: [{ DoseType: "Injection, Powder, Lyophilized, For Solution", Routes: [{ Route: "Intravenous" }] }] },
    { Medication: "Zynrelef", DoseTypes: [{ DoseType: "Solution", Routes: [{ Route: "Infiltration" }] }] },
    { Medication: "Zypitamag", DoseTypes: [{ DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zyprexa",
        DoseTypes: [
            { DoseType: "Injection, Powder, For Solution", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Kit", Routes: [{ Route: "Intramuscular" }] },
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Zyrtec",
        DoseTypes: [
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Chewable", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zyrtec 1S Blister", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zyrtec 2S Blister", DoseTypes: [{ DoseType: "Tablet", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zyrtec Allergy",
        DoseTypes: [
            { DoseType: "Kit", Routes: [{ Route: "" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Orally Disintegrating", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zyrtec-D", DoseTypes: [{ DoseType: "Tablet, Film Coated, Extended Release", Routes: [{ Route: "Oral" }] }] },
    { Medication: "Zytec Hand Sanitizer Wipes", DoseTypes: [{ DoseType: "Cloth", Routes: [{ Route: "Topical" }] }] },
    {
        Medication: "Zytiga",
        DoseTypes: [
            { DoseType: "Tablet", Routes: [{ Route: "Oral" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zyvana", DoseTypes: [{ DoseType: "Capsule", Routes: [{ Route: "Oral" }] }] },
    {
        Medication: "Zyvox",
        DoseTypes: [
            { DoseType: "Granule, For Suspension", Routes: [{ Route: "Oral" }] },
            { DoseType: "Injection", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Injection, Solution", Routes: [{ Route: "Intravenous" }] },
            { DoseType: "Tablet, Film Coated", Routes: [{ Route: "Oral" }] },
        ],
    },
    {
        Medication: "Zzzquil",
        DoseTypes: [
            { DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] },
            { DoseType: "Capsule, Liquid Filled", Routes: [{ Route: "Oral" }] },
            { DoseType: "Liquid", Routes: [{ Route: "Oral" }] },
            { DoseType: "Solution", Routes: [{ Route: "Oral" }] },
        ],
    },
    { Medication: "Zzzquil Nightime Sleep-Aid", DoseTypes: [{ DoseType: "Capsule, Gelatin Coated", Routes: [{ Route: "Oral" }] }] },
];
