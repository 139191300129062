//css
import "./patientAssessments.css";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToggleSwitch } from "../../components/toogleSwitch/toogleSwitch";
import useQuery from "../../lib/hooks/useQuery";
import { isError } from "../../lib/isError";
import Moment from "react-moment";
import { AssessmentCard } from "./components/assessmentCard/assessmentCard";
import { PatientNotFound } from "./components/patientNotFound/patientNotFound";
import { SkilledNurseVisitNoteDialog } from "./components/skilledNurseVisitNoteDialog/skilledNurseVisitNoteDialog";
import { SkilledObservationAssessmentDialog } from "./components/skilledObservationAssessmentDialog/skilledObservationAssessmentDialog";
import { CardiopulmonaryDialog } from "./components/cardiopulmonaryDialog/cardiopulmonaryDialog";
import { NeuromuscularDialog } from "./components/neuromuscularDialog/neuromuscularDialog";
import { WoundCareDialog } from "./components/woundCareDialog/woundCareDialog";
import { GastrointestinalDialog } from "./components/gastrointestinalDialog/gastrointestinalDialog";
import { GenitourinaryDialog } from "./components/genitourinaryDialog/genitourinaryDialog";
import { MedicationsDialog } from "./components/medicationsDialog/medicationsDialog";
import { AssistiveDevicesDialog } from "./components/assistiveDevicesDialog/assistiveDevicesDialog";
import { DiabeticFocusExamDialog } from "./components/diabeticExamDialog/diabeticExamDialog";
import { InterventionsInstructionsDialog } from "./components/interventionsInstructionsDialog/interventionsInstructionsDialog";
import { ResponseTeachingDialog } from "./components/responseTeachingDialog/responseTeachingDialog";
import { NurseOrdersDialog } from "./components/nurseOrdersDialog/nurseOrdersDialog";
import { NurseReviewDialog } from "./components/nurseReviewDialog/nurseReviewDialog";
import { CommentsDialog } from "./components/commentsDialog/commentsDialog";
import { CaregiverClinicalOrientationDialog } from "./components/caregiverClinicalOrientationDialog/caregiverClinicalOrientationDialog";
import { getVisitById, completeVisit, getVisitSummaryForm } from "../../api/visitAPI";
import { Loading } from "../../components/loading/loading";
import { ErrorMessage } from "../../components/errorMessage/errorMessage";
import { CompleteAssessmentConfirmationDialog } from "./components/completeAssessmentConfirmationDialog/completeAssessmentConfirmationDialog";
import { get485Form } from "../../api/cpaAPI";
import moment from "moment";
import { DownloadButton } from "../../components/downloadButton/downloadButton";
import { NotApplicableConfirmationDialog } from "./components/notApplicableConfirmationDialog/notApplicableConfirmationDialog";
import { getAssistiveDevicesSectionData } from "./Lib/patientAssessmentsLib_AssistiveDevices";
import { getCardiopulmonarySectionData } from "./Lib/patientAssessmentsLib_Cardiopulmonary";
import { getNeuromuscularSectionData } from "./Lib/patientAssessmentsLib_Neuromuscular";
import { getWoundCareSectionData } from "./Lib/patientAssessmentsLib_WoundCare";
import { getGenitourinarySectionData } from "./Lib/patientAssessmentsLib_Genitourinary";
import { getMedicationsSectionData } from "./Lib/patientAssessmentsLib_Medications";
import { getGastrointestinalSectionData } from "./Lib/patientAssessmentsLib_Gastrointestinal";
import { getDiabeticExamSectionData } from "./Lib/patientAssessmentsLib_DiabeticExam";
import { getInterventionsInstructionsSectionData } from "./Lib/patientAssessmentsLib_InterventionsInstructions";
import { getResponseTeachingSectionData } from "./Lib/patientAssessmentsLib_ResponseTeaching";
import { getNurseOrdersSectionData } from "./Lib/patientAssessmentsLib_NurseOrders";
import { getNurseReviewSectionData } from "./Lib/patientAssessmentsLib_NurseReview";
import { getCommentsSectionData } from "./Lib/patientAssessmentsLib_Comments";
import { getSkilledNurseVisitNoteSectionData } from "./Lib/patientAssessmentsLib_SkilledNurseVisitNote";
import { getSkilledObservationSectionData } from "./Lib/patientAssessmentsLib_SkilledObservation";
import { PhysicalDialog } from "./components/physicalDialog/physicalDialog";
import { getPhysicalSectionData } from "./Lib/patientAssessmentsLib_Physical";
import { getCaregiverClinicalOrientationSectionData } from "./Lib/patientAssessmentsLib_CaregiverClinicalOrientation";
import { getBradenScaleSectionData } from "./Lib/patientAssessmentsLib_BradenScale";
import { BradenScaleDialog } from "./components/bradenScaleDialog/bradenScaleDialog";
import { SummaryDialog } from "./components/summaryDialog/summaryDialog";
import { getFallRiskSectionData } from "./Lib/patientAssessmentsLib_FallRisk";
import { getDepressionScreenSectionData } from "./Lib/patientAssessmentsLib_DepressionScreen";
import { FallRiskDialog } from "./components/fallRiskDialog/fallRiskDialog";
import { getHomeSafetySectionData } from "./Lib/patientAssessmentsLib_HomeSafety";
import { HomeSafetyDialog } from "./components/homeSafetyDialog/homeSafetyDialog";
import { DepressionScreenDialog } from "./components/depressionScreenDialog/depressionScreenDialog";
import { DownloadVisitSummaryButton } from "../../components/downloadVisitSummaryButton/downloadVisitSummaryButton";

export const PatientAssessments = () => {
    //hook to get data from query string
    let query = useQuery();

    //hok to navigate
    let navigate = useNavigate();

    //Patient data
    const [patient, setPatient] = useState(null);

    //Complete assessment button enabled
    const [enableCompleteAssessmentButton, setEnableCompleteAssessmentButton] = useState(false);

    //Show confirm complete assessment dialog
    const [showConfirmCompleteAssessmentDialog, setShowConfirmCompleteAssessmentDialog] = useState(false);

    //Show confirm not applicable assessment dialog
    const [showConfirmNotApplicableDialog, setShowConfirmNotApplicableDialog] = useState(false);

    //Flag if we should show the ConfirmNotApplicableDialog
    const [displayConfirmNotApplicableDialog, setDisplayConfirmNotApplicableDialog] = useState(true);

    //Change Status Function
    const [handleChangeStatusCard, setHandleChangeStatusCard] = useState(null);

    //card filter
    const [statusFilter, setStatusFilter] = useState("Pending");

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //Downloading form flag state
    const [downloading, setDownloading] = useState(false);
    const [downloadingSummary, setDownloadingSummary] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(false);

    //saving state
    const [saving, setSaving] = useState(false); // eslint-disable-line

    //assessments Dialogs
    const [showSkilledNurseVisitNoteDialog, setShowSkilledNurseVisitNoteDialog] = useState(false);
    const [showSkilledObservationAssessmentDialog, setShowSkilledObservationAssessmentDialog] = useState(false);
    const [showCardiopulmonaryDialog, setShowCardiopulmonaryDialog] = useState(false);
    const [showNeuromuscularDialog, setShowNeuromuscularDialog] = useState(false);
    const [showWoundCareDialog, setShowWoundCareDialog] = useState(false);
    const [showGastrointestinalDialog, setShowGastrointestinalDialog] = useState(false);
    const [showGenitourinaryDialog, setShowGenitourinaryDialog] = useState(false);
    const [showMedicationsDialog, setShowMedicationsDialog] = useState(false);
    const [showAssistiveDevicesDialog, setShowAssistiveDevicesDialog] = useState(false);
    const [showDiabeticExamDialog, setShowDiabeticExamDialog] = useState(false);
    const [showInterventionsInstructionsDialog, setShowInterventionsInstructionsDialog] = useState(false);
    const [showResponseTeachingDialog, setShowResponseTeachingDialog] = useState(false);
    const [showNurseOrdersDialog, setShowNurseOrdersDialog] = useState(false);
    const [showNurseReviewDialog, setShowNurseReviewDialog] = useState(false);
    const [showPhysicalAssessmentDialog, setShowPhysicalAssessmentDialog] = useState(false);
    const [showCaregiverClinicalOrientationDialog, setShowCaregiverClinicalOrientationDialog] = useState(false);
    const [showBradenScaleDialog, setShowBradenScaleDialog] = useState(false);
    const [showFallRiskDialog, setShowFallRiskDialog] = useState(false);
    const [showDepressionScreenDialog, setShowDepressionScreenDialog] = useState(false);
    const [showHomeSafetyDialog, setShowHomeSafetyDialog] = useState(false);
    const [showCommentsDialog, setShowCommentsDialog] = useState(false);
    const [showSummaryDialog, setShowSummaryDialog] = useState(false);

    const [assessmentCards, setAssessmentCards] = useState([]);

    //Use Effects ===========================================================

    useEffect(() => {
        const id = query.get("id");

        //Loading client Assessment from api
        setLoading(true);
        getVisitById(id)
            .then((data) => {
                const myLocalDate = new Date(data?.appointmentDate + "Z").toLocaleString();

                console.log("Visit Type", data);

                setPatient({
                    id: id,
                    cpaVisitId: data?.cpaVisitId,
                    status: data?.status,
                    assessmentType: data?.assessmentType,
                    assessmentHas485: data?.assessmentHas485,
                    worker: data?.worker,
                    patientId: data?.client?.id,
                    patient: data?.client?.fullName,
                    patientDOB: data?.client?.dateOfBirth,
                    patientSOC: data?.client?.startOfCare,
                    patientSpecialNotes: data?.client?.specialNotes,
                    patientCPAClientId: data?.client?.cpaClientId,
                    contactPhone: data?.client?.phone,
                    appointmentDate: myLocalDate,
                    completedDate: data?.completedDate,
                    address: data?.client?.address?.address + (data?.client?.address?.city ? ", " + data?.client?.address?.city : "") + (data?.client?.address?.state ? ", " + data?.client?.address?.state : ""),
                    client: data?.client,
                    skilledNurseVisitNoteSection: data?.nurseAssessment?.skilledNurseVisitNoteSection,
                    skilledObservationSection: data?.nurseAssessment?.skilledObservationSection,
                    cardiopulmonarySection: data?.nurseAssessment?.cardiopulmonarySection,
                    neuromuscularSection: data?.nurseAssessment?.neuromuscularSection,
                    woundCareSection: data?.nurseAssessment?.woundCareSection,
                    gastrointestinalSection: data?.nurseAssessment?.gastrointestinalSection,
                    genitourinarySection: data?.nurseAssessment?.genitourinarySection,
                    medicationsSection: data?.nurseAssessment?.medicationsSection,
                    assistiveDevicesSection: data?.nurseAssessment?.assistiveDevicesSection,
                    diabeticExamSection: data?.nurseAssessment?.diabeticExamSection,
                    interventionsInstructionsSection: data?.nurseAssessment?.interventionsInstructionsSection,
                    responseTeachingSection: data?.nurseAssessment?.responseTeachingSection,
                    nurseOrdersSection: data?.nurseAssessment?.nurseOrdersSection,
                    nurseReviewSection: data?.nurseAssessment?.nurseReviewSection,
                    physicalSection: data?.nurseAssessment?.physicalSection ?? { id: id, progress: "Pending" },
                    caregiverClinicalOrientationSection: data?.nurseAssessment?.caregiverClinicalOrientationSection ?? { id: id, progress: "Pending" },
                    bradenScaleSection: data?.nurseAssessment?.bradenScaleSection ?? { id: id, progress: "Pending" },
                    fallRiskSection: data?.nurseAssessment?.fallRiskSection ?? { id: id, progress: "Pending" },
                    depressionScreenSection: data?.nurseAssessment?.depressionScreenSection ?? { id: id, progress: "Pending" },
                    homeSafetySection: data?.nurseAssessment?.homeSafetySection ?? { id: id, progress: "Pending" },
                    commentsSection: data?.nurseAssessment?.commentsSection,
                });

                setLoading(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setLoading(false);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //Setting Cards
        const assessmentSections = [];
        const data = patient;

        if (data?.skilledNurseVisitNoteSection) {
            const sectionContent = getSkilledNurseVisitNoteSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowSkilledNurseVisitNoteDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.skilledObservationSection) {
            const sectionContent = getSkilledObservationSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowSkilledObservationAssessmentDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.cardiopulmonarySection) {
            const sectionContent = getCardiopulmonarySectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowCardiopulmonaryDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.neuromuscularSection) {
            const sectionContent = getNeuromuscularSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowNeuromuscularDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.woundCareSection) {
            const sectionContent = getWoundCareSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowWoundCareDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.gastrointestinalSection) {
            const sectionContent = getGastrointestinalSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowGastrointestinalDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.genitourinarySection) {
            const sectionContent = getGenitourinarySectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowGenitourinaryDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.medicationsSection) {
            const sectionContent = getMedicationsSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowMedicationsDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.assistiveDevicesSection) {
            const sectionContent = getAssistiveDevicesSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowAssistiveDevicesDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.diabeticExamSection) {
            const sectionContent = getDiabeticExamSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowDiabeticExamDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.interventionsInstructionsSection) {
            const sectionContent = getInterventionsInstructionsSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowInterventionsInstructionsDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.responseTeachingSection) {
            const sectionContent = getResponseTeachingSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowResponseTeachingDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.nurseOrdersSection) {
            const sectionContent = getNurseOrdersSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowNurseOrdersDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.nurseReviewSection) {
            const sectionContent = getNurseReviewSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowNurseReviewDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.physicalSection) {
            const sectionContent = getPhysicalSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowPhysicalAssessmentDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.caregiverClinicalOrientationSection) {
            const sectionContent = getCaregiverClinicalOrientationSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowCaregiverClinicalOrientationDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.bradenScaleSection) {
            const sectionContent = getBradenScaleSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowBradenScaleDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.fallRiskSection) {
            const sectionContent = getFallRiskSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowFallRiskDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.depressionScreenSection) {
            const sectionContent = getDepressionScreenSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowDepressionScreenDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.homeSafetySection) {
            const sectionContent = getHomeSafetySectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowHomeSafetyDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        if (data?.commentsSection) {
            const sectionContent = getCommentsSectionData(data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowCommentsDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard);
            assessmentSections.push(sectionContent);
        }

        //Setting cards...
        setAssessmentCards(assessmentSections);

        //Setting enableCompleteAssessmentButton
        setEnableCompleteAssessmentButton(
            patient?.status !== "Completed" &&
                patient?.skilledNurseVisitNoteSection?.progress === "Completed" &&
                patient?.skilledObservationSection?.progress === "Completed" &&
                patient?.cardiopulmonarySection?.progress === "Completed" &&
                patient?.neuromuscularSection?.progress === "Completed" &&
                patient?.woundCareSection?.progress === "Completed" &&
                patient?.gastrointestinalSection?.progress === "Completed" &&
                patient?.genitourinarySection?.progress === "Completed" &&
                patient?.medicationsSection?.progress === "Completed" &&
                patient?.assistiveDevicesSection?.progress === "Completed" &&
                patient?.diabeticExamSection?.progress === "Completed" &&
                patient?.interventionsInstructionsSection?.progress === "Completed" &&
                patient?.responseTeachingSection?.progress === "Completed" &&
                patient?.nurseReviewSection?.progress === "Completed" &&
                patient?.nurseOrdersSection?.progress === "Completed" &&
                patient?.physicalSection?.progress === "Completed" &&
                patient?.caregiverClinicalOrientationSection?.progress === "Completed" &&
                patient?.bradenScaleSection?.progress === "Completed" &&
                patient?.fallRiskSection?.progress === "Completed" &&
                patient?.depressionScreenSection?.progress === "Completed" &&
                patient?.homeSafetySection?.progress === "Completed" &&
                patient?.commentsSection?.progress === "Completed"
        );
    }, [patient]); // eslint-disable-line

    //=== Methods ============================================================

    //handle client click
    const handleClientNameClick = (id) => {
        navigate(`/clientProfile/${id}`);
    };

    const cardFilterValueChange = (e) => {
        const newCardFilter = e.value;
        setStatusFilter(newCardFilter);
    };

    const handleCompleteAssessmentClick = () => {
        setShowConfirmCompleteAssessmentDialog(true);
    };

    const handleDownloadFormClick = async () => {
        setDownloading(true);

        const assessments = [];

        //1. Medication
        const medication = {
            categoryIDF: 1,
            value: patient?.medicationsSection?.medications?.map((m) => {
                return m.name + (m.dose ? " - " + m.dose : "") + (m.frequency ? " - " + m.frequency : "") + (m.route ? " - " + m.route : "");
            }),
        };
        assessments.push(medication);

        //2. Nutritional Requirements
        const nutritionalRequirements = { categoryIDF: 2, value: [] };
        assessments.push(nutritionalRequirements);

        //3. Diagnosis
        const diagnosis = { categoryIDF: 3, value: [...(patient?.skilledNurseVisitNoteSection?.diagnoses ?? []), ...[patient?.skilledNurseVisitNoteSection?.reasonForNeedingHomecare ?? ""]]?.filter((d) => d !== "") };
        assessments.push(diagnosis);

        //4. Medical Equipment
        const medicalEquipment = { categoryIDF: 4, value: [...(patient?.assistiveDevicesSection?.devices ?? []), ...[patient?.assistiveDevicesSection?.deviceOtherDescription ?? ""]]?.filter((d) => d !== "Other" && d !== "") };
        assessments.push(medicalEquipment);

        //5. Safety Measures
        const safetyMeasures = { categoryIDF: 5, value: patient?.assistiveDevicesSection?.safety ?? [] };
        assessments.push(safetyMeasures);

        //6. Functional Limitations
        const functionalLimitations = { categoryIDF: 6, value: patient?.nurseOrdersSection?.functionalLimitations ?? [] };
        assessments.push(functionalLimitations);

        //7. Activities Permitted
        const activitiesPermitted = { categoryIDF: 7, value: [...(patient?.nurseOrdersSection?.activitiesPermitted ?? []), ...[patient?.nurseOrdersSection?.activitiesPermittedOther ?? ""]]?.filter((d) => d !== "Other" && d !== "") };
        assessments.push(activitiesPermitted);

        //8. Mental Status
        const mentalStatus = { categoryIDF: 8, value: patient?.skilledObservationSection?.mentalStatus ?? [] };
        assessments.push(mentalStatus);

        //9. Treatments
        console.log("nurseOrdersSection.nurseOrders", patient?.nurseOrdersSection?.nurseOrders);
        const treatments = {
            categoryIDF: 9,
            value: patient?.nurseOrdersSection?.nurseOrders?.map((m) => {
                const orders = [];

                if (m.rnSupervisoryVisit) orders.push("RN supervisory visit every two (2) months and PRN for RN clinical assessment and employee supervision. HHA to notify Nurse Supervisor with any change in condition.");
                if (m.hhaAssistClient) orders.push("HHA may assist client with the following ADL’s including, but not limited to: bathing, dressing, eating, toileting, transfers, medication reminders, ambulation, meal preparation, light housekeeping, shopping and accompanying to appointments.");
                if (m.rnInitialAssessmentLPNMedicationPRNMedChanges) orders.push("RN initial assessment with reassessment every 30 days for supervision of LPN. LPN medication pre pour every two (2) weeks and PRN for med changes x 60 days vital signs completed with each visit x 60 days.");
                if (m.rnInitialAssessmentPRNOversight) orders.push("RN initial assessment with reassessment every 30 days and PRN for oversight of Plan of care and supervision of LPN.");
                if (m.rnCompleteTaskMDOrders) orders.push(`RN and/or LPN to complete tasks per MD orders as follows: ${m.comments}`);

                return { skill: m.skill, daysForWeek: m.daysForWeek ?? "", hoursForDay: m.hoursForDay ?? "", durationPeriod: m.durationPeriod ?? "", durationValue: m.durationValue ?? "", orders: orders };
                //return m.skill + (m.daysForWeek ? " - " + m.daysForWeek + " Days/Week" : "") + (m.daysForWeek ? " - " + m.hoursForDay + " Hours/Day" : "") + (m.comments ? " - " + m.comments : "");
            }),
        };
        assessments.push(treatments);

        //10. Goals
        const goals = { categoryIDF: 10, value: [] };
        assessments.push(goals);

        //11. Discharge Plans
        const dischargePlans = { categoryIDF: 11, value: [] };
        assessments.push(dischargePlans);

        const data = { patientName: patient?.patient?.replace(/\s/g, ""), visitDate: moment(patient?.appointmentDate).format("YYYYMMDD"), completedDate: patient?.completedDate ? moment(patient?.completedDate).format("YYYYMMDD") : null, cpaVisitId: patient?.cpaVisitId ?? 0, assessmentID: patient?.id, prognosis: patient?.nurseOrdersSection?.prognosis, assessments: assessments };

        await get485Form(data)
            .then((ret) => {
                setDownloading(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setDownloading(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    const handleDownloadSummaryClick = async () => {
        setDownloadingSummary(true);

        const data = { id: patient.id, patientName: patient?.patient?.replace(/\s/g, "") }; //{ patientName: patient?.patient?.replace(/\s/g, ""), visitDate: moment(patient?.appointmentDate).format("YYYYMMDD"), cpaVisitId: patient?.cpaVisitId ?? 0, assessmentID: patient?.id, prognosis: patient?.nurseOrdersSection?.prognosis, assessments: assessments };

        await getVisitSummaryForm(data)
            .then((ret) => {})
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally((_) => {
                setDownloadingSummary(false);
            });
    };

    const completeAssessments = () => {
        setSaving(true);

        const id = query.get("id");

        const saveObj = { id: id };
        completeVisit(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "Completed" };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            })
            .finally(() => setSaving(false));
    };

    //=== Render ============================================================

    return (
        <section id="patientAssessments">
            {!patient && !loading && <PatientNotFound />}
            {patient && (
                <>
                    <div className="container-fluid mt-5 pt-4">
                        <div className="row mb-0 pb-0">
                            <div className="col-md-12 pb-0 mb-0">
                                <h4 className="pt-3 pb-0 mb-0 text-success ps-3 float-start">Client Assessments</h4>
                                <Link className="navLink text-success float-end pt-4 small" to="/">
                                    Return to Client Visits List
                                </Link>
                            </div>
                        </div>

                        <hr />

                        <div className="row ms-4 ps-4">
                            <div className="col-md-2">
                                <label className="text-muted fw-bolder">Client Name</label>
                                <span
                                    className="text-success d-block small"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        handleClientNameClick(patient?.patientId);
                                    }}
                                >
                                    <u>{patient?.patient}</u>
                                </span>
                            </div>

                            <div className="col-md-2">
                                <label className="text-muted fw-bolder">Appointment Date</label>
                                <span className="text-success d-block small">
                                    <i className="fa fa-calendar pe-2" aria-hidden="true"></i>
                                    <Moment format="MM/DD/YYYY hh:mm A">{patient?.appointmentDate}</Moment>
                                </span>
                            </div>

                            <div className="col-md-2">
                                <label className="text-muted fw-bolder">Assessment Status</label>
                                <span className="text-success d-block small">{patient?.status ?? "Not Started"}</span>
                            </div>

                            <div className="col-md-2">
                                <label className="text-muted fw-bolder">Assessment Type</label>
                                <span className="text-success d-block small">{patient?.assessmentType}</span>
                            </div>

                            <div className="col-md-2">
                                <label className="text-muted fw-bolder">Phone</label>
                                <span className="text-success d-block small">
                                    <i className="fa fa-phone pe-2" aria-hidden="true"></i>
                                    <a href={`tel:${patient?.contactPhone?.replace(/\D/g, "")}`}>{patient?.contactPhone}</a>
                                </span>
                            </div>

                            <div className="col-md-2">
                                <label className="text-muted fw-bolder">Address</label>
                                <span className="text-success d-block small">
                                    <i className="fa fa-map-marker pe-2" aria-hidden="true"></i>
                                    {patient?.address}
                                </span>
                            </div>
                        </div>

                        <div className="row ms-4 ps-4 mt-3">
                            <div className="col-md-12">
                                <label className="text-muted fw-bolder">Special Notes</label>
                                <span className="text-success d-block small">{patient?.patientSpecialNotes ?? "-"}</span>
                            </div>
                        </div>

                        <hr />

                        <section id="ClientAssessmentsCards">
                            <div className="container mt-4">
                                <div className="row">
                                    <div className="col-8 pe-3 pb-4 ps-4">
                                        <button className="btn btn-outline-success btn-sm" onClick={handleCompleteAssessmentClick} disabled={!enableCompleteAssessmentButton && !saving}>
                                            Complete Assessments
                                        </button>
                                        {patient?.assessmentHas485 && (
                                            <>
                                                &nbsp;&nbsp;
                                                <DownloadButton className="btn btn-outline-success btn-sm" handleDownload={handleDownloadFormClick} loading={downloading} disabled={patient?.status !== "Completed"}></DownloadButton>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-4 pe-3 pb-4 ps-4 text-end">
                                        {/*
                                        <button
                                            className="btn btn-outline-success btn-sm"
                                            onClick={() => {
                                                setShowSummaryDialog(true);
                                            }}
                                        >
                                            Summary
                                        </button>
                                        {/*
                                        <PrintAssessments data={patient} />
                                        */}

                                        <DownloadVisitSummaryButton className="btn btn-outline-success btn-sm" handleDownload={handleDownloadSummaryClick} loading={downloadingSummary}></DownloadVisitSummaryButton>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mt-0 ps-4">
                                        <ToggleSwitch values={["Pending", "All", "Completed"]} selected={statusFilter} name="cardFilter" onChange={cardFilterValueChange} />
                                    </div>
                                    <div className="col-6 col-md-8 col-lg-9 col-xl-9 col-xxl-10 ps-4 text-end">
                                        {loading && <Loading />}
                                        {operationError && <ErrorMessage msg={operationError} size="small" />}
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 ms-0 ps-0 mb-4">
                                    {assessmentCards.map((item, index) => {
                                        if (statusFilter === "All" || statusFilter === item?.progress) {
                                            return (
                                                <div className="col mt-3" key={index}>
                                                    <AssessmentCard index={index} iconClass={item?.iconClass} iconCompleted={item?.iconCompleted} iconPending={item?.iconPending} title={item?.title} description={item?.description} progress={item?.progress} enableStatusField={item?.enableStatusField} saving={item?.saving} cardClickHandler={item?.onClick} statusClickHandler={item?.statusClickHandler} checked={item?.status === "Not Applicable"} readOnly={patient?.status === "Completed"} />
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>

                    {statusFilter === "Completed" && assessmentCards.filter((c) => c.progress === statusFilter).length === 0 && (
                        <>
                            <div className="row mt-4 pt-4">
                                <div className="col-6 offset-3 text-center">
                                    <hr></hr>
                                    <h5>No Completed Assessments</h5>
                                    <hr></hr>
                                </div>
                            </div>
                        </>
                    )}

                    {statusFilter === "Pending" && assessmentCards.filter((c) => c.progress === statusFilter).length === 0 && (
                        <>
                            <div className="row mt-4 pt-4">
                                <div className="col-6 offset-3 text-center">
                                    <hr></hr>
                                    <h5>No Pending Assessments</h5>
                                    <hr></hr>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}

            {/* Modal components */}
            {console.log("patient", patient)}
            {showSkilledNurseVisitNoteDialog && <SkilledNurseVisitNoteDialog data={patient} setData={setPatient} closeDialog={() => setShowSkilledNurseVisitNoteDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showSkilledObservationAssessmentDialog && <SkilledObservationAssessmentDialog data={patient} setData={setPatient} closeDialog={() => setShowSkilledObservationAssessmentDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showCardiopulmonaryDialog && <CardiopulmonaryDialog data={patient} setData={setPatient} closeDialog={() => setShowCardiopulmonaryDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showNeuromuscularDialog && <NeuromuscularDialog data={patient} setData={setPatient} closeDialog={() => setShowNeuromuscularDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showWoundCareDialog && <WoundCareDialog data={patient} setData={setPatient} closeDialog={() => setShowWoundCareDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showGastrointestinalDialog && <GastrointestinalDialog data={patient} setData={setPatient} closeDialog={() => setShowGastrointestinalDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showGenitourinaryDialog && <GenitourinaryDialog data={patient} setData={setPatient} closeDialog={() => setShowGenitourinaryDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showMedicationsDialog && <MedicationsDialog data={patient} setData={setPatient} closeDialog={() => setShowMedicationsDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showAssistiveDevicesDialog && <AssistiveDevicesDialog data={patient} setData={setPatient} closeDialog={() => setShowAssistiveDevicesDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showDiabeticExamDialog && <DiabeticFocusExamDialog data={patient} setData={setPatient} closeDialog={() => setShowDiabeticExamDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showInterventionsInstructionsDialog && <InterventionsInstructionsDialog data={patient} setData={setPatient} closeDialog={() => setShowInterventionsInstructionsDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showResponseTeachingDialog && <ResponseTeachingDialog data={patient} setData={setPatient} closeDialog={() => setShowResponseTeachingDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showNurseReviewDialog && <NurseReviewDialog data={patient} setData={setPatient} closeDialog={() => setShowNurseReviewDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showNurseOrdersDialog && <NurseOrdersDialog data={patient} setData={setPatient} closeDialog={() => setShowNurseOrdersDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showPhysicalAssessmentDialog && <PhysicalDialog data={patient} setData={setPatient} closeDialog={() => setShowPhysicalAssessmentDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showBradenScaleDialog && <BradenScaleDialog data={patient} setData={setPatient} closeDialog={() => setShowBradenScaleDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showFallRiskDialog && <FallRiskDialog data={patient} setData={setPatient} closeDialog={() => setShowFallRiskDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showDepressionScreenDialog && <DepressionScreenDialog data={patient} setData={setPatient} closeDialog={() => setShowDepressionScreenDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showHomeSafetyDialog && <HomeSafetyDialog data={patient} setData={setPatient} closeDialog={() => setShowHomeSafetyDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showCommentsDialog && <CommentsDialog data={patient} setData={setPatient} closeDialog={() => setShowCommentsDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showCaregiverClinicalOrientationDialog && <CaregiverClinicalOrientationDialog data={patient} setData={setPatient} closeDialog={() => setShowCaregiverClinicalOrientationDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showConfirmCompleteAssessmentDialog && <CompleteAssessmentConfirmationDialog data={patient} handleConfirmClick={completeAssessments} closeDialog={() => setShowConfirmCompleteAssessmentDialog(false)} readOnly={patient?.status === "Completed"} />}
            {showConfirmNotApplicableDialog && <NotApplicableConfirmationDialog setDisplayDialog={setDisplayConfirmNotApplicableDialog} handleConfirmClick={handleChangeStatusCard} closeDialog={() => setShowConfirmNotApplicableDialog(false)} />}
            {showSummaryDialog && <SummaryDialog data={patient} closeDialog={() => setShowSummaryDialog(false)} />}
        </section>
    );
};
