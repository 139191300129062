//css
import "./gastrointestinalDialog.css";

//Icons
import icon from "../../../../assets/cloud-meatball-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { updateGastrointestinalSection } from "../../../../api/nurseAssessment/gastrointestinalAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const GastrointestinalDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //GastroIntestinal data state
    const [gastroIntestinalData, setGastroIntestinalData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of oral assessments
    const listOfOralAssessments = [
        { label: "No Concerns", value: "No Concerns" },
        { label: "Bleeding Gums", value: "Bleeding Gums" },
        { label: "Coated Tongue", value: "Coated Tongue" },
        { label: "Dry Mouth", value: "Dry Mouth" },
        { label: "Mouth Odor", value: "Mouth Odor" },
        { label: "Oral Lesions", value: "Oral Lesions" },
        { label: "Stomatitis", value: "Stomatitis" },
    ];

    //list of symptoms
    const listOfSymptoms = [
        { label: "Anorexia", value: "Anorexia" },
        { label: "Difficulty Swallowing", value: "Difficulty Swallowing" },
        { label: "GERD", value: "GERD" },
        { label: "Nausea", value: "Nausea" },
        { label: "Ulcer", value: "Ulcer" },
        { label: "Vomiting", value: "Vomiting" },
        { label: "Other", value: "Other" },
    ];

    //list Of Additional Products Used
    const listOfAdditionalProductsUsed = [
        { label: "Adhesive Seals", value: "Adhesive Seals" },
        { label: "Adhesive Remover Wipes", value: "Adhesive Remover Wipes" },
        { label: "Barrier Wafer", value: "Barrier Wafer" },
        { label: "Barrier Wipes", value: "Barrier Wipes" },
        { label: "Deodorizer", value: "Deodorizer" },
        { label: "Liquid Adhesive", value: "Liquid Adhesive" },
        { label: "Paste", value: "Paste" },
        { label: "Powder", value: "Powder" },
        { label: "Strip", value: "Strip" },
        { label: "Support Belt", value: "Support Belt" },
        { label: "Tape", value: "Tape" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.gastrointestinalSection, status: data?.gastrointestinalSection?.status ?? "Applicable" };
        setGastroIntestinalData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: gastroIntestinalData?.id, status: gastroIntestinalData?.status };
        setGastroIntestinalData(newData);
    };

    const handleSave = () => {
        setSaving(true);

        const saveObj = {
            ...gastroIntestinalData,
        };

        //===========================================================

        //console.log("saveObj", saveObj);

        //Saving GastrointestinalSection
        updateGastrointestinalSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", gastrointestinalSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...gastroIntestinalData, [name]: value };
        setGastroIntestinalData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...gastroIntestinalData, [name]: checked };
        setGastroIntestinalData(newData);
    };

    const handleRadioClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...gastroIntestinalData, [name]: value };
        setGastroIntestinalData(newData);
    };

    const handleOralAssessmentChange = (e) => {
        let oralAssessmentNew = e?.map((i) => i.value);
        const newData = { ...gastroIntestinalData, oralAssessment: oralAssessmentNew };
        setGastroIntestinalData(newData);
    };

    const handleAdditionalProductsUsedChange = (e) => {
        let additionalProductsUsedNew = e?.map((i) => i.value);
        const newData = { ...gastroIntestinalData, additionalProductsUsed: additionalProductsUsedNew };
        setGastroIntestinalData(newData);
    };

    const handleSymptomsChange = (e) => {
        let symptomsNew = e?.map((i) => i.value);
        const newData = { ...gastroIntestinalData, symptoms: symptomsNew };
        setGastroIntestinalData(newData);
    };

    return (
        <Modal dialogClassName="gastroIntestinalmodal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Gastrointestinal - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Oral Assessment</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {gastroIntestinalData?.status === "Applicable"
                                        ? gastroIntestinalData?.oralAssessment?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="oralAssessment"
                                        id="oralAssessment"
                                        menuPlacement="bottom"
                                        options={listOfOralAssessments}
                                        //value={listOfOralAssessments.value}
                                        //defaultValue={gastroIntestinalData?.oralAssessment?.map((med) => listOfOralAssessments?.find((i) => i.value === med))}
                                        value={(gastroIntestinalData?.oralAssessment ?? [])?.map((med) => listOfOralAssessments?.find((i) => i.value === med))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleOralAssessmentChange}
                                        isDisabled={!["Applicable"].includes(gastroIntestinalData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Abdominal Assessment</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.abdominalAssessment ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="abdominalAssessment" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.abdominalAssessment ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Asymmetrical">Asymmetrical</option>
                                        <option value="Distended">Distended</option>
                                        <option value="Symmetrical">Symmetrical</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 pt-2">
                            <input name="tubeFeeding" id="tubeFeeding" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.tubeFeeding ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="tubeFeeding">
                                Tube Feeding
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-1 pt-2">Describe</div>
                        <div className="col-5">
                            {readOnly && <div className="text-success pt-2 pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.tubeFeeding ? gastroIntestinalData?.tubeFeedingDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="tubeFeedingDescription" type="text" className="form-control" placeholder={`Description`} value={gastroIntestinalData?.tubeFeedingDescription ?? ""} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.tubeFeeding} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3 mt-2 mx-0 px-0">
                            <div className="form-check form-check-inline me-0 pe-0">
                                <input name="tubeFeedingType" className="form-check-input" type="radio" id="tubeFeedingTypeContinuous" value="Continuous" checked={gastroIntestinalData?.tubeFeedingType === "Continuous"} onClick={handleRadioClick} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.tubeFeeding || readOnly} />
                                <label className="form-check-label" htmlFor="tubeFeedingTypeContinuous">
                                    Continuous
                                </label>
                                &nbsp;
                            </div>
                            <div className="form-check form-check-inline me-0 pe-0">
                                <input name="tubeFeedingType" className="form-check-input" type="radio" id="tubeFeedingTypeIntermittent" value="Intermittent" checked={gastroIntestinalData?.tubeFeedingType === "Intermittent"} onClick={handleRadioClick} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.tubeFeeding || readOnly} />
                                <label className="form-check-label" htmlFor="tubeFeedingTypeIntermittent">
                                    Intermittent
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 pt-2">
                            <input name="auscultation" id="auscultation" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.auscultation ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="auscultation">
                                Auscultation
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 offset-1">
                            <span className="my-1 d-block">RLQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.auscultation ? gastroIntestinalData?.auscultationRLQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="auscultationRLQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.auscultationRLQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.auscultation}>
                                        <option value="">Select</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Active">Active</option>
                                        <option value="Hyperactive">Hyperactive</option>
                                        <option value="Hypoactive">Hypoactive</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">RUQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.auscultation ? gastroIntestinalData?.auscultationRUQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="auscultationRUQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.auscultationRUQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.auscultation}>
                                        <option value="">Select</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Active">Active</option>
                                        <option value="Hyperactive">Hyperactive</option>
                                        <option value="Hypoactive">Hypoactive</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">LLQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.auscultation ? gastroIntestinalData?.auscultationLLQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="auscultationLLQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.auscultationLLQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.auscultation}>
                                        <option value="">Select</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Active">Active</option>
                                        <option value="Hyperactive">Hyperactive</option>
                                        <option value="Hypoactive">Hypoactive</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">LUQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.auscultation ? gastroIntestinalData?.auscultationLUQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="auscultationLUQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.auscultationLUQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.auscultation}>
                                        <option value="">Select</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Active">Active</option>
                                        <option value="Hyperactive">Hyperactive</option>
                                        <option value="Hypoactive">Hypoactive</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 pt-2">
                            <input name="percussion" id="percussion" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.percussion ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="percussion">
                                Percussion
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 offset-1">
                            <span className="my-1 d-block">RLQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.percussion ? gastroIntestinalData?.percussionRLQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="percussionRLQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.percussionRLQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.percussion}>
                                        <option value="">Select</option>
                                        <option value="Dull">Dull</option>
                                        <option value="Tympanic">Tympanic</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">RUQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.percussion ? gastroIntestinalData?.percussionRUQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="percussionRUQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.percussionRUQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.percussion}>
                                        <option value="">Select</option>
                                        <option value="Dull">Dull</option>
                                        <option value="Tympanic">Tympanic</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">LLQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.percussion ? gastroIntestinalData?.percussionLLQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="percussionLLQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.percussionLLQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.percussion}>
                                        <option value="">Select</option>
                                        <option value="Dull">Dull</option>
                                        <option value="Tympanic">Tympanic</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">LUQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.percussion ? gastroIntestinalData?.percussionLUQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="percussionLUQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.percussionLUQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.percussion}>
                                        <option value="">Select</option>
                                        <option value="Dull">Dull</option>
                                        <option value="Tympanic">Tympanic</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 pt-2">
                            <input name="palpation" id="palpation" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.palpation ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="palpation">
                                Palpation
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 offset-1">
                            <span className="my-1 d-block">RLQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.palpation ? gastroIntestinalData?.palpationRLQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="palpationRLQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.palpationRLQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.palpation}>
                                        <option value="">Select</option>
                                        {/*
                                        <option value="Deep Palpation">Deep Palpation</option>
                                        <option value="Light Palpation">Light Palpation</option>
                                        */}
                                        <option value="Non-Tender">Non-Tender</option>
                                        <option value="Tender to touch">Tender to touch</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">RUQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.palpation ? gastroIntestinalData?.palpationRUQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="palpationRUQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.palpationRUQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.palpation}>
                                        <option value="">Select</option>
                                        {/*
                                        <option value="Deep Palpation">Deep Palpation</option>
                                        <option value="Light Palpation">Light Palpation</option>
                                        */}
                                        <option value="Non-Tender">Non-Tender</option>
                                        <option value="Tender to touch">Tender to touch</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">LLQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.palpation ? gastroIntestinalData?.palpationLLQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="palpationLLQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.palpationLLQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.palpation}>
                                        <option value="">Select</option>
                                        {/*
                                        <option value="Deep Palpation">Deep Palpation</option>
                                        <option value="Light Palpation">Light Palpation</option>
                                        */}
                                        <option value="Non-Tender">Non-Tender</option>
                                        <option value="Tender to touch">Tender to touch</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">LUQ</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.palpation ? gastroIntestinalData?.palpationLUQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="palpationLUQ" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.palpationLUQ ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.palpation}>
                                        <option value="">Select</option>
                                        {/*
                                        <option value="Deep Palpation">Deep Palpation</option>
                                        <option value="Light Palpation">Light Palpation</option>
                                        */}
                                        <option value="Non-Tender">Non-Tender</option>
                                        <option value="Tender to touch">Tender to touch</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 pt-2">
                            <input name="negativeSymptomsReported" id="negativeSymptomsReported" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.negativeSymptomsReported ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="negativeSymptomsReported">
                                Negative Symptoms Reported or Observed
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-7 offset-1">
                            <span className="my-1 d-block">Symptoms</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.negativeSymptomsReported
                                        ? gastroIntestinalData?.symptoms?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select isMulti name="symptoms" id="symptoms" menuPlacement="bottom" options={listOfSymptoms} value={(gastroIntestinalData?.symptoms ?? [])?.map((med) => listOfSymptoms?.find((i) => i.value === med))} menuPortalTarget={document.body} styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} onChange={handleSymptomsChange} isDisabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.negativeSymptomsReported}></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Other Symptoms</span>
                            {readOnly && <div className="text-success pt-2 pb-2">{gastroIntestinalData?.status === "Applicable" && gastroIntestinalData?.symptoms?.includes("Other") ? gastroIntestinalData?.symptomOther ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="symptomOther" type="text" className="form-control" placeholder={`Other Symptoms`} value={gastroIntestinalData?.symptomOther ?? ""} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.symptoms?.includes("Other")} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Elimination</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.elimination ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="elimination" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.elimination ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Continent">Continent</option>
                                        <option value="Diverted">Diverted</option>
                                        <option value="Incontinent">Incontinent</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Last BM</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.lastBM?.split("T")[0] ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="lastBM" type="date" className="form-control" value={gastroIntestinalData?.lastBM?.split("T")[0] ?? ""} onChange={handleFieldChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Description Last BM</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.lastBMDescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="lastBMDescription" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.lastBMDescription ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Bloody Stool">Bloody Stool</option>
                                        <option value="Constipation">Constipation</option>
                                        <option value="Diarrhea">Diarrhea</option>
                                        <option value="Rectal Bleeding">Rectal Bleeding</option>
                                        <option value="Soft Formed Comfortable">Soft Formed Comfortable</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Stoma Status</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.stomaStatus ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="stomaStatus" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.stomaStatus ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Temporary">Temporary</option>
                                        <option value="Permanent">Permanent</option>
                                        <option value="New">New</option>
                                        <option value="Established">Established</option>
                                        <option value="Revision">Revision</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Stoma Location</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.stomaLocation ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="stomaLocation" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.stomaLocation ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Lower Right">Lower Right</option>
                                        <option value="Upper Right">Upper Right</option>
                                        <option value="Central">Central</option>
                                        <option value="Upper Left">Upper Left</option>
                                        <option value="Lower Left">Lower Left</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Type of Diversion</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.typeOfDiversion ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="typeOfDiversion" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.typeOfDiversion ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Ileostomy">Ileostomy</option>
                                        <option value="Colostomy Ascending">Colostomy Ascending</option>
                                        <option value="Colostomy Transverse">Colostomy Transverse</option>
                                        <option value="Colostomy Sigmoid">Colostomy Sigmoid</option> <option value="Diarrhea">Diarrhea</option>
                                        <option value="Kock Pouch">Kock Pouch</option>
                                        <option value="Ileal Conduit">Ileal Conduit</option>
                                        <option value="Mucous Fistula">Mucous Fistula</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Stoma Tissue Assessment <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Color</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.stomaTissueColor ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="stomaTissueColor" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.stomaTissueColor ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Bright Red/Moist/Viable">Bright Red/Moist/Viable</option>
                                        <option value="Dark Red">Dark Red</option>
                                        <option value="Pink">Pink</option>
                                        <option value="Sloughing">Sloughing</option>
                                        <option value="Necrotic">Necrotic</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Stoma Size</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.stomaSize ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="stomaSize" type="text" className="form-control" placeholder={`Stoma Size`} value={gastroIntestinalData?.stomaSize ?? ""} onChange={handleFieldChange} maxLength="20" disabled={!["Applicable"].includes(gastroIntestinalData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Stoma Type</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.stomaType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="stomaType" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.stomaType ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Round">Round</option>
                                        <option value="Oval">Oval</option>
                                        <option value="Irregular">Irregular</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Stoma Height</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.stomaHeight ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="stomaHeight" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.stomaHeight ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Flush">Flush</option>
                                        <option value="Retracted">Retracted</option>
                                        <option value="Budded">Budded</option>
                                        <option value="Edematous">Edematous</option>
                                        <option value="Prolapse">Prolapse</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <input name="giStomaFunction" id="giStomaFunction" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.giStomaFunction ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="giStomaFunction">
                                GI Stoma Function
                            </label>
                            {readOnly && <div className="text-success pb-2 my-1">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.giStomaOutput ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="giStomaOutput" className="form-select my-1" onChange={handleFieldChange} value={gastroIntestinalData?.giStomaOutput ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.giStomaFunction}>
                                        <option value="">Select</option>
                                        <option value="Sero-Sanguenous">Sero-Sanguenous</option>
                                        <option value="Sanguenous">Sanguenous</option>
                                        <option value="+Flatus">+Flatus</option>
                                        <option value="-Flatus">-Flatus</option>
                                        <option value="Bilious">Bilious</option>
                                        <option value="Liquid">Liquid</option>
                                        <option value="Pasty">Pasty</option>
                                        <option value="Formed Soft">Bilious</option>
                                        <option value="Formed Firm">Formed Firm</option>
                                        <option value="None">None</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        {/*
                        <div className="col-3">
                            <input name="guStomaFunction" id="guStomaFunction" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.guStomaFunction ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="guStomaFunction">
                                GU Stoma Function
                            </label>
                            {readOnly && <div className="text-success pb-2 my-1">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.guStomaOutput ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="guStomaOutput" className="form-select my-1" onChange={handleFieldChange} value={gastroIntestinalData?.guStomaOutput ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.guStomaFunction}>
                                        <option value="">Select</option>
                                        <option value="Yellow">Yellow</option>
                                        <option value="Amber">Amber</option>
                                        <option value="Pink">Pink</option>
                                        <option value="Purple">Purple</option>
                                        <option value="Clots">Clots</option>
                                        <option value="Odor">Odor</option>
                                        <option value="Mucous">Mucous</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        */}
                        <div className="col-3">
                            <span className="my-1 d-block">Peristomal Skin</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.peristomalSkin ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="peristomalSkin" className="form-select" onChange={handleFieldChange} value={gastroIntestinalData?.peristomalSkin ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Intact">Intact</option>
                                        <option value="Irritant Dermatitis">Irritant Dermatitis</option>
                                        <option value="Candidiasis">Candidiasis</option>
                                        <option value="Caput Medusae">Caput Medusae</option>
                                        <option value="Folliculitis">Folliculitis</option>
                                        <option value="Mechanical Trauma">Mechanical Trauma</option>
                                        <option value="Mucosal Transplantation">Mucosal Transplantation</option>
                                        <option value="Pyoderma Gangrenosum">Pyoderma Gangrenosum</option>
                                        <option value="Hyperplasia">Hyperplasia</option>
                                        <option value="Radiation Trauma">Radiation Trauma</option>
                                        <option value="Allergies">Allergies</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-6">
                            <span className="my-1 d-block">Intervention</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.peristomalSkinIntervention ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="peristomalSkinIntervention" type="text" className="form-control" placeholder={`Intervention`} value={gastroIntestinalData?.peristomalSkinIntervention ?? ""} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(gastroIntestinalData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Self-care barriers <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <input name="selfCarePsychologicalBarriers" id="selfCarePsychologicalBarriers" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.selfCarePsychologicalBarriers ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="selfCarePsychologicalBarriers">
                                Psychological Barriers
                            </label>
                            {readOnly && <div className="text-success pb-2 my-1">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.selfCarePsychologicalBarriersType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="selfCarePsychologicalBarriersType" className="form-select my-1" onChange={handleFieldChange} value={gastroIntestinalData?.selfCarePsychologicalBarriersType ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.selfCarePsychologicalBarriers}>
                                        <option value="">Select</option>
                                        <option value="Emotional Instability">Emotional Instability</option>
                                        <option value="Body Image">Body Image</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <input name="selfCarePhysicalBarriers" id="selfCarePhysicalBarriers" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.selfCarePhysicalBarriers ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="selfCarePhysicalBarriers">
                                Physical Barriers
                            </label>
                            {readOnly && <div className="text-success pb-2 my-1">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.selfCarePhysicalBarriersType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="selfCarePhysicalBarriersType" className="form-select my-1" onChange={handleFieldChange} value={gastroIntestinalData?.selfCarePhysicalBarriersType ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.selfCarePhysicalBarriers}>
                                        <option value="">Select</option>
                                        <option value="Vision Impairment">Vision Impairment</option>
                                        <option value="Hearing Impairment">Hearing Impairment</option>
                                        <option value="Motor Impairment - Fine">Motor Impairment - Fine</option>
                                        <option value="Motor Impairment - Gross">Motor Impairment - Gross</option>
                                        <option value="Motor Impairment - Paraplegic">Motor Impairment - Paraplegic</option>
                                        <option value="Motor Impairment - Other">Motor Impairment - Other</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <input name="selfCareCognitiveBarriers" id="selfCareCognitiveBarriers" type="checkbox" className="form-check-input" checked={gastroIntestinalData?.selfCareCognitiveBarriers ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || readOnly} />
                            <label className="form-check-label ps-2" htmlFor="selfCareCognitiveBarriers">
                                Cognitive Barriers
                            </label>
                            {readOnly && <div className="text-success pb-2 my-1">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.selfCareCognitiveBarriersType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="selfCareCognitiveBarriersType" className="form-select my-1" onChange={handleFieldChange} value={gastroIntestinalData?.selfCareCognitiveBarriersType ?? ""} disabled={!["Applicable"].includes(gastroIntestinalData?.status) || !gastroIntestinalData?.selfCareCognitiveBarriers}>
                                        <option value="">Select</option>
                                        <option value="Language">Language</option>
                                        <option value="Mental Status Impairment">Mental Status Impairment</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Appliance Brand/Manufacturer</span>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.applianceBrandManufacturer ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="applianceBrandManufacturer" type="text" className="form-control" placeholder={`Brand/Manufacturer`} value={gastroIntestinalData?.applianceBrandManufacturer ?? ""} onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(gastroIntestinalData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-9">
                            <span className="my-1 d-block">Additional Products Used</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {gastroIntestinalData?.status === "Applicable"
                                        ? gastroIntestinalData?.additionalProductsUsed?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="additionalProductsUsed"
                                        id="additionalProductsUsed"
                                        menuPlacement="bottom"
                                        options={listOfAdditionalProductsUsed}
                                        value={(gastroIntestinalData?.additionalProductsUsed ?? [])?.map((med) => listOfAdditionalProductsUsed?.find((i) => i.value === med))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleAdditionalProductsUsedChange}
                                        isDisabled={!["Applicable"].includes(gastroIntestinalData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <label className="my-1 d-block" htmlFor="comments">
                                Comments
                            </label>
                            {readOnly && <div className="text-success pb-2">{gastroIntestinalData?.status === "Applicable" ? gastroIntestinalData?.comments ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <textarea name="comments" id="comments" className="form-control" placeholder={`Comments`} value={gastroIntestinalData?.comments ?? ""} maxLength="150" onChange={handleFieldChange} disabled={!["Applicable"].includes(gastroIntestinalData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} readOnly={readOnly} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, gastrointestinalSection: data?.gastrointestinalSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
