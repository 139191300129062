import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./notApplicableConfirmationDialog.css";

export const NotApplicableConfirmationDialog = ({ setDisplayDialog, handleConfirmClick, closeDialog }) => {
    const handleClose = () => closeDialog();

    const handleDialogConfirmClick = () => {
        handleConfirmClick(true);
        closeDialog();
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        setDisplayDialog(!checked);
    };

    return (
        <Modal dialogClassName="confirmationNotApplicableModal" show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 text-center">
                        <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                                fontSize: "4rem",
                                color: "red",
                                paddingTop: "0.6rem",
                            }}
                        ></i>
                        <h4 className="text-danger pt-2 pb-3">
                            Notice: Information in this card will be deleted. <br></br>Do you want to continue?
                        </h4>
                    </div>
                </div>
                <div className="row m-0 p-0 text-center">
                    <div className="col-12 m-0 p-0 text-left">
                        <input name="dontShowMessageAgain" id="dontShowMessageAgain" type="checkbox" className="form-check-input" onChange={handleCheckboxChange} />
                        <label className="form-check-label ps-2 small" htmlFor="dontShowMessageAgain">
                            Don't show this message again for this visit.
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-secondary" onClick={handleClose}>
                    Cancel
                </button>
                &nbsp;
                <button className="btn btn-danger" onClick={handleDialogConfirmClick}>
                    Confirm
                </button>
            </Modal.Footer>
        </Modal>
    );
};
