import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { loginRequest } from "../../authConfig";
import { callMsGraph, callMsGraphMyPhoto } from "../../graph";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./ProfilePhoto.css";
import genericAvatar from "./genericAvatar.jpg";

export const ProfilePhoto = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const name = accounts[0] && accounts[0].name;

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3" bsPrefix="my-popover-header">
                {name}
            </Popover.Header>
            <Popover.Body style={{ padding: "10px", margin: "0" }}>
                <div className="row my-0 py-0">
                    <div className="col-md-12">
                        <small>
                            <strong>Email: </strong> {graphData?.userPrincipalName}
                        </small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <small>
                            <strong>Job Title: </strong> {graphData?.jobTitle}
                        </small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <small>
                            <strong>Office Location: </strong> {graphData?.officeLocation}
                        </small>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
        RequestPhotoData();
        RequestProfileData();
    }, []);

    function RequestPhotoData() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance
            .acquireTokenSilent(request)
            .then((response) => {
                callMsGraphMyPhoto(response.accessToken).then((response) => {
                    //alert(response.type);
                    //alert(response.size);
                    const url = window.URL || window.webkitURL;
                    if (response.type !== "application/json") {
                        const blobUrl = url.createObjectURL(response);
                        setImageUrl(blobUrl);
                    }
                });
            })
            .catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    callMsGraphMyPhoto(response.accessToken).then((response) => {
                        const url = window.URL || window.webkitURL;
                        const blobUrl = url.createObjectURL(response.data);
                        setImageUrl(blobUrl);
                    });
                });
            });
    }

    function RequestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance
            .acquireTokenSilent(request)
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            })
            .catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    callMsGraph(response.accessToken).then((response) => setGraphData(response));
                });
            });
    }

    return (
        <>
            {imageUrl && (
                <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={popover}>
                    <img className="my-user-avatar" alt="my-user-avatar" width="40" height="40" src={imageUrl} />
                </OverlayTrigger>
            )}

            {!imageUrl && (
                <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={popover}>
                    <img className="my-user-avatar" alt="my-user-avatar" width="40" height="40" src={genericAvatar} />
                </OverlayTrigger>
            )}
        </>
    );
};
