export const getAssessmentTypeManagementData = () => {
    return {
        title: "Visit Completion",
        description: "Visit completion report at your fingertips.",
        icon: "clipboard-list-solid-red.svg",
        iconHover: "clipboard-list-solid-green.svg",
        cardLink: "/reports/visitCompletionCompliance",
        section: "ATK",
    };
};
