//Icons
import iconNurseOrdersGreen from "../../../assets/hand-holding-heart-solid-green.svg";
import iconNurseOrdersRed from "../../../assets/hand-holding-heart-solid-red.svg";

import { updateNurseOrdersSection } from "../../../api/nurseAssessment/nurseOrdersAPI";
import { isError } from "../../../lib/isError";

export const getNurseOrdersSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowNurseOrdersDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateNurseOrdersSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving NurseOrders Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.nurseOrdersSection, status: status };
        const saveObj = { id: patient?.nurseOrdersSection?.id, status: status };
        updateNurseOrdersSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", nurseOrdersSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Plan of Care",
        description: null,
        progress: data?.nurseOrdersSection?.progress,
        status: data?.nurseOrdersSection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconNurseOrdersRed,
        iconCompleted: iconNurseOrdersGreen,
        saving: false,
        onClick: () => {
            setShowNurseOrdersDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateNurseOrdersSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateNurseOrdersSectionStatus(notApplicable);
            }
        },
    };
};
