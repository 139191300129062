import "./pdfViewerModal.css";

import { Modal } from "react-bootstrap";
//import { PDFViewer } from "@react-pdf/renderer";

export const PdfViewerModal = ({ title, show, setShow }) => {
    //=== Use States ==========================================================

    //=== Use Effects =========================================================

    //=== Methods =============================================================

    //=== Handlers ============================================================

    //=== Render ==============================================================
    return (
        <>
            <Modal show={show} onHide={() => setShow(false)} size="lg" dialogClassName="modal-70w">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "calc(100vh - 150px)" }}>teste{/*<PDFViewer width="100%" height="100%"></PDFViewer>*/}</Modal.Body>
            </Modal>
        </>
    );
};
