import "./reports.css";

import { useEffect, useState } from "react";
import { ReportCardSection } from "./components/reportCardSection/reportCardSection";
import { getAssessmentTypeManagementData } from "./components/reportCard/lib/reportCardLib_AssessmentTypeManagement";
import { getSupervisionReportByClientData } from "./components/reportCard/lib/reportCardLib_SupervisionReportByClient";
import { getSupervisionReportByEmployeeData } from "./components/reportCard/lib/reportCardLib_SupervisionReportByEmployee";
import { getWoundCareReportData } from "./components/reportCard/lib/reportCardLib_WoundCareReport";

export const Reports = () => {
    //=== Use States ==========================================================

    //Reports cards collection
    const [reportsFeatureCards, setReportsFeatureCards] = useState([]);

    //filter value state
    const [filter, setFilter] = useState(null);

    //=== Use Effects =========================================================
    useEffect(() => {
        //Setting Cards
        const reportsCards = [];

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getAssessmentTypeManagementData();
            reportsCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getSupervisionReportByClientData();
            reportsCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getSupervisionReportByEmployeeData();
            reportsCards.push(sectionContent);
        }

        //ToDo: Conditional to add or nor card
        if (1 == 1) {
            const sectionContent = getWoundCareReportData();
            reportsCards.push(sectionContent);
        }

        //Setting cards...
        setReportsFeatureCards(reportsCards);
    }, []);

    //=== Handlers ============================================================

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //=== Render ==============================================================
    return (
        <>
            <section id="reports">
                <div className="container container-wide mt-5 pt-4">
                    <div className="row">
                        <div className="col-sm-7 col-md-9 col-lg-9">
                            <h4 className="pt-4 pb-0 mb-0 ps-3 float-start text-primary">Reports</h4>
                        </div>
                        <div className="col-sm-5 col-md-3 col-lg-3 align-end">
                            <input type="text" className="mt-3 form-control form-control" placeholder="Search...." onChange={handleSearchInput} />
                        </div>
                    </div>

                    <hr />
                    {reportsFeatureCards && <ReportCardSection title="" reportCards={[...reportsFeatureCards.filter((i) => i?.section === "ATK")]} filter={filter} />}
                </div>
            </section>
        </>
    );
};
