import icon from "../../../../../../../../assets/bandaid-red.svg";

export const SummaryWoundCare = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Wound Care
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row-prt ms-2 py-3">
                        <div className="col-prt-2 fw-bold fst-italic">
                            <li>Wounds</li>
                        </div>
                    </div>

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            {(data?.wounds?.length === 0 || !data?.wounds) && "-"}
                            {data?.wounds?.map((wound, index) => {
                                return (
                                    <section id="wound" key={index}>
                                        <div className="row-prt">
                                            <div className="col-prt-12 fw-bold">{index + 1}. Wound Type</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-12 text-success">{wound?.typeOfWound === "Burns" ? `Burns ${wound?.burnsDescription ? `(${wound.burnsDescription})` : ""}` : wound?.typeOfWound ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-2 fw-bold">Wound Location</div>
                                            <div className="col-prt-2 fw-bold">Classification</div>
                                            <div className="col-prt-2 fw-bold">Length</div>
                                            <div className="col-prt-2 fw-bold">Width</div>
                                            <div className="col-prt-2 fw-bold">Depth</div>
                                            <div className="col-prt-2 fw-bold">Tunneling</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-2 text-success">{wound?.location}</div>
                                            <div className="col-prt-2 text-success">{wound?.classification}</div>
                                            <div className="col-prt-2 text-success">{wound?.length}</div>
                                            <div className="col-prt-2 text-success">{wound?.width}</div>
                                            <div className="col-prt-2 text-success">{wound?.depth}</div>
                                            <div className="col-prt-2 text-success">{wound?.tunneling}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-2 fw-bold">Odor</div>
                                            <div className="col-prt-2 fw-bold">Stoma</div>
                                            <div className="col-prt-4 fw-bold">Wound Healing Progress</div>
                                            <div className="col-prt-4 fw-bold">Description</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-2 text-success">{wound?.odor}</div>
                                            <div className="col-prt-2 text-success">{wound?.stoma}</div>
                                            <div className="col-prt-4 text-success">{wound?.woundHealingProgress}</div>
                                            <div className="col-prt-4 text-success">{wound?.woundHealingProgressDetails}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-4 fw-bold">Mode of Healing</div>
                                            <div className="col-prt-6 fw-bold">Previous Wound Care/Dressing Change Performed by</div>
                                            <div className="col-prt-2 fw-bold">Performed On</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.modeOfHealing}</div>
                                            <div className="col-prt-6 text-success">{wound?.previousWoundCareDressingChangePerformedBy === "Other Medical/Nursing Provider" ? wound?.previousWoundCareDressingChangePerformedByOther ?? "-" : wound?.previousWoundCareDressingChangePerformedBy ?? "-"}</div>
                                            <div className="col-prt-2 text-success">{wound?.previousWoundCareDressingChangePerformedOn?.split("T")[0]}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-4 fw-bold">Previous Wound Resolved</div>
                                            <div className="col-prt-4 fw-bold">Wound Irrigation</div>
                                            <div className="col-prt-4 fw-bold">Wound Debridement</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.previousWoundResolved ? "Yes" : "-"}</div>
                                            <div className="col-prt-4 text-success">{wound?.woundIrrigation === "Not Performed" ? `Not Performed ${wound?.woundIrrigationNoReason ? `(${wound.woundIrrigationNoReason})` : ""}` : wound?.woundIrrigation ?? "-"}</div>
                                            <div className="col-prt-4 text-success">{wound?.woundDebridement === "Not Performed" ? `Not Performed ${wound?.woundDebridementNoReason ? `(${wound.woundDebridementNoReason})` : ""}` : wound?.woundDebridement ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-4 fw-bold">Does Client Receive NPWT</div>
                                            <div className="col-prt-4 fw-bold"># Pieces of Foam/Gauze removed</div>
                                            <div className="col-prt-4 fw-bold">Contact Layer</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.clientReceiveNPWT ?? "-"}</div>
                                            <div className="col-prt-4 text-success">{wound?.numberOfPiecesOfOfFoamGauzeRemoved ?? "-"}</div>
                                            <div className="col-prt-4 text-success">{wound?.contactLayer ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-4 fw-bold">Soiled dressing removed/disposed of properly</div>
                                            <div className="col-prt-8 fw-bold">Unit Settings have been checked and Pressure and Variability setting confirmed against order</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.soiledDressingRemovedDisposedProperly ? "Yes" : "-"}</div>
                                            <div className="col-prt-8 text-success">{wound?.unitSettingsCheckedAndPressureAndVariabilityConfirmed ? "Yes" : "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-8 fw-bold">Drainage canister disposed of appropriately as required</div>
                                            <div className="col-prt-4 fw-bold">Wound cleaned</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-8 text-success">{wound?.drainageCanisterDisposed ? "Yes" : "-"}</div>
                                            <div className="col-prt-4 text-success">{wound?.woundCleaned ? `Yes ${wound.woundCleanedDescription === "Other" ? (wound?.woundCleanedDescriptionOther ? `(${wound?.woundCleanedDescriptionOther})` : "") : `(${wound.woundCleanedDescription})`}` : "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-4 fw-bold">Exudate container emptied</div>
                                            <div className="col-prt-4 fw-bold">Level</div>
                                            <div className="col-prt-4 fw-bold">Type</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.exudateContainerEmptied ? "Yes" : "-"}</div>
                                            <div className="col-prt-4 text-success">{wound?.exudateLevel ?? "-"}</div>
                                            <div className="col-prt-4 text-success">{wound?.exudateType ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-4 fw-bold">Drain in Place</div>
                                            <div className="col-prt-2 fw-bold">Amount (mL)</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.drainInPlace ? "Yes" : "-"}</div>
                                            <div className="col-prt-2 text-success">{wound?.drainageAmount ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-4 fw-bold">Dressing Applied as ordered</div>
                                            <div className="col-prt-8 fw-bold">Description</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.dressingsApplied ? "Yes" : "-"}</div>
                                            <div className="col-prt-8 text-success">{wound?.dressingsAppliedDescription ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-4 fw-bold">Patient tolerated procedure well</div>
                                            <div className="col-prt-8 fw-bold">Description</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.patientToleratedProcedureWell ? "Yes" : "-"}</div>
                                            <div className="col-prt-8 text-success">{wound?.patientToleratedProcedureWellDescription ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-12 fw-bold">Patient/family/caregiver instructed on wound care / ostomy / disposal soiled dressing</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-12 text-success">{wound?.instructedOnWoundCareOstomyDisposalSoiledDressing ? "Yes" : "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-12 fw-bold">Patient/family/caregiver to perform wound care / ostomy / dressing change</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-12 text-success">{wound?.performWoundCareOstomyDressingChange ? "Yes" : "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-10 fw-bold">Nursing Supervisor aware</div>
                                            <div className="col-prt-2 fw-bold">Date</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-10 text-success">{wound?.nursingSupervisorNotifiedImpairedHealingAndPotentialBarriersToHealing ? "Yes" : "-"}</div>
                                            <div className="col-prt-2 text-success">{wound?.nursingSupervisorNotifiedDate?.split("T")[0] ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-10 fw-bold">MD aware</div>
                                            <div className="col-prt-2 fw-bold">Date</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-10 text-success">{wound?.mdNotifiedImpairedHealingAndPotentialBarriersToHealing ? "Yes" : "-"}</div>
                                            <div className="col-prt-2 text-success">{wound?.mdNotifiedDate?.split("T")[0] ?? "-"}</div>
                                        </div>

                                        <div className="row-prt">
                                            <div className="col-prt-12 fw-bold">Comments</div>
                                        </div>
                                        <div className="row-prt mb-2">
                                            <div className="col-prt-4 text-success">{wound?.comments ?? "-"}</div>
                                        </div>

                                        <div className="row-prt ms-2">
                                            <div className="col-prt-12">
                                                <hr />
                                            </div>
                                        </div>
                                    </section>
                                );
                            })}
                        </div>
                    </div>
                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
