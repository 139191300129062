//css
import "./genitourinaryDialog.css";

//Icons
import icon from "../../../../assets/toilet-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { updateGenitourinarySection } from "../../../../api/nurseAssessment/genitourinaryAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const GenitourinaryDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Genitourinary data state
    const [genitourinaryData, setGenitourinaryData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of disorders
    const listOfDisorders = [
        { label: "Anuria", value: "Anuria" },
        { label: "Burning", value: "Burning" },
        { label: "Frequency", value: "Frequency" },
        { label: "Hematuria", value: "Hematuria" },
        { label: "Incontinence", value: "Incontinence" },
        { label: "Nocturia", value: "Nocturia" },
        { label: "Odor", value: "Odor" },
        { label: "Oliguria", value: "Oliguria" },
        { label: "Pain", value: "Pain" },
        { label: "Polyuria", value: "Polyuria" },
        { label: "Retention/Hesitancy", value: "Retention/Hesitancy" },
        { label: "Urgency", value: "Urgency" },
        { label: "Urinary Diversion", value: "Urinary Diversion" },
        { label: "Urinary Tract Infection", value: "Urinary Tract Infection" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.genitourinarySection, status: data?.genitourinarySection?.status ?? "Applicable" };
        setGenitourinaryData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: genitourinaryData?.id, status: genitourinaryData?.status };
        setGenitourinaryData(newData);
    };

    const handleSave = () => {
        setSaving(true);

        const saveObj = {
            ...genitourinaryData,
        };

        //===========================================================

        //Saving GenitourinarySection
        updateGenitourinarySection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", genitourinarySection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...genitourinaryData, [name]: value };
        setGenitourinaryData(newData);
    };

    const handleDisorderChange = (e) => {
        let disordersNew = e?.map((i) => i.value);
        const newData = { ...genitourinaryData, disorders: disordersNew };
        setGenitourinaryData(newData);
    };

    return (
        <Modal dialogClassName="genitourinarymodal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Genitourinary - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{genitourinaryData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Disorders</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {genitourinaryData?.status === "Applicable"
                                        ? genitourinaryData?.disorders?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="disorders"
                                        id="disorders"
                                        menuPlacement="bottom"
                                        options={listOfDisorders}
                                        //value={listOfDisorders.value}
                                        //defaultValue={genitourinaryData?.disorders?.map((med) => listOfDisorders?.find((i) => i.value === med))}
                                        value={(genitourinaryData?.disorders ?? [])?.map((med) => listOfDisorders?.find((i) => i.value === med))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleDisorderChange}
                                        isDisabled={!["Applicable"].includes(genitourinaryData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Dialysis Patient</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 offset-1">
                            <span className="my-1 d-block">Bruit</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.bruit ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="bruit" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.bruit ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Positive">Positive</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-6">
                            <span className="my-1 d-block">Thrill</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.thrill ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="thrill" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.thrill ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Positive">Positive</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Urostomy</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.urostomy ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="urostomy" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.urostomy ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Ileal Conduit">Ileal Conduit (located on the Right lower side of abdomen)</option>
                                        <option value="Ureterostomy">Ureterostomy – two stomas (will need two sites included in documentation)</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Continent Urinary Diversion</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.continentUrinaryDiversion ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="continentUrinaryDiversion" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.continentUrinaryDiversion ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Indiana Pouch">Indiana Pouch (located on the lower right side of abdomen or at umbilicus)</option>
                                        <option value="Mitrofanoff">Mitrofanoff (located on the lower right side of abdomen or at umbilicus)</option>
                                        <option value="Orthotopic Neobladder">Orthotopic Neobladder (No Stoma)</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Type of Stoma</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.typeOfStoma ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="typeOfStoma" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.typeOfStoma ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Double Barrel">Double Barrel</option>
                                        <option value="End">End</option>
                                        <option value="Loop">Loop</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-5">
                            <span className="my-1 d-block">Appearance of Stoma</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.appearanceOfStoma ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="appearanceOfStoma" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.appearanceOfStoma ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Bloody">Bloody</option>
                                        <option value="Dry">Dry</option>
                                        <option value="Edematous">Edematous</option>
                                        <option value="Moist">Moist</option>
                                        <option value="Pale">Pale</option>
                                        <option value="Shiny">Shiny</option>
                                        <option value="Smooth">Smooth</option>
                                        <option value="Taunt">Taunt</option>
                                        <option value="Textured">Textured</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Color of Stoma Mucosa</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.colorOfStoma ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="colorOfStoma" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.colorOfStoma ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Beefy Red">Beefy Red</option>
                                        <option value="Black">Black</option>
                                        <option value="Blue">Blue</option>
                                        <option value="Pale Pink">Pale Pink</option>
                                        <option value="Pink">Pink</option>
                                        <option value="Purple">Purple</option>
                                        <option value="Red">Red</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Stints/Rods/Drains</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.stintsRodsDrains ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="stintsRodsDrains" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.stintsRodsDrains ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Drains">Drains</option>
                                        <option value="Rods">Rods</option>
                                        <option value="Stints">Stints</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Height of Stoma</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.heightOfStoma ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="heightOfStoma" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.heightOfStoma ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Budded">Budded</option>
                                        <option value="Flush">Flush</option>
                                        <option value="Prolapsed">Prolapsed</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">
                                Size of Stoma <small>(mm)</small>
                            </span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.sizeOfStoma ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="sizeOfStoma" type="text" className="form-control" placeholder={`Size (mm)`} value={genitourinaryData?.sizeOfStoma ?? ""} onChange={handleFieldChange} maxLength="5" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">Stoma/Peristomal Skin Junction</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.stomaPeristomalSkinJunction ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="stomaPeristomalSkinJunction" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.stomaPeristomalSkinJunction ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Intact">Intact</option>
                                        <option value="Separated">Separated</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">
                                Lumen <small>(#)</small>
                            </span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.numLumen ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="numLumen" type="text" className="form-control" placeholder={`Lumen #`} value={genitourinaryData?.numLumen ?? ""} onChange={handleFieldChange} maxLength="5" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3">
                            <span className="my-1 d-block">Location of Lumen</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.locationOfLumen ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="locationOfLumen" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.locationOfLumen ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Level with Skin">Level with Skin</option>
                                        <option value="Side">Side</option>
                                        <option value="Straight Up">Straight Up</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Odor</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.odor ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="odor" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.odor ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Fecal">Fecal</option>
                                        <option value="Foul">Foul</option>
                                        <option value="Musty">Musty</option>
                                        <option value="Pungent">Pungent</option>
                                        <option value="Strong">Strong</option>
                                        <option value="Sweet">Sweet</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Stoma Functioning Status</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.functioningStatusStoma ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="functioningStatusStoma" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.functioningStatusStoma ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Functioning">Functioning</option>
                                        <option value="Non-Functioning">Non-Functioning</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Peristomal assessment</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 offset-1">
                            <span className="my-1 d-block">Integrity</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.peristomalIntegrity ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="peristomalIntegrity" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.peristomalIntegrity ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Denuded">Denuded (superficial skin damage)</option>
                                        <option value="Intact">Intact (no breakdown in skin)</option>
                                        <option value="Macerated">Macerated (white friable skin, too much moisture)</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-6">
                            <span className="my-1 d-block">Lesions</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.peristomalLesions ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="peristomalLesions" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.peristomalLesions ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Incision/Scars">Incision/Scars (connective tissue reflective of dermal damage)</option>
                                        <option value="Ulceration">Ulceration (a wound through the dermis layer)</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 offset-1">
                            <span className="my-1 d-block">Texture</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.peristomalTexture ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="peristomalTexture" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.peristomalTexture ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Crusty">Crusty</option>
                                        <option value="Moist">Moist</option>
                                        <option value="Warm">Warm</option>
                                        <option value="Weepy">Weepy</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <span className="my-1 d-block">Turgor</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.turgor ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="turgor" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.turgor ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Detected by Palpation">Detected by Palpation</option>
                                        <option value="Edema">Edema</option>
                                        <option value="Firm">Firm (hard)</option>
                                        <option value="Flaccid">Flaccid (weak and flabby)</option>
                                        <option value="Induration">Induration (hardened mass with defined edges)</option>
                                        <option value="Normal">Normal (soft, good elasticity)</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Peristomal Pain (PQRST Assessment)</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 offset-1">
                            <span className="my-1 d-block">(P) Provocation/Palliation</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.peristomalPainP ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="peristomalPainP" type="text" className="form-control" placeholder={`Describe`} value={genitourinaryData?.peristomalPainP ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">(Q) Quality/Quantity</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.peristomalPainQ ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="peristomalPainQ" type="text" className="form-control" placeholder={`Describe`} value={genitourinaryData?.peristomalPainQ ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">(R) Region/Radiation</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.peristomalPainR ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="peristomalPainR" type="text" className="form-control" placeholder={`Describe`} value={genitourinaryData?.peristomalPainR ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">(S) Severity Scale</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.peristomalPainS ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="peristomalPainS" type="text" className="form-control" placeholder={`Describe`} value={genitourinaryData?.peristomalPainS ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">(T) Timing</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.peristomalPainT ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="peristomalPainT" type="text" className="form-control" placeholder={`Describe`} value={genitourinaryData?.peristomalPainT ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Foley Catheter</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 offset-1">
                            <span className="my-1 d-block">Catheter Type</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.catheterType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="catheterType" type="text" className="form-control" placeholder={`Describe`} value={genitourinaryData?.catheterType ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">
                                Size <small>(Fr)</small>
                            </span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.catheterSize ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="catheterSize" type="text" className="form-control" placeholder={`Size`} value={genitourinaryData?.catheterSize ?? ""} onChange={handleFieldChange} maxLength="10" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">
                                Foley Irrigation <small>(mL)</small>
                            </span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.foleyIrrigation ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="foleyIrrigation" type="text" className="form-control" placeholder={`Foley`} value={genitourinaryData?.foleyIrrigation ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Urine Color</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.urineColor ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="urineColor" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.urineColor ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Amber">Amber</option>
                                        <option value="Blood Tinged">Blood Tinged</option>
                                        <option value="Clear">Clear</option>
                                        <option value="Cloudy">Cloudy</option>
                                        <option value="Straw">Straw</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Consistency</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.consistency ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="consistency" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.consistency ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Clear">Clear</option>
                                        <option value="Crystals">Crystals</option>
                                        <option value="Grit">Grit</option>
                                        <option value="Mucous Strands">Mucous Strands</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 offset-1">
                            <span className="my-1 d-block">To be changed by</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.foleyToBeChangedBy ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="foleyToBeChangedBy" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.foleyToBeChangedBy ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="MD office">MD office</option>
                                        <option value="Medicare Nurse">Medicare Nurse</option>
                                        <option value="Private Duty Nurse">Private Duty Nurse</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Odor</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.foleyToBeChangedBy ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="foleyOdor" className="form-select" onChange={handleFieldChange} value={genitourinaryData?.foleyOdor ?? ""} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)}>
                                        <option value="">Select</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Fecal">Fecal</option>
                                        <option value="Foul">Foul</option>
                                        <option value="Musty">Musty</option>
                                        <option value="Present">Present</option>
                                        <option value="Pungent">Pungent</option>
                                        <option value="Strong">Strong</option>
                                        <option value="Sweet">Sweet</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">
                                Output <small>(mL)</small>
                            </span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.foleyOutput ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="foleyOutput" type="text" className="form-control" placeholder="Output" value={genitourinaryData?.foleyOutput ?? ""} maxLength="10" onChange={handleFieldChange} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-2">
                            <span className="my-1 d-block">Foley Change Date</span>
                            {readOnly && <div className="text-success pb-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.foleyChangeDate?.split("T")[0] ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="foleyChangeDate" type="date" className="form-control" value={genitourinaryData?.foleyChangeDate?.split("T")[0] ?? ""} onChange={handleFieldChange} disabled={!["Applicable"].includes(genitourinaryData?.status) || ["Orthotopic Neobladder"].includes(genitourinaryData?.continentUrinaryDiversion)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Comments</span>
                            {readOnly && <div className="text-success pb-2 pt-2">{genitourinaryData?.status === "Applicable" ? genitourinaryData?.comments ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <textarea name="comments" className="form-control" placeholder={`Comments`} value={null} maxLength="1000" onChange={handleFieldChange} disabled={!["Applicable"].includes(genitourinaryData?.status)} value={genitourinaryData?.comments ?? ""} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, genitourinarySection: data?.genitourinarySection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
