//Icons
import iconNurseReviewGreen from "../../../assets/comment-medical-solid-green.svg";
import iconNurseReviewRed from "../../../assets/comment-medical-solid-red.svg";

import { updateNurseReviewSection } from "../../../api/nurseAssessment/nurseReviewAPI";
import { isError } from "../../../lib/isError";

export const getNurseReviewSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowNurseReviewDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateNurseReviewSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving NurseReview Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.nurseReviewSection, status: status };
        const saveObj = { id: patient?.nurseReviewSection?.id, status: status };
        updateNurseReviewSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", nurseReviewSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Nurse Review",
        description: null,
        progress: data?.nurseReviewSection?.progress,
        status: data?.nurseReviewSection?.status,
        enableStatusField: true,
        iconClass: "fa-user",
        iconPending: iconNurseReviewRed,
        iconCompleted: iconNurseReviewGreen,
        saving: false,
        onClick: () => {
            setShowNurseReviewDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateNurseReviewSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateNurseReviewSectionStatus(notApplicable);
            }
        },
    };
};
