//css
import "./diabeticExamDialog.css";

//Icons
import icon from "../../../../assets/person-rays-solid-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { updateDiabeticExamSection } from "../../../../api/nurseAssessment/diabeticExamAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const DiabeticFocusExamDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //diabetic focus data state
    const [diabeticExamData, setDiabeticExamData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //list of loss sense
    const listOfLossSense = [
        { label: "Warm right", value: "Warm right" },
        { label: "Warm left", value: "Warm left" },
        { label: "Cold right", value: "Cold right" },
        { label: "Cold left", value: "Cold left" },
    ];

    //list of sides
    const listOfSides = [
        { label: "Left", value: "Left" },
        { label: "Right", value: "Right" },
    ];

    //list of previous experiences
    const listOfPreviousExperiences = [
        { label: "Dry skin", value: "Dry skin" },
        { label: "Fatigue and weakness", value: "Fatigue and weakness" },
        { label: "Polydipsia or increased thirst", value: "Polydipsia or increased thirst" },
        { label: "Polyphagia or increased appetite", value: "Polyphagia or increased appetite" },
        { label: "Polyuria or increased urination", value: "Polyuria or increased urination" },
        { label: "Recurrent infections", value: "Recurrent infections" },
        { label: "Skin lesions or wounds that are slow to heal", value: "Skin lesions or wounds that are slow to heal" },
        { label: "Sudden vision changes", value: "Sudden vision changes" },
        { label: "Tingling or numbness in hands or feet", value: "Tingling or numbness in hands or feet" },
    ];

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.diabeticExamSection, status: data?.diabeticExamSection?.status ?? "Applicable" };
        setDiabeticExamData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: diabeticExamData?.id, status: diabeticExamData?.status };
        setDiabeticExamData(newData);
    };

    const handleSave = () => {
        setSaving(true);

        const saveObj = {
            ...diabeticExamData,
        };

        //===========================================================

        //Saving DiabeticExamSection
        updateDiabeticExamSection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", diabeticExamSection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...diabeticExamData, [name]: value };
        setDiabeticExamData(newData);
    };

    const handleLossOfSenseChange = (e) => {
        let lossOfSenseNew = e?.map((i) => i.value);
        const newData = { ...diabeticExamData, lossOfSense: lossOfSenseNew };
        setDiabeticExamData(newData);
    };

    const handleNeuropathyChange = (e) => {
        let neuropathyNew = e?.map((i) => i.value);
        const newData = { ...diabeticExamData, neuropathy: neuropathyNew };
        setDiabeticExamData(newData);
    };

    const handleTinglingChange = (e) => {
        let tinglingNew = e?.map((i) => i.value);
        const newData = { ...diabeticExamData, tingling: tinglingNew };
        setDiabeticExamData(newData);
    };

    const handleBurningChange = (e) => {
        let burningNew = e?.map((i) => i.value);
        const newData = { ...diabeticExamData, burning: burningNew };
        setDiabeticExamData(newData);
    };

    const handleSymptomsSinceLastAssessmentChange = (e) => {
        let symptomsSinceLastAssessmentNew = e?.map((i) => i.value);
        const newData = { ...diabeticExamData, symptomsSinceLastAssessment: symptomsSinceLastAssessmentNew };
        setDiabeticExamData(newData);
    };

    return (
        <Modal dialogClassName="diabeticModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt=""></img>
                        </span>
                        <span className="ps-2">Diabetic Focused Exam - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-3">{diabeticExamData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={diabeticExamData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Frequency of diabetic foot exam</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.frequencyDiabeticFootExam ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" placeholder={`Enter frequency exam`} value={diabeticExamData?.frequencyDiabeticFootExam ?? ""} name="frequencyDiabeticFootExam" onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(diabeticExamData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Done by</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.doneBy ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="doneBy" className="form-select" onChange={handleFieldChange} value={diabeticExamData?.doneBy ?? ""} disabled={!["Applicable"].includes(diabeticExamData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Caregiver">Caregiver</option>
                                        <option value="Patient">Patient</option>
                                        <option value="RN/PT">RN/PT</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">If other, specify</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" && diabeticExamData?.doneBy === "Other" ? diabeticExamData?.doneByOtherName ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" name="doneByOtherName" className="form-control" placeholder={`Enter name`} value={diabeticExamData?.doneByOtherName ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(diabeticExamData?.status) || diabeticExamData?.doneBy !== "Other"} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Integument findings</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.integumentFindings ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" placeholder={`Enter integument findings`} value={diabeticExamData?.integumentFindings ?? ""} name="integumentFindings" onChange={handleFieldChange} maxLength="100" disabled={!["Applicable"].includes(diabeticExamData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <span className="my-1 d-block">Loss of sense of:</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {diabeticExamData?.status === "Applicable"
                                        ? diabeticExamData?.lossOfSense?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="lossOfSense"
                                        id="lossOfSense"
                                        menuPlacement="bottom"
                                        options={listOfLossSense}
                                        //value={listOfLossSense.value}
                                        //defaultValue={diabeticExamData?.lossOfSense?.map((dev) => listOfLossSense?.find((i) => i.value === dev))}
                                        value={(diabeticExamData?.lossOfSense ?? []).map((dev) => listOfLossSense?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleLossOfSenseChange}
                                        isDisabled={!["Applicable"].includes(diabeticExamData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <span className="my-1 d-block">Neuropathy</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {diabeticExamData?.status === "Applicable"
                                        ? diabeticExamData?.neuropathy?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="neuropathy"
                                        id="neuropathy"
                                        menuPlacement="bottom"
                                        options={listOfSides}
                                        //value={listOfSides.value}
                                        //defaultValue={diabeticExamData?.neuropathy?.map((dev) => listOfSides?.find((i) => i.value === dev))}
                                        value={(diabeticExamData?.neuropathy ?? []).map((dev) => listOfSides?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleNeuropathyChange}
                                        isDisabled={!["Applicable"].includes(diabeticExamData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3">
                            <span className="my-1 d-block">Tingling</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {diabeticExamData?.status === "Applicable"
                                        ? diabeticExamData?.tingling?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="tingling"
                                        id="tingling"
                                        menuPlacement="bottom"
                                        options={listOfSides}
                                        //value={listOfSides.value}
                                        //defaultValue={diabeticExamData?.tingling?.map((dev) => listOfSides?.find((i) => i.value === dev))}
                                        value={(diabeticExamData?.tingling ?? []).map((dev) => listOfSides?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleTinglingChange}
                                        isDisabled={!["Applicable"].includes(diabeticExamData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3">
                            <span className="my-1 d-block">Burning</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {diabeticExamData?.status === "Applicable"
                                        ? diabeticExamData?.burning?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="burning"
                                        id="burning"
                                        menuPlacement="bottom"
                                        options={listOfSides}
                                        //value={listOfSides.value}
                                        //defaultValue={diabeticExamData?.burning?.map((dev) => listOfSides?.find((i) => i.value === dev))}
                                        value={(diabeticExamData?.burning ?? []).map((dev) => listOfSides?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleBurningChange}
                                        isDisabled={!["Applicable"].includes(diabeticExamData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-3">
                            <div className="row">
                                <div className="col-6 me-0 pe-1">
                                    <span className="my-1 d-block">Blood Sugar</span>
                                    {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.bloodSugar ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <input type="text" className="form-control" placeholder={`Value`} value={diabeticExamData?.bloodSugar ?? ""} name="bloodSugar" onChange={handleFieldChange} maxLength="4" disabled={!["Applicable"].includes(diabeticExamData?.status)} />
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                                <div className="col-6 ms-0 ps-0">
                                    <span className="my-1 d-block">&nbsp;</span>
                                    {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.bloodSugarReported ?? "-" : "-"}</div>}
                                    {!readOnly && (
                                        <>
                                            <select name="bloodSugarReported" className="form-select" onChange={handleFieldChange} value={diabeticExamData?.bloodSugarReported ?? ""} disabled={!["Applicable"].includes(diabeticExamData?.status)}>
                                                <option value="">Select</option>
                                                <option value="Actual">Actual</option>
                                                <option value="Reported">Reported</option>
                                            </select>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Client test their Blood Glucose?</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.clientTestBloodGlucose ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="clientTestBloodGlucose" className="form-select" onChange={handleFieldChange} value={diabeticExamData?.clientTestBloodGlucose ?? ""} disabled={!["Applicable"].includes(diabeticExamData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <span className="my-1 d-block">Glucometer type</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.glucometerType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" placeholder={`Glucometer type`} value={diabeticExamData?.glucometerType ?? ""} name="glucometerType" onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(diabeticExamData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Are acquiring supplies difficult for the client?</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.acquiringSuppliesAreDifficult ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="acquiringSuppliesAreDifficult" className="form-select" onChange={handleFieldChange} value={diabeticExamData?.acquiringSuppliesAreDifficult ?? ""} disabled={!["Applicable"].includes(diabeticExamData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <span className="my-1 d-block">Independent with use of the machine?</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.independentUseMachine ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="independentUseMachine" className="form-select" onChange={handleFieldChange} value={diabeticExamData?.independentUseMachine ?? ""} disabled={!["Applicable"].includes(diabeticExamData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Average fasting range (last 30 days)</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.averageFastingRange ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="text" className="form-control" placeholder={`Average fasting range`} value={diabeticExamData?.averageFastingRange ?? ""} name="averageFastingRange" onChange={handleFieldChange} maxLength="20" disabled={!["Applicable"].includes(diabeticExamData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <span className="my-1 d-block">How many days did they test (last 30 days)</span>
                            {readOnly && <div className="text-success pb-2">{diabeticExamData?.status === "Applicable" ? diabeticExamData?.numDaysTested ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input type="number" className="form-control" placeholder={`Num Days`} value={diabeticExamData?.numDaysTested ?? ""} name="numDaysTested" onChange={handleFieldChange} maxLength="2" disabled={!["Applicable"].includes(diabeticExamData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Since the last assessment. Has the patient experienced the following</span>
                            {readOnly && (
                                <div className="text-success pb-2">
                                    {diabeticExamData?.status === "Applicable"
                                        ? diabeticExamData?.symptomsSinceLastAssessment?.map((item, index) => {
                                              if (index === 0) return item;
                                              else return `, ${item}`;
                                          }) ?? "-"
                                        : "-"}
                                </div>
                            )}
                            {!readOnly && (
                                <>
                                    <Select
                                        isMulti
                                        name="symptomsSinceLastAssessment"
                                        id="symptomsSinceLastAssessment"
                                        menuPlacement="bottom"
                                        options={listOfPreviousExperiences}
                                        value={(diabeticExamData?.symptomsSinceLastAssessment ?? []).map((dev) => listOfPreviousExperiences?.find((i) => i.value === dev))}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={handleSymptomsSinceLastAssessmentChange}
                                        isDisabled={!["Applicable"].includes(diabeticExamData?.status)}
                                    ></Select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, diabeticExamSection: data?.diabeticExamSection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
