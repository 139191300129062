export const DownloadVisitSummaryButton = ({ className, loading, handleDownload, children = "Download Summary", disabled = false, readOnly = false }) => {
    return (
        <>
            {!readOnly && (
                <button type="button" className={className} disabled={disabled || loading} onClick={handleDownload}>
                    {!loading && children}
                    {loading && (
                        <span className="ps-2">
                            <span className="pe-2">Downloading...</span>
                            <span className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></span>
                        </span>
                    )}
                </button>
            )}
        </>
    );
};
