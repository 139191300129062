import Moment from "react-moment";
import "../visitListStyle.css";
import { useState } from "react";
import { getVisitSummaryForm } from "../../../../../api/visitAPI";
import { DownloadVisitSummaryButton } from "../../../../../components/downloadVisitSummaryButton/downloadVisitSummaryButton";

export const VisitListTableBody = ({ currentPage, numberPerPage, items, loadingError, loading, displayAssignedToField, handleItemClick }) => {
    const begin = (Number(currentPage) - 1) * Number(numberPerPage);
    const end = begin + Number(numberPerPage);
    const data = items.slice(begin, end);

    //=== Use States ==========================================================

    //=== Use Effects =========================================================

    //=== Methods =============================================================

    //=== Handlers ============================================================

    const handleDownloadSummaryClick = async (visitId, patientName) => {
        //setDownloadingSummary(true);

        const data = { id: visitId, patientName: patientName?.replace(/\s/g, "") }; //{ patientName: patient?.patient?.replace(/\s/g, ""), visitDate: moment(patient?.appointmentDate).format("YYYYMMDD"), cpaVisitId: patient?.cpaVisitId ?? 0, assessmentID: patient?.id, prognosis: patient?.nurseOrdersSection?.prognosis, assessments: assessments };

        await getVisitSummaryForm(data)
            .then((ret) => {})
            .catch((err) => {
                try {
                    //setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    //setOperationError("Error loading data");
                }

                //hide message after 5s
                //setTimeout(() => setOperationError(null), 5000);
            })
            .finally((_) => {
                //setDownloadingSummary(false);
            });
    };

    //=== Render ==============================================================

    if (loadingError)
        return (
            <tr>
                <td colSpan={displayAssignedToField ? "9" : "8"} className="table-danger text-center">
                    {loadingError}
                </td>
            </tr>
        );

    return data.length === 0
        ? !loading && (
              <tr>
                  <td colSpan={displayAssignedToField ? "9" : "8"} className="table-danger text-center">
                      No Visits to display
                  </td>
              </tr>
          )
        : data.map(({ patient, cpOffice, assignedTo, contactName, contactPhone, address, assessmentType, appointmentDate, assignedToId, assignedToName, nurseAssessmentId, status, id }) => {
              return (
                  <tr key={id} onClick={() => handleItemClick(nurseAssessmentId, id)} style={{ cursor: "pointer" }}>
                      <td className="small" valign="middle">
                          {patient}
                      </td>

                      <td className="small d-none d-md-table-cell" valign="middle">
                          {cpOffice}
                      </td>

                      {displayAssignedToField && (
                          <td className="small d-none d-md-table-cell" valign="middle">
                              {assignedTo}
                          </td>
                      )}
                      <td className="small d-none d-md-table-cell" valign="middle">
                          {address}
                      </td>

                      <td className="small" valign="middle">
                          {contactPhone}
                      </td>

                      <td className="small d-none d-md-table-cell" valign="middle">
                          {assessmentType}
                      </td>

                      {/*
                      <td className="small" valign="middle">
                          {assignedToId != null ? assignedToName : "unassigned"}
                      </td>
                    */}
                      <td className="small" valign="middle">
                          <Moment format="MM/DD/YYYY hh:mm A">{appointmentDate}</Moment>
                      </td>
                      <td className="small text-center d-none d-md-table-cell" valign="middle">
                          {status ?? "Not Started"}
                      </td>

                      <td className="small text-center d-none d-md-table-cell" valign="middle">
                          {status === "Completed" ? (
                              <DownloadVisitSummaryButton
                                  className="btn btn-outline-success btn-sm"
                                  handleDownload={(event) => {
                                      event.stopPropagation();
                                      handleDownloadSummaryClick(id, patient);
                                  }}
                                  loading={false}
                              >
                                  Summary
                              </DownloadVisitSummaryButton>
                          ) : (
                              "-"
                          )}
                      </td>

                      {/* 
                      <td align="right" className="text-right" valign="middle">
                          {assignedToId && (
                              <>
                                  <button className="btn btn-sm btn-outline-secondary" title="Release Assignment">
                                      <i className="fa fa-arrow-left"></i>
                                  </button>
                                  <button className="btn btn-sm btn-success ms-1" title="Edit Assessments">
                                      <i className="fa fa-pencil"></i>
                                  </button>
                              </>
                          )}
                          {!assignedToId && (
                              <button className="btn btn-sm btn-success" title="Take over patient">
                                  <i className="fa fa-plus"></i>
                              </button>
                          )}
                      </td>
                      */}
                  </tr>
              );
          });
};
