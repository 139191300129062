//css
import "./cardiopulmonaryDialog.css";

//Icons
import icon from "../../../../assets/heart-pulse-green.svg";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateCardiopulmonarySection } from "../../../../api/nurseAssessment/cardiopulmonaryAPI";
import { isError } from "../../../../lib/isError";
import { ModalDialogFooter } from "../../../../components/modalDialogFooter/modalDialogFooter";
import { AssessmentSummary } from "../summaryDialog/components/assessmentSummary/assessmentSummary";

export const CardiopulmonaryDialog = ({ data, setData, closeDialog, readOnly = false, enableStatusField = false }) => {
    //Cardiopulmonary data state
    const [cardiopulmonaryData, setCardiopulmonaryData] = useState(null);

    //saving state
    const [saving, setSaving] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //=== Use Effects ========================================================

    useEffect(() => {
        const sectionData = { ...data?.cardiopulmonarySection, status: data?.cardiopulmonarySection?.status ?? "Applicable" };
        setCardiopulmonaryData(sectionData);
    }, [data]);

    //=== Handlers ===========================================================

    const handleReset = () => {
        const newData = { id: cardiopulmonaryData?.id, status: cardiopulmonaryData?.status };
        setCardiopulmonaryData(newData);
    };

    const handleSave = () => {
        setSaving(true);
        const saveObj = {
            ...cardiopulmonaryData,
            status: cardiopulmonaryData?.status,
            pacemaker: cardiopulmonaryData?.pacemaker,
            pacemakerLastChecked: cardiopulmonaryData?.pacemakerLastChecked,
            pacemakerType: cardiopulmonaryData?.pacemakerType,
            chestPain: cardiopulmonaryData?.chestPain,
            palpitation: cardiopulmonaryData?.palpitation,
            headacheDizziness: cardiopulmonaryData?.headacheDizziness,
            pittingEdema: cardiopulmonaryData?.pittingEdema,
            pittingEdemaLUE: cardiopulmonaryData?.pittingEdemaLUE,
            pittingEdemaLUEDescription: cardiopulmonaryData?.pittingEdemaLUEDescription,
            pittingEdemaLLE: cardiopulmonaryData?.pittingEdemaLLE,
            pittingEdemaLLEDescription: cardiopulmonaryData?.pittingEdemaLLEDescription,
            pittingEdemaRUE: cardiopulmonaryData?.pittingEdemaRUE,
            pittingEdemaRUEDescription: cardiopulmonaryData?.pittingEdemaRUEDescription,
            pittingEdemaRLE: cardiopulmonaryData?.pittingEdemaRLE,
            pittingEdemaRLEDescription: cardiopulmonaryData?.pittingEdemaRLEDescription,
            nonPittingEdema: cardiopulmonaryData?.nonPittingEdema,
            nonPittingEdemaLUE: cardiopulmonaryData?.nonPittingEdemaLUE,
            nonPittingEdemaLUEDescription: cardiopulmonaryData?.nonPittingEdemaLUEDescription,
            nonPittingEdemaLLE: cardiopulmonaryData?.nonPittingEdemaLLE,
            nonPittingEdemaLLEDescription: cardiopulmonaryData?.nonPittingEdemaLLEDescription,
            nonPittingEdemaRUE: cardiopulmonaryData?.nonPittingEdemaRUE,
            nonPittingEdemaRUEDescription: cardiopulmonaryData?.nonPittingEdemaRUEDescription,
            nonPittingEdemaRLE: cardiopulmonaryData?.nonPittingEdemaRLE,
            nonPittingEdemaRLEDescription: cardiopulmonaryData?.nonPittingEdemaRLEDescription,
            other: cardiopulmonaryData?.other,
            pedalPulse: cardiopulmonaryData?.pedalPulse,
            pedalPulsePresence: cardiopulmonaryData?.pedalPulsePresence,
            pedalPulseValue: cardiopulmonaryData?.pedalPulseValue,
            cough: cardiopulmonaryData?.cough,
            coughType: cardiopulmonaryData?.coughType,
            coughProductiveColor: cardiopulmonaryData?.coughProductiveColor,
            coughProductiveCharacter: cardiopulmonaryData?.coughProductiveCharacter,
            dyspnea: cardiopulmonaryData?.dyspnea,
            orthopnea: cardiopulmonaryData?.orthopnea,
            cyanosis: cardiopulmonaryData?.cyanosis,
            o2Flow: cardiopulmonaryData?.o2Flow,
            o2Via: cardiopulmonaryData?.o2Via,
            o2Prescription: cardiopulmonaryData?.o2Prescription,
            comments: cardiopulmonaryData?.comments,
        };

        //===========================================================

        //Saving CardiopulmonarySection
        updateCardiopulmonarySection(saveObj)
            .then((ret) => {
                const newData = { ...data, status: "In Progress", cardiopulmonarySection: ret };
                setData(newData);
                closeDialog();
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...cardiopulmonaryData, [name]: value };
        setCardiopulmonaryData(newData);
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        const newData = { ...cardiopulmonaryData, [name]: checked };
        setCardiopulmonaryData(newData);
    };

    return (
        <Modal dialogClassName="cardiopulmonaryModal" show={true} onHide={closeDialog}>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">
                    <div>
                        <span className="icon">
                            <img src={icon} width="24px" height="24px" alt="" className="icon mt-0 pt-0"></img>
                        </span>
                        <span className="ps-2">Cardiopulmonary - {data.patient}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    maxHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                }}
            >
                <>
                    {enableStatusField && (
                        <div className="row">
                            <div className="col-12">
                                <span className="my-1 d-block">Status</span>
                                {readOnly && <div className="text-success pb-2">{cardiopulmonaryData?.status ?? "-"}</div>}
                                {!readOnly && (
                                    <>
                                        <select name="status" className="form-select" onChange={handleFieldChange} value={cardiopulmonaryData?.status ?? ""}>
                                            <option value="">Select</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Applicable">Applicable</option>
                                        </select>
                                        <div className="d-block text-danger small text-end">&nbsp;</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <span className="text-success">
                                Cardio <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 me-0 pe-0 ">
                            <span className="my-1 d-block">Cardiac Device</span>
                            {readOnly && <div className="text-success pb-2">{cardiopulmonaryData?.status === "Applicable" ? cardiopulmonaryData?.pacemaker ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="pacemaker" className="form-select" onChange={handleFieldChange} value={cardiopulmonaryData?.pacemaker ?? ""} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-3">
                            <span className="my-1 d-block">Last Checked</span>
                            {readOnly && <div className="text-success pb-2">{cardiopulmonaryData?.status === "Applicable" ? cardiopulmonaryData?.pacemakerLastChecked?.split("T")[0] ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="pacemakerLastChecked" type="date" className="form-control" placeholder={`Last Checked`} value={cardiopulmonaryData?.pacemakerLastChecked?.split("T")[0] ?? ""} onChange={handleFieldChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || cardiopulmonaryData?.pacemaker !== "Yes"} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-6">
                            <span className="my-1 d-block">Type</span>
                            {readOnly && <div className="text-success pb-3">{cardiopulmonaryData?.status === "Applicable" ? cardiopulmonaryData?.pacemakerType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="pacemakerType" className="form-select" onChange={handleFieldChange} value={cardiopulmonaryData?.pacemakerType ?? ""} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || cardiopulmonaryData?.pacemaker !== "Yes"}>
                                        <option value="">Select</option>
                                        <option value="IDC">IDC</option>
                                        <option value="Pacemaker">Pacemaker</option>
                                        <option value="IDC/Pacemaker">IDC/Pacemaker</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <input name="chestPain" id="chestPain" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.chestPain ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="chestPain">
                                Chest Pain
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <input name="palpitation" id="palpitation" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.palpitation ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="palpitation">
                                Palpitation
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                        <div className="col-3">
                            <input name="headacheDizziness" id="headacheDizziness" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.headacheDizziness ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="headacheDizziness">
                                Headache/Dizziness
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-check form-check-inline">
                                        <input name="pittingEdema" id="pittingEdema" type="checkbox" className="form-check-input" onChange={handleCheckboxChange} checked={cardiopulmonaryData?.pittingEdema ?? false} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                                        <label className="form-check-label" htmlFor="pittingEdema">
                                            Pitting Edema
                                        </label>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-1 offset-2 pt-2 me-0 pe-0">
                                            <input name="pittingEdemaLUE" id="pittingEdemaLUE" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.pittingEdemaLUE ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.pittingEdema} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2" htmlFor="pittingEdemaLUE">
                                                LUE
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-4 me-0 pe-0">
                                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.pittingEdemaLUE ? cardiopulmonaryData?.pittingEdemaLUEDescription ?? "-" : "-"}</div>}
                                            {!readOnly && (
                                                <>
                                                    <input name="pittingEdemaLUEDescription" type="text" className="form-control" placeholder={`Describe`} value={cardiopulmonaryData?.pittingEdemaLUEDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.pittingEdema || !cardiopulmonaryData?.pittingEdemaLUE} />
                                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-1 pt-2 me-0 pe-0">
                                            <input name="pittingEdemaLLE" id="pittingEdemaLLE" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.pittingEdemaLLE ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.pittingEdema} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2" htmlFor="pittingEdemaLLE">
                                                LLE
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-4">
                                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.pittingEdemaLLE ? cardiopulmonaryData?.pittingEdemaLLEDescription ?? "-" : "-"}</div>}
                                            {!readOnly && (
                                                <>
                                                    <input name="pittingEdemaLLEDescription" type="text" className="form-control" placeholder={`Describe`} value={cardiopulmonaryData?.pittingEdemaLLEDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.pittingEdema || !cardiopulmonaryData?.pittingEdemaLLE} />
                                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-1 offset-2 pt-2 pe-0">
                                            <input name="pittingEdemaRUE" id="pittingEdemaRUE" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.pittingEdemaRUE ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.pittingEdema} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2 pe-0 me-0" htmlFor="pittingEdemaRUE">
                                                RUE
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-4 me-0 pe-0">
                                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.pittingEdemaRUE ? cardiopulmonaryData?.pittingEdemaRUEDescription ?? "-" : "-"}</div>}
                                            {!readOnly && (
                                                <>
                                                    <input name="pittingEdemaRUEDescription" type="text" className="form-control" placeholder={`Describe`} value={cardiopulmonaryData?.pittingEdemaRUEDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.pittingEdema || !cardiopulmonaryData?.pittingEdemaRUE} />
                                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-1 pt-2 me-0 pe-0">
                                            <input name="pittingEdemaRLE" id="pittingEdemaRLE" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.pittingEdemaRLE ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.pittingEdema} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2" htmlFor="pittingEdemaRLE">
                                                RLE
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-4">
                                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.pittingEdemaRLE ? cardiopulmonaryData?.pittingEdemaRLEDescription ?? "-" : "-"}</div>}
                                            {!readOnly && (
                                                <>
                                                    <input name="pittingEdemaRLEDescription" type="text" className="form-control" placeholder={`Describe`} value={cardiopulmonaryData?.pittingEdemaRLEDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.pittingEdema || !cardiopulmonaryData?.pittingEdemaRLE} />
                                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-check form-check-inline">
                                        <input name="nonPittingEdema" id="nonPittingEdema" type="checkbox" className="form-check-input" onChange={handleCheckboxChange} checked={cardiopulmonaryData?.nonPittingEdema ?? false} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                                        <label className="form-check-label" htmlFor="nonPittingEdema">
                                            Non-Pitting Edema
                                        </label>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-1 offset-2 pt-2 me-0 pe-0">
                                            <input name="nonPittingEdemaLUE" id="nonPittingEdemaLUE" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.nonPittingEdemaLUE ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.nonPittingEdema} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2" htmlFor="nonPittingEdemaLUE">
                                                LUE
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-4 me-0 pe-0">
                                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.nonPittingEdemaLUE ? cardiopulmonaryData?.nonPittingEdemaLUEDescription ?? "-" : "-"}</div>}
                                            {!readOnly && (
                                                <>
                                                    <input name="nonPittingEdemaLUEDescription" type="text" className="form-control" placeholder={`Describe`} value={cardiopulmonaryData?.nonPittingEdemaLUEDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.nonPittingEdema || !cardiopulmonaryData?.nonPittingEdemaLUE} />
                                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-1 pt-2  me-0 pe-0">
                                            <input name="nonPittingEdemaLLE" id="nonPittingEdemaLLE" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.nonPittingEdemaLLE ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.nonPittingEdema} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2" htmlFor="nonPittingEdemaLLE">
                                                LLE
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-4">
                                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.nonPittingEdemaLLE ? cardiopulmonaryData?.nonPittingEdemaLLEDescription ?? "-" : "-"}</div>}
                                            {!readOnly && (
                                                <>
                                                    <input name="nonPittingEdemaLLEDescription" type="text" className="form-control" placeholder={`Describe`} value={cardiopulmonaryData?.nonPittingEdemaLLEDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.nonPittingEdema || !cardiopulmonaryData?.nonPittingEdemaLLE} />
                                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-1 offset-2 pt-2 pe-0">
                                            <input name="nonPittingEdemaRUE" id="nonPittingEdemaRUE" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.nonPittingEdemaRUE ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.nonPittingEdema} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2 pe-0 me-0" htmlFor="nonPittingEdemaRUE">
                                                RUE
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-4 me-0 pe-0">
                                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.nonPittingEdemaRUE ? cardiopulmonaryData?.nonPittingEdemaRUEDescription ?? "-" : "-"}</div>}
                                            {!readOnly && (
                                                <>
                                                    <input name="nonPittingEdemaRUEDescription" type="text" className="form-control" placeholder={`Describe`} value={cardiopulmonaryData?.nonPittingEdemaRUEDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.nonPittingEdema || !cardiopulmonaryData?.nonPittingEdemaRUE} />
                                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-1 pt-2 me-0 pe-0">
                                            <input name="nonPittingEdemaRLE" id="nonPittingEdemaRLE" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.nonPittingEdemaRLE ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.nonPittingEdema} disabled={readOnly} value="" />
                                            <label className="form-check-label ps-2" htmlFor="nonPittingEdemaRLE">
                                                RLE
                                            </label>
                                            <div className="d-block text-danger small text-end">&nbsp;</div>
                                        </div>
                                        <div className="col-4">
                                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.nonPittingEdemaRLE ? cardiopulmonaryData?.nonPittingEdemaRLEDescription ?? "-" : "-"}</div>}
                                            {!readOnly && (
                                                <>
                                                    <input name="nonPittingEdemaRLEDescription" type="text" className="form-control" placeholder={`Describe`} value={cardiopulmonaryData?.nonPittingEdemaRLEDescription ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.nonPittingEdema || !cardiopulmonaryData?.nonPittingEdemaRLE} />
                                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Other</span>
                            {readOnly && <div className="text-success pb-2">{cardiopulmonaryData?.status === "Applicable" ? cardiopulmonaryData?.other ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="other" type="text" className="form-control" placeholder={`Describe Other`} value={cardiopulmonaryData?.other ?? ""} onChange={handleFieldChange} maxLength="50" disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row m-0 py-2">
                        <div className="col-12 mx-0 px-0">
                            <span className="text-success">
                                Pulmonary <hr className="my-1" />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 mt-2">
                            <input name="cough" id="cough" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.cough ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="cough">
                                Cough
                            </label>
                        </div>
                        <div className="col-3 me-0 pe-0">
                            <span className="my-1 d-block"></span>
                            {readOnly && <div className="text-success pb-3 pt-1">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.cough ? cardiopulmonaryData?.coughType ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="coughType" className="form-select" onChange={handleFieldChange} value={cardiopulmonaryData?.coughType ?? ""} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.cough}>
                                        <option value="">Select</option>
                                        <option value="Productive">Productive</option>
                                        <option value="Non-Productive">Non-Productive</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-1 text-end mt-2">
                            <label className="form-check-label" htmlFor="coughProductiveColor">
                                Color
                            </label>
                        </div>
                        <div className="col-2">
                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.cough && cardiopulmonaryData?.coughType === "Productive" ? cardiopulmonaryData?.coughProductiveColor ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="coughProductiveColor" className="form-control" type="text" maxLength="15" value={cardiopulmonaryData?.coughProductiveColor ?? ""} onChange={handleFieldChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.cough || !["Productive"].includes(cardiopulmonaryData?.coughType)} />
                                </>
                            )}
                        </div>
                        <div className="col-1 text-end mt-2 ps-0 ms-0">
                            <label className="form-check-label">Character</label>
                        </div>
                        <div className="col-3">
                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" && cardiopulmonaryData?.cough && cardiopulmonaryData?.coughType === "Productive" ? cardiopulmonaryData?.coughProductiveCharacter ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="coughProductiveCharacter" className="form-control" type="text" maxLength="50" value={cardiopulmonaryData?.coughProductiveCharacter ?? ""} onChange={handleFieldChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status) || !cardiopulmonaryData?.cough || !["Productive"].includes(cardiopulmonaryData?.coughType)} />
                                </>
                            )}
                        </div>
                        <div className="d-block text-danger small text-end">&nbsp;</div>
                    </div>

                    <div className="row">
                        <div className="col-2">
                            <input name="dyspnea" id="dyspnea" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.dyspnea ?? false} onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="dyspnea">
                                Dyspnea
                            </label>
                        </div>
                        <div className="col-2">
                            <input name="orthopnea" id="orthopnea" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.orthopnea ?? false} id="orthopnea" onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="orthopnea">
                                Orthopnea
                            </label>
                        </div>
                        <div className="col-2">
                            <input name="cyanosis" type="checkbox" className="form-check-input" checked={cardiopulmonaryData?.cyanosis ?? false} id="cyanosis" onChange={handleCheckboxChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} disabled={readOnly} value="" />
                            <label className="form-check-label ps-2" htmlFor="cyanosis">
                                Cyanosis
                            </label>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">O² (liters/minute)</span>
                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" ? cardiopulmonaryData?.o2Flow ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <input name="o2Flow" type="text" className="form-control" placeholder={`O² flow`} value={cardiopulmonaryData?.o2Flow ?? ""} onChange={handleFieldChange} maxLength="10" disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4">
                            <span className="my-1 d-block">O² via</span>
                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" ? cardiopulmonaryData?.o2Via ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="o2Via" className="form-select" onChange={handleFieldChange} value={cardiopulmonaryData?.o2Via ?? ""} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)}>
                                        <option value="">Select</option>
                                        <option value="Nasal Cannula">Nasal Cannula</option>
                                        <option value="Mask">Mask</option>
                                        <option value="Trach">Trach</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                        <div className="col-4 me-0 pe-0">
                            <span className="my-1 d-block">O² Prescription</span>
                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" ? cardiopulmonaryData?.o2Prescription ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <select name="o2Prescription" className="form-select" onChange={handleFieldChange} value={cardiopulmonaryData?.o2Prescription ?? ""} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)}>
                                        <option value="">Select</option>
                                        <option value="PRN">PRN</option>
                                        <option value="Continuous">Continuous</option>
                                    </select>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <label className="my-1 d-block" htmlFor="comments">
                                Comments
                            </label>
                            {readOnly && <div className="text-success pb-3 pt-2">{cardiopulmonaryData?.status === "Applicable" ? cardiopulmonaryData?.comments ?? "-" : "-"}</div>}
                            {!readOnly && (
                                <>
                                    <textarea name="comments" id="comments" className="form-control" placeholder={`Comments`} value={cardiopulmonaryData?.comments ?? ""} maxLength="4000" onChange={handleFieldChange} disabled={!["Applicable"].includes(cardiopulmonaryData?.status)} />
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <ModalDialogFooter operationError={operationError} saving={saving} handleClose={closeDialog} handleSave={handleSave} handleReset={handleReset} showPrint={true} readOnly={readOnly}>
                    {/*Component to be printed when enable print button is true*/}
                    <AssessmentSummary data={{ patient: data?.patient, contactPhone: data?.contactPhone, address: data?.address, assessmentType: data?.assessmentType, status: data?.status, appointmentDate: data?.appointmentDate, client: data?.client, cardiopulmonarySection: data?.cardiopulmonarySection }} />
                </ModalDialogFooter>
            </Modal.Footer>
        </Modal>
    );
};
