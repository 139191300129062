//Icons
import iconBandaidRed from "../../../assets/bandaid-red.svg";
import iconBandaidGreen from "../../../assets/bandaid-green.svg";

import { updateWoundCareSection } from "../../../api/nurseAssessment/woundCareAPI";
import { isError } from "../../../lib/isError";

export const getWoundCareSectionData = (data, displayConfirmNotApplicableDialog, patient, setPatient, setSaving, setOperationError, setShowWoundCareDialog, setShowConfirmNotApplicableDialog, setHandleChangeStatusCard) => {
    const updateWoundCareSectionStatus = (notApplicable) => {
        setSaving(true);

        //===========================================================
        //Saving WoundCare Section

        const status = notApplicable ? "Not Applicable" : "Applicable";
        //const saveObj = { ...patient?.woundCareSection, status: status };
        const saveObj = { id: patient?.woundCareSection?.id, status: status };
        updateWoundCareSection(saveObj)
            .then((ret) => {
                const newData = { ...patient, status: "In Progress", woundCareSection: ret };
                setPatient(newData);
                setSaving(false);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading data");
                }
                setSaving(false);

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        //===========================================================
    };

    return {
        title: "Wound Care",
        description: null,
        progress: data?.woundCareSection?.progress,
        status: data?.woundCareSection?.status,
        enableStatusField: true,
        iconClass: "fa-wheelchair",
        iconPending: iconBandaidRed,
        iconCompleted: iconBandaidGreen,
        saving: false,
        onClick: () => {
            setShowWoundCareDialog(true);
        },
        statusClickHandler: (notApplicable) => {
            if (notApplicable && displayConfirmNotApplicableDialog) {
                setHandleChangeStatusCard(() => updateWoundCareSectionStatus);
                setShowConfirmNotApplicableDialog(true);
            } else {
                updateWoundCareSectionStatus(notApplicable);
            }
        },
    };
};
