import icon from "../../../../../../../../assets/note-sticky-solid-red.svg";

export const SummaryComments = ({ data }) => {
    return (
        <>
            {data?.status !== "Not Applicable" && (
                <small>
                    <div className="row-prt">
                        <div className="col-prt-12">
                            <div className="mb-4">
                                <img src={icon} alt="" height="30px" width="39px" />
                                &nbsp;&nbsp;
                                <span className="h5" style={{ position: "relative", top: "5px" }}>
                                    Comments
                                </span>
                            </div>
                        </div>
                    </div>

                    {data?.comments.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="row-prt ms-2">
                                    <div className="col-prt-12 fw-bold">({index + 1}) Comments</div>
                                </div>
                                <div className="row-prt ms-2 mb-2">
                                    <div className="col-prt-12 text-primary">{item?.comments ?? "-"}</div>
                                </div>
                            </div>
                        );
                    })}

                    <div className="row-prt ms-2">
                        <div className="col-prt-12">
                            <hr />
                        </div>
                    </div>
                </small>
            )}
        </>
    );
};
